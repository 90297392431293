import React from 'react'
import styles from './index.module.scss'
import { Typography } from '@material-ui/core'
import CustomTabs from 'components/v3/Tabs/Tabs'
import { tabLabels } from './tabsData'
import PiplineTimeline from './components/PipelineTimeline'
import PendingAward from './components/PendingAward'
import HealthRevenue from './components/HealthRevenue'
import Archive from './components/Archive'

const Opportunities = () => {
  return (
    <div className={styles['opportunities-scroll-wrapper']}>
      <div className={styles['opportunities-wrapper']}>
        <Typography className={styles['opportunities-title']} variant='h2'>Opportunities</Typography>
        <CustomTabs 
          tabLabels={tabLabels}
          tabItems={[
            <PiplineTimeline />,
            <HealthRevenue />,
            <PendingAward />,
            <Archive />
          ]}
        />
      </div>
    </div>
  )
}

export default Opportunities