import { Dispatch } from 'redux'
import Paths from 'utils/paths'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import { AdResponseModel, Ad } from 'models/common/ads/ad.model'
import { BaseResponseModel } from 'models/base/base.model'

export const GET_ITEMS = 'GET_ITEMS'
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS'
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR'

export const SET_COUNTER = 'SET_COUNTER'
export const SET_CLICKED = 'SET_CLICKED'

export const getItems = (dispatch: Dispatch) => async () => {
  dispatch({
    type: GET_ITEMS
  })
  try {
    const res = await request<AdResponseModel>(RequestMethods.Get, Paths.Common.Ads.getItems, {
      schema: AdResponseModel
    })
    dispatch({
      type: GET_ITEMS_SUCCESS,
      payload: res.data.data.data
    })
  }
  catch (e) {
    dispatch({
      type: GET_ITEMS_ERROR
    })
  }
}

export const getAd = (dispatch: Dispatch) => (ads: Ad[], counter: number): Ad => {
  // Counter is index of element
  let newCounter = counter + 1

  // If counter is bigger or equal ads.length, reset counter
  // It can be equal because of length starting with 1
  if (newCounter >= ads.length) {
    newCounter = 0
  }

  dispatch({
    type: SET_COUNTER,
    payload: newCounter
  })

  return ads[counter]
}

export const sendAd = (dispatch: Dispatch) => async (ad: Ad) => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Common.Ads.sendItem(ad.id))
  dispatch({
    type: SET_CLICKED,
    payload: ad.id
  })
}