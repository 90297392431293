export const alphabetFormatter = (num: number) => {
  // @ts-ignore
  return Intl.NumberFormat('en-US', {notation: 'compact', compactDisplay: 'short'}).format(num)
}

export const numberToFixedWithCommas = (num: number) => {
  return numberWithCommas(parseFloat(num.toFixed(2)))
}

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const removeCommas = (num: string) => {
  return num.replace(/[\s.,%]/g, '')
}

const billionRound = (num: number) => Math.round(num / 100000000) * 100000000
const millionRound = (num: number) => Math.round(num / 100000) * 100000
const thousandRound = (num: number) => Math.round(num / 1000) * 1000
const toFixed = (num: number) => Number(num.toFixed(2))

export const numberToRoundWithoutDollarSign = (nmb: number) => {
  if (nmb === null || nmb === undefined) {
    return undefined
  }

  if (Math.abs(Number(nmb)) >= 1.0e+9) {
    nmb = billionRound(Number(nmb))
  } else if (Math.abs(Number(nmb)) >= 1.0e+6) {
    nmb = millionRound(Number(nmb))
  } else if (Math.abs(Number(nmb)) >= 1.0e+3) {
    nmb = thousandRound(Number(nmb))
  }

  if (Math.abs(Number(nmb)) >= 1.0e+9) {
    return (toFixed(nmb) / 1.0e+9) + 'B'
  } else if (Math.abs(Number(nmb)) >= 1.0e+6) {
    return (toFixed(nmb) / 1.0e+6) + 'M'
  } else if (Math.abs(Number(nmb)) >= 1.0e+3) {
    return (toFixed(nmb) / 1.0e+3) + 'K'
  } else {
    return Math.abs(toFixed(nmb))
  }
}

export const numberToStrWithRound = (nmb: number | undefined) => {
  if (nmb === null || nmb === undefined) {
    return undefined
  }
  return '$' + numberToRoundWithoutDollarSign(nmb)
}

export const getOnlyNumberFromString = (str: string): number => {
  return +str.replace(/\D/g, '')
}
