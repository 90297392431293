import React, { useEffect } from 'react'
import { List, ListItem, Typography, ListItemIcon, ListItemText, useTheme, Button, Grid } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import Modal from 'components/v2/Modal'
import CustomLoader from 'components/CustomLoader'
import {
  UserOwnerActivityModel,
  UserOwnerModel
} from 'models/common/user/user.model'
import { getUserDetails } from 'store/common/owner/actions'
import GrantPerissionsModal from './GrantPermissionsModal'

interface Props {
  user: UserOwnerModel
  onClose: () => void
  onConfirm: () => void
  onDelete: () => void
}

const UserModal = ({ user, onClose, onConfirm, onDelete }: Props) => {
  const theme = useTheme()

  const [userDetails, setUserDetails] = React.useState<UserOwnerActivityModel[] | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [managePermissions, setManagePermissions] = React.useState(false)

  const managePermissionsSuccess = () => {
    setManagePermissions(false)
    onConfirm()
  }

  const deletePermissionsSuccess = () => {
    setManagePermissions(false)
    onDelete()
  }

  useEffect(() => {
    setLoading(true)
    getUserDetails(user.id).then(data => {
      setUserDetails(data)
    }).finally(() => setLoading(false))
  }, [])

  const body = () => {
    if (loading) {
      return <CustomLoader size={32} />
    }
    if (!userDetails || userDetails.length === 0) {
      return (
        <div style={{padding: '24px 0'}}>
          <Typography align='center'>No data provided</Typography>
        </div>
      )
    }
    return (
      <div>
        <Typography variant='h6' align='center'>Latest user actions</Typography>
        <List>
          {userDetails?.map((item => (
            <ListItem key={item.id}>
              <ListItemIcon>
                <SvgIcon name='transaction' width={35} height={35} color={theme.palette.grey['700']} />
              </ListItemIcon>
              <ListItemText
                primary={`${item.type} - ${item.date.toLocaleString()}`}
                secondary={item.data}
              />
            </ListItem>
          )))}
        </List>
      </div>
    )
  }

  return (
    <Modal
      title={[user.firstName, user.lastName].join(' ')}
      onClose={onClose}
      size='md'
      open={true}
    >
      {body()}
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <Button
            disabled={user.status !== 'active' || (!!user.paymentPlan && !user.paymentPlan.manageable)}
            onClick={() => setManagePermissions(true)}
          >
            Manage permissions
          </Button>
        </Grid>
      </Grid>

      {managePermissions && (
        <GrantPerissionsModal
          user={user}
          onClose={() => setManagePermissions(false)}
          onManagePermissionsSuccess={managePermissionsSuccess}
          onDeletePermissionsSuccess={deletePermissionsSuccess}
        />
      )}
    </Modal>
  )
}

export default UserModal