export const options = [
  {
    id: 1,
    name: 'PRICE',
    label: 'Price'
  },
  {
    id: 2,
    name: 'TECHNICAL_APPROACH',
    label: 'Technical Approach'
  },
  {
    id: 3,
    name: 'MANAGEMENT_APPROACH',
    label: 'Management Approach'
  },
  {
    id: 4,
    name: 'PAST_PERFORMANCE',
    label: 'Past Performance Strength'
  },
  {
    id: 5,
    name: 'OTHER',
    label: 'Other'
  },
]