import React from 'react'
import styles from '../index.module.scss'
import { Button, Checkbox, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { partnersFinderContactsSelector, partnersFinderDashboardSelector } from 'store/hp/education/selector'
import { getPartnersFinderContacts, getPartnersFinderDashboardById } from 'store/hp/education/action'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import SubAgencyConnectionsItem from './SubAgencyConnectionsItem'
import { awardsFiltersSelector } from 'store/hp/awards/search/selectors'
import { PaginationLimit } from 'utils/constants/education'


export enum SubAgencySpendingEnum {
  awards = 'awards',
  connections = 'connections'
}

interface SubAgencySpendingProps {
  selectedAgencies: number[]
  handleSetAgencies: (agency: number, state: boolean) => void
}

const SubAgencySpending = ({selectedAgencies, handleSetAgencies}: SubAgencySpendingProps) => {
  const dispatch = useDispatch()
  const {
    data: partnertsFinderData,
  } = useSelector(partnersFinderDashboardSelector)
  const notification = useNotification()
  const {loading: contactsLoading, data: contactsData} = useSelector(partnersFinderContactsSelector)
  const selectedFilters = useSelector(awardsFiltersSelector)
  
  const getContacts = (company: string) => {
    getPartnersFinderContacts(dispatch)(company)
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  const getMoreCompanies = (companyId: number, offset: number) => {
    getPartnersFinderDashboardById(dispatch)(selectedFilters, companyId, offset + PaginationLimit)
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }
  
  return (
    <div className={styles['subAgency-spending-wrapper']}>
      <Paper style={{padding: '12px'}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h4'>Small Businesses</Typography>
          <Typography className={styles['relationships-title']} variant='h4'>Relationships</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item md={4} />
          <Grid item md={5} />
          <Grid item md={2}>
            <Grid container spacing={1} alignItems='center' justifyContent='space-between' style={{textAlign: 'center'}}>
              <Grid item md={6}>
                <Typography variant='subtitle2'>Key jobs</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='subtitle2'>Other</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles['relationship-map-list']}>
          {
            partnertsFinderData?.agencies.length && partnertsFinderData.agencies.map((el, index) => (
              <Grid className={styles['partners-finder-item']} key={index}>
                <Typography variant='h5'>{el.agency} / {el.subagency}</Typography>
                {el.companies.map((el, index) => (
                  <Grid container alignItems='center' spacing={2} className={styles['relationship-map-item']} key={index}>
                    <Grid item md={1}> 
                      <Checkbox
                        onClick={() => handleSetAgencies(Number(el.duns!), !selectedAgencies.includes(Number(el.duns!)))}
                        checked={selectedAgencies.includes(Number(el.duns!))}
                        disabled={!el.duns}
                        color='primary'
                        style={{padding: '2px'}}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant='subtitle2' className={styles['relationship-map-agency']} onClick={() => getContacts(el.id)}>{el.company}</Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Grid container alignItems='center' spacing={2}>     
                        <Paper style={{width: `${(100 * el.spending.friendly) / partnertsFinderData.total}%`}} className={styles['progress-item-keys']}>
                          <Typography variant='subtitle1' className={styles['progress-item-value']}>{el.spending.friendly}</Typography>
                        </Paper>    
                      </Grid>
                    </Grid>
                    <Grid item md={2} style={{textAlign: 'center'}}>
                      <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
                        <Grid item md={6}>
                          {el.key}
                        </Grid>
                        <Grid item md={6}>
                          {el.other}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {
                  el.total >= el.companies.length + 1 ? (
                    <Grid container>
                      <Button
                        style={{marginTop: '25px'}}
                        color='primary'
                        variant='contained'
                        onClick={() => getMoreCompanies(el.id, el.offset)}
                      >
                        Load more companies
                      </Button>
                    </Grid>
                  ) : null
                }
              </Grid>
            ))
          }
        </Grid>
      </Paper>
      <Grid container className={styles['connection-details']} spacing={2}>
        {
          contactsLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )
        }
        {
          !contactsLoading && (
            <>
              <SubAgencyConnectionsItem connections={contactsData?.connections || null} type={SubAgencySpendingEnum.connections} />
              <SubAgencyConnectionsItem awards={contactsData?.awards} type={SubAgencySpendingEnum.awards} />
            </>
          )
        }
      </Grid>
    </div>
  )
}

export default SubAgencySpending