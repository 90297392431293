import React, { useRef } from 'react'
import styles from './index.module.scss'
import { Divider, Grid, Paper, Typography } from '@material-ui/core'
import { CompanyProfilePreviewModel, CompanyProfileWithRankedModel } from 'models/hp/company-profiles/base/company-profile.model'
import { NotificationTypeList } from 'components/NotificationType'
import Header from 'components/RfpDetailsBlocks/Header'
import { useNotification } from 'context/NotificationContext'
import { setCompanyCategorized } from 'store/hp/company-profiles/listing/actions'
import { useDispatch } from 'react-redux'
import { previewProps } from 'components/v2/PscImg'
import { getErrorMessage } from 'helpers/errors'

interface Props {
  company: CompanyProfilePreviewModel | CompanyProfileWithRankedModel
  onClick: () => void
  marginBottom?: string
}

const CompanyListItem = ({ company, onClick, marginBottom = '12px' }: Props) => {
  const notification = useRef(useNotification())
  const dispatch = useDispatch()
  
  const isLiked = company.categorizationType === 'like'

  const renderCachedData = () => {
    const cachedData = (company as CompanyProfileWithRankedModel)?.cached
    if (cachedData) {
      return (
        <>
          <Divider light style={{ margin: '24px 0' }} />
          <Grid container justifyContent='space-between' direction='column' className={styles['content']}>
            <Typography
              variant='h2'
              className={styles['title']}
              onClick={onClick}>
              {cachedData.name}
            </Typography>
            <Grid item>
              <Typography>Description:</Typography>
              <Typography variant='h5'>
                {cachedData.description}
              </Typography>
            </Grid>
          </Grid>
        </>
      )
    }
    return null
  }
  return (
    <Paper style={{marginBottom}} className={styles['company-list-item']} variant='outlined'>
      <Header
        title={company.name || company.duns}
        titleClass={styles['header']}
        pscCode={company.popularPscCode}
        previewProps={previewProps}
        isLink={true}
        actions={{
          navigate: {
            handler: () => {
              onClick && onClick()
            }
          },
          like: {
            state: isLiked,
            handler: async () => {
              try {
                await setCompanyCategorized(dispatch)(company)
                notification.current.show(isLiked ? 'Company Unliked' : 'Company added to Liked', NotificationTypeList.Success)
              } catch (e) {
                const msg = getErrorMessage(e)
                notification.current.show(msg, NotificationTypeList.Error)
              }
            }
          }
        }}>
        <Divider light style={{ margin: '24px 0' }}/>
        <Grid container justifyContent='space-between' className={styles['header-content']}>
          <Grid item>
            <Typography>Size:</Typography>
            <Typography variant='h5'>
              {company.sizeName} Sized Company
            </Typography>
          </Grid>
          <Grid item>
            <Typography>GovCon:</Typography>
            <Typography variant='h5'>
              {company.govConName} Company
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Typical Contract Size:</Typography>
            <Typography variant='h5'>
              ${company.roundLowerEnd.toLocaleString()}-{company.roundUpperEnd.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Popular PSC:</Typography>
            <Typography variant='h5'>
              {company.popularPsc}
            </Typography>
          </Grid>
        </Grid>
        {renderCachedData()}
      </Header>
    </Paper>
  )
}

export default CompanyListItem
