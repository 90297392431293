import React from 'react'
import ContractUploader from 'components/v2/ContractUploader'

const Contracts = () => {
  return (
    <>
      <div style={{height: '12px'}}/>
      <ContractUploader fromAdmin={true}/>
    </>
  )
}

export default Contracts
