import React, { useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import SearchInput from './components/SearchInput'
import ContractItem from './components/ContractItem'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import ContractDetails from './components/ContractDetails'
import AssignmentDialog from './components/AssignmentDialog'
import { tableHead } from './components/tableHead'
import TableHeadItem from './components/TableHeadItem'

interface ContractsProps {
  data: IPiplineTimeline[]
  currentContract: IPiplineTimeline | null
  setCurrentContract: (value: IPiplineTimeline | null) => void
  page: number
}

const Contracts = ({data, currentContract, setCurrentContract, page}: ContractsProps) => {
  const [open, setOpen] = useState<boolean>(false)
  
  return (
    <div className={styles['gantt-grid-container__tasks']}>
      <div className={styles['gant-task-header']}>
        <div className={classNames(styles['gant-task-header-column'], styles['gant-task-header-column-input'])}>
          <SearchInput />
        </div>
        {tableHead.map(el => (
          <TableHeadItem 
            key={el.id}
            title={el.title}
            customClassName={el.customClassName}
            fieldName={el.fieldName}
            withSort={el.withSort}
            page={page}
          />
        ))}
      </div>
      {data &&
        data.map((el: IPiplineTimeline, i: number) => (
          <ContractItem
            key={i}
            contract={el}
            contractDetails={currentContract}
            setContractDetails={setCurrentContract}
            setOpenAssignedDialog={setOpen}
          />
        ))}
      <ContractDetails 
        contract={currentContract} 
        setContractDetails={setCurrentContract}
      />
      <AssignmentDialog 
        open={open}
        onClose={() => setOpen(false)}
        contract={currentContract}
      />
    </div>  
  )
}

export default Contracts
