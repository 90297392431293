import React from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { HpMarketResearchRfp } from 'models/hp/rfp/rfp.model'
import { createLazyUserAccount } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { userActivity } from 'store/common/owner/actions'
import { getErrorMessage } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { useNotification } from 'context/NotificationContext'
import { UserBaseModel } from 'models/common/user/user.model'
import { getMarketResearchKeywordsItems } from 'store/hp/market-research/actions'
import NameModal from 'modules/Main/User/MarketResearchTools/Keywords/components/NameModal'
import Filters from './components/Filters'
import Listing from './components/Listing'
import { EDUCATION_SUBTITLE, PlanningPhaseNavigationList } from 'utils/constants/education'
import EducationNavigationButtons from 'modules/Main/Education/components/EducationNavigationButtons'
import { selectedEducationPhaseClassSelector } from 'store/hp/education/selector'
import {
  redirectAccordingEducationPhase,
  setCurrentEducationPhaseClass,
  setPhaseProgressValue
} from 'store/hp/education/action'
import { createFulfilledSearch, removeSearchFilterValues } from 'store/hp/search/actions'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { IFilterOption } from 'models/hp/search/search.interface'
import {
  projectTypeOptions as projectTypeOptionsAction, selectedProjectTypeOptions as selectedProjectTypeOptionsAction
} from 'store/hp/search/selectors'
import { setKeywordsBySearchId } from 'store/hp/keyword/actions'
import { SearchModel } from 'models/hp/search/search.model'

export interface IFilters {
  keywords?: string[]
  type?: string
}

const KeywordsTool = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { show } = useNotification()

  const user = useSelector(userSelector) as UserBaseModel
  const selectedEducationClass = useSelector(selectedEducationPhaseClassSelector)

  const [filters, setFilters] = React.useState<IFilters>({ type: '', keywords: ['', '', '', '', '', ''] })
  const [keywords, setKeywords] = React.useState<string[]>([])
  const [listing, setListing] = React.useState<HpMarketResearchRfp[]>([])
  const [loading, setLoading] = React.useState(false)
  const [modalOpened, setModalOpened] = React.useState(false)

  const projectTypeOptions: IFilterOption[] = useSelector(projectTypeOptionsAction)
  const selectedProjectTypeOptions: number[] = useSelector(selectedProjectTypeOptionsAction) as number[]
  const currentPhaseClassIndex = PlanningPhaseNavigationList.findIndex((phase: IEducationPhaseClass) => phase.name === selectedEducationClass.name)
  const lastClass = currentPhaseClassIndex + 1 === PlanningPhaseNavigationList.length

  const handleChangeFilters = (filters: IFilters) => {
    setFilters(prev => ({ ...prev, ...filters }))
  }

  const handleSetKeywords = (keyword: string, state: boolean) => {
    state
      ? setKeywords(prev => prev.concat(keyword))
      : setKeywords(prev => prev.filter(k => k !== keyword))
  }

  const findItems = async () => {
    try {
      setLoading(true)
      const res = await getMarketResearchKeywordsItems(filters.keywords!, filters.type!)
      setListing(res.data)
    }
    catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  const saveKeywords = async (name: string) => {
    await createFulfilledSearch(dispatch)({
      ...new SearchModel(),
      keywords: keywords,
      type: undefined // not market research type
    }, name, true, false)
  }

  const handleFinish = async (name: string, email?: string) => {
    try {
      if (!user) {
        await createLazyUserAccount({ email: email! })
      }

      await saveKeywords(name)

      setModalOpened(false)

      const msg = user
        ? 'Keywords saved successfully'
        : 'Keywords saved successfully and credentials were saved to provided email'
      show(msg, NotificationTypeList.Success)

      userActivity.event(userActivity.activities.marketResearchToolKeywordsUsed)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleUpdateSearch = async (searchId: number) => {
    try {
      await setKeywordsBySearchId(searchId, keywords)
      show('Search successfully updated', NotificationTypeList.Success)
      setModalOpened(false)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const onNext = () => {
    if (lastClass) {
      setPhaseProgressValue(dispatch)('planning', true)
      return
    }
    let nextPhaseClass = currentPhaseClassIndex < PlanningPhaseNavigationList.length ? PlanningPhaseNavigationList[currentPhaseClassIndex + 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(nextPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, nextPhaseClass, projectTypeOptions)
  }

  const onPrevious = () => {
    let previousPhaseClass = currentPhaseClassIndex ? PlanningPhaseNavigationList[currentPhaseClassIndex - 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(previousPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, previousPhaseClass, projectTypeOptions)
  }

  return (
    <div className={styles['container']}>
      <Grid container spacing={2} className={styles['height-block']}>
        <Grid item md={4} className={styles['filter-list']}>
          <Filters
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            findItems={findItems}
            keywords={keywords}
            handleSaveKeywords={() => setModalOpened(true)}
          />
          {
            selectedEducationClass && selectedEducationClass.name === EDUCATION_SUBTITLE.IDENTIFY_YOUR_KEYWORDS &&
            <EducationNavigationButtons
              onNext={onNext}
              onPrevious={onPrevious}
              hasNext={!lastClass}
              hasPrevious={!!currentPhaseClassIndex}
            />
          }
        </Grid>
        <Grid item md={8} className={styles['height-block']}>
          <Listing
            listing={listing}
            keywords={keywords}
            handleSetKeywords={handleSetKeywords}
            loading={loading}
          />
        </Grid>
      </Grid>

      {modalOpened && (
        <NameModal
          handleSave={handleFinish}
          handleUpdateSearch={handleUpdateSearch}
          onClose={() => setModalOpened(false)}
          user={user}
        />
      )}
    </div>
  )
}

export default KeywordsTool