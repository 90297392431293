import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useNotification } from 'context/NotificationContext'
import { useDispatch, useSelector } from 'react-redux'
import { awardsFiltersSelector } from 'store/hp/awards/search/selectors'
import SearchFilters from './SearchFilters/SearchFilters'
import SubAgencySpending from './SubAgencySpending/SubAgencySpending'
import { NotificationTypeList } from 'components/NotificationType'
import { awardsListingSelector } from 'store/hp/awards/listing/selectors'
import { clearPartnersFinderContacts, getPartnersFinderDashboard } from 'store/hp/education/action'
import {  partnersFinderDashboardSelector } from 'store/hp/education/selector'
import SavedSearches from './SavedSearchs/SavedSearchs'
import { setGroupCompanyCategorized } from 'store/hp/company-profiles/listing/actions'
import { getErrorMessage } from 'helpers/errors'
import { resetSearchFilters } from 'store/hp/search/actions'
import { useHistory } from 'react-router-dom'
import { resetFiltersInUrl } from 'helpers/search'


const PartnersFinder = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const history = useHistory()
  
  const awardsListing = useSelector(awardsListingSelector)
  const selectedFilters = useSelector(awardsFiltersSelector)
  const {
    data: partnertsFinderData,
    loading: partnertsFinderDashboarLoading,
    loaded: partnertsFinderDashboarLoaded
  } = useSelector(partnersFinderDashboardSelector)

  const [isViewTutorial, setViewTutorial] = useState<boolean>(true)
  const [selectedAgencies, setSelectedAgencies] = useState<number[]>([])

  useEffect(() => {
    resetFiltersInUrl(history)
    resetSearchFilters(dispatch)()
  }, [])

  useEffect(() => {
    if(partnertsFinderDashboarLoaded) {
      setViewTutorial(false)
    } 
  }, [partnertsFinderData])

  const handleSearch = (keywords?: string[]) => {
    clearPartnersFinderContacts(dispatch)
    const body = keywords && Array.isArray(keywords) ? {...selectedFilters, keywords} : selectedFilters
    getPartnersFinderDashboard(dispatch)(body)
  }

  const handleSaveAgencies = async () => {
    setGroupCompanyCategorized(selectedAgencies)
      .then(() => {
        setSelectedAgencies([])
        notification.show('Companies added to Liked', NotificationTypeList.Success)
        resetSearchFilters(dispatch)()
      })
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  const handleSetAgencies = (agency: number, state: boolean) => {
    if (state) {
      setSelectedAgencies(prev => prev.concat(agency))
    } else {
      setSelectedAgencies(prev => prev.filter(x => x !== agency))
    }
  }


  return (
    <div className={styles['customer-finder']}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <SavedSearches />
          <SearchFilters
            count={awardsListing.count}
            onSearch={handleSearch}
            onSaveAgencies={handleSaveAgencies}
            selectedAgencies={selectedAgencies}
          />
        </Grid>
        <Grid item md={9}>
          { (isViewTutorial && !partnertsFinderDashboarLoading && !partnertsFinderDashboarLoaded) ?
            <Grid container justifyContent='center'>
              <Paper className={styles['default-prompt']}>
                <Grid container >
                  <Typography variant='subtitle1' className={styles['default-prompt-text']}>
                    Select a saved search and target sub-agencies  or create a new search to find promising small business partners
                  </Typography>
                </Grid>
              </Paper> 
            </Grid>
            : null
          }
          {
            !partnertsFinderDashboarLoading && (!partnertsFinderData?.agencies.length && partnertsFinderDashboarLoaded) ? (
              <Grid container justifyContent='center'>
                <Paper className={styles['default-prompt']}>
                  <Grid container >
                    <Typography variant='subtitle1' className={styles['default-prompt-text']}>
                      We didn't find a small business in your contact list
                    </Typography>
                  </Grid>
                </Paper>
              </Grid> 
            ) : null
          }
          { !partnertsFinderDashboarLoading && partnertsFinderData?.agencies.length ?
            <SubAgencySpending selectedAgencies={selectedAgencies} handleSetAgencies={handleSetAgencies}  /> : null
          }
          { partnertsFinderDashboarLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default PartnersFinder