import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  AWARD_LIST_CATEGORIZED,
  AWARD_LIST_CATEGORIZED_ERROR,
  AWARD_LIST_CATEGORIZED_SUCCESS,
  AWARD_LIST_CATEGORIZED_UPDATE_PAGINATION,
  AWARD_LIST_CATEGORIZED_APPEND_SUCCESS
} from './actions'
import { mapCategorize } from '../reducer-helper'
import { AwardListResponseModel } from 'models/hp/awards/awards.model'
import { Pagination } from 'models/base/base.interfaces'
import { AWARD_LIST_CATEGORIZE_REMOVE } from '../listing/actions'

export interface IAwardsListingCategorized {
  data: AwardListResponseModel
  loading: boolean
  loaded: boolean
  params: Pagination
}

const AwardsListingCategorized = Record<IAwardsListingCategorized>({
  data: new AwardListResponseModel(),
  params: {limit: 20, page: 0},
  loading: false,
  loaded: false,
})

export const awardsListingCategorizedReducer = (state: Record<IAwardsListingCategorized> = new AwardsListingCategorized(), action: IAction) => {
  switch (action.type) {
    case AWARD_LIST_CATEGORIZED:
      return state
        .set('loading', true)

    case AWARD_LIST_CATEGORIZED_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)

    case AWARD_LIST_CATEGORIZED_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)

    case AWARD_LIST_CATEGORIZED_APPEND_SUCCESS:
      return state
        .update('data', (fields: AwardListResponseModel) => ({
          ...fields,
          data: fields.data.concat(action.payload.data)
        }))
    case AWARD_LIST_CATEGORIZED_UPDATE_PAGINATION:
      return state
        .set('params', action.payload)

    case AWARD_LIST_CATEGORIZE_REMOVE:
      return state
        .update('data', content => ({
          ...content,
          data: content.data.map(item => mapCategorize(item, action.payload.id, false, 'trash'))
        }))

    default:
      return state
  }
}
