import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import { MarketContractsResponseModel } from 'models/hp/market-research/market-research.model'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'
import { AppendContractsOpts } from 'modules/Main/User/MarketResearchTools/ExpiringContracts/Listing/components/LoadMoreBtn'
import { BaseResponseModel } from '../../../models/base/base.model'

export const MARKET_RESEARCH_TOOLS_LIST = 'MARKET_RESEARCH_TOOLS_LIST'
export const MARKET_RESEARCH_TOOLS_LIST_ERROR = 'MARKET_RESEARCH_TOOLS_LIST_ERROR'
export const MARKET_RESEARCH_TOOLS_LIST_SUCCESS = 'MARKET_RESEARCH_TOOLS_LIST_SUCCESS'
export const MARKET_RESEARCH_TOOLS_LIST_APPEND_SUCCESS = 'MARKET_RESEARCH_TOOLS_LIST_APPEND_SUCCESS'

export const SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE_BOOL = 'SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE_BOOL'
export const SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE = 'SET_MARKET_RESEARCH_SEARCH_VALUE'
export const SET_MARKET_RESEARCH_SEARCH_FILTER_VALUES = 'SET_MARKET_RESEARCH_SEARCH_FILTER_VALUES'
export const REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUE = 'REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUE'
export const REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUES = 'REMOVE_MARKET_RESEARCH_SEARCH_FILTERS_VALUE'
export const RESET_MARKET_RESEARCH_SEARCH_FILTERS = 'RESET_MARKET_RESEARCH_SEARCH_FILTERS'

export const getContractsListing = (dispatch: Dispatch) => async (filters: IAwardSearchRecord) => {
  try {
    dispatch({
      type: MARKET_RESEARCH_TOOLS_LIST,
      payload: {
        key: 'expiringContracts'
      }
    })
    const res = await request<MarketContractsResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.contracts(), {
      schema: MarketContractsResponseModel,
      body: {
        ...filters,
        savedCompanies: [] // TODO: Handle implementation
      }
    })
    dispatch({
      type: MARKET_RESEARCH_TOOLS_LIST_SUCCESS,
      payload: {
        key: 'expiringContracts',
        data: {
          in3Month: res.data.data[0],
          in6Month: res.data.data[1],
          in9Month: res.data.data[2],
          in12Month: res.data.data[3]
        }
      }
    })
  }
  catch (e) {
    dispatch({
      type: MARKET_RESEARCH_TOOLS_LIST_ERROR,
      payload: {
        key: 'expiringContracts'
      }
    })
    throw e
  }
}

export const addRfpCategorizationsReport = async () => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Awards.report, {
    schema: BaseResponseModel,
  })
}

export const addContractsListing = (dispatch: Dispatch) => async (filters: IAwardSearchRecord, opts: AppendContractsOpts) => {
  const res = await request<MarketContractsResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.contracts(opts.range, opts.from), {
    schema: MarketContractsResponseModel,
    body: {
      ...filters,
      savedCompanies: []
    }
  })
  dispatch({
    type: MARKET_RESEARCH_TOOLS_LIST_APPEND_SUCCESS,
    payload: {
      key: 'expiringContracts',
      dataKey: opts.dataKey,
      data: res.data.data[0].items
    }
  })
}

export const setSearchFilterValueBool = (storeKey: string) => (dispatch: Dispatch) => (key: string, value?: boolean | string | number | string[] | number[]) => {
  dispatch({
    type: SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE_BOOL,
    payload: {
      key: storeKey,
      data: { key, value }
    }
  })
}

export const setSearchFilterValue = (storeKey: string) => (dispatch: Dispatch) => (key: string, value: number | string) => {
  console.log(storeKey, key, value)
  dispatch({
    type: SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE,
    payload: {
      key: storeKey,
      data: {key, value}
    }
  })
}

export const setSearchFilterValues = (storeKey: string) => (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  dispatch({
    type: SET_MARKET_RESEARCH_SEARCH_FILTER_VALUES,
    payload: {
      key: storeKey,
      data: {key, value}
    }
  })
}

export const removeSearchFilterValue = (storeKey: string) => (dispatch: Dispatch) => (key: string, value: string | number) => {
  dispatch({
    type: REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUE,
    payload: {
      key: storeKey,
      data: {key, value}
    }
  })
}

export const removeSearchFilterValues = (storeKey: string) => (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  dispatch({
    type: REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUES,
    payload: {
      key: storeKey,
      data: {key, value}
    }
  })
}

export const resetSearchFilters = (key: string) => (dispatch: Dispatch) => () => {
  dispatch({
    type: RESET_MARKET_RESEARCH_SEARCH_FILTERS,
    payload: {
      key
    }
  })
}

// expiringContracts

export const setExpiringContractsSearchFilterValueBool = setSearchFilterValueBool('expiringContracts')
export const setExpiringContractsSearchFilterValue = setSearchFilterValue('expiringContracts')
export const setExpiringContractsSearchFilterValues = setSearchFilterValues('expiringContracts')
export const removeExpiringContractsSearchFilterValue = removeSearchFilterValue('expiringContracts')
export const removeExpiringContractsSearchFilterValues = removeSearchFilterValues('expiringContracts')
export const resetExpiringContractsSearchFilters = resetSearchFilters('expiringContracts')