import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  REMOVE_COMPANY_SEARCH_FILTER_VALUE,
  REMOVE_COMPANY_SEARCH_FILTER_VALUES,
  RESET_COMPANY_SEARCH_FILTERS,
  SET_COMPANY_SEARCH_FILTER_KEYWORD,
  SET_COMPANY_SEARCH_FILTER_VALUE,
  SET_COMPANY_SEARCH_FILTER_VALUE_BOOL,
  SET_COMPANY_SEARCH_FILTER_VALUES
} from './actions'

export enum userIncumbentGroups {
  arlington = 'arlington',
  like = 'like',
  ifra = 'ifra'
}

export type groupTypes = userIncumbentGroups.arlington | userIncumbentGroups.like | userIncumbentGroups.ifra

export const useIncumbentGroupStrategy: { [key in groupTypes]: string } = {
  [userIncumbentGroups.ifra]: 'Ifra',
  [userIncumbentGroups.arlington]: 'Arglington',
  [userIncumbentGroups.like]: 'Liked'
}

export interface ICompanySearchRecord {
  keyword: string
  pscCodes: string[]
  naicsCodes: string[]
  customerCodes: string[]
  govConIds: number[]
  sizeIds: number[]
  stateCodes: string[]
  setAsideIds: number[]
  vehicleNames: string[]
  isDcaa?: boolean
  isEndingSoon?: boolean
}

export const CompanySearchFilters = Record<ICompanySearchRecord>({
  keyword: '',
  pscCodes: [],
  naicsCodes: [],
  customerCodes: [],
  govConIds: [],
  sizeIds: [],
  stateCodes: [],
  setAsideIds: [],
  vehicleNames: [],
  isDcaa: undefined,
  isEndingSoon: undefined
})

export const companySearchReducer = (
  state: Record<ICompanySearchRecord> = new CompanySearchFilters(),
  action: IAction) => {
  switch (action.type) {
    case SET_COMPANY_SEARCH_FILTER_KEYWORD:
      return state
        .set('keyword', action.payload.value)
    case SET_COMPANY_SEARCH_FILTER_VALUE_BOOL:
      return state
        .set(action.payload.key, action.payload.value)
    case SET_COMPANY_SEARCH_FILTER_VALUE:
      return state
        .update(action.payload.key, arr => [...arr, action.payload.value])
    case SET_COMPANY_SEARCH_FILTER_VALUES:
      return state
        .mergeIn([action.payload.key], action.payload.value)
    case REMOVE_COMPANY_SEARCH_FILTER_VALUE:
      return state
        .update(action.payload.key, arr => arr.filter((x: any) => x !== action.payload.value))
    case REMOVE_COMPANY_SEARCH_FILTER_VALUES:
      return state
        .update(action.payload.key, arr => arr.filter((x: any) => !action.payload.value.includes(x)))
    case RESET_COMPANY_SEARCH_FILTERS:
      return state
        .set('keyword', '')
        .set('pscCodes', [])
        .set('naicsCodes', [])
        .set('customerCodes', [])
        .set('govConIds', [])
        .set('sizeIds', [])
        .set('stateCodes', [])
        .set('setAsideIds', [])
        .set('vehicleNames', [])
        .set('isDcaa', undefined)
        .set('isEndingSoon', undefined)
    default:
      return state
  }
}
