import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { filterOptionsLoadedSelector, filterOptionsLoadingSelector } from 'store/hp/search/selectors'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { agenciesLoadedSelector } from 'store/hp/agency/selectors'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { getSearchOptions } from 'store/hp/search/actions'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import { getAgenciesAction } from 'store/hp/agency/actions'
import { getLocationsAction } from 'store/hp/location/action'
import { expiringContractsFiltersSelector } from 'store/hp/market-research-tools/selectors'
import { userActivity } from 'store/common/owner/actions'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'
import {
  addRfpCategorizationsReport,
  getContractsListing,
  removeExpiringContractsSearchFilterValue,
  removeExpiringContractsSearchFilterValues,
  setExpiringContractsSearchFilterValue,
  setExpiringContractsSearchFilterValueBool,
  setExpiringContractsSearchFilterValues
} from 'store/hp/market-research-tools/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { userSelector } from 'store/common/user/selectors'
import { getErrorMessage } from 'helpers/errors'
import { convertStringToKeywords } from 'helpers/search'
import NaicsFilter from './NaicsFilter'
import PscFilter from './PscFilter'
import DepartmentFilter from './DepartmentFilter'
import SetAsideFilter from './SetAsideFilter'
import LocationFilter from './LocationFilter'
import CompanyGroupOptions from '../../Filters/CompanyGroupOptions'
import { CompanyWithGroup } from 'models/hp/companies/companies.model'
import KeywordsFilterWithOptions from '../../Filters/KeywordsFilterWithOptions'

export interface IFilters {
  keywords: string[]
  customers: number[]
  naicsCodes: number[]
  pscCodes: number[]
  duns: string[]
  setAsides: number[]
  locations: number[]
}

const Filters = () => {
  const dispatch = useDispatch()
  const notifications = useRef(useNotification())
  const user = useSelector(userSelector)

  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector) as boolean
  const filterOptionsLoading = useSelector(filterOptionsLoadingSelector) as boolean
  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector) as boolean
  const agenciesLoaded = useSelector(agenciesLoadedSelector) as boolean
  const locationsLoaded = useSelector(locationsLoadedSelector) as boolean

  const selectedFilters = useSelector(expiringContractsFiltersSelector) as IAwardSearchRecord

  const handleChange = (key: keyof IFilters) => (id: number | string, state: boolean) => {
    if (state) {
      setExpiringContractsSearchFilterValue(dispatch)(key, id)
    } else {
      removeExpiringContractsSearchFilterValue(dispatch)(key, id)
    }
  }

  const handlePartialChange = (key: keyof IFilters) => (ids: Array<string | number>, state: boolean) => {
    if (state) {
      setExpiringContractsSearchFilterValues(dispatch)(key, ids)
    } else {
      removeExpiringContractsSearchFilterValues(dispatch)(key, ids)
    }
  }

  const handleKeywordsChange = (keywords?: string) => {
    setExpiringContractsSearchFilterValueBool(dispatch)(
      'keywords',
      convertStringToKeywords(keywords) || []
    )
  }

  const handleCompanyChange = (companies: CompanyWithGroup[]) => {
    const duns = companies.map(c => c.companyDuns)
    setExpiringContractsSearchFilterValueBool(dispatch)(
      'duns',
      duns
    )
  }

  const getListing = () => {
    getContractsListing(dispatch)(selectedFilters)
  }

  const sendReport = () => {
    addRfpCategorizationsReport()
      .then(() => {
        notifications.current.show('Request on emailing has been saved', NotificationTypeList.Success)
        userActivity.event(userActivity.activities.marketResearchToolContractsUsed)
      })
      .catch((e) => notifications.current.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  useEffect(() => {
    // Get all selectable options if not downloaded
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
    if (!groupedCodesLoaded) {
      getNaicsCodesAction(dispatch)()
      getPscCodesAction(dispatch)()
    }
    if (!agenciesLoaded) {
      getAgenciesAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }

    userActivity.event(userActivity.activities.marketResearchToolContracts)
  }, [])

  if (filterOptionsLoading) {
    return (
      <Paper className={styles['card']}>
        <Grid container justifyContent='center'>
          <CircularProgress color='primary' />
        </Grid>
      </Paper>
    )
  }

  return (
    <Paper className={styles['card']}>
      <Typography variant='h4' gutterBottom>
        Expiring contracts (by company)
      </Typography>
      <KeywordsFilterWithOptions
        keywords={selectedFilters['keywords']}
        handleChange={handleKeywordsChange}
      />
      <div style={{height: '16px'}} />
      <CompanyGroupOptions handleChange={handleCompanyChange}/>
      <div style={{height: '16px'}} />
      <PscFilter
        handleChange={handleChange('pscCodes')}
        handlePartialChange={handlePartialChange('pscCodes')}
        selectedOptions={selectedFilters['pscCodes']}
      />
      <div style={{height: '16px'}} />
      <NaicsFilter
        handleChange={handleChange('naicsCodes')}
        handlePartialChange={handlePartialChange('naicsCodes')}
        selectedOptions={selectedFilters['naicsCodes']}
      />
      <div style={{height: '16px'}} />
      <DepartmentFilter
        handleChange={handleChange('customers')}
        handlePartialChange={handlePartialChange('customers')}
        selectedOptions={selectedFilters['customers']}
      />
      <div style={{height: '16px'}} />
      <SetAsideFilter
        handleChange={handleChange('setAsides')}
        selectedOptions={selectedFilters['setAsides']}
      />
      <div style={{height: '16px'}} />
      <LocationFilter
        handleChange={handleChange('locations')}
        handlePartialChange={handlePartialChange('locations')}
        selectedOptions={selectedFilters['locations']}
      />
      <div style={{height: '16px'}} />
      <Button
        color='primary'
        variant='contained'
        fullWidth
        onClick={getListing}
      >
        Find expiring contracts
      </Button>
      <div style={{height: '16px'}} />
      {user && (
        <>
          <Button
            color='primary'
            variant='outlined'
            fullWidth
            onClick={sendReport}
          >
            Track and email expiring contracts
          </Button>
          <div style={{height: '16px'}} />
        </>
      )}
    </Paper>
  )
}

export default Filters