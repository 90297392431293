import React, { useEffect } from 'react'
import { Typography, Paper, Button, Grid, CircularProgress } from '@material-ui/core'
import PscFilter from './PscFilter'
import styles from './index.module.scss'
import NaicsFilter from './NaicsFitler'
import CustomerFilter from './CustomerFilter'
import GovConFilter from './GovConFilter'
import GovConSizeFilter from './GovConSizeFilter'
import { useDispatch, useSelector } from 'react-redux'
import { resetCompanySearchFilters } from 'store/hp/company-profiles/search/actions'
import CompanySearchBlock from '../SearchBlock'
import SetAsideFilter from './SetAsideFilter'
import StateFilter from './StateFilter'
import DccaFilter from './DccaFilter'
import EndingSoonFilter from './EndingSoonFilter'
import VehicleFilter from './VehicleFilter'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import {
  companyAgenciesLoadedSelector,
  companySetAsidesLoadedSelector,
  companyVehicleLoadedSelector,
  govConSizesLoadedSelector,
  govConsLoadedSelector
} from 'store/hp/company-profiles/options/selectors'
import {
  getCompanyGovConAction, getCompanyGovConSizeAction,
  getCompanyGroupedCustomersAction, getCompanySetAsideAction, getCompanyVehicleAction
} from 'store/hp/company-profiles/options/actions'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { getLocationsAction } from 'store/hp/location/action'

interface Props {
  setLoadWithoutDebounce: () => void
  count: number
  title?: string
}

const CompanySearchFilters = ({setLoadWithoutDebounce, count, title}: Props) => {

  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const companyAgenciesLoaded = useSelector(companyAgenciesLoadedSelector)
  const govConLoadedSelector = useSelector(govConsLoadedSelector)
  const govConSizeLoadedSelector = useSelector(govConSizesLoadedSelector)
  const setAsidesLoaded = useSelector(companySetAsidesLoadedSelector)
  const locationsLoaded = useSelector(locationsLoadedSelector)
  const vehicleLoaded = useSelector(companyVehicleLoadedSelector)

  const dispatch = useDispatch()

  const onReset = () => {
    resetCompanySearchFilters(dispatch)()
  }

  useEffect(() => {
    if (!groupedCodesLoaded) {
      getPscCodesAction(dispatch)()
      getNaicsCodesAction(dispatch)()
    }
    if (!companyAgenciesLoaded) {
      getCompanyGroupedCustomersAction(dispatch)()
    }
    if (!govConLoadedSelector) {
      getCompanyGovConAction(dispatch)()
    }
    if (!govConSizeLoadedSelector) {
      getCompanyGovConSizeAction(dispatch)()
    }
    if (!setAsidesLoaded) {
      getCompanySetAsideAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }
    if (!vehicleLoaded) {
      getCompanyVehicleAction(dispatch)()
    }
  }, [dispatch])

  return (
    <Paper className={styles['container']}>
      {groupedCodesLoaded && companyAgenciesLoaded && govConLoadedSelector &&
      govConSizeLoadedSelector && setAsidesLoaded && setAsidesLoaded &&
      locationsLoaded && vehicleLoaded ?
        <>
          <Typography variant='h4'>{title || `Company Filtering - ${count.toLocaleString()} matches`}</Typography>
          <div style={{height: '8px'}}/>
          <CompanySearchBlock
            onSearch={() => setLoadWithoutDebounce()}
          />
          <div style={{height: '16px'}}/>
          <PscFilter />
          <div style={{height: '16px'}}/>
          <NaicsFilter />
          <div style={{height: '16px'}}/>
          <CustomerFilter />
          <div style={{height: '16px'}}/>
          <GovConFilter />
          <div style={{height: '16px'}}/>
          <GovConSizeFilter />
          <div style={{height: '16px'}}/>
          <SetAsideFilter />
          <div style={{height: '16px'}}/>
          <StateFilter />
          <div style={{height: '16px'}}/>
          <DccaFilter />
          <div style={{height: '16px'}}/>
          <EndingSoonFilter />
          <div style={{height: '16px'}}/>
          <VehicleFilter />
          <div style={{height: '16px'}}/>
          <Button
            fullWidth
            variant='outlined'
            onClick={onReset}
          >
            Reset
          </Button>
        </>
        :
        <Grid container justifyContent='center' alignItems='center'>
          <CircularProgress/>
        </Grid>
      }
    </Paper>
  )
}

export default CompanySearchFilters
