import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pscCodeCountSelector, pscCodesSelector } from 'store/hp/grouped-code/selectors'
import {
  selectedPscOptions as selectedPscOptionsAction
} from 'store/hp/awards/search/selectors'
import {
  setAwardSearchFilterValue,
  setAwardSearchFilterValues,
  removeAwardSearchFilterValue,
  removeAwardSearchFilterValues
} from 'store/hp/awards/search/actions'
import { IFilterOption } from 'models/hp/search/search.interface'
import styles from './index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { searchPscCodesAction } from 'store/hp/grouped-code/actions'
import { FilterOption } from 'models/hp/search/search.model'
import { getChildrenIds } from 'helpers/filter-option'

let searchDebounceTimer: number | null = null

const PscFilter = () => {
  const dispatch = useDispatch()

  const pscCodesOptions: IFilterOption[] = useSelector(pscCodesSelector) as IFilterOption[]
  const selectedPscOptions: string[] = useSelector(selectedPscOptionsAction) as string[]
  const pscCount: number = useSelector(pscCodeCountSelector) as number
  const [options, setOptions] = React.useState<FilterOption[]>([])

  const handleChange = (id: number | string, state: boolean) => {
    if (state) {
      setAwardSearchFilterValue(dispatch)('pscCodes', id)
    } else {
      removeAwardSearchFilterValue(dispatch)('pscCodes', id)
    }
  }

  const handlePartialChange = (ids: Array<string | number>, state: boolean) => {
    if (state) {
      setAwardSearchFilterValues(dispatch)('pscCodes', ids)
    } else {
      removeAwardSearchFilterValues(dispatch)('pscCodes', ids)
    }
  }

  const loadOptions = async (value: string) => {
    const response = await searchPscCodesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const onSelect = (state: boolean, value: IFilterOption | null) => {
    if (value && value.sub?.length === 0) {
      handleChange(value.id, state)
    } else if (value && value.sub && value.sub.length > 0) {
      const childIds: number[] = []
      getChildrenIds(value.sub, (id: number) => {
        childIds.push(id)
      })
      handlePartialChange(childIds, state)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        searchLabel='Function search'
        allCount={pscCount}
        iconType='psc'
        filterTitle='R&D, SBIR, Product or Service'
        filterOptions={pscCodesOptions}
        selectedFilterOptions={selectedPscOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default PscFilter