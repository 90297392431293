import React, {useState} from 'react'
import {Button} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {userBaseResponseSelector} from 'store/common/user/selectors'
import {PLATFORM, STATUS} from 'constants/payment'
import {cancelSubscription} from 'store/common/user/actions'
import {useNotification} from 'context/NotificationContext'
import {NotificationTypeList} from '../NotificationType'
import CancelSubscriptionConfirmationModal from './CancelSubscriptionConfirmationModal'
import styles from './index.module.scss'


const CancelSubscriptionButton = () => {
  const user = useSelector(userBaseResponseSelector)
  const {show} = useNotification()

  const [isProcessing, setProcessing] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const [isCancelled, setIsCancelled] = useState(user.data?.paymentPlan?.status === STATUS.CANCELED)

  const handleModalConfirm = () => {
    setIsOpened(false)

    const transactionId = user.data?.paymentPlan?.transactionId
    const platform = user.data?.paymentPlan?.platform
    if (!transactionId) {
      return show('There is no active subscription', NotificationTypeList.Warning)
    }
    if (platform !== PLATFORM.PAYPAL) {
      return show(`Please, use ${platform} account to unsubscribe`, NotificationTypeList.Warning)
    }
    setProcessing(true)
    cancelSubscription(transactionId!)
      .then(() => {
        show('Subscription was cancelled successfully', NotificationTypeList.Success)
        setIsCancelled(true)
      })
      .catch(() => {
        show('Error happened while processing request, try again later', NotificationTypeList.Error)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <div className={styles['cancel-button-wrapper']}>
      <Button
        onClick={() => setIsOpened(true)}
        disabled={isCancelled || isProcessing}
        variant='contained'
        color='primary'
        className={styles['cancel-button']}
      >
        {isCancelled ? 'Subscription cancelled' : 'Cancel subscription'}
      </Button>
      {
        isOpened &&
        <CancelSubscriptionConfirmationModal
          onClose={() => setIsOpened(false)}
          onConfirm={handleModalConfirm}
        />
      }

    </div>
  )
}

export default CancelSubscriptionButton