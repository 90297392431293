import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acceptUserInvitationRequestInGroup, deleteUserInvitation, getUserGroupIvitations } from 'store/common/user-group/actons'
import { userGroupListLoadingSelector, userGroupRequest } from 'store/common/user-group/selectors'
import { userSelector } from 'store/common/user/selectors'
import styles from './index.module.scss'

const Requests = () => {
  const invitations = useSelector(userGroupRequest)
  const invitationsLoading = useSelector(userGroupListLoadingSelector)
  const user = useSelector(userSelector) as IUserBaseModel
  const dispatch = useDispatch()
  const notification = useNotification()

  const denyInvitation = (id: string) => {
    deleteUserInvitation(id).then(() => {
      getUserGroupIvitations(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  const approveInvitation = (id: string) => {
    acceptUserInvitationRequestInGroup(id).then(() => {
      getUserGroupIvitations(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  return (
    <>
      <Typography variant='h4'>Requests to join your team from</Typography>
      <Paper className={styles['user-group-paper']}>
        { !invitations.length && !invitationsLoading &&
            <Typography align='center' variant='subtitle1'>No invitations found.</Typography>
        }
        {
          invitationsLoading && 
          <Grid container alignItems='center' justifyContent='center'>
            <CircularProgress size={30}/>
          </Grid>
        }
        {
          invitations.length && !invitationsLoading ? (
            <Grid>
              {
                invitations.map(el => (
                  <Grid className={styles['user-item']} container alignItems='center' justifyContent='space-between' spacing={2} key={el.id}>
                    <Grid item md={6}>
                      <Typography variant='body2'>{el.invitedUser.firstName} {el.invitedUser.lastName}</Typography>
                      <Typography variant='body2'>{el.invitedUser.email}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                          <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={() => approveInvitation(el.id)}
                            disabled={user.userGroup?.role !== 'manager'}
                          >
                            Approve
                          </Button>
                        </Grid>
                        <Grid item >
                          <Button 
                            style={{background: 'orange'}} 
                            variant='contained' 
                            color='primary' 
                            onClick={() => denyInvitation(el.id)}
                            disabled={user.userGroup?.role !== 'manager'}
                          >
                            Deny
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
          ) : null
        }
        
      </Paper>
    </>
  )
}

export default Requests