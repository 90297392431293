import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getListOfGroupsUserBelongsTo, getUserPendingIvitations } from 'store/common/user-group/actons'
import MyGroups from './Groups'
import styles from './index.module.scss'
import Invitations from './Invitations'
import Requests from './Requests'

const Clients = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    getUserPendingIvitations(dispatch)()
    getListOfGroupsUserBelongsTo(dispatch)()
  }, [])


  return (
    <Grid container className={styles['clients-wrapper']} spacing={2}>
      <Grid item md={6}>
        <Requests />
      </Grid>
      <Grid item md={6}>
        <Invitations />
      </Grid>
      <Grid item md={6}>
        <MyGroups />
      </Grid>
    </Grid>
  )
}

export default Clients