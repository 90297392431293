import React from 'react'
import styles from './index.module.scss'
import { HpRfpModel } from 'models/hp/rfp/rfp.model'
import { Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { getFormattedTextDate } from 'helpers/date'

interface ContractItemProps {
    contract: HpRfpModel
}

const ContractItem = ({contract}: ContractItemProps) => {
  return (
    <div 
      className={styles['archive-item-contract-item']}>
      <div className={styles['archive-item-contract-item-right']}>
        <div className={styles['archive-item-contract-item-info']}>
          <SvgIcon width={24} height={24} name='contract' />
          <div className={styles['archive-item-contract-item-info-list']}>
            <Typography className={styles['archive-item-contract-item-info-name']}>{contract.title}</Typography>
            <Typography className={styles['archive-item-contract-item-info-agency']}>{contract.typeOfSetAside || '-'}</Typography>  
          </div>
        </div>
      </div>
      <div className={styles['archive-item-contract-item-left']}>
        <div className={styles['archive-item-contract-item-q']}>
          <Typography className={styles['archive-item-contract-item-q-title']}>Response deadline</Typography>
          <Typography className={styles['archive-item-contract-item-q-date']}>{getFormattedTextDate(new Date(contract.responseDeadLine), 'MMM DD YYYY')}</Typography>
        </div>
      </div>
      <div className={styles['archive-item-contract-item-left']}>
        <div className={styles['archive-item-contract-item-q']}>
          <Typography className={styles['archive-item-contract-item-q-title']}>Type</Typography>
          <Typography className={styles['archive-item-contract-item-q-date']}>{contract.type || '-'}</Typography>
        </div>
      </div>
      <div className={styles['archive-item-contract-item-left']}>
        <div className={styles['archive-item-contract-item-q']}>
          <Typography className={styles['archive-item-contract-item-q-title']}>Solicitation Number</Typography>
          <Typography className={styles['archive-item-contract-item-q-date']}>{contract.solicitationNumber || '-'}</Typography>
        </div>
      </div>
    </div>
  )
}

export default ContractItem