import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { FilterOption } from 'models/hp/search/search.model'
import { getChildrenIds } from 'helpers/filter-option'
import { AgencyModel } from 'models/hp/agencies/agencies.model'
import { agenciesSelector, agencyCountSelector } from 'store/hp/agency/selectors'
import { searchAgenciesAction } from 'store/hp/agency/actions'
import RecursiveItem, { isChecked } from 'components/v2/Filters/SearchFilter/RecursiveItem'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'
import { Autocomplete } from '@material-ui/lab'
import TextField from 'components/v2/TextField'
import styles from '../../index.module.scss'
import { IFilterOption } from 'models/hp/search/search.interface'

let searchDebounceTimer: number | null = null

interface Props {
  selectedCustomers: IFilterOption[]
  handleChange: (id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
}

const ServiceLineCustomerFilter = ({ selectedCustomers, handleChange, handlePartialChange }: Props) => {

  const [showMain, setShowMain] = React.useState<boolean>(false)
  const [showMapper, setShowMapperValue] = React.useState<{ [key: string]: boolean }>({})
  const [options, setOptions] = React.useState<Array<AgencyModel | FilterOption>>([])

  const agenciesOptions: AgencyModel[] = useSelector(agenciesSelector) as AgencyModel[]
  const agencyCount: number = useSelector(agencyCountSelector)!

  const loadOptions = async (value: string) => {
    const response = await searchAgenciesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const onSelect = (state: boolean, value: AgencyModel | IFilterOption | null) => {
    if (value?.id) {
      handleChange(value.id, state, value! as IFilterOption)
    } else if (value && value.sub && value.sub.length > 0) {
      const children: IFilterOption[] = []
      getChildrenIds(value.sub, (id: number, sub) => {
        children.push(sub!)
      })
      handlePartialChange(children.map(i => i.id), state, children)
    }
  }

  const renderItems = () => {
    return agenciesOptions.map(option => {
      const checked = isChecked(option.sub, selectedCustomers.map(i => i.id), 'id')
      return (<div className={styles['select-block-wrap']} key={option.departmentHP}>
        <div className={styles['select-block']}>
          <FormControlLabel
            className={styles['select-input']}
            control={
              <Checkbox
                checked={checked}
                onChange={() =>
                  handlePartialChange(option.sub.map(item => item.id), !checked, option.sub)
                }
                color='primary'
              />
            }
            label={option.departmentHP}
          />
          {option.sub && option.sub.length > 0 && (
            <Button
              className={showMapper[option.departmentHP] ? styles['show-btn-active'] : undefined}
              variant='text'
              disableRipple
              onClick={() => setShowMapperValue({
                ...showMapper,
                [option.departmentHP]: !showMapper[option.departmentHP]
              })}
            >
              <SvgIcon
                name='angle-down'
              />
            </Button>
          )}
        </div>
        {showMapper[option.departmentHP] && (
          <div className={styles['select-block-children']}>
            {option.sub.map(item => (
              <RecursiveItem
                key={item.id}
                id={item.id}
                text={item.text}
                option={item}
                sub={item.sub || []}
                handleChange={handleChange}
                handlePartialChange={() => {
                }}
                selected={selectedCustomers.map(i => i.id)}
              />
            ))}
          </div>
        )}
      </div>)
    })
  }

  return (
    <div className={styles['paper']}>
      <div className={styles['paper']}>
        <div className={styles['select-block']}>
          <FilterTitle
            title='Customers' iconType='department' allCount={agencyCount}
            selectedLength={selectedCustomers.length}/>
          {agenciesOptions && agenciesOptions.length > 0 && (
            <Button
              className={showMain ? styles['show-btn-active'] : undefined}
              variant='text'
              disableRipple
              onClick={() => setShowMain(!showMain)}
            >
              <SvgIcon
                name='angle-down'
              />
            </Button>
          )}
        </div>
        {showMain && (
          <div className={styles['select-block-children']}>
            <Autocomplete
              id='some-autocomplete'
              style={{ margin: '15px 0' }}
              options={options}
              filterOptions={options => options}
              value={null}
              getOptionLabel={(option) => option?.text || ''}
              getOptionSelected={(option) => !!selectedCustomers.filter(val => !option?.sub?.map(i => i.id).includes(val.id as number))}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='filled'
                  label='Customer search'
                  onChange={onSearch}
                />
              )}
              renderOption={(option) => {
                const selected = option.id ? selectedCustomers.map(i => i.id).includes(option.id) : isChecked(option?.sub || [], selectedCustomers.map(i => i.id), 'id')
                return (
                  <div style={{ width: '100%' }} onClick={() => onSelect(!selected, option)}>
                    <FormControlLabel
                      key={option.text}
                      control={
                        <Checkbox
                          checked={selected}
                          color='primary'
                        />
                      }
                      label={option.text}
                    />
                  </div>
                )
              }}
            />
            {renderItems()}
          </div>
        )}
      </div>
    </div>
  )
}

export default ServiceLineCustomerFilter
