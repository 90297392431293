import { request } from 'utils/http'
import { BaseResponseModel } from 'models/base/base.model'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { CardTypes } from 'models/hp/pipeline-task-card/pipeline-task-card.interface'
import { Dispatch } from 'redux'
import { IPipelineCard, IPiplineCardsResponseModel, PipelineTaskStage, pipelineStageParser } from 'models/hp/pipeline/pipeline.interface'
import { PiplineCardsResponseModel } from 'models/hp/pipeline/pipeline.model'
import { IBaseResponseModel } from 'models/base/base.interfaces'

export const GET_CARDS_LIST = 'GET_CARDS_LIST'
export const GET_CARDS_LIST_SUCCESS = 'GET_CARDS_LIST_SUCCESS'
export const GET_CARDS_LIST_ERROR = 'GET_CARDS_LIST_ERROR'

export const UPDATE_TASKS = 'UPDATE_TASKS'

export const setCardSignOff = (rfpId: string, type: CardTypes) => {
  return request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.PipelineTaskCard.signOff(rfpId, type), {
    schema: BaseResponseModel
  })
}

export const getTaskCardsList = (dispatch: Dispatch) => async (rfpId: string) => {
  try{
    dispatch({
      type: GET_CARDS_LIST,
    })
    const res = await  request<IPiplineCardsResponseModel>(RequestMethods.Get, Paths.Hp.PipelineTaskCard.cardsList(rfpId), {
      schema: PiplineCardsResponseModel
    })
    dispatch({
      type: GET_CARDS_LIST_SUCCESS,
      payload: res.data.data
    })
  } catch(e) {
    dispatch({
      type: GET_CARDS_LIST_ERROR,
      payload: e
    })
  }
}

export const updateTask = (dispatch: Dispatch) => async (rfpId: string, task: IPipelineCard<string>) => {
  dispatch({
    type: UPDATE_TASKS,
    payload: task
  })
  await request<IBaseResponseModel>(RequestMethods.Put, Paths.Hp.PipelineTaskCard.changeStage(rfpId, task.id), {
    schema: BaseResponseModel,
    body: {stage: pipelineStageParser[task.stage as PipelineTaskStage]}
  })
}