import React, { ChangeEvent, useCallback } from 'react'
import styles from './index.module.scss'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import ServiceLineRow from './components/Row'
import { createFulfilledSearch, createSearch, deleteSearch, getSearchesByType } from 'store/hp/search/actions'
import { getMarketSetAsides, setMarketSetAsides } from 'store/hp/market-research/actions'
import { SearchModel } from 'models/hp/search/search.model'
import Modal from 'components/v2/Modal'
import TextField from 'components/v2/TextField'
import ServiceLineSetAside from './components/SetAside'

const ServiceLine = () => {
  const history = useHistory()

  const [serviceLines, setServiceLines] = React.useState<SearchModel[]>([])
  const [newServiceLine, setNewServiceLine] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>('')
  const [selectedSetAsides, setSelectedSetAsides] = React.useState<number[]>([])
  const [processing, setProcessing] = React.useState(false)

  const onAddSearch = async () => {
    const createdSearch = await createSearch({ name, type: 'marketResearch', contractType: 'contract opportunities' })
    setServiceLines(prev => [...prev, { ...new SearchModel(), id: createdSearch.id, name: createdSearch.name }])
    setName('')
    setNewServiceLine(false)
  }

  const onDuplicate = async (search: SearchModel, name: string) => {
    const res = await createFulfilledSearch()({
      ...search,
      type: 'marketResearch',
      setAsideIds: selectedSetAsides
    }, name)
    setServiceLines(prev => [...prev, { ...search, name: res!.name!, id: res!.id!, type: 'marketResearch' }])
  }

  const onDelete = async (id: number) => {
    await deleteSearch()(id)
    setServiceLines(prev => prev.filter(i => i.id !== id))
  }

  const getAllMarketSearches = useCallback(async () => {
    const response = await getSearchesByType()
    setServiceLines(response)
  }, [setServiceLines])

  const handleSelectedSetAsides = async (id: number) => {
    setProcessing(prev => !prev)
    const idx = selectedSetAsides.findIndex(i => i === id)
    if (idx > -1) {
      const result = selectedSetAsides.filter(x => x !== id)
      await setMarketSetAsides(result)
      setSelectedSetAsides(result)
    } else {
      await setMarketSetAsides([...selectedSetAsides, id])
      setSelectedSetAsides(prev => [...prev, id])
    }
    setProcessing(prev => !prev)
  }

  React.useEffect(() => {
    (async () => {
      const selectedSetAsides = await getMarketSetAsides()
      setSelectedSetAsides(selectedSetAsides)
    })()
  }, [])

  React.useEffect(() => {
    getAllMarketSearches()
  }, [getAllMarketSearches])

  return (
    <div className={styles['service-line']}>
      <ServiceLineSetAside
        selectedSetAsides={selectedSetAsides} handleSelectedSetAsides={handleSelectedSetAsides}
        processing={processing}/>
      <div style={{ height: '24px' }}/>
      <Typography variant='h3' gutterBottom>Product/Service lines(limit: 3):</Typography>
      {
        serviceLines.map((search, idx) => (
          <ServiceLineRow
            count={serviceLines.length} onDuplicate={onDuplicate} onDelete={onDelete} key={idx}
            search={search}/>
        ))
      }
      {serviceLines.length < 3 &&
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/user/market-research')}
      >
        Add new
      </Button>
      }
      {newServiceLine &&
      <Modal
        title='What would you like to name this Product/Service Line?'
        onConfirm={onAddSearch}
        onClose={() => setNewServiceLine(false)}
        open={newServiceLine}
        confirmLoading={!name}
      >
        <TextField
          label='Service line name'
          variant='filled'
          value={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value || '')}
          fullWidth
        />
      </Modal>
      }
    </div>
  )
}

export default ServiceLine
