import { Record } from 'immutable'
import {
  CompanyProfilePreviewModel,
  CompanyProfilePreviewModelResponse, CompanyProfileRankedResponseModel
} from 'models/hp/company-profiles/base/company-profile.model'
import { IAction } from 'types/common'
import {
  COMPANY_PROFILE_FILTER,
  COMPANY_PROFILE_LIST,
  COMPANY_PROFILE_LIST_SUCCESS,
  COMPANY_PROFILE_LIST_ERROR,
  COMPANY_PROFILE_LIST_APPEND_SUCCESS,
  COMPANY_PROFILE_LIST_LOADED,
  COMPANY_LIKE_ACTION,
  COMPANY_RANKED_LIST,
  COMPANY_PROFILE_LIST_RESET
} from './actions'

export interface ICompanyProfileParams {
  page: number
  limit: number
}

export interface ICompanyProfileListing {
  listing: {
    data: CompanyProfilePreviewModelResponse
    loading: boolean
    loaded: boolean
    params: ICompanyProfileParams
  },
  ranked: CompanyProfileRankedResponseModel
}

const defaultListing = {
  data: {...new CompanyProfilePreviewModelResponse()},
  params: {limit: 50, page: 0},
  loading: false,
  loaded: false,
  count: 0
}

const CompanyProfilesWithFilter = Record<ICompanyProfileListing>({
  listing: {...defaultListing},
  ranked: {...new CompanyProfileRankedResponseModel()}
})

export const companyListingReducer = (state: Record<ICompanyProfileListing> = new CompanyProfilesWithFilter(), action: IAction) => {
  switch (action.type) {
    case COMPANY_PROFILE_LIST:
      return state
        .setIn(['listing', 'loading'], true)
    case COMPANY_PROFILE_LIST_ERROR:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], false)
    case COMPANY_PROFILE_LIST_SUCCESS:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], true)
        .setIn(['listing', 'data', 'data'], action.payload?.data || [])
        .setIn(['listing', 'data', 'count'], action.payload?.count || 0)
    case COMPANY_PROFILE_LIST_LOADED:
      return state
        .setIn(['listing', 'loaded'], action.payload)
    case COMPANY_PROFILE_LIST_APPEND_SUCCESS:
      return state
        .updateIn(['listing', 'data', 'data'], (data: any) => data.concat(action.payload?.data || []))
    case COMPANY_PROFILE_FILTER:
      return state
        .setIn(['listing', 'params'], action.payload || state.getIn(['listing', 'params']))
    case COMPANY_LIKE_ACTION:
      return state
        .updateIn(['listing', 'data'],
          (item: any) => ({
            ...item,
            data: item.data.filter((item: CompanyProfilePreviewModel) => item.duns !== action.payload.duns),
            count: item.count - 1
          }))
    case COMPANY_RANKED_LIST:
      return state
        .set('ranked', action.payload || state.get('ranked'))

    case COMPANY_PROFILE_LIST_RESET:
      return state
        .set('listing', defaultListing)
        .set('ranked', {...new CompanyProfileRankedResponseModel()})

    default:
      return state
  }
}
