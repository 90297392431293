import React from 'react'
import { FilledTextFieldProps, TextField as MuiTextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useFilledStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    borderRadius: '12px'
  },
  focused: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    borderColor: `${theme.palette.primary.main} !important`
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    borderColor: theme.palette.error.main
  }
}))

const useStandardStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    borderBottom: `1px solid ${theme.palette.grey['400']}`
  },
  focused: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
  }
}))

const useLabelStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Poppins'
  }
}))

interface Props extends FilledTextFieldProps {
  variant: any
}

const TextField = (props: Props) => {
  const filledClasses = useFilledStyles()
  const standardClasses = useStandardStyles()
  const labelClasses = useLabelStyles()

  return (
    <MuiTextField
      {...props}
      variant={props.variant}
      InputProps={{
        ...props.InputProps,
        classes: props.variant === 'standard' ? standardClasses : filledClasses,
      }}
      InputLabelProps={{
        classes: labelClasses
      }}
      FormHelperTextProps={{
        classes: labelClasses
      }}
    />
  )
}

export default TextField