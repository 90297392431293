import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { ISignUpForm, SIGN_UP_FORM_INIT_VALUE, SIGN_UP_FORM_VALIDATION_SCHEMA } from './components/Form'
import { userSignUpAction } from 'store/common/user/actions'
import { useNotification } from 'context/NotificationContext'
import FormRender from './components/FormRenderer'
import { NotificationTypeList } from 'components/NotificationType'
import PublicEmailModal from './components/PublicEmailModal'

let timeoutId: number | undefined
const SignUp = () => {
  const history = useHistory()
  const [ isRequested, setIsRequested ] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const { show } = useNotification()

  const onSubmit = async (values: ISignUpForm) => {
    setIsRequested(true)
    try {
      const response = await userSignUpAction(values)
      setIsRequested(false)
      response.message && show(response.message, NotificationTypeList.Info)
      history.push({ pathname: `/auth/verify/${values.email}`, search: history.location.search })
    } catch (error) {
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
      setIsRequested(false)
    }
  }

  useEffect(() => {
    timeoutId = window.setTimeout(() => {
      setModalOpened(true)
    }, 2000)

    return () => { timeoutId && window.clearTimeout(timeoutId) }
  }, [])

  return (
    <>
      <Formik
        validateOnMount={SIGN_UP_FORM_VALIDATION_SCHEMA.isValidSync(SIGN_UP_FORM_INIT_VALUE)}
        initialValues={SIGN_UP_FORM_INIT_VALUE}
        validationSchema={SIGN_UP_FORM_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formProps: FormikProps<ISignUpForm>) => (
          <FormRender isRequested={isRequested} {...formProps}/>
        )}
      </Formik>

      <PublicEmailModal
        onClose={() => setModalOpened(false)}
        open={modalOpened}
        title=''
      />
    </>
  )
}

export default SignUp
