import React from 'react'
import Opportunities from './pages/Opportunities'

export const pagesData = () => [
  {
    id: 3,
    component: <Opportunities />,
    path: 'opportunities',
    title: 'Opportunities',
    icon: 'opportunities',
    protected: false
  },
]
