import React from 'react'
import { CompanyProfileWithRankedModel } from 'models/hp/company-profiles/base/company-profile.model'
import CompanyListItem from '../CompanyListItem'

interface Props {
  rankItem: CompanyProfileWithRankedModel
  onClick: () => void
}

const RankingItem = ({ rankItem, onClick }: Props) => {
  return (
    <CompanyListItem marginBottom='6px' onClick={onClick} company={rankItem}/>
  )
}

export default RankingItem
