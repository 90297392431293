import React from 'react'
import styles from './index.module.scss'
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { createUserGroupIvitationToJoin, deleteUserSelfInvitation, getUserPendingIvitations } from 'store/common/user-group/actons'
import { userGroupInvitationsRequestPending, userGroupListLoadingSelector } from 'store/common/user-group/selectors'
import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import TextField from 'components/v2/TextField'
import SvgIcon from 'components/SvgIcon'

interface RequestsForm {
  email: string
}

const Requests = () => {
  const theme = useTheme()
  const invitationsPending = useSelector(userGroupInvitationsRequestPending)
  const invitationsLoading = useSelector(userGroupListLoadingSelector)
  const dispatch = useDispatch()
  const notification = useNotification()

  const onSubmit = ({email}: RequestsForm, {resetForm}: FormikHelpers<RequestsForm>) => {
    createUserGroupIvitationToJoin(email).then(() => {
      resetForm()
      getUserPendingIvitations(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
      resetForm()
    })
  }

  const removeRequest = (id:string) => {
    deleteUserSelfInvitation(id).then(() => {
      getUserPendingIvitations(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  return (
    <>
      <Typography variant='h4'>Request access</Typography>
      <Paper className={styles['clients-paper']}>
        <Formik 
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required(ERROR_MESSAGES.Field.Required('Email'))
              .email(ERROR_MESSAGES.Field.NotValid('Email')),
          })}
          onSubmit={onSubmit}
          initialErrors={{
            email: ''
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            errors,
            isValid,
            isSubmitting,
          }: FormikProps<RequestsForm>) =>  (
            <Grid container spacing={2} alignItems='center' className={styles['clients-request-form']}>
              <Grid item md={9}>
                <TextField
                  onBlur={handleBlur('email')}
                  error={!!touched.email && !!errors.email}
                  helperText={!!touched.email && !!errors.email && errors.email}
                  value={values.email}
                  onChange={handleChange('email')}
                  className={styles['field']}
                  label='Email'
                  fullWidth
                  variant='filled'
                />
              </Grid>
              <Grid item md={3}>
                <Button   
                  variant='contained' 
                  color='primary'
                  fullWidth
                  disabled={!isValid || isSubmitting} 
                  onClick={() => handleSubmit()}
                >
                    Send
                </Button>
              </Grid>
            </Grid>
          )
          }
        </Formik>
        {
          invitationsPending.length && !invitationsLoading ? (
            <Grid>
              {invitationsPending.map(el => (
                <Grid container key={el.id} className={styles['clients-item']} justifyContent='space-between' alignItems='center'>
                  <Grid item md={10}>
                    <Typography variant='body2'>{el.user.firstName} {el.user.lastName}</Typography>
                    <Typography variant='body2'>{el.user.email}</Typography>
                  </Grid>
                  <Grid>
                    <Button onClick={() => removeRequest(el.id)}>
                      <SvgIcon name='multiply' width={18} height={18} color={theme.palette.grey['500']}/>
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : null
        }
        { !invitationsPending.length && !invitationsLoading &&
            <Typography align='center' variant='subtitle1'>No requests found.</Typography>
        }
        {
          invitationsLoading && 
          <Grid className={styles['clients-loader-wrapper']} container alignItems='center' justifyContent='center'>
            <CircularProgress size={30}/>
          </Grid>
        }
      </Paper>
    </>
  )
}

export default Requests