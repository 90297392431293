import React from 'react'
import styles from '../index.module.scss'
import { HpMarketResearchRfp } from 'models/hp/rfp/rfp.model'
import { Typography, Paper, Grid, useTheme, Checkbox, CircularProgress } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import ListingHints from './ListingHints'

interface SortedListingItem {
  keyword: string
  items: HpMarketResearchRfp[]
}

interface Props {
  listing: HpMarketResearchRfp[]
  keywords: string[]
  handleSetKeywords: (keyword: string, state: boolean) => void
  loading: boolean
}

const Listing = ({listing, keywords, handleSetKeywords, loading}: Props) => {
  const theme = useTheme()

  const [hintsOpened, setHintsOpened] = React.useState(true)

  const sortListing = (listing: HpMarketResearchRfp[]): SortedListingItem[] => {
    const map: { [key: string]: number } = {}
    const sortedListing: SortedListingItem[] = []
    let idx = 0
    for (let item of listing) {
      const index = map[item.keyword!]
      if (typeof index === 'undefined') {
        map[item.keyword!] = idx
        sortedListing[idx] = {
          keyword: item.keyword!,
          items: [item]
        }
        idx++
      } else {
        sortedListing[index].items.push(item)
      }
    }
    return sortedListing
  }

  if (loading) {
    return (
      <Grid container className={styles['list']} alignItems='center' justifyContent='center'>
        <CircularProgress size={32} color='primary'/>
      </Grid>
    )
  }

  if (hintsOpened && listing.length === 0) {
    return (
      <ListingHints close={() => setHintsOpened(false)} />
    )
  }

  return (
    <Grid container className={styles['list']} spacing={1}>
      {sortListing(listing).map((sortedItem: SortedListingItem, idx) => (
        <Grid item md={12} key={idx}>
          <Paper className={styles['paper']}>
            <Grid container alignItems='center'>
              <Checkbox
                color='primary'
                checked={keywords.includes(sortedItem.keyword)}
                onChange={() => handleSetKeywords(sortedItem.keyword, !keywords.includes(sortedItem.keyword))}
              />
              <Typography variant='h5'>Keyword: "{sortedItem.keyword}"</Typography>
            </Grid>
            <Grid container style={{paddingLeft: '16px'}} spacing={1}>
              {sortedItem.items.map(item => (
                <Grid item md={12} key={item.id}>
                  <Grid container spacing={1}>
                    <Grid item style={{width: '24px'}}>
                      <SvgIcon name='arrow-right' color={theme.palette.warning.main} width={24} height={24} />
                    </Grid>
                    <Grid item>
                      <Typography variant='h5'>{item.title}</Typography>
                      <Typography>NAICS: {item.naicsName} ({item.naicsCode})</Typography>
                      <Typography>PSC: {item.classificationName} ({item.classificationCode})</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(Listing)