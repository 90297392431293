import React  from 'react'
import styles from 'modules/Main/Education/pages/main/phases/index.module.scss'
import { Grid, Typography } from '@material-ui/core'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import DropdownItem from 'components/DropdownItem'
import { PitchingDualVcInvestors } from 'utils/constants/education-dual-vc'

interface Props {
  selectedSubtitle: IEducationPhaseClass
  onSelectSubtitle: (subtitle: IEducationPhaseClass) => void
}

const DualVcPitchingInvestors = ({onSelectSubtitle, selectedSubtitle}: Props) => {
  return (
    <div className={styles['classes_exercises']}>
      <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
        <Typography variant={'h4'} color={'inherit'}>Pitching Dual-Use Investors</Typography>
      </Grid>
      {PitchingDualVcInvestors.map((phase: IEducationPhaseClass) => {
        return (
          <DropdownItem
            key={phase.name}
            currentSubtitle={selectedSubtitle}
            subtitle={phase}
            onSelectSubtitle={onSelectSubtitle}
          />
        )
      })}
    </div>
  )
}

export default DualVcPitchingInvestors