import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  SEARCH_AGENCIES, SEARCH_CLEAR,
  SEARCH_CONTRACT_TYPES,
  SEARCH_GROUPED_CODES,
  SEARCH_ID_NAME_KEYWORD,
  SEARCH_LOCATIONS,
  SEARCH_PROJECT_TYPES, SEARCH_SET_ASIDES
} from './action'

export enum LocationType {
  Conus = 'CONUS',
  Oconus = 'OCONUS',
  Specific = 'SPECIFIC'
}

export type locationTypes = LocationType.Conus | LocationType.Oconus | LocationType.Specific

export interface ILocationFormData {
  type: locationTypes | undefined
  data: { [key: string]: number[] }
}

export interface IGroupCodeData {
  subCodeIds: number[]
  pscCodeIds: number[]
  totalLen: number
}

export interface IGroupedAgenciesForm {
  data: { [key: string]: number[] }
  all: boolean
}

export interface ISearchCreateForm {
  id: number | undefined
  name: string
  keywords: string
  contractTypeIds: number[]
  groupTypes: {
    data: { [key: string]: IGroupCodeData }
    all: boolean
  }
  projectTypeIds: number[]
  agencies: IGroupedAgenciesForm,
  locations: ILocationFormData,
  setAsideIds: number[]
}

const SearchFormRecord = Record<ISearchCreateForm>({
  id: undefined,
  name: '',
  keywords: '',
  contractTypeIds: [],
  groupTypes: {
    data: {},
    all: false
  },
  projectTypeIds: [],
  agencies: { data: {}, all: false },
  locations: {
    type: undefined,
    data: {}
  },
  setAsideIds: []
})

export const searchCreateFormReducer = (state: Record<ISearchCreateForm> = SearchFormRecord(), action: IAction<ISearchCreateForm>) => {

  switch (action.type) {
    case SEARCH_ID_NAME_KEYWORD:
      return state
        .set('id', action.payload?.id || undefined)
        .set('name', action.payload?.name || '')
        .set('keywords', action.payload?.keywords || '')
    case SEARCH_CONTRACT_TYPES:
      return state
        .set('contractTypeIds', action.payload?.contractTypeIds || [])
    case SEARCH_GROUPED_CODES:
      return state
        .set('groupTypes', action.payload?.groupTypes || { data: {}, all: false })
    case SEARCH_PROJECT_TYPES:
      return state
        .set('projectTypeIds', action.payload?.projectTypeIds || [])
    case SEARCH_AGENCIES:
      return state
        .set('agencies', action.payload?.agencies || state.get('agencies'))
    case SEARCH_LOCATIONS:
      return state
        .set('locations', action.payload?.locations || state.get('locations'))
    case SEARCH_SET_ASIDES:
      return state
        .set('setAsideIds', action.payload?.setAsideIds || state.get('setAsideIds'))
    case SEARCH_CLEAR:
      return state.clear()
    default:
      return state
  }

}