import React from 'react'
import { Divider as MuiDivider, DividerProps, useTheme } from '@material-ui/core'

interface Props extends DividerProps {
  gutterBottom?: boolean
  gutterTop?: boolean
  dark?: boolean
}

const Divider = ({gutterBottom, gutterTop, dark, ...props }: Props) => {
  const theme = useTheme()
  return (
    <MuiDivider
      {...props}
      style={{
        backgroundColor: theme.palette.grey['200'],
        marginBottom: gutterBottom ? '10px' : undefined,
        marginTop: gutterTop ? '10px' : undefined,
        background: dark ? theme.palette.grey['400'] : ''
      }}
    />
  )
}

export default Divider