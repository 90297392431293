import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  AWARD_LIST,
  AWARD_LIST_SUCCESS,
  AWARD_LIST_ERROR,
  AWARD_LIST_APPEND_SUCCESS,
  AWARD_LIST_RESET,
  AWARD_LIST_APPEND,
  AWARD_LIST_APPEND_ERROR,
  AWARD_LIST_UPDATE_PAGINATION,
  AWARD_LIST_CATEGORIZE,
  AWARD_LIST_CATEGORIZE_REMOVE,
  GET_AWARD_DETAILS,
  GET_AWARD_DETAILS_SUCCESS,
  GET_AWARD_DETAILS_ERROR,
  GET_AWARD_TRANSACTIONS,
  GET_AWARD_TRANSACTIONS_SUCCESS,
  GET_SPENDING_CHART,
  GET_SPENDING_CHART_SUCCESS,
  GET_AWARD_SIZE,
  GET_AWARD_SIZE_ERROR,
  GET_AWARD_SIZE_SUCCESS,
  GET_TRANSITION,
  GET_TRANSITION_SUCCESS,
  GET_TRANSITION_ERROR,
  GET_SUMMARY_OPTIONS,
  GET_SUMMARY_OPTIONS_SUCCESS,
  GET_SUMMARY_OPTIONS_ERROR,
  GET_SUMMARY,
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_ERROR,
  SET_SUMMARY,
  SET_SUMMARY_SUCCESS,
  UPDATE_SUMMARY,
  GET_MARKET_SIZE,
  GET_MARKET_SIZE_SUCCESS,
  GET_MARKET_SIZE_ERROR,
  GET_TRANSITION_MARKET,
  GET_TRANSITION_MARKET_SUCCESS,
  GET_TRANSITION_MARKET_ERROR,
  GET_TRANSITION_MARKET_OVERVIEW,
  GET_TRANSITION_MARKET_OVERVIEW_SUCCESS,
  GET_TRANSITION_MARKET_OVERVIEW_ERROR,
  GET_TRANSITION_MARKET_USG,
  GET_TRANSITION_MARKET_USG_SUCCESS,
  GET_TRANSITION_MARKET_USG_ERROR,
  GET_GOV_CONTRACTS_OVERVIEW,
  GET_GOV_CONTRACTS_OVERVIEW_SUCCESS,
  GET_GOV_CONTRACTS_OVERVIEW_ERROR,
  GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE,
  GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_SUCCESS,
  GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_ERROR,
  RESET_AWARD
} from './actions'
import { mapCategorize } from '../reducer-helper'
import { 
  AwardDetailsItem,
  AwardListResponseModel,
  AwardSizeModel, GovContractsModel, SpendingModel,
  SummaryModel,
  SummaryOptionsModel,
  Transaction,
  TransitionMarketOptionModel,
  TransitionMarketOverviewByTypeModel,
  TransitionMarketUSGMarketModel,
  TransitionModel
} from 'models/hp/awards/awards.model'
import { Pagination } from 'models/base/base.interfaces'

export interface IAwardsListing {
  data: AwardListResponseModel
  loading: boolean
  appending: boolean
  loaded: boolean
  params: Pagination
  details: {
    data: AwardDetailsItem
    loaded: boolean
    loading: boolean
  }
  transactions: {
    data: Transaction[]
    loading: boolean
    loaded: boolean
  },
  spending: {
    data: SpendingModel
    loading: boolean
    loaded: boolean
  }
  profit: {
    awardSize: {
      data: AwardSizeModel[]
      loading: boolean
      loaded: boolean
    },
    transition: {
      data: TransitionModel[]
      loading: boolean
      loaded: boolean
    },
    marketSize: {
      data: TransitionModel[]
      loading: boolean
      loaded: boolean
    },
    summaryOptions: {
      data: SummaryOptionsModel
      loading: boolean
      loaded: boolean
    },
    summary: {
      data: SummaryModel
      loading: boolean
      loaded: boolean
    }
  },
  transition: {
    options: {
      data: TransitionMarketOptionModel[]
      loading: boolean
      loaded: boolean
    },
    overview: {
      data: TransitionModel[]
      loading: boolean
      loaded: boolean
    },
    overviewByType: {
      data: TransitionMarketOverviewByTypeModel
      loading: boolean
      loaded: boolean
    },
    USGMarket: {
      data: TransitionMarketUSGMarketModel
      loading: boolean
      loaded: boolean
    }
  },
  govContracts: {
    overview: {
      data: GovContractsModel,
      loading: boolean,
      loaded: boolean
    }
  }
}

const AwardsListing = Record<IAwardsListing>({
  data: new AwardListResponseModel(),
  params: {limit: 20, page: 0},
  loading: false,
  loaded: false,
  appending: false,
  details: {
    data: new AwardDetailsItem(),
    loaded: false,
    loading: false
  },
  transactions: {
    data: [],
    loading: false,
    loaded: false
  },
  spending: {
    data: new SpendingModel(),
    loaded: false,
    loading: false
  },
  profit: {
    awardSize: {
      data: [],
      loading: false,
      loaded: false
    },
    transition: {
      data: [],
      loading: false,
      loaded: false
    },
    marketSize: {
      data: [],
      loading: false,
      loaded: false
    },
    summaryOptions: {
      data: new SummaryOptionsModel(),
      loading: false,
      loaded: false
    },
    summary: {
      data: new SummaryModel(),
      loading: false,
      loaded: false
    }
  },
  transition: {
    options: {
      data: [],
      loading: false,
      loaded: false
    },
    overview: {
      data: [],
      loading: false,
      loaded: false
    },
    overviewByType: {
      data: new TransitionMarketOverviewByTypeModel(),
      loading: false,
      loaded: false
    },
    USGMarket: {
      data: new TransitionMarketUSGMarketModel(),
      loading: false,
      loaded: false
    }
  },
  govContracts: {
    overview: {
      data: new GovContractsModel(),
      loading: false,
      loaded: false
    }
  }
})

export const awardsListingReducer = (state: Record<IAwardsListing> = new AwardsListing(), action: IAction) => {
  switch (action.type) {
    case AWARD_LIST:
      return state
        .set('loading', true)

    case AWARD_LIST_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)

    case AWARD_LIST_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)

    case AWARD_LIST_APPEND:
      return state
        .set('appending', true)

    case AWARD_LIST_APPEND_ERROR:
      return state
        .set('appending', false)

    case AWARD_LIST_APPEND_SUCCESS:
      return state
        .set('appending', false)
        .update('data', (fields: AwardListResponseModel) => ({
          ...fields,
          data: fields.data.concat(action.payload.data)
        }))
    case AWARD_LIST_UPDATE_PAGINATION:
      return state
        .set('params', action.payload)
    case AWARD_LIST_CATEGORIZE:
      return state
        .update('data', content => ({
          ...content,
          data: content.data.map(item => mapCategorize(item, action.payload.id, true))
        }))
    case AWARD_LIST_CATEGORIZE_REMOVE:
      return state
        .update('data', content => ({
          ...content,
          data: content.data.map(item => mapCategorize(item, action.payload.id, false))
        }))
    case AWARD_LIST_RESET:
      return new AwardsListing()
    case GET_AWARD_DETAILS:
      return state
        .setIn(['details', ['loading']], true)
    case GET_AWARD_DETAILS_SUCCESS:
      return state
        .setIn(['details', ['loading']], false)
        .setIn(['details', ['loaded']], true)
        .setIn(['details', ['data']], action.payload)
    case GET_AWARD_DETAILS_ERROR:
      return state
        .setIn(['details', ['loading']], false)
        .setIn(['details', ['loaded']], false)
    case GET_AWARD_TRANSACTIONS:
      return state
        .setIn(['transactions', ['loading']], true)
    case GET_AWARD_TRANSACTIONS_SUCCESS:
      return state
        .setIn(['transactions', ['loading']], false)
        .setIn(['transactions', ['loaded']], true)
        .setIn(['transactions', ['data']], action.payload)
    case GET_SPENDING_CHART:
      return state
        .setIn(['spending', ['loading']], true)
    case GET_SPENDING_CHART_SUCCESS:
      return state
        .setIn(['spending', ['loading']], false)
        .setIn(['spending', ['loaded']], true)
        .setIn(['spending', ['data']], action.payload)
    case GET_AWARD_SIZE:
      return state
        .setIn(['profit', 'awardSize', ['loading']], true)
    case GET_AWARD_SIZE_SUCCESS:
      return state
        .setIn(['profit', 'awardSize', ['loading']], false)
        .setIn(['profit', 'awardSize', ['loaded']], true)
        .setIn(['profit', 'awardSize', ['data']], action.payload)
    case GET_AWARD_SIZE_ERROR:
      return state
        .setIn(['profit', 'awardSize', ['loading']], false)
        .setIn(['profit', 'awardSize', ['loaded']], true)
    case GET_TRANSITION:
      return state
        .setIn(['profit', 'transition', ['loading']], true)
    case GET_TRANSITION_SUCCESS:
      return state
        .setIn(['profit', 'transition', ['loading']], false)
        .setIn(['profit', 'transition', ['loaded']], true)
        .setIn(['profit', 'transition', ['data']], action.payload)
    case GET_TRANSITION_ERROR:
      return state
        .setIn(['profit', 'transition', ['loading']], false)
        .setIn(['profit', 'transition', ['loaded']], true)
    case GET_SUMMARY_OPTIONS:
      return state
        .setIn(['profit', 'summaryOptions', ['loading']], true)
    case GET_SUMMARY_OPTIONS_SUCCESS:
      return state
        .setIn(['profit', 'summaryOptions', ['loading']], false)
        .setIn(['profit', 'summaryOptions', ['loaded']], true)
        .setIn(['profit', 'summaryOptions', ['data']], action.payload)
    case GET_SUMMARY_OPTIONS_ERROR:
      return state
        .setIn(['profit', 'summaryOptions', ['loading']], false)
        .setIn(['profit', 'summaryOptions', ['loaded']], true)
    case GET_SUMMARY:
      return state
        .setIn(['profit', 'summary', ['loading']], true)
    case GET_SUMMARY_SUCCESS:
      return state
        .setIn(['profit', 'summary', ['loading']], false)
        .setIn(['profit', 'summary', ['loaded']], true)
        .setIn(['profit', 'summary', ['data']], action.payload)
    case GET_SUMMARY_ERROR:
      return state
        .setIn(['profit', 'summary', ['loading']], false)
        .setIn(['profit', 'summary', ['loaded']], true)
    case SET_SUMMARY:
      return state
        .setIn(['profit', 'summary', ['loading']], true)
    case SET_SUMMARY_SUCCESS:
      return state
        .setIn(['profit', 'summary', ['loading']], false)
    case UPDATE_SUMMARY:
      return state
        .updateIn(['profit', 'summary', ['data']], (data: any) => {
          return {
            ...data,
            records: {
              ...data.records,
              [action.payload.key]: {
                ...data.records[action.payload.key],
                id: action.payload.id
              }
            }
          }
        })
    case GET_MARKET_SIZE:
      return state
        .setIn(['profit', 'marketSize', ['loading']], true)
    case GET_MARKET_SIZE_SUCCESS:
      return state
        .setIn(['profit', 'marketSize', ['loading']], false)
        .setIn(['profit', 'marketSize', ['loaded']], true)
        .setIn(['profit', 'marketSize', ['data']], action.payload)
    case GET_MARKET_SIZE_ERROR:
      return state
        .setIn(['profit', 'marketSize', ['loading']], false)
        .setIn(['profit', 'marketSize', ['loaded']], true)
    case GET_TRANSITION_MARKET:
      return state
        .setIn(['transition', 'options', ['loading']], true)
    case GET_TRANSITION_MARKET_SUCCESS:
      return state
        .setIn(['transition', 'options', ['loading']], false)
        .setIn(['transition', 'options', ['loaded']], true)
        .setIn(['transition', 'options', ['data']], action.payload)
    case GET_TRANSITION_MARKET_ERROR:
      return state
        .setIn(['transition', 'options', ['loading']], false)
        .setIn(['transition', 'options', ['loaded']], true)
    case GET_TRANSITION_MARKET_OVERVIEW:
      return state
        .setIn(['transition', 'overview', ['loading']], true)
    case GET_TRANSITION_MARKET_OVERVIEW_SUCCESS:
      return state
        .setIn(['transition', 'overview', ['loading']], false)
        .setIn(['transition', 'overview', ['loaded']], true)
        .setIn(['transition', 'overview', ['data']], action.payload)
    case GET_TRANSITION_MARKET_OVERVIEW_ERROR:
      return state
        .setIn(['transition', 'overview', ['loading']], false)
        .setIn(['transition', 'overview', ['loaded']], true)
    case GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE:
      return state
        .setIn(['transition', 'overviewByType', ['loading']], true)
    case GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_SUCCESS:
      return state
        .setIn(['transition', 'overviewByType', ['loading']], false)
        .setIn(['transition', 'overviewByType', ['loaded']], true)
        .setIn(['transition', 'overviewByType', ['data']], action.payload)
    case GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_ERROR:
      return state
        .setIn(['transition', 'overviewByType', ['loading']], false)
        .setIn(['transition', 'overviewByType', ['loaded']], true)
    case GET_TRANSITION_MARKET_USG:
      return state
        .setIn(['transition', 'USGMarket', ['loading']], true)
    case GET_TRANSITION_MARKET_USG_SUCCESS:
      return state
        .setIn(['transition', 'USGMarket', ['loading']], false)
        .setIn(['transition', 'USGMarket', ['loaded']], true)
        .setIn(['transition', 'USGMarket', ['data']], action.payload)
    case GET_TRANSITION_MARKET_USG_ERROR:
      return state
        .setIn(['transition', 'USGMarket', ['loading']], false)
        .setIn(['transition', 'USGMarket', ['loaded']], true)
    case GET_GOV_CONTRACTS_OVERVIEW:
      return state
        .setIn(['govContracts', 'overview', ['loading']], true)
    case GET_GOV_CONTRACTS_OVERVIEW_SUCCESS:
      return state
        .setIn(['govContracts', 'overview', ['loading']], false)
        .setIn(['govContracts', 'overview', ['loaded']], true)
        .setIn(['govContracts', 'overview', ['data']], action.payload)
    case GET_GOV_CONTRACTS_OVERVIEW_ERROR:
      return state
        .setIn(['govContracts', 'overview', ['loading']], false)
        .setIn(['govContracts', 'overview', ['loaded']], true)
    case RESET_AWARD:
      return new AwardsListing() 
    default:
      return state
  }
}
