import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { data, options } from './options'
import { IPiplineHealthItem } from 'models/hp/pipeline/pipeline.interface'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface ValueOfOpsChartProps {
  data: IPiplineHealthItem[]
}

const ValueOfOpsChart = ({data: chartData}: ValueOfOpsChartProps) => {
  return <Bar options={options} data={data(chartData)} />
}

export default ValueOfOpsChart
