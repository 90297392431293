import React from 'react'
import {  Button, Grid, Paper, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import { IContactList } from 'models/hp/education/education.interface'
import { PriorityEnum } from './RelationshipMap'
import { useDispatch } from 'react-redux'
import { useNotification } from 'context/NotificationContext'
import { createPipelineTask } from 'store/hp/pipeline/task/actions'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { PipelineTaskStatuses } from 'utils/const'
import { getContactsById } from 'store/hp/education/action'
import { PaginationLimit } from 'utils/constants/education'

interface RelationshipConnectionsItemProps {
    data: IContactList[] | null
}

const RelationshipConnectionsItem = ({data}: RelationshipConnectionsItemProps) => {
  const dispatch = useDispatch()
  const notification = useNotification()
  
  const createTask = async (firstName: string, lastName: string) => {
    try {
      await createPipelineTask(dispatch)({
        type: PipelineTaskStatuses.government__1,
        name: `${firstName} ${lastName}`,
        role: 'Agency Leadership',
        date: undefined,
        text: '',
        assignedUser: undefined,
        assignedUserId: undefined,
        step: null
      })
      notification.show('Task successfully created', NotificationTypeList.Success)
    }
    catch (e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const loadMoreContacts = async (agencyId: number, positionId: string, offset: number) => {
    try {
      await getContactsById(dispatch)(agencyId, positionId, offset + PaginationLimit)
    } catch(e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <>
      {data && data.length ? (
        
        <Grid item md={6}>
          <Paper style={{padding: '12px'}} className={styles['connection-details-item']}>
            <Typography variant='h4'>Connections ({data[0].priority === PriorityEnum.other ? 'Other' : 'Key jobs'}):</Typography>
            {data.map(el => (
              <Grid className={styles['contacts-list-item']} key={el.id}>
                <Typography variant='subtitle1' className={styles['contacts-list-item-title']}>{el.title}</Typography>
                <Grid container className={styles['contacts-list']}>
                  {
                    el.contacts.length && el.contacts.map(contact => (
                      <Grid container spacing={2} alignItems='center' className={styles['contacts-item']} key={contact.id}>
                        <Grid item md={9}>
                          <Typography variant='subtitle1'>{`${contact.firstName} ${contact.lastName}`}</Typography>
                          <Typography variant='subtitle2'>{contact.position}</Typography>
                          <Typography variant='subtitle2'>{contact.company}</Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Grid container justifyContent='flex-end'>
                            <Button 
                              variant='contained'
                              onClick={() => createTask(contact.firstName, contact.lastName)}
                            >
                            Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
                {
                  (el.total >= el.contacts.length + 1) ? (
                    <Grid container justifyContent='center'>
                      <Button
                        style={{marginTop: '15px'}}
                        color='primary'
                        variant='contained'
                        onClick={() => loadMoreContacts(el.customerId, el.id, el.offset)}
                      >
                      Load more contacts
                      </Button>
                    </Grid>
                  ) : null
                }
              </Grid>
            ))
            }
          </Paper>
        </Grid>
       
      ): null}
    </>
  )
}

export default RelationshipConnectionsItem