import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import styles from '../index.module.scss'
import { getPscCodesAction } from 'store/hp/grouped-code/actions'
import { useDispatch, useSelector } from 'react-redux'
import { FilterOption, SearchModel } from 'models/hp/search/search.model'
import { pscCodesSelector } from 'store/hp/grouped-code/selectors'
import Select, { Option } from 'components/v2/Select'
import TextField from 'components/v2/TextField'
import { getChildrenIds } from 'helpers/filter-option'
import { createFulfilledSearch, getAndSetSearchDetails, setSearchFilterData } from 'store/hp/search/actions'
import { useHistory } from 'react-router-dom'
import ButtonWithLoader from 'components/ButtonWithLoader'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'

const SearchStarter = () => {
  const dispatch = useDispatch()
  const notification = useRef(useNotification())

  const history = useHistory()
  const [request, setIsRequest] = React.useState<boolean>(false)
  const [keyword, setKeyword] = React.useState<string>('')
  const [selectedPsc, setSelectedPsc] = React.useState<{ name?: string | undefined, value: unknown } | null>(null)

  const pscCodesOptions: FilterOption[] = useSelector(pscCodesSelector) as FilterOption[]

  const handleChangePsc = (event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    setSelectedPsc(event.target)
  }

  const handleSave = async () => {
    try {
      setIsRequest(true)
      const psc = pscCodesOptions.find(i => i.id === selectedPsc?.value)
      const pscCodeIds: number[] = []
      getChildrenIds(psc?.sub || [], (id: number) => {
        pscCodeIds.push(id)
      })
      const created = await createFulfilledSearch()({
        ...new SearchModel(),
        pscCodeIds,
        keywords: keyword.split(','),
        type: undefined
      }, 'Starter Search')
      const search = await getAndSetSearchDetails(dispatch)(created!.id)
      setSearchFilterData(dispatch)({ ...search, pscCodeIds })
      history.push('/contracts')
    } catch (error) {
      setIsRequest(false)
      notification.current.show(getErrorMessage(error), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    getPscCodesAction(dispatch)()
  }, [])

  const pscToSelect: Option[] = pscCodesOptions.filter(i => [1, 2, 3].includes(i.id)).map(i => ({
    value: i.id!,
    key: i.name!
  }))

  return (
    <Grid container className={styles['form-block']}>
      <Hidden smDown>
        <Grid item md={6}>
          <LeftBlock/>
        </Grid>
      </Hidden>
      <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
        <div className={styles['form-block-right-inner']}>
          <div className={styles['logo']}>
            <img src={LogoWithText} alt='logo'/>
          </div>
          <Grid container justifyContent='center' className={styles['form-subtitle']}>
            <Typography align='center'>
              Please add some info so we will be able to prepare a first search for you!
            </Typography>
          </Grid>
          <Select
            value={selectedPsc?.value || ''}
            onChange={handleChangePsc}
            options={pscToSelect}
            label='Functional area'
          />
          <div style={{ height: '16px' }}/>
          <TextField
            label='Keyword'
            variant='filled'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setKeyword(event.target.value)}
            value={keyword}
          />
          <div style={{ height: '16px' }}/>
          <ButtonWithLoader
            style={{ height: '56px' }}
            variant='contained'
            color='primary'
            fullWidth
            disabled={!selectedPsc || !keyword}
            type='submit'
            onClick={handleSave}
            isRequested={request}
          >
            Submit
          </ButtonWithLoader>
        </div>
      </Grid>
    </Grid>
  )
}

export default SearchStarter
