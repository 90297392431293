import React from 'react'
import styles from '../index.module.scss'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import ServiceLineFilter from './Filter'
import RowName from './Name'
import Modal from 'components/v2/Modal'
import { IFilterOption, ISearchDTO } from 'models/hp/search/search.interface'
import GeneralColumn from './Columns/General'
import KeywordColumn from './Columns/Keyword'
import { SearchModel } from 'models/hp/search/search.model'
import CustomerColumn from './Columns/Customer'
import {
  addSearchAgencies,
  addSearchCodes,
  addSearchKeywords,
  addSearchLocations,
  updateFulfilledSearch
} from 'store/hp/search/actions'
import { getRandom } from 'helpers/numbers'

interface Props {
  count: number
  onDelete: (id: number) => void
  search: SearchModel
  onDuplicate: (search: SearchModel, name: string) => void
}

export type actionTypes = 'psc' | 'naics' | 'location' | 'customer'

const ServiceLineRow = ({ search, onDelete, onDuplicate, count }: Props) => {

  const [customSearch, setCustomSearch] = React.useState<SearchModel>(new SearchModel())
  const [isEdit, setIsEdit] = React.useState(!search.id)
  const [isDelete, setIsDelete] = React.useState(false)
  const [nameModalOpen, setNameModalOpen] = React.useState(false)
  const [resetModalOpen, setResetModalOpen] = React.useState(false)

  const [psc, setPsc] = React.useState<IFilterOption[]>([])
  const [naics, setNaics] = React.useState<IFilterOption[]>([])
  const [locations, setLocations] = React.useState<IFilterOption[]>([])
  const [customers, setCustomers] = React.useState<IFilterOption[]>([])
  const [keywords, setKeywords] = React.useState<string[]>([])

  const handleChange = async (type: actionTypes, id: number | string, state: boolean, option?: IFilterOption, viaRequest?: boolean) => {
    if (state) {
      if (type === 'psc') {
        setPsc(prev => [...prev, option!])
      } else if (type === 'naics') {
        setNaics(prev => [...prev, option!])
      } else if (type === 'location') {
        setLocations(prev => [...prev, option!])
      } else if (type === 'customer') {
        setCustomers(prev => [...prev, option!])
      }
    } else {
      if (type === 'psc') {
        const pscResult = [...psc].filter((x: IFilterOption) => x.id !== id)
        if (viaRequest) {
          await addSearchCodes(
            customSearch.id.toString(),
            pscResult.map(i => i.id.toString()),
            [...naics].filter((x: IFilterOption) => x.id !== id).map(i => i.id.toString()))
        }
        setPsc(pscResult)
      } else if (type === 'naics') {
        const naicsResult = naics.filter((x: IFilterOption) => x.id !== id)
        if (viaRequest) {
          await addSearchCodes(
            customSearch.id.toString(),
            [...psc].filter((x: any) => x.id !== id).map(i => i.id.toString()),
            naicsResult.map(i => i.id.toString())
          )
        }
        setNaics(naicsResult)
      } else if (type === 'location') {
        const locationResult = locations.filter((x: IFilterOption) => x.id !== id)
        if (viaRequest) {
          await addSearchLocations(customSearch.id.toString(), locationResult.map(i => i.id.toString()))
        }
        setLocations(locationResult)
      } else if (type === 'customer') {
        const customerResult = customers.filter((x: IFilterOption) => x.id !== id)
        if (viaRequest) {
          await addSearchAgencies(customSearch.id.toString(), customerResult.map(i => i.id.toString()))
        }
        setCustomers(customerResult)
      }
    }
  }

  const handlePartialChange = async (type: actionTypes, ids: Array<string | number>, state: boolean, options?: IFilterOption[], viaRequest?: boolean) => {
    if (state) {
      if (type === 'psc') {
        setPsc(prev => [...prev, ...options!])
      } else if (type === 'naics') {
        setNaics(prev => [...prev, ...options!])
      } else if (type === 'location') {
        setLocations(prev => [...prev, ...options!])
      } else if (type === 'customer') {
        setCustomers(prev => [...prev, ...options!])
      }
    } else {
      if (type === 'psc') {
        setPsc(prev => prev.filter((x: IFilterOption) => !ids.includes(x.id)))
      } else if (type === 'naics') {
        setNaics(prev => prev.filter((x: IFilterOption) => !ids.includes(x.id)))
      } else if (type === 'location') {
        setLocations(prev => prev.filter((x: IFilterOption) => !ids.includes(x.id)))
      } else if (type === 'customer') {
        const customerResult = customers.filter((x: IFilterOption) => !ids.includes(x.id))
        if (viaRequest) {
          await addSearchAgencies(customSearch.id.toString(), customerResult.map(i => i.id.toString()))
        }
        setCustomers(customerResult)
      }
    }
  }

  const onAddKeywords = async (keyword: string) => {
    await addSearchKeywords(customSearch.id.toString(), [...keywords, keyword])
    setKeywords(prev => [...prev, keyword])
  }

  const onDeleteKeyword = async (keyword: string) => {
    const keywordResult = keywords.filter(i => i !== keyword)
    await addSearchKeywords(customSearch.id.toString(), keywordResult)
    setKeywords(keywordResult)
  }

  const onReset = async () => {
    if (customSearch.id) {
      await onSave({ ...new SearchModel(), type: 'marketResearch' })
    }
    setPsc([])
    setNaics([])
    setLocations([])
    setCustomers([])
    setKeywords([])
    setIsEdit(false)
    setResetModalOpen(false)
  }

  const getSearchFullData = (): any => ({
    ...new SearchModel(),
    keywords,
    customers,
    groupedCodeIds: naics.map(i => i.id),
    pscCodeIds: psc.map(i => i.id),
    customerIds: customers.map(i => i.id),
    locationIds: locations.map(i => i.id),
    states: locations,
    pscCodes: psc,
    groupedCodes: naics,
    type: 'marketResearch'
  })

  const handleDuplicate = () => {
    onDuplicate(getSearchFullData(), `${customSearch.name}_2_${getRandom(0, 9)}`)
  }

  const onSave = async (values?: ISearchDTO) => {
    const data: ISearchDTO = values || getSearchFullData()
    await updateFulfilledSearch()(data, customSearch.name, customSearch.id)
    setIsEdit(false)
  }

  React.useEffect(() => {
    // Duplicate search for change
    setCustomSearch({ ...search })
    // Set locations
    setLocations(search.states)
    // Set psc
    setPsc(search.pscCodes)
    // Set naics
    setNaics(search.groupedCodes)
    // Set keywords
    setKeywords(search.keywords)
    // Set customers
    setCustomers(search.customers)
  }, [search, setCustomSearch])

  return (
    <div className={styles['row']}>
      {isEdit &&
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h4' style={{ marginBottom: '5px' }}>{customSearch.name || '-'}</Typography>
        <ServiceLineFilter
          selectedPsc={psc}
          selectedNaics={naics}
          selectedLocations={locations}
          selectedCustomers={customers}
          handleChange={handleChange}
          handlePartialChange={handlePartialChange}
          onReset={() => setResetModalOpen(true)}
          onSave={() => onSave()}
          onKeywordAdd={(name) => !keywords.includes(name) && onAddKeywords(name)}
        />
      </div>
      }
      {isEdit && <div style={{ width: '16px' }}/>}
      <div className={styles['line-wrapper']}>
        {isEdit &&
        <Button
          style={{ margin: '5px auto 5px 0' }}
          variant='contained'
          onClick={() => setNameModalOpen(true)}
          color='primary'
        >
          {customSearch.name ? 'Edit name' : 'Add name'}
        </Button>
        }
        <Paper className={styles['line-paper']}>
          <Grid container spacing={2}>
            <RowName
              count={count}
              isEdit={isEdit}
              handleNameModal={() => setNameModalOpen(prev => !prev)}
              search={customSearch}
              onEdit={() => setIsEdit(true)}
              onDelete={() => setIsDelete(true)}
              onDuplicate={handleDuplicate}
              isModalOpen={nameModalOpen}
              setName={(name) => setCustomSearch(prev => ({ ...prev, name }))}
            />
            <GeneralColumn type='psc' handleDelete={handleChange} title='PSC' isEdit={isEdit} list={psc}/>
            <GeneralColumn type='naics' handleDelete={handleChange} title='NAICS' isEdit={isEdit} list={naics}/>
            <KeywordColumn onDelete={onDeleteKeyword} isEdit={isEdit} keywords={keywords}/>
            <GeneralColumn
              type='location' handleDelete={handleChange} title='Location' isEdit={isEdit}
              list={locations}/>
            <CustomerColumn
              handlePartialDelete={handlePartialChange}
              handleDelete={handleChange}
              isEdit={isEdit} customers={customers}
            />
          </Grid>
        </Paper>
      </div>
      {isDelete &&
      <Modal
        title='Are you sure you want to delete this product/service line?'
        onConfirm={() => {
          onDelete(customSearch.id)
          setIsDelete(false)
        }}
        onClose={() => setIsDelete(false)}
        open={isDelete}
      />
      }
      {resetModalOpen &&
      <Modal
        title='Are you sure you want to reset this product/service line data?'
        onConfirm={onReset}
        onClose={() => setResetModalOpen(false)}
        open={resetModalOpen}
      />
      }
    </div>
  )
}

export default ServiceLineRow
