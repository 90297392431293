import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import {
  IGoNoGoSuggestionResponse,
  IHpChatModel, IHpReportModel, IHpRfpChatAddMessageResponseModel,
  IHpRfpChatResponseModel,
  IHpRfpDetailModel,
  IHpRfpDetailResponseModel,
  IHpRfpModel,
  IHpRfpModelResponseModel, IHpRfpReportResponseModel, IUserGroup, TCategorization
} from './rfp.interface'
import { ResourceLinkModel } from 'models/common/resource-link/resource-link.model'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { PlaceOfPerfModel } from '../place-of-perf/place-of-perf.model'
import { NaicsDescriptionModel } from '../naics/naics.model'
import { HpRfpLightCalcModel, HpRfpTinderLightCalcModel } from './calc/calc.model'
import { PscDescriptionModel } from '../psc/psc.model'
import { PocModel } from '../poc/poc.model'
import { UserBaseModel } from '../../common/user/user.model'
import { BaseCityModel, BaseStateModel } from '../location/state.model'
import { clearHtmlString } from 'helpers/string'
import { SuggestionStatus, TRole } from 'constants/user-group'

@jsonObject
export class HpRfpModel implements IHpRfpModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  solicitationNumber: string = ''

  @jsonMember({ constructor: String, deserializer: (val: any) => val.replace(/_/gim, ' ') })
  title: string = ''

  @jsonMember({ constructor: String })
  responseDeadLine: string = ''

  @jsonMember({ constructor: String })
  postedDate: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: String })
  contractType: string = ''

  @jsonMember({ constructor: String })
  contractTransactionUniqueKey: string = ''

  @jsonMember({ constructor: String })
  typeOfSetAside: string = ''

  @jsonMember({ constructor: String })
  classificationCode: string = ''

  @jsonMember({ constructor: Boolean })
  deadlineCalculated: boolean = false

  @jsonMember({ constructor: String })
  categorizationType: string = ''

  @jsonMember({constructor: Number})
  rfpCategorizationId: number | undefined = undefined

  @jsonMember({constructor: HpRfpTinderLightCalcModel})
  calc?: HpRfpTinderLightCalcModel = new HpRfpTinderLightCalcModel()

  @jsonMember({constructor: Date})
  snooze?: Date = undefined
}

@jsonObject
export class HpMarketResearchRfp {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String, deserializer: (val: any) => val.replace(/_/gim, ' ')  })
  title: string = ''

  @jsonMember({ constructor: String })
  naicsCode: string = ''

  @jsonMember({ constructor: String })
  naicsName: string = ''

  @jsonMember({ constructor: String })
  classificationCode: string = ''

  @jsonMember({ constructor: String })
  classificationName: string = ''

  @jsonMember({constructor: String})
  keyword?: string = ''

  @jsonMember({constructor: String})
  categorization?: string = ''
}

@jsonObject
export class HpMarketResearchKeywordResult {
  @jsonMember({ constructor: String })
  keyword: string = ''

  @jsonMember({ constructor: Number, deserializer: val => Number(val) || 0 })
  like: number = 0

  @jsonMember({ constructor: Number, deserializer: val => Number(val) || 0 })
  dislike: number = 0

  @jsonMember({ constructor: Number, deserializer: val => Number(val) || 0 })
  skip: number = 0
}

@jsonObject
export class HpRfpUserGroupModel implements IUserGroup {
  @jsonMember({ constructor: String })
  createdBy: string = ''
}

@jsonObject
export class HpRfpAssessing {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  awardType: string = ''

  @jsonMember({ constructor: String })
  awardOrIdvFlag: string = ''

  @jsonMember({ constructor: Number })
  numberOfOffersReceived: number = 0

  @jsonMember({ constructor: String })
  externalId: string = ''

  @jsonMember({constructor: Date})
  periodOfPerformanceStartDate: Date | null = null

  @jsonMember({constructor: Date})
  periodOfPerformancePotentialEndDate: Date | null = null
}

@jsonObject
export class Calc {
  @jsonMember({ constructor: String })
  net: string = ''

  @jsonMember({ constructor: Number })
  views: number = 0

  @jsonMember({ constructor: String })
  estimatedValue?: string = ''
}

@jsonObject
export class CategorizedHpRfpModel extends HpRfpModel {
  @jsonMember({ constructor: Calc })
  calc: Calc = new Calc()

  @jsonMember({constructor: HpRfpAssessing})
  assessings?: HpRfpAssessing = new HpRfpAssessing()

  @jsonMember({constructor: String, deserializer: (step) => typeof step === 'number' ? step.toString() : step})
  step: string | null = null
}

@jsonObject
export class HpOfficeAddress {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  city?: string = ''

  @jsonMember({constructor: String})
  countryCode?: string = ''

  @jsonMember({constructor: String})
  state?: string = ''

  @jsonMember({constructor: String})
  zipcode?: string = ''
}

@jsonObject
export class HpRfpAwardeeLocationModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: BaseCityModel })
  city?: BaseCityModel = new BaseCityModel()

  @jsonMember({ constructor: BaseStateModel })
  state?: BaseStateModel = new BaseStateModel()

  @jsonMember({ constructor: BaseStateModel })
  country?: BaseStateModel = new BaseStateModel()
}

@jsonObject
export class HpRfpAwardeeModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: String })
  duns: string = ''

  @jsonMember({ constructor: HpRfpAwardeeLocationModel })
  location: HpRfpAwardeeLocationModel = new HpRfpAwardeeLocationModel()
}

@jsonObject
export class HpRfpAwardModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  number: string = ''

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  amount: number = 0

  @jsonMember({ constructor: Date })
  date: Date = new Date()

  @jsonMember({ constructor: HpRfpAwardeeModel })
  awardee: HpRfpAwardeeModel = new HpRfpAwardeeModel()
}

@jsonObject
export class HpRfpSummaryModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  text: string = ''
}

@jsonObject
export class HpRfpDetailModel implements IHpRfpDetailModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  noticeId: string = ''

  @jsonMember({ constructor: String, deserializer: (val: any) => val.replace(/_/gim, ' ') })
  title: string = ''

  @jsonMember({ constructor: String })
  solicitationNumber: string = ''

  @jsonMember({ constructor: String })
  department: string = ''

  @jsonMember({ constructor: String })
  departmentCode: string = ''

  @jsonMember({ constructor: String })
  subTier: string = ''

  @jsonMember({ constructor: String })
  office: string = ''

  @jsonMember({ constructor: String })
  postedDate: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: String, deserializer: (json) => json || 'Standard Contract' })
  analyzedType: string = ''

  @jsonMember({ constructor: String })
  baseType: string = ''

  @jsonMember({ constructor: Boolean })
  sbirContract: boolean = false

  @jsonMember({ constructor: String })
  archiveType: string = ''

  @jsonMember({ constructor: String })
  archiveDate: string = ''

  @jsonMember({ constructor: String })
  typeOfSetAsideDescription: string = ''

  @jsonMember({ constructor: String })
  typeOfSetAside: string = ''

  @jsonMember({ constructor: String })
  classificationCode: string = ''

  @jsonMember({ constructor: String })
  pricing: string = ''

  @jsonMember({ constructor: String })
  evaluation: string = ''

  @jsonMember({ constructor: String })
  selfAssessed: string = ''

  @jsonMember({ constructor: String })
  responseDeadLine: string = ''

  @jsonMember({ constructor: String })
  active: string = ''

  @jsonMember({ constructor: String, deserializer: (json) => clearHtmlString(json) })
  description: string = ''

  @jsonMember({ constructor: String })
  organizationType: string = ''

  @jsonMember({ constructor: String })
  additionalInfoLink: string = ''

  @jsonMember({ constructor: String })
  uiLink: string = ''

  @jsonMember({ constructor: Boolean })
  deadlineCalculated: boolean = false

  @jsonMember({ constructor: String })
  categorizationType: string = ''

  @jsonMember({ constructor: Number })
  rfpCategorizationId: number = 0

  @jsonMember({ constructor: Number })
  awardIndicator: number | null = null

  @jsonMember({ constructor: HpRfpSummaryModel })
  summary: HpRfpSummaryModel = new HpRfpSummaryModel()

  @jsonMember({ constructor: HpRfpAwardModel })
  award?: HpRfpAwardModel = new HpRfpAwardModel()

  @jsonMember({ constructor: HpOfficeAddress })
  officeAddress?: HpOfficeAddress = undefined

  @jsonMember({ constructor: HpRfpLightCalcModel })
  calc?: HpRfpLightCalcModel = undefined

  @jsonMember({ constructor: NaicsDescriptionModel })
  naicsDescription?: NaicsDescriptionModel = undefined

  @jsonMember({ constructor: PscDescriptionModel })
  pscDescription?: PscDescriptionModel = undefined

  @jsonArrayMember(PocModel)
  pointOfContacts: PocModel[] = []

  @jsonMember({ constructor: PlaceOfPerfModel })
  placeOfPerformance?: PlaceOfPerfModel

  @jsonArrayMember(ResourceLinkModel)
  resourceLinks: ResourceLinkModel[] = []

  @jsonMember({ constructor: HpRfpUserGroupModel })
  userGroup: HpRfpUserGroupModel | null = null
}

@jsonObject
export class HpRfpDetailResponseModel extends BaseResponseModel implements IHpRfpDetailResponseModel {
  @jsonMember({ constructor: HpRfpDetailModel })
  data: HpRfpDetailModel | null = null
}

@jsonObject
export class HpRfpModelResponseModel extends BasePaginationModel implements IHpRfpModelResponseModel {
  @jsonArrayMember(HpRfpModel)
  data: HpRfpModel[] = []
}

@jsonObject
export class HpChatModel implements IHpChatModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  text: string = ''

  @jsonMember({ constructor: String })
  createdAt: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: UserBaseModel })
  user?: UserBaseModel = undefined
}

@jsonObject
export class RfpDocumentLinkPayload {
  @jsonMember({ constructor: String })
  location: string | null = null
}

@jsonObject
export class RfpDocumentLinkResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: RfpDocumentLinkPayload })
  data: RfpDocumentLinkPayload = new RfpDocumentLinkPayload()
}

@jsonObject
export class HpRfpChatResponseModel extends BasePaginationModel implements IHpRfpChatResponseModel {
  @jsonArrayMember(HpChatModel)
  data: HpChatModel[] = []
}

@jsonObject
export class HpRfpChatAddMessageResponseModel extends BaseResponseModel implements IHpRfpChatAddMessageResponseModel {
  @jsonMember(HpChatModel)
  data: HpChatModel = new HpChatModel()
}

@jsonObject
export class HpRfpReportModel implements IHpReportModel {
  @jsonMember({constructor: String})
  url: string = ''
}

@jsonObject
export class HpRfpReportResponseModel extends BaseResponseModel implements IHpRfpReportResponseModel {
  @jsonMember({ constructor: HpRfpReportModel })
  data: HpRfpReportModel | null = null
}

@jsonObject
export class CategorizedHpRfpPayloadItem {
  @jsonMember({ constructor: String, deserializer: (val: any) => val.replace(/_/gim, ' ') })
  title: string = ''

  @jsonMember({ constructor: String })
  type: TCategorization = 'pipeline__1'

  @jsonMember({ constructor: Number })
  count: number = 0

  @jsonMember({ constructor: String })
  estValue?: string = ''

  @jsonArrayMember(CategorizedHpRfpModel)
  items: CategorizedHpRfpModel[] = []
}

@jsonObject
export class CategorizedHpRfpResponseModel extends BaseResponseModel {
  @jsonArrayMember(CategorizedHpRfpPayloadItem)
  data: CategorizedHpRfpPayloadItem[] = []
}

@jsonObject
export class TrashedHpRfpResponseModel extends BasePaginationModel {
  @jsonArrayMember(CategorizedHpRfpModel)
  data: CategorizedHpRfpModel[] = []
}

@jsonObject
export class HpMarketResearchKeywordResultResponseModel extends BaseResponseModel {
  @jsonArrayMember(HpMarketResearchKeywordResult)
  data: HpMarketResearchKeywordResult[] = []
}

@jsonObject
export class GoNoGoModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  value!: SuggestionStatus

  @jsonMember({ constructor: String })
  createdAt: string = ''
}

@jsonObject
export class GoNoGoSuggestionModel {
  @jsonMember({ constructor: Number })
  id!: number

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  status: string = ''

  @jsonMember({ constructor: String })
  role!: TRole

  @jsonMember({ constructor: GoNoGoModel })
  goNoGo!: GoNoGoModel | null
}

@jsonObject
export class GoNoGoSuggestionResponseModel extends BaseResponseModel implements IGoNoGoSuggestionResponse {
  @jsonArrayMember(GoNoGoSuggestionModel)
  data: GoNoGoSuggestionModel[] = []
}

