import { Grid } from '@material-ui/core'
import React from 'react'
import InviteTeam from './InviteTeam'
import Requests from './Requests'

interface Props {
  updateCurrentUserList: () => void
}

const InviteAndRequests = ({ updateCurrentUserList }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <InviteTeam
          updateCurrentUserList={updateCurrentUserList}
        />
      </Grid>
      <Grid item md={6}>
        <Requests />
      </Grid>
    </Grid>
  )
}

export default InviteAndRequests