import { jsonMember, jsonObject } from 'typedjson'
import { IPocModel } from './poc.interface'

@jsonObject
export class PocModel implements IPocModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: Boolean })
  subscribed: boolean = false

  @jsonMember({ constructor: String })
  rfpId: string = ''

  @jsonMember({ constructor: String })
  phone: string = ''

  @jsonMember({ constructor: String })
  fullName: string = ''

  @jsonMember({ constructor: String })
  fax: string = ''

  @jsonMember({ constructor: String })
  email: string = ''
}