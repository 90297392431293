import { RefObject, useEffect} from 'react'

export function useOnClickOutside(
  ref: RefObject<HTMLElement> | null,
  handler: () => void
) {
  useEffect(
    () => {
      if(ref?.current) {
        const listener = (event: any) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return 
          }
          handler()
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
          document.removeEventListener('mousedown', listener)
          document.removeEventListener('touchstart', listener)
        }
      }
      return
    },
    // Add ref and handler to effect dependencies
    [ref, handler]
  )
}
