import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { useHistory } from 'react-router-dom'
import { HpMarketResearchRfp } from 'models/hp/rfp/rfp.model'
import { categorizeItem, getMarketResearchItems } from 'store/hp/market-research/actions'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { searchSelector } from 'store/hp/market-research/selectors'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import CategorizationItem from './CategorizationItem'
import ActionsBar from '../../components/ActionsBar'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

let page = 0
const SearchCategorization = () => {
  const history = useHistory()
  const search = useSelector(searchSelector) as SearchModel
  const { show } = useNotification()

  const [requesting, setRequesting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [data, setData] = React.useState<{ count: number, items: HpMarketResearchRfp[] }>({ count: 0, items: [] })
  const [active, setActive] = React.useState(0)
  const [checkedAmount, setCheckedAmount] = React.useState(0)

  const setNext = async () => {
    if (active < data.items.length - 1) {
      setActive((val) => val + 1)
    } else {
      if (active < data.count) {
        page += 1
        const res = await getMarketResearchItems(search.id, page)
        setData({ count: res.count, items: res.data })
        setActive(0)
      }
    }
    setCheckedAmount(val => val + 1)
  }

  const categorize = async (rfpId: string, type: string) => {
    try {
      setRequesting(true)

      await categorizeItem(rfpId, search.id, type)
      await setNext()

      setRequesting(false)
    }
    catch (e) {
      setRequesting(false)
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleContinue = () => {
    history.push('/user/market-research/keywords-results')
  }

  const handleStop = () => {
    show('Changes saved successfully', NotificationTypeList.Success)
  }

  useEffect(() => {
    setLoading(true)
    getMarketResearchItems(search.id)
      .then(res => setData({ count: res.count, items: res.data }))
      .finally(() => setLoading(false))
  }, [search.id])

  const renderBody = () => {
    if (loading) {
      return (
        <Grid className={styles['content']} alignItems='center' container justifyContent='space-between' direction='column'>
          <CircularProgress color='primary' />
          <ActionsBar
            handleGoBack={() => history.goBack()}
            handleContinue={handleContinue}
            handleStop={handleStop}
          />
        </Grid>
      )
    }
    if (checkedAmount === data.count) {
      return (
        <Grid className={styles['content']} container justifyContent='space-between' direction='column'>
          <Grid container justifyContent='center' alignItems='center' direction='column'>
            <Typography variant='h6'>You successfully finished with evaluation</Typography>
            <Typography variant='h5'>Go to the next step to continue</Typography>
          </Grid>
          <ActionsBar
            handleGoBack={() => history.goBack()}
            handleContinue={handleContinue}
            handleStop={handleStop}
          />
        </Grid>
      )
    }
    return (
      <div>
        <Typography variant='h2'>Validate</Typography>
        <Grid wrap='nowrap' container className={styles['content']}>
          <Grid item md={4} className={styles['left-block']}>
            <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
            <div className={styles['vertical-divider']} />
            <Grid className={styles['left-block-content']}>
              <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_VALIDATE_KEYWORDS}/>
              <Typography variant='h4'>
                Validating your keywords
              </Typography>
              <Typography>
                • Based on your keywords here are a sampling of matching contracts
              </Typography>
              <Typography>
                • If it looks like the contract is related to the kind of work you are considering then like it
              </Typography>
              <Typography>
                • The contract doesn’t need to be an exact match, if it is generally in your market: Like it
              </Typography>
              <Typography>
                • If you can’t do the entire contract, but could probably do a piece of it: Like it
              </Typography>
              <Typography>
                • If the contract is in a location that is bad for you, is too big or too small, or has
                expired, but the work seems to be in your market: like it
              </Typography>
              <Typography>
                • If the contract is outside your market trash it
              </Typography>
              <Typography>
                • Some contract titles make no sense (e.g. it is just be a string of numbers). If you really
                can’t tell what the contract is for then click the question mark and we’ll toss it out
              </Typography>
              <Typography gutterBottom>
                • It’s best to evaluate a lot of contracts, so don’t agonize over each one and make the like/
                trash decisions fast
              </Typography>
            </Grid>
          </Grid>
          <div className={styles['horizontal-divider']} />
          <Grid item md={8} className={styles['right-block']}>
            <Typography className={styles['content-title-left']} variant='h4'>
              Evaluate contracts. More is better, but at least 10 per keyword | Evaluated: {checkedAmount + 1}/{data.count || 1}
            </Typography>
            <div className={styles['vertical-divider-left']} />
            <Grid className={styles['right-block-content']} container direction='column' alignItems='center' justifyContent='space-between' spacing={2}>
              <Grid className={styles['form']} style={{ paddingTop: '40px' }}>
                <div>
                  <Grid item md={8} style={{ margin: '0 auto'}}>
                    {data.items[active] && (
                      <CategorizationItem
                        item={data.items[active]}
                        categorize={categorize}
                        requesting={requesting}
                      />
                    )}
                  </Grid>
                </div>
                <ActionsBar
                  handleGoBack={() => history.goBack()}
                  handleContinue={handleContinue}
                  handleStop={handleStop}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div >
      {renderBody()}
    </div>
  )

}

export default SearchCategorization