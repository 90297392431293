import React from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'

const Wrapper = ({children}: {children: React.ReactNode | React.ReactNodeArray}) => {
  return (
    <Grid
      container
      className={styles['list']}
      alignItems='center'
      justifyContent='center'
    >
      {children}
    </Grid>
  )
}

export default Wrapper