import React, { useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import EducationNavigationButtons from 'modules/Main/Education/components/EducationNavigationButtons'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { EDUCATION_DUAL_VC_IFRAME_SOURCE, PitchingDualVcInvestorsNavs } from 'utils/constants/education-dual-vc'
import DualVcPitchingInvestors from 'modules/Main/Education/pages/dual-vc/phases'


const EducationDualVc = () => {
  const [selectedSubtitle, setSelectedSubtitle] = useState(PitchingDualVcInvestorsNavs[0])
  const currentTitleIndex = PitchingDualVcInvestorsNavs.findIndex((phase: IEducationPhaseClass) => phase.name === selectedSubtitle.name)
  const lastSubtitle = currentTitleIndex + 1 === PitchingDualVcInvestorsNavs.length

  const onSelectSubtitle = (subtitle: IEducationPhaseClass) => {
    setSelectedSubtitle(subtitle)
    if (EDUCATION_DUAL_VC_IFRAME_SOURCE[subtitle.name]) {
      return
    }
  }

  const onNext = () => {
    if (lastSubtitle) {
      return
    }
    let nextSubtitle = currentTitleIndex < PitchingDualVcInvestorsNavs.length ? PitchingDualVcInvestorsNavs[currentTitleIndex + 1] : selectedSubtitle
    setSelectedSubtitle(nextSubtitle)
  }

  const onPrevious = () => {
    let previousSubtitle = currentTitleIndex ? PitchingDualVcInvestorsNavs[currentTitleIndex - 1] : selectedSubtitle
    setSelectedSubtitle(previousSubtitle)
  }

  return (
    <Grid container className={styles['education']}>
      <Grid item md={3} className={styles['education-left-block']}>
        <DualVcPitchingInvestors
          selectedSubtitle={selectedSubtitle}
          onSelectSubtitle={onSelectSubtitle}
        />
        <EducationNavigationButtons
          onPrevious={onPrevious}
          onNext={onNext}
          hasNext={!lastSubtitle}
          hasPrevious={!!currentTitleIndex}
        />
      </Grid>
      <Grid className={styles['education-content']} item md={9}>
        {selectedSubtitle && EDUCATION_DUAL_VC_IFRAME_SOURCE[selectedSubtitle.name] && (
          <iframe src={EDUCATION_DUAL_VC_IFRAME_SOURCE[selectedSubtitle.name]} title={selectedSubtitle.title}/>
        )}
      </Grid>
    </Grid>
  )
}

export default EducationDualVc