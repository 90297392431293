import React, { useEffect } from 'react'
import styles from '../Sap/Filter/index.module.scss'
import { InputAdornment, TextField } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface Props {
  handleChange: (keywords: string) => void
  handleSearch: () => void
  keywords: string[]
}

const KeywordsFilter = (props: Props) => {
  const [text, setText] = React.useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setText(value)
    props.handleChange(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.handleSearch()
    }
  }

  useEffect(() => {
    if (props.keywords && props.keywords.length > 0) {
      setText(props.keywords[0])
    }
  }, [props.keywords])

  return (
    <TextField
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={text}
      variant='outlined'
      placeholder='Keyword'
      color='primary'
      fullWidth
      InputProps={{
        classes: {root: styles['keyword-input']},
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon name='search' width={24} height={24}/>
          </InputAdornment>
        )
      }}
    />
  )
}

export default React.memo(KeywordsFilter, (prev, next) => prev.keywords === next.keywords)