import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  PIPELINE_TASK_BOARD_MANAGE_SUCCESS,
  PIPELINE_TASK_BOARD_CREATE_SUCCESS,
} from 'store/hp/pipeline/task/actions'
import { TaskItem } from 'models/hp/pipeline-task/pipeline-task.model'

export interface IPipelineTaskBoardListRecord {
  listing: {
    loaded: boolean
    loading: boolean
    count: number
    data: TaskItem[]
  }
}

const PipelineTaskBoardListRecord = Record<IPipelineTaskBoardListRecord>({
  listing: {
    loaded: false,
    loading: false,
    count: 0,
    data: [],
  }
})

export const pipelineTaskBoardListReducer = (state: Record<IPipelineTaskBoardListRecord> = new PipelineTaskBoardListRecord(), action: IAction) => {
  switch (action.type) {
    case PIPELINE_TASK_BOARD_MANAGE_SUCCESS:
      return state
        .updateIn(['listing', 'data'], (data) => {
          return (data as TaskItem[]).map((item: TaskItem) => {
            if (item.id === action.payload.taskId) {
              Object.keys(action.payload.dto).forEach((key) => {
                // @ts-ignore
                item[key as keyof TaskItem] = action.payload.dto[key]
              })
            }
            return item
          })
        })
    case PIPELINE_TASK_BOARD_CREATE_SUCCESS:
      return state
        .updateIn(['listing', 'data'], (data) => (data as TaskItem[]).concat(action.payload))
    default:
      return state
  }
}