const tokenStoreName = 'token'

export const setToken = (token: string) => localStorage.setItem(tokenStoreName, token)

export const getToken = () => localStorage.getItem(tokenStoreName)

export const removeToken = () => localStorage.removeItem(tokenStoreName)

//Tutorials
export const setTutorial = (tutorial: string) => localStorage.setItem(tutorial, 'true')
export const getTutorial = (tutorial: string) => localStorage.getItem(tutorial)
export const removeTutorial = (tutorial: string) => localStorage.removeItem(tutorial)



