import React from 'react'
import styles from './index.module.scss'
import { Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

// Filter icons
import { ReactComponent as PscSvg } from 'assets/svg-icons/opportunity-work/type-of-work-1.svg'
import { ReactComponent as NaicsSvg } from 'assets/svg-icons/opportunity-work/type-of-work-3.svg'
import { ReactComponent as DepartmentSvg } from 'assets/svg-icons/opportunity-header/sub-agency.svg'
import { ReactComponent as DateSvg } from 'assets/svg-icons/opportunity-header/due-in.svg'
import { ReactComponent as SetAsideSvg } from 'assets/svg-icons/opportunity-header/set-aside.svg'
import { ReactComponent as GovConSvg } from 'assets/svg-icons/opportunity-header/type.svg'
import { ReactComponent as LocationSvg } from 'assets/svg-icons/filters/location.svg'
import { ReactComponent as VehicleSvg } from 'assets/svg-icons/filters/vehicle.svg'
import { ReactComponent as DcaaSvg } from 'assets/svg-icons/filters/dcaa.svg'
import { ReactComponent as SizeSvg } from 'assets/svg-icons/filters/size.svg'

export type IconTypes = 'psc' | 'naics' | 'department' | 'setAside' |
  'date' | 'govCon' | 'size' | 'vehicle' | 'location' | 'dcaa' | 'bars' | 'scope' | 'document' | 'task' |
  'documentInfo' | 'dollar' | 'plus'

export const iconSize = {
  width: 24,
  height: 24
}

export const IconStrategy: {[key in IconTypes]: JSX.Element} = {
  psc: <PscSvg/>,
  naics: <NaicsSvg/>,
  department: <DepartmentSvg/>,
  govCon: <GovConSvg/>,
  setAside: <SetAsideSvg/>,
  date: <DateSvg/>,
  location: <LocationSvg/>,
  dcaa: <DcaaSvg/>,
  vehicle: <VehicleSvg style={{height: '26px'}}/>,
  size: <SizeSvg/>,
  bars: <SvgIcon name='bars' width={iconSize.width} height={iconSize.height} />,
  scope: <SvgIcon name='telescope' width={iconSize.width} height={iconSize.height} />,
  document: <SvgIcon name='file-alt' width={iconSize.width} height={iconSize.height} />,
  task: <SvgIcon name='check' width={iconSize.width} height={iconSize.height} />,
  documentInfo: <SvgIcon name='document-info' width={iconSize.width} height={iconSize.height} />,
  dollar: <SvgIcon color='#6E7191' name='dollar-alt' width={iconSize.width} height={iconSize.height} />,
  plus: <SvgIcon color='#6E7191' name='plus' width={iconSize.width} height={iconSize.height} />,
}

interface Props {
  title: string
  selectedLength?: number
  iconType?: IconTypes
  allCount?: number
}

const renderTitle = (title: string, count?: number, all?: number) => {
  if (!count || count === 0) {
    return title
  }
  if (all === count) {
    return `${title} - ALL`
  }
  return `${title} - ${count}`
}

const FilterTitle = ({title, selectedLength, iconType, allCount}: Props) => {
  return (
    <div className={styles['title-block']}>
      <div className={styles['icon']}>
        {iconType && IconStrategy[iconType]}
      </div>
      <Typography className={styles['title']}>
        {renderTitle(title, selectedLength, allCount)}
      </Typography>
    </div>
  )
}

export default FilterTitle
