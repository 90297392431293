import { ISetAsideResponseModel } from 'models/hp/set-aside/set-aside.interface'
import { SetAsideResponseModel } from 'models/hp/set-aside/set-aside.model'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'

export const getSetAsidesAction = async (): Promise<ISetAsideResponseModel> => {
  try {
    const response = await request<ISetAsideResponseModel>(RequestMethods.Get, Paths.Hp.SetAsides.getAll, {
      schema: SetAsideResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.erros
  }
}

export const createSetAsideAction = async (searchId: number, setAsideIds: number[]): Promise<void> => {
  try {
    await request(RequestMethods.Post, Paths.Hp.SetAsides.create(searchId), {
      body: { setAsideIds }
    })
  } catch (error) {
    throw error.response.data.errors
  }
}