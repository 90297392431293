import React, { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR_MESSAGES } from 'utils/message'
import { useNotification } from 'context/NotificationContext'
import { getUserAction, updateProfile } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { userBaseResponseSelector } from 'store/common/user/selectors'
import { Formik, FormikProps } from 'formik'
import FormRenderer from './FormRenderer'

export interface IProfile {
  firstName: string
  lastName: string
}

export const PROFILE_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Field')),
  lastName: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Field'))
})

interface Props {
  cancelUpdate: () => void
}

const Form = ({ cancelUpdate }: Props) => {
  const dispatch = useDispatch()
  const { show } = useNotification()

  const [ isRequested, setIsRequested ] = useState(false)
  const user = useSelector(userBaseResponseSelector)

  const onSubmit = async (values: IProfile) => {
    setIsRequested(true)
    try {
      const { firstName, lastName } = values
      const response = await updateProfile(firstName, lastName, user.data?.email!)
      getUserAction()(dispatch)

      setIsRequested(false)
      show(response?.message || 'User data successfully updated', NotificationTypeList.Success)
      cancelUpdate() // close form
    } catch (error) {
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
      setIsRequested(false)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          lastName: user.data?.lastName || '',
          firstName: user.data?.firstName || ''
        }}
        validationSchema={PROFILE_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formProps: FormikProps<IProfile>) => (
          <FormRenderer
            {...formProps}
            isRequested={isRequested}
            cancelUpdate={cancelUpdate}
          />
        )}
      </Formik>
    </div>
  )
}

export default Form