import React from 'react'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { agenciesSelector, agencyCountSelector } from 'store/hp/agency/selectors'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean) => void
  selectedOptions: string[]
}

const DepartmentFilter = ({ handleChange, handlePartialChange, selectedOptions }: Props) => {
  const agenciesOptions: IFilterOption[] = useSelector(agenciesSelector) as IFilterOption[]
  const agencyCount: number = useSelector(agencyCountSelector)!

  return (
    <div className={styles['paper']}>
      <SearchFilter
        searchLabel='Customers'
        allCount={agencyCount}
        iconType='department'
        filterTitle='Customers'
        filterOptions={agenciesOptions}
        selectedFilterOptions={selectedOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default React.memo(DepartmentFilter, (prev, next) => prev.selectedOptions === next.selectedOptions)