import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useNotification } from 'context/NotificationContext'
import { useDispatch, useSelector } from 'react-redux'
import { awardsFiltersSelector } from 'store/hp/awards/search/selectors'
import SearchFilters from './SearchFilters/SearchFilters'
import SubAgencySpending from './SubAgencySpending/SubAgencySpending'
import { NotificationTypeList } from 'components/NotificationType'
import { userSelector } from 'store/common/user/selectors'
import { awardsListingSelector } from 'store/hp/awards/listing/selectors'
import SaveModal from './SaveModal/SaveModal'
import { UserBaseModel } from 'models/common/user/user.model'
import { clearCustomerFinderContacts, getCustomerFinderDashboard } from 'store/hp/education/action'
import { customerFinderDashboardSelector } from 'store/hp/education/selector'
import { createFulfilledSearch, getAndSetSearchDetails, resetSearchFilters, updateFulfilledSearch } from 'store/hp/search/actions'
import { ISearchDTO } from 'models/hp/search/search.interface'
import { resetFiltersInUrl } from 'helpers/search'
import { useHistory } from 'react-router-dom'
import SavedSearches from './SavedSearchs/SavedSearchs'


const CustomerFinder = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const history = useHistory()
  
  const awardsListing = useSelector(awardsListingSelector)
  const selectedFilters = useSelector(awardsFiltersSelector)
  const {
    data: customerFinderDashboarData,
    loading: customerFinderDashboarLoading,
    loaded: customerFinderDashboarLoaded
  } = useSelector(customerFinderDashboardSelector)
  const user = useSelector(userSelector) as UserBaseModel

  const [isViewTutorial, setViewTutorial] = useState<boolean>(true)
  const [selectedAgencies, setSelectedAgencies] = useState<number[]>([])
  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
    resetFiltersInUrl(history)
    resetSearchFilters(dispatch)()
  }, [])

  useEffect(() => {
    if(customerFinderDashboarLoaded) {
      setViewTutorial(false)
    } 
  }, [customerFinderDashboarData])

  const handleSearch = (keywords?: string[]) => {
    clearCustomerFinderContacts(dispatch)
    const body = keywords && Array.isArray(keywords) ? {...selectedFilters, keywords} : selectedFilters
    getCustomerFinderDashboard(dispatch)(body)
  }

  const handleSaveAgencies = async () => {
    setModalOpened(true)
  }
  
  const handleFinish = async (name: string) => {
    const selectedSubagenciesFilters: ISearchDTO = {
      allPscCodes: false,
      allGroupedCodes: false,
      allEstimatedValues: false,
      allEstimatedEfforts: false,
      allDueDates: false,
      allCustomers: true,
      allProjectTypes: true,
      allSetAsides: true,
      contractTypeIds: [],
      customerIds: selectedAgencies,
      dueDateIds: [],
      estimatedEffortIds: [],
      estimatedValueIds: [],
      excludeIds: [],
      groupedCodeIds: selectedFilters.naicsCodes.map(el => Number(el)),
      keywords: selectedFilters.keywords,
      locationIds: selectedFilters.locations.map(el => Number(el)),
      pastPerformance: null,
      projectTypeIds: [],
      pscCodeIds: selectedFilters.pscCodes.map(el => Number(el)),
      setAsideIds: [],
      workLocation: 'CONUS'
    }
    try {
      await createFulfilledSearch(dispatch)(selectedSubagenciesFilters, name, !user)
      notification.show('Search successfully created', NotificationTypeList.Success)
      setModalOpened(false)
      setSelectedAgencies([])
    } catch (e) {
      notification.show('Error happened, please try again', NotificationTypeList.Error)
    }
  }

  const handleSetAgencies = (agency: number, state: boolean) => {
    if (state) {
      setSelectedAgencies(prev => prev.concat(agency))
    } else {
      setSelectedAgencies(prev => prev.filter(x => x !== agency))
    }
  }

  const handleUpdateSearch = async (searchId: number) => {
    const search = await getAndSetSearchDetails(dispatch)(searchId)
    if(search) {
      updateFulfilledSearch(dispatch)({
        ...search,
        customerIds: selectedAgencies,
        keywords: selectedFilters.keywords,
        groupedCodeIds: selectedFilters.naicsCodes.map(el => Number(el)),
        pscCodeIds: selectedFilters.pscCodes.map(el => Number(el)),
        locationIds: selectedFilters.locations.map(el => Number(el)),
        type: undefined
      }, search.name, searchId)
        .then(() => {
          setModalOpened(false)
          setSelectedAgencies([])
          notification.show('Search successfully updated', NotificationTypeList.Success)
        })
        .catch(() => {
          notification.show('Error happened, please try again', NotificationTypeList.Error)
        })
    } else {
      notification.show('Error happened, please try again', NotificationTypeList.Error)
    }
  }

  return (
    <div className={styles['customer-finder']}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <SavedSearches isEducationFlow />
          <SearchFilters
            count={awardsListing.count}
            onSearch={handleSearch}
            onSaveAgencies={handleSaveAgencies}
            selectedAgencies={selectedAgencies}
          />
        </Grid>
        <Grid item md={9}>
          { (isViewTutorial && !customerFinderDashboarLoading && !customerFinderDashboarLoaded) ?
            <Grid container justifyContent='center'>
              <Paper className={styles['default-prompt']}>
                <Grid container >
                  <Typography variant='subtitle1' className={styles['default-prompt-text']}>
                Select a saved search or create a new search to find promising sub-agency customers
                  </Typography>
                </Grid>
              </Paper> 
            </Grid>
            : null
          }
          {
            !customerFinderDashboarLoading && (!customerFinderDashboarData?.agencies.length && customerFinderDashboarLoaded) ? (
              <Grid container justifyContent='center'>
                <Paper className={styles['default-prompt']}>
                  <Grid container >
                    <Typography variant='subtitle1' className={styles['default-prompt-text']}>
                      Not found, please try again
                    </Typography>
                  </Grid>
                </Paper> 
              </Grid>
            ) : null
          }
          { !customerFinderDashboarLoading && customerFinderDashboarData?.agencies.length ?
            <SubAgencySpending selectedAgencies={selectedAgencies} handleSetAgencies={handleSetAgencies}  /> : null
          }
          { customerFinderDashboarLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )}
        </Grid>
      </Grid>
      {
        modalOpened && (
          <SaveModal
            handleSave={handleFinish}
            onClose={() => setModalOpened(false)}
            handleUpdateSearch={handleUpdateSearch}
          />
        )
      }
    </div>
  )
}

export default CustomerFinder