import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { ISearchDTO, ISearchLightModel } from 'models/hp/search/search.interface'
import {
  savedSearchesLoadedSelector,
  savedSearchesLoadingSelector,
  savedSearchesSelector,
  selectedSearchLoadingSelector,
  selectedSearchSelector
} from 'store/hp/search/selectors'
import { deleteSearch, getAndSetSearchDetails, getSavedSearches, resetSearchFilters, setSearchFilterData } from 'store/hp/search/actions'
import SvgIcon from 'components/SvgIcon'
import { SearchModel } from 'models/hp/search/search.model'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import styles from './index.module.scss'
import { resetFiltersInUrl } from 'helpers/search'
import { useHistory } from 'react-router-dom'
import { setAwardSearchFilterValueBool } from 'store/hp/awards/search/actions'
import { clearCustomerFinderContacts, getCustomerFinderDashboard } from 'store/hp/education/action'

interface SavedSearchesProps {
  isEducationFlow?: boolean
}

const SavedSearches = ({isEducationFlow = false}: SavedSearchesProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const notification = useRef(useNotification())

  const savedSearches: ISearchLightModel[] = useSelector(savedSearchesSelector) as ISearchLightModel[]
  const savedSearchesLoading = useSelector(savedSearchesLoadingSelector)
  const savedSearchesLoaded = useSelector(savedSearchesLoadedSelector)

  const selectedSearch: SearchModel = useSelector(selectedSearchSelector) as SearchModel
  const selectedSearchLoading = useSelector(selectedSearchLoadingSelector) as boolean

  const [activeSearchId, setActiveSearchId] = React.useState<number | null>(null)

  useEffect(() => {
    if (!savedSearchesLoaded) {
      getSavedSearches(dispatch)()
    }
  }, [])

  useEffect(() => {
    setActiveSearchId(selectedSearch ? selectedSearch.id : null)
  }, [selectedSearch])

  const handleSavedSearchConfirm = async (search: SearchModel | null) => {
    if (search !== null) {
      if(isEducationFlow) {
        clearCustomerFinderContacts(dispatch)
        setAwardSearchFilterValueBool(dispatch)('keywords', search.keywords)
        setAwardSearchFilterValueBool(dispatch)('naicsCodes', search.groupedCodes.map(el => el.id) as unknown as string[])
        setAwardSearchFilterValueBool(dispatch)('pscCodes', search.pscCodes.map(el => el.id) as unknown as string[])
        setAwardSearchFilterValueBool(dispatch)('locations', search.states.map(el => el.id) as unknown as string[])
        getCustomerFinderDashboard(dispatch)({
          keywords: search.keywords,
          pscCodes: search.pscCodes.map(el => el.id) as unknown as string[],
          naicsCodes: search.groupedCodes.map(el => el.id) as unknown as string[],
          locations: search.states.map(el => el.id) as unknown as string[],
          customers: [],
          setAsides: [],
          types: [],
          duns: [],
          values: [],
          dates: [],
          startDate: null,
          endDate: null,
          isIdv: false
        })
      }
      setSearchFilterData(dispatch)(search as ISearchDTO) // set search parameters as selected filters in store
    } else {
      resetSearchFilters(dispatch)()
    }
  }

  const handleSetActiveSearch = async (searchId: number) => {
    if (activeSearchId === searchId) {
      return setActiveSearchId(null)
    } else {
      setActiveSearchId(searchId)
      //get contracts by active search
      const search = await getAndSetSearchDetails(dispatch)(searchId)
      await handleSavedSearchConfirm(search)
    }
  }

  const handleRemove = (searchId: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // don't select removed element
    if (selectedSearch && selectedSearch.id === searchId) {
      resetFiltersInUrl(history) // first
      resetSearchFilters(dispatch)()
    }
    try {
      await deleteSearch(dispatch)(searchId)
      notification.current.show('Search deleted successfully', NotificationTypeList.Success)
    }
    catch (e) {
      notification.current.show('Error happened, please try again', NotificationTypeList.Error)
    }
  }

  const savedSearchesExist = savedSearchesLoaded && savedSearches?.length > 0
  const body = () => {
    if (savedSearchesLoading) {
      return (
        <Grid container alignItems='center' justifyContent='center'>
          <CircularProgress size={30}/>
        </Grid>
      )
    }
    if (!savedSearchesExist) {
      return (
        <Typography align='center' variant='h6'>No searches found.</Typography>
      )
    }
    return (
      <Grid container direction='column'>
        {savedSearches?.map(search => (
          <Grid
            key={search.id}
            item
            onClick={() => handleSetActiveSearch(search.id)}
          >
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-between'}
              wrap={'nowrap'}
              className={styles['search-item']}
              style={{
                border: `2px solid ${theme.palette.grey['200']}`,
                backgroundColor: activeSearchId === search.id ? theme.palette.grey['200'] : theme.palette.primary.contrastText
              }}
            >
              <Grid item>
                <Typography className={styles['search-title']}>{search.name}</Typography>
              </Grid>
              <Grid item>
                {
                  search.id === activeSearchId && selectedSearchLoading ? <CircularProgress size={30}/> : <Button
                    onClick={handleRemove(search.id)}
                  >
                    <SvgIcon name='trash' width={18} height={18} color={theme.palette.grey['500']}/>
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }
  return (
    <Paper className={styles['card']}>
      <Typography variant='h4'>Saved Searches</Typography>
      <div className={styles['saved-searches']}>{
        body()
      }</div>
    </Paper>
  ) 
}

export default SavedSearches
