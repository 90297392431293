import React, { useContext, useEffect, useState } from 'react'
import { RouteProps, useLocation } from 'react-router-dom'
import * as H from 'history'

interface IRouterStore {
  prev: H.Location | null
  current: H.Location | null
  prev2: H.Location | null
}

const RouterContext = React.createContext<IRouterStore>({
  current: null,
  prev: null,
  prev2: null
})

interface Props extends RouteProps {
}

export const RouterProvider = ({ children }: Props) => {
  const location = useLocation()

  const [prevLocation, setPrevLocation] = useState<IRouterStore>({
    current: null,
    prev: null,
    prev2: null
  })

  useEffect(() => {
    setPrevLocation(prev => ({ current: location, prev: prev?.current, prev2: prev?.prev }))
  }, [location])

  return (
    <RouterContext.Provider value={prevLocation}>
      {children}
    </RouterContext.Provider>
  )
}

export const useRouter = () => useContext<IRouterStore>(RouterContext)
