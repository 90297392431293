import { Dispatch } from 'redux'
import { request } from 'utils/http'
import {
  PipelineTaskCreateResponse,
  TaskItem,
} from 'models/hp/pipeline-task/pipeline-task.model'
import { PipelineTaskDto } from 'models/hp/pipeline-task/pipeline-task.interface'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { BaseResponseModel } from 'models/base/base.model'
import { UserBaseModel } from 'models/common/user/user.model'
import moment from 'moment'

export const PIPELINE_TASK_BOARD_MANAGE_SUCCESS = 'PIPELINE_TASK_BOARD_MANAGE_SUCCESS'
export const PIPELINE_TASK_BOARD_CREATE_SUCCESS = 'PIPELINE_TASK_BOARD_CREATE_SUCCESS'

export const managePipelineTask = (dispatch: Dispatch) => async (taskId: string, dto: PipelineTaskDto) => {
  // to avoid delay, dispatch goes first
  if (dto.assignedUserId) {
    dto.assignedUser = new UserBaseModel()
    dto.assignedUser.id = dto.assignedUserId
  }
  if (dto.date) {
    dto.date = moment(dto.date).format('YYYY-MM-DD') as unknown as Date
  }
  dispatch({
    type: PIPELINE_TASK_BOARD_MANAGE_SUCCESS,
    payload: {
      taskId, dto
    }
  })
  await request<BaseResponseModel>(RequestMethods.Put, Paths.Hp.Pipeline.managePipelineTask(taskId), {
    schema: BaseResponseModel,
    body: dto
  })
}

export const createPipelineTask = (dispatch: Dispatch) => async (dto: PipelineTaskDto): Promise<TaskItem> => {
  const res = await request<PipelineTaskCreateResponse>(RequestMethods.Post, Paths.Hp.Pipeline.createPipelineTask(), {
    schema: PipelineTaskCreateResponse,
    body: dto
  })
  const task = res.data.data
  if (task.assignedUserId && !task.assignedUser) {
    task.assignedUser = new UserBaseModel()
    task.assignedUser.id = task.assignedUserId
  }
  dispatch({
    type: PIPELINE_TASK_BOARD_CREATE_SUCCESS,
    payload: task
  })

  return res.data.data
}
