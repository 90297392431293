import { IRootState } from '../../reducers'
import { createSelector } from 'reselect'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'
import { QualifyType } from 'models/common/calculators/qualifying/qualifying.interface'
import { TActivities, TEvalueationCriteria, TOpenQuestions, TPastPerformance, TProcess, TSummary, TWinThemes } from 'models/hp/rfp/rfp.interface'
import { NotesMapper, PrepareNotes, TabType } from 'models/hp/rfp/calc/calc.interface'
import { mapSort } from 'modules/Main/Hp/RfpInnerRoutesNew/pages/Outline/components/OutlineHeaders/linkedListHelpers'

const hpRfpListing = (state: IRootState) => state.hpRfpListingReducer
const hpRfpDetails = (state: IRootState) => state.hpRfpDetailsReducer

export const hpRfpListingDataSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.get('listing')
)

export const hpRfpUniqueListingDataSelector = createSelector(
  hpRfpListing,
  hpRfpListing => (hpRfpListing.getIn(['listing', 'data']) as RfpTinderModel[]).filter((item, idx, self) => 
    idx === self.findIndex(x => x.id === item.id)
  )
)

export const hpRfpListingFilterParamsSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.get('filterParams')
)

export const hpRfpListingLoadedSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.getIn(['listing', 'loaded'])
)

export const hpRfpListingLoadingSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.getIn(['listing', 'loading'])
)

export const hpRfpDetailsSelector = createSelector(
  hpRfpDetails,
  hpRfpDetail => hpRfpDetail.toJSON()
)

export const downloadDocumentsLinkSelector = createSelector(
  hpRfpDetails,
  hpRfpDetail => hpRfpDetail.get('downloadDocumentsLink')
)

export const hpRfpCategorizedLoadedSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['categorized', 'loaded'])
)
export const hpRfpCategorizedLoadingSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['categorized', 'loading'])
)
export const hpRfpCategorizedSegmentSelector = (type: string) => createSelector(
  hpRfpListing,
  listing => listing.getIn(['categorized', type])
)

export const hpRfpContractsShouldLoadMoreSelector = createSelector(
  hpRfpListing,
  hpRfpListing => {
    const listing = hpRfpListing.get('listing')
    return listing.count > listing.data.length
  }
)

export const hpRfpTrashedLoadingSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['trashed', 'loading'])
)
export const hpRfpTrashedLoadedSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['trashed', 'loaded'])
)
export const hpRfpTrashedSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['trashed', 'data'])
)
export const hpRfpTrashedCountSelector = createSelector(
  hpRfpListing,
  listing => listing.getIn(['trashed', 'count'])
)
export const hpRfpTrashedShowLoadMoreSelector = createSelector(
  hpRfpListing,
  hpRfpListing => {
    const listing = hpRfpListing.get('listing')
    return listing.count > listing.data.length
  }
)

export const goNoGoLoadingSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.getIn(['goNoGoSuggestions', 'loading'])
)
export const goNoGoDataSelector = createSelector(
  hpRfpListing,
  hpRfpListing => hpRfpListing.getIn(['goNoGoSuggestions', 'data'])
)

export const getPipelineRfpSteps = createSelector(
  hpRfpDetails,
  (details) => details.get('steps')
)

export const getPipelineRfpCalculators = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').overview
)

export const getPipelineRfpCalculatorsRiskAndEffort = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.risk
)

export const getPipelineRfpCalculatorsQualify = (type: QualifyType) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.pWin[type]
)

export const getPipelineRfpCalculatorsQualifySub = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.pWin.sub
)

export const getPipelineRfpCalculatorsQualifyPrime = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.pWin.prime
)

export const getPipelineRfpCalculatorsQualifyPrimeSBIR = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.pWin.sbir
)

export const getPipelineRfpCalculatorsTasks = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.tasks
)

export const getPipelineRfpCalculatorsIncumbents = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.incumbents
)

export const getPipelineRfpCalculatorsIncumbentPerformance = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.incumbentPerformance
)

export const getPipelineRfpCalculatorsSubsequentAwards = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.subsequentAwards
)

export const getPipelineRfpCalculatorsCustomerConnection = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.customerConnection
)

export const getPipelineRfpCalculatorsCompetitorLandscape = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.competitorLandscape
)

export const getPipelineRfpCalculatorsCompetitivePosition = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.competitivePosition
)

export const getPipelineRfpCalculatorsSummary = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.summary
)

export const getPipelineRfpCalculatorsSummaryKey = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.summary.data.reduce((acc, item) => {
    return {...acc, [item.key]: item}
  }, {})
)

export const getPipelineRfpCalculatorsSummaryCount = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.summary.data.reduce((acc, item) => {
    return {...acc, [item.key]: item.count.user !== 0 ? item.count.user : item.count.incumbent}
  }, {})
)

export const getPipelineRfpCalculatorsSelfAssessment = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.selfAssessment
)

export const getPipelineRfpCalculatorsSelfAssessmentIdentify = createSelector(
  hpRfpDetails,
  (details) => {
    return details.get('calculators').qualifying.selfAssessment.data.input.criteria
      .reduce((acc, item) => {
        if(item) {
          return {
            ...acc,
            [item.id]: item.quote
          }
        }
        return acc
      }, {} as {
      [key: string]: string
    })
  }
)

export const getPipelineRfpCalculatorsSelfAssessmentSelfScore = createSelector(
  hpRfpDetails,
  (details) => {
    return details.get('calculators').qualifying.selfAssessment.data.input.criteria
      .reduce((acc, item) => {
        if(item) {
          return {...acc, [item.id]: item.evaluation.value}
        }
        return acc
      }, {})
  }
)

export const getPipelineRfpCalculatorsSubbing = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').qualifying.subbing
)

export const getStrategyCalc = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy
)

export const getStrategyWinThemes = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.winThemes
)

export const getStrategyWinThemesBySubType = (subType: TWinThemes) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.winThemes.data.filter(el => el.subType === subType)
)

export const getStrategyActivities = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.activities
)

export const getStrategyActivitiesBySubType = (subType: TActivities) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.activities.data.filter(el => el.subType === subType)
)

export const getStrategyPastPerformance = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.pastPerformance
)

export const getStrategyPastPerformanceBySubType = (subType: TPastPerformance) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.pastPerformance.data.filter(el => el.subType === subType)
)

export const getStrategyPastPerformanceRecommendations = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.pastPerformanceRecommendations
)

export const getStrategyPastPerformanceBySubTypeRecommendations = (subType: TPastPerformance) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.pastPerformanceRecommendations.data.filter(el => el.subType === subType)
)

export const getStrategyEvaluationCriteria = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.evaluationCriteria
)

export const getStrategyEvaluationCriteriaBySubType = (subType: TEvalueationCriteria) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.evaluationCriteria.data.filter(el => el.subType === subType)
)

export const getStrategyProcess = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.process
)

export const getStrategyProcessBySubType = (subType: TProcess) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.process.data.filter(el => el.subType === subType)
)

export const getStrategyOpenQuestions = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.openQuestions
)

export const getStrategyOpenQuestionsBySubType = (subType: TOpenQuestions) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.openQuestions.data.filter(el => el.subType === subType)
)

export const getStrategyProcessNaics = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.processNaics
)

export const getStrategyProcessSetAside = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.processSetAside
)

export const getStrategySummary = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.summary
)

export const getStrategySummaryBySubType = (subType: TSummary) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').strategy.summary.data.filter(el => el.subType === subType)
)

export const getResponseNotes = (type: TabType) => createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').response[type]
)

export const getResponseOutline = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').response.outline
)

export const getResponseCompliance = createSelector(
  hpRfpDetails,
  (details) => details.get('calculators').response.compliance
)

export const getResponseOutlinePrepairData = (type: TabType) => createSelector(
  hpRfpDetails,
  (details) => {
    const notes = details.get('calculators').response[type].data

    if(type === 'compliance') {
      return mapSort(notes).map(el => ({
        id: el.id,
        title: el,
        context: []
      }))
    }

    // order parents by linked list first
    const orderedParents = mapSort(notes.filter(note => note.content === 'title'))
    const orderedParentIds = orderedParents.map(x => x.id)

    // then order it's children (context items)
    return orderedParentIds.reduce((acc, item) => {
      const title = notes.find(el => el.parentId === item && el.content === 'title') || null
      const context = notes.filter(el => el.parentId === item && el.content === 'context')

      if (!title) return acc

      return [...acc, {
        ...title,
        context: mapSort(context)
      }]
    }, [] as PrepareNotes[])
  }
)

export const getResponseOutlineMappedData = (type: TabType) => createSelector(
  hpRfpDetails,
  details => {
    const notes = details.get('calculators').response[type].data
    return notes.reduce<NotesMapper>((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
  }
)