import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { SapItem } from 'models/hp/market-research/market-research.model'
import ListingItem from './Item'
import { BigHint, SmallHint } from './components/ListingHints'

export interface ICustomer {
  agency: string
  subAgency: string
  office: string
}

export interface ICustomerMapper {
  [key: string]: ICustomer | undefined
}

interface Props {
  listingData: SapItem[]
  listingLoading: boolean,
  customers: ICustomerMapper
  addCustomer: (customer: ICustomer) => void
}

let initLoading = true

const Listing = ({ listingData, listingLoading, customers, addCustomer }: Props) => {
  const [hintClosed, setHintClosed] = React.useState(false)

  const renderBody = () => {
    if (listingLoading) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <CircularProgress color='primary' />
        </Grid>
      )
    }

    if (!initLoading && listingData.length === 0) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <Typography variant='h4'>Nothing was found, please try another filters</Typography>
        </Grid>
      )
    }

    return listingData.map((item, idx) => (
      <ListingItem
        key={idx}
        item={item}
        addCustomer={addCustomer}
        customers={customers}
      />
    ))
  }

  useEffect(() => {
    return () => {
      initLoading = true
    }
  }, [])

  if (listingData.length > 0 && initLoading) {
    initLoading = false
  }

  if (listingData.length === 0 && initLoading) {
    if (!hintClosed) {
      return <BigHint close={() => setHintClosed(true)} />
    } else {
      initLoading = false
      return <SmallHint />
    }
  }

  return (
    <Grid
      container
      spacing={2}
      className={styles['list']}
      direction='column'
      wrap='nowrap'
      justifyContent='flex-start'
    >
      {renderBody()}
    </Grid>
  )
}

export default Listing