import React, { useEffect } from 'react'
import { Typography, Button, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { savedSearchesSelector, savedSearchesLoadedSelector, savedSearchesLoadingSelector } from 'store/hp/search/selectors'
import { ISearchLightModel } from 'models/hp/search/search.interface'
import { getSavedSearches } from 'store/hp/search/actions'
import CustomLoader from 'components/CustomLoader'
import SvgIcon from 'components/SvgIcon'
import { getKeywordsBySearchId } from 'store/hp/keyword/actions'

interface Props {
  handleSelectKeywords?: (keywords: string[]) => void
}

const SearchKeywords = ({ handleSelectKeywords }: Props) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const savedSearches = useSelector(savedSearchesSelector) as ISearchLightModel[]
  const savedSearchesLoading = useSelector(savedSearchesLoadingSelector)
  const savedSearchesLoaded = useSelector(savedSearchesLoadedSelector)

  const handleSearchClick = async (searchId: number) => {
    const { data: keywords } = await getKeywordsBySearchId(searchId)
    if (keywords.length > 0 && handleSelectKeywords) {
      handleSelectKeywords(keywords.map(keyword => keyword.name))
    }
  }

  useEffect(() => {
    if (!savedSearchesLoaded) {
      getSavedSearches(dispatch)()
    }
  }, [])

  const renderBody = () => {
    if (savedSearchesLoading) return (
      <div style={{paddingTop: '12px'}}><CustomLoader size={24} /></div>
    )
    if (savedSearches?.length === 0) return (
      <Typography>No saved searches found</Typography>
    )
    return (
      <div style={{paddingTop: '8px'}}>
        {savedSearches?.map(search => (
          <Button
            key={search.id}
            onClick={() => handleSearchClick(search.id)}
            variant='text'
            fullWidth
            startIcon={<SvgIcon name='arrow-right' color={theme.palette.warning.main} />}
            style={{justifyContent: 'flex-start'}}
          >
            {search.name}
          </Button>
        ))}
      </div>
    )
  }

  return (
    <div>
      <Typography variant='h5'>Use keywords from a saved search:</Typography>
      {renderBody()}
    </div>
  )
}

export default SearchKeywords