import { IEducationPhaseClass } from 'models/hp/education/education.interface'

export enum EDUCATION_SUBTITLE {
  EDUCATION_OVERVIEW = 'EDUCATION_OVERVIEW',
  WORKBOOK = 'WORKBOOK',
  GOVERNMENT_MARKET_BASICS = 'GOVERNMENT_MARKET_BASICS',
  FIRST_YEAR_OF_CONTRACTING = 'FIRST_YEAR_OF_CONTRACTING',
  COMMON_PATH_FOR_NEW_VENDOR = 'COMMON_PATH_FOR_NEW_VENDOR',
  SET_ASIDES = 'SET_ASIDES',
  HOW_GOVERNMENT_BUYS = 'HOW_GOVERNMENT_BUYS',
  HOW_GOVERNMENT_ORGANIZED = 'HOW_GOVERNMENT_ORGANIZED',
  HOW_COMMUNITY_ORGANIZED = 'HOW_COMMUNITY_ORGANIZED',
  FINDING_BUDGET = 'FINDING_BUDGET',
  WHAT_TO_SELL = 'WHAT_TO_SELL',
  PRODUCTS_SERVICES_BRAINSTORM = 'PRODUCTS_SERVICES_BRAINSTORM',
  IDENTIFY_YOUR_KEYWORDS = 'IDENTIFY_YOUR_KEYWORDS',
  IDENTIFY_YOUR_CODES = 'IDENTIFY_YOUR_CODES',
  DIFFERENTIATORS = 'DIFFERENTIATORS',
  REGISTRATIONS = 'REGISTRATIONS',
  INCORPORATE_FEDERAL_STATE_REGISTRATIONS = 'INCORPORATE_FEDERAL_STATE_REGISTRATIONS',
  DUNS_REGISTRATIONS = 'DUNS_REGISTRATIONS',
  SAM_REGISTRATIONS = 'SAM_REGISTRATIONS',
  SBIR_REGISTRATIONS = 'SBIR_REGISTRATIONS',
  SET_ASIDES_REGISTRATIONS = 'SET_ASIDES_REGISTRATIONS',
  YOUR_RATES = 'YOUR_RATES',
  CUSTOMERS = 'CUSTOMERS',
  MOST_PROMISING_CUSTOMERS = 'MOST_PROMISING_CUSTOMERS',
  CUSTOMERS_ANALYSIS = 'CUSTOMERS_ANALYSIS',
  CUSTOMERS_KEY_PERSONNEL = 'CUSTOMERS_KEY_PERSONNEL',
  CUSTOMER_CONTACTS = 'CUSTOMER_CONTACTS',
  PARTNERS = 'PARTNERS',
  MOST_PROMISING_PARTNERS = 'MOST_PROMISING_PARTNERS',
  PROMISING_PARTNERS = 'PROMISING_PARTNERS',
  PARTNERS_KEY_PERSONNEL = 'PARTNERS_KEY_PERSONNEL',
  PARTNERS_CONTACTS = 'PARTNERS_CONTACTS',
  MARKETING = 'MARKETING',
  MARKETING_GOALS = 'MARKETING_GOALS',
  MARKETING_CONCEPTS = 'MARKETING_CONCEPTS',
  PLANNING_YOUR_MARKETING = 'PLANNING_YOUR_MARKETING',
  RELATIONSHIP_BUILDING = 'RELATIONSHIP_BUILDING',
  RELATIONSHIP_BUILDING_GOALS = 'RELATIONSHIP_BUILDING_GOALS',
  RELATIONSHIP_BUILDING_PLAN = 'RELATIONSHIP_BUILDING_PLAN',
  COLLATERAL = 'COLLATERAL',
  COLLATERAL_GOALS = 'COLLATERAL_GOALS',
  COLLATERAL_SAP_MICRO_OFFERINGS = 'COLLATERAL_SAP_MICRO_OFFERINGS',
  COLLATERAL_WEBPAGE_CARDS = 'COLLATERAL_WEBPAGE_CARDS',
  RESPONSIVE_LIBRARY = 'RESPONSIVE_LIBRARY',
  RESPONSIVE_LIBRARY_SHAPING = 'RESPONSIVE_LIBRARY_SHAPING',
  RESPONSIVE_LIBRARY_PAST_PERFORMANCE_BIOS_RESUMES = 'RESPONSIVE_LIBRARY_PAST_PERFORMANCE_BIOS_RESUMES',
  RFIs_RFPs = 'RFIs_RFPs',
  RFIs = 'RFIs',
  READING_ASSESSING_RFPs = 'READING_ASSESSING_RFPs',
  PLANNING_A_COMPLIANT_PROPOSAL = 'PLANNING_A_COMPLIANT_PROPOSAL',
  PIPELINE = 'PIPELINE',
  PIPELINE_BASICS = 'PIPELINE_BASICS',
  NEAR_TERM_OPPORTUNITIES = 'NEAR_TERM_OPPORTUNITIES',
  MID_TERM_OPPORTUNITIES = 'MID_TERM_OPPORTUNITIES',
  LONG_TERM_OPPORTUNITIES = 'LONG_TERM_OPPORTUNITIES',
  PROGRESS_FEEDBACK = 'PROGRESS_FEEDBACK'
}

export const comingSoonEducationPages = [
  EDUCATION_SUBTITLE.PLANNING_YOUR_MARKETING,
  EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING_PLAN,
  EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING_PLAN,
  EDUCATION_SUBTITLE.CUSTOMERS_ANALYSIS,
]

export const EDUCATION_IFRAME_SOURCE: { [key: string]: string | undefined } = {
  [EDUCATION_SUBTITLE.EDUCATION_OVERVIEW]: 'https://www.fedscout.com/education-overview',
  [EDUCATION_SUBTITLE.WORKBOOK]: 'https://www.fedscout.com/workbooks-and-downloads',
  [EDUCATION_SUBTITLE.FIRST_YEAR_OF_CONTRACTING]: 'https://www.fedscout.com/contractor-stages-of-development',
  [EDUCATION_SUBTITLE.COMMON_PATH_FOR_NEW_VENDOR]: 'https://www.fedscout.com/typical-models-of-contracting-success',
  [EDUCATION_SUBTITLE.SET_ASIDES]: 'https://www.fedscout.com/a-guide-to-set-asides-woman-veteran-minority-8a-and-hubzone-0',
  [EDUCATION_SUBTITLE.HOW_GOVERNMENT_BUYS]: 'https://www.fedscout.com/the-contracting-process',
  [EDUCATION_SUBTITLE.HOW_GOVERNMENT_ORGANIZED]: 'https://www.fedscout.com/structure-of-the-government-market',
  [EDUCATION_SUBTITLE.HOW_COMMUNITY_ORGANIZED]: 'https://www.fedscout.com/structure-of-the-contractor-community',
  [EDUCATION_SUBTITLE.FINDING_BUDGET]: 'https://www.fedscout.com/the_budget_and_how_money_moves',
  [EDUCATION_SUBTITLE.PRODUCTS_SERVICES_BRAINSTORM]: 'https://www.fedscout.com/selecting-a-product-or-service',
  [EDUCATION_SUBTITLE.DIFFERENTIATORS]: 'https://www.fedscout.com/differentiating-your-offering',
  [EDUCATION_SUBTITLE.INCORPORATE_FEDERAL_STATE_REGISTRATIONS]: 'https://www.fedscout.com/incorporation-federal-and-state-registration',
  [EDUCATION_SUBTITLE.DUNS_REGISTRATIONS]: ' https://www.fedscout.com/registering-in-db-duns',
  [EDUCATION_SUBTITLE.SAM_REGISTRATIONS]: 'https://www.fedscout.com/registering-in-sam',
  [EDUCATION_SUBTITLE.SBIR_REGISTRATIONS]: 'https://www.fedscout.com/registering-sbirgov',
  [EDUCATION_SUBTITLE.SET_ASIDES_REGISTRATIONS]: 'https://www.fedscout.com/a-guide-to-set-asides-woman-veteran-minority-8a-and-hubzone-0',
  [EDUCATION_SUBTITLE.YOUR_RATES]: 'https://www.fedscout.com/setting-wrap-fringe-ga-and-overhead',
  [EDUCATION_SUBTITLE.MOST_PROMISING_CUSTOMERS]: 'https://www.fedscout.com/choosing-your-first-government-customers',
  [EDUCATION_SUBTITLE.CUSTOMERS_KEY_PERSONNEL]: 'https://www.fedscout.com/finding-program-officers',
  [EDUCATION_SUBTITLE.MOST_PROMISING_PARTNERS]: 'https://www.fedscout.com/choosing-your-first-partners',
  [EDUCATION_SUBTITLE.PARTNERS_KEY_PERSONNEL]: ' https://www.fedscout.com/finding-key-partner-personnel',
  [EDUCATION_SUBTITLE.MARKETING_GOALS]: 'https://www.fedscout.com/business-marketing',
  [EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING_GOALS]: 'https://www.fedscout.com/building-partner-relationships',
  [EDUCATION_SUBTITLE.COLLATERAL_GOALS]: 'https://www.fedscout.com/collateral-goals',
  [EDUCATION_SUBTITLE.COLLATERAL_SAP_MICRO_OFFERINGS]: 'https://www.fedscout.com/sap_and_micro_purchaess',
  [EDUCATION_SUBTITLE.COLLATERAL_WEBPAGE_CARDS]: 'https://www.fedscout.com/collateral-content',
  [EDUCATION_SUBTITLE.RESPONSIVE_LIBRARY_SHAPING]: 'https://www.fedscout.com/past-perform-key-personnel',
  [EDUCATION_SUBTITLE.RESPONSIVE_LIBRARY_PAST_PERFORMANCE_BIOS_RESUMES]: 'https://www.fedscout.com/bios-resumes-and-past-performance-write-ups',
  [EDUCATION_SUBTITLE.RFIs]: 'https://www.fedscout.com/rfis',
  [EDUCATION_SUBTITLE.READING_ASSESSING_RFPs]: 'https://www.fedscout.com/reading-and-assessing-rfps',
  [EDUCATION_SUBTITLE.PLANNING_A_COMPLIANT_PROPOSAL]: 'https://www.fedscout.com/planning-a-compliant-proposal',
  [EDUCATION_SUBTITLE.PIPELINE_BASICS]: 'https://www.fedscout.com/pipeline',
  [EDUCATION_SUBTITLE.PROGRESS_FEEDBACK]: 'https://www.fedscout.com/progress-and-feedback',
}

export const PlanningPhaseClasses: IEducationPhaseClass[] = [{
  name: EDUCATION_SUBTITLE.EDUCATION_OVERVIEW,
  title: 'Education overview',
}, {
  name: EDUCATION_SUBTITLE.WORKBOOK,
  title: 'Workbooks and downloads',
}, {
  name: EDUCATION_SUBTITLE.GOVERNMENT_MARKET_BASICS,
  title: 'Government Market Basics',
  children: [{
    name: EDUCATION_SUBTITLE.FIRST_YEAR_OF_CONTRACTING,
    title: 'The First Years of a Government Contracting',
  }, {
    name: EDUCATION_SUBTITLE.COMMON_PATH_FOR_NEW_VENDOR,
    title: 'Common Paths for New Vendor Success',
  }, {
    name: EDUCATION_SUBTITLE.SET_ASIDES,
    title: 'Set-Asides',
  }, {
    name: EDUCATION_SUBTITLE.HOW_GOVERNMENT_BUYS,
    title: 'How the government buys',
  }, {
    name: EDUCATION_SUBTITLE.HOW_GOVERNMENT_ORGANIZED,
    title: 'How the government is organized',
  }, {
    name: EDUCATION_SUBTITLE.HOW_COMMUNITY_ORGANIZED,
    title: 'How the contractor Community is Organized',
  }, {
    name: EDUCATION_SUBTITLE.FINDING_BUDGET,
    title: 'Finding budget',
  },]
}, {
  name: EDUCATION_SUBTITLE.WHAT_TO_SELL,
  title: 'What to Sell',
  children: [{
    name: EDUCATION_SUBTITLE.PRODUCTS_SERVICES_BRAINSTORM,
    title: 'Products and Services Brainstorm & Selection'
  }, {
    name: EDUCATION_SUBTITLE.IDENTIFY_YOUR_KEYWORDS,
    title: 'Identify your keywords'
  }, {
    name: EDUCATION_SUBTITLE.IDENTIFY_YOUR_CODES,
    title: 'Identify your code'
  }]
}, {
  name: EDUCATION_SUBTITLE.DIFFERENTIATORS,
  title: 'Differentiators',
}, {
  name: EDUCATION_SUBTITLE.REGISTRATIONS,
  title: 'Registrations',
  children: [{
    name: EDUCATION_SUBTITLE.INCORPORATE_FEDERAL_STATE_REGISTRATIONS,
    title: 'Incorporate, Federal & State Registrations',
  }, {
    name: EDUCATION_SUBTITLE.DUNS_REGISTRATIONS,
    title: 'DUNS',
  }, {
    name: EDUCATION_SUBTITLE.SAM_REGISTRATIONS,
    title: 'SAM',
  }, {
    name: EDUCATION_SUBTITLE.SBIR_REGISTRATIONS,
    title: 'SBIR',
  }, {
    name: EDUCATION_SUBTITLE.SET_ASIDES_REGISTRATIONS,
    title: 'Set-asides',
  }]
}, {
  name: EDUCATION_SUBTITLE.YOUR_RATES,
  title: 'Your Rates',
}, {
  name: EDUCATION_SUBTITLE.CUSTOMERS,
  title: 'Customers',
  children: [{
    name: EDUCATION_SUBTITLE.MOST_PROMISING_CUSTOMERS,
    title: 'Most promising customers'
  }, {
    name: EDUCATION_SUBTITLE.CUSTOMERS_ANALYSIS,
    title: 'Customer analysis'
  }, {
    name: EDUCATION_SUBTITLE.CUSTOMERS_KEY_PERSONNEL,
    title: 'Key personnel'
  }, {
    name: EDUCATION_SUBTITLE.CUSTOMER_CONTACTS,
    title: 'Customer contacts'
  }]
}, {
  name: EDUCATION_SUBTITLE.PARTNERS,
  title: 'Partners',
  children: [{
    name: EDUCATION_SUBTITLE.MOST_PROMISING_PARTNERS,
    title: 'Most promising partners'
  }, {
    name: EDUCATION_SUBTITLE.PROMISING_PARTNERS,
    title: 'Promising partners'
  }, {
    name: EDUCATION_SUBTITLE.PARTNERS_KEY_PERSONNEL,
    title: 'Key personnel'
  }, {
    name: EDUCATION_SUBTITLE.PARTNERS_CONTACTS,
    title: 'Partner contacts'
  }]
}, {
  name: EDUCATION_SUBTITLE.MARKETING,
  title: 'Marketing',
  children: [{
    name: EDUCATION_SUBTITLE.MARKETING_GOALS,
    title: 'Marketing Goals'
  }, {
    name: EDUCATION_SUBTITLE.MARKETING_CONCEPTS,
    title: 'Marketing Concepts'
  }, {
    name: EDUCATION_SUBTITLE.PLANNING_YOUR_MARKETING,
    title: 'Planning your marketing'
  }]
}, {
  name: EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING,
  title: 'Relationship building',
  children: [{
    name: EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING_GOALS,
    title: 'Relationship Building Goals'
  }, {
    name: EDUCATION_SUBTITLE.RELATIONSHIP_BUILDING_PLAN,
    title: 'Relationship building plan'
  }]
}, {
  name: EDUCATION_SUBTITLE.COLLATERAL,
  title: 'Collateral',
  children: [{
    name: EDUCATION_SUBTITLE.COLLATERAL_GOALS,
    title: 'Collateral goals'
  }, {
    name: EDUCATION_SUBTITLE.COLLATERAL_SAP_MICRO_OFFERINGS,
    title: 'SAP & Micro Offering'
  }, {
    name: EDUCATION_SUBTITLE.COLLATERAL_WEBPAGE_CARDS,
    title: 'Cap Stmt, Webpage, Cards'
  }]
}, {
  name: EDUCATION_SUBTITLE.RESPONSIVE_LIBRARY,
  title: 'Response Library',
  children: [{
    name: EDUCATION_SUBTITLE.RESPONSIVE_LIBRARY_SHAPING,
    title: 'Shaping'
  }, {
    name: EDUCATION_SUBTITLE.RESPONSIVE_LIBRARY_PAST_PERFORMANCE_BIOS_RESUMES,
    title: 'Past performance, Bios & Resumes'
  }]
}, {
  name: EDUCATION_SUBTITLE.RFIs_RFPs,
  title: 'RFIs/RFPs',
  children: [{
    name: EDUCATION_SUBTITLE.RFIs,
    title: 'RFIs'
  }, {
    name: EDUCATION_SUBTITLE.READING_ASSESSING_RFPs,
    title: 'Reading/assessing  RFPs'
  }, {
    name: EDUCATION_SUBTITLE.PLANNING_A_COMPLIANT_PROPOSAL,
    title: 'Planning a compliant proposal'
  }]
}, {
  name: EDUCATION_SUBTITLE.PIPELINE,
  title: 'Pipeline',
  children: [{
    name: EDUCATION_SUBTITLE.PIPELINE_BASICS,
    title: 'Pipeline basics'
  }, {
    name: EDUCATION_SUBTITLE.NEAR_TERM_OPPORTUNITIES,
    title: 'Near term opportunities'
  }, {
    name: EDUCATION_SUBTITLE.MID_TERM_OPPORTUNITIES,
    title: 'Mid term opportunities'
  }, {
    name: EDUCATION_SUBTITLE.LONG_TERM_OPPORTUNITIES,
    title: 'Long term opportunities'
  }]
}, {
  name: EDUCATION_SUBTITLE.PROGRESS_FEEDBACK,
  title: 'Progress & Feedback',
}]

export const PlanningPhaseNavigationList = PlanningPhaseClasses.map(phase => phase.children ? phase.children : phase).flat()

export const PlanningPhaseSearchList = PlanningPhaseClasses.map(phase => phase.children ? [phase, ...phase.children] : phase).flat()

export const CONTRACTS_PHASE_CLASSES: any[] = [EDUCATION_SUBTITLE.NEAR_TERM_OPPORTUNITIES, EDUCATION_SUBTITLE.MID_TERM_OPPORTUNITIES, EDUCATION_SUBTITLE.LONG_TERM_OPPORTUNITIES]

export const PaginationLimit = 10

export const EDUCATION_ACADEMY_LINK = 'https://academy.fedscout.com/'