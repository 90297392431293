import moment, { Moment, unitOfTime } from 'moment'

export const getFormattedDate = (date: Date) => {
  const d = moment(date)
  return d.isValid() ? moment.utc(date).format('MMM DD, YYYY') : 'N/A'
}

export const getFormattedLLDate = (date: Date | string) => {
  const d = moment(date)
  return d.isValid() ? moment.utc(date).format('ll') : 'N/A'
}

export const getDiffInDates = (date1: Date | string | Moment, date2: Date | string | Moment, type: unitOfTime.Diff = 'days'): number => {
  const d1 = moment.utc(date1)
  const d2 = moment.utc(date2)
  let days = d1.diff(d2, type)

  if (!d1.isValid() || !d2.isValid() || d1 > d2) {
    days = 0
  }

  return Math.abs(days)
}

export const getFormattedTextDate = (date: Date | undefined | null, format?: string) => {
  if (!date || !moment(date).isValid()) return date
  return moment.utc(date).format(format || 'YYYY-MM-DD')
}

export const getFormattedTextMonth = (date: Date | undefined | null) => {
  if (!date || !moment(date).isValid()) return date
  return moment.utc(date).format('YYYY-MM')
}

export const getFormattedQtr = (date: Date) => {
  return moment(date).format('[Qtr] Q YYYY')
}

export function getDaysInMonth(year: number, month: number) {
  const concatDate = `${year}-${month}`
  return moment(concatDate, 'YYYY-MM').daysInMonth()
}

export function getTheWeekNumber(year: number, month: number) {
  const concatDate = `${year}-${month}`
  return moment(concatDate).isoWeek()
}

export function getWeeksInMonth(year: number, month: number) {
  const l = new Date(year, month + 1, 0)
  return Math.ceil( (l.getDate()- (l.getDay()?l.getDay() : 7 )) / 7 ) + 1
}

export function getDayOfWeek(year: number, month: number, day: number) {
  const daysOfTheWeekArr = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  const dayOfTheWeekIndex = moment(`${year}-${month}-${day}`).day()
  return daysOfTheWeekArr[dayOfTheWeekIndex]
}