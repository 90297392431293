import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import { IAwardSearchRecord, AwardSearchFilter } from 'store/hp/awards/search/reducer'
import {
  MARKET_RESEARCH_TOOLS_LIST_ERROR,
  MARKET_RESEARCH_TOOLS_LIST_SUCCESS,
  MARKET_RESEARCH_TOOLS_LIST,
  SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE_BOOL,
  SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE,
  SET_MARKET_RESEARCH_SEARCH_FILTER_VALUES,
  REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUE,
  REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUES,
  RESET_MARKET_RESEARCH_SEARCH_FILTERS, MARKET_RESEARCH_TOOLS_LIST_APPEND_SUCCESS
} from './actions'
import { AWARD_LIST_CATEGORIZE, AWARD_LIST_CATEGORIZE_REMOVE } from 'store/hp/awards/listing/actions'
import { ContractItem } from 'models/hp/market-research/market-research.model'
import { AwardListItem } from 'models/hp/awards/awards.model'
import { mapCategorize } from 'store/hp/awards/reducer-helper'

export interface IMarketResearchToolsExpiringContracts {
  in3Month: ContractItem
  in6Month: ContractItem
  in9Month: ContractItem
  in12Month: ContractItem
}

export interface IMarketResearchTools {
  expiringContracts: {
    listing: Record<IRecord<IMarketResearchToolsExpiringContracts>>
    filters: Record<IAwardSearchRecord>
  }
  [key: string]: {
    listing: Record<IRecord>
    filters: Record<IAwardSearchRecord>
  }
}

const MarketResearchToolsExpiringContracts = Record<IRecord<IMarketResearchToolsExpiringContracts>>({
  loading: false,
  loaded: false,
  data: {
    in3Month: new ContractItem(),
    in6Month: new ContractItem(),
    in9Month: new ContractItem(),
    in12Month: new ContractItem()
  }
})

const MarketResearchToolsRecord = Record<IMarketResearchTools>({
  expiringContracts: {
    listing: new MarketResearchToolsExpiringContracts(),
    filters: new AwardSearchFilter(),
  }
})

export const marketResearchToolsReducer = (state: Record<IMarketResearchTools> = new MarketResearchToolsRecord(), action: IAction<any>) => {
  switch (action.type) {
    case MARKET_RESEARCH_TOOLS_LIST:
      return state
        .setIn([action.payload.key, 'listing', 'loading'], true)
    case MARKET_RESEARCH_TOOLS_LIST_SUCCESS:
      return state
        .setIn([action.payload.key, 'listing', 'loading'], false)
        .setIn([action.payload.key, 'listing', 'loaded'], true)
        .setIn([action.payload.key, 'listing', 'data'], action.payload.data)
    case MARKET_RESEARCH_TOOLS_LIST_APPEND_SUCCESS:
      return state
        .updateIn([action.payload.key, 'listing', 'data'], (data: any) => ({
          ...data,
          [action.payload.dataKey]: {
            ...data[action.payload.dataKey],
            items: data[action.payload.dataKey].items.concat(action.payload.data)
          }
        }))
    case AWARD_LIST_CATEGORIZE: {
      if (!action.payload.dataKey) {
        return state
      }
      return state
        .updateIn([action.payload.key, 'listing', 'data'], (data: any) => ({
          ...data,
          [action.payload.dataKey]: {
            ...data[action.payload.dataKey],
            items: data[action.payload.dataKey].items.map(
              (item: AwardListItem) => mapCategorize(item, action.payload.id, true)
            )
          }
        }))
    }
    case AWARD_LIST_CATEGORIZE_REMOVE: {
      if (!action.payload.dataKey) {
        return state
      }
      return state
        .updateIn([action.payload.key, 'listing', 'data'], (data: any) => ({
          ...data,
          [action.payload.dataKey]: {
            ...data[action.payload.dataKey],
            items: data[action.payload.dataKey].items.map(
              (item: AwardListItem) => mapCategorize(item, action.payload.id, false)
            )
          }
        }))
    }

    case MARKET_RESEARCH_TOOLS_LIST_ERROR:
      return state
        .setIn([action.payload.key, 'listing', 'loading'], false)
        .setIn([action.payload.key, 'listing', 'loaded'], false)

    case SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE_BOOL:
      return state
        .setIn([action.payload.key, 'filters', action.payload.data.key], action.payload.data.value)
    case SET_MARKET_RESEARCH_SEARCH_FILTER_VALUE:
      return state
        .updateIn([action.payload.key, 'filters', action.payload.data.key], (arr: any) => [...arr, action.payload.data.value])
    case SET_MARKET_RESEARCH_SEARCH_FILTER_VALUES:
      return state
        .updateIn([action.payload.key, 'filters', action.payload.data.key], (arr: any) => arr.concat(action.payload.data.value))
    case REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUE:
      return state
        .updateIn([action.payload.key, 'filters', action.payload.data.key], (arr: any) => arr.filter((x: any) => x !== action.payload.data.value))
    case REMOVE_MARKET_RESEARCH_SEARCH_FILTER_VALUES:
      return state
        .updateIn([action.payload.key, 'filters', action.payload.data.key], (arr: any) => arr.filter((x: any) => !action.payload.data.value.includes(x)))
    case RESET_MARKET_RESEARCH_SEARCH_FILTERS:
      console.log(action)
      return state
        .setIn([action.payload.key, 'filters', 'keywords'], [])
        .setIn([action.payload.key, 'filters', 'customers'], [])
        .setIn([action.payload.key, 'filters', 'setAsides'], [])
        .setIn([action.payload.key, 'filters', 'pscCodes'], [])
        .setIn([action.payload.key, 'filters', 'naicsCodes'], [])
        .setIn([action.payload.key, 'filters', 'locations'], [])
        .setIn([action.payload.key, 'filters', 'types'], [])
        .setIn([action.payload.key, 'filters', 'duns'], [])
        .setIn([action.payload.key, 'filters', 'values'], [])
        .setIn([action.payload.key, 'filters', 'dates'], [])
        .setIn([action.payload.key, 'filters', 'startDate'], null)
        .setIn([action.payload.key, 'filters', 'endDate'], null)
    default:
      return state
  }
}