import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  REMOVE_AWARD_SEARCH_FILTER_VALUE, REMOVE_AWARD_SEARCH_FILTER_VALUES, RESET_AWARD_SEARCH_FILTERS,
  SET_AWARD_SEARCH_FILTER_KEYWORD,
  SET_AWARD_SEARCH_FILTER_VALUE,
  SET_AWARD_SEARCH_FILTER_VALUE_BOOL,
  SET_AWARD_SEARCH_FILTER_VALUES
} from './actions'

export interface IAwardSearchRecord {
  keywords: string[]
  customers: string[]
  setAsides: string[]
  pscCodes: string[]
  naicsCodes: string[]
  locations: string[]
  types: string[]
  duns: string[]
  values: number[]
  dates: number[]
  startDate: Date | null
  endDate: Date | null

  isIdv?: boolean
}

export const AwardSearchFilter = Record<IAwardSearchRecord>({
  keywords: [],
  customers: [],
  setAsides: [],
  pscCodes: [],
  naicsCodes: [],
  locations: [],
  types: [],
  duns: [],
  values: [],
  dates: [],
  startDate: null,
  endDate: null,

  isIdv: false
})

export const awardSearchReducer = (
  state: Record<IAwardSearchRecord> = new AwardSearchFilter(),
  action: IAction) => {
  switch (action.type) {
    case SET_AWARD_SEARCH_FILTER_KEYWORD:
      return state
        .set('keywords', action.payload.value)
    case SET_AWARD_SEARCH_FILTER_VALUE_BOOL:
      return state
        .set(action.payload.key, action.payload.value)
    case SET_AWARD_SEARCH_FILTER_VALUE:
      return state
        .update(action.payload.key, arr => [...arr, action.payload.value])
    case SET_AWARD_SEARCH_FILTER_VALUES:
      return state
        .mergeIn([action.payload.key], action.payload.value)
    case REMOVE_AWARD_SEARCH_FILTER_VALUE:
      return state
        .update(action.payload.key, arr => arr.filter((x: any) => x !== action.payload.value))
    case REMOVE_AWARD_SEARCH_FILTER_VALUES:
      return state
        .update(action.payload.key, arr => arr.filter((x: any) => !action.payload.value.includes(x)))
    case RESET_AWARD_SEARCH_FILTERS:
      return state
        .set('keywords', [])
        .set('customers', [])
        .set('setAsides', [])
        .set('pscCodes', [])
        .set('naicsCodes', [])
        .set('locations', [])
        .set('types', [])
        .set('duns', [])
        .set('values', [])
        .set('dates', [])
        .set('startDate', null)
        .set('endDate', null)
    default:
      return state
  }
}
