import React from 'react'
import { Button, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { expiringContractsFiltersSelector } from 'store/hp/market-research-tools/selectors'
import { addContractsListing } from 'store/hp/market-research-tools/actions'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'

export interface AppendContractsOpts {
  from: number
  range: number
  dataKey: string
}

interface Props {
  dataKey: string
  count: number
  currentCount: number
}

const LoadMoreBtn = ({ dataKey, count, currentCount }: Props) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)

  const selectedFilters = useSelector(expiringContractsFiltersSelector) as IAwardSearchRecord

  const dataKeyMapper: {[key: string]: number} = {
    'in3Month': 0,
    'in6Month': 1,
    'in9Month': 2,
    'in12Month': 3,
  }

  const loadMore = () => {
    setLoading(true)
    addContractsListing(dispatch)(selectedFilters, {
      dataKey: dataKey,
      range: dataKeyMapper[dataKey],
      from: currentCount
    })
      .finally(() => setLoading(false))
  }

  if (currentCount >= count) {
    return null
  }

  return (
    <Button
      style={{backgroundColor: theme.palette.primary.contrastText}}
      fullWidth
      color='primary'
      variant='text'
      onClick={loadMore}
      disabled={loading}
    >
      Load more
    </Button>
  )
}

export default LoadMoreBtn