import { Dispatch } from 'redux'
import Paths from 'utils/paths'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import { ISignUpForm } from 'modules/Auth/SignUp/components/Form'
import {
  UserResponseModel,
  UserSignInResponseModel,
  UserSignUpResponseModel,
  UserRecoverPasswordVerifyCodeCheck,
  UserVerifyResponseModel,
  UserSettingsResponseModel,
  UserIncumbentGroupResponse, UserLazyAccountResponseModel
} from 'models/common/user/user.model'
import { ICreateLazyAccountForm, ISignInForm } from 'modules/Auth/SignIn/components/Form'
import { removeToken, setToken } from 'utils/localStorage'
import { userActivity } from 'store/common/owner/actions'
import {
  IUserSignInResponseModel,
  IUserRecoverPasswordVerifyCodeCheck,
  IUserVerifyResponseModel,
  IUserSignUpResponseModel, IUserSettings, IUserLazyAccountCreationResponseModel
} from 'models/common/user/user.interfaces'
import { IBaseResponseModel } from 'models/base/base.interfaces'
import { BaseResponseModel } from 'models/base/base.model'
import { IHpRfpDetailModel } from 'models/hp/rfp/rfp.interface'
import { setTokenToSession } from 'utils/sessionStorage'

export const USER_BASE = 'USER_BASE'
export const USER_SETTINGS = 'USER_SETTINGS'
export const USER_SETTINGS_ERROR = 'USER_SETTINGS_ERROR'
export const RESET_USER = 'RESET_USER'
export const USER_BASE_ERROR = 'USER_BASE_ERROR'
export const USER_INCUMBENT_GROUP = 'USER_INCUMBENT_GROUP'
export const USER_INCUMBENT_GROUP_ERROR = 'USER_INCUMBENT_GROUP_ERROR'
export const UPDATE_TIMER_FOR_LAZY_ACCOUNT = 'UPDATE_TIMER_FOR_LAZY_ACCOUNT'
export const SET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT = 'SET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT'
export const RESET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT = 'RESET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT'

export const userSignUpAction = async (body: ISignUpForm): Promise<IUserSignUpResponseModel> => {
  try {
    const response = await request<IUserSignUpResponseModel>(RequestMethods.Post, Paths.User.signUp, {
      body,
      schema: UserSignUpResponseModel
    }, false)
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const userSignInAction = async (body: ISignInForm): Promise<IUserSignInResponseModel> => {
  try {
    const response = await request<IUserSignInResponseModel>(RequestMethods.Post, Paths.User.signIn, {
      body,
      schema: UserSignInResponseModel
    }, false)
    setToken(response.data.token)
    return response.data
  } catch (error) {
    // There we need return all response, because in component we need also status of response
    throw error.response
  }
}

export const userLogoutAction = (dispatch: Dispatch) => () => {
  dispatch({
    type: RESET_USER,
    payload: undefined
  })
  removeToken()
}

export const getUserAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await request(RequestMethods.Get, Paths.User.getMe, {schema: UserResponseModel})
      const payload = response.data

      dispatch({
        type: USER_BASE,
        payload,
      })
      return response.data
    } catch (error) {
      dispatch({
        type: USER_BASE_ERROR,
        error: error.response?.data?.errors
      })
      throw error
    }
  }
}

export const getUserSettingsAction = (dispatch: Dispatch) => async () => {
  try {
    const response = await request<UserSettingsResponseModel>(
      RequestMethods.Get,
      Paths.User.settings,
      {schema: UserSettingsResponseModel}
    )

    dispatch({
      type: USER_SETTINGS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: USER_SETTINGS_ERROR,
      error: error.response?.data?.errors
    })
  }
}

export const getUserIncumbentGroup = (dispatch: Dispatch) => async () => {
  try {
    const response = await request<UserIncumbentGroupResponse>(RequestMethods.Get, Paths.Hp.Incumbents.getUserGroup, {schema: UserIncumbentGroupResponse})
    dispatch({
      type: USER_INCUMBENT_GROUP,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: USER_INCUMBENT_GROUP_ERROR,
      error: error.response?.errors
    })
  }
}

export const updateUserSettings = (dispatch?: Dispatch) => async (dto: IUserSettings) => {
  const res = await request<BaseResponseModel>(
    RequestMethods.Put,
    Paths.User.settings,
    {
      schema: BaseResponseModel,
      body: dto
    }
  )
  dispatch && dispatch({
    type: USER_SETTINGS,
    payload: {
      data: dto,
    }
  })
  return res.data
}

export const userVerifyNumberAction = async (email: string, verifyNumber: number): Promise<IUserVerifyResponseModel> => {
  try {
    const response = await request<IUserVerifyResponseModel>(RequestMethods.Post, Paths.User.doVerify, {
      body: {email, verifyNumber,},
      schema: UserVerifyResponseModel
    })
    setToken(response.data.token)
    userActivity.event(userActivity.activities.createAccount, email)
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const userVerifyResendAction = async (email: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Post, Paths.User.resend, {
      body: {email},
      schema: BaseResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const userRecoverPasswordAction = async (email: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Post, Paths.User.recoverPassword, {
      body: {email},
      schema: BaseResponseModel
    }, false)
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const userRecoverPasswordCodeCheckAction = async (email: string, verifyNumber: number): Promise<IUserRecoverPasswordVerifyCodeCheck> => {
  try {
    const response = await request<IUserRecoverPasswordVerifyCodeCheck>(RequestMethods.Post, Paths.User.recoverCodeCheck, {
      body: {email, verifyNumber},
      schema: UserRecoverPasswordVerifyCodeCheck
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const userRecoverPasswordResetAction = async (password: string, email: string, token: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Post, Paths.User.recoverResetPassword, {
      body: {password, email, token},
      schema: BaseResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors || []
  }
}

export const userChangePassword = async (currentPassword: string, newPassword: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Post, Paths.User.changePassword, {
      body: {currentPassword, newPassword},
      schema: BaseResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors || []
  }
}

export const updateProfile = async (firstName: string, lastName: string, email: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Put, Paths.User.updateProfile, {
      body: {firstName, lastName, email},
      schema: BaseResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors || []
  }
}

export const cancelSubscription = async (id: string): Promise<IBaseResponseModel> => {
  try {
    const response = await request<IBaseResponseModel>(RequestMethods.Post, Paths.User.cancelSubscription, {
      body: {value: id},
      schema: BaseResponseModel
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors || []
  }
}

export const updateTimerForLazyAccountCreation = (dispatch?: Dispatch) => (isPassed: boolean) => {
  dispatch && dispatch({
    type: UPDATE_TIMER_FOR_LAZY_ACCOUNT,
    payload: {
      data: isPassed
    }
  })
}

export const setViewedContractsForLazyAccountCreation = (dispatch?: Dispatch) => (dto: IHpRfpDetailModel) => {
  dispatch && dispatch({
    type: SET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT,
    payload: {
      data: dto
    }
  })
}

export const resetViewedContractsForLazyAccountCreation = (dispatch: Dispatch) => () => {
  dispatch({
    type: RESET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT
  })
}

export const setPromptedStateForLazyAccountCreation = () => {
  sessionStorage.setItem('lazyAccountPrompted', 'prompted')
}

export const getPromptedStateForLazyAccountCreation = () => {
  return sessionStorage.getItem('lazyAccountPrompted')
}

export const createLazyUserAccount = async (body: ICreateLazyAccountForm): Promise<IUserLazyAccountCreationResponseModel> => {
  const response = await request<IUserLazyAccountCreationResponseModel>(RequestMethods.Post, Paths.User.createLazy, {
    body,
    schema: UserLazyAccountResponseModel
  }, false)
  if (response.data.data) {
    setTokenToSession(response.data.data.token)
    userActivity.event(userActivity.activities.createAccount, body.email)
  }
  return response.data
}