import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { AwardListResponseModel } from 'models/hp/awards/awards.model'
import { Pagination } from 'models/base/base.interfaces'

export const AWARD_LIST_CATEGORIZED = 'AWARD_LIST_CATEGORIZED'
export const AWARD_LIST_CATEGORIZED_ERROR = 'AWARD_LIST_CATEGORIZED_ERROR'
export const AWARD_LIST_CATEGORIZED_SUCCESS = 'AWARD_LIST_CATEGORIZED_SUCCESS'
export const AWARD_LIST_CATEGORIZED_UPDATE_PAGINATION = 'AWARD_LIST_CATEGORIZED_UPDATE_PAGINATION'
export const AWARD_LIST_CATEGORIZED_APPEND_SUCCESS = 'AWARD_LIST_CATEGORIZED_APPEND_SUCCESS'

export const getList = (dispatch: Dispatch) => async (pagination: Pagination): Promise<AwardListResponseModel> => {
  try {
    dispatch({
      type: AWARD_LIST_CATEGORIZED
    })
    const res = await request<AwardListResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getCategorizedList(pagination), {
      schema: AwardListResponseModel,
    })
    dispatch({
      type: AWARD_LIST_CATEGORIZED_SUCCESS,
      payload: res.data
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: AWARD_LIST_CATEGORIZED_ERROR
    })
    throw e
  }
}

export const appendList = (dispatch: Dispatch) => async (pagination: Pagination) => {
  dispatch({
    type: AWARD_LIST_CATEGORIZED_UPDATE_PAGINATION,
    payload: pagination
  })
  const res = await request<AwardListResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getCategorizedList(pagination), {
    schema: AwardListResponseModel,
  })
  dispatch({
    type: AWARD_LIST_CATEGORIZED_APPEND_SUCCESS,
    payload: res.data
  })

}
