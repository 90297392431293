import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'

export interface IVerifyPasswordForm {
  verificationCode: string
}

export const VERIFY_FORM_INIT_VALUE: IVerifyPasswordForm = {
  verificationCode: ''
}

export const VERIFY_FORM_VALIDATION_SCHEMA = Yup.object({
  verificationCode: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Verification Number'))
    .matches(/^\d+$/, ERROR_MESSAGES.Field.OnlyOneType('Verification Number', 'numbers'))
})