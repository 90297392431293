import { IPiplineHealthItem } from 'models/hp/pipeline/pipeline.interface'

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        color: '#14142B',
        font: {
          family: 'Inter',
          size: 12,
          weight: 600
        },
      }
    },
    y: {
      max: 100,
      ticks: {
        color: '#6E7191',
        font: {
          family: 'Inter',
          size: 12,
        },
        callback: function(value: string) {
          return value + '%'
        }
      }
    }
  },
}
  
const labels = [['Pre-RFI/SS', 'Capture'], ['RFI/SS', 'Response'], ['Post-RFI/SS', 'Capture'], ['RFP', 'Response'], ['Pending', 'Award']]
  
export const data = (data: IPiplineHealthItem[]) => ({
  labels,
  datasets: [
    {
      label: 'Value',
      data: data.map(el => el.percentage),
      backgroundColor: '#FFB169',
      borderRadius: 6
    },
  ],
})
  