import { AwardListItem } from 'models/hp/awards/awards.model'

export const mapCategorize = (item: AwardListItem, id: string, state: boolean, categorization?: string): AwardListItem => {
  if (item.contractTransactionUniqueKey === id) {
    if (categorization) {
      item.categorization = categorization
    } else {
      item.categorization = state ? 'like': ''
    }
  }
  return item
}