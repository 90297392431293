import React, { useRef } from 'react'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, Paper, Typography } from '@material-ui/core'
import { getAwardDetailsLink } from 'helpers/rfp'
import { categorizeItem } from 'store/hp/awards/listing/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { ContractItem } from 'models/hp/market-research/market-research.model'
import { numberToStrWithRound } from 'common/number'
import ListItem from 'modules/Main/Awards/AwardHome/components/ListItem'
import ProgressBar from './components/ProgressBar'
import SvgIcon from 'components/SvgIcon'
import LoadMoreBtn from './components/LoadMoreBtn'

interface Props {
  label: string
  item: ContractItem
  dataKey: string
  itemSum: number
  valueSum: number
  tabOpened: boolean
  toggleTab: () => void
}

const ListingItem = ({ item, label, itemSum, valueSum, dataKey, tabOpened, toggleTab }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useRef(useNotification())

  const goToDetails = (id: string) => {
    history.push(getAwardDetailsLink(id))
  }

  const handleCategorize = (id: string) => {
    categorizeItem(dispatch)(id, 'expiringContracts', dataKey)
      .then(() => notification.current.show('Contract added to Liked list', NotificationTypeList.Success))
      .catch((e) => notification.current.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  return (
    <Grid item>
      <Paper className={styles['paper']}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <Typography variant='h5'>{label}</Typography>
          </Grid>
          <Grid item md={6}>
            <Typography><b>PRIMES ARE:</b> {item.title}</Typography>
          </Grid>
          <Grid item md={3}>
            <Grid container style={{marginBottom: '6px'}}>
              <Grid item md={4}>
                <Typography align='right' className={styles['subtitle']}>
                  #:
                </Typography>
              </Grid>
              <Grid item md={8}>
                {
                  item.amount !== 0 ? (
                    <ProgressBar
                      value={(item.count / itemSum) * 100 || 0}
                      color='secondary'
                      label={item.count ? item.count.toString() : '0'}
                    />
                  ) : (
                    <Typography>None found</Typography>
                  )
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={4}>
                <Typography align='right' className={styles['subtitle']}>
                  $ Val:
                </Typography>
              </Grid>
              <Grid item md={8}>
                {
                  item.amount !== 0 ? (
                    <ProgressBar
                      value={(item.amount / valueSum) * 100 || 0}
                      color='primary'
                      label={numberToStrWithRound(item.amount)}
                    />
                  ) : (
                    <Typography>None found</Typography>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item md={1}
            onClick={() => item.items && item.items.length > 0 && toggleTab()}
          >
            <Grid container alignItems='center' justifyContent='center' className={styles['icon-container']}>
              <SvgIcon
                className={tabOpened ? styles['rotated'] : undefined}
                name='angle-down'
                width={35} height={35}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {tabOpened && (
        <div className={styles['paper-below']}>
          <Grid container spacing={2}>
            {item.items && item.items.length > 0 && item.items.map(item => (
              <Grid item md={12} key={item.id}>
                <ListItem
                  rfp={item}
                  onClick={() => goToDetails(item.id)}
                  onCategorizeClick={() => handleCategorize(item.contractTransactionUniqueKey)}
                />
              </Grid>
            ))}
          </Grid>

          <LoadMoreBtn
            dataKey={dataKey}
            count={item.count}
            currentCount={item.items.length}
          />
        </div>
      )}
    </Grid>
  )
}

export default ListingItem