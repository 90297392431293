export const getErrorMessage = (e: any): string => {
  const defaultMsg = 'Error happened, please try again'

  if (Array.isArray(e)) {
    return e[0].message || defaultMsg
  }

  return e?.response?.data?.errors[0].message || defaultMsg
}

export const defaultMsg = 'Error happened while processing your request. Please, try again later'