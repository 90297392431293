import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import { GroupedCodeResponseModel } from 'models/hp/grouped-code/grouped-code.model'
import { setOptionCount } from 'common/filter'
import { FilterOption } from 'models/hp/search/search.model'
import { BaseResponseModel } from 'models/base/base.model'

export const GET_NAICS_CODES = 'GET_NAICS_CODES'
export const GET_NAICS_CODES_SUCCESS = 'GET_NAICS_CODES_SUCCESS'
export const GET_NAICS_CODES_ERROR = 'GET_NAICS_CODES_ERROR'
export const NAICS_CODE_COUNT = 'NAICS_CODE_COUNT'

export const GET_PSC_CODES = 'GET_PSC_CODES'
export const GET_PSC_CODES_SUCCESS = 'GET_PSC_CODES_SUCCESS'
export const GET_PSC_CODES_ERROR = 'GET_PSC_CODES_ERROR'
export const PSC_CODE_COUNT = 'PSC_CODE_COUNT'

export const getNaicsCodesAction = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: GET_NAICS_CODES
  })
  try {
    const response = await request<GroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.GroupedCodes.getNaicsCodes, {
      schema: GroupedCodeResponseModel
    })
    dispatch({
      type: GET_NAICS_CODES_SUCCESS,
      payload: response.data.data
    })
    setOptionCount(dispatch)(NAICS_CODE_COUNT, response.data.data)
  } catch (e) {
    dispatch({
      type: GET_NAICS_CODES_ERROR
    })
    throw e
  }
}

export const getPscCodesAction = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: GET_PSC_CODES
  })
  try {
    const response = await request<GroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.GroupedCodes.getPscCodes, {
      schema: GroupedCodeResponseModel
    })
    dispatch({
      type: GET_PSC_CODES_SUCCESS,
      payload: response.data.data
    })
    setOptionCount(dispatch)(PSC_CODE_COUNT, response.data.data)
  }
  catch (e) {
    dispatch({
      type: GET_PSC_CODES_ERROR
    })
    throw e
  }
}

export const searchPscCodesAction = async (search: string): Promise<FilterOption[]> => {
  try {
    const response = await request<GroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.GroupedCodes.getPscCodes, {
      schema: GroupedCodeResponseModel,
      params: { q: search, limit: 10 }
    })
    return response.data.data
  } catch (e) {
    throw e.response.data.errors
  }
}

export const searchNaicsCodesAction = async (search: string) => {
  try {
    const response = await request<GroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.GroupedCodes.getNaicsCodes, {
      schema: GroupedCodeResponseModel,
      params: { q: search, limit: 10 }
    })
    return response.data.data
  } catch (e) {
    throw e.response.data.errors
  }
}

export const updateSearchGroupedCodes = async (searchId: number, codes: { naicsCodes: string[], pscCodes: string[] }) => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.GroupedCodes.updateSearchCodeNames(searchId), {
    schema: BaseResponseModel,
    body: codes
  })
}
