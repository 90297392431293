import React, { useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import ButtonLink from 'components/v3/ButtonLink'
import { FormikProps } from 'formik'
import { IPiplinePendingAward, IPiplinePendingAwardsBody } from 'models/hp/pipeline/pipeline.interface'
import { useDispatch } from 'react-redux'
import { upadatePiplinePendingAwards } from 'store/hp/pipeline/rfp/action'

interface ContractItemProprs {
    activeContract: IPiplinePendingAward | null
    contract: IPiplinePendingAward
    setActiveContract: (value: IPiplinePendingAward | null) => void
    formikProps: FormikProps<IPiplinePendingAwardsBody>
}

const ContractItem = ({contract, activeContract, setActiveContract, formikProps}: ContractItemProprs) => {
  const dispatch = useDispatch()
  const [outcome, setOutcome] = useState<string>(() => {
    if(activeContract && activeContract.id === contract.id) {
      return formikProps.values.outcome
    }
    return contract.pipelineAnalysis?.outcome || ''
  })
  const awardDate = contract.awardDate.split(' ')

  const handleOutcome = (type: string) => {
    if(activeContract) {
      upadatePiplinePendingAwards(dispatch)(activeContract!.id, formikProps.values)
      formikProps.setFieldValue('outcome', type)
      setOutcome(type)
    }
  }

  return (
    <div 
      className={classNames(styles['pending-award-item-contract-item'], {
        [styles['pending-award-item-contract-item-hidden']]: activeContract && activeContract.id !== contract.id
      })}
    >
      <div className={styles['pending-award-item-contract-item-left']}>
        <div className={styles['pending-award-item-contract-item-q']}>
          <Typography className={styles['pending-award-item-contract-item-q-title']}>{awardDate[0]}</Typography>
          <Typography className={styles['pending-award-item-contract-item-q-date']}>{awardDate[1]}</Typography>
        </div>
        <div className={styles['pending-award-item-contract-item-outcome']}>
          <Typography className={styles['pending-award-item-contract-item-outcome-title']}>Outcome</Typography>
          <div className={styles['pending-award-item-contract-item-outcome-action']}>
            <div 
              className={classNames(styles['pending-award-item-contract-item-outcome-action-btn'], {
                [styles['pending-award-item-contract-item-outcome-action-btn-success']]: outcome === 'WON',
                [styles['pending-award-item-contract-item-outcome-action-btn-disabled']]: !activeContract
              })}
              onClick={() => handleOutcome('WON')}
            >
              <div className={styles['pending-award-item-contract-item-outcome-action-btn-circle']} />
              <div className={styles['pending-award-item-contract-item-outcome-action-btn-title']}>WON</div>
            </div>
            <div 
              className={classNames(styles['pending-award-item-contract-item-outcome-action-btn'], {
                [styles['pending-award-item-contract-item-outcome-action-btn-lost']]: outcome === 'LOST',
                [styles['pending-award-item-contract-item-outcome-action-btn-disabled']]: !activeContract
              })}
              onClick={() => handleOutcome('LOST')}
            >
              <div className={styles['pending-award-item-contract-item-outcome-action-btn-circle']} />
              <div className={styles['pending-award-item-contract-item-outcome-action-btn-title']}>LOST</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['pending-award-item-contract-item-right']}>
        <div className={styles['pending-award-item-contract-item-info']}>
          <SvgIcon width={24} height={24} name='contract' />
          <div className={styles['pending-award-item-contract-item-info-list']}>
            <Typography className={styles['pending-award-item-contract-item-info-name']}>{contract.title}</Typography>
            <Typography className={styles['pending-award-item-contract-item-info-agency']}>{contract.typeOfSetAside || '-'}</Typography>  
          </div>
        </div>
        <div className={styles['pending-award-item-contract-item-analyze']}>
          <ButtonLink iconName='comment' title='Analyze' onClick={() => setActiveContract(contract)} />
        </div>
      </div>
    </div>
  )
}

export default ContractItem