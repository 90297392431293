import React, { useRef } from 'react'
import { MenuItem, Select, Typography } from '@material-ui/core'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { updateUserRole } from 'store/common/user-group/actons'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { groupCan } from 'components/v2/Can/GroupCan'
import { mapper, TRole } from 'constants/user-group'

interface Props {
  user: IUserBaseModel
  storedUser: IUserBaseModel
}

const ManageRoleBtn = ({user, storedUser}: Props) => {
  const notification = useRef(useNotification())

  const [value, setValue] = React.useState(user.userGroup?.role || '')

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = e.target.value as string

    updateUserRole(String(user.id), user.userGroupId!, value)
      .then(() => {
        notification.current.show('Role updated successfully', NotificationTypeList.Success)
        setValue(value as TRole)
      })
      .catch((e) => {
        if (e?.response?.status === 403) return notification.current.show('You don\'t have permissions to assign roles', NotificationTypeList.Error)
        if (e?.response?.status === 409) return notification.current.show('You can\'t change your role', NotificationTypeList.Error)
        notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
      })
  }

  if (!groupCan(storedUser, 'manager')) {
    return (
      <div>
        <Typography>{mapper[user.userGroup!.role]}</Typography>
      </div>
    )
  }

  return (
    <div>
      <Select
        value={value}
        onChange={handleChange}
      >
        <MenuItem value='client' title='Can put items on a first stage of pipeline'>{mapper.client}</MenuItem>
        <MenuItem value='qualifier' title='Can qualify items in pipeline'>{mapper.qualifier}</MenuItem>
        <MenuItem value='manager' title='Can assign roles to users and qualify items'>{mapper.manager}</MenuItem>
        <MenuItem value='advisor' title='Can assign roles to users and qualify items'>{mapper.advisor}</MenuItem>
      </Select>
    </div>
  )
}

export default ManageRoleBtn