import { IFilterOption } from 'models/hp/search/search.interface'

export interface Trigger {
  key: string | null
  items: NodeListOf<HTMLElement> | null
  active: number | null | undefined
}

export const getChildrenIds = (subs: IFilterOption[], callback: (id: number, sub?: IFilterOption) => void) => {
  subs.forEach(item => {
    if (item.sub && item.sub.length > 0) {
      getChildrenIds(item.sub, callback)
    } else {
      callback(item.id as number, item)
    }
  })
}

export const handleSwitch = (trigger: Trigger | null, handleSetTrigger: (key: string, index?: number) => void, turn: string = 'next', index?: number) => {
  if (!trigger) return

  let idx = index
  if (typeof index != 'number') { // if concrete index was not passed
    const topBorder = trigger!.items!.length - 1 || 0
    const active = trigger?.active || 0
    if (turn === 'next') {
      if (active >= topBorder) {
        idx = 0
      } else {
        idx = active + 1
      }
    }
    if (turn === 'prev') {
      if (active <= 0) {
        idx = topBorder
      } else {
        idx = active - 1
      }
    }
  }
  handleSetTrigger(trigger.key!, idx)
}