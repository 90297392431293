import React, { useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import Modal from 'components/v2/Modal'
import CustomLoader from 'components/CustomLoader'
import { FeedbackDetailsModel, FeedbackModel } from 'models/hp/feedback/feedback.model'
import { getFeedbackDetails } from 'store/hp/feedback/action'
import styles from '../../index.module.scss'

interface Props {
  feedback: FeedbackModel
  onClose: () => void
}

const FeedbackDetailsModal = ({feedback, onClose}: Props) => {

  const [feedbackDetails, setFeedbackDetails] = React.useState<FeedbackDetailsModel | null>(null)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setLoading(true)
    getFeedbackDetails(feedback.id).then(data => {
      setFeedbackDetails(data)
    }).finally(() => setLoading(false))
    return () => {
      setFeedbackDetails(null)
    }
  }, [])

  return (
    <Modal
      title={[feedback.type, 'feedback'].join(' ')}
      onClose={onClose}
      size={'lg'}
      open={true}
    >
      {loading && <CustomLoader size={32}/>}
      {!loading && !!feedbackDetails && (
        <Grid className={styles['feedback-modal']}>
          <Typography variant={'h5'}>{feedbackDetails.firstName} {feedbackDetails.lastName}</Typography>
          <Grid container alignItems={'center'} style={{marginBottom: '10px'}}>
            <Typography variant={'subtitle2'} style={{marginRight: '15px'}}>
              <span className={styles['label']}>email:</span> {feedbackDetails.email}
            </Typography>
            {feedbackDetails.zip && (
              <Typography variant={'subtitle2'}>
                <span className={styles['label']}>zip:</span> {feedbackDetails.zip}
              </Typography>
            )}
          </Grid>
          <Grid container alignItems={'center'} style={{marginBottom: '10px'}}>
            <span className={styles['label']}>Subject:</span>
            <Typography variant={'h5'}>{feedbackDetails.subject}</Typography>
          </Grid>
          <span className={styles['label']}>Body: </span>
          <Typography variant={'subtitle2'}>{feedbackDetails.body}</Typography>
        </Grid>
      )}
    </Modal>
  )
}

export default FeedbackDetailsModal