import React from 'react'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import styles from '../../index.module.scss'
import { IconStrategy } from 'components/v2/Filters/SearchFilter/FilterTitle'
import { IFilterOption } from 'models/hp/search/search.interface'
import SvgIcon from 'components/SvgIcon'
import { actionTypes } from '../Row'
import Modal from 'components/v2/Modal'

interface Props {
  isEdit: boolean
  customers: IFilterOption[]
  handlePartialDelete: (type: actionTypes, ids: Array<string | number>, state: boolean, options?: IFilterOption[], vieRequest?: boolean) => void
  handleDelete: (type: actionTypes, id: number | string, state: boolean, option?: IFilterOption, vieRequest?: boolean) => void
}

const CustomerColumn = ({ customers, isEdit, handleDelete, handlePartialDelete }: Props) => {

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const theme = useTheme()

  const toRender = Array.from(new Set(customers.map(i => i.departmentHP))).reduce((acc, item) => {
    const subAgencies = customers.filter(i => i.departmentHP === item)
    acc.push({ agency: item!, sub: subAgencies })
    return acc
  }, [] as Array<{ agency: string, sub: IFilterOption[] }>)

  const lessShow = [...toRender].slice(0, 1)

  const renderRow = (agency: { agency: string, sub: IFilterOption[] }, viaSlice = false, canDelete = false) => {
    const subs = viaSlice ? agency.sub.slice(0, 3) : agency.sub
    return (
      <div key={agency.agency}>
        <div className={styles['block-row']}>
          <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
          <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{agency.agency}</Typography>
          {(isEdit || canDelete) &&
          <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            <SvgIcon
              onClick={() => handlePartialDelete('customer', agency.sub.map(sub => sub.id), false, undefined, true)}
              name='trash'
              color={theme.palette.warning.light}
              width={30}
              height={30}
            />
          </div>
          }
        </div>
        {subs.map(sub => (
          <div style={{ marginLeft: '15px' }} className={styles['block-row']} key={sub.id}>
            <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
            <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{sub.text}</Typography>
            {(isEdit || canDelete) &&
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
              <SvgIcon
                onClick={() => handleDelete('customer', sub.id, false, undefined, true)}
                name='trash'
                color={theme.palette.warning.light}
                width={30}
                height={30}
              />
            </div>
            }
          </div>
        ))}
      </div>
    )
  }

  return (
    <Grid item md={4}>
      <Typography variant='h4' className={styles['title']}>
        {IconStrategy['department']}
        CUSTOMER
      </Typography>
      <hr/>
      {lessShow.map(i =>
        renderRow(i, true, false)
      )}
      {(customers.length || toRender.length > 4) &&
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalOpen(true)}
      >
        More
      </Button>
      }
      {modalOpen &&
      <Modal
        title='Customers'
        open={modalOpen}
        size='md'
        onClose={() => setModalOpen(false)}
      >
        {toRender.map(i => renderRow(i, false, true))}
      </Modal>
      }
    </Grid>
  )
}

export default CustomerColumn
