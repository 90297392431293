import React from 'react'
import styles from '../index.module.scss'
import { useSelector } from 'react-redux'
import { getPlanTitle } from 'constants/payment'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import { userBaseResponseSelector } from 'store/common/user/selectors'

const PaymentPlan = () => {
  const user = useSelector(userBaseResponseSelector)

  return (
    <>
      <Typography variant='h4'>Payment Tier</Typography>
      <div className={styles['text']}>
        <Typography variant='h5'>{user.data?.paymentPlan ? getPlanTitle(user.data.paymentPlan.plan) : 'Free'}</Typography>
      </div>
      <Link to='/plans'>
        <Button
          variant='text'
          color='primary'
        >
          Change / Cancel subscription
        </Button>
      </Link>
    </>
  )
}

export default PaymentPlan