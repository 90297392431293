import React from 'react'
import { Grid, Theme, Typography, useTheme } from '@material-ui/core'
import SvgIcon from '../SvgIcon'
import styles from './index.module.scss'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'

interface Props {
  currentSubtitle: IEducationPhaseClass
  subtitle: IEducationPhaseClass
  onSelectSubtitle: (subtitle: IEducationPhaseClass) => void
}

const DropdownItem = ({subtitle, currentSubtitle, onSelectSubtitle}: Props) => {
  const theme = useTheme<Theme>()

  const active = subtitle.name === currentSubtitle.name

  const renderChild = active || subtitle.children?.some((child) => child.name === currentSubtitle.name)

  const onClick = (subtitle: IEducationPhaseClass) => {
    subtitle.children?.length ? onSelectSubtitle(subtitle.children[0]) : onSelectSubtitle(subtitle)
  }

  return (
    <div>
      <Grid
        className={[styles['dropdown-item'], styles[renderChild ? 'active' : '']].join(' ')}
        container
        wrap={'nowrap'}
        alignItems={'center'}
        onClick={() => onClick(subtitle)}
      >
        <div className={styles['icon']}>
          <SvgIcon
            color={renderChild ? theme.palette.warning.light : theme.palette.grey['400']}
            name={renderChild && subtitle.children? 'arrow-down' : 'arrow-right'}
            width={24}
            height={24}
          />
        </div>
        <Typography variant={'body2'} className={styles['title']}>
          {subtitle.title}
        </Typography>
      </Grid>
      <div className={styles['inner-items']}>
        {renderChild && subtitle.children && !!subtitle.children.length && subtitle.children.map((child, idx) => (
          <DropdownItem
            key={idx}
            currentSubtitle={currentSubtitle}
            subtitle={child}
            onSelectSubtitle={onSelectSubtitle}
          />
        ))}
      </div>
    </div>
  )
}

export default DropdownItem