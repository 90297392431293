import { steps as overviewSteps } from './pages/Overview/components/Tutorial/tutorialSteps'
import { StepType } from '@reactour/tour'

export interface ITutorial {
  meta: string
  steps: StepType[]
}

export interface IPage {
  id: number
  path: string;
  title: string;
  icon: string;
  forActiveContract: boolean;
  protected?: boolean;
  tutorials: ITutorial
}

export const pagesData = (): IPage[] => [
  {
    id: 1,
    path: 'overview',
    title: 'Overview',
    icon: 'file-search-alt',
    forActiveContract: false,
    tutorials: {
      meta: 'tutorialOverview',
      steps: overviewSteps
    }
  },
  {
    id: 2,
    path: 'documents',
    title: 'Documents',
    icon: 'folder',
    forActiveContract: false,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 3,
    path: 'qualifying',
    title: 'Qualifying',
    icon: 'qualifying',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 4,
    path: 'competition',
    title: 'Competition',
    icon: 'competition',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 6,
    path: 'outline',
    title: 'Outline',
    icon: 'outline',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 7,
    path: 'compliance',
    title: 'Compliance',
    icon: 'compliance',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 8,
    path: 'selfEval',
    title: 'Self Evaluation',
    icon: 'self-eval',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 5,
    path: 'strategy',
    title: 'Strategy',
    icon: 'strategy',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 9,
    path: 'project-managment',
    title: 'Project Management',
    icon: 'project-management',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 10,
    path: 'service-providers',
    title: 'Service providers',
    icon: 'response',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  },
  {
    id: 11,
    path: 'glossary',
    title: 'Glossary',
    icon: 'book-open',
    forActiveContract: true,
    tutorials: {
      meta: '',
      steps: []
    }
  }
]