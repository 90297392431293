import React from 'react'
import styles from  './index.module.scss'
import { Skeleton } from '@material-ui/lab'
import { Paper } from '@material-ui/core'

const Skeletons = () => {
  return (
    <div className={styles['skeletons']}>
      <div className={styles['skeletons-list']}>
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <br/>
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <br/>
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
        <Skeleton  variant="rect" animation='wave' height={30} width='100%' />
      </div>
      <Paper className={styles['skeletons-right']}>
        <Skeleton  variant="text" animation='wave' height={40} width='100%' />
        <Skeleton  variant="text" animation='wave' height={20} width='80%' />
        <Skeleton  variant="text" animation='wave' height={20} width='60%' />
        <br/>
        <Skeleton  variant="text" animation='wave' height={20} width='50%' />
        <Skeleton  variant="text" animation='wave' height={20} width='50%' />
        <Skeleton  variant="text" animation='wave' height={20} width='50%' />
        <Skeleton  variant="text" animation='wave' height={20} width='50%' />
        <Skeleton  variant="text" animation='wave' height={20} width='50%' />
        <br/>
        <Skeleton  variant="text" animation='wave' height={40} width='30%' />
      </Paper>
    </div>
  )
}

export default Skeletons