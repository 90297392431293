import { IRootState } from 'store/reducers'
import { createSelector } from 'reselect'

const pipeline = (state: IRootState) => state.pipeline

const pipelineRfp = createSelector(
  pipeline,
  (pipeline) => pipeline.rfp
)

export const pipelineBoardLoadingSelector = createSelector(
  pipelineRfp,
  (pipelineBoardListing) => pipelineBoardListing.getIn(['listing', 'loading'])
)

export const pipelineBoardItemsSelector = createSelector(
  pipelineRfp,
  (pipelineBoardListing) => pipelineBoardListing.getIn(['listing', 'data'])
)

export const pipelineBoardLoadedSelector = createSelector(
  pipelineRfp,
  (pipelineBoardListing) => pipelineBoardListing.getIn(['listing', 'loaded'])
)

export const pipelineTimelineSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('timeline')
)

export const pipelineTimelineCountSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('timeline').data.count
)

export const pipelineHealthSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('health')
)

export const pipelineRevenueSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('revenue')
)

export const pipelinePenmdingAwardSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('pendingAwards')
)

export const pipelinePenmdingAwardDataSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('pendingAwards').data.data
)

export const pipelinePenmdingAwardCountSelector = createSelector(
  pipelineRfp,
  (pipelineRfp) => pipelineRfp.get('pendingAwards').data.count
)

