import * as H from 'history'
import { UserBaseModel } from 'models/common/user/user.model'
import { ISearchDTO, ISearchLightModel, UrlFilters } from 'models/hp/search/search.interface'
import { autoSearchCreator, setAutoFiltersValues } from 'store/hp/search/actions'
import { updateUserSettings } from 'store/common/user/actions'
import { Dispatch } from 'redux'
import { IUserBaseModel, IUserSettings } from 'models/common/user/user.interfaces'
import { PLAN } from 'constants/payment'
import { IAutoFiltersRfp, IHpRfpDetailModel } from 'models/hp/rfp/rfp.interface'
import { SearchModel } from 'models/hp/search/search.model'

export const newItemsNotificationValues = {
  hour: 'hour',
  day: 'day',
  disable: 'null'
}

export const newItemsNotificationTitles: {[key: string]: string} = {
  hour: 'Hourly',
  day: 'Daily',
  disable: 'Turned off'
}

export const convertSearchDto = (dto: ISearchDTO, name: string) => {
  return {
    'name': name,
    'keywords': dto.keywords,
    'contractTypeIds': dto.contractTypeIds,
    'groupedCodeIds': dto.groupedCodeIds,
    'allGroup': dto.groupedCodeIds.length ? !dto.groupedCodeIds.length : dto.allGroupedCodes,
    'pscCodeIds': dto.pscCodeIds,
    'allPsc': dto.pscCodeIds.length ? !dto.pscCodeIds.length : dto.allPscCodes,
    'projectTypeIds': dto.projectTypeIds,
    'allProjectTypes': dto.projectTypeIds.length ? !dto.projectTypeIds.length : dto.allProjectTypes,
    'locationIds': dto.locationIds,
    'workLocation': (dto.workLocation === 'SPECIFIC' && dto.locationIds.length === 0) ? 'CONUS' : dto.workLocation || 'CONUS',
    'customerIds': dto.customerIds,
    'allAgencies': dto.locationIds.length ? !dto.locationIds.length : dto.allCustomers,
    'setAsideIds': dto.setAsideIds,
    'allSetAsides': dto.setAsideIds.length ? !dto.setAsideIds.length : dto.allSetAsides,
    'dueDateIds': dto.dueDateIds,
    'dueDateRange': dto.dueDateRange,
    'estimatedValueIds': dto.estimatedValueIds,
    'estimatedEffortIds': dto.estimatedEffortIds,
    'analyzedTypeIds': dto.analyzedTypeIds || [],
    'type': dto.type,
    'pastPerformance': dto.pastPerformance,
    'excludeIds': dto.excludeIds,

    // notifications configuration
    'newSearchItemsNotification': dto.newSearchItemsNotification || newItemsNotificationValues.day // one email per day by default
  }
}

export const convertSearchToDto = (search: SearchModel): ISearchDTO => {
  return {
    ...search,
    'keywords': search.keywords,
    'groupedCodeIds': search.groupedCodes ? search.groupedCodes.map(code => code.id) : search.groupedCodeIds,
    'pscCodeIds': search.pscCodes ? search.pscCodes.map(code => code.id) : search.pscCodeIds,
    'customerIds': search.customers ? search.customers.map(customer => customer.id) : search.customerIds,
    'workLocation': 'SPECIFIC',
  }
}

export const convertStringToKeywords = (str?: string): string[] | null => {
  if (!str) {
    return null
  }
  return str.match(/[a-zA-Z0-9_\-:]+|("[^"]*")/g)
}

export const marketResearchFilterPercentage = {
  percent: 40,
  handler: (item: {like: number, dislike: number}): number => {
    return Math.round(item.like / ((item.like + item.dislike) || 1) * 100)
  }
}

export interface SapPercentageValue {
  value: number
  negative: boolean
}

export const marketResearchSapPercentage = {
  handler: (a: number, b: number, main: number): SapPercentageValue => {
    let negative = false
    if (main < 0) {
      negative = true
      main = Math.abs(main)
    }
    a = Math.abs(a)
    b = Math.abs(b)
    return {
      value: Math.round(main / ((a + b) || 1) * 100),
      negative
    }
  }
}

/**
 * Helper determines and notifies if auto-search creator should be triggered and called
 * @param user
 * @param searches
 * @param search
 */

export const autoSearch = {
  _validator(user?: UserBaseModel, searches?: ISearchLightModel[], search?: ISearchDTO): boolean {
    return !!(
      (user) &&
      (searches && searches.length < 3) &&
      (search && search.keywords.length > 0) &&
      (!searches.some(s => s.name === search.keywords.join(' ')))
    )
  },
  _creator(dto: ISearchDTO, dispatch?: Dispatch) {
    return autoSearchCreator(dispatch)(dto)
  },
  _notifier(user?: UserBaseModel, dispatch?: Dispatch) {
    if (!user || !user.paymentPlan || user.paymentPlan.plan === PLAN.FREE) return

    return updateUserSettings(dispatch)({
      hpNewSearchItemEmailNotification: true
    } as IUserSettings)
  },
  async runner(user?: UserBaseModel, searches?: ISearchLightModel[], search?: ISearchDTO, dispatch?: Dispatch) {
    if (!this._validator(user, searches, search)) return

    await this._creator(search!, dispatch)
    await this._notifier(user, dispatch)

    return true
  }
}

/*
* Helper determines a user who entered the service from google or something and visited certain contract.
* If no filters were selected before, if he isn't logged in and it's a first visit of listing page -
* We should preselect certain filters for him. This object stands for the logic of this functionality
 */
export const autoFilters = {
  triggered: false,

  extractData(rfpDetails: IHpRfpDetailModel | null): IAutoFiltersRfp | null {
    if (!rfpDetails) return null
    return {
      id: rfpDetails.id,
      naics: rfpDetails.naicsDescription?.id || null,
      psc: rfpDetails.pscDescription?.id || null,
      title: rfpDetails.title
    }
  },

  validate(someFiltersSelected?: boolean, user?: IUserBaseModel, rfp?: IAutoFiltersRfp) {
    return !someFiltersSelected && !user && rfp && !this.triggered
  },

  run(dispatch: Dispatch, rfp?: IAutoFiltersRfp) {
    if (!rfp) return
    this.triggered = true
    setAutoFiltersValues(dispatch)(rfp)
  }
}

export const addFiltersToUrl = (history: H.History<any>, key: string, value: string|number|string[]|number[]|null): void => {
  const mapper: { [key: string]: UrlFilters } = {
    keywords: 'keywords',
    pscCodeIds: 'psc',
    groupedCodeIds: 'naics',
    customerIds: 'customers',
    locationIds: 'locations',
    setAsideIds: 'setAsides',
    pastPerformance: 'pastPerformance',
    projectTypeIds: 'projectTypes',
    dueDateIds: 'dueDates',
    analyzedTypeIds: 'analyzedTypes'
  }
  if (!mapper[key]) return

  const queryParams = new URLSearchParams(history.location.search)
  let state = !!value
  if (Array.isArray(value) && (value.length === 0 || value.length > 100)) state = false

  if (state) {
    queryParams.set(mapper[key],
      Array.isArray(value)
        ? (value as string[]).map(item => item.toString()).join(',')
        : value!.toString()
    )
  } else {
    queryParams.delete(mapper[key])
  }
  history.push(`${history.location.pathname}?${queryParams.toString()}`)
}

export const resetFiltersInUrl = (history: H.History<any>) => {
  history.push(history.location.pathname)
}