import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import { DTOValues } from 'modules/Main/User/MarketResearch/modules/SearchName'
import { createSearch as createSearchAction, updateSearch as updateSearchAction } from 'store/hp/search/actions'
import {
  GetItemsResponseModel,
  ManageSearchPayload,
  ManageSearchResponseModel,
  SearchModel
} from 'models/hp/search/search.model'
import { HpMarketResearchKeywordResult, HpMarketResearchKeywordResultResponseModel } from 'models/hp/rfp/rfp.model'
import { MarketGroupedCodePayload, MarketGroupedCodeResponseModel } from 'models/hp/grouped-code/grouped-code.model'
import { MarketAgenciesResponseModel, MarketAgency } from 'models/hp/agencies/agencies.model'
import {
  MarketSetAsideResponse,
  MarketSize,
  MarketSizeResponseModel,
  MarketSapResponseModel,
  SapItem,
  MarketCompanyResponseModel,
  SimilarCompanyItem,
  MarketCodesResponseModel,
  MarketCodesGroupedResponseModel
} from 'models/hp/market-research/market-research.model'
import {
  MarketLocationModel,
  MarketLocationsResponseModel
} from 'models/hp/location/location.model'
import { BaseResponseModel } from 'models/base/base.model'
import { IFilters } from 'modules/Main/User/MarketResearchTools/Sap/Filter'
import { ISimilarCompaniesFilters } from 'modules/Main/User/MarketResearchTools/SimilarCompanies/Filters'
import { ISimilarCompany } from 'modules/Main/User/MarketResearchTools/SimilarCompanies/Listing'
import {
  MarketSimilarCompaniesResponseModel,
  MarketSimilarCompanyGroup
} from 'models/hp/companies/companies.model'
import { ICodesFiltersConverted } from 'modules/Main/User/MarketResearchTools/Codes/Filters'

export const MARKET_RESEARCH_SET_SEARCH = 'MARKET_RESEARCH_SET_SEARCH'
export const MARKET_RESEARCH_RESET = 'MARKET_RESEARCH_RESET'
export const MARKET_RESEARCH_UPDATE_SEARCH_NAME = 'MARKET_RESEARCH_UPDATE_SEARCH_NAME'
export const MARKET_RESEARCH_UPDATE_FILTER = 'MARKET_RESEARCH_UPDATE_FILTER'

export const manageSearch = (dispatch: Dispatch) => async (values: DTOValues, searchId?: string): Promise<ManageSearchPayload | void> => {
  if (!searchId) {
    const search = await createSearchAction(values)
    addSearchToMarketResearch(dispatch)(search)
    return search
  } else {
    await updateSearchAction(values, searchId)
    dispatch({
      type: MARKET_RESEARCH_UPDATE_SEARCH_NAME,
      payload: values.name
    })
  }
}

export const addSearchToMarketResearch = (dispatch: Dispatch) => (search: SearchModel | ManageSearchPayload) => {
  dispatch({
    type: MARKET_RESEARCH_SET_SEARCH,
    payload: search
  })
}

export const resetMarketResearch = (dispatch: Dispatch) => {
  dispatch({
    type: MARKET_RESEARCH_RESET
  })
}

export const updateSearchFilter = (dispatch: Dispatch) => async (field: string, value: any[]) => {
  dispatch({
    type: MARKET_RESEARCH_UPDATE_FILTER,
    payload: { field, value: value.map(x => Number(x)) }
  })
}

export const runMarketResearch = (searchId: string | number, keywords: string[], type: string) => {
  return request<ManageSearchResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.research, {
    schema: ManageSearchResponseModel,
    body: { searchId: searchId || undefined, keywords, type }
  })
}

export const getMarketResearchItems = async (searchId: string | number, page?: number): Promise<GetItemsResponseModel> => {
  const res = await request<GetItemsResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.items(searchId, page), {
    schema: GetItemsResponseModel,
  })
  return res.data
}

export const categorizeItem = async (rfpId: string, searchId: string | number, categorization: string) => {
  return request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.categorization(rfpId), {
    schema: BaseResponseModel,
    body: { searchId, categorization }
  })
}

export const getKeywordsResults = async (searchId: string | number): Promise<HpMarketResearchKeywordResult[]> => {
  const res = await request<HpMarketResearchKeywordResultResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.keywords(searchId), {
    schema: HpMarketResearchKeywordResultResponseModel
  })
  return res.data.data
}

export const getCodesResults = async (searchId: string | number): Promise<MarketGroupedCodePayload> => {
  const res = await request<MarketGroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.codes(searchId), {
    schema: MarketGroupedCodeResponseModel
  })
  return res.data.data
}

export const getAgenciesResults = async (searchId: string | number): Promise<MarketAgency[]> => {
  const res = await request<MarketAgenciesResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.agencies(searchId), {
    schema: MarketAgenciesResponseModel
  })
  return res.data.data
}

export const getLocationsResults = async (searchId: string | number): Promise<MarketLocationModel[]> => {
  const res = await request<MarketLocationsResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.locations(searchId), {
    schema: MarketLocationsResponseModel
  })
  return res.data.data
}

export const getMarketSizeResults = async (searchId: string | number): Promise<MarketSize> => {
  const res = await request<MarketSizeResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.marketSize(searchId), {
    schema: MarketSizeResponseModel
  })
  return res.data.data
}

export const downloadMarketSizeReport = async (searchId: string | number): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.Results.marketSizeReport(searchId), {
    schema: BaseResponseModel
  })
}

export const getMarketSetAsides = async (): Promise<number[]> => {
  const res = await request<MarketSetAsideResponse>(RequestMethods.Get, Paths.Hp.MarketResearch.setAside, {
    schema: MarketSetAsideResponse
  })
  return res.data.data
}

export const setMarketSetAsides = async (setAsideIds: number[]): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.setAside, {
    schema: BaseResponseModel,
    body: { setAsideIds }
  })
}

export const getMarketCustomers = async (): Promise<MarketAgency[]> => {
  const res = await request<MarketAgenciesResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.customer, {
    schema: MarketAgenciesResponseModel
  })
  return res.data.data
}

export const setMarketCustomers = async (customers: string[]): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.customer, {
    schema: BaseResponseModel,
    body: { customers },
    lazyUser: true
  })
}

export const getMarketResearchSap = async (dto: IFilters): Promise<SapItem[]> => {
  const res = await request<MarketSapResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.sap, {
    schema: MarketSapResponseModel,
    body: dto
  })

  return res.data.data
}

export const getMarketResearchCompanies = async (dto: ISimilarCompaniesFilters, page: number): Promise<SimilarCompanyItem[]> => {
  const res = await request<MarketCompanyResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.companies(page), {
    schema: MarketCompanyResponseModel,
    body: dto
  })

  return res.data.data
}

export const setMarketResearchCompanies = async (companies: ISimilarCompany[], name: string): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.companies, {
    schema: BaseResponseModel,
    body: { name, companies },
    lazyUser: true
  })
}

export const getSimilarCompaniesGroups = async (): Promise<MarketSimilarCompanyGroup[]> => {
  const res = await request<MarketSimilarCompaniesResponseModel>(RequestMethods.Get, Paths.Hp.MarketResearch.companies, {
    schema: MarketSimilarCompaniesResponseModel
  })
  return res.data.data
}

export const deleteSimilarCompaniesGroup = async (group: MarketSimilarCompanyGroup): Promise<void> => {
  await request<MarketSimilarCompaniesResponseModel>(RequestMethods.Delete, Paths.Hp.MarketResearch.companies, {
    schema: MarketSimilarCompaniesResponseModel,
    body: {
      companies: ['company-id', group.id]
    }
  })
}

export const getMarketResearchCodes = async (dto: ICodesFiltersConverted): Promise<MarketCodesGroupedResponseModel> => {
  const res = await request<MarketCodesResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.codes, {
    schema: MarketCodesResponseModel,
    body: {keywords: Object.values(dto).flat()}
  })

  return res.data.data
}

export const getMarketResearchKeywordsItems = async (keywords: string[], type: string): Promise<GetItemsResponseModel> => {
  const res = await request<GetItemsResponseModel>(RequestMethods.Post, Paths.Hp.MarketResearch.Tools.keywords, {
    schema: GetItemsResponseModel,
    body: { keywords, type }
  })
  return res.data
}