import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { IAd } from './ad.interface'

@jsonObject
export class Ad implements IAd {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  name: string = ''

  @jsonMember({constructor: String})
  img: string = ''
}

@jsonObject
export class AdResponsePayload extends BaseResponseModel {
  @jsonMember({constructor: Number})
  count: number = 0

  @jsonArrayMember(Ad)
  data: Ad[] = []
}

@jsonObject
export class AdResponseModel extends BaseResponseModel {
  @jsonMember({constructor: AdResponsePayload })
  data: AdResponsePayload = new AdResponsePayload()
}