import React from 'react'
import Navigation from '../Navigation'
import Steps from '../Steps'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hpRfpDetailsSelector } from 'store/hp/rfp/selector'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { autoFilters } from 'helpers/search'

interface RfpDetailsLayoutProps {
    children: JSX.Element | React.Component
}

const RfpDetailsLayout = ({children}: RfpDetailsLayoutProps) => {
  const history = useHistory()
  const rfpDetails = useSelector(hpRfpDetailsSelector)
  const user = useSelector(userSelector) as IUserBaseModel

  const onBack = () => {
    document.referrer ? history.push('/contracts', autoFilters.extractData(rfpDetails.data)) : history.goBack()
  }

  return (
    <>
      <Navigation user={user} onBack={onBack} />
      <Steps />
      {children}
    </>
  )
}

export default RfpDetailsLayout