import React from 'react'
import styles from './index.module.scss'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

const Keywords = () => {
  const history = useHistory()

  return (
    <div className={styles['paper']}>
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/user/market-research/tools/keywords')}
      >
        Keywords tool
      </Button>
    </div>
  )
}

export default Keywords