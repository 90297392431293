import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { MIN_PASSWORD_LEN } from 'utils/const'

export interface ISignUpForm {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const SIGN_UP_FORM_INIT_VALUE: ISignUpForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
}

export const SIGN_UP_FORM_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('First Name')),
  lastName: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Last Name')),
  email: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Email'))
    .email(ERROR_MESSAGES.Field.NotValid('Email')),
  password: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Password'))
    .min(MIN_PASSWORD_LEN, ERROR_MESSAGES.Field.MinLen('Password', MIN_PASSWORD_LEN)),
})
