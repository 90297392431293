import React from 'react'
import {Typography} from '@material-ui/core'
import { default as Logo } from 'assets/svg-icons/main-logo.svg'
import styles from './index.module.scss'

interface Data {
  title?: string
  body: string
}

const data: Data[] = [
  {
    body: `FedScout is a Freemium app. This SERVICE is provided by FedScout and is intended for use as is. This page is
          used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information
          if you decide to use our Service. If you choose to use our Service, then you agree to the collection and use of
          information in relation to this policy. The Personal Information that we collect is used for providing and
          improving the Service. we will not use or share your information with anyone except as described in this Privacy
          Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
          accessible at FedScout unless otherwise defined in this Privacy Policy.
          Information Collection and Use`
  },
  {
    body: `For a better experience, while using our Service, we may require you to provide us with certain personally
          identifiable information, including but not limited to your email address and your name. The information that we
          request will be retained by us and used as described in this privacy policy.`
  },
  {
    title: 'Log Data',
    body: `We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and
          information (through third party products) on your phone called Log Data. This Log Data may include information
          such as your device Internet Protocol ("IP") address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of the Service, and other statistics.`
  },
  {
    title: 'Use data',
    body: `Our product tracks user behavior, e.g. search criteria and "liked" RFPs to learn user preferences and return
    better results. Behavior data is never shared at an individual or corporate level but may be used in large scale
    analysis and shared in an aggregate manner to discuss general market trends. We will ensure that company
    specificinformation can not be derived from these analyses`
  },
  {
    title: 'Cookies',
    body: `Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are
    sent to your browser from the websites that you visit and are stored on your device's internal memory. This
    Service does not use these "cookies" explicitly. However, the app may use third party code and libraries that
    use "cookies" to collect information and improve their services. You have the option to either accept or refuse
    these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.`
  },
  {
    title: 'Service Providers',
    body: `We may employ third-party companies and individuals due to the following reasons: To facilitate our Service; To
    provide the Service on our behalf; To perform Service-related services; or to assist us in analyzing how our
    Service is used. We want to inform users of this Service that these third parties have access to your Personal
    Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not
    to disclose or use the information for any other purpose.`
  },
  {
    title: 'Security',
    body: `We value your trust in providing us your Personal Information, thus we are striving to use commercially
    acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic
    storage is 100% secure and reliable, and we cannot guarantee its absolute security.`
  },
  {
    title: 'Links to Other Sites',
    body: `This Service may contain links to other sites. If you click on a third-party link, you will be directed to that
    site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the
    Privacy Policy of these websites. we have no control over and assume no responsibility for the content, privacy
    policies, or practices of any third-party sites or services.`
  },
  {
    title: 'Children\'s Privacy',
    body: `These Services do not address anyone under the age of 13. we do not knowingly collect personally identifiable
    information from children under 13. In the case we discover that a child under 13 has provided us with personal
    information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that
    your child has provided us with personal information, please contact us so that we will be able to do necessary
    actions.`
  },
  {
    title: 'Changes to This Privacy Policy',
    body: `We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for
    any changes. we will notify you of any changes by posting the new Privacy Policy on this page. This policy is
    effective as of 2020-04-24`
  },
  {
    title: 'Contact Us',
    body: `If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
    info@federal-foundry.com.`
  }
]

const PrivacyPolicy = () => {
  return (
    <div className='global-body'>
      <div className={styles['title-logo']}><img src={Logo} alt='logo' width='60' height='60'/></div>
      <Typography variant={'h4'} paragraph align='center'>FedScout Privacy Policy</Typography>

      {data.map((item, idx) => (
        <div key={idx}>
          {item.title && <Typography variant={'h5'} gutterBottom>{item.title}</Typography>}
          <Typography paragraph>{item.body}</Typography>
        </div>
      ))}
    </div>
  )
}

export default PrivacyPolicy