import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { numberWithCommas} from 'helpers/numbers'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, useTheme, CircularProgress, Button } from '@material-ui/core'
import Divider from 'components/v2/Divider'
import SvgIcon from 'components/SvgIcon'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { useSelector } from 'react-redux'
import { MarketSize } from 'models/hp/market-research/market-research.model'
import { searchSelector } from 'store/hp/market-research/selectors'
import { getMarketSizeResults, downloadMarketSizeReport } from 'store/hp/market-research/actions'
import { markSearchDone as markSearchDoneAction } from 'store/hp/search/actions'
import BorderLinearProgress from '../../components/BorderLinearProgress'
import ActionsBar from '../../components/ActionsBar'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import InfoButtons from './InfoButtons'
import { VideoSourceType } from 'utils/const'
import { numberToStrWithRound } from 'common/number'
import { SearchModel } from 'models/hp/search/search.model'

const SearchMarketSize = () => {
  const theme = useTheme()
  const search = useSelector(searchSelector) as SearchModel
  const history = useHistory()
  const { show } = useNotification()

  const [loading, setLoading] = React.useState(false)
  const [reportRequested, setReportRequested] = React.useState(false)

  const [marketSize, setMarketSize] = React.useState<MarketSize>({
    totalMarketSize: 0,
    contractsCount: 0,
    marketSizeBySetAsides: []
  })

  const downloadReport = async () => {
    try {
      await downloadMarketSizeReport(search.id)
      show('Report will be sent via email when ready', NotificationTypeList.Success)
      setReportRequested(true)
    }
    catch (e) {
      show('Something went wrong, please try again', NotificationTypeList.Error)
    }
  }

  const handleFinish = async () => {
    try {
      await markSearchDoneAction(search.id)
      show('Search saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    setLoading(true)
    getMarketSizeResults(search.id)
      .then(res => {
        setMarketSize(res)
      })
      .finally(() => setLoading(false))
  }, [search.id])

  if (loading) {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Typography variant='h2'>Your Market</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_YOUR_MARKET}/>
            <Typography variant='h4'>
              SUCCESS!!
            </Typography>
            <Typography>
              • I hope this didn’t take too long
            </Typography>
            <Typography>
              • You should have a very solid (but draft) understanding of your potential market
            </Typography>
            <Typography>
              • You may see instances where there are contracts but no dollars. This is typically because the government began an acquisition but hasn’t completed it.
            </Typography>
            <Typography>
              • In the downloadable report you’ll find:
            </Typography>
            <Typography style={{ paddingLeft: '12px' }}>
              • Market sizing
            </Typography>
            <Typography style={{ paddingLeft: '12px' }}>
              • Your NAICS and PSC codes
            </Typography>
            <Typography style={{ paddingLeft: '12px' }}>
              • Your customers and contact information for:
            </Typography>
            <Typography style={{ paddingLeft: '24px' }}>
              • Their small business offices
            </Typography>
            <Typography style={{ paddingLeft: '24px' }}>
              • Relevant points of contact
            </Typography>
            <Typography gutterBottom>
              • I recommend taking this analysis to your local PTAC, SBDC, or paid consultant and use this
              as the start of a conversation to help you refine your understanding of the market
            </Typography>
            <Typography variant='h4' gutterBottom>
              GOOD LUCK!
            </Typography>
            <InfoButtons />
          </Grid>
        </Grid>
        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>
            You Market
          </Typography>
          <div className={styles['vertical-divider-left']} />

          <Grid className={styles['right-block-content']}>
            <Grid className={styles['form']}>
              <div>
                {
                  marketSize.totalMarketSize > 0 || marketSize.contractsCount > 0 ? (
                    <div className={styles['gutter-bottom']}>
                      <Grid container alignItems='center'>
                        <Grid item md={'auto'}>
                          <SvgIcon name='arrow-right' color={theme.palette.warning.main} width={40} height={40} />
                        </Grid>
                        <Grid item md={11}>
                          <Typography>Estimated $/Year: <b>${numberWithCommas(marketSize.totalMarketSize)}</b></Typography>
                        </Grid>
                      </Grid>
                      <Grid container alignItems='center'>
                        <Grid item md={'auto'}>
                          <SvgIcon name='arrow-right' color={theme.palette.warning.main} width={40} height={40} />
                        </Grid>
                        <Grid item md={11}>
                          <Typography>Estimated contracts/Year: <b>{marketSize.contractsCount}</b></Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <Grid container justifyContent='center' className={styles['gutter-top']}>
                      <Grid item><Typography variant='h5'>No data found</Typography></Grid>
                    </Grid>
                  )
                }
                <div className={styles['gutter-top']}>
                  <Typography variant='h5'>Set-Aside (Average contracts/Year, $/Year)</Typography>
                  <Divider dark gutterBottom />
                </div>
                <div className={styles['gutter-top']}>
                  {
                    marketSize.marketSizeBySetAsides &&
                      marketSize.marketSizeBySetAsides.length > 0 ?
                      marketSize.marketSizeBySetAsides.map((item, idx) => (
                        <Grid container key={idx} spacing={2}>
                          <Grid item md={3}>
                            <Typography>{item.typeOfSetAsideDescription}</Typography>
                          </Grid>
                          <Grid item md={1}>
                            {numberWithCommas(item.contractsCount)}
                          </Grid>
                          <Grid item md={1}>
                            {numberToStrWithRound(item.marketSize)}
                          </Grid>
                          <Grid item md={7}>
                            {Math.round(item.marketSize / marketSize.totalMarketSize * 100) > 0 && (
                              <BorderLinearProgress
                                value={Math.round(item.marketSize / marketSize.totalMarketSize * 100)}
                              />
                            )}
                          </Grid>
                        </Grid>
                      )) : (
                        <Grid container justifyContent='center'>
                          <Grid item><Typography variant='h5'>No data found</Typography></Grid>
                        </Grid>
                      )}
                </div>
              </div>
              <Grid container justifyContent='center' style={{ padding: '24px 0' }}>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={downloadReport}
                    disabled={reportRequested}
                  >Download full report</Button>
                </Grid>
              </Grid>
              <ActionsBar
                handleGoBack={() => history.goBack()}
                handleFinish={handleFinish}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchMarketSize