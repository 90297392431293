import React from 'react'
import styles from '../../index.module.scss'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { getPiplineTimeline } from 'store/hp/pipeline/rfp/action'
import { PipelineTimelineOrderType } from 'models/hp/pipeline/pipeline.interface'

interface TableHeadItemProps {
    title: string
    customClassName: string
    fieldName: string
    withSort: boolean
    page: number
}

const TableHeadItem = ({title, customClassName, fieldName, withSort, page}: TableHeadItemProps) => {
  const dispatch = useDispatch()
    
  const handleSort = (order: PipelineTimelineOrderType) => {
    getPiplineTimeline(dispatch)(page, undefined, undefined, fieldName, order)
  }

  return (
    <div className={classNames(styles['gant-task-header-column'], styles[customClassName])}>
      <Typography className={styles['gant-task-header-column-label']}>{title}</Typography>
      {withSort ? (
        <div className={styles['gant-task-header-column-actions']}>
          <div className={styles['gant-task-header-column-actions-triangle']} onClick={() => handleSort('ASC')} />
          <div className={classNames(styles['gant-task-header-column-actions-triangle'], styles['rotate'])} onClick={() => handleSort('DESC')} />
        </div>
      ) : null}
    </div>
  )
}

export default TableHeadItem