
export const getEscapedString = (str: string): string => {
  ['[', '\\', '^', '$', '.', '|', '?', '*', '+', '(', ')'].forEach(symbol => {
    str = str.replace(new RegExp(`\\${symbol}`, 'g'), `\\${symbol}`)
  })
  return str
}

export const clearHtmlString = (str: string): string => {
  const div = document.createElement('div')
  div.innerHTML = str
  div.querySelectorAll('script, img, style, meta, link, video').forEach(el => el.remove())
  return div.innerHTML
}

export const capitalizedText = (text: any): string | any => {
  if (!text || typeof text !== 'string') {
    return text
  }
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const cleanAwardTitle = (text: string): string => {
  text = text.replace(/(igf::..::igf[ -:]{0,4})/gim, '')
  return text
}

export const startsWithHttp = (str: string): boolean => /^http/i.test(str)