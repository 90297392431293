import { createSelector } from 'reselect'
import { IRootState } from '../../reducers'

const locations = (state: IRootState) => state.locations

export const locationsLoadedSelector = createSelector(
  locations,
  (locations) => locations.get('loaded')
)
export const locationsLoadingSelector = createSelector(
  locations,
  (locations) => locations.get('loading')
)
export const locationsSelector = createSelector(
  locations,
  (locations) => locations.get('data')
)

export const locationsOptionsSelector = createSelector(
  locations,
  (locations) => locations.get('data')?.reduce((acc, filter) => {
    const filters = filter.sub.map((filter) => ({
      id: filter.id,
      name: filter.name
    }))
    return acc.concat(filters as any)
  }, []) || []
)

export const locationCountSelector = createSelector(
  locations,
  locations => locations.get('count')
)
