import { Paper, Theme, Typography, useTheme } from '@material-ui/core'
import styles from './index.module.scss'
import SvgIcon from '../SvgIcon'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export enum NotificationTypeList {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success'
}

export type notificationTypes =
  NotificationTypeList.Error
  | NotificationTypeList.Warning
  | NotificationTypeList.Info
  | NotificationTypeList.Success

const iconStrategy: { [key: string]: string } = {
  [NotificationTypeList.Error]: 'frown',
  [NotificationTypeList.Warning]: 'confused',
  [NotificationTypeList.Info]: 'smile-squint-wink',
  [NotificationTypeList.Success]: 'smile-beam',
}

const useStyles = makeStyles({
  rounded: {
    borderRadius: '6px',
    padding: '6px'
  },
  root: {
    color: '#FFFFFF',
    margin: '0 15px'
  }
})

interface INotificationTypeProps {
  children: React.ReactNode
  onClose: () => void
  type?: notificationTypes
}

const NotificationType = (props: INotificationTypeProps) => {
  const { type = NotificationTypeList.Success, children, onClose } = props
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const backgroundColorStrategy: { [key: string]: string } = {
    [NotificationTypeList.Error]: theme.palette.error.main,
    [NotificationTypeList.Warning]: theme.palette.warning.main,
    [NotificationTypeList.Info]: theme.palette.info.main,
    [NotificationTypeList.Success]: theme.palette.success.main,
  }

  return (
    <Paper className={classes.rounded} style={{ backgroundColor: backgroundColorStrategy[type] }}>
      <div className={styles['notification-wrapper']}>
        <SvgIcon className={styles['icon']} name={iconStrategy[type]} width={25} height={25} color='#FFFFFF'/>
        <Typography className={classes.root}>{children}</Typography>
        <SvgIcon onClick={onClose} className={styles['icon']} name='multiply' width={16} height={16} color='#FFFFFF'/>
      </div>
    </Paper>
  )
}

export default NotificationType
