export const tabLabels = [
  {
    id: 1,
    title: 'Timeline' 
  },
  {
    id: 2,
    title: 'Health & Revenue'
  },
  // {
  //   id: 3,
  //   title: 'By Stage'
  // },
  // {
  //   id: 4,
  //   title: 'Comparison'
  // },
  // {
  //   id: 5,
  //   title: 'Loss Analysis'
  // },
  // {
  //   id: 6,
  //   title: 'P-Win Analysis'
  // },
  {
    id: 7,
    title: 'Pending Award'
  },
  {
    id: 8,
    title: 'Archive'
  },
]