import React, { useEffect, useRef, useState } from 'react'
import styles from '../index.module.scss'
import { Grid, Paper, Typography, Button} from '@material-ui/core'
import { getTokens as getTokensAction } from 'store/common/owner/actions'
import { createToken } from 'store/common/owner/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { TokenModel } from 'models/common/owner/token.model'
import Item, { Values } from './Item'
import CreateTokenModal from './components/CreateTokenModal'

const Index = () => {
  const notification = useRef(useNotification())

  const [tokens, setTokens] = useState<TokenModel[]>([])
  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
    (async () => {
      const items = await getTokensAction()
      setTokens(items)
    })()
  }, [])

  const handleDelete = (id: string) => {
    setTokens(tokens.filter(token => token.id !== id))
  }

  const handleUpdate = (id: string, values: Values) => {
    setTokens(tokens.map(token => token.id === id ? ({...token, ...values}) : token))
  }

  const handleCreate = async (token: string) => {
    try {
      const newToken = await createToken(token)
      notification.current.show('Token successfully added', NotificationTypeList.Success)
      setModalOpened(prev => !prev)
      setTokens([newToken, ...tokens])
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['gutter-bottom']}>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => setModalOpened(true)}
        >
          Add new +
        </Button>
      </div>
      <Paper className={styles['container']}>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
        >
          <Grid item md={1}><Typography variant='h5'>ID</Typography></Grid>
          <Grid item md={4}><Typography variant='h5'>Token</Typography></Grid>
          <Grid item md={2}><Typography variant='h5'>Active</Typography></Grid>
          <Grid item md={2}><Typography variant='h5'>Last change</Typography></Grid>
          <Grid item md={3}><Typography variant='h5'>Actions</Typography></Grid>
        </Grid>
        {tokens.map((token, idx) => (
          <Item
            key={token.id}
            token={token}
            idx={idx + 1}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        ))}
      </Paper>

      {/*Modals*/}
      {modalOpened && (
        <CreateTokenModal
          onClose={() => setModalOpened(false)}
          onConfirm={handleCreate}
        />
      )}
    </div>
  )
}

export default Index