import React from 'react'
import { Button, Grid, styled } from '@material-ui/core'
import styles from './index.module.scss'

const StyledButton = styled(Button)(({theme}) => (
  {
    backgroundColor: theme.palette.grey['600'],
  }
))

interface Props {
  hasPrevious?: boolean
  hasNext?: boolean
  onPrevious?: () => void
  onNext: () => void
}


const EducationNavigationButtons = ({onPrevious, onNext, hasPrevious = true, hasNext = true}: Props) => {
  return (
    <div className={styles['navigation-buttons']}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Grid item md={6} sm={6} xs={12}>
          <Button
            fullWidth
            variant='outlined'
            onClick={onPrevious}
            disabled={!hasPrevious}
          >Back
          </Button>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <StyledButton
            fullWidth
            variant='contained'
            color='primary'
            onClick={onNext}
          >{hasNext ? 'Next' : 'Finish'}
          </StyledButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default EducationNavigationButtons