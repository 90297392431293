import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route as RRoute, RouteProps, useHistory } from 'react-router-dom'
import { getQueryValueByKey } from 'common/query'
import { getUserAction, getUserIncumbentGroup, getUserSettingsAction } from 'store/common/user/actions'
import { getItems as getAdsAction } from 'store/common/ads/actions'
import { userActivity } from 'store/common/owner/actions'

interface Props extends RouteProps {}

const RouteWithRedirect = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const redirectRoute = getQueryValueByKey('redirect', history.location.search)

  useEffect(() => {
    getUserAction()(dispatch)
      .then(() => {
        getUserSettingsAction(dispatch)()
        getUserIncumbentGroup(dispatch)()
        userActivity.event(userActivity.activities.lastLogin)
      })
    getAdsAction(dispatch)()
  }, [ dispatch ])

  if (redirectRoute) {
    return <Redirect to={redirectRoute} />
  }

  if (history.location.pathname === '/') {
    return <Redirect to={'/contracts'} />
  }

  return (
    <RRoute {...props}>
      {props.children}
    </RRoute>
  )
}

export default RouteWithRedirect