import { IBaseManageResponseModel, IBasePaginationModel, IBaseResponseModel } from './base.interfaces'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class BaseResponseModel implements IBaseResponseModel {

  @jsonMember({ constructor: String })
  message: string = ''

  @jsonMember({ constructor: Boolean })
  success: boolean = false

  @jsonArrayMember(String)
  errors?: string[] = undefined
}

@jsonObject
export class BasePaginationModel extends BaseResponseModel implements IBasePaginationModel {

  @jsonMember({ constructor: Number })
  new: number  = 0

  @jsonMember({ constructor: String })
  searchName: string = ''

  @jsonMember({ constructor: Number })
  count: number = 0
}

@jsonObject
export class BaseManageResponsePayload {
  @jsonMember({constructor: String})
  id: string = ''
}

@jsonObject
export class BaseManageResponseModel extends BaseResponseModel implements IBaseManageResponseModel {
  @jsonMember({constructor: BaseManageResponsePayload})
  data: BaseManageResponsePayload = new BaseManageResponsePayload()
}