import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { searchSelector } from 'store/hp/market-research/selectors'
import { useNotification } from 'context/NotificationContext'
import { getLocationsResults } from 'store/hp/market-research/actions'
import { addSearchLocations as addSearchLocationsAction } from 'store/hp/search/actions'
import { updateSearchFilter as updateSearchFilterAction } from 'store/hp/market-research/actions'
import { MarketLocationModel } from 'models/hp/location/location.model'
import { Grid, Typography, Checkbox, CircularProgress } from '@material-ui/core'
import ActionsBar from '../../components/ActionsBar'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import BorderLinearProgress from '../../components/BorderLinearProgress'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

interface CheckedRegions {
  [key: string]: boolean
}

const SearchRegions = () => {
  const history = useHistory()
  const search = useSelector(searchSelector) as SearchModel
  const dispatch = useDispatch()
  const { show } = useNotification()

  const [requesting, setRequesting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [regionsResults, setRegionsResults] = React.useState<MarketLocationModel[]>()
  const [checkedRegions, setCheckedRegions] = React.useState<CheckedRegions>({})
  const [totalContracts, setTotalContracts] = React.useState(1)

  const handleChange = (id: number) => () => {
    setCheckedRegions(val => ({
      ...val,
      [id]: !val[id]
    }))
  }

  const handleChangeParent = (parent: MarketLocationModel) => () => {
    const state = !parent.sub.every(item => checkedRegions[item.id])
    const items = Object.values(parent.sub).reduce((acc, item) => {
      acc[item.id] = state
      return acc
    }, {} as CheckedRegions)
    setCheckedRegions(val => ({
      ...val,
      ...items
    }))
  }

  const addLocations = async () => {
    try {
      setRequesting(true)

      const regions: string[] = Object.keys(checkedRegions).reduce((acc, item) => {
        if (checkedRegions[item] && Number(item) > 0) {
          (acc as string[]).push(item)
        }
        return acc
      }, [])

      await addSearchLocationsAction(search.id, regions)
      await updateSearchFilterAction(dispatch)('locationIds', regions)

      setRequesting(false)
    }
    catch (e) {
      setRequesting(false)
      throw e
    }
  }

  const handleStop = async () => {
    try {
      await addLocations()
      show('Changes saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleContinue = async () => {
    try {
      await addLocations()
      history.push('/user/market-research/agencies')
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    setLoading(true)
    getLocationsResults(search.id)
      .then(res => {
        let contracts = 0
        setRegionsResults(res)
        setCheckedRegions(res.reduce((acc, item) => {
          item.sub.forEach(state => {
            contracts += state.marketSize
            acc[state.id] = search.locationIds.length > 0
              ? search.locationIds.includes(state.id)
              : true
          })
          return acc
        }, {} as CheckedRegions))
        setTotalContracts(contracts)
      })
      .finally(() => setLoading(false))
  }, [search.id])

  if (loading) {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Typography variant='h2'>Region</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_LOCATION} />
            <Typography variant='h4'>
              Where can you deliver your products and services
            </Typography>
            <Typography>
              • Some products and services can be delivered or performed anywhere while others tend to be localized
            </Typography>
            <Typography>
              • The charts show the contracting activity for your products/services by state
            </Typography>
            <Typography gutterBottom>
              • Select the states where you could realistically provide your service or deliver your product
            </Typography>
          </Grid>
        </Grid>

        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>
            Average number of contracts/year/state
          </Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']}>
            <Grid className={styles['form']}>
              <div>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item md={1} style={{ marginRight: '-40px' }}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Use</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Location</Typography>
                  </Grid>
                  <Grid item md={1} style={{ marginRight: '-40px' }}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold', paddingLeft: '10px' }}>#</Typography>
                  </Grid>
                  <Grid item md={8} />
                </Grid>
                {regionsResults && regionsResults.map((item, idx) => (
                  <Grid container key={idx} style={{ marginBottom: '16px' }}>
                    <Grid item md={12}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item md={1} style={{ marginRight: '-40px' }}>
                          <Checkbox
                            color='primary'
                            checked={item.sub.every(item => checkedRegions[item.id]) || item.region === 'No Region'}
                            onChange={handleChangeParent(item)}
                            disabled={item.region === 'No Region'}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant='body1'><b>{item.region}</b></Typography>
                        </Grid>
                        <Grid item md={1} style={{ marginRight: '-40px' }} />
                        <Grid item md={8} />
                      </Grid>
                    </Grid>
                    <Grid item style={{ paddingLeft: '16px' }} md={12}>
                      {item.sub.map((sub, idx) => (
                        <Grid container justifyContent='space-between' alignItems='center' key={idx}>
                          <Grid item md={1} style={{ marginRight: '-40px' }}>
                            <Checkbox
                              name='select-keywords'
                              color={'primary'}
                              value={sub.id}
                              onChange={handleChange(sub.id)}
                              checked={checkedRegions[sub.id] || !sub.id || false}
                              disabled={!sub.id}
                            />
                          </Grid>
                          <Grid item md={2}>
                            <Typography variant='body1'>{sub.name || 'No state'}</Typography>
                          </Grid>
                          <Grid item md={1} style={{ marginRight: '-40px' }}>
                            <Typography variant='body1'>
                              <b>{sub.marketSize}</b>
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            <BorderLinearProgress
                              transparent={true}
                              color='primary'
                              value={Math.round(sub.marketSize / totalContracts * 100)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </div>
              <ActionsBar
                handleGoBack={() => history.goBack()}
                handleContinue={handleContinue}
                handleStop={handleStop}
                requesting={requesting}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchRegions