import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import {
  AwardDetailsResponseModel,
  AwardListResponseModel,
  AwardSizeResponseModel,
  AwardTransactionsResponseModel,
  GovContractsResponseModel,
  SpendingResponseModel,
  SummaryOptionsResponseModel,
  SummaryResponseModel,
  TransitionMarketOptionsResponseModel,
  TransitionMarketOverviewByTypeResponseModel,
  TransitionMarketUSGMarketResponseModel,
  TransitionResponseModel
} from 'models/hp/awards/awards.model'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'
import { Pagination } from 'models/base/base.interfaces'
import { BaseResponseModel } from 'models/base/base.model'

export const AWARD_LIST = 'AWARD_LIST'
export const AWARD_LIST_ERROR = 'AWARD_LIST_ERROR'
export const AWARD_LIST_SUCCESS = 'AWARD_LIST_SUCCESS'
export const AWARD_LIST_APPEND = 'AWARD_LIST_APPEND'
export const AWARD_LIST_APPEND_SUCCESS = 'AWARD_LIST_APPEND_SUCCESS'
export const AWARD_LIST_APPEND_ERROR = 'AWARD_LIST_APPEND_ERROR'
export const AWARD_LIST_UPDATE_PAGINATION = 'AWARD_LIST_UPDATE_PAGINATION'
export const AWARD_LIST_RESET = 'AWARD_LIST_RESET'
export const AWARD_LIST_CATEGORIZE = 'AWARD_LIST_CATEGORIZE'
export const AWARD_LIST_CATEGORIZE_REMOVE = 'AWARD_LIST_CATEGORIZE_REMOVE'

export const GET_AWARD_DETAILS = 'GET_AWARD_DETAILS'
export const GET_AWARD_DETAILS_SUCCESS = 'GET_AWARD_DETAILS_SUCCESS'
export const GET_AWARD_DETAILS_ERROR = 'GET_AWARD_DETAILS_ERROR'

export const GET_AWARD_TRANSACTIONS = 'GET_AWARD_TRANSACTIONS'
export const GET_AWARD_TRANSACTIONS_SUCCESS = 'GET_AWARD_TRANSACTIONS_SUCCESS'
export const GET_AWARD_TRANSACTIONS_ERROR = 'GET_AWARD_TRANSACTIONS_ERROR'

export const GET_SPENDING_CHART = 'GET_SPENDING_CHART'
export const GET_SPENDING_CHART_SUCCESS = 'GET_SPENDING_CHART_SUCCESS'
export const GET_SPENDING_CHART_ERROR = 'GET_SPENDING_CHART_ERROR'

export const GET_AWARD_SIZE = 'GET_AWARD_SIZE'
export const GET_AWARD_SIZE_SUCCESS = 'GET_AWARD_SIZE_SUCCESS'
export const GET_AWARD_SIZE_ERROR = 'GET_AWARD_SIZE_ERROR'

export const GET_TRANSITION = 'GET_TRANSITION'
export const GET_TRANSITION_SUCCESS = 'GET_TRANSITION_SUCCESS'
export const GET_TRANSITION_ERROR = 'GET_TRANSITION_ERROR'

export const GET_SUMMARY_OPTIONS = 'GET_SUMMARY_OPTIONS'
export const GET_SUMMARY_OPTIONS_SUCCESS = 'GET_SUMMARY_OPTIONS_SUCCESS'
export const GET_SUMMARY_OPTIONS_ERROR = 'GET_SUMMARY_OPTIONS_ERROR'

export const GET_SUMMARY = 'GET_SUMMARY'
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS'
export const GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR'

export const SET_SUMMARY = 'SET_SUMMARY'
export const SET_SUMMARY_SUCCESS = 'SET_SUMMARY_SUCCESS'
export const SET_SUMMARY_ERROR = 'SET_SUMMARY_ERROR'

export const UPDATE_SUMMARY = 'UPDATE_SUMMARY'

export const GET_MARKET_SIZE = 'GET_MARKET_SIZE'
export const GET_MARKET_SIZE_SUCCESS = 'GET_MARKET_SIZE_SUCCESS'
export const GET_MARKET_SIZE_ERROR = 'GET_MARKET_SIZE_ERROR'

export const GET_TRANSITION_MARKET = 'GET_TRANSITION_MARKET'
export const GET_TRANSITION_MARKET_SUCCESS = 'GET_TRANSITION_MARKET_SUCCESS'
export const GET_TRANSITION_MARKET_ERROR = 'GET_TRANSITION_MARKET_ERROR'

export const GET_TRANSITION_MARKET_OVERVIEW = 'GET_TRANSITION_MARKET_OVERVIEW'
export const GET_TRANSITION_MARKET_OVERVIEW_SUCCESS = 'GET_TRANSITION_MARKET_OVERVIEW_SUCCESS'
export const GET_TRANSITION_MARKET_OVERVIEW_ERROR = 'GET_TRANSITION_MARKET_OVERVIEW_ERROR'

export const GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE = 'GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE'
export const GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_SUCCESS = 'GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_SUCCESS'
export const GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_ERROR = 'GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_ERROR'

export const GET_TRANSITION_MARKET_USG = 'GET_TRANSITION_MARKET_USG'
export const GET_TRANSITION_MARKET_USG_SUCCESS = 'GET_TRANSITION_MARKET_USG_SUCCESS'
export const GET_TRANSITION_MARKET_USG_ERROR = 'GET_TRANSITION_MARKET_USG_ERROR'

export const GET_GOV_CONTRACTS_OVERVIEW = 'GET_GOV_CONTRACTS'
export const GET_GOV_CONTRACTS_OVERVIEW_SUCCESS = 'GET_GOV_CONTRACTS_SUCCESS'
export const GET_GOV_CONTRACTS_OVERVIEW_ERROR = 'GET_GOV_CONTRACTS_ERROR'

export const RESET_AWARD = 'RESET_AWARD'

export const getList = (dispatch: Dispatch) => async (filters: IAwardSearchRecord): Promise<AwardListResponseModel> => {
  try {
    dispatch({
      type: AWARD_LIST
    })
    const res = await request<AwardListResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getList({}), {
      schema: AwardListResponseModel,
      body: filters || {}
    })
    dispatch({
      type: AWARD_LIST_SUCCESS,
      payload: res.data
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: AWARD_LIST_ERROR
    })
    throw e
  }
}

export const appendList = (dispatch: Dispatch) => async (filters: IAwardSearchRecord, pagination: Pagination) => {
  try {
    dispatch({
      type: AWARD_LIST_UPDATE_PAGINATION,
      payload: pagination
    })
    dispatch({
      type: AWARD_LIST_APPEND
    })
    const res = await request<AwardListResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getList(pagination), {
      schema: AwardListResponseModel,
      body: filters || {}
    })
    dispatch({
      type: AWARD_LIST_APPEND_SUCCESS,
      payload: res.data
    })
  }
  catch (e) {
    dispatch({
      type: AWARD_LIST_APPEND_ERROR
    })
    throw e
  }
}

export const getDetails = (dispatch: Dispatch) => async (id: string, byAwardId?: boolean) => {
  try {
    dispatch({
      type: GET_AWARD_DETAILS
    })
    const res = await request<AwardDetailsResponseModel>(
      RequestMethods.Get,
      byAwardId ? Paths.Hp.Awards.getDetailsByQuery(id) : Paths.Hp.Awards.getDetails(id),
      {
        schema: AwardDetailsResponseModel
      }
    )
    dispatch({
      type: GET_AWARD_DETAILS_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  }
  catch (e) {
    dispatch({
      type: GET_AWARD_DETAILS_ERROR,
      payload: e
    })
    if (!e.response && e.response.status !== 404) throw e
    return null
  }
}

export const resetList = (dispatch: Dispatch) => () => {
  dispatch({
    type: AWARD_LIST_RESET
  })
}

export const categorizeItem = (dispatch: Dispatch) => async (id: string, key?: string, dataKey?: string) => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Awards.categorized(id), {
    schema: BaseResponseModel,
    body: {
      type: 'pipeline__1'
    }
  })
  dispatch({
    type: AWARD_LIST_CATEGORIZE,
    payload: { id, key, dataKey }
  })
}

export const removeItemCategorization = (dispatch: Dispatch) => async (id: string) => {
  await request<BaseResponseModel>(RequestMethods.Delete, Paths.Hp.Awards.categorized(id), {
    schema: BaseResponseModel
  })
  dispatch({
    type: AWARD_LIST_CATEGORIZE_REMOVE,
    payload: { id }
  })
}

export const getTransactions = (dispatch: Dispatch) => async (id: string) => {
  dispatch({
    type: GET_AWARD_TRANSACTIONS
  })
  const res = await request<AwardTransactionsResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getTransactions(id), {
    schema: AwardTransactionsResponseModel
  })
  dispatch({
    type: GET_AWARD_TRANSACTIONS_SUCCESS,
    payload: res.data.data
  })
  return res.data.data
}

export const getSpendingChart = (dispatch: Dispatch) => async (id: string) => {
  dispatch({
    type: GET_SPENDING_CHART
  })
  const res = await request<SpendingResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getSpendingChart(id), {
    schema: SpendingResponseModel
  })
  dispatch({
    type: GET_SPENDING_CHART_SUCCESS,
    payload: res.data.data
  })
  return res.data.data
}

export const getAwardSize = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_AWARD_SIZE
    })
    const res = await request<AwardSizeResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getAwardSize(id), {
      schema: AwardSizeResponseModel
    })
    dispatch({
      type: GET_AWARD_SIZE_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_AWARD_SIZE_ERROR,
      payload: e
    })
    throw e
  }
}


export const getTransition = (dispatch: Dispatch) => async (id: string, keyword: string) => {
  try {
    dispatch({
      type: GET_TRANSITION
    })
    const res = await request<TransitionResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getTransition(id), {
      schema: TransitionResponseModel,
      body: { keyword }
    })
    dispatch({
      type: GET_TRANSITION_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_TRANSITION_ERROR,
      payload: e
    })
    throw e
  }
}

export const getSummaryOptions = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_SUMMARY_OPTIONS
    })
    const res = await request<SummaryOptionsResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getSummaryOptions(id), {
      schema: SummaryOptionsResponseModel,
    })
    dispatch({
      type: GET_SUMMARY_OPTIONS_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_SUMMARY_OPTIONS_ERROR,
      payload: e
    })
    throw e
  }
}

export const getSummary = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_SUMMARY
    })
    const res = await request<SummaryResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getSummary(id), {
      schema: SummaryResponseModel,
    })
    dispatch({
      type: GET_SUMMARY_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_SUMMARY_ERROR,
      payload: e
    })
    throw e
  }
}

export const setSummary = (dispatch: Dispatch) => async (id: string, values: {[key: string]: number}) => {
  try {
    dispatch({
      type: SET_SUMMARY
    })
    const res = await request<SummaryResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getSummary(id), {
      schema: SummaryResponseModel,
      body: {
        data: values
      }
    })
    dispatch({
      type: SET_SUMMARY_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: SET_SUMMARY_ERROR,
      payload: e
    })
    throw e
  }
}

export const updateSummary = (dispatch: Dispatch) => (key: string, id: number) => {
  dispatch({
    type: UPDATE_SUMMARY,
    payload: {key, id},
  })
}

export const getMarketSize = (dispatch: Dispatch) => async (id: string, keyword: string) => {
  try {
    dispatch({
      type: GET_MARKET_SIZE
    })
    const res = await request<TransitionResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getMarketSize(id), {
      schema: TransitionResponseModel,
      body: { keyword }
    })
    dispatch({
      type: GET_MARKET_SIZE_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_MARKET_SIZE_ERROR,
      payload: e
    })
    throw e
  }
}

export const getTransitionMarketOptions = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_TRANSITION_MARKET
    })
    const res = await request<TransitionMarketOptionsResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getTransitionMarketOptions(id), {
      schema: TransitionMarketOptionsResponseModel
    })
    dispatch({
      type: GET_TRANSITION_MARKET_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_TRANSITION_MARKET_ERROR,
      payload: e
    })
    throw e
  }
}

export const getTransitionMarketOverview = (dispatch: Dispatch) => async (id: string, pscCodes: string[]) => {
  try {
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW
    })
    const res = await request<TransitionResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getTransitionMarketOverview(id), {
      schema: TransitionResponseModel,
      body: { pscCodes }
    })
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW_ERROR,
      payload: e
    })
    throw e
  }
}

export const getTransitionMarketOverviewByType = (dispatch: Dispatch) => async (id: string, pscCodes: string[], type: number) => {
  try {
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE
    })
    const res = await request<TransitionMarketOverviewByTypeResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getTransitionMarketOverviewByType(id, type), {
      schema: TransitionMarketOverviewByTypeResponseModel,
      body: { pscCodes }
    })
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_TRANSITION_MARKET_OVERVIEW_BY_TYPE_ERROR,
      payload: e
    })
    throw e
  }
}

export const getTransitionMarketUSGMarket = (dispatch: Dispatch) => async (id: string, params: {
  keyword: string,
  incorporationKeyword: string
}) => {
  try {
    dispatch({
      type: GET_TRANSITION_MARKET_USG
    })
    const res = await request<TransitionMarketUSGMarketResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getTransitionMarketUSG(id), {
      schema: TransitionMarketUSGMarketResponseModel,
      body: {...params}
    })
    dispatch({
      type: GET_TRANSITION_MARKET_USG_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_TRANSITION_MARKET_USG_ERROR,
      payload: e
    })
    throw e
  }
}


export const getGovContractsOverview = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_GOV_CONTRACTS_OVERVIEW
    })
    const res = await request<GovContractsResponseModel>(RequestMethods.Get, Paths.Hp.Awards.getGovContractsOverview(id), {
      schema: GovContractsResponseModel
    })
    dispatch({
      type: GET_GOV_CONTRACTS_OVERVIEW_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  } catch(e) {
    dispatch({
      type: GET_GOV_CONTRACTS_OVERVIEW_ERROR,
      payload: e
    })
    throw e
  }
}


export const resetAward = () => {
  return {
    type: RESET_AWARD
  }
}


