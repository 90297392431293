import { IRootState } from '../../reducers'
import { createSelector } from 'reselect'
import { TSearchOpenedModals } from './reducers'

const hpSearch = (state: IRootState) => state.hpSearch

export const savedSearchesSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searches', 'data'])
)
export const savedSearchesAmountSelector = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['searches', 'data'])?.length || 0
)
export const savedSearchesLoadingSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searches', 'loading'])
)
export const savedSearchesLoadedSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searches', 'loaded'])
)

export const filterOptionsLoadingSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['filterOptions', 'loading'])
)

export const filterOptionsLoadedSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['filterOptions', 'loaded'])
)

export const selectedFiltersSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.get('searchFilters')
)

export const normalizedFilterOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.get('normalizedFilterOptions').data
)

export const selectedKeywordsSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'keywords'])
)

export const selectedPscOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'pscCodeIds'])
)

export const selectedNaicsOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'groupedCodeIds'])
)

export const selectedPastPerformanceOption = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'pastPerformance'])
)

export const setAsideOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['filterOptions', 'data']).setAsides
)

export const normalizedSetAsideOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['normalizedFilterOptions', 'data']).setAsides
)

export const selectedSetAsideOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'setAsideIds'])
)
export const projectTypeOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['filterOptions', 'data']).projectTypes
)
export const normalizedProjectTypeOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['normalizedFilterOptions', 'data']).projectTypes
)
export const selectedProjectTypeOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'projectTypeIds'])
)
export const dueDateOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['filterOptions', 'data']).dueDates
)
export const dueDateRangeOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['searchFilters']).dueDateRange
)
export const normalizedDueDateOptions = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['normalizedFilterOptions', 'data']).dueDates
)
export const selectedDueDateOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'dueDateIds'])
)
export const selectedAgenciesOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'customerIds'])
)
export const selectedLocationsOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'locationIds'])
)
export const selectedLocationsAllOptions = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'workLocation'])
)

export const selectedSearchLoadingSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['selectedSearch', 'loading'])
)
export const selectedSearchSelector = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['selectedSearch', 'data'])
)

export const estimatedValues = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['filterOptions', 'data']).estimatedValues
)
export const normalizedEstimatedValues = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['normalizedFilterOptions', 'data']).estimatedValues
)
export const selectedEstimatedValues = createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['searchFilters', 'estimatedValueIds'])
)

export const analyzedTypes = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['filterOptions', 'data']).analyzedTypes
)

export const selectedAnalyzedTypes = createSelector(
  hpSearch,
  (hpSearch: any) => hpSearch.getIn(['searchFilters', 'analyzedTypeIds'])
)

export const getSearchModalOpenedSelector = (key: TSearchOpenedModals) => createSelector(
  hpSearch,
  hpSearch => hpSearch.getIn(['openedModals', key])
)

export const someFilterSelectedSelector = createSelector(
  hpSearch,
  hpSearch =>
    !!(
      hpSearch.get('searchFilters').contractTypeIds.length ||
      hpSearch.get('searchFilters').customerIds.length ||
      hpSearch.get('searchFilters').dueDateIds.length ||
      hpSearch.get('searchFilters').estimatedEffortIds.length ||
      hpSearch.get('searchFilters').estimatedValueIds.length ||
      hpSearch.get('searchFilters').groupedCodeIds.length ||
      hpSearch.get('searchFilters').keywords.length ||
      hpSearch.get('searchFilters').locationIds.length ||
      hpSearch.get('searchFilters').projectTypeIds.length ||
      hpSearch.get('searchFilters').pscCodeIds.length ||
      hpSearch.get('searchFilters').setAsideIds.length ||
      hpSearch.get('searchFilters').analyzedTypeIds?.length ||
      hpSearch.get('searchFilters').pastPerformance ||
      hpSearch.get('searchFilters').dueDateRange
    )
)