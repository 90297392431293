import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class DetailCustomerDataModel {
  @jsonMember({constructor: Number})
  amount: number = 0
  @jsonMember({constructor: String})
  agencyShortName: string = ''
  @jsonMember({constructor: String})
  subAgencyCode: string = ''
  @jsonMember({constructor: String})
  subAgencyShortName: string = ''
  @jsonMember({constructor: String})
  type: string = ''
}
