import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { getSearchOptions } from 'store/hp/search/actions'
import { getAgenciesAction } from 'store/hp/agency/actions'
import { getLocationsAction } from 'store/hp/location/action'
import {
  filterOptionsLoadedSelector,
  filterOptionsLoadingSelector,
} from 'store/hp/search/selectors'
import { setAwardSearchFilterValueBool } from 'store/hp/awards/search/actions'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { agenciesLoadedSelector } from 'store/hp/agency/selectors'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { awardsDashboardLoadingSelector } from 'store/hp/awards/dashboard/selectors'
import KeywordInput from 'modules/Main/Awards/AwardHome/components/SearchFilters/KeywordInput'
import LocationFilter from 'modules/Main/Awards/AwardHome/components/SearchFilters/LocationFilter'
import PscFilter from 'modules/Main/Awards/AwardHome/components/SearchFilters/PscFilter'
import NaicsFilter from 'modules/Main/Awards/AwardHome/components/SearchFilters/NaicsFilter'

interface Props {
  onSearch: () => void
  onSaveAgencies: () => Promise<void>
  selectedAgencies: number[]
  count?: number
  isIdv?: boolean
  isIdvActually?: boolean
}

const SearchFilters = (props: Props) => {
  const dispatch = useDispatch()

  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector)
  const filterOptionsLoading = useSelector(filterOptionsLoadingSelector)
  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const agenciesLoaded = useSelector(agenciesLoadedSelector)
  const locationsLoaded = useSelector(locationsLoadedSelector)
  const dashboardLoading = useSelector(awardsDashboardLoadingSelector)
  
  useEffect(() => {
    if (props.isIdvActually !== props.isIdv) {
      setAwardSearchFilterValueBool(dispatch)('isIdv', !!props.isIdv)
    }

    // Get all selectable options if not downloaded
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
    if (!groupedCodesLoaded) {
      getNaicsCodesAction(dispatch)()
      getPscCodesAction(dispatch)()
    }
    if (!agenciesLoaded) {
      getAgenciesAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }
  }, [])

  return (
    <div className={styles['container']}>
      <Paper className={styles['card']}>
        {
          filterOptionsLoading ? (
            <Grid style={{margin: '20px 0'}} container justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Typography variant='h4'>Search and Filtering</Typography>
                {dashboardLoading && <CircularProgress size={24} />}
              </Grid>
              {props.count ? (
                <Typography variant='h5'>Results: {props.count >= 10000 ? '10000+' : props.count} matches</Typography>
              ) : (
                <Typography variant='h5'>Results:</Typography>
              )}
              <div style={{height: '8px'}} />
              <Paper elevation={0}>
                <Grid container>
                  <KeywordInput onSearch={props.onSearch} />
                </Grid>
              </Paper>
              <div style={{height: '16px'}} />
              <PscFilter />
              <div style={{height: '16px'}} />
              <NaicsFilter />
              <div style={{height: '16px'}} />
              <LocationFilter />
              <div style={{height: '16px'}} />
            </>
          )
        }
        {
          <Button 
            variant='contained'
            color='primary'
            onClick={props.onSaveAgencies} 
            className={styles['save-btn']}
            fullWidth
            disabled={!props.selectedAgencies.length}
          >
            Save selected Agencies
          </Button>
        }
      </Paper>
    </div>
  )
}

export default SearchFilters