import React from 'react'
import styles from 'components/v2/Wrapper/index.module.scss'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface BigHintProps {
  close?: () => void
}

export const BigHint = ({close}: BigHintProps) => {
  return (
    <Grid
      container
      style={{height: '100%'}}
      direction='column'
    >
      <Paper className={styles['paper']}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>PSC & NAICS Code Finder</Typography>
          <SvgIcon
            name='multiply'
            width={30}
            height={30}
            className={styles['clickable']}
            onClick={close}
          />
        </Grid>
        <Typography>Difficulty: <b>Low</b></Typography>
        <Typography>Time: <b>5 min</b></Typography>
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
          <Grid item md={6}>
            <SvgIcon name='film' width={250} height={250}/>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>Why NAICS & PSC codes are important</Typography>
            <Typography>
              NAICS and PSC codes are important for two reasons:
            </Typography>
            <ul>
              <li><b>They help you find opportunities:</b> The government releases a few thousand solicitations a
                day and
                each one has a NAICS and PSC code associated with it that you can search by.
              </li>
              <li><b>They tell you whether you’re a small business:</b> Whether you are a small business depends on
                the
                kind of work you do. For example, if you are an accounting firm and make $30M a year that makes you
                a pretty good sized accounting firm so the government doesn’t think you’re small anymore. On the
                other hand a small construction firm might spend $30M just on asphalt so a $30M construction company
                is still considered small.
              </li>
              <li>So you might be small for one NAICS code and large for another. To determine whether you are you
                have to look at the NAICS for each solicitation
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>What they are</Typography>
            <Typography gutterBottom>
              NAICS and PSC codes do fundamentally the same thing. They each classify the world’s products and
              services. So there is a NAICS and a PSC code for everything from Car tires to computer programming
              services
            </Typography>
            <Typography>
              <b>Why are there two systems:</b> Great question, industry came up with NAICS codes and the government
              came
              up with PSC codes, and while we don’t say this often we think the government got this one right:
            </Typography>
            <ul>
              <li><b>NAICS codes:</b> NAICS codes classify things based on the industry they fall into which is a
                slippery
                concept. For example if the contract is to provide legal services to the DOD HR Department does that
                contract get classified as Legal or as HR? (This is a trick question, the government will just use
                the “Other” NAICS code and avoid the decision entirely)
              </li>
              <li><b>PSC codes:</b> PSC codes classify things based on what’s being done. And importantly PSC codes
                that
                start with a number are for products, and PSC codes that start with a letter are for services.
              </li>
            </ul>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>How to find your</Typography>
            <Typography>
              Option 1 (~5 min):
            </Typography>
            <ul>
              <li>Put in a few keywords related to what you do (Left side of screen)</li>
              <li>We’ll look through a few thousand old government contracts and show you which codes are associated
                with your words (we call these Strongly Matching codes)
              </li>
              <li>Well also pull a list of codes that are similar to your Strong Matches</li>
              <li>Get rid of any codes that seem off base (this happens due to poor government data hygiene)</li>
              <li>Hit Save/Email Me My Codes and voila you’re done.</li>
            </ul>
            <Typography variant={'body2'} gutterBottom>
              Option 2 (~1 HR):
            </Typography>
            <Typography gutterBottom>Go to the full lists of ~1,200 NAICS codes and ~5,600 PSC codes and start
              hitting CTRL+F.
              Don’t do this, and don’t pay someone to do this, it’s insane.</Typography>
            <Typography gutterBottom>
              <b>NOTE on NIGP and SIC codes:</b> While NIGP and SIC codes are not used as frequently
              if you download/email yourself a report on the codes you’ve selected we include the associated NIGP
              and SIC codes
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export const SmallHint = () => {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{height: '100%'}}
      direction='column'
    >
      <Typography variant='h3' gutterBottom>Enter on or more keywords and hit</Typography>
      <Button
        color='primary'
        variant='contained'
      >
        Find codes
      </Button>
      <Typography
        variant='h3'
        style={{marginTop: '12px'}}
      >
        To get started
      </Typography>
    </Grid>
  )
}