import { companyListingReducer, ICompanyProfileListing } from './listing/reducers'
import { Record } from 'immutable'
import { companySearchReducer, ICompanySearchRecord } from './search/reducers'
import { ICompanyOptionReducer, rootCompanyOptionReducers } from './options/reducers'
import { combineReducers } from 'redux'

export interface ICompanyProfileRootReducer {
  listing: Record<ICompanyProfileListing>
  search: Record<ICompanySearchRecord>
  options: ICompanyOptionReducer
}

export const rootCompanyProfileReducer = combineReducers<ICompanyProfileRootReducer>({
  listing: companyListingReducer,
  search: companySearchReducer,
  options: rootCompanyOptionReducers
})
