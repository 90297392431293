import { AgencyGroupedResponseModel, AgencyResponseModel } from 'models/hp/agencies/agencies.model'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import { setCustomFiltersName, setOptionCount } from 'common/filter'
import { CustomersMapperFieldType } from 'models/hp/agencies/agencies.interface'

export const GET_AGENCIES = 'GET_AGENCIES'
export const GET_AGENCIES_SUCCESS = 'GET_AGENCIES_SUCCESS'
export const GET_AGENCIES_ERROR = 'GET_AGENCIES_ERROR'
export const GET_AGENCY_COUNT = 'GET_AGENCY_COUNT'

export const SET_CUSTOM_DEPARTMENT_NAMES = 'SET_CUSTOM_DEPARTMENT_NAMES'
export const SET_CUSTOM_SUBTIER_NAMES = 'SET_CUSTOM_SUBTIER_NAMES'

export const getAgenciesAction = (dispatch: Dispatch) => async (): Promise<void> => {
  try {
    dispatch({
      type: GET_AGENCIES
    })
    const response = await request<AgencyGroupedResponseModel>(RequestMethods.Get, Paths.Hp.Agencies.getAllGrouped, {
      schema: AgencyGroupedResponseModel
    })
    
    setCustomFiltersName(dispatch)(SET_CUSTOM_DEPARTMENT_NAMES , response.data.data, CustomersMapperFieldType.departmentSAM)
    setCustomFiltersName(dispatch)(SET_CUSTOM_SUBTIER_NAMES , response.data.data, CustomersMapperFieldType.subtierSAM)
 
    dispatch({
      type: GET_AGENCIES_SUCCESS,
      payload: response.data.data
    })
    setOptionCount(dispatch)(GET_AGENCY_COUNT, response.data.data, response.data.count)
  } catch (error) {
    dispatch({
      type: GET_AGENCIES_ERROR
    })
    throw error.response.data.errors
  }
}

export const searchAgenciesAction = async (search: string) => {
  try {
    const response = await request<AgencyGroupedResponseModel>(RequestMethods.Get, Paths.Hp.Agencies.getAllGrouped, {
      schema: AgencyGroupedResponseModel,
      params: { q: search }
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const searchAgenciesByDepartmentAction = async (search: string) => {
  try {
    const response = await request<AgencyResponseModel>(RequestMethods.Get, Paths.Hp.Agencies.getAllByDepartment, {
      schema: AgencyResponseModel,
      params: { q: search }
    })
    const arr = []
    for (const item of response.data.data) {
      arr.length <= 15 && arr.push(item)
      if (item.sub) {
        for (const sub of item.sub) {
          arr.length <= 15 && arr.push(sub)
        }
      }
    }
    return arr
  } catch (error) {
    throw error.response.data.errors
  }
}
