import { IAgencyResponseModel } from './agencies.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { FilterOption } from 'models/hp/search/search.model'

@jsonObject
export class AgencyModel {

  @jsonMember({ constructor: Number })
  id?: number = undefined

  public get text(): string {
    return this.departmentHP
  }

  @jsonMember({ constructor: String })
  departmentHP: string = ''

  @jsonArrayMember(FilterOption)
  sub: FilterOption[] = []
}

@jsonObject
export class AgencyResponseModel extends BaseResponseModel implements IAgencyResponseModel {
  @jsonArrayMember(AgencyModel)
  data: AgencyModel[] = []
}

@jsonObject
export class AgencyGroupedResponseModel extends BasePaginationModel {
  @jsonArrayMember(FilterOption)
  data: FilterOption[] = []
}

@jsonObject
export class MarketAgency {
  @jsonMember({ constructor: Number })
  id: number =  0

  @jsonMember({ constructor: String, name: 'departmentHP' })
  department: string =  ''

  @jsonMember({ constructor: String, name: 'subtierHP' })
  subTier: string =  ''

  @jsonMember({ constructor: Number })
  marketSize: number =  0
}

@jsonObject
export class MarketAgenciesResponseModel extends BaseResponseModel {
  @jsonArrayMember(MarketAgency)
  data: MarketAgency[] = []
}