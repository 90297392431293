import { IRootState } from '../../../reducers'
import { createSelector } from 'reselect'

const companyProfileListing = (state: IRootState) => state.companyProfile.listing

export const companyProfileListingSelector = createSelector(
  companyProfileListing,
  companyProfileListing => companyProfileListing.getIn(['listing', 'data'])
)

export const companyProfileFilterParamsSelector = createSelector(
  companyProfileListing,
  companyProfileListing => companyProfileListing.getIn(['listing', 'params'])
)

export const companyProfileListingLoadingSelector = createSelector(
  companyProfileListing,
  companyProfileListing => companyProfileListing.getIn(['listing', 'loading'])
)

export const companyProfileListingLoadedSelector = createSelector(
  companyProfileListing,
  companyProfileListing => companyProfileListing.getIn(['listing', 'loaded'])
)

export const companyProfileRankedListingSelector = createSelector(
  companyProfileListing,
  companyProfileListing => companyProfileListing.get('ranked')
)
