import React from 'react'
import { useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'

const getPscPrefix = (psc: string) => {
  if (/[a-zA-Z]/.test(psc.charAt(0))) {
    return psc.slice(0, 1)
  } else {
    return psc.slice(0, 2)
  }
}

export interface PscProps {
  pscCode?: string
  height?: string
  width?: string
  svgHeight?: number
  svgWidth?: number
}

export const previewProps: PscProps = {
  svgHeight: 50,
  svgWidth: 50,
  height: '80px',
  width: '80px'
}

const Avatar = ({pscCode, svgHeight = 70, svgWidth = 70, height = '120px', width = '120px'}: PscProps) => {
  const theme = useTheme()

  let prefix = pscCode ? getPscPrefix(pscCode) : 'Unlisted'

  return (
    <div
      style={{
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: '12px',
        backgroundColor: theme.palette.grey['100'],
        height,
        width
      }}
      className={styles['avatar']}
    >
      <SvgIcon name={prefix} width={svgWidth} height={svgHeight} color={theme.palette.grey['400']}/>
    </div>
  )
}

export default Avatar