import React, { useEffect, useState } from 'react'
import { Button, InputAdornment, TextField, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanySearchFilterKeyword } from 'store/hp/company-profiles/search/actions'
import styles from '../../index.module.scss'
import { companyProfileKeywordSelector } from 'store/hp/company-profiles/search/selectors'

interface Props {
  onSearch?: () => void
}

const CompanySearchBlock = ({onSearch}: Props) => {

  const filterKeyword = useSelector(companyProfileKeywordSelector)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [keyword, setKeyword] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const handleOnSearch = () => {
    setCompanySearchFilterKeyword(dispatch)(keyword)
    onSearch && onSearch()
  }

  const handleKeyboardChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleOnSearch()
    }
  }

  useEffect(() => {
    setKeyword(filterKeyword)
  }, [filterKeyword, setKeyword])

  return (
    <TextField
      onChange={handleChange}
      onKeyPress={handleKeyboardChange}
      value={keyword}
      variant='outlined'
      placeholder='Keyword'
      color='primary'
      fullWidth
      style={{borderRadius: '12px', border: 0}}
      InputProps={{
        classes: {root: styles['keyword-input']},
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon name='search' width={24} height={24}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <Button
              onClick={handleOnSearch}
              style={{backgroundColor: theme.palette.primary.contrastText}}
              variant='text'
              color='primary'
              disableRipple
            >
              Search
            </Button>
          </InputAdornment>
        )
      }}
    />
  )
}

export default CompanySearchBlock
