import { createTheme } from '@material-ui/core'

const themeV2 = createTheme({
  palette: {
    primary: {
      main: '#3664DA',
      light: '#7494E6',
      dark: '#1C3F99',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#00819F',
      dark: '#00667e',
      light: '#c5d6da',
      contrastText: '#FFFFFF'
    },
    grey: {
      '50': '#FCFCFC',
      '100': '#F7F7FC',
      '200': '#EFF0F6',
      '300': '#D9DBE9',
      '400': '#A0A3BD',
      '500': '#6E7191',
      '600': '#4E4B66',
      '700': '#14142B',
      '800': '#212139'
    },
    action: {
      disabledBackground: '#C9CFDE',
      active: '#F2F6FF',
      selected: '#899FD3'
    },
    error: {
      main: '#B91226',
      dark: '#75000E',
      light: '#FFEBEE',
      contrastText: '#FFFFFF'
    },
    // Get from material
    warning: {
      main: '#F28826',
      dark: '#AB5200',
      light: '#FFB169',
      contrastText: '#FFFFFF'
    },
    // Get from material
    info: {
      main: '#3664DA',
      light: '#7494E6',
      dark: '#1C3F99',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#00BA88',
      dark: '#00966D',
      light: '#34EAB9',
      contrastText: '#FFFFFF'
    },
    common: {
      white: '#FFFFFF',
      black: '#000000'
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      'Poppins',
      'sans-serif'
    ].join(', ')
  }
})

themeV2.overrides = {
  MuiTypography: {
    h2: {
      fontFamily: 'Poppins',
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',
      color: '#14142B'
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',
      color: themeV2.palette.primary.dark
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '40px'
    },
    h5: {
      fontSize: '16px',
      fontWeight: 600
    }
  },
  MuiLink: {
    root: {
      fontSize: '16px',
      fontWeight: 600,
      color: themeV2.palette.primary.dark
    }
  },
  MuiButton: {
    root: {
      fontWeight: 600,
      textTransform: 'none',
      fontSize: '14px',
      lineHeight: '24px',
      fontFamily: 'Poppins'
    },
    outlined: {
      borderRadius: '6px'
    },
    contained: {
      borderRadius: '6px'
    },
    text: {
      padding: '11px'
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: '6px',
      background: '#FCFCFC',
      border: '1px solid #D9DBE9'
  
    },
    elevation1: {
      boxShadow: 'none'
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '12px',
      boxShadow: 'none'
    }
  },
  MuiTextField: {
    root: {
      border: 'none',
      borderRadius: '12px',
      boxShadow: 'none'
    }
  },
  MuiFormControl: {
    root: {
      border: 'none'
    }
  }
}

export default themeV2
