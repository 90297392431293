import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import {
  IRecoverPasswordForm,
  RECOVER_PASSWORD_FORM_INIT_VALUE,
  RECOVER_PASSWORD_FORM_VALIDATION_SCHEMA
} from './components/Form'
import FormRenderer from './components/FormRenderer'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { userRecoverPasswordAction } from 'store/common/user/actions'
import { useHistory } from 'react-router-dom'

const RecoverPassword = () => {

  const { show } = useNotification()
  const history = useHistory()

  const [ isRequested, setIsRequested ] = useState<boolean>(false)

  const onSubmit = async (values: IRecoverPasswordForm) => {
    setIsRequested(prev => !prev)
    try {
      const response = await userRecoverPasswordAction(values.email)
      setIsRequested(prev => !prev)
      response.message && show(response.message, NotificationTypeList.Info)
      history.push({ pathname: `/auth/recover/check-code/${values.email}`, search: history.location.search })
    } catch (error) {
      setIsRequested(prev => !prev)
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
    }
  }

  return (
    <Formik
      initialErrors={RECOVER_PASSWORD_FORM_INIT_VALUE}
      initialValues={RECOVER_PASSWORD_FORM_INIT_VALUE}
      validationSchema={RECOVER_PASSWORD_FORM_VALIDATION_SCHEMA}
      onSubmit={onSubmit}>
      {(formProps: FormikProps<IRecoverPasswordForm>) => (
        <FormRenderer {...formProps} isRequested={isRequested}/>
      )}
    </Formik>
  )
}

export default RecoverPassword