import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import {
  IPipelineCounter,
  IPipelineDashboard, IPipelineDashboardProjections, IPipelineDashboardValues, IPipelineIndicators
} from 'models/hp/pipeline-dashboard/index.interface'


@jsonObject
export class PipelineDashboardValues implements IPipelineDashboardValues{
  @jsonMember({constructor: String})
  type: string = ''

  @jsonMember({constructor: Number})
  value: number = 0
}

@jsonObject
export class PipelineIndicators implements IPipelineIndicators {
  @jsonArrayMember(PipelineDashboardValues)
  tasks: PipelineDashboardValues[] = []

  @jsonArrayMember(PipelineDashboardValues)
  rfps: PipelineDashboardValues[] = []
}

@jsonObject
export class PipelineCounter implements IPipelineCounter {
  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: Number })
  count: number = 0
}

@jsonObject
export class PipelineDashboardProjections implements IPipelineDashboardProjections {
  @jsonMember({constructor: Number})
  thisYear: number = 0

  @jsonMember({constructor: Number})
  nextYear: number = 0

  @jsonMember({constructor: Number})
  in2Years: number = 0
}

@jsonObject
export class PipelineDashboard implements IPipelineDashboard{
  @jsonMember({ constructor: PipelineIndicators })
  indicators: PipelineIndicators = new PipelineIndicators()

  @jsonMember({ constructor: PipelineDashboardProjections })
  projected: PipelineDashboardProjections = new PipelineDashboardProjections()
}

@jsonObject
export class PipelineDashboardResponseModel extends BaseResponseModel {
  @jsonMember(PipelineDashboard)
  data: PipelineDashboard | undefined
}

@jsonObject
export class PipelineDashboardCountResponseModel extends BaseResponseModel {
  @jsonArrayMember(PipelineCounter)
  data: PipelineCounter[] = []
}

