import React from 'react'
import { FormikProps } from 'formik'
import { IRecoverPasswordForm } from './Form'
import { Grid, Hidden, Typography } from '@material-ui/core'
import ButtonWithLoader from 'components/ButtonWithLoader'
import styles from '../../../index.module.scss'
import SignInTextLink from 'components/SignInTextLink'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import TextField from 'components/v2/TextField'

interface IFormRendererProps extends FormikProps<IRecoverPasswordForm> {
  isRequested: boolean
}

const FormRenderer = ({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  isRequested
}: IFormRendererProps) => {

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={styles['form-block']}>
        <Hidden smDown>
          <Grid item md={6}>
            <LeftBlock/>
          </Grid>
        </Hidden>
        <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
          <div className={styles['form-block-right-inner']}>
            <div className={styles['logo']}>
              <img src={LogoWithText} alt='logo'/>
            </div>
            <Grid container justifyContent='center' className={styles['form-subtitle']}>
              <Typography variant='h4'>Recover Password</Typography>
            </Grid>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('email')}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && !!errors.email && errors.email}
                value={values.email}
                onChange={handleChange('email')}
                className={styles['field']}
                label='Email'
                fullWidth
                variant='filled'
              />
            </div>
            <ButtonWithLoader
              className={styles['submit']}
              isRequested={isRequested}
              type='submit'
              fullWidth
              variant='contained'
              color='primary'>
              Recover
            </ButtonWithLoader>
            <SignInTextLink/>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer