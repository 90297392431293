import { ICountryModel } from './country.interface'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class CountryModel implements ICountryModel {
  @jsonMember({ constructor: Number })
  id?: number = undefined

  @jsonMember({ constructor: String })
  name: string = ''
}