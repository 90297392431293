import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const awards = (state: IRootState) => state.awards

export const selectedPscOptions = createSelector(
  awards,
  awards => awards.search.get('pscCodes')
)

export const selectedNaicsOptions = createSelector(
  awards,
  awards => awards.search.get('naicsCodes')
)

export const selectedAgenciesOptions = createSelector(
  awards,
  awards => awards.search.get('customers')
)

export const selectedLocationsOptions = createSelector(
  awards,
  awards => awards.search.get('locations')
)

export const selectedSetAsideOptions = createSelector(
  awards,
  awards => awards.search.get('setAsides')
)

export const selectedKeywordsSelector = createSelector(
  awards,
  awards => awards.search.get('keywords')
)

export const selectedValueOptions = createSelector(
  awards,
  awards => awards.search.get('values')
)

export const selectedTypeOptions = createSelector(
  awards,
  awards => awards.search.get('types')
)

export const selectedDateOptions = createSelector(
  awards,
  awards => awards.search.get('dates')
)

export const selectedDunsOptions = createSelector(
  awards,
  awards => awards.search.get('duns')
)

export const selectedStartDate = createSelector(
  awards,
  awards => awards.search.get('startDate')
)

export const selectedEndDate = createSelector(
  awards,
  awards => awards.search.get('endDate')
)

export const isIdv = createSelector(
  awards,
  awards => awards.search.get('isIdv')
)

export const awardsFiltersSelector = createSelector(
  awards,
  (awards) => awards.search.toJSON()
)

const awardsSearch = createSelector(
  awards,
  (awards) => awards.search
)

export const awardsFiltersChangedSelector = createSelector(
  awardsSearch,
  (search) => (
    search.get('customers').length +
    search.get('setAsides').length +
    search.get('pscCodes').length +
    search.get('naicsCodes').length +
    search.get('locations').length +
    (Number(search.get('types')[0]) + 10 || 5) +
    search.get('duns').length +
    search.get('values').length +
    (Number(search.get('dates')[0]) + 10 || 5) +
    (new Date(search.get('startDate') || '').getTime() || 0) +
    (new Date(search.get('endDate') || '').getTime() || 0)
  )
)

