import React from 'react'
import styles from '../index.module.scss'
import { Checkbox, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { customerFinderContactsSelector, customerFinderDashboardSelector } from 'store/hp/education/selector'
import { getCustomerFinderContacts } from 'store/hp/education/action'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import SubAgencyConnectionsItem from './SubAgencyConnectionsItem'


export enum SubAgencySpendingEnum {
  awards = 'awards',
  connections = 'connections'
}

interface SubAgencySpendingProps {
  selectedAgencies: number[]
  handleSetAgencies: (agency: number, state: boolean) => void
}

const SubAgencySpending = ({selectedAgencies, handleSetAgencies}: SubAgencySpendingProps) => {
  const dispatch = useDispatch()
  const {
    data: customerFinderDashboarData,
  } = useSelector(customerFinderDashboardSelector)
  const notification = useNotification()
  const {loading: contactsLoading, data: contactsData} = useSelector(customerFinderContactsSelector)

  const getContacts = (agencyId: number) => {
    getCustomerFinderContacts(dispatch)(agencyId)
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }
  
  return (
    <div className={styles['subAgency-spending-wrapper']}>
      <Paper style={{padding: '12px'}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h4'>Sub Agendy spending</Typography>
          <Typography className={styles['relationships-title']} variant='h4'>Relationships</Typography>
        </Grid>
        <Grid container alignItems='center' spacing={2} className={styles['relationship-map-head']}>
          <Grid item>
            <Grid container alignItems='center'>
              <div className={styles['progress-key-head']} />
              <Typography variant='subtitle1'>New vendor friendly spending</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems='center'>
              <div className={styles['progress-other-head']} />
              <Typography variant='subtitle1'>Other spending</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container wrap='wrap' className={styles['relationship-map-list']}>
          {
            customerFinderDashboarData?.agencies && customerFinderDashboarData?.agencies.length && customerFinderDashboarData.agencies.map((el, index) => (
              <Grid container alignItems='center' spacing={2} className={styles['relationship-map-item']} key={index + el.agency}>
                <Grid item md={1}> 
                  <Checkbox
                    onClick={() => handleSetAgencies(el.customerId!, !selectedAgencies.includes(el.customerId!))}
                    checked={selectedAgencies.includes(el.customerId!)}
                    disabled={!el.customerId}
                    color='primary'
                    style={{padding: '2px'}}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant='subtitle2' className={styles['relationship-map-agency']} onClick={() => getContacts(el.id)}>{el.agency}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant='subtitle2' className={styles['relationship-map-sub-agency']} onClick={() => getContacts(el.id)}>{el.subagency}</Typography>
                </Grid>
                <Grid item md={6}>
                  <Grid container alignItems='center' spacing={2}>     
                    <Paper style={{width: `${(100 * el.spending.friendly) / customerFinderDashboarData.total}%`}} className={styles['progress-item-keys']}>
                      <Typography variant='subtitle1' className={styles['progress-item-value']}>{el.spending.friendly}</Typography>
                    </Paper>    
                    <Paper style={{width: `${(100 * el.spending.other) / customerFinderDashboarData.total}%`}} className={styles['progress-item-other']}>
                      <Typography variant='subtitle1' className={styles['progress-item-value']}>{el.spending.other}</Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
                    <Grid item md={6}>
                      {el.positions.key}
                    </Grid>
                    <Grid item md={6}>
                      {el.positions.other}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Paper>
      <Grid container className={styles['connection-details']} spacing={2}>
        {
          contactsLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )
        }
        {
          !contactsLoading && (
            <>
              <SubAgencyConnectionsItem connections={contactsData?.connections || null} type={SubAgencySpendingEnum.connections} />
              <SubAgencyConnectionsItem awards={contactsData?.awards} type={SubAgencySpendingEnum.awards} />
            </>
          )
        }
      </Grid>
    </div>
  )
}

export default SubAgencySpending