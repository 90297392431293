import { Dispatch } from 'redux'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { DashboardSimpleResponseModel } from 'models/hp/awards/awards.model'

export const AWARD_DASHBOARD_SIMPLE = 'AWARD_DASHBOARD_SIMPLE'
export const AWARD_DASHBOARD_SIMPLE_ERROR = 'AWARD_DASHBOARD_SIMPLE_ERROR'
export const AWARD_DASHBOARD_SIMPLE_SUCCESS = 'AWARD_DASHBOARD_SIMPLE_SUCCESS'
export const AWARD_DASHBOARD_SIMPLE_SET_FILTER = 'AWARD_DASHBOARD_SIMPLE_SET_FILTER'

export const getDashboardSimple = (dispatch: Dispatch) => async (filters: IAwardSearchRecord): Promise<void> => {
  dispatch({
    type: AWARD_DASHBOARD_SIMPLE
  })
  try {
    const res = await request<DashboardSimpleResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getDashboardSimple, {
      schema: DashboardSimpleResponseModel,
      body: filters || {}
    })
    dispatch({
      type: AWARD_DASHBOARD_SIMPLE_SUCCESS,
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({
      type: AWARD_DASHBOARD_SIMPLE_ERROR,
    })
    throw e
  }
}

export const setDashboardSimpleFilter = (dispatch: Dispatch) => (key: string, value: number) => {
  dispatch({
    type: AWARD_DASHBOARD_SIMPLE_SET_FILTER,
    payload: { key, value }
  })
}