import React from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import Filters from './Filters'
import Listing from './Listing'

const ExpiringContracts = () => {
  return (
    <div className={styles['container']}>
      <Grid container spacing={2} className={styles['height-block']}>
        <Grid item md={4} className={styles['filter-list']}>
          <Filters />
        </Grid>
        <Grid item md={8} className={styles['height-block']}>
          <Listing />
        </Grid>
      </Grid>
    </div>
  )
}

export default ExpiringContracts