import React from 'react'
import styles from  './index.module.scss'
import { Skeleton } from '@material-ui/lab'
import { Paper } from '@material-ui/core'

const Skeletons = () => {
  const skeletonData = Array.from({length: 9}, (_, index) => index + 1)
  return (
    <div className={styles['skeletons']}>
      <div className={styles['skeletons-list']}>
        {
          skeletonData.map((_, index) => (
            <Paper key={index} className={styles['skeletons-list-item']}>
              <div className={styles['skeletons-list-item-main']}>
                <Skeleton  variant="text" animation='wave' height={20} width='100%' />
                <Skeleton  variant="text" animation='wave' height={15} width='50%' />
              </div>
              <div className={styles['skeletons-list-item-additional']}>
                <Skeleton  variant="text" animation='wave' height={20} width='100%' />
                <Skeleton  variant="text" animation='wave' height={15} width='100%' />
              </div>
              <div className={styles['skeletons-list-item-additional']}>
                <Skeleton  variant="text" animation='wave' height={20} width='100%' />
                <Skeleton  variant="text" animation='wave' height={15} width='100%' />
              </div>
              <div className={styles['skeletons-list-item-additional']}>
                <Skeleton  variant="text" animation='wave' height={20} width='100%' />
                <Skeleton  variant="text" animation='wave' height={15} width='100%' />
              </div>
            </Paper>
          ))
        }
        
      </div>
    </div>
  )
}

export default Skeletons