import { Grid, Hidden, Typography } from '@material-ui/core'
import ButtonWithLoader from 'components/ButtonWithLoader'
import React from 'react'
import { FormikProps } from 'formik'
import { IVerifyPasswordForm } from './Form'
import SignInTextLink from 'components/SignInTextLink'
import styles from '../../../index.module.scss'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import TextField from 'components/v2/TextField'

interface IFormRendererProps extends FormikProps<IVerifyPasswordForm> {
  isRequested: boolean
}

const FormRenderer = ({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  isRequested
}: IFormRendererProps) => {

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={styles['form-block']}>
        <Hidden smDown>
          <Grid item md={6}>
            <LeftBlock/>
          </Grid>
        </Hidden>
        <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
          <div className={styles['form-block-right-inner']}>
            <div className={styles['logo']}>
              <img src={LogoWithText} alt='logo'/>
            </div>
            <Grid container justifyContent='center' className={styles['form-subtitle']}>
              <Typography variant='h4'>Recover Password</Typography>
              <Typography className={styles['description']} variant='body1'>
                Please enter the code sent to your email to reset your password
              </Typography>
            </Grid>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('verificationCode')}
                error={!!touched.verificationCode && !!errors.verificationCode}
                helperText={!!touched.verificationCode && !!errors.verificationCode && errors.verificationCode}
                value={values.verificationCode}
                onChange={handleChange('verificationCode')}
                className={styles['field']}
                label='Verification Code'
                fullWidth
                variant='filled'/>
            </div>
            <ButtonWithLoader
              className={styles['submit']}
              isRequested={isRequested}
              type='submit'
              fullWidth
              variant='contained'
              color='primary'>
              Recover
            </ButtonWithLoader>
            <Typography className={styles['helper-text']} variant='body1'>
              Please check your spam folder if you do not receive a confirmation email
            </Typography>
            <SignInTextLink/>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer