import React, { useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import SvgIcon from 'components/SvgIcon'
import { useTheme } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import { pagesData } from '../../pagesData'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'

const Steps = () => {
  const theme = useTheme()
  const history = useHistory()
  const user = useSelector(userSelector) as IUserBaseModel
  const splitLocation = history.location.pathname.split('/')
  const currentPageName = splitLocation[2]
  const activePageIndex = pagesData().findIndex((el) => el.path === currentPageName)

  useEffect(() => {
    if(!user && currentPageName === 'qualifying') {
      handleStep('overview')
    }
  }, [])

  const handleStep = (path: string, isProtected?: boolean) => {
    const newPath = splitLocation
    newPath.splice(2, 1, path)
    if((isProtected && user) || !isProtected) {
      history.push(newPath.join('/'))
    } 
  }

  return (
    <div className={styles['steps-wrapper']}>
      {
        pagesData().map((page, index) => {
          return (
            <div key={page.id} className={classNames(styles['steps-item'], {
              [styles['steps-item-active']]: currentPageName === page.path,
              [styles['steps-item-success']]: activePageIndex > index,
              [styles['steps-item-disabled']]: page.protected && !user
            })} onClick={() => handleStep(page.path, page.protected)}>
              <SvgIcon name={page.icon} color={activePageIndex === index ?
                theme.palette.primary.main 
                : page.protected && !user ? 
                  theme.palette.grey['500']
                  : theme.palette.grey['800']
              } />
              <span className={styles['steps-item-title']}>{page.title}</span>
              {page.protected && !user ? (
                <div className={styles['steps-item-login-link']}>
                  <Link to='/auth/sign-in'>Please login</Link>
                </div>
              ) : null}
            </div>
          )
        })
      }
    </div>
  )
}

export default Steps