import { IBaseResponseModel } from 'models/base/base.interfaces'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'

export enum PipelineStatuses {
  pipeline__1 = 'pipeline__1',
  pipeline__2 = 'pipeline__2',
  pipeline__3 = 'pipeline__3',
  pipeline__4 = 'pipeline__4'
}

export enum PipelineQuestionTypes {
  open = 'open',
  upcoming = 'upcoming',
  distant = 'distant',
  distant1 = 'distant__1',
  distant2 = 'distant__2',
  distant3 = 'distant__3'
}

export enum PipelineTaskStage {
  toDo = 'to-do',
  inProgress = 'in-progress',
  signOff = 'sign-off',
  complete = 'complete'
}

export const pipelineStageMapper: {[key: number]: PipelineTaskStage} = {
  1: PipelineTaskStage.toDo,
  2: PipelineTaskStage.inProgress,
  3: PipelineTaskStage.signOff,
  4: PipelineTaskStage.complete
}

export const pipelineStageParser: {[key in PipelineTaskStage]: number} = {
  [PipelineTaskStage.toDo] : 1,
  [PipelineTaskStage.inProgress] : 2,
  [PipelineTaskStage.signOff] : 3,
  [PipelineTaskStage.complete] : 4,
}

export interface PipelineColumnInfo {
  id: string
  title: string
  contracts: RfpTinderModel[]
}

export interface ManagePipelineRfpDTO {
  assignedUserId?: number
  step?: number | string
  snooze?: Date
}

export interface PipelineColumns {
  [key: string]: PipelineColumnInfo

}

export interface PipelineBoardData {
  columns: PipelineColumns,
  columnsOrder: string[]
}

export interface IPipelineDashboardValues {
  type: string,
  value: number
}

export interface IPipelineDashboardCalculatorPoints {
  [key: string]: {
    value: string
    color: string
  }
}

export interface IPiplineTimelineCalc {
  pWin: number | null
  estimatedValue: number | null
}

export interface IPiplineTimelineDate {
  id: number
  start: Date
  end: Date
}

export interface IPiplineTimelineAssinedUser {
  id: number
  email: string
  firstName: string
  lastName: string
  role: string
  status: string
}

export interface IPiplineTimeline {
  id: string
  title: string
  department: string
  subTier: string
  solicitationNumber: string
  type: string
  responseDeadLine: Date
  postedDate: Date
  calc: IPiplineTimelineCalc
  dates: IPiplineTimelineDate[]
  assignedUser: IPiplineTimelineAssinedUser
}

export interface IPiplineTimelineResponseModel extends IBaseResponseModel {
  count: number
  data: IPiplineTimeline[]
}

export type PipelineTimelineOrderType = 'ASC' | 'DESC'

export interface IPiplineHealthItem {
  id: number,
  default: number,
  value: number,
  percentage: number
}

export interface IPiplineHealthResponseModel extends IBaseResponseModel {
  data: IPiplineHealthItem[]
}

export interface IPiplineRevenueItem {
  year: string,
  value: number
}

export interface IPiplineRevenueResponseModel extends IBaseResponseModel {
  data: IPiplineRevenueItem[]
}

export interface IPiplinePendingAwardPipelineAnalysis {
  id: string
  outcome: string
  description: string[]
}

export interface IPiplinePendingAward {
  id: string
  solicitationNumber: string
  title: string
  type: string
  typeOfSetAside: string
  deadlineCalculated: boolean
  classificationCode: string
  pipelineAnalysis: IPiplinePendingAwardPipelineAnalysis | null
  awardDate: string
}

export interface IPiplinePendingAwardsResponseModel extends IBaseResponseModel {
  count: number
  data: IPiplinePendingAward[]
}

export interface IPiplinePendingAwardsBody {
  outcome: string
  description: string[]
}

export interface IPipelineCardUser {
  id: number
  email: string
  firstName: string
  lastName: string
}

export interface IPipelineCard<T = number> {
  id: string
  type: string
  stage: T,
  title: string,
  section: string,
  updatedAt: Date,
  dueDate: Date
  user: IPipelineCardUser
}

export interface IPiplineCardsResponseModel extends IBaseResponseModel {
  data: IPipelineCard[]
}