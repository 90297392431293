import React from 'react'
import styles from '../index.module.scss'
import { FormikProps } from 'formik'
import { IChangePasswordForm } from './Form'
import { FormControl, Button } from '@material-ui/core'
import TextField from 'components/v2/TextField'
import ButtonWithLoader from 'components/ButtonWithLoader'

interface Props extends FormikProps<IChangePasswordForm> {
  isRequested: boolean
  cancelUpdate: () => void
}

const FormRenderer = ({ values, handleSubmit, handleChange, handleBlur, isValid, touched, errors, isRequested, cancelUpdate }: Props) => {
  return (
    <form onSubmit={handleSubmit} className={styles['form']}>
      <FormControl className={styles['input-control']}>
        <TextField
          label='Current password'
          type='password'
          variant='filled'
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
          error={!!touched.password && !!errors.password}
          helperText={errors.password}
        />
      </FormControl>
      <FormControl className={styles['input-control']}>
        <TextField
          label='New password'
          type='password'
          variant='filled'
          onChange={handleChange('newPassword')}
          onBlur={handleBlur('newPassword')}
          value={values.newPassword}
          error={!!touched.newPassword && !!errors.newPassword}
          helperText={errors.newPassword}
        />
      </FormControl>
      <ButtonWithLoader
        isRequested={isRequested}
        type='submit'
        disabled={!isValid}
        variant='contained'
        color='primary'
      >
        Update
      </ButtonWithLoader>
      <Button
        type='submit'
        variant='outlined'
        color='primary'
        onClick={cancelUpdate}
        style={{marginLeft: '4px'}}
      >
        Cancel
      </Button>
    </form>
  )
}

export default FormRenderer
