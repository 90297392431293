import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { IBaseKeywordModel, IKeywordResponseModel, IKeywordOptionWithGroup } from './keyword.interface'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class BaseKeywordModel implements IBaseKeywordModel {
  @jsonMember({ constructor: Number })
  id: number | undefined = undefined

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Number })
  searchId: number | undefined = undefined
}

@jsonObject
export class KeywordResponseModel extends BaseResponseModel implements IKeywordResponseModel {
  @jsonArrayMember(BaseKeywordModel)
  data: BaseKeywordModel[] = []
}

@jsonObject
export class KeywordOptionWithGroupModel implements IKeywordOptionWithGroup {
  @jsonMember({ constructor: String })
  keyword: string = ''

  @jsonMember({ constructor: String })
  groupName: string = ''

  @jsonMember({ constructor: String })
  inputValue: string = ''
}
