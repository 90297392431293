import { Dispatch } from 'redux'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { EDUCATION_SUBTITLE } from 'utils/constants/education'
import { setFilterOptions, setSearchFilterValues } from 'store/hp/search/actions'
import { IFilterOption } from 'models/hp/search/search.interface'
import Paths from 'utils/paths'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import { ContactsListByIdResponseModel, ContactsListResponseModel, ContractsChartInnerResponseModel, ContractsChartResponseModel, CustomerFinderContactsResponseModel, PartnersFinderByIdResponseModel, PartnersFinderResponseModel } from 'models/hp/education/education.model'
import { BaseResponseModel } from 'models/base/base.model'
import { IAwardSearchRecord } from '../awards/search/reducer'

export const SET_CURRENT_PHASE_CLASS = 'SET_CURRENT_PHASE_CLASS'
export const SET_PHASE_PROGRESS_VALUE_BOOL = 'SET_PHASE_PROGRESS_BOOL'

export const GET_CONTACTS_CHART = 'GET_CONTACTS_CHART'
export const GET_CONTACTS_CHART_SUCCESS = 'GET_CONTACTS_CHART_SUCCESS'
export const GET_CONTACTS_CHART_ERROR = 'GET_CONTACTS_CHART_ERROR'

export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR'

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS'

export const GET_CUSTOMER_FINDER_DASHBOARD = 'GET_CUSTOMER_FINDER_DASHBOARD'
export const GET_CUSTOMER_FINDER_DASHBOARD_SUCCESS = 'GET_CUSTOMER_FINDER_DASHBOARD_SUCCESS'
export const GET_CUSTOMER_FINDER_DASHBOARD_ERROR = 'GET_CUSTOMER_FINDER_DASHBOARD_ERROR'

export const GET_CUSTOMER_FINDER_CONTACTS = 'GET_CUSTOMER_FINDER_CONTACTS'
export const GET_CUSTOMER_FINDER_CONTACTS_SUCCESS = 'GET_CUSTOMER_FINDER_CONTACTS_SUCCESS'
export const GET_CUSTOMER_FINDER_CONTACTS_ERROR = 'GET_CUSTOMER_FINDER_CONTACTS_ERROR'

export const CLEAR_CUSTOMER_FINDER_CONTACTS = 'CLEAR_CUSTOMER_FINDER_CONTACTS'

export const GET_PARTNERS_FINDER_DASHBOARD = 'GET_PARTNERS_FINDER_DASHBOARD'
export const GET_PARTNERS_FINDER_DASHBOARD_SUCCESS = 'GET_PARTNERS_FINDER_DASHBOARD_SUCCESS'
export const GET_PARTNERS_FINDER_DASHBOARD_ERROR = 'GET_PARTNERS_FINDER_DASHBOARD_ERROR'

export const GET_PARTNERS_FINDER_CONTACTS = 'GET_PARTNERS_FINDER_CONTACTS'
export const GET_PARTNERS_FINDER_CONTACTS_SUCCESS = 'GET_PARTNERS_FINDER_CONTACTS_SUCCESS'
export const GET_PARTNERS_FINDER_CONTACTS_ERROR = 'GET_PARTNERS_FINDER_CONTACTS_ERROR'

export const CLEAR_PARTNERS_FINDER_CONTACTS = 'CLEAR_PARTNERS_FINDER_CONTACTS'

export const GET_CONTACTS_BY_ID = 'GET_CONTACTS_BY_ID'
export const GET_CONTACTS_BY_ID_SUCCESS = 'GET_CONTACTS_BY_ID_SUCCESS'
export const GET_CONTACTS_BY_ID_ERROR = 'GET_CONTACTS_BY_ID_ERROR'

export const GET_PARTNERS_FINDER_CONTACTS_BY_ID = 'GET_PARTNERS_FINDER_CONTACTS_BY_ID'
export const GET_PARTNERS_FINDER_CONTACTS_BY_ID_SUCCESS = 'GET_PARTNERS_FINDER_CONTACTS_BY_ID_SUCCESS'
export const GET_PARTNERS_FINDER_CONTACTS_BY_ID_ERROR = 'GET_PARTNERS_FINDER_CONTACTS_BY_ID'

export const GET_PARTNERS_FINDER_DASHBOARD_BY_ID = 'GET_PARTNERS_FINDER_DASHBOARD_BY_ID'
export const GET_PARTNERS_FINDER_DASHBOARD_BY_ID_SUCCESS = 'GET_PARTNERS_FINDER_DASHBOARD_BY_ID_SUCCESS'
export const GET_PARTNERS_FINDER_DASHBOARD_BY_ID_ERROR = 'GET_PARTNERS_FINDER_DASHBOARD_BY_ID_ERROR'

export const setCurrentEducationPhaseClass = (dispatch: Dispatch) => (phaseClass: IEducationPhaseClass): void => {
  dispatch({
    type: SET_CURRENT_PHASE_CLASS,
    payload: phaseClass
  })
}

export const setPhaseProgressValue = (dispatch: Dispatch) => (key: string, value: any) => {
  dispatch({
    type: SET_PHASE_PROGRESS_VALUE_BOOL,
    payload: {
      key: key,
      value: value
    }
  })
}

export const redirectAccordingEducationPhase = (dispatch: Dispatch) => (history: any, selectedPhase: IEducationPhaseClass, projectTypeOptions: IFilterOption[]): void => {
  setSearchFilterValues(dispatch)('projectTypeIds', [])

  switch (selectedPhase.name) {
    case EDUCATION_SUBTITLE.IDENTIFY_YOUR_CODES:
      return history.push('/user/market-research/tools/codes')

    case EDUCATION_SUBTITLE.IDENTIFY_YOUR_KEYWORDS:
      return history.push('/user/market-research/tools/keywords')

    case EDUCATION_SUBTITLE.PROMISING_PARTNERS:
      return history.push('/user/market-research/tools/similar-company')

    case EDUCATION_SUBTITLE.CUSTOMERS_ANALYSIS:
      return history.push('/awards')

    case EDUCATION_SUBTITLE.NEAR_TERM_OPPORTUNITIES:
      setFilterOptions(dispatch)({projectTypes: ['Open opportunities']}, projectTypeOptions)
      return history.push('/contracts')

    case EDUCATION_SUBTITLE.MID_TERM_OPPORTUNITIES:
      setFilterOptions(dispatch)({projectTypes: ['Upcoming opportunities']}, projectTypeOptions)
      return history.push('/contracts')

    case EDUCATION_SUBTITLE.LONG_TERM_OPPORTUNITIES:
      setFilterOptions(dispatch)({projectTypes: ['Distant opportunities']}, projectTypeOptions)
      return history.push('/contracts')

    default:
      return history.push('/education')
  }
}

export const uploadFile = async (formData: any): Promise<void> =>  {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.EducationWorkplan.upload, {
    body: formData
  })
}

export const getContactsChart = async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_CONTACTS_CHART
    })
    const response = await request<ContractsChartResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getContactsChart, {
      schema: ContractsChartResponseModel
    })
    dispatch({
      type: GET_CONTACTS_CHART_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_CONTACTS_CHART_ERROR
    })
    throw e
  }
}

export const getContactsList = (dispatch: Dispatch) => async (agencyId: string) => {
  try {
    dispatch({
      type: GET_CONTACTS
    })
    const response = await request<ContactsListResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getContactsList(agencyId), {
      schema: ContactsListResponseModel,
    })
    dispatch({
      type: GET_CONTACTS_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_CONTACTS_ERROR
    })
    throw e
  }
}

export const getContactsById = (dispatch: Dispatch) => async (agencyId: number, positionId: string, offset: number) => {
  try {
    dispatch({
      type: GET_CONTACTS_BY_ID
    })
    const response = await request<ContactsListByIdResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getContactsById(agencyId, positionId, offset), {
      schema: ContactsListByIdResponseModel,
    })
    dispatch({
      type: GET_CONTACTS_BY_ID_SUCCESS,
      payload: {...response.data.data, id: positionId}
    })
  } catch(e) {
    dispatch({
      type: GET_CONTACTS_BY_ID_ERROR
    })
    throw e
  }
}

export const clearContactsList = (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_CONTACTS
  })
}

export const getCustomerFinderDashboard = (dispatch: Dispatch) => async(filters: IAwardSearchRecord) => {
  try {
    dispatch({
      type: GET_CUSTOMER_FINDER_DASHBOARD
    })
    const response = await request<ContractsChartInnerResponseModel>(RequestMethods.Post, Paths.Hp.EducationWorkplan.getCustomerFinderDashboard, {
      schema: ContractsChartInnerResponseModel,
      body: filters
    })
    dispatch({
      type: GET_CUSTOMER_FINDER_DASHBOARD_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_CUSTOMER_FINDER_DASHBOARD_ERROR
    })
    throw e
  }
}

export const getCustomerFinderContacts = (dispatch: Dispatch) => async (agencyId: number) => {
  try {
    dispatch({
      type: GET_CUSTOMER_FINDER_CONTACTS
    })
    const response = await request<CustomerFinderContactsResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getCustomerFinderContacts(agencyId), {
      schema: CustomerFinderContactsResponseModel,
    })
    dispatch({
      type: GET_CUSTOMER_FINDER_CONTACTS_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_CUSTOMER_FINDER_CONTACTS_ERROR
    })
    throw e
  }
}

export const clearCustomerFinderContacts = (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_CUSTOMER_FINDER_CONTACTS
  })
}

export const getPartnersFinderDashboard = (dispatch: Dispatch) => async(filters: IAwardSearchRecord) => {
  try {
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD
    })
    const response = await request<PartnersFinderResponseModel>(RequestMethods.Post, Paths.Hp.EducationWorkplan.getPartnersDashboard, {
      schema: PartnersFinderResponseModel,
      body: filters
    })
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD_ERROR
    })
    throw e
  }
}

export const getPartnersFinderDashboardById= (dispatch: Dispatch) => async(filters: IAwardSearchRecord, companyId: number, offset: number) => {
  try {
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD_BY_ID
    })
    const response = await request<PartnersFinderByIdResponseModel>(RequestMethods.Post, Paths.Hp.EducationWorkplan.getPartnersDashboardById(companyId, offset), {
      schema: PartnersFinderByIdResponseModel,
      body: filters
    })
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD_BY_ID_SUCCESS,
      payload: {...response.data.data, id: companyId}
    })
  } catch(e) {
    dispatch({
      type: GET_PARTNERS_FINDER_DASHBOARD_BY_ID_ERROR
    })
    throw e
  }
}

export const getPartnersFinderContacts = (dispatch: Dispatch) => async (companyId: string) => {
  try {
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS
    })
    const response = await request<CustomerFinderContactsResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getPartnersFinderContacts(companyId), {
      schema: CustomerFinderContactsResponseModel,
    })
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS_SUCCESS,
      payload: response.data
    })
  } catch(e) {
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS_ERROR
    })
    throw e
  }
}

export const getPartnersFinderContactsById = (dispatch: Dispatch) => async (companyId: string, positionId: string, offset: number) => {
  try {
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS_BY_ID
    })
    const response = await request<ContactsListByIdResponseModel>(RequestMethods.Get, Paths.Hp.EducationWorkplan.getPartnersFinderContactsById(companyId, positionId, offset), {
      schema: ContactsListByIdResponseModel,
    })
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS_BY_ID_SUCCESS,
      payload: {...response.data.data, id: positionId}
    })
  } catch(e) {
    dispatch({
      type: GET_PARTNERS_FINDER_CONTACTS_BY_ID_ERROR
    })
    throw e
  }
}


export const clearPartnersFinderContacts = (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_PARTNERS_FINDER_CONTACTS
  })
}
