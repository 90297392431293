import React from 'react'

import { PayPalButton } from 'react-paypal-button-v2'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from '../NotificationType'
import { getUserAction } from 'store/common/user/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

interface Props {
  userId?: number
  planId: string
  planName: string
}

const PaypalButtonWrapper = ({ userId, planId, planName }: Props) => {

  const { show } = useNotification()
  const dispatch = useDispatch()
  const history = useHistory()

  if (!userId) {
    return <Button
      variant='contained'
      disableRipple
      onClick={() => history.push('/auth/sign-in')}
    >
      Log In
    </Button>
  }
  return (
    <PayPalButton
      options={{
        locale: 'en_US'
      }}
      createSubscription={(data: any, actions: any) => {
        return actions.subscription.create({
          plan_id: planId,
          custom_id: userId,
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        })
      }}
      onError={() => {
        show('Error happened while processing your request, please try again later', NotificationTypeList.Error)
      }}
      onApprove={() => {
        getUserAction()(dispatch)
        show(`You successfully subscribed to "${planName}" plan. Please, reload this page in a minute to get changes applied`, NotificationTypeList.Success, undefined, 30000)
      }}
    />
  )
}

export default PaypalButtonWrapper
