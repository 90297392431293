import {
  ICompetitivePositionResponseModel,
  ICompetitorLandscapeResponseModel,
  ICustomerConnectionResponseModel,
  IHPPWin,
  IHPPWinResponseModel,
  IIncumbentperformanceResponseModel,
  IIncumbentsResponseModel,
  IMemebersTimeModel,
  IMemebersTimeResponseModel,
  IQualifyPrimeResponseModel,
  IQualifyPrimeSBIRResponseModel,
  IQualifySubResponseModel,
  IRiskAndEffort,
  IRiskAndEffortResponseModel,
  IScoreResponseModel,
  ISelfAssessmentInputResponseModel,
  ISetSubbingResponseModel,
  ISetTaskDataResponseModel,
  ISubbingResponseModel,
  ISubsequentAwardsResponseModel,
  ISummaryResponseModel,
  ITasksCalcResponseModel,
} from './qualifying.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { SpendingModel } from 'models/hp/awards/awards.model'
import * as calcInterface from 'models/hp/rfp/calc/calc.interface'


@jsonObject
export class MemebersTimeModel implements IMemebersTimeModel {
  @jsonMember({ constructor: Number })
  id: number  = 0

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Number })
  value: number = 0
}

@jsonObject
export class MemebersTimeResponseModel extends BaseResponseModel implements IMemebersTimeResponseModel {
  @jsonArrayMember(MemebersTimeModel)
  data: MemebersTimeModel[] = []
}


@jsonObject
export class RiskAndEffortData {
  @jsonMember({ constructor: Number })
  estimatedValue: number  = 0

  @jsonMember({ constructor: Number })
  percentage: number  = 0

  @jsonMember({ constructor: Number })
  margin: number  = 0
}


@jsonObject
export class RiskAndEffortModel implements IRiskAndEffort {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  type: string  = ''

  @jsonMember({ constructor: RiskAndEffortData })
  data: RiskAndEffortData = new RiskAndEffortData()
}


@jsonObject
export class RiskAndEffortResponseModel extends BaseResponseModel implements IRiskAndEffortResponseModel {
  @jsonMember({ constructor: RiskAndEffortModel })
  data: RiskAndEffortModel = new RiskAndEffortModel()
}


@jsonObject
export class HPPWinData {
  @jsonMember({ constructor: Boolean })
  similarSizedContract: boolean  = false

  @jsonMember({ constructor: Boolean })
  aligningWork: boolean  = false

  @jsonMember({ constructor: Boolean })
  deliverSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  marginAcceptable: boolean  = false

  @jsonMember({ constructor: Boolean })
  priceSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  valueAlignsWithCriteria: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentCompelling: boolean  = false

  @jsonMember({ constructor: Boolean })
  repsCertsPastPeformance: boolean  = false

  @jsonMember({ constructor: Boolean })
  repsCertsPastPeformanceFlowDown: boolean  = false

  @jsonMember({ constructor: Boolean })
  customerKnows: boolean  = false

  @jsonMember({ constructor: Boolean })
  startEarly: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentEngagement: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentShapingAnotherGroup: boolean  = false

  @jsonMember({ constructor: Boolean })
  fundingPlans: boolean  = false
}


@jsonObject
export class HPPWinModel implements IHPPWin {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  type: string  = ''

  @jsonMember({ constructor: HPPWinData })
  values: HPPWinData = new HPPWinData()

  @jsonMember({ constructor: Number })
  score: number  = 0
}


@jsonObject
export class HPPWinResponseModel extends BaseResponseModel implements IHPPWinResponseModel {
  @jsonMember({ constructor: HPPWinModel })
  data: HPPWinModel = new HPPWinModel()
}

@jsonObject
export class QualifySubValuesModel {
  @jsonMember({ constructor: Boolean })
  pieceOfWorkSubcontracted: boolean  = false

  @jsonMember({ constructor: Boolean })
  wonBefore: boolean  = false

  @jsonMember({ constructor: Boolean })
  workAligns: boolean  = false

  @jsonMember({ constructor: Boolean })
  haveCapacityToPursue: boolean  = false

  @jsonMember({ constructor: Boolean })
  marginAcceptable: boolean  = false

  @jsonMember({ constructor: Boolean })
  priceSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  valueAlignsWithCriteria: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentCompelling: boolean  = false

  @jsonMember({ constructor: String })
  bestDescription: string  = 'won'

  @jsonMember({ constructor: Boolean })
  teamingRelationship: boolean  = false

  @jsonMember({ constructor: Boolean })
  increasePrimeChances: boolean  = false
}

@jsonObject
export class QualifyPrimeValuesModel {
  @jsonMember({ constructor: Boolean })
  similarSizedContract: boolean  = false

  @jsonMember({ constructor: Boolean })
  aligningWork: boolean  = false

  @jsonMember({ constructor: Boolean })
  deliverSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  marginAcceptable: boolean  = false

  @jsonMember({ constructor: Boolean })
  priceSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  valueAlignsWithCriteria: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentCompelling: boolean  = false

  @jsonMember({ constructor: Boolean })
  repsCertsPastPerformance: boolean  = false

  @jsonMember({ constructor: Boolean })
  customerKnows: boolean  = false

  @jsonMember({ constructor: Boolean })
  startEarly: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentEngagement: boolean  = false

  @jsonMember({ constructor: Boolean })
  governmentShapingAnotherGroup: boolean  = false

  @jsonMember({ constructor: Boolean })
  fundingPlans: boolean  = false
}

@jsonObject
export class QualifySubModel {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  type: string  = ''

  @jsonMember({ constructor: QualifySubValuesModel })
  values: QualifySubValuesModel = new QualifySubValuesModel()

  @jsonMember({ constructor: Number })
  score: number  = 0
}

@jsonObject
export class QualifyPrimeSBIRValuesModel {
  @jsonMember({ constructor: Boolean })
  workMirrorsThings: boolean  = false

  @jsonMember({ constructor: Boolean })
  workAlignsWithCore: boolean  = false

  @jsonMember({ constructor: Boolean })
  capacityToPursue: boolean  = false

  @jsonMember({ constructor: Boolean })
  innovativeSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  technicalRisk: boolean  = false

  @jsonMember({ constructor: Boolean })
  sellSolution: boolean  = false

  @jsonMember({ constructor: Boolean })
  performAllWork: boolean  = false

  @jsonMember({ constructor: Boolean })
  understandProblem: boolean  = false

  @jsonMember({ constructor: Boolean })
  understandHowToSellGov: boolean  = false

  @jsonMember({ constructor: Boolean })
  understandHowToSellCustomers: boolean  = false

  @jsonMember({ constructor: Boolean })
  receivedPrivateFunding: boolean  = false

  @jsonMember({ constructor: Boolean })
  successfulBenefit: boolean  = false

  @jsonMember({ constructor: Boolean })
  developedSimilar: boolean  = false

  @jsonMember({ constructor: Boolean })
  commercializedSimilar: boolean  = false

  @jsonMember({ constructor: Boolean })
  phdKnowledge: boolean  = false

}

@jsonObject
export class QualifyPrimeSBIRModel {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  type: string  = ''

  @jsonMember({ constructor: QualifyPrimeSBIRValuesModel })
  values: QualifyPrimeSBIRValuesModel = new QualifyPrimeSBIRValuesModel()

  @jsonMember({ constructor: Number })
  score: number  = 0
}

@jsonObject
export class QualifyPrimeModel {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  type: string  = ''

  @jsonMember({ constructor: QualifyPrimeValuesModel })
  values: QualifyPrimeValuesModel = new QualifyPrimeValuesModel()

  @jsonMember({ constructor: Number })
  score: number  = 0
}

@jsonObject
export class QualifySubResponseModel extends BaseResponseModel implements IQualifySubResponseModel {
  @jsonMember({ constructor: QualifySubModel })
  data: QualifySubModel = new QualifySubModel()
}

@jsonObject
export class QualifyPrimeResponseModel extends BaseResponseModel implements IQualifyPrimeResponseModel {
  @jsonMember({ constructor: QualifyPrimeModel })
  data: QualifyPrimeModel = new QualifyPrimeModel()
}

@jsonObject
export class QualifyPrimeSBIRResponseModel extends BaseResponseModel implements IQualifyPrimeSBIRResponseModel {
  @jsonMember({ constructor: QualifyPrimeSBIRModel })
  data: QualifyPrimeSBIRModel = new QualifyPrimeSBIRModel()
}


@jsonObject
export class ScoreModel {
  @jsonMember({ constructor: Number })
  score: number  = 0
}


@jsonObject
export class ScoreResponseModel extends BaseResponseModel implements IScoreResponseModel {
  @jsonMember({ constructor: ScoreModel })
  data: ScoreModel = new ScoreModel()
}


@jsonObject
export class TasksCalcIndicator {
  @jsonMember({ constructor: String })
  value: string  = ''

  @jsonMember({ constructor: Number })
  count: number  = 0
}


@jsonObject
export class TasksCalcAnswer {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  value: string = ''
}

@jsonObject
export class TasksCalcDataItem {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  text: string = ''

  @jsonMember({ constructor: String })
  rawValue = ''

  @jsonMember({ constructor: String })
  value = ''

  @jsonMember({ constructor: String })
  fileName: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: TasksCalcAnswer })
  answer: TasksCalcAnswer = new TasksCalcAnswer()
}

@jsonObject
export class TasksCalcModel {
  @jsonMember({ constructor: Boolean })
  success: boolean  = false

  @jsonMember({ constructor: Number })
  count: number  = 0

  @jsonArrayMember(TasksCalcIndicator)
  indicators: TasksCalcIndicator[] = []

  @jsonArrayMember(TasksCalcDataItem)
  data: TasksCalcDataItem[] = []
}

@jsonObject
export class TasksCalcResponseModel extends BaseResponseModel implements ITasksCalcResponseModel {
  @jsonArrayMember(TasksCalcDataItem)
  data: TasksCalcDataItem[] = []

  @jsonMember({ constructor: Number })
  count: number  = 0

  @jsonArrayMember(TasksCalcIndicator)
  indicators: TasksCalcIndicator[] = []
}


@jsonObject
export class SetTasksModel {
  @jsonMember({ constructor: String })
  selfAssessed: string  = ''
}


@jsonObject
export class SetTasksResponseModel extends BaseResponseModel implements ISetTaskDataResponseModel {
  @jsonMember({ constructor: SetTasksModel })
  data: SetTasksModel = new SetTasksModel()
}

@jsonObject
export class IncumbentContractModel {
  @jsonMember({ constructor: String })
  id: string  = ''
  
  @jsonMember({ constructor: String })
  createdBy: string  = ''

  @jsonMember({ constructor: String })
  createdAt: string  = ''

  @jsonMember({ constructor: String })
  updatedBy: string  = ''

  @jsonMember({ constructor: String })
  updatedAt: string  = ''

  @jsonMember({ constructor: String })
  awardIdPiid: string  = ''
}

@jsonObject
export class IncumbentModel {
  @jsonMember({ constructor: String })
  multipleOrSingleAwardIdvTypeCode: string  = ''

  @jsonMember({ constructor: String })
  totalDollarsObligated: string  = ''

  @jsonMember({ constructor: String })
  contractTransactionUniqueKey: string  = ''

  @jsonMember({ constructor: String })
  potentialTotalValueOfAward: string  = ''

  @jsonMember({ constructor: String })
  currentTotalValueOfAward: string  = ''

  @jsonMember({ constructor: String })
  typeOfSetAside: string  = ''

  @jsonMember({ constructor: String })
  naicsCodeDescription: string  = ''

  @jsonMember({ constructor: String })
  solicitationIdentifier: string  = ''

  @jsonMember({ constructor: String })
  awardIdPiid: string  = ''

  @jsonMember({ constructor: String })
  idvType: string  = ''

  @jsonMember({ constructor: String })
  parentAwardType: string  = ''

  @jsonMember({ constructor: String })
  primaryPOPStateCode: string  = ''

  @jsonMember({ constructor: String })
  awardingAgencyName: string  = ''

  @jsonMember({ constructor: String })
  primaryPOPCityName: string  = ''

  @jsonMember({ constructor: Date })
  periodOfPerformancePotentialEndDate: Date  = new Date()

  @jsonMember({ constructor: String })
  awardDescription: string  = ''

  @jsonMember({ constructor: String })
  productOrServiceCode: string  = ''

  @jsonMember({ constructor: String })
  recipientName: string  = ''

  @jsonMember({ constructor: String })
  numberOfOffersReceived: string  = ''

  @jsonMember({ constructor: String })
  awardingSubAgencyName: string  = ''

  @jsonMember({ constructor: String })
  productOrServiceCodeDescription: string  = ''

  @jsonMember({ constructor: String })
  awardOrIdvFlag: string  = ''

  @jsonMember({ constructor: String })
  naicsCode: string  = ''

  @jsonMember({ constructor: String })
  awardType: string  = ''

  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  federalActionObligation: string  = ''

  @jsonMember({ constructor: IncumbentContractModel })
  contacts: IncumbentContractModel  = new IncumbentContractModel()
}

@jsonObject
export class IncumbensinputValueModel {
  @jsonMember({ constructor: IncumbentModel })
  incumbent: IncumbentModel  = new IncumbentModel()

  @jsonMember({ constructor: String })
  choice: string  = ''

  @jsonMember({ constructor: String })
  value: string  = ''

  @jsonMember({ constructor: String })
  competitors: string  = ''
}


@jsonObject
export class IncumbensInputCountModel {
  @jsonMember({ constructor: Number })
  incumbent: number  = 0

  @jsonMember({ constructor: Number })
  user: number  = 0
}

@jsonObject
export class IncumbentInputModel {
  @jsonMember({ constructor: IncumbensinputValueModel })
  values: IncumbensinputValueModel  = new IncumbensinputValueModel()

  @jsonMember({ constructor: IncumbensInputCountModel })
  count: IncumbensInputCountModel  = new IncumbensInputCountModel()
}

@jsonObject
export class IncumbentsModel {
  @jsonArrayMember(IncumbentModel)
  data: IncumbentModel[] = []

  @jsonMember({ constructor: IncumbentInputModel })
  input: IncumbentInputModel  = new IncumbentInputModel()
}


@jsonObject
export class IncumbensResponseModel extends BaseResponseModel implements IIncumbentsResponseModel {
  @jsonMember({ constructor: IncumbentsModel })
  data: IncumbentsModel = new IncumbentsModel()
}


@jsonObject
export class IncumbentPerformanceInputValuesModel {
  @jsonMember({ constructor: String })
  choice: string  = ''
}


@jsonObject
export class IncumbentPerformanceInputCountModel {
  @jsonMember({ constructor: Number })
  incumbent: number  = 0

  @jsonMember({ constructor: Number })
  user: number  = 0
}

@jsonObject
export class IncumbentPerformanceInputModel {
  @jsonMember({ constructor: IncumbentPerformanceInputValuesModel })
  values: IncumbentPerformanceInputValuesModel  = new IncumbentPerformanceInputValuesModel()

  @jsonMember({ constructor: IncumbentPerformanceInputCountModel })
  count: IncumbentPerformanceInputCountModel  = new IncumbentPerformanceInputCountModel()
}


@jsonObject
export class IncumbentPerformanceModel {
  @jsonMember({ constructor: IncumbentPerformanceInputModel })
  input: IncumbentPerformanceInputModel  = new IncumbentPerformanceInputModel()

  @jsonMember({ constructor: SpendingModel })
  data: SpendingModel = new SpendingModel()
}


@jsonObject
export class IncumbentPerformanceResponseModel extends BaseResponseModel implements IIncumbentperformanceResponseModel {
  @jsonMember({ constructor: IncumbentPerformanceModel })
  data: IncumbentPerformanceModel = new IncumbentPerformanceModel()
}


@jsonObject
export class SubsequentAwardItemModel {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  primeAwardDescription: string  = ''

  @jsonMember({ constructor: String })
  totalDollarsObligated: string  = ''

  @jsonMember({ constructor: String })
  contractTransactionUniqueKey: string  = ''

  @jsonMember({ constructor: String })
  awardingOfficeName: string  = ''

  @jsonMember({ constructor: String })
  fundingOfficeName: string  = ''

  @jsonMember({ constructor: Date })
  periodOfPerformanceStartDate: Date  = new Date()
}


@jsonObject
export class SubsequentAwardsModel {
  @jsonMember({ constructor: IncumbentPerformanceInputModel })
  input: IncumbentPerformanceInputModel  = new IncumbentPerformanceInputModel()

  @jsonArrayMember(SubsequentAwardItemModel)
  data: SubsequentAwardItemModel[] = []
}


@jsonObject
export class SubsequentAwardsResponseModel extends BaseResponseModel implements ISubsequentAwardsResponseModel {
  @jsonMember({ constructor: SubsequentAwardsModel })
  data: SubsequentAwardsModel = new SubsequentAwardsModel()
}


@jsonObject
export class CustomerConnectionItemModel {
  @jsonMember({ constructor: String })
  id: string  = ''

  @jsonMember({ constructor: String })
  createdBy: string  = ''

  @jsonMember({ constructor: String })
  approvedBy: string  = ''

  @jsonMember({ constructor: Date })
  createdAt: Date  = new Date()
}


@jsonObject
export class CustomerConnectionModel {
  @jsonMember({ constructor: IncumbentPerformanceInputModel })
  input: IncumbentPerformanceInputModel  = new IncumbentPerformanceInputModel()

  @jsonArrayMember(CustomerConnectionItemModel)
  data: CustomerConnectionItemModel[] = []
}


@jsonObject
export class CustomerConnectionResponseModel extends BaseResponseModel implements ICustomerConnectionResponseModel {
  @jsonMember({ constructor: CustomerConnectionModel })
  data: CustomerConnectionModel = new CustomerConnectionModel()
}

@jsonObject
export class CompetitorLandscapeDataModel {
  @jsonMember({ constructor: Number })
  x: number  = 0

  @jsonMember({ constructor: Number })
  y: number  = 0

  @jsonMember({ constructor: Number })
  r: number  = 0
  
  @jsonMember({ constructor: IncumbentModel })
  record: IncumbentModel  = new IncumbentModel()
}


@jsonObject
export class CompetitorLandscapeCoordinatesModel {
  @jsonMember({ constructor: Number })
  x: number  = 0

  @jsonMember({ constructor: Number })
  y: number  = 0

  @jsonMember({ constructor: Number })
  r: number  = 0
}


@jsonObject
export class CompetitorLandscapeRecordModel {
  @jsonMember({ constructor: String })
  recipientUEI: string  = ''

  @jsonMember({ constructor: String })
  recipientName: string  = ''

  @jsonMember({ constructor: String })
  typeOfSetAside: string  = ''

  @jsonMember({ constructor: String })
  revenue: string  = ''
  
  @jsonArrayMember(CompetitorLandscapeDataModel)
  records: CompetitorLandscapeDataModel[]  = []

  @jsonMember({ constructor: CompetitorLandscapeCoordinatesModel })
  coordinates: CompetitorLandscapeCoordinatesModel  = new CompetitorLandscapeCoordinatesModel()
}


@jsonObject
export class CompetitorLandscapeModel {
  @jsonMember({ constructor: IncumbentPerformanceInputModel })
  input: IncumbentPerformanceInputModel  = new IncumbentPerformanceInputModel()

  @jsonArrayMember(CompetitorLandscapeRecordModel)
  data: CompetitorLandscapeRecordModel[] = []
}


@jsonObject
export class CompetitorLandscapeResponseModel extends BaseResponseModel implements ICompetitorLandscapeResponseModel {
  @jsonMember({ constructor: CompetitorLandscapeModel })
  data: CompetitorLandscapeModel = new CompetitorLandscapeModel()
}


@jsonObject
export class CompetitivePositionInputValuesModel {
  @jsonMember({ constructor: String })
  choice1: string  = ''

  @jsonMember({ constructor: String })
  choice2: string  = ''
}


@jsonObject
export class CompetitivePositionInputModel {
  @jsonMember({ constructor: CompetitivePositionInputValuesModel })
  values: CompetitivePositionInputValuesModel  = new CompetitivePositionInputValuesModel()

  @jsonMember({ constructor: IncumbentPerformanceInputCountModel })
  count: IncumbentPerformanceInputCountModel  = new IncumbentPerformanceInputCountModel()
}


@jsonObject
export class CompetitivePositionModel {
  @jsonMember({ constructor: CompetitivePositionInputModel })
  input: CompetitivePositionInputModel  = new CompetitivePositionInputModel()
}


@jsonObject
export class CompetitivePositionResponseModel extends BaseResponseModel implements ICompetitivePositionResponseModel {
  @jsonMember({ constructor: CompetitivePositionModel })
  data: CompetitivePositionModel = new CompetitivePositionModel()
}

@jsonObject
export class SummaryModel {
  @jsonMember({ deserializer: (json => json || json === 0 ? String(json) : '') })
  choice: string  = ''

  @jsonMember({ constructor: String })
  choice1: string  = ''

  @jsonMember({ constructor: String })
  choice2: string  = ''

  @jsonMember({ constructor: String })
  key: string  = ''

  @jsonMember({ constructor: IncumbentPerformanceInputCountModel })
  count: IncumbentPerformanceInputCountModel  = new IncumbentPerformanceInputCountModel()
}


@jsonObject
export class SummaryResponseModel extends BaseResponseModel implements ISummaryResponseModel {
  @jsonArrayMember(SummaryModel)
  data: SummaryModel[] = []
}

@jsonObject
export class SelfAssessmentInputCriteria {
  @jsonMember({ deserializer: (json => json) })
  value: number | boolean = false
}

@jsonObject
export class SelfAssessmentInputCriteriaModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  prevId: string = ''
  
  @jsonMember({ constructor: String })
  nextId: string = ''

  @jsonMember({ constructor: String })
  quote: string = ''

  @jsonMember({ constructor: String })
  type: calcInterface.ComplianceFieldType = 'compliance__certs'

  @jsonMember({ constructor: SelfAssessmentInputCriteria })
  evaluation: SelfAssessmentInputCriteria = new SelfAssessmentInputCriteria()
}

@jsonObject
export class SelfAssessmentInput {
  @jsonMember({ constructor: String })
  grade: string = ''

  @jsonMember({ constructor: String })
  partnersGrade: string = ''

  @jsonArrayMember(SelfAssessmentInputCriteriaModel)
  criteria: SelfAssessmentInputCriteriaModel[] = []
}

@jsonObject
export class SelfAssessmentGrade {
  @jsonMember({ constructor: String })
  grade: string = ''
}


@jsonObject
export class SelfAssessmentInputModel {
  @jsonMember({ constructor: SelfAssessmentInput })
  input: SelfAssessmentInput = new SelfAssessmentInput()
} 


@jsonObject
export class SelfAssessmentResponseModel extends BaseResponseModel implements ISelfAssessmentInputResponseModel {
  @jsonMember({ constructor: SelfAssessmentInputModel })
  data: SelfAssessmentInputModel = new SelfAssessmentInputModel()
}

@jsonObject
export class SelfAssessmentGradeResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SelfAssessmentGrade })
  data: SelfAssessmentGrade = new SelfAssessmentGrade()
}



@jsonObject
export class SubbingInputValues {
  @jsonMember({ constructor: String })
  pastPerformance: string = ''

  @jsonMember({ constructor: String })
  intimacy: string = ''

  @jsonMember({ constructor: String })
  technology: string = ''

  @jsonMember({ constructor: String })
  price:string = ''
}


@jsonObject
export class SubbingInputCount {
  @jsonMember({ constructor: Number })
  incumbent: number = 0

  @jsonMember({ constructor: Number })
  user: number = 0
}


@jsonObject
export class SubbingInputModel {
  @jsonMember({ constructor: SubbingInputValues })
  values: SubbingInputValues = new SubbingInputValues()

  @jsonMember({ constructor: SubbingInputCount })
  count: SubbingInputCount = new SubbingInputCount()

  @jsonMember({ constructor: String })
  grade: string = ''
}


@jsonObject
export class SubbingModel {
  @jsonMember({ constructor: SubbingInputModel })
  input: SubbingInputModel = new SubbingInputModel()
}

@jsonObject
export class SubbingResponseModel extends BaseResponseModel implements ISubbingResponseModel {
  @jsonMember({ constructor: SubbingModel })
  data: SubbingModel = new SubbingModel()
}

@jsonObject
export class SetSubbingModel {
  @jsonMember({ constructor: String })
  grade: string = ''
}

@jsonObject
export class SetSubbingResponseModel extends BaseResponseModel implements ISetSubbingResponseModel {
  @jsonMember({ constructor: SetSubbingModel })
  data: SetSubbingModel = new SetSubbingModel()
}


