import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IFilterOption } from 'models/hp/search/search.interface'
import {
  pscCodeCountSelector,
  pscCodesSelector
} from 'store/hp/grouped-code/selectors'
import styles from './index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import {
  removeCompanySearchFilterValue, removeCompanySearchFilterValues,
  setCompanySearchFilterValue, setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'
import {
  companyProfileSelectedPscOptions
} from 'store/hp/company-profiles/search/selectors'

const PscFilter = () => {
  const dispatch = useDispatch()

  const pscCodesOptions: IFilterOption[] = useSelector(pscCodesSelector) as IFilterOption[]
  const selectedPscOptions: string[] = useSelector(companyProfileSelectedPscOptions) as string[]
  const pscCount: number = useSelector(pscCodeCountSelector) as number

  const handleChange = (value: string | number, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('pscCodes', value)
    } else {
      removeCompanySearchFilterValue(dispatch)('pscCodes', value)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('pscCodes', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('pscCodes', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={pscCount}
        iconType='psc'
        handlePartialChange={handlePartialChange}
        filterTitle='Functional Area (PSC)'
        filterOptions={pscCodesOptions}
        selectedFilterOptions={selectedPscOptions}
        selectField='value'
        handleChange={handleChange}
      />
    </div>
  )
}

export default PscFilter