import { createContext } from 'react'

export interface IMainFrameContext {
  isHiddenSidebar: boolean,
  setHiddenSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

export const MainFrameContext = createContext<IMainFrameContext>({
  isHiddenSidebar: false,
  setHiddenSidebar: () => {}
})