import React from 'react'
import styles from './index.module.scss'
import { Grid, Typography, Paper, Checkbox } from '@material-ui/core'
import { SapItem } from 'models/hp/market-research/market-research.model'
import SvgIcon from 'components/SvgIcon'
import ProgressBar from './components/ProgressBar'
import { marketResearchSapPercentage } from 'helpers/search'
import { numberToStrWithRound } from 'common/number'
import { ICustomer, ICustomerMapper } from './index'

interface Props {
  item: SapItem
  addCustomer: (customer: ICustomer) => void
  customers: ICustomerMapper
}

const ListingItem = ({ item, addCustomer, customers }: Props) => {
  const [open, setOpen] = React.useState(false)

  const valueToText = (val: number): string | null => {
    if (val > 10000) {
      return '(SAP)'
    } if (val <= 10000 && val > 0) {
      return '(Micro)'
    } else {
      return null
    }
  }

  return (
    <Grid item>
      <Paper className={styles['paper']}>
        <Grid container>
          <Grid item md={3}>
            <Typography variant='h5'>{item.state}</Typography>
          </Grid>
          <Grid item md={8}>
            <Grid container className={styles['bar-item']}>
              <Grid item md={4}>
                <Typography>$ Val SAP K:</Typography>
              </Grid>
              <Grid item md={8}>
                <ProgressBar
                  value={marketResearchSapPercentage.handler(item.sap, item.micro, item.sap)}
                  color='primary'
                  label={numberToStrWithRound(item.sap)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={4}>
                <Typography>$ Val Micro Purchases:</Typography>
              </Grid>
              <Grid item md={8}>
                <ProgressBar
                  value={marketResearchSapPercentage.handler(item.sap, item.micro, item.micro)}
                  color='secondary'
                  label={numberToStrWithRound(item.micro)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} onClick={() => setOpen(prev => !prev)}>
            <Grid container alignItems='center' justifyContent='center' className={styles['icon-container']}>
              <SvgIcon
                className={open ? styles['rotated'] : undefined}
                name='angle-down'
                width={35} height={35}
              />
            </Grid>
          </Grid>
        </Grid>

        {open && (
          <div>
            {item.offices.map((office, idx) => (
              <div key={idx}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <Checkbox
                      color='primary'
                      checked={!!customers[office.office]}
                      onClick={() => addCustomer(office)}
                    />
                  </Grid>
                  <Grid item md={11}>
                    <Typography variant='h5'>
                      {[office.agency, office.subAgency, office.office].filter(x => !!x).join(' | ')}
                    </Typography>
                  </Grid>
                </Grid>
                <ul className={styles['inner-list']}>
                  {office.items.map((item, idx) => (
                    <li key={idx}>
                      <b>{valueToText(item.potentialTotalValueOfAward)}</b> {item.awardDescription}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </Paper>
    </Grid>
  )
}

export default ListingItem