import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import SvgIcon from 'components/SvgIcon'

interface Props {
  close: () => void
}

const ListingHints = ({close}: Props) => {
  return (
    <Grid
      container
      style={{height: '100%'}}
      direction='column'
    >
      <Paper className={styles['paper']}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>Find Keywords</Typography>
          <SvgIcon
            name='multiply'
            width={30}
            height={30}
            className={styles['clickable']}
            onClick={close}
          />
        </Grid>
        <Typography>Difficulty: <b>Mid</b></Typography>
        <Typography>Time: <b>15 min</b></Typography>
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
          <Grid item md={6}>
            <SvgIcon name='film' width={250} height={250}/>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>Why you should do market research</Typography>
            <Typography gutterBottom>
              The federal market is massive, diverse, and fragmented
            </Typography>
            <Typography gutterBottom>
              The government buys about $500B a year in good and services, so they probably buy what you are selling,
              but finding your customers, partners, and contracts is challenging
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>What this tool will help you find</Typography>
            <Typography gutterBottom>The right keywords and codes to surface the most promising contracts</Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>The Process</Typography>
            <Typography gutterBottom>We will start with keywords about your products and services, and then provide a
              bunch of contracts based on your keywords so you can consider them and save in your profile</Typography>
            <Typography gutterBottom>This exercise will probably take 15 minutes</Typography>
            <Typography gutterBottom>If you have a FedScout account we will save your progress and insights so that you
              can quickly build searches and get alerts about promising contracts</Typography>
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export default ListingHints