import React from 'react'
import styles from './index.module.scss'
import { Button, Grid, Typography, Paper } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface BigHintProps {
  close?: () => void
}

export const BigHint = ({ close }: BigHintProps) => {
  return (
    <Grid
      container
      style={{height: '100%'}}
      direction='column'
    >
      <Paper className={styles['paper']}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>Find Customers that are new vendor friendly</Typography>
          <SvgIcon
            name='multiply'
            width={30}
            height={30}
            className={styles['clickable']}
            onClick={close}
          />
        </Grid>
        <Typography>Difficulty: <b>Mid</b></Typography>
        <Typography>Time: <b>15 min</b></Typography>
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
          <Grid item md={6}>
            <SvgIcon name='film' width={250} height={250}/>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>Find Customers that are new vendor friendly</Typography>
            <Typography gutterBottom>
              One of the government’s favorite ways of keeping new vendors out is to put a bunch of onerous
              certifications, qualifications, and past performance requirements into their RFPs.
            </Typography>
            <Typography gutterBottom>
              But there are some government agencies and offices that are more open to new vendors and structure their
              contracts in ways that are new business friendly making them ideal onramps to the federal market.
            </Typography>
            <Typography>
              But here’s the catch, these purchases don’t get announced (usually) and finding information on them is a
              little tricky.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>What makes a customer new-vendor-friendly</Typography>
            <Typography gutterBottom>
              To identify new-vendor-friendly customers we are looking at the way each office buys. If they mostly use
              full-octane high bureaucracy contracting mechanisms we rate them less friendly. But if they use SAP and
              micro-purchases we rate them more friendly. Here’s what this means;
            </Typography>
            <Typography>
              <b>Simplified Acquisitions Process (SAP):</b> These are smaller purchases (in general below $250K) and the
              government doesn’t have to go through all the formality of the traditional acquisitions process. Basically
              the government:
            </Typography>
            <ul>
              <li>Identifies a need</li>
              <li>Gets quotes from three vendors</li>
              <li>Chooses a winner, and justifies why they chose them</li>
              <li>Completes the contract</li>
            </ul>
            <Typography>
              <b>Micro-purchase (aka purchase cards):</b> If the government is buying a product of service less than
              $10K then
              they can pretty much buy it like you buy stuff. The government identifies a need then they go buy it from
              a commercial vendor (e.g. Amazon, Home Depot, or you) using their government credit card. Super easy
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>How to find new-vendor-friendly customers</Typography>
            <Typography gutterBottom>
              Most government data is messy, but this data is a disaster. In fairness the government shouldn’t spend a
              lot of time documenting the pizzas they bought for the office on a Friday. So we get it, but it makes
              finding new-vendor-friendly offices tricky.
            </Typography>
            <Typography gutterBottom>
              So we recommend putting in some keywords and seeing what comes back. Our best estimate is that we are
              capturing about 20% of all micro-transactions and 80% of the SAP contracts, so be aware that this is an
              incomplete list.
            </Typography>
            <Typography gutterBottom>
              HOWEVER, this is a great starting point.
            </Typography>
            <Typography gutterBottom>
              <b>A note on office names:</b> The government has no discipline in their office naming conventions so the
              office names that we show may look like random jumbles of letters and numbers (looking at you DOD) but if
              you find a promising office, google the office name and usually you can find out who they are and a phone
              number or email.
            </Typography>
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export const SmallHint = () => {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{height: '100%'}}
      direction='column'
    >
      <Typography variant='h3' gutterBottom>Enter on or more keywords and hit</Typography>
      <Button
        color='primary'
        variant='contained'
      >
        Find friendly customers
      </Button>
      <Typography
        variant='h3'
        style={{marginTop: '12px'}}
      >
        To get started
      </Typography>
    </Grid>
  )
}