import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { DetailServiceLineModel } from '../service-line/service-line.model'
import { EndingSoonModel } from '../ending-soon/ending-soon.model'
import { DetailCustomerDataModel } from '../customer/customer.model'
import { VehicleModel } from '../vehicle/vehicle.model'
import { roundAmount } from 'common/chart'

export class CompanyProfileInlineItem {
  @jsonMember({constructor: String})
  duns: string = ''

  @jsonMember({constructor: String})
  name: string = ''
}

@jsonObject
export class DetailKeyPersonalModel {
  @jsonMember({constructor: String})
  fullName: string = ''

  @jsonArrayMember(String)
  titles: string[] = []

  @jsonMember({constructor: String})
  phone: string = ''

  @jsonMember({constructor: String})
  email: string = ''
}

@jsonObject
export class CompanyProfilePreviewModel {
  @jsonMember({constructor: String})
  duns: string = ''
  @jsonMember({constructor: String})
  govConName: string = ''
  @jsonMember({constructor: String})
  name?: string = undefined
  @jsonMember({constructor: String})
  popularPsc: string = ''
  @jsonMember({constructor: Number})
  roundLowerEnd: number = 0
  @jsonMember({constructor: Number})
  roundUpperEnd: number = 0
  @jsonMember({constructor: String})
  sizeName: string = ''
  @jsonMember({constructor: String})
  popularPscCode: string = ''
  @jsonMember({constructor: String})
  categorizationType: string = ''
  @jsonMember({constructor: String})
  websiteUrl: string = ''
}

@jsonObject
export class CompanyProfilePreviewModelResponse extends BasePaginationModel {
  @jsonArrayMember(CompanyProfilePreviewModel)
  data: CompanyProfilePreviewModel[] = []
}

@jsonObject
export class CompanyProfileInlineListResponseModel extends BaseResponseModel {
  @jsonArrayMember(CompanyProfileInlineItem)
  data: CompanyProfileInlineItem[] = []
}

@jsonObject
export class CompanyProfileDetailModel {

  static chartDataDeserializer(data: any): any {
    if (!data || data.length === 0) {
      return data
    }
    if (data.every((i: any) => i.amount === 0)) {
      return []
    }
    return data.map((item: any) => ({...item, amount: roundAmount(item.amount / 1000000)}))
      .sort((a: any, b: any) => b.amount - a.amount)
  }

  @jsonMember({constructor: String})
  duns: string = ''

  @jsonMember({constructor: Number})
  roundLowerEnd: number = 0

  @jsonMember({constructor: Number})
  roundUpperEnd: number = 0

  @jsonArrayMember(VehicleModel)
  vehicles: VehicleModel[] = []

  @jsonArrayMember(DetailKeyPersonalModel)
  keyPersonnel: DetailKeyPersonalModel[] = []

  @jsonMember({constructor: Boolean})
  isDcaa: boolean = false

  @jsonArrayMember(String)
  groups: string[] = []

  @jsonArrayMember(String)
  setAsides: string[] = []

  @jsonMember({constructor: String})
  govConName: string = ''

  @jsonMember({constructor: String})
  sizeName: string = ''

  @jsonArrayMember(() => EndingSoonModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  productEndingSoon: EndingSoonModel[] = []

  @jsonArrayMember(() => EndingSoonModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  serviceEndingSoon: EndingSoonModel[] = []

  @jsonArrayMember(() => DetailCustomerDataModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  civilian: DetailCustomerDataModel[] = []

  @jsonArrayMember(() => DetailCustomerDataModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  defense: DetailCustomerDataModel[] = []

  @jsonArrayMember(() => DetailServiceLineModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  productLine: DetailServiceLineModel[] = []

  @jsonArrayMember(() => DetailServiceLineModel, {deserializer: (json) => CompanyProfileDetailModel.chartDataDeserializer(json)})
  serviceLine: DetailServiceLineModel[] = []

  @jsonMember({constructor: String})
  name: string = ''

  @jsonMember({constructor: String})
  websiteUrl: string = ''

  @jsonMember({constructor: String})
  popularPsc: string = ''

  @jsonMember({constructor: String})
  popularPscCode: string = ''

  @jsonMember({constructor: String})
  categorizationType: string = ''
}

@jsonObject
export class CompanyProfileDetailResponseModel extends BaseResponseModel {
  @jsonMember({constructor: CompanyProfileDetailModel})
  data?: CompanyProfileDetailModel = undefined
}

@jsonObject
export class CompanyProfileRankedModel {
  @jsonMember({constructor: String})
  name: string = ''
  @jsonMember({constructor: String})
  description: string = ''
  @jsonMember({constructor: String})
  url: string = ''
}

@jsonObject
export class CompanyProfileWithRankedModel extends CompanyProfilePreviewModel {
  @jsonMember({ constructor: CompanyProfileRankedModel })
  cached: CompanyProfileRankedModel = new CompanyProfileRankedModel()
}

@jsonObject
export class CompanyProfileRankedResponseModel {
  @jsonArrayMember(CompanyProfileWithRankedModel)
  data: CompanyProfileWithRankedModel[] = []
  @jsonMember({constructor: Number})
  count: number = 0
}

@jsonObject
export class CompanyProfileGroupedResponseModel {
  @jsonMember({constructor: CompanyProfilePreviewModelResponse})
  items: CompanyProfilePreviewModelResponse = new CompanyProfilePreviewModelResponse()
  @jsonMember({constructor: CompanyProfileRankedResponseModel})
  rankedItems: CompanyProfileRankedResponseModel = new CompanyProfileRankedResponseModel()
}

@jsonObject
export class CompanyProfileClusteringResponseModel extends BasePaginationModel {
  @jsonMember({constructor: CompanyProfileGroupedResponseModel})
  data: CompanyProfileGroupedResponseModel = new CompanyProfileGroupedResponseModel()
}
