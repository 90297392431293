import { jsonMember, jsonObject } from 'typedjson'
import { IStateModel } from './state.interface'

@jsonObject
export class StateModel implements IStateModel {
  @jsonMember({ constructor: Number })
  id?: number = undefined

  @jsonMember({ constructor: String })
  code: string = ''

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Number })
  regionId?: number = undefined
}