import { IDateModel, IDueTimelineModel, IDueTimelineResponseModel } from './due-timeline.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class DateModel implements IDateModel {
  @jsonMember({ constructor: String })
  date: string = ''

  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: String })
  hint?: string
}

@jsonObject
export class DueTimelineModel implements IDueTimelineModel {
  @jsonArrayMember(DateModel)
  dates: DateModel[] = []

  @jsonMember({ constructor: Number })
  currentActive?: number

  @jsonMember({ constructor: String })
  due: string = ''
}

@jsonObject
export class DueTimelineResponseModel extends BaseResponseModel implements IDueTimelineResponseModel {
  @jsonMember({ constructor: DueTimelineModel })
  data?: DueTimelineModel
}


@jsonObject
export class TimelineResponseModel extends BaseResponseModel {
  @jsonArrayMember(DateModel)
  data: DateModel[] = []
}