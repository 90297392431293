import {  Grid, Paper, Typography, IconButton, CircularProgress } from '@material-ui/core'
import React, {useState} from 'react'
import styles from '../index.module.scss'
import SvgIcon from 'components/SvgIcon'
import { useDispatch } from 'react-redux'
import { clearContactsList, getContactsChart, uploadFile } from 'store/hp/education/action'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'


const FileUploader = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const [isLaoding, setLoading] = useState<boolean>(false)

  const onFileChange = async (e: { target: { files: any } }) => {
    let file = e.target.files![0]
    const formData = new FormData()
    formData.append('file', file)
    setLoading(true)
    uploadFile(formData)
      .then(() => {
        setLoading(false)
        clearContactsList(dispatch)
        getContactsChart(dispatch)
      })
      .catch(e => {
        setLoading(false)
        notification.show(getErrorMessage(e), NotificationTypeList.Error)
      })
  }

  return (
    <Grid container justifyContent='center'>
      <Paper className={styles['file-uploader']}>
        {
          !isLaoding && (
            <>
              <Typography variant='h5'>Upload your LinkedIn relationships file:</Typography>
              <Grid 
                container 
                direction='column' 
                alignItems='center' 
                justifyContent='center' 
                className={styles['download-block']}
              >
                <IconButton color='primary' aria-label='upload csv' component='label'>
                  <input hidden accept='.csv' type='file' onChange={onFileChange} />
                  <SvgIcon name='download-alt' width={100} height={100}/>
                </IconButton>
              </Grid>
            </>
          )
        }
        {
          isLaoding && (
            <Grid className={styles['file-uploader-loader']} container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )
        }
      </Paper>
    </Grid>
   
  )
}

export default FileUploader