import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Sap from './Sap'
import ExpiringContracts from './ExpiringContracts'
import SimilarCompany from './SimilarCompanies'
import Codes from './Codes'
import Keywords from './Keywords'

const MarketResearchTools = () => {
  return (
    <Switch>
      <Route path='/user/market-research/tools/sap'>
        <Sap />
      </Route>
      <Route path='/user/market-research/tools/expiring-contracts'>
        <ExpiringContracts />
      </Route>
      <Route path='/user/market-research/tools/keywords'>
        <Keywords />
      </Route>
      <Route path='/user/market-research/tools/similar-company'>
        <SimilarCompany/>
      </Route>
      <Route path='/user/market-research/tools/codes'>
        <Codes />
      </Route>
    </Switch>
  )
}

export default MarketResearchTools