import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import ItemBlock from '../components/ItemBlock'

const SelfEvaluation = () => {

  const history = useHistory()

  return (
    <>
      <Typography variant='h3' gutterBottom>Self Evaluation</Typography>
      <Grid container spacing={3} justifyContent='space-between' alignItems='stretch'>
        <Grid item md={4}>
          <ItemBlock title='Market Overview' icon='map' onClick={() => history.push('user/market-research')}>
            <Typography variant='h5'>Rapidly access a new product or service</Typography>
            <ul>
              <li><Typography>Best keywords, PSC, & NAICS codes</Typography></li>
              <li><Typography>Customers & Points of contact</Typography></li>
              <li><Typography>Market size</Typography></li>
            </ul>
          </ItemBlock>
        </Grid>
        <Grid item md={4}>
          <ItemBlock title='Market Research Tools' icon='key-skeleton'>
            <Typography variant='h5'>Discover and identify what matters to your company the most</Typography>
            <ul>
              <li><Link to='/user/market-research/tools/keywords'>Keyword identifier tool</Link></li>
            </ul>
          </ItemBlock>
        </Grid>
        <Grid item md={4}>
          <ItemBlock title='Customer Finder' icon='question-circle'>
            <ul>
              <li><Link to='/education/relationship-map'>Relationship map</Link></li>
              <li><Link to='/education/customer-finder'>Customer Finder</Link></li>
              <li><Link to='/education/partners-finder'>Partners Finder</Link></li>
            </ul>
          </ItemBlock>
        </Grid>
      </Grid>
    </>
  )
}

export default SelfEvaluation
