import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { MIN_PASSWORD_LEN } from 'utils/const'

export interface ISignInForm {
  email: string
  password: string
}

export interface ICreateLazyAccountForm {
  email: string
}

export const SIGN_IN_FORM_INIT_VALUE: ISignInForm = {
  email: '',
  password: ''
}

export const CREATE_LAZY_ACCOUNT_FORM_INIT_VALUE: ICreateLazyAccountForm = {
  email: ''
}

export const SIGN_IN_FORM_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Email'))
    .email(ERROR_MESSAGES.Field.NotValid('Email')),
  password: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Password'))
    .min(MIN_PASSWORD_LEN, ERROR_MESSAGES.Field.MinLen('Password', MIN_PASSWORD_LEN))
})

export const CREATE_LAZY_ACCOUNT_FORM_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Email'))
    .email(ERROR_MESSAGES.Field.NotValid('Email'))
})