import React from 'react'
import styles from '../index.module.scss'
import { Button, Grid, Paper, Radio, Typography } from '@material-ui/core'
import KeywordsFilter from '../../Filters/KeywordsFilter'
import SvgIcon from 'components/SvgIcon'
import { IFilters } from 'modules/Main/User/MarketResearchTools/Keywords/index'
import SearchKeywords from './SearchKeywords'

interface Props {
  filters: IFilters
  keywords: string[]
  handleChangeFilters: (filters: IFilters) => void
  findItems: () => void
  handleSaveKeywords: () => void
}

const Filters = ({ filters, handleChangeFilters, findItems, keywords, handleSaveKeywords }: Props) => {
  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeFilters({type: e.target.value})
  }
  const handleChangeKeywords = (idx: number) => (keyword: string) => {
    const keywords = [...filters.keywords!]
    keywords[idx] = keyword
    handleChangeFilters({ keywords })
  }
  const handleChangeKeywordsArray = (keywords: string[]) => {
    const k = [...filters.keywords!]
    keywords.forEach((keyword, idx) => {
      k[idx] = keyword
    })
    handleChangeFilters({ keywords: k })
  }

  return (
    <Paper className={styles['paper']}>
      <Typography variant='h4' gutterBottom>Keyword analysis</Typography>
      <Typography variant='h5' gutterBottom>Are you selling a:</Typography>
      <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
        <Grid item className={styles['centered-content']}>
          <SvgIcon name='box' width={40} height={30} />
          <Typography>Product</Typography>
          <Radio
            name='type-selection'
            checked={filters.type === 'product'}
            onChange={handleChangeType}
            value='product'
            color='primary'
          />
        </Grid>
        <Grid item className={styles['centered-content']}>
          <SvgIcon name='setting' width={40} height={30} />
          <Typography>Service</Typography>
          <Radio
            name='type-selection'
            checked={filters.type === 'service'}
            onChange={handleChangeType}
            value='service'
            color='primary'
          />
        </Grid>
        <Grid item className={styles['centered-content']}>
          <SvgIcon name='search' width={40} height={30} />
          <Typography>R&D</Typography>
          <Radio
            name='type-selection'
            checked={filters.type === 'r&d'}
            onChange={handleChangeType}
            value='r&d'
            color='primary'
          />
        </Grid>
      </Grid>
      <div style={{height: '16px'}}/>
      <SearchKeywords
        handleSelectKeywords={handleChangeKeywordsArray}
      />
      <div style={{height: '16px'}}/>
      <Typography variant='h5' gutterBottom>Enter keywords:</Typography>
      {filters.keywords?.map((keyword, idx) => (
        <div key={idx}>
          <KeywordsFilter
            handleChange={handleChangeKeywords(idx)}
            handleSearch={() => {}}
            keywords={[keyword]}
          />
          <div style={{height: '16px'}}/>
        </div>
      ))}
      <Button
        color='primary'
        variant='contained'
        fullWidth
        onClick={findItems}
        disabled={!filters.type || filters.keywords?.every(keyword => !keyword)}
      >
        Analyze
      </Button>
      <div style={{height: '12px'}} />
      <Button
        color='primary'
        variant='outlined'
        fullWidth
        onClick={handleSaveKeywords}
        disabled={!keywords.length}
      >
        Save these keywords
      </Button>
    </Paper>
  )
}

export default Filters