import {
  IFpRfpDetailResponseModel,
  IFpRfpModelResponseModel
} from 'models/fp/rfp/rfp.interface'
import {
  FpRfpDetailResponseModel,
  FpRfpModelResponseModel,
} from 'models/fp/rfp/rfp.model'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'
import { sortDirections } from 'common/sort'
import { IHpRfpChatResponseModel } from '../../../models/hp/rfp/rfp.interface'
import { HpRfpChatResponseModel } from '../../../models/hp/rfp/rfp.model'

export const FP_RFP_LIST = 'FP_RFP_LIST'
export const FP_RFP_LIST_FILTER = 'FP_RFP_LIST_FILTER'
export const FP_RFP_FILTER = 'FP_RFP_FILTER'
export const FP_RFP_DETAILS = 'FP_RFP_DETAILS'

export interface IRfpFilter {
  searchId?: number
  limit?: number
  page?: number
  date?: sortDirections
  title?: sortDirections
}

export const getRfpFilteredListAction = async (params: IRfpFilter): Promise<IFpRfpModelResponseModel> => {
  try {
    const response = await request<IFpRfpModelResponseModel>(RequestMethods.Get, Paths.Fp.Rfp.getFiltered, {
      schema: FpRfpModelResponseModel,
      params
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getRfpDetailAction = async (id: string): Promise<IFpRfpDetailResponseModel> => {
  try {
    const response = await request<IFpRfpDetailResponseModel>(RequestMethods.Get, Paths.Fp.Rfp.getById(id), {
      schema: FpRfpDetailResponseModel
    })

    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getFpRfpDiscussMessages = async (
  id: string, params: {limit: number, offset: number}
): Promise<IHpRfpChatResponseModel> => {
  try {
    const response = await request<IHpRfpChatResponseModel>(RequestMethods.Get, Paths.Fp.RfpDiscuss.messages(id), {
      schema: HpRfpChatResponseModel,
      params
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const sendFpRfpDiscussMessages = async (
  id: string, message: string
): Promise<void> => {
  try {
    await request(RequestMethods.Post, Paths.Fp.RfpDiscuss.messages(id), {
      body: {value: message}
    })
  } catch (error) {
    throw error.response.data.errors
  }
}

export const fpRfpSetListingAction = (rfpData: IFpRfpModelResponseModel) => ({
  type: FP_RFP_LIST,
  payload: { ...rfpData }
})

export const fpRfpListingFilterAction = (filterParams: IRfpFilter) => ({
  type: FP_RFP_LIST_FILTER,
  payload: { filterParams }
})

export const fpRfpSetListingByFilterAction = (rfpData: IFpRfpModelResponseModel) => ({
  type: FP_RFP_FILTER,
  payload: { ...rfpData }
})

export const fpRfpSetDetailsAction = (rfpData: IFpRfpDetailResponseModel) => ({
  type: FP_RFP_DETAILS,
  payload: { ...rfpData }
})
