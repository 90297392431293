import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { getSearchSettings, updateSearchSettings } from 'store/hp/search/actions'
import { SearchSetting } from 'models/hp/search/search.model'
import { Grid, Typography } from '@material-ui/core'
import CustomLoader from 'components/CustomLoader'
import Select from 'components/v2/Select'
import { newItemsNotificationTitles, newItemsNotificationValues } from 'helpers/search'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'

const SearchNotifications = () => {
  const notification = useNotification()

  const [loading, setLoading] = React.useState(false)
  const [settings, setSettings] = React.useState<SearchSetting[]>([])

  const handleChange = (setting: SearchSetting, value: string) => {
    setSettings(prev => prev.map(prevSetting => {
      if (prevSetting.id === setting.id) {
        prevSetting.newSearchItemsNotification = value
      }
      return prevSetting
    }))

    updateSearchSettings(setting.id, { newSearchItemsNotification: getValue(value) })
      .then(() => notification.show('Search notification frequency updated successfully', NotificationTypeList.Success))
      .catch((e) => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  const getValue = (val: string) => {
    if (val === newItemsNotificationValues.disable) {
      return null
    }
    return val
  }

  useEffect(() => {
    setLoading(true)
    getSearchSettings()
      .then(setSettings)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <Grid container>
        <Grid item md={4}>
          <CustomLoader size={24} />
        </Grid>
      </Grid>
    )
  }

  if (settings.length === 0) { // return nothing if no searches found yet
    return null
  }

  return (
    <Grid container direction='column'>
      <Grid container alignContent='center'>
        <Grid item md={6}>
          <Typography variant='h5' gutterBottom>Search name:</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' gutterBottom>Frequency:</Typography>
        </Grid>
      </Grid>
      {settings.map(setting => (
        <Grid container alignItems='center' key={setting.id}>
          <Grid item md={6}>
            <Typography variant='h5' className={styles['title']}>{setting.search.name}:</Typography>
          </Grid>
          <Grid item className={styles['custom-select']}>
            <Select
              label=''
              value={setting.newSearchItemsNotification === null ? 'null' : setting.newSearchItemsNotification}
              options={Object.entries(newItemsNotificationValues).map(([key, value]) => ({
                key: newItemsNotificationTitles[key],
                value: value
              }))}
              onChange={(e) => handleChange(setting, e.target.value as string)}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default SearchNotifications