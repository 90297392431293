import React from 'react'
import styles from '../index.module.scss'
import { FormikProps } from 'formik'
import { IProfile } from './Form'
import { FormControl, Button } from '@material-ui/core'
import TextField from 'components/v2/TextField'
import ButtonWithLoader from 'components/ButtonWithLoader'

interface Props extends FormikProps<IProfile> {
  isRequested: boolean
  cancelUpdate: () => void
}

const FormRenderer = ({ values, handleSubmit, handleChange, handleBlur, isValid, touched, errors, isRequested, cancelUpdate }: Props) => {
  return (
    <form onSubmit={handleSubmit} className={styles['form']}>
      <FormControl className={styles['input-control']}>
        <TextField
          label='First Name'
          variant='filled'
          onChange={handleChange('firstName')}
          onBlur={handleBlur('firstName')}
          value={values.firstName}
          error={!!touched.firstName && !!errors.firstName}
          helperText={errors.firstName}
        />
      </FormControl>
      <FormControl className={styles['input-control']}>
        <TextField
          label='Last Name'
          variant='filled'
          className={styles['input']}
          onChange={handleChange('lastName')}
          onBlur={handleBlur('lastName')}
          value={values.lastName}
          error={!!touched.lastName && !!errors.lastName}
          helperText={errors.lastName}
        />
      </FormControl>
      <ButtonWithLoader
        isRequested={isRequested}
        type='submit'
        disabled={!isValid}
        variant='contained'
        color='primary'
      >
        Update
      </ButtonWithLoader>
      <Button
        type='submit'
        variant='outlined'
        color='primary'
        onClick={cancelUpdate}
        style={{marginLeft: '4px'}}
      >
        Cancel
      </Button>
    </form>
  )
}

export default FormRenderer
