import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import styles from '../index.module.scss'

interface Route {
  title: string
  route: string
}

const ROUTES: Array<Route> = [{
  title: 'Users', route: '/owner/users'
}, {
  title: 'Parser tokens', route: '/owner/tokens'
}, {
  title: 'Contracts', route: '/owner/contracts'
}, {
  title: 'Feedback', route: '/owner/feedback'
}]

const Menu = () => {
  const history = useHistory()

  return (
    <div>
      <Grid container>
        {ROUTES.map(route => (
          <Grid
            className={route.route === history.location.pathname ? styles['active-tab'] : ''}
            item
            key={route.route}>
            <Link to={route.route}>
              <Button variant={'text'}>{route.title}</Button>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>

  )
}

export default Menu