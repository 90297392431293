import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import GanttChart from './components/GanttChart/GanttChart'
import {Typography} from '@material-ui/core'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { getPiplineTimeline } from 'store/hp/pipeline/rfp/action'
import { pipelineTimelineCountSelector, pipelineTimelineSelector } from 'store/hp/pipeline/rfp/selectors'
import { Pagination } from '@material-ui/lab'
import TimelineSelect from './components/Select'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import NotAuthorizedDialog from 'modules/Main/Hp/RfpInnerRoutesNew/components/NotAuthorizedDialog'
import { useHistory } from 'react-router-dom'

export enum DateType {
  week = 'Week',
  month = 'Month',
  quarter = 'Quarter'
} 

const PiplineTimeline = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(userSelector) as IUserBaseModel
  const {data, loaded, loading} = useSelector(pipelineTimelineSelector)
  const count = useSelector(pipelineTimelineCountSelector)
  const paginationLimit = 10

  const [dateType, setDateType] = useState<DateType>(DateType.week)
  const [page, setPage] =  useState<number>(0)
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false)

  useEffect(() => {
    if(user) {
      getPiplineTimeline(dispatch)()
    } else {
      setOpenDialog(true)
    }
  }, [])

  const handlePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1)
    getPiplineTimeline(dispatch)(page - 1)
  }

  const handleClose = () => {
    history.push('/contracts')
  }

  return (
    <div className={styles['timeline-wrapper']}>
      <div className={styles['timeline-header']}>
        <Typography className={styles['timeline-header-title']}>Liked Projects</Typography>
        <div className={styles['timeline-header-controls']}>
          <div className={styles['timeline-header-controls-statuses']}>
            <div className={styles['timeline-header-controls-status']}>
              <div className={classNames(styles['timeline-header-controls-status-cube'], styles['pre-rfi'])} />
              <Typography className={styles['timeline-header-controls-status-label']}>Pre-RFI/SS Capture</Typography>
            </div>
            <div className={styles['timeline-header-controls-status']}>
              <div className={classNames(styles['timeline-header-controls-status-cube'], styles['rfi'])} />
              <Typography className={styles['timeline-header-controls-status-label']}>RFI/SS Response</Typography>
            </div>
            <div className={styles['timeline-header-controls-status']}>
              <div className={classNames(styles['timeline-header-controls-status-cube'], styles['pre-rfp'])} />
              <Typography className={styles['timeline-header-controls-status-label']}>Pre-RFP Capture</Typography>
            </div>
            <div className={styles['timeline-header-controls-status']}>
              <div className={classNames(styles['timeline-header-controls-status-cube'], styles['rfp'])} />
              <Typography className={styles['timeline-header-controls-status-label']}>RFP Response</Typography>
            </div>
          </div>
          <TimelineSelect dateType={dateType} setDateType={setDateType} />
        </div>
      </div>
      <GanttChart 
        data={data.data}
        isLoading={loading || !loaded}
        dateType={dateType}
        page={page}
      />
      {count > paginationLimit ? (
        <div className={styles['gantt-grid-pagination']}>
          <Pagination
            count={Math.ceil(count / paginationLimit)}
            color="primary"
            onChange={handlePagination}
          />
        </div>
      ) : null}
      <NotAuthorizedDialog isOpen={isOpenDialog} user={user} handleClose={handleClose} />
    </div>
  )
}

export default PiplineTimeline