import React from 'react'
import styles from '../../index.module.scss'
import {
  Button,
  Grid, Hidden,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { FormikProps } from 'formik'
import { ISignUpForm } from './Form'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import TextField from 'components/v2/TextField'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import SignInTextLink from 'components/SignInTextLink'
import { Link } from 'react-router-dom'

interface IFormRenderProps extends FormikProps<ISignUpForm> {
  isRequested: boolean
}

const FormRenderer = ({ values, handleSubmit, handleChange, handleBlur, touched, errors, isRequested }: IFormRenderProps) => {

  return (
    <form onSubmit={handleSubmit} className={styles['form-wrapper']}>
      <Grid container className={styles['form-block']}>
        <Hidden smDown>
          <Grid item className={styles['form-left-block']} md={6}>
            <LeftBlock />
          </Grid>
        </Hidden>
        <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
          <div className={styles['form-block-right-inner']}>
            <div className={styles['logo']}>
              <img src={LogoWithText} alt='logo'/>
            </div>
            <Grid container justifyContent='center' className={styles['form-subtitle']}>
              <Typography variant='h4'>Create your account</Typography>
            </Grid>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('firstName')}
                error={!!touched.firstName && !!errors.firstName}
                helperText={!!touched.firstName && !!errors.firstName && errors.firstName}
                value={values.firstName}
                onChange={handleChange('firstName')}
                className={styles['field']}
                label='First Name'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('lastName')}
                error={!!touched.lastName && !!errors.lastName}
                helperText={!!touched.lastName && !!errors.lastName && errors.lastName}
                value={values.lastName}
                onChange={handleChange('lastName')}
                className={styles['field']}
                label='Last Name'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('email')}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && !!errors.email && errors.email}
                value={values.email}
                onChange={handleChange('email')}
                className={styles['field']}
                label='Email Address'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('password')}
                error={!!touched.password && !!errors.password}
                helperText={!!touched.password && !!errors.password && errors.password}
                value={values.password}
                onChange={handleChange('password')}
                className={styles['field']}
                label='Password'
                type='password'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <Grid container justifyContent='center'>
                <Grid item>
                  <Typography>By clicking "Sign up" you agree to our</Typography>
                  <Typography>
                    <Link to='/policies/privacy'>Terms of Services</Link> and <Link to='/policies/privacy'>Privacy Policy</Link>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Button
              className={styles['submit']}
              variant='contained'
              color='primary'
              fullWidth
              disabled={isRequested}
              type='submit'
            >
              {
                isRequested ? (
                  <CircularProgress color='primary' size={30} />
                ) : (
                  <span>Sign up</span>
                )
              }
            </Button>
            <SignInTextLink/>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer