import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from '../../base/base.model'
import { AwardListItem } from 'models/hp/awards/awards.model'

@jsonObject
export class MarketSizeBySetAside {
  @jsonMember({constructor: String})
  typeOfSetAsideDescription: string = ''

  @jsonMember({constructor: Number})
  contractsCount: number = 0

  @jsonMember({constructor: Number})
  marketSize: number = 0
}

@jsonObject
export class MarketSize {
  @jsonMember({constructor: Number})
  totalMarketSize: number = 0

  @jsonMember({constructor: Number})
  contractsCount: number = 0

  @jsonArrayMember(MarketSizeBySetAside)
  marketSizeBySetAsides: MarketSizeBySetAside[] = []
}

@jsonObject
export class SapOfficeInnerItem {
  @jsonMember({constructor: String})
  awardDescription: string = ''

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  potentialTotalValueOfAward: number = 0
}

@jsonObject
export class SapOfficeItem {
  @jsonMember({ constructor: String })
  agency: string = ''

  @jsonMember({ constructor: String })
  subAgency: string = ''

  @jsonMember({ constructor: String })
  office: string = ''

  @jsonArrayMember(SapOfficeInnerItem)
  items: SapOfficeInnerItem[] = []
}

@jsonObject
export class SapItem {
  @jsonMember({
    constructor: String,
    deserializer: (val) => val.split(' ').map((x: string) => x.charAt(0) + x.slice(1).toLowerCase()).join(' ')
  })
  state: string = ''

  @jsonMember({ constructor: Number })
  sap: number = 0

  @jsonMember({ constructor: Number })
  micro: number = 0

  @jsonArrayMember(SapOfficeItem)
  offices: SapOfficeItem[] = []
}

@jsonObject
export class ContractItem {
  @jsonMember({constructor: String})
  title: string = ''

  @jsonMember({constructor: Number})
  count: number = 0

  @jsonMember({constructor: Number})
  amount: number = 0

  @jsonArrayMember(AwardListItem)
  items: AwardListItem[] = []
}

@jsonObject
export class MarketSapResponseModel extends BaseResponseModel {
  @jsonArrayMember(SapItem)
  data: SapItem[] = []
}

@jsonObject
export class MarketSizeResponseModel extends BaseResponseModel {
  @jsonMember({constructor: MarketSize})
  data: MarketSize = new MarketSize()
}

@jsonObject
export class MarketSetAsideResponse extends BaseResponseModel {
  @jsonArrayMember(Number)
  data: number[] = []
}

@jsonObject
export class MarketContractsResponseModel extends BaseResponseModel {
  @jsonArrayMember(ContractItem)
  data: ContractItem[] = []
}

@jsonObject
export class SimilarCompanyDetailedItem {
  @jsonMember({
    constructor: String
  })
  value: string = ''

  @jsonMember({
    constructor: String,
    deserializer: (val) => val.split(' ').map((x: string) => x.charAt(0) + x.slice(1).toLowerCase()).join(' ')
  })
  name: string = ''
}

@jsonObject
export class SimilarCompanyItem {
  @jsonMember({
    constructor: String,
    deserializer: (val) => val.split(' ').map((x: string) => x.charAt(0) + x.slice(1).toLowerCase()).join(' ')
  })
  name: string = ''

  @jsonMember({constructor: String})
  duns: string = ''

  @jsonArrayMember(SimilarCompanyDetailedItem)
  items: SimilarCompanyDetailedItem[] = []
}

@jsonObject
export class MarketCompanyResponseModel extends BaseResponseModel {
  @jsonArrayMember(SimilarCompanyItem)
  data: SimilarCompanyItem[] = []
}

@jsonObject
export class MarketCodeItem {
  @jsonMember({constructor: String})
  code: string = ''

  @jsonMember({constructor: String})
  name: string = ''
}


@jsonObject
export class MarketCodesGroupedByType {
  @jsonArrayMember(MarketCodeItem)
  naicsCodes: MarketCodeItem[] = []

  @jsonArrayMember(MarketCodeItem)
  pscCodes: MarketCodeItem[] = []
}

@jsonObject
export class MarketCodesGroupedResponseModel {
  @jsonMember({constructor: MarketCodesGroupedByType})
  matched: MarketCodesGroupedByType = new MarketCodesGroupedByType()

  @jsonMember({constructor: MarketCodesGroupedByType})
  related: MarketCodesGroupedByType = new MarketCodesGroupedByType()
}


@jsonObject
export class MarketCodesResponseModel extends BaseResponseModel {
  @jsonMember({constructor: MarketCodesGroupedResponseModel})
  data: MarketCodesGroupedResponseModel = new MarketCodesGroupedResponseModel()
}
