import React from 'react'
import styles from './index.module.scss'
import { Typography, useTheme } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'

const SignInTextLink = () => {
  const theme = useTheme()
  const history = useHistory()
  return (
    <div className={styles['login-wrapper']}>
      <Typography variant='body1' className={styles['helper-text']}>
        Have an account?
      </Typography>
      <Link style={{ color: theme.palette.info.main }} className={styles['signin-link']} to={{ search: history.location.search, pathname: '/auth/sign-in' }}>
        Sign in
      </Link>
    </div>
  )
}

export default SignInTextLink