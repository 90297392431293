import { Dispatch } from 'redux'

export const SET_AWARD_SEARCH_FILTER_KEYWORD = 'SET_AWARD_SEARCH_FILTER_KEYWORD'
export const SET_AWARD_SEARCH_FILTER_VALUE_BOOL = 'SET_AWARD_SEARCH_FILTER_VALUE_BOOL'
export const SET_AWARD_SEARCH_FILTER_VALUE = 'SET_AWARD_SEARCH_VALUE'
export const SET_AWARD_SEARCH_FILTER_VALUES = 'SET_AWARD_SEARCH_FILTER_VALUES'
export const REMOVE_AWARD_SEARCH_FILTER_VALUE = 'REMOVE_AWARD_SEARCH_FILTER_VALUE'
export const REMOVE_AWARD_SEARCH_FILTER_VALUES = 'REMOVE_AWARD_SEARCH_FILTERS_VALUE'
export const RESET_AWARD_SEARCH_FILTERS = 'RESET_AWARD_SEARCH_FILTERS'

export const setAwardSearchFilterKeyword = (dispatch: Dispatch) => (value: string[]) => {
  dispatch({
    type: SET_AWARD_SEARCH_FILTER_KEYWORD,
    payload: {value}
  })
}

export const setAwardSearchFilterValueBool = (dispatch: Dispatch) => (key: string, value?: boolean | string | number | string[] | number[]) => {
  dispatch({
    type: SET_AWARD_SEARCH_FILTER_VALUE_BOOL,
    payload: {key, value}
  })
}

export const setAwardSearchFilterValue = (dispatch: Dispatch) => (key: string, value: number | string) => {
  dispatch({
    type: SET_AWARD_SEARCH_FILTER_VALUE,
    payload: {key, value}
  })
}

export const setAwardSearchFilterValues = (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  dispatch({
    type: SET_AWARD_SEARCH_FILTER_VALUES,
    payload: {key, value}
  })
}

export const removeAwardSearchFilterValue = (dispatch: Dispatch) => (key: string, value: string | number) => {
  dispatch({
    type: REMOVE_AWARD_SEARCH_FILTER_VALUE,
    payload: {key, value}
  })
}

export const removeAwardSearchFilterValues = (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  dispatch({
    type: REMOVE_AWARD_SEARCH_FILTER_VALUES,
    payload: {key, value}
  })
}

export const resetAwardSearchFilters = (dispatch: Dispatch) => () => {
  dispatch({
    type: RESET_AWARD_SEARCH_FILTERS
  })
}
