import { GeneralCalcResponseModel } from 'models/common/calculators/general/general.model'
import { Dispatch } from 'redux'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'

export const GET_GENERAL_CALC = 'GET_GENERAL_CALC'
export const GET_GENERAL_CALC_SUCCESS = 'GET_GENERAL_CALC_SUCCESS'
export const CLEAR_GENERAL_CALC = 'CLEAR_GENERAL_CALC'
  
export const getGeneralCalc = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({
      type: GET_GENERAL_CALC,
    })
    const response = await request<GeneralCalcResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.General.getGeneral(rfpId), {
      schema: GeneralCalcResponseModel
    })
    dispatch({
      type: GET_GENERAL_CALC_SUCCESS,
      payload: response.data.data!
    })
  } catch (error) {
    throw error.response.data.errors
  }
}