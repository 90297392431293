import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { naicsCodeCountSelector, naicsCodesSelector } from 'store/hp/grouped-code/selectors'
import {
  selectedNaicsOptions as selectedNaicsOptionsAction
} from 'store/hp/awards/search/selectors'
import {
  setAwardSearchFilterValue,
  removeAwardSearchFilterValue,
  setAwardSearchFilterValues,
  removeAwardSearchFilterValues
} from 'store/hp/awards/search/actions'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'
import styles from './index.module.scss'
import { searchNaicsCodesAction } from 'store/hp/grouped-code/actions'
import { FilterOption } from 'models/hp/search/search.model'
import { getChildrenIds } from 'helpers/filter-option'

let searchDebounceTimer: number | null = null

const NaicsFilter = () => {
  const dispatch = useDispatch()

  const naicsCodesOptions: IFilterOption[] = useSelector(naicsCodesSelector) as IFilterOption[]
  const selectedNaicsOptions: string[] = useSelector(selectedNaicsOptionsAction)
  const naicsCount: number = useSelector(naicsCodeCountSelector) as number
  const [options, setOptions] = React.useState<FilterOption[]>([])

  const handleChange = (id: number | string, state: boolean) => {
    if (state) {
      setAwardSearchFilterValue(dispatch)('naicsCodes', id)
    } else {
      removeAwardSearchFilterValue(dispatch)('naicsCodes', id)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setAwardSearchFilterValues(dispatch)('naicsCodes', values)
    } else {
      removeAwardSearchFilterValues(dispatch)('naicsCodes', values)
    }
  }

  const loadOptions = async (value: string) => {
    const response = await searchNaicsCodesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const onSelect = (state: boolean, value: IFilterOption | null) => {
    if (value && value.sub?.length === 0) {
      handleChange(value.id, state)
    } else if (value && value.sub && value.sub.length > 0) {
      const childIds: number[] = []
      getChildrenIds(value.sub, (id: number) => {
        childIds.push(id)
      })
      handlePartialChange(childIds, state)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        searchLabel='NAICS search'
        allCount={naicsCount}
        iconType='naics'
        filterTitle='Industry Area (NAICS)'
        filterOptions={naicsCodesOptions}
        selectedFilterOptions={selectedNaicsOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default NaicsFilter