import React from 'react'
import ContractSearchVideoThumbnail from 'assets/images/contract-search-video-thumbnail.png'
import MarketResearchIntro from 'assets/images/market_research_an_overview.jpeg'
import MarketResearchName from 'assets/images/market_research_name_tutorial.jpeg'
import MarketResearchChooseKeywords from 'assets/images/market_research_choose_keywords.jpeg'
import MarketResearchSelectKeywords from 'assets/images/market_research_select_keywords.jpeg'
import MarketResearchValidate from 'assets/images/market_research_validate.jpeg'
import MarketResearchCodes from 'assets/images/market_research_codes.jpeg'
import MarketResearchLocation from 'assets/images/market_research_location.jpeg'
import MarketResearchCustomers from 'assets/images/market_research_customers.jpeg'
import MarketResearchYourMarket from 'assets/images/market_research_your_market.jpeg'
import Modal from '../Modal'
import HelperVideo from './HelperVideo'
import { VideoSourceType } from 'utils/const'


interface Props {
  source?: VideoSourceType
}

const HelperVideoModal = ({ source }: Props) => {
  const [opened, setOpened] = React.useState(false)

  let coverImage = ContractSearchVideoThumbnail
  if (source) {
    switch (VideoSourceType[source]) {
      case VideoSourceType.MARKET_RESEARCH_INTRO:
        coverImage = MarketResearchIntro
        break
      case VideoSourceType.MARKET_RESEARCH_SELECT_KEYWORDS:
        coverImage = MarketResearchSelectKeywords
        break
      case VideoSourceType.MARKET_RESEARCH_VALIDATE_KEYWORDS:
        coverImage = MarketResearchValidate
        break
      case VideoSourceType.MARKET_RESEARCH_YOUR_MARKET:
        coverImage = MarketResearchYourMarket
        break
      case VideoSourceType.MARKET_RESEARCH_CHOOSING_KEYWORDS:
        coverImage = MarketResearchChooseKeywords
        break
      case VideoSourceType.MARKET_RESEARCH_CODES:
        coverImage = MarketResearchCodes
        break
      case VideoSourceType.MARKET_RESEARCH_NAME:
        coverImage = MarketResearchName
        break
      case VideoSourceType.MARKET_RESEARCH_LOCATION:
        coverImage = MarketResearchLocation
        break
      case VideoSourceType.MARKET_RESEARCH_CUSTOMER:
        coverImage = MarketResearchCustomers
        break
    }
  }
  return (
    <>
      <img
        style={{ marginTop: '15px', marginBottom: '20px', width: '100%', cursor: 'pointer' }}
        onClick={() => setOpened(true)}
        src={coverImage}
        alt='TutorialThumbnail'
      />
      {
        opened && (
          <Modal
            open={opened}
            onClose={() => setOpened(false)}
            size='lg'
            title=''
          >
            <HelperVideo source={source} />
          </Modal>
        )
      }
    </>
  )
}

export default HelperVideoModal