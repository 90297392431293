import React, { useEffect, useRef } from 'react'
import styles from '../index.module.scss'
import {
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button
} from '@material-ui/core'
import { getUsers, downloadUsersReport, downloadUserActivitiesReport } from 'store/common/owner/actions'
import { UserOwnerModel } from 'models/common/user/user.model'
import { Pagination } from 'models/base/base.interfaces'
import SvgIcon from 'components/SvgIcon'
import UserModal from './components/UserModal'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import ButtonWithLoader from 'components/ButtonWithLoader'

const tableHead: Array<{label: string, id: string, sortable?: true}> = [
  {
    id: 'id',
    label: 'Id',
    sortable: true
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'fullName',
    label: 'Full name'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'plan',
    label: 'Plan'
  },
  {
    id: 'createdAt',
    label: 'Account created'
  }
]

const Users = () => {
  const notification = useRef(useNotification())

  const [users, setUsers] = React.useState<UserOwnerModel[]>([])
  const [userCount, setUserCount] = React.useState<number>(0)

  const [query, setQuery] = React.useState('')
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20)
  const [page, setPage] = React.useState<number>(0)
  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [orderDirection, setOrderDirection] = React.useState<'ASC' | 'DESC'>('ASC')

  const [activitiesReportLoading, setActivitiesReportLoading] = React.useState(false)

  const [activeUser, setActiveUser] = React.useState<UserOwnerModel | null>(null)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleOrder = (key: string) => {
    if (key === orderBy) {
      const order = orderDirection === 'ASC' ? 'DESC' : 'ASC'
      requestUsers({
        page,
        limit: rowsPerPage,
        q: query,
        sort: { field: key, order }
      })
      setOrderDirection(order)
    } else {
      const order = 'ASC'
      requestUsers({
        page,
        limit: rowsPerPage,
        q: query,
        sort: { field: key, order }
      })
      setOrderBy(key)
      setOrderDirection(order)
    }
  }

  const runSearch = () => {
    requestUsers({
      page,
      limit: rowsPerPage,
      q: query,
      sort: { field: orderBy, order: orderDirection }
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    requestUsers({
      page: newPage,
      limit: rowsPerPage,
      q: query,
      sort: { field: orderBy, order: orderDirection }
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    requestUsers({
      page: 0,
      limit: Number.parseInt(event.target.value),
      q: query,
      sort: { field: orderBy, order: orderDirection }
    })
  }

  const requestUsers = async ({page, limit, q, sort}: Pagination) => {
    try {
      const data = await getUsers({ page, limit, q, sort })
      setUserCount(data.count)
      setUsers(data.data)
    }
    catch (e) {
      console.log(e)
    }
  }

  const onConfirm = () => {
    runSearch()
    notification.current.show(`Access provided to ${activeUser?.email}`, NotificationTypeList.Success)

    setActiveUser(null)
  }

  const onDelete = () => {
    runSearch()
    notification.current.show('Access deleted', NotificationTypeList.Success)

    setActiveUser(null)
  }

  const downloadReport = () => {
    downloadUsersReport()
  }

  const downloadActivitiesReport = () => {
    setActivitiesReportLoading(true)
    downloadUserActivitiesReport()
      .finally(() => setActivitiesReportLoading(false))
  }

  useEffect(() => {
    requestUsers({
      limit: rowsPerPage,
      q: query,
      page,
    })
  }, [])

  return (
    <div className={[styles['wrapper'], styles['users-wrapper']].join(' ')}>
      <Paper>
        <div className={styles['search-wrapper']}>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
              <InputBase
                placeholder='Search by email'
                onChange={handleChangeInput}
                onKeyPress={event => event.key === 'Enter' && runSearch()}
              />
              <IconButton type='submit' onClick={runSearch}>
                <SvgIcon name='search' />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                onClick={downloadReport}
              >Download Users</Button>
              <ButtonWithLoader
                variant='outlined'
                onClick={downloadActivitiesReport}
                isRequested={activitiesReportLoading}
              >Download User Activities</ButtonWithLoader>
            </Grid>
          </Grid>
        </div>
        <TableContainer>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                {tableHead.map(head => head.sortable ? (
                  <TableCell key={head.id}>
                    <TableSortLabel
                      active={orderBy === head.id}
                      direction={(orderBy === head.id ? orderDirection.toLowerCase() : 'asc') as 'asc' | 'desc'}
                      onClick={() => handleOrder(head.id)}
                    >
                      {head.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={head.id}>{head.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id} onClick={() => setActiveUser(user)} className={styles['table-row']}>
                  <TableCell component='th' scope='row'>
                    {user.id}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.firstName} {user.lastName}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>{user.paymentPlan?.plan || '---'}</TableCell>
                  <TableCell>{user.createdAt.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          page={page}
          count={userCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20, 40, 50]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {activeUser && (
        <UserModal
          user={activeUser}
          onClose={() => setActiveUser(null)}
          onConfirm={onConfirm}
          onDelete={onDelete}
        />
      )}
    </div>
  )
}

export default Users