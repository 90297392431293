import { CategorizedHpRfpModel, HpRfpModel } from 'models/hp/rfp/rfp.model'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'
import { IHpRfpDetailModel } from 'models/hp/rfp/rfp.interface'
import { IResourceLinkModel } from 'models/common/resource-link/resource-link.interface'
import { startsWithHttp } from 'helpers/string'

export const contractTypes = {
  combinedSynopsisSolicitation: 'Combined Synopsis/Solicitation',
  solicitation: 'Solicitation',
  awardNotice: 'Award Notice',
  presolicitation: 'Presolicitation',
  sourcesSought: 'Sources Sought',
  specialNotice: 'Special Notice',
  justification: 'Justification',
  saleOfSurplusProperty: 'Sale of Surplus Property',
  intentToBundleRequirements: 'Intent to Bundle Requirements',
  agencyForecast: 'Agency forecast',
  projected: 'Projected',

  // funding types
  sbirBoth: 'BOTH: SBIR',
  sttrBoth: 'BOTH: STTR',
  phase1Both: 'Phase I: BOTH',
  phase1Sttr: 'Phase I: STTR',
  phase1Sbir: 'Phase I: SBIR',
  phase2Sttr: 'Phase II: STTR',
  phase2Sbir: 'Phase II: SBIR',

  // custom types
  hotFill: 'Hot Fill', // Awards that may need subs (From API => ex-Awards)
  predicted: 'Predicted', // when users create contracts using EXCEL template
  reCompete: 'Re-compete', // Distant rfps calculated from historical awards,
}

export const shortenRfpTypes = (type: string) => {
  if ([contractTypes.solicitation, contractTypes.combinedSynopsisSolicitation].includes(type)) {
    return 'RFP'
  }
  return type
}

export const pipelineTypes = {
  distant: [
    contractTypes.reCompete,
    contractTypes.agencyForecast,
    contractTypes.projected
  ],
  open: [
    contractTypes.solicitation,
    contractTypes.combinedSynopsisSolicitation,
    contractTypes.specialNotice,
    contractTypes.hotFill,
    contractTypes.sbirBoth,
    contractTypes.sttrBoth,
    contractTypes.phase1Both,
    contractTypes.phase1Sttr,
    contractTypes.phase1Sbir,
    contractTypes.phase2Sttr,
    contractTypes.phase2Sbir,

  ],
  upcoming: [
    contractTypes.presolicitation,
    contractTypes.sourcesSought,
    contractTypes.intentToBundleRequirements
  ]
}

export const definePscType = (psc?: string) => {
  if (!psc) {
    return null
  }
  const strategy = [{
    regex: /^(\d{2,}.*)/gmi,
    value: 'Product'
  }, {
    regex: /^([A|B]).*/gmi,
    value: 'R&D or Special Study/Analysis'
  }, {
    regex: /^([C-Z]).*/gmi,
    value: 'Service'
  }]
  const item = strategy.find(strategyItem => strategyItem.regex.test(psc))
  return item ? item.value : null
}

export const getDetailsLink = (rfp: HpRfpModel | CategorizedHpRfpModel | RfpTinderModel, from?: string): string => {
  const solicitationNumber = encodeURIComponent(rfp.solicitationNumber)
  const title = encodeURIComponent(rfp.title.replace(/ /g, '-'))
  const id = rfp.id
  const detailsLink = `/contracts/federal-government-opportunity/overview/noticeId-${solicitationNumber}-title-${title}-id-${id}?`
  return [
    detailsLink,
    from ? `from=${from}` : undefined
  ].filter(x => !!x).join('')
}

/**
 * Function do the same as getDetailsLink but return evaluationFlag - contract can be evaluated by pipeline features
 * @param rfp
 * @param from
 */
export const getDetailsLinkWithEvaluationFlag = (rfp: HpRfpModel | CategorizedHpRfpModel | RfpTinderModel, from?: string): string => {
  const url = getDetailsLink(rfp)
  return [
    url,
    from ? `from=${from}&` : undefined,
    'evaluation=true'
  ].filter(x => !!x).join('')
}

export const getAwardDetailsLink = (externalId: string) => {
  return `/awards/contracts/overview/${externalId}`
}

export const getPSCToFTE = (psc?: string): number => {
  if (!psc) {
    return 80000
  }
  const letter = psc.substr(0, 1).toUpperCase()
  if (!/[A-Z]/.test(letter)) {
    return 0
  }
  const mapper: {[key: string]: number} = {
    'A': 166000,
    'B': 166000,
    'C': 166000,
    'D': 166000,
    'E': 120000,
    'F': 90000,
    'G': 80000,
    'H': 60000,
    'J': 80000,
    'K': 80000,
    'L': 100000,
    'M': 65000,
    'N': 80000,
    'P': 60000,
    'Q': 140000,
    'R': 166000,
    'S': 40000,
    'T': 60000,
    'U': 75000,
    'V': 70000,
    'W': 65000,
    'X': 65000,
    'Y': 65000,
    'Z': 75000,
  }
  return mapper[letter] || 80000
}

export const calculateFTE = (rfp: IHpRfpDetailModel | RfpTinderModel): number | string => {
  const a = rfp.award?.amount || 0
  const b = getPSCToFTE(rfp.classificationCode || (rfp as IHpRfpDetailModel).pscDescription?.fullNumber)
  if (b === 0) {
    return '---'
  }
  return Math.round(a / b / 5)
}

export const getPricingByCode = (code: string): string => {
  const mapper: {[key: string]: string} = {
    'A': 'Fixed Price Redetermination',
    'B': 'Fixed Price Level of Effort',
    'J': 'Firm Fixed Price',
    'K': 'Fixed Price with Economic Price Adjustment',
    'L': 'Fixed Price Incentive',
    'M': 'Fixed Price Award Fee',
    'R': 'Cost Plus Award Fee',
    'S': 'Cost No Fee',
    'T': 'Cost Sharing',
    'U': 'Cost Plus Fixed Fee',
    'V': 'Cost Plus Incentive Fee',
    'Y': 'Time and Materials',
    'Z': 'Labor Hours',
    '1': 'Order Dependent',
    '2': 'Combination',
    '3': 'Other',
    'FPDS Legacy': 'Currently collected; PEC approved'
  }
  return mapper[code] || code || 'N/A'
}

export const getMajorCommandByCode = (detectCode: string, code: string): string | undefined => {
  if (/(1700|2100|5700|97AS)/.test(detectCode)) {
    const mapper: {[key: string]: string} = {
      'FA4419':	'AETC',
      'FA3016':	'AETC',
      'FA4460':	'AETC',
      'FA3099':	'AETC',
      'FA3030':	'AETC',
      'FA3029':	'AETC',
      'FA3010':	'AETC',
      'FA3300':	'AETC',
      'FA3002':	'AETC',
      'FA3020':	'AETC',
      'FA4887':	'AETC',
      'F2X3CA':	'AETC',
      'FA3022':	'AETC',
      'F2U346':	'AETC',
      'F2X3C4':	'AETC',
      'F4A31A':	'AETC',
      'F2R3Q6':	'AETC',
      'F2X3CD':	'AETC',
      'F2X3CB':	'AETC',
      'F1Z32A':	'AETC',
      'F4A31C':	'AETC',
      'F2U300':	'AETC',
      'F2X3C3':	'AETC',
      'FA3089':	'AETC',
      'FA3047':	'AETC',
      'FA4819':	'AETC',
    }
    return mapper[code]
  }
}

export const getSolicitationProcedureByCode = (code: string): string | undefined => {
  const mapper: {[key: string]: string} = {
    'NP': 'Negotiated Proposal',
    'SB': 'Sealed Bid',
    'TS': 'Two Step',
    'SP1': 'Simplified Acquisition',
    'AE': 'Architect - Engineer',
    'BR': 'Basic Research',
    'AS': 'Alternative Sources',
    'MAFO': 'Subject to Multiple Award Fair Opportunity',
    'SSS': 'Only One Source',
    'FPDS': 'Legacy'
  }
  return mapper[code]
}

export const getSingleMultipleIdvByCode = (code?: string) => {
  if (code === 'S') {
    return 'Single'
  }
  if (code === 'M') {
    return 'Multiple'
  }
  return ''
}

export const getSetAsideText = (setAside?: string): string => {
  return setAside && setAside !== 'NO SET ASIDE USED.' ? setAside : 'FULL AND OPEN'
}

export const getAwardType = (code?: string) => {
  if (!code) {
    return 'N/A'
  }
  const mapper: {[key: string]: string} = {
    'DELIVERY O': 'DELIVERY ORDER',
    'DO': 'DELIVERY ORDER',
    'PURCHASE O': 'PURCHASE ORDER',
    'PO': 'PURCHASE ORDER',
    'BPA CALL': 'BPA CALL',
    'DEFINITIVE': 'DEFINITIVE CONTRACT',
    'DCA': 'DEFINITIVE CONTRACT ACTION',
    'BPA': 'BLANKET PURCHASE AGREEMENT'
  }
  return mapper[code] || code || 'N/A'
}

export const getRfpPredecessorTypes = () => {
  return [
    contractTypes.presolicitation,
    contractTypes.sourcesSought,
    contractTypes.solicitation,
    contractTypes.combinedSynopsisSolicitation,
    contractTypes.specialNotice
  ]
}

export const areDocumentsAvailable = (rfpDetails: IHpRfpDetailModel): boolean => {
  return rfpDetails.resourceLinks
    && rfpDetails.resourceLinks.length > 0
    && rfpDetails.resourceLinks.some(doc => doc.internalLink)
}

export const isDescriptionAvailable = (desc: string): boolean => !!desc && !/^http/.test(desc)

export const isOpenTopic = (text: string): boolean => {
  return /open[- ]{0,}topic/i.test(text)
}

export const isNationalSceienceFoundationDepartment = (text: string) => {
  return text.toLowerCase().includes('national sceience foundation')
}

export const getOutlineDocuments = (record: IHpRfpDetailModel | null): IResourceLinkModel[] => {
  const documents = record?.resourceLinks.filter(x => !!x.internalLink) || []
  if (record?.description && !startsWithHttp(record.description)) {
    const abstractDocument: IResourceLinkModel = {
      id: 'abstract-id',
      fileName: 'Abstract',
      internalLink: 'abstract',
      link: 'abstract'
    }
    return [abstractDocument].concat(documents)
  }
  return documents
}