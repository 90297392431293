import React from 'react'
import styles from './index.module.scss'
import { Button, Divider, Grid, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

export type IconTypes = 'search' |
  'dollar' | 'filter' | 'bookOpen' | 'question' | 'planning' |
  'auditioning' | 'luckyWinning' | 'processDriven' | 'pen' | 'speedometer' | 'print' |
  'brain' | 'like' | 'trust'

export const iconSize = {
  width: 24,
  height: 24
}

export const IconStrategy: {[key in IconTypes]: JSX.Element} = {
  search: <SvgIcon color='#232326' name='search' width={iconSize.width} height={iconSize.height} />,
  dollar: <SvgIcon color='#232326' name='dollar-alt' width={iconSize.width} height={iconSize.height} />,
  filter: <SvgIcon color='#232326' name='filter' width={iconSize.width} height={iconSize.height} />,
  bookOpen: <SvgIcon color='#232326' name='book-open' width={iconSize.width} height={iconSize.height} />,
  question: <SvgIcon color='#232326' name='question-circle' width={iconSize.width} height={iconSize.height} />,
  planning: <SvgIcon color='#232326' name='planning' width={iconSize.width} height={iconSize.height}/>,
  auditioning: <SvgIcon color='#232326' name='auditioning' width={iconSize.width} height={iconSize.height}/>,
  luckyWinning: <SvgIcon color='#232326' name='horseshoe' width={iconSize.width} height={iconSize.height}/>,
  processDriven: <SvgIcon color='#232326' name='pointer' width={iconSize.width} height={iconSize.height}/>,
  pen: <SvgIcon color='#232326' name='pen' width={iconSize.width} height={iconSize.height}/>,
  speedometer: <SvgIcon color='#232326' name='speedometer' width={iconSize.width} height={iconSize.height}/>,
  print: <SvgIcon color='#232326' name='print' width={iconSize.width} height={iconSize.height}/>,
  brain: <SvgIcon color='#232326' name='brain' width={iconSize.width} height={iconSize.height}/>,
  like: <SvgIcon color='#232326' name='thumbs-up' width={iconSize.width} height={iconSize.height}/>,
  trust: <SvgIcon color='#232326' name='trust' width={iconSize.width} height={iconSize.height}/>,
}

interface Props {
  title: string
  iconType?: IconTypes
  show: boolean
  toggle: () => void
  bottomLine?: boolean
}

const DropdownTitle = ({title, iconType, show, toggle, bottomLine}: Props) => {
  return (
    <div className={styles['tutorial-title-block']}>
      <Grid container wrap={'nowrap'} alignItems={'center'}>
        <div className={styles['icon']}>
          {iconType && IconStrategy[iconType]}
        </div>
        <Typography variant={'body2'} className={styles['title']}>
          {title}
        </Typography>
      </Grid>
      <Button
        className={show ? styles['show-btn-active'] : undefined}
        variant='text'
        disableRipple
        onClick={toggle}
      >
        <SvgIcon
          name='angle-down'
        />
      </Button>
      {bottomLine && (<Divider light style={{margin: '24px 0'}}/>)}
    </div>
  )
}

export default DropdownTitle
