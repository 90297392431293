import React  from 'react'
import styles from '../../index.module.scss'
import {
  Theme,
  Typography,
  useTheme,
  Grid,
  Checkbox,
  Button,
  FormControlLabel,
  Hidden,
  CircularProgress
} from '@material-ui/core'
import { FormikProps } from 'formik'
import { ISignInForm } from './Form'
import { Link, useHistory } from 'react-router-dom'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import TextField from 'components/v2/TextField'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import paths from 'utils/paths'
import SvgIcon from 'components/SvgIcon'

interface IFormRendererProps extends FormikProps<ISignInForm> {
  isRequested: boolean
}

const FormRenderer = ({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  isRequested
}: IFormRendererProps) => {
  const history = useHistory()
  const theme = useTheme<Theme>()

  return (
    <form onSubmit={handleSubmit} className={styles['form-wrapper']}>
      <Grid container className={styles['form-block']}>
        <Hidden smDown>
          <Grid item md={6}>
            <LeftBlock />
          </Grid>
        </Hidden>
        <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
          <div className={styles['form-block-right-inner']}>
            <div className={styles['logo']}>
              <img src={LogoWithText} alt='logo'/>
            </div>
            <Grid container justifyContent='center' className={styles['form-subtitle']}>
              <Typography variant='h4'>Welcome Back!</Typography>
            </Grid>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('email')}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && !!errors.email && errors.email}
                value={values.email}
                onChange={handleChange('email')}
                className={styles['field']}
                label='Email Address'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <TextField
                onBlur={handleBlur('password')}
                error={!!touched.password && !!errors.password}
                helperText={!!touched.password && !!errors.password && errors.password}
                value={values.password}
                onChange={handleChange('password')}
                className={styles['field']}
                label='Password'
                type='password'
                fullWidth
                variant='filled'
              />
            </div>
            <div className={styles['input']}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                  />
                }
                label='Remember me'
              />
            </div>
            <Button
              className={styles['submit']}
              variant='contained'
              color='primary'
              fullWidth
              disabled={isRequested}
              type='submit'
            >
              {isRequested ? (
                <CircularProgress color='primary' size={30} />
              ) : (
                <span>Log in</span>
              )}
            </Button>
            <div className={styles['external-links']}>
              <Typography variant='h6' style={{paddingBottom: '18px'}}>OR</Typography>
              <Grid container justifyContent='center' spacing={1}>
                <Grid item className={styles['oauth-wrapper']}>
                  <a
                    href={paths.User.OAuth.gmail}
                    className={styles['oauth-button']}
                  >
                    <SvgIcon name={'google'} width={28} height={28}/>
                    <Typography variant='h6'>Continue with Google</Typography>
                  </a>
                </Grid>
                <Grid item className={styles['oauth-wrapper']}>
                  <a
                    href={paths.User.OAuth.outlook}
                    className={styles['oauth-button']}
                  >
                    <SvgIcon name={'microsoft'} width={28} height={28}/>
                    <Typography variant='h6'>Continue with Microsoft</Typography>
                  </a>
                </Grid>
              </Grid>
            </div>
            <div className={styles['external-links']}>
              <Link
                style={{color: theme.palette.info.main, marginBottom: '10px'}}
                className={styles['forgot-link']}
                to={{ pathname: '/auth/recover/send', search: history.location.search }}
              >
                Forgot Password?
              </Link>
              <Typography>
                Don't have an account?
                <Link
                  className={styles['link']}
                  style={{color: theme.palette.info.main}}
                  to={{pathname: '/auth/sign-up', search: history.location.search}}
                > Sign up</Link>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer