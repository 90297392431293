import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectedLocationsOptions as selectedLocationsOptionsSelector
} from 'store/hp/awards/search/selectors'
import { locationCountSelector, locationsSelector } from 'store/hp/location/selectors'
import {
  setAwardSearchFilterValue,
  removeAwardSearchFilterValue,
  removeAwardSearchFilterValues,
  setAwardSearchFilterValues
} from 'store/hp/awards/search/actions'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import RecursiveItem, { isChecked } from 'components/v2/Filters/SearchFilter/RecursiveItem'
import { BaseRegionModel } from 'models/hp/location/location.model'
import styles from './index.module.scss'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'
import { userActivity } from 'store/common/owner/actions'

const LocationFilter = () => {
  const dispatch = useDispatch()

  const [showMain, setShowMain] = React.useState<boolean>(false)
  const [showMapper, setShowMapperValue] = React.useState<{[key: string]: boolean}>({})

  const locationOptions: BaseRegionModel[] = useSelector(locationsSelector) as BaseRegionModel[]
  const selectedLocationsOptions: string[] = useSelector(selectedLocationsOptionsSelector)
  const locationCount: number = useSelector(locationCountSelector)!

  const handleShowMain = () => {
    if (!showMain) {
      userActivity.event(userActivity.activities.openLocationFilter)
    }
    setShowMain(!showMain)
  }

  const handleChange = (id: number | string, state: boolean) => {
    if (state) {
      setAwardSearchFilterValue(dispatch)('locations', id)
    } else {
      removeAwardSearchFilterValue(dispatch)('locations', id)
    }
  }

  const handlePartialAllChange = (state: boolean, values: number[]) => {
    if (state) {
      setAwardSearchFilterValues(dispatch)('locations', values)
    } else {
      removeAwardSearchFilterValues(dispatch)('locations', values)
    }
  }

  const renderItems = () => {
    return locationOptions.map(option => {
      const checked = isChecked(option.sub, selectedLocationsOptions, 'id')
      return (
        <div className={styles['select-block-wrap']} key={option.region}>
          <div className={styles['select-block']}>
            <FormControlLabel
              className={styles['select-input']}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() =>
                    handlePartialAllChange(!checked, option.sub.map(item => item.id))
                  }
                  color='primary'
                />
              }
              label={option.region}
            />
            {option.sub && option.sub.length > 0 && (
              <Button
                className={showMapper[option.region] ? styles['show-btn-active'] : undefined}
                variant='text'
                disableRipple
                onClick={() => setShowMapperValue({...showMapper, [option.region]: !showMapper[option.region]})}
              >
                <SvgIcon
                  name='angle-down'
                />
              </Button>
            )}
          </div>
          {showMapper[option.region] && (
            <div className={styles['select-block-children']}>
              {option.sub.map(item => (
                <RecursiveItem
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  sub={item.sub || []}
                  handleChange={handleChange}
                  handlePartialChange={() => {
                  }}
                  selected={selectedLocationsOptions}
                />
              ))}
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <div className={styles['paper']}>
      <div className={styles['paper']}>
        <div className={styles['select-block']}>
          <FilterTitle title='Locations' allCount={locationCount} iconType='location' selectedLength={selectedLocationsOptions.length}/>
          {locationOptions && locationOptions.length > 0 && (
            <Button
              className={showMain ? styles['show-btn-active'] : undefined}
              variant='text'
              disableRipple
              onClick={handleShowMain}
            >
              <SvgIcon
                name='angle-down'
              />
            </Button>
          )}
        </div>
        {showMain && (
          <div className={styles['select-block-children-root']}>
            {renderItems()}
          </div>
        )}
      </div>
    </div>
  )
}

export default LocationFilter