import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import {
  PipelineBoardListResponseModel,
  PiplineHealthResponseModel,
  PiplinePendingAwardsResponseModel,
  PiplineRevenueResponseModel,
  PiplineTimelineResponseModel,
} from 'models/hp/pipeline/pipeline.model'
import { IPiplineHealthResponseModel, IPiplinePendingAwardsBody, IPiplinePendingAwardsResponseModel, IPiplineRevenueResponseModel, IPiplineTimelineAssinedUser, IPiplineTimelineResponseModel, PipelineTimelineOrderType } from 'models/hp/pipeline/pipeline.interface'
import { ISearchDTO } from 'models/hp/search/search.interface'
import { BaseResponseModel } from 'models/base/base.model'

export const PIPELINE_RFP_BOARD_LIST = 'PIPELINE_RFP_BOARD_LIST'
export const PIPELINE_RFP_BOARD_LIST_SUCCESS = 'PIPELINE_RFP_BOARD_LIST_SUCCESS'
export const PIPELINE_RFP_BOARD_LIST_ERROR = 'PIPELINE_RFP_BOARD_LIST_ERROR'

export const GET_PIPLINE_TIMELINE = 'GET_PIPLINE_TIMELINE'
export const GET_PIPLINE_TIMELINE_SUCCESS = 'GET_PIPLINE_TIMELINE_SUCCESS'
export const GET_PIPLINE_TIMELINE_ERROR = 'GET_PIPLINE_TIMELINE_ERROR'

export const GET_PIPLINE_HEALTH = 'GET_PIPLINE_HEALTH'
export const GET_PIPLINE_HEALTH_SUCCESS = 'GET_PIPLINE_HEALTH_SUCCESS'
export const GET_PIPLINE_HEALTH_ERROR = 'GET_PIPLINE_HEALTH_ERROR'

export const GET_PIPLINE_REVENUE = 'GET_PIPLINE_REVENUE'
export const GET_PIPLINE_REVENUE_SUCCESS = 'GET_PIPLINE_REVENUE_SUCCESS'
export const GET_PIPLINE_REVENUE_ERROR = 'GET_PIPLINE_REVENUE_ERROR'

export const GET_PIPLINE_PENDING_AWARDS = 'GET_PIPLINE_PENDING_AWARDS'
export const GET_PIPLINE_PENDING_AWARDS_SUCCESS = 'GET_PIPLINE_PENDING_AWARDS_SUCCESS'
export const GET_PIPLINE_PENDING_AWARDS_ERROR = 'GET_PIPLINE_PENDING_AWARDS_ERROR'

export const SET_PIPLINE_PENDING_AWARDS = 'GET_PIPLINE_PENDING_AWARDS'
export const SET_PIPLINE_PENDING_AWARDS_SUCCESS = 'GET_PIPLINE_PENDING_AWARDS_SUCCESS'
export const SET_PIPLINE_PENDING_AWARDS_ERROR = 'GET_PIPLINE_PENDING_AWARDS_ERROR'

export const RESET_PIPLINE_PENDING_AWARDS = 'RESET_PIPLINE_PENDING_AWARDS'
export const UPDATE_PIPLINE_PENDING_AWARDS = 'UPDATE_PIPLINE_PENDING_AWARDS'

export const PIPELINE_USER_ASSIGNMENT = 'PIPELINE_USER_ASSIGNMENT'
export const PIPELINE_USER_ASSIGNMENT_SUCCESS = 'PIPELINE_USER_ASSIGNMENT_SUCCESS'
export const PIPELINE_USER_ASSIGNMENT_ERROR = 'PIPELINE_USER_ASSIGNMENT_ERROR'

export const getPipelineBoardList = (dispatch: Dispatch) => async (query: string, body: ISearchDTO) => {
  try {
    dispatch({
      type: PIPELINE_RFP_BOARD_LIST
    })
    const res = await request<PipelineBoardListResponseModel>(RequestMethods.Post, Paths.Hp.Pipeline.getPipelineBoardList(query), {
      schema: PipelineBoardListResponseModel,
      body
    })
    dispatch({
      type: PIPELINE_RFP_BOARD_LIST_SUCCESS,
      payload: res.data.data
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: PIPELINE_RFP_BOARD_LIST_ERROR
    })
    throw e
  }
}

export const getPiplineTimeline = (dispatch: Dispatch) => async (page: number = 0, offset?: number, limit?: number, field?: string, order?: PipelineTimelineOrderType) => {
  try {
    dispatch({
      type: GET_PIPLINE_TIMELINE
    })
    const res = await request<IPiplineTimelineResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.timeline(page, offset, limit, field, order), {
      schema: PiplineTimelineResponseModel
    })
    dispatch({
      type: GET_PIPLINE_TIMELINE_SUCCESS,
      payload: res.data
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: GET_PIPLINE_TIMELINE_ERROR
    })
    throw e
  }
}

export const getPiplineHealth = async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_PIPLINE_HEALTH
    })
    const res = await request<IPiplineHealthResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.health, {
      schema: PiplineHealthResponseModel
    })
    dispatch({
      type: GET_PIPLINE_HEALTH_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  }
  catch (e) {
    dispatch({
      type: GET_PIPLINE_HEALTH_ERROR
    })
    throw e
  }
}

export const setPiplineHealth = async (values: {[key: string]: number}) => {
  await request<IPiplineHealthResponseModel>(RequestMethods.Post, Paths.Hp.Pipeline.health, {
    schema: PiplineHealthResponseModel,
    body: {values}
  })
}

export const getPiplineRevenue = async (dispatch: Dispatch) =>  {
  try {
    dispatch({
      type: GET_PIPLINE_REVENUE
    })
    const res = await request<IPiplineRevenueResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.revenue, {
      schema: PiplineRevenueResponseModel
    })
    dispatch({
      type: GET_PIPLINE_REVENUE_SUCCESS,
      payload: res.data.data
    })
    return res.data.data
  }
  catch (e) {
    dispatch({
      type: GET_PIPLINE_REVENUE_ERROR
    })
    throw e
  }
}

export const getPiplinePendingAwards = (dispatch: Dispatch) => async (offset = 0) =>  {
  try {
    dispatch({
      type: GET_PIPLINE_PENDING_AWARDS
    })
    const res = await request<IPiplinePendingAwardsResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.pendingAwards(offset), {
      schema: PiplinePendingAwardsResponseModel
    })
    dispatch({
      type: GET_PIPLINE_PENDING_AWARDS_SUCCESS,
      payload: res.data
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: GET_PIPLINE_PENDING_AWARDS_ERROR
    })
    throw e
  }
}

export const upadatePiplinePendingAwards = (dispatch: Dispatch) => (id: string, values: IPiplinePendingAwardsBody) => {
  dispatch({
    type: UPDATE_PIPLINE_PENDING_AWARDS,
    payload: {
      id,
      values
    }
  })
}

export const setPiplinePendingAwards = (dispatch: Dispatch) => async (rfpId: string, values: IPiplinePendingAwardsBody) =>  {
  try {
    dispatch({
      type: SET_PIPLINE_PENDING_AWARDS
    })
    const res = await request<IPiplinePendingAwardsResponseModel>(RequestMethods.Post, Paths.Hp.Pipeline.pendingAwardsOutcome(rfpId), {
      schema: PiplinePendingAwardsResponseModel,
      body: values
    })
    dispatch({
      type: SET_PIPLINE_PENDING_AWARDS_SUCCESS,
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: SET_PIPLINE_PENDING_AWARDS_ERROR
    })
    throw e
  }
}

export const resetPiplinePendingAwards = (dispatch: Dispatch) => {
  dispatch({
    type: RESET_PIPLINE_PENDING_AWARDS
  })
}

export const pipelineUserAssignment = (dispatch: Dispatch) => async (rfpId: string, user: IPiplineTimelineAssinedUser) => {
  try {
    dispatch({
      type: PIPELINE_USER_ASSIGNMENT
    })
    const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Pipeline.piplineUserAsignment(rfpId), {
      schema: BaseResponseModel,
      body: {assignedUserId : user.id}
    })
    dispatch({
      type: PIPELINE_USER_ASSIGNMENT_SUCCESS,
      payload: {
        rfpId,
        assignedUser: user
      }
    })
    return res.data
  }
  catch (e) {
    dispatch({
      type: PIPELINE_USER_ASSIGNMENT_ERROR
    })
    throw e
  }
}

