import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  companyProfileSelectedEndingSoonOption
} from 'store/hp/company-profiles/search/selectors'
import { setCompanySearchFilterValueBool } from 'store/hp/company-profiles/search/actions'
import styles from './index.module.scss'
import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'
import SvgIcon from 'components/SvgIcon'

const EndingSoonFilter = () => {

  const dispatch = useDispatch()

  const selectedEndingSoonOption = useSelector(companyProfileSelectedEndingSoonOption)

  const [show, setShow] = React.useState<boolean>(false)
  const [isSelected, setIsSelected] = React.useState<string>()

  useEffect(() => {
    setIsSelected(selectedEndingSoonOption !== undefined ? selectedEndingSoonOption.toString() : '')
  }, [selectedEndingSoonOption])

  const changeStrategy = (state?: boolean) => {
    if (typeof state === 'boolean') {
      setCompanySearchFilterValueBool(dispatch)('isEndingSoon', state)
    } else {
      setCompanySearchFilterValueBool(dispatch)('isEndingSoon', undefined)
    }
  }

  const onChange = (value: string) => {
    if (value === isSelected) {
      setIsSelected('')
      changeStrategy(undefined)
    } else {
      changeStrategy(value === 'true')
      setIsSelected(value)
    }
  }
  return (
    <div className={styles['paper']}>
      <div className={styles['select-block']}>
        <FilterTitle title='Ending Soon(in next 6 month)' iconType='date' selectedLength={isSelected ? 1 : 0}/>
        <Button
          className={show ? styles['show-btn-active'] : undefined}
          variant='text'
          disableRipple
          onClick={() => setShow(!show)}
        >
          <SvgIcon
            name='angle-down'
          />
        </Button>
      </div>
      {show &&
      <div className={styles['list']}>
        <RadioGroup aria-label='gender' name='gender1' value={isSelected}>
          <FormControlLabel
            value={'true'}
            control={<Radio onClick={() => onChange('true')} color='primary'/>}
            label='True'
          />
          <FormControlLabel
            value={'false'}
            control={<Radio onClick={() => onChange('false')} color='primary'/>}
            label='False'
          />
        </RadioGroup>
      </div>
      }
    </div>
  )
}

export default EndingSoonFilter
