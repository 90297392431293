import { createSelector } from 'reselect'
import { IRootState } from '../../../reducers'

const companyProfileSearch = (state: IRootState) => state.companyProfile.search

export const companyProfileSearchSelector = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.toJSON()
)

export const companyProfileKeywordSelector = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('keyword')
)

export const companyProfileSelectedPscOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('pscCodes')
)

export const companyProfileSelectedNaicsOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('naicsCodes')
)

export const companyProfileSelectedCustomerOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('customerCodes')
)

export const companyProfileSelectedGovConOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('govConIds')
)

export const companyProfileSelectedSizeOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('sizeIds')
)

export const companyProfileSelectedSetAsideOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('setAsideIds')
)

export const companyProfileAllSelectedStats = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('stateCodes')
)

export const companyProfileSelectedDccaOption = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('isDcaa')
)

export const companyProfileSelectedEndingSoonOption = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('isEndingSoon')
)

export const companyProfileSelectedVehicleOptions = createSelector(
  companyProfileSearch,
  companyProfileSearch => companyProfileSearch.get('vehicleNames')
)
