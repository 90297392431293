import * as React from 'react'
import styles from './index.module.scss'
import { LinearProgress, useTheme, makeStyles } from '@material-ui/core'
import { SapPercentageValue } from 'helpers/search'

interface Props {
  value: SapPercentageValue
  color: string
  label?: string
}

const ProgressBar = ({ value, label, color }: Props) => {
  const theme = useTheme()

  const getColor = (): string => {
    if (value.negative) {
      return theme.palette.error.main
    }
    if (color === 'primary') {
      return theme.palette.primary.light
    }
    return theme.palette.success.main
  }

  const useStyles = makeStyles({
    root: {
      height: 25,
      borderRadius: 4,
      transform: `rotate(${value.negative ? '180deg' : '0deg'})`
    },
    colorPrimary: {
      backgroundColor: 'transparent',
      border: 0
    },
    bar: {
      borderRadius: 4,
      backgroundColor: getColor(),
    },
  })

  const classes = useStyles()

  return (
    <div className={styles['container']}>
      {label && (
        <div className={styles['label']}><b>{label}</b></div>
      )}
      <LinearProgress
        variant='determinate'
        value={value.value}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar
        }}
      />
    </div>
  )
}

export default ProgressBar