import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { FEEDBACK_TYPE } from 'constants/feedback'
import { BaseResponseModel } from 'models/base/base.model'
import { IFeedbackDetailsModel, IFeedbackDetailsResponseModel } from 'models/hp/feedback/feedback.interface'

@jsonObject
export class FeedbackModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  email: string = ''

  @jsonMember({constructor: String})
  createdAt: string = ''

  @jsonMember({ constructor: String})
  subject: string | null = null

  @jsonMember({constructor: String})
  type: string = FEEDBACK_TYPE.GENERAL
}

@jsonObject
export class FeedbackDetailsModel implements IFeedbackDetailsModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  email: string = ''

  @jsonMember({constructor: String})
  firstName: string = ''

  @jsonMember({constructor: String})
  lastName: string = ''

  @jsonMember({constructor: String})
  createdAt: string = ''

  @jsonMember({constructor: String})
  subject: string = ''

  @jsonMember({constructor: String})
  body: string = ''

  @jsonMember({constructor: String})
  type: FEEDBACK_TYPE = FEEDBACK_TYPE.GENERAL

  @jsonMember({constructor: String})
  zip: string | null = null
}


@jsonObject
export class FeedbackListOwnerResponseModel extends BaseResponseModel {
  @jsonArrayMember(FeedbackModel)
  data: FeedbackModel[] = []

  @jsonMember({constructor: Number})
  count: number = 0
}

@jsonObject
export class FeedbackDetailsOwnerResponseModel extends BaseResponseModel implements IFeedbackDetailsResponseModel{
  @jsonMember({constructor: FeedbackDetailsModel})
  data: FeedbackDetailsModel = new FeedbackDetailsModel()
}