import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const awards = (state: IRootState) => state.awards

export const awardsLoadingSelector = createSelector(
  awards,
  (awards) => awards.listing.get('loading')
)

export const awardsLoadedSelector = createSelector(
  awards,
  (awards) => awards.listing.get('loaded')
)

export const awardsAppendingSelector = createSelector(
  awards,
  (awards) => awards.listing.get('appending')
)

export const awardsListingSelector = createSelector(
  awards,
  (awards) => awards.listing.get('data')
)

export const awardsParamsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('params')
)

export const awardDetailsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('details')
)

export const awardTransactionsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transactions')
)

export const awardSpendigChartSelector = createSelector(
  awards,
  (awards) => awards.listing.get('spending')
)

export const awardProfitSelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit')
)

export const awardProfitAwardSizeSelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit').awardSize
)

export const awardProfitTransitionSelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit').transition
)

export const awardProfitSummaryOptionsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit').summaryOptions
)

export const awardProfitSummarySelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit').summary
)

export const awardProfitMarketSizeSelector = createSelector(
  awards,
  (awards) => awards.listing.get('profit').marketSize
)

export const awardTransitionMarketSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transition')
)

export const awardTransitionMarketOptionsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transition').options
)

export const awardTransitionMarketOverviewSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transition').overview
)

export const awardTransitionMarketOverviewByTypeSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transition').overviewByType
)

export const awardTransitionMarketUSGMarketSelector = createSelector(
  awards,
  (awards) => awards.listing.get('transition').USGMarket
)

export const awardGovContractsSelector = createSelector(
  awards,
  (awards) => awards.listing.get('govContracts')
)

export const awardGovContractsOverviewSelector = createSelector(
  awards,
  (awards) => awards.listing.get('govContracts').overview
)