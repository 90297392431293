import { ClickAwayListener, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

interface Props {
  title: string
  children: React.ReactNode
  onOpen?: () => void
}

const WithTooltip = ({ children, title, onOpen }: Props) => {

  let tooltipCloseTimeout: number | null = null

  const [ open, setOpen ] = useState<boolean>(false)

  const handleCloseTooltip = () => {
    setOpen(false)
  }

  const handleOpenTooltip = () => {
    setOpen(true)
    onOpen && onOpen()
    // auto close tooltip after 1 second
    tooltipCloseTimeout = window.setTimeout(() => {
      setOpen(false)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      tooltipCloseTimeout && clearTimeout(tooltipCloseTimeout)
    }
  }, [])

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Tooltip
        open={open}
        placement='top-end'
        title={title}
      >
        <div onClick={handleOpenTooltip}>
          {children}
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default WithTooltip
