import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import { getLocationsAction } from 'store/hp/location/action'
import { Button, Grid, Paper } from '@material-ui/core'
import styles from '../../index.module.scss'
import SearchInput from 'components/v2/SearchInput'
import ServiceLinePscFilter from './Psc'
import ServiceLineNaicsFilter from './Naics'
import ServiceLineCustomerFilter from './Customer'
import { agenciesLoadedSelector } from 'store/hp/agency/selectors'
import ServiceLineLocationFilter from './Location'
import { IFilterOption } from 'models/hp/search/search.interface'
import { actionTypes } from '../Row'
import { getAgenciesAction } from 'store/hp/agency/actions'

interface Props {
  selectedNaics: IFilterOption[]
  selectedPsc: IFilterOption[]
  selectedLocations: IFilterOption[]
  selectedCustomers: IFilterOption[]
  handleChange: (type: actionTypes, id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (type: actionTypes, ids: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
  onReset: () => void
  onSave: () => void
  onKeywordAdd: (name: string) => void
}

const ServiceLineFilter = ({ selectedPsc, handlePartialChange, handleChange, onReset, onSave, selectedNaics, selectedLocations, selectedCustomers, onKeywordAdd }: Props) => {

  const dispatch = useDispatch()
  const [keyword, setKeyword] = React.useState('')

  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const locationsLoaded = useSelector(locationsLoadedSelector)
  const agenciesLoaded = useSelector(agenciesLoadedSelector)

  const onAddKeyword = () => {
    onKeywordAdd(keyword)
    setKeyword('')
  }

  useEffect(() => {
    if (!groupedCodesLoaded) {
      getNaicsCodesAction(dispatch)()
      getPscCodesAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }
    if (!agenciesLoaded) {
      getAgenciesAction(dispatch)()
    }
  }, [])

  return (
    <Paper className={styles['filter']}>
      <SearchInput
        value={keyword}
        onInput={(value) => setKeyword(value)}
        actionText='Add'
        onAction={onAddKeyword}
        placeholder='Keywords'
      />
      <div style={{ height: '16px' }}/>
      <ServiceLinePscFilter
        handlePartialChange={(ids, state, options) => handlePartialChange('psc', ids, state, options)}
        handleChange={(id, state, option) => handleChange('psc', id, state, option)}
        selectedPsc={selectedPsc}
      />
      <div style={{ height: '16px' }}/>
      <ServiceLineNaicsFilter
        handlePartialChange={(ids, state, options) => handlePartialChange('naics', ids, state, options)}
        handleChange={(id, state, option) => handleChange('naics', id, state, option)}
        selectedNaics={selectedNaics}
      />
      <div style={{ height: '16px' }}/>
      <ServiceLineCustomerFilter
        selectedCustomers={selectedCustomers}
        handlePartialChange={(ids, state, options) => handlePartialChange('customer', ids, state, options)}
        handleChange={(id, state, option) => handleChange('customer', id, state, option)}
      />
      <div style={{ height: '16px' }}/>
      <ServiceLineLocationFilter
        selectedLocations={selectedLocations}
        handlePartialChange={(ids, state, options) => handlePartialChange('location', ids, state, options)}
        handleChange={(id, state, option) => handleChange('location', id, state, option)}
      />
      <div style={{ height: '16px' }}/>
      <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
        <Grid item md={6} sm={6} xs={12}>
          <Button
            fullWidth
            variant='outlined'
            onClick={onReset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={onSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>

    </Paper>
  )
}

export default ServiceLineFilter
