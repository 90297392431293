import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import { getMarketResearchSap, setMarketCustomers } from 'store/hp/market-research/actions'
import { userSelector } from 'store/common/user/selectors'
import { SapItem } from 'models/hp/market-research/market-research.model'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import Filters, { IFilters } from './Filter'
import Listing, { ICustomer, ICustomerMapper } from './Listing'
import { useSelector } from 'react-redux'
import CreateLazyAccountModal from 'components/v2/LazyAccountCreationModal'
import { userActivity } from 'store/common/owner/actions'

const Sap = () => {
  const notification = useRef(useNotification())
  const user = useSelector(userSelector)

  const [listingLoading, setListingLoading] = React.useState(false)
  const [listingData, setListingData] = React.useState<SapItem[]>([])
  const [customers, setCustomers] = React.useState<ICustomerMapper>({})
  const [lazyUserModalOpened, setLazyUserModalOpened] = React.useState(false)

  const addCustomer = (customer: ICustomer) => {
    setCustomers(prev => ({
      ...prev,
      [customer.office]: customers[customer.office] ? undefined : customer
    }))
  }

  const getListingData = (filters: IFilters) => {
    setListingLoading(true)
    getMarketResearchSap(filters)
      .then(setListingData)
      .catch(console.log)
      .finally(() => setListingLoading(false))
  }

  const handleValidate = () => {
    if (Object.keys(customers).length === 0) {
      return notification.current.show('Please, select at least one option', NotificationTypeList.Warning)
    }
    if (!user) {
      return setLazyUserModalOpened(true)
    }
    return handleSave()
  }

  const handleSave = async () => {
    try {
      const values = []
      for (let key in customers) {
        if (!customers[key]) continue
        values.push(customers[key]!.subAgency)
      }

      await setMarketCustomers(values)

      notification.current.show('Selected options are saved', NotificationTypeList.Success)
      setLazyUserModalOpened(false)

      userActivity.event(userActivity.activities.marketResearchToolSapUsed)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    userActivity.event(userActivity.activities.marketResearchToolSap)
  }, [])

  return (
    <div className={styles['container']}>
      <Grid container spacing={2} className={styles['height-block']}>
        <Grid item md={4} className={styles['filter-list']}>
          <Filters
            getListing={getListingData}
            saveCustomers={handleValidate}
          />
        </Grid>
        <Grid item md={8} className={styles['height-block']}>
          <Listing
            listingData={listingData}
            listingLoading={listingLoading}
            customers={customers}
            addCustomer={addCustomer}
          />
        </Grid>
      </Grid>
      {lazyUserModalOpened && (
        <CreateLazyAccountModal
          open={true}
          onClose={() => setLazyUserModalOpened(false)}
          onSend={handleSave}
        />
      )}
    </div>
  )
}

export default Sap