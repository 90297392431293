import React from 'react'
import { useSelector } from 'react-redux'
import { savedSearchesSelector } from 'store/hp/search/selectors'
import { ISearchLightModel } from 'models/hp/search/search.interface'
import { Button, Typography, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface Props {
  title: string
  handleSave: (searchId: number) => Promise<void>
}

const SearchUpdater = ({ title, handleSave }: Props) => {
  const theme = useTheme()
  const savedSearches = useSelector(savedSearchesSelector) as ISearchLightModel[]
  const [searchId, setSearchId] = React.useState<number | null>(null)

  const renderBody = () => {
    if (savedSearches?.length === 0) return (
      <Typography>No saved searches found</Typography>
    )
    return (
      <div>
        {savedSearches?.map(search => (
          <div key={search.id} style={{marginBottom: '6px'}}>
            <Button
              key={search.id}
              onClick={() => setSearchId(search.id)}
              variant='text'
              color={searchId === search.id ? 'primary' : 'default'}
              startIcon={
                <SvgIcon name='arrow-right' color={theme.palette.warning.main} />
              }
            >
              {search.name}
            </Button>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <Typography variant='h4'>{title}</Typography>
      {renderBody()}
      {savedSearches.length !== 0 && (
        <div style={{paddingTop: '6px'}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleSave(searchId!)}
            disabled={searchId === null}
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  )
}

export default SearchUpdater