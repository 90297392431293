import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'

export interface IRecoverPasswordForm {
  email: string
}

export const RECOVER_PASSWORD_FORM_INIT_VALUE: IRecoverPasswordForm = {
  email: ''
}

export const RECOVER_PASSWORD_FORM_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Email'))
    .email(ERROR_MESSAGES.Field.NotValid('Email')),
})