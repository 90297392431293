import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { MIN_PASSWORD_LEN } from 'utils/const'

export interface IResetPasswordForm {
  password: string
  confirmPassword: string
}

export const RESET_PASSWORD_INIT_VALUE: IResetPasswordForm = {
  password: '',
  confirmPassword: ''
}

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object({
  password: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Password'))
    .min(MIN_PASSWORD_LEN, ERROR_MESSAGES.Field.MinLen('Password', MIN_PASSWORD_LEN)),
  confirmPassword: Yup.string()
    .oneOf([ Yup.ref('password') ], 'Password do not match')
    .required('Password confirm is required')
})