import React from 'react'
import { Paper, Typography, useTheme } from '@material-ui/core'
import styles from '../../index.module.scss'
import SvgIcon from 'components/SvgIcon'

interface Props {
  title: string
  icon: string
  children?: React.ReactNode
  onClick?: () => void
}

const ItemBlock = ({ title, icon, children, onClick }: Props) => {

  const theme = useTheme()

  return (
    <Paper className={styles['item-wrapper']}>
      <div className={styles['header']} onClick={onClick}>
        <SvgIcon height={40} width={40} name={icon} color={theme.palette.warning.main} className={styles['logo']}/>
        <Typography style={{ marginLeft: '10px' }} variant='h4'>{title}</Typography>
      </div>
      <hr/>
      <div className={styles['body']}>
        {children && children}
      </div>
    </Paper>
  )
}

export default ItemBlock
