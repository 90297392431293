import React from 'react'
import { Card, CardContent, Typography, CardActions, IconButton, useTheme } from '@material-ui/core'
import { HpMarketResearchRfp } from 'models/hp/rfp/rfp.model'
import SvgIcon from 'components/SvgIcon'

interface Props {
  item: HpMarketResearchRfp
  categorize: (id: string, type: string) => void
  requesting: boolean
}

const CategorizationItem = ({item, categorize, requesting}: Props) => {
  const theme = useTheme()
  return (
    <Card>
      <CardContent>
        <Typography variant='h5' component='h2'>
          {item.title}
        </Typography>
        <Typography color='textSecondary'>
          <b>PSC:</b> {item.classificationName || item.classificationCode}
        </Typography>
        <Typography color='textSecondary'>
          <b>NAICS:</b> {item.naicsName || item.naicsCode}
        </Typography>
      </CardContent>
      <CardActions style={{justifyContent: 'flex-end'}}>
        <IconButton disabled={requesting} title='Move to trash' onClick={() => categorize(item.id, 'dislike')}>
          <SvgIcon name='trash' width={30} height={30} color={theme.palette.error.main}/>
        </IconButton>
        <IconButton disabled={requesting} title='Skip' onClick={() => categorize(item.id, 'skip')}>
          <SvgIcon name='question-circle' width={30} height={30}/>
        </IconButton>
        <IconButton disabled={requesting} title='Like' onClick={() => categorize(item.id, 'like')}>
          <SvgIcon name='heart' width={30} height={30} color={theme.palette.primary.main}/>
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default CategorizationItem