import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class GovConModel {
  public get text(): string {
    return this.name
  }

  @jsonMember({constructor: Number})
  id: number = 0
  @jsonMember({constructor: String})
  name: string = ''
}

@jsonObject
export class GovConSizeModel {

  public get text(): string {
    return `${this.name} ${this.signsNumbers}`
  }

  @jsonMember({constructor: Number})
  id: number = 0
  @jsonMember({constructor: String})
  name: string = ''
  @jsonMember({constructor: String})
  signsNumbers: string = ''
}

@jsonObject
export class CompanyGovConModelResponse extends BaseResponseModel {
  @jsonArrayMember(GovConModel)
  data: GovConModel[] = []
}

@jsonObject
export class CompanyGovConSizeModelResponse extends BaseResponseModel {
  @jsonArrayMember(GovConSizeModel)
  data: GovConSizeModel[] = []
}
