import { jsonMember, jsonObject } from 'typedjson'
import { ICityModel } from './city.interface'

@jsonObject
export class CityModel implements ICityModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  code: string = ''

  @jsonMember({ constructor: String })
  name: string = ''
}