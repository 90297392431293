import React from 'react'
import styles from './index.module.scss'
import { Button, InputAdornment, TextField, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface Props {
  actionText?: string
  value?: string
  onKeyPress?: (key: string) => void
  onInput?: (value: string) => void
  onAction?: () => void
  placeholder?: string
}

const SearchInput = ({ onAction, onInput, onKeyPress, value, actionText, placeholder }: Props) => {

  const theme = useTheme()

  return (
    <TextField
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInput && onInput(e.target.value)}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress && onKeyPress(e.key)}
      value={value}
      variant='outlined'
      placeholder={placeholder}
      color='primary'
      fullWidth
      InputProps={{
        classes: { root: styles['keyword-input'] },
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon name='search' width={24} height={24}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <Button
              onClick={onAction}
              style={{ backgroundColor: theme.palette.primary.contrastText }}
              variant='text'
              color='primary'
              disableRipple
            >
              {actionText}
            </Button>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchInput
