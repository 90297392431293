import * as React from 'react'
import { Popper, TextField } from '@material-ui/core'
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete'
import { useEffect, useState } from 'react'
import { getCompanyListByName } from 'store/hp/company-profiles/listing/actions'
import { getSimilarCompaniesGroups } from 'store/hp/market-research/actions'
import { getCompaniesWithGroups, getFoundCompaniesFlat } from 'store/hp/company-groups/actions'
import { CompanyWithGroup } from 'models/hp/companies/companies.model'
import styles from './index.module.scss'

interface Props {
  handleChange: (company: CompanyWithGroup[]) => void
}

let timeoutId: number = 0
let savedCompaniesByGroups: CompanyWithGroup[] = []
let selectedCompanies: CompanyWithGroup[] = []

const CompanyGroupOptions = (props: Props) => {
  const [options, setOptions] = useState([] as CompanyWithGroup[])

  const fetchSimilarCompaniesGroups = () => {
    getSimilarCompaniesGroups()
      .then((data) => {
        savedCompaniesByGroups = data.map(group => getCompaniesWithGroups(group)).flat()
        setOptions(prev => prev.concat(savedCompaniesByGroups))
      })
      .catch(console.log)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: CompanyWithGroup[]) => {
    selectedCompanies = newValue
    props.handleChange(selectedCompanies)
  }

  const handleGroupClick = (groupId: string) => {
    if(!groupId) return
    const companiesFromGroup = options.filter((company: CompanyWithGroup) => company.groupId === groupId)
    const groupSelected = selectedCompanies.find(c => c.groupId === groupId)

    if(groupSelected) {
      selectedCompanies = selectedCompanies.filter(c => c.groupId !== groupId)
    } else{
      selectedCompanies = [...selectedCompanies, ...companiesFromGroup]
    }
    props.handleChange(selectedCompanies)
  }

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (!value || value === '') return

    timeoutId && window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      getCompanyListByName(value)
        .then(items => {
          const baseOptions = savedCompaniesByGroups.concat(selectedCompanies)
          const foundOptions = getFoundCompaniesFlat(items, baseOptions)
          setOptions(baseOptions.concat(foundOptions))
        })
        .catch(console.log)
    }, 750)
  }

  useEffect(() => {
    fetchSimilarCompaniesGroups()
    return () => {
      savedCompaniesByGroups = []
      selectedCompanies = []
    }
  }, [])

  const renderGroup = (params: AutocompleteRenderGroupParams, options: any) => {
    const currentGroup = options.find((o: CompanyWithGroup) => o.groupId === params.group)
    return (
      <div key={params.key}>
        <p
          className={[styles['option-group'], params.group ? styles['clickable'] : ''].join(' ')}
          onClick={() => handleGroupClick(currentGroup.groupId)}
        >
          {currentGroup.groupName}
        </p>
        {params.children}
      </div>
    )
  }

  const CustomPopper = function(props: any) {
    return (
      <Popper {...props}/>
    )
  }

  return (
    <Autocomplete
      id='company-group-options'
      multiple
      value={selectedCompanies}
      onChange={handleChange}
      onInputChange={handleInputChange}
      groupBy={(option: CompanyWithGroup) => option.groupId}
      renderGroup={(params) => renderGroup(params, options)}
      fullWidth
      options={options}
      PopperComponent={CustomPopper}
      getOptionSelected={(option, value) => option.companyDuns === value.companyDuns}
      getOptionLabel={(option: CompanyWithGroup) => option.companyName}
      renderOption={(option: CompanyWithGroup) => <div>{option.companyName}</div>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          color='primary'
          fullWidth
          label='Company'
        />
      )}
    />
  )
}

export default CompanyGroupOptions
