import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import styles from './index.module.scss'
import React from 'react'
import classNames from 'classnames'

interface ICustomLoaderProps {
  size?: number
  className?: string
}

const useStyles = makeStyles({
  root: {
    margin: 'auto'
  }
})
const CustomLoader = ({ size, className }: ICustomLoaderProps) => {

  const classes = useStyles()

  return (
    <div className={classNames(styles['main'], className)}>
      <CircularProgress className={classes.root} size={size}/>
    </div>
  )
}

export default CustomLoader