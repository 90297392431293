export const AFTER_SIGN_UP = 'AFTER_SIGN_UP'
export const FROM_DETAILS = 'FROM_DETAILS'

export const afterSignUpAction = (value = false) => ({
  type: AFTER_SIGN_UP,
  payload: value
})

export const signUpFromDetails = (value: string) => ({
  type: FROM_DETAILS,
  payload: value
})
