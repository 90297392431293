import React from 'react'
import styles from './index.module.scss'
import { useHistory } from 'react-router-dom'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Menu, MenuItem, useTheme } from '@material-ui/core'
import { removeToken } from 'utils/localStorage'
import { globalReset } from 'store/reducers'
import { userSelector } from 'store/common/user/selectors'
import SvgIcon from '../SvgIcon'
import { IMainRoute } from 'common/routes'

interface ProfileMenuProps {
  currentRoute: IMainRoute | null
}

export const ProfileMenu = ({currentRoute}: ProfileMenuProps) => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector(userSelector) as IUserBaseModel

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = (path?: string) => {
    setAnchorEl(null)
    if (path) {
      history.push(path)
    }
  }

  const onLogout = () => {
    removeToken()
    globalReset(dispatch)
    handleClose('/auth/sign-in')
  }

  return (
    <div className={styles['profile-menu-wrapper']}>
      <IconButton
        disableRipple={false}
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <SvgIcon height={24} width={24} name='user' color={theme.palette.primary.contrastText}/>
      </IconButton>
      <Menu
        style={{ zIndex: 9999 }}
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <div onMouseLeave={() => handleClose()}>
          {user && user.role === 'owner' && (
            <MenuItem
              style={{ backgroundColor: theme.palette.common.white }}
              onClick={() => handleClose('/owner')}>
              Admin page
            </MenuItem>
          )}
          <MenuItem
            style={{ backgroundColor: theme.palette.common.white }}
            onClick={() => handleClose('/plans')}>
            Subscription
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: theme.palette.common.white }}
            onClick={() => handleClose('/user/profile')}>
            Profile
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: theme.palette.common.white }}
            onClick={() => handleClose('/contracts/trashed')}>
            Trash
          </MenuItem>
          <MenuItem style={{ backgroundColor: theme.palette.common.white }} onClick={() => onLogout()}>Logout</MenuItem>
        </div>
      </Menu>
    </div>
  )
}
