import React from 'react'
import RecoverPassword from './RecoverPassword'
import CheckPasswordToken from './CheckPasswordToken'
import ResetPassword from './ResetPassword'
import { Switch, Route } from 'react-router-dom'

const Password = () => {
  return (
    <Switch>
      <Route path={'/auth/recover/send'}>
        <RecoverPassword/>
      </Route>
      <Route path={'/auth/recover/check-code/:email'}>
        <CheckPasswordToken/>
      </Route>
      <Route path={'/auth/recover/reset-password/:email/:token'}>
        <ResetPassword/>
      </Route>
    </Switch>
  )
}

export default Password
