import React, { useState } from 'react'
import Modal from 'components/v2/Modal'
import { Formik, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  createLazyUserAccount,
  setPromptedStateForLazyAccountCreation,
} from 'store/common/user/actions'
import { CreateLazyAccountModel } from 'models/common/user/user.model'
import { setRfpCategorization } from 'store/hp/rfp/action'
import { HpRfpModel } from 'models/hp/rfp/rfp.model'
import { userLazyAccountSelector } from 'store/common/user/selectors'
import { selectedFiltersSelector } from 'store/hp/search/selectors'
import { createFulfilledSearch } from 'store/hp/search/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import {
  CREATE_LAZY_ACCOUNT_FORM_INIT_VALUE,
  CREATE_LAZY_ACCOUNT_FORM_VALIDATION_SCHEMA, ICreateLazyAccountForm
} from 'modules/Auth/SignIn/components/Form'
import FormRenderer from './FormRenderer'

interface Props {
  onClose: () => void
  open: boolean
  onSend: () => void
  title?: string
  description?: string | React.ReactChild
}

const CreateLazyAccountModal = ({onClose, open, onSend, title, description}: Props) => {
  const dispatch = useDispatch()
  const {show} = useNotification()
  const promptInfo = useSelector(userLazyAccountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const [isRequested, setIsRequested] = useState(false)

  const setViewedContractsAsLiked = async () => {
    if (promptInfo.viewedContracts.length > 0) {
      await Promise.all(promptInfo.viewedContracts.map(contract => setRfpCategorization(dispatch)(contract as HpRfpModel, 'pipeline__1', undefined, true)))
    }
  }

  const createSearch = async () => {
    const name = selectedFilters.keywords.join(' ')
    if (name) {
      await createFulfilledSearch(dispatch)(selectedFilters, name, true)
    }
  }

  const onSubmit = async (values: ICreateLazyAccountForm) => {
    setPromptedStateForLazyAccountCreation()
    setIsRequested(true)
    try {
      await createLazyUserAccount({email: values.email} as CreateLazyAccountModel)
      await setViewedContractsAsLiked()
      await createSearch()
    } catch (error) {
      error.data.errors.length && error.data.errors[0].message && show(error.data.errors[0].message, NotificationTypeList.Error)
    } finally {
      setIsRequested(false)
      onSend()
    }
  }

  return (
    <Formik
      initialErrors={CREATE_LAZY_ACCOUNT_FORM_INIT_VALUE}
      initialValues={CREATE_LAZY_ACCOUNT_FORM_INIT_VALUE}
      validationSchema={CREATE_LAZY_ACCOUNT_FORM_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formProps: FormikProps<ICreateLazyAccountForm>) => (
        <Modal
          title={title || 'Get alerts when similar contracts come out'}
          onClose={onClose}
          onConfirm={formProps.handleSubmit}
          confirmLoading={isRequested}
          open={open}
        >
          <FormRenderer {...formProps} isRequested={isRequested} description={description}/>
        </Modal>
      )}
    </Formik>
  )
}

export default CreateLazyAccountModal