import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class VehicleModel {
  public get text(): string {
    return this.name
  }
  @jsonMember({constructor: String})
  name: string = ''
}

@jsonObject
export class VehicleResponseModel extends BaseResponseModel {
  @jsonArrayMember(VehicleModel)
  data: VehicleModel[] = []
}

@jsonObject
export class VehicleDetailModel {
  @jsonArrayMember(String)
  gwac: string[] = []
  @jsonArrayMember(String)
  schedules: string[] = []
  @jsonArrayMember(String)
  multiAward: string[] = []
  @jsonArrayMember(String)
  singleAward: string[] = []
}

@jsonObject
export class VehicleDetailsResponseModel extends BaseResponseModel {
  @jsonMember({constructor: VehicleDetailModel})
  data: VehicleDetailModel = new VehicleDetailModel()
}
