import * as React from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { KeywordOptionWithGroupModel } from 'models/hp/keyword/keyword.model'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete'

const filter = createFilterOptions()

interface Props {
  handleChange: (keywords?: string) => void
  keywords: string[]
}

const KeywordsFilterWithOptions = (props: Props) => {
  const [value, setValue] = React.useState(new KeywordOptionWithGroupModel())

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null | KeywordOptionWithGroupModel) => {
    if (typeof newValue === 'string') {
      setValue({
        keyword: newValue,
        groupName: ''
      } as KeywordOptionWithGroupModel)
      props.handleChange(newValue)
    } else {
      setValue(newValue as KeywordOptionWithGroupModel)
      if(newValue){
        props.handleChange(newValue.keyword)
      } else {
        props.handleChange()
      }
    }
  }

  const getFilterOptions = (options: KeywordOptionWithGroupModel[], params: FilterOptionsState<any>) => {
    const filtered = filter(options, params) as KeywordOptionWithGroupModel[]

    const {inputValue} = params
    // Suggest the creation of a new value
    const isExisting = options.some((option: KeywordOptionWithGroupModel) => inputValue === option.keyword)
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        keyword: `Add "${inputValue}"`,
        groupName: ''
      } as KeywordOptionWithGroupModel)
    }

    return filtered
  }

  const getOptionLabel = (option: KeywordOptionWithGroupModel | string) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue
    }
    // Regular option
    return option.keyword
  }

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      filterOptions={getFilterOptions}
      groupBy={(option: KeywordOptionWithGroupModel) => option.groupName}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id='keywords-filter-with-options'
      options={[]}
      getOptionLabel={getOptionLabel}
      renderOption={(option: KeywordOptionWithGroupModel) => <div>{option.keyword}</div>}
      freeSolo
      renderInput={(params) => (
        <TextField
          value={value}
          variant='outlined'
          color='primary'
          fullWidth
          {...params} label='Keyword'/>
      )}
    />
  )
}

export default React.memo(KeywordsFilterWithOptions, (prev, next) => prev.keywords === next.keywords)
