import { IAction } from 'types/common'
import { Record } from 'immutable'
import { IContactList, IContactsChart, ICustomerFinderData, IEducationPhaseClass, IPartnersFinderResponse } from 'models/hp/education/education.interface'
import { EducationPhaseClass, EducationProgressData } from 'models/hp/education/education.model'
import { 
  
  SET_CURRENT_PHASE_CLASS,
  SET_PHASE_PROGRESS_VALUE_BOOL,
  GET_CONTACTS_CHART,
  GET_CONTACTS_CHART_SUCCESS,
  GET_CONTACTS_CHART_ERROR,
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_ERROR,
  CLEAR_CONTACTS,
  GET_CUSTOMER_FINDER_DASHBOARD,
  GET_CUSTOMER_FINDER_DASHBOARD_ERROR,
  GET_CUSTOMER_FINDER_DASHBOARD_SUCCESS,
  GET_CUSTOMER_FINDER_CONTACTS,
  GET_CUSTOMER_FINDER_CONTACTS_ERROR,
  GET_CUSTOMER_FINDER_CONTACTS_SUCCESS,
  CLEAR_CUSTOMER_FINDER_CONTACTS,
  GET_PARTNERS_FINDER_DASHBOARD,
  GET_PARTNERS_FINDER_DASHBOARD_SUCCESS,
  GET_PARTNERS_FINDER_DASHBOARD_ERROR,
  GET_PARTNERS_FINDER_CONTACTS,
  GET_PARTNERS_FINDER_CONTACTS_SUCCESS,
  GET_PARTNERS_FINDER_CONTACTS_ERROR,
  CLEAR_PARTNERS_FINDER_CONTACTS,
  GET_CONTACTS_BY_ID,
  GET_CONTACTS_BY_ID_SUCCESS,
  GET_CONTACTS_BY_ID_ERROR,
  GET_PARTNERS_FINDER_CONTACTS_BY_ID,
  GET_PARTNERS_FINDER_CONTACTS_BY_ID_SUCCESS,
  GET_PARTNERS_FINDER_CONTACTS_BY_ID_ERROR,
  GET_PARTNERS_FINDER_DASHBOARD_BY_ID,
  GET_PARTNERS_FINDER_DASHBOARD_BY_ID_SUCCESS,
  GET_PARTNERS_FINDER_DASHBOARD_BY_ID_ERROR
} from 'store/hp/education/action'
import { IEducationProgressData } from 'modules/Main/Education/components/EducationProgressBarSvg'

export interface IEducationRecord {
  currentPhaseClass: IEducationPhaseClass
  progressData: Record<IEducationProgressData>,
  contactsChart: {
    loaded: boolean,
    loading: boolean,
    data: IContactsChart | null
  },
  contactsList: {
    loaded: boolean,
    loading: boolean,
    data: IContactList[] | null
  },
  customerFinderDashboard: {
    loaded: boolean,
    loading: boolean,
    data: IContactsChart | null
  },
  customerFinderContacts: {
    loaded: boolean,
    loading: boolean,
    data: ICustomerFinderData | null
  },
  partnersFinderDashboard: {
    loaded: boolean,
    loading: boolean,
    data: IPartnersFinderResponse | null
  },
  partnersFinderContacts: {
    loaded: boolean,
    loading: boolean,
    data: ICustomerFinderData | null
  },
}

const EducationRecord = Record<IEducationRecord>({
  currentPhaseClass: new EducationPhaseClass(),
  progressData: new EducationProgressData(),
  contactsChart: {
    loaded: false,
    loading: false,
    data: null
  },
  contactsList: {
    loaded: false,
    loading: false,
    data: null
  },
  customerFinderDashboard: {
    loaded: false,
    loading: false,
    data: null
  },
  customerFinderContacts: {
    loaded: false,
    loading: false,
    data: null
  },
  partnersFinderDashboard: {
    loaded: false,
    loading: false,
    data: null
  },
  partnersFinderContacts: {
    loaded: false,
    loading: false,
    data: null
  },
})

export const educationReducer = (state: Record<IEducationRecord> = new EducationRecord(), action: IAction) => {
  switch (action.type) {
    case SET_CURRENT_PHASE_CLASS:
      return state.set('currentPhaseClass', action.payload)

    case SET_PHASE_PROGRESS_VALUE_BOOL:
      return state
        .setIn(['progressData', 'planning'], action.payload.value)
    case GET_CONTACTS_CHART:
      return state
        .update('contactsChart', (contactsChart => ({...contactsChart, loading: true})))
    case GET_CONTACTS_CHART_SUCCESS:
      return state
        .update('contactsChart', (contactsChart => ({...contactsChart, loading: false, loaded: true, data: action.payload.data})))
    case GET_CONTACTS_CHART_ERROR:
      return state
        .update('contactsChart', (contactsChart => ({...contactsChart, loading: false, loaded: false})))

    case GET_CONTACTS:
      return state
        .update('contactsList', (contactsList => ({...contactsList, loading: true})))
    case GET_CONTACTS_SUCCESS:
      return state
        .update('contactsList', (contactsList => ({...contactsList, loading: false, loaded: true, data: action.payload.data.connections})))
    case GET_CONTACTS_ERROR:
      return state
        .update('contactsList', (contactsList => ({...contactsList, loading: false})))
    case CLEAR_CONTACTS:
      return state
        .update('contactsList', (contactsList => ({...contactsList, loading: false, loaded: false, data: null})))
    case GET_CONTACTS_BY_ID:
      return state
    case GET_CONTACTS_BY_ID_SUCCESS:
      return state
        .update('contactsList', (contactsList => ({...contactsList, data: contactsList.data?.map(el => el.id === action.payload.id ? {...el, offset: action.payload.offset, contacts: [...el.contacts, ...action.payload.contacts]} : el) as IContactList[] })))
    case GET_CONTACTS_BY_ID_ERROR:
      return state
        .update('contactsList', (contactsList => ({...contactsList, loading: false})))
    case GET_CUSTOMER_FINDER_DASHBOARD:
      return state
        .update('customerFinderDashboard', (customerFinderDashboard => ({ ...customerFinderDashboard, loading: true })))
    case GET_CUSTOMER_FINDER_DASHBOARD_SUCCESS:
      return state
        .update('customerFinderDashboard', (customerFinderDashboard => ({ ...customerFinderDashboard, loading: false, loaded: true, data: action.payload.data })))
    case GET_CUSTOMER_FINDER_DASHBOARD_ERROR:
      return state
        .update('customerFinderDashboard', (customerFinderDashboard => ({ ...customerFinderDashboard, loading: false })))
    case GET_CUSTOMER_FINDER_CONTACTS:
      return state
        .update('customerFinderContacts', (customerFinderContacts) => ({ ...customerFinderContacts, loading: true }))
    case GET_CUSTOMER_FINDER_CONTACTS_SUCCESS:
      return state
        .update('customerFinderContacts', (customerFinderContacts) => ({ ...customerFinderContacts, loading: false, loaded: true, data: action.payload.data }))
    case GET_CUSTOMER_FINDER_CONTACTS_ERROR:
      return state
        .update('customerFinderContacts', (customerFinderContacts) => ({ ...customerFinderContacts, loading: false }))
    case CLEAR_CUSTOMER_FINDER_CONTACTS:
      return state
        .update('customerFinderContacts', (customerFinderContacts) => ({ ...customerFinderContacts, loading: false, loaded: false, data: null }))
    case GET_PARTNERS_FINDER_DASHBOARD:
      return state
        .update('partnersFinderDashboard', (partnersFinderDashboard) => ({ ...partnersFinderDashboard, loading: true }))
    case GET_PARTNERS_FINDER_DASHBOARD_SUCCESS:
      return state
        .update('partnersFinderDashboard', (partnersFinderDashboard) => ({ ...partnersFinderDashboard, loading: false, loaded: true, data: action.payload.data }))
    case GET_PARTNERS_FINDER_DASHBOARD_ERROR:
      return state
        .update('partnersFinderDashboard', (partnersFinderDashboard) => ({ ...partnersFinderDashboard, loading: false }))
    case GET_PARTNERS_FINDER_CONTACTS:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, loading: true }))
    case GET_PARTNERS_FINDER_CONTACTS_SUCCESS:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, loading: false, loaded: true, data: action.payload.data }))
    case GET_PARTNERS_FINDER_CONTACTS_ERROR:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, loading: false }))
    case CLEAR_PARTNERS_FINDER_CONTACTS:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, loading: false, loaded: false, data: null }))
    case GET_PARTNERS_FINDER_CONTACTS_BY_ID:
      return state
    case GET_PARTNERS_FINDER_CONTACTS_BY_ID_SUCCESS:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, data: partnersFinderContacts.data && {...partnersFinderContacts.data, 
          connections: partnersFinderContacts.data?.connections?.map(el => el.id === action.payload.id ? {...el, offset: action.payload.offset, contacts: [...el.contacts, ...action.payload.contacts]} : el) as IContactList[]} }))
    case GET_PARTNERS_FINDER_CONTACTS_BY_ID_ERROR:
      return state
        .update('partnersFinderContacts', (partnersFinderContacts) => ({ ...partnersFinderContacts, loading: false }))
    case GET_PARTNERS_FINDER_DASHBOARD_BY_ID:
      return state
    case GET_PARTNERS_FINDER_DASHBOARD_BY_ID_SUCCESS:
      return state
        .update('partnersFinderDashboard', (partnersFinderDashboard => ({ ...partnersFinderDashboard, data: partnersFinderDashboard.data && 
          {...partnersFinderDashboard.data, 
            agencies: partnersFinderDashboard.data.agencies.map(el => el.id === action.payload.id ? {...el, companies: [...el.companies, ...action.payload.companies], offset: action.payload.offset } : el)}  
        })))
    case GET_PARTNERS_FINDER_DASHBOARD_BY_ID_ERROR:
      return state
    default:
      return state
  }
}