import moment from 'moment'
import Paths from 'utils/paths'
import { request, requestAndDownload } from 'utils/http'
import { RequestMethods } from 'utils/const'
import { CreateTokenResponseModel, TokenModel, TokenModelResponseModel } from 'models/common/owner/token.model'
import { BaseResponseModel } from 'models/base/base.model'
import { Pagination } from 'models/base/base.interfaces'
import { Values } from 'modules/Main/Owner/modules/Tokens/Item'
import {
  UserOwnerActivitiesResponseModel,
  UserOwnerActivityModel,
  UserOwnerResponseModel
} from 'models/common/user/user.model'
import { IPermissions } from 'modules/Main/Owner/modules/Users/components/GrantPermissionsModal'

export const getTokens = async (): Promise<TokenModel[]> => {
  const res = await request<TokenModelResponseModel>(RequestMethods.Get, Paths.Owner.Token.get, {
    schema: TokenModelResponseModel
  })
  return res.data.data
}

export const updateToken = async (id: string, body: Values): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Put, Paths.Owner.Token.update(id), {
    body,
    schema: BaseResponseModel
  })
  return res.data
}

export const createToken = async (token: string): Promise<TokenModel> => {
  const res = await request<CreateTokenResponseModel>(RequestMethods.Post, Paths.Owner.Token.create, {
    body: { token },
    schema: CreateTokenResponseModel
  })
  return res.data.data
}

export const deleteToken = async (id: string): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Delete, Paths.Owner.Token.delete(id), {
    schema: BaseResponseModel
  })
  return res.data
}

export const getUsers = async (pagination: Pagination): Promise<UserOwnerResponseModel> => {
  const res = await request<UserOwnerResponseModel>(RequestMethods.Get, Paths.Owner.User.get(pagination), {
    schema: UserOwnerResponseModel
  })
  return res.data
}

export const getUserDetails = async (id: number): Promise<UserOwnerActivityModel[]> => {
  const res = await request<UserOwnerActivitiesResponseModel>(RequestMethods.Get, Paths.Owner.User.getById(id), {
    schema: UserOwnerActivitiesResponseModel
  })
  return res.data.data
}

export const downloadUsersReport = async () => {
  const now = moment().format('YYYY-MM-DD')
  await requestAndDownload(RequestMethods.Get, Paths.Owner.User.download, {
    schema: BaseResponseModel,
    fileName: `users-report-${now}.csv`
  })
}

export const downloadUserActivitiesReport = async () => {
  const now = moment().format('YYYY-MM-DD')
  await requestAndDownload(RequestMethods.Get, Paths.Owner.User.downloadActivities, {
    schema: BaseResponseModel,
    fileName: `users-activities-report-${now}.csv`
  })
}

export const manageSubscription = async (values: IPermissions, userId: number) => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Owner.User.manageSubscription(userId), {
    schema: BaseResponseModel,
    body: values
  })
  return res.data
}

export const deleteSubscription = async (userGroupId: string, userId: number) => {
  const res = await request<BaseResponseModel>(RequestMethods.Delete, Paths.Owner.User.deleteSubscription(userId), {
    schema: BaseResponseModel,
    body: { userGroupId: userGroupId || null }
  })
  return res.data
}

export const adminUploadContractsFromFile = async (base64: string) => {
  await request(RequestMethods.Post, Paths.Owner.Contract.upload, { body: { base64 } })
}

// ------------------------------------- User activities -------------------------------------

export const userActivity = {
  activities: {
    createAccount: 'create-account',
    lastLogin: 'last-login',
    closeApp: 'close-app',

    rfpDetails: 'rfp-details',
    rfpLike: 'rfp-like',
    rfpTrash: 'rff-trash',

    joinVendorsPrimes: 'join-vendors-primes',
    joinVendorsSubs: 'join-vendors-subs',

    openEstimatedValueCalculator: 'open-estimated-value-calculator',
    enterEstimatedValueCalculator: 'enter-estimated-value-calculator',
    openPWinCalculator: 'open-p-win-calculator',
    enterPWinCalculator: 'enter-p-win-calculator',
    openCostToPursueCalculator: 'open-cost-to-pursue-calculator',
    enterCostToPursueCalculator: 'enter-cost-to-pursue-calculator',

    keywordsSearch: 'keywords-search',
    createSearch: 'create-search',

    openPscFilter: 'open-psc-filter',
    openNaicsFilter: 'open-naics-filter',
    openCustomerFilter: 'open-customer-filter',
    openLocationFilter: 'open-location-filter',
    openSetAsideFilter: 'open-set-aside-filter',
    openMaturityFilter: 'open-maturity-filter',
    openDueInFilter: 'open-due-in-filter',
    enterPscFilter: 'enter-psc-filter',
    enterNaicsFilter: 'enter-naics-filter',
    enterCustomerFilter: 'enter-customer-filter',
    enterLocationFilter: 'enter-location-filter',
    enterSetAsideFilter: 'enter-set-aside-filter',
    enterMaturityFilter: 'enter-maturity-filter',
    enterDueInFilter: 'enter-due-in-filter',

    openCompanySearchPage: 'open-company-search-page',
    openGroupSearchPage: 'open-group-search-page',
    openContractsSearchPage: 'open-contracts-search-page',
    openSavedSearches: 'open-saved-searches',
    openCategorizedItemsPage: 'open-categorized-items-page',
    openTrashedItemsPage: 'open-trashed-items-page',

    marketResearchToolSap: 'market-research-tool-sap',
    marketResearchToolSapUsed: 'market-research-tool-sap-used',
    marketResearchToolCompanies: 'market-research-tool-companies',
    marketResearchToolCompaniesUsed: 'market-research-tool-companies-used',
    marketResearchToolContracts: 'market-research-tool-contracts',
    marketResearchToolContractsUsed: 'market-research-tool-contracts-used',
    marketResearchToolCodes: 'market-research-tool-codes',
    marketResearchToolCodesUsed: 'market-research-tool-codes-used',
    marketResearchToolKeywords: 'market-research-tool-keywords',
    marketResearchToolKeywordsUsed: 'market-research-tool-keywords-used',
  },
  dynamicActivities: {
    rfpDetailsTab: {
      key: 'rfp-details__tab',
      getType(tab: string) { return `${this.key}_${tab}` }
    },
  },
  event: (type: string, data?: string) => {
    request<BaseResponseModel>(RequestMethods.Post, Paths.Owner.User.setUserActivity, {
      schema: BaseResponseModel,
      body: { type, data },
      lazyUser: true
    }).catch(console.log)
  }
}