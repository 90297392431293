import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import RouteWithRedirect from 'components/v2/RouteWithRedirect'
import Auth from 'modules/Auth'
import NotificationSnackbar from 'components/Notification'
import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MainFrame from './components/MainFrame'
import Policies from 'modules/Main/Policies'
import { ReactComponent as SpriteSvg } from 'assets/icons-sprite/sprite.svg'
import { RouterProvider } from './context/RouterContext'
import { userActivity } from 'store/common/owner/actions'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      overflowY: 'hidden',
    },
    content: {
      overflowY: 'hidden',
      width: '100%'
    }
  }),
)

// Track app closing
window.addEventListener('beforeunload', (e) => {
  e.preventDefault()
  userActivity.event(userActivity.activities.closeApp)
  return e.returnValue = ''
})

function App() {
  const classes = useStyles()

  return (
    <>
      <NotificationSnackbar/>
      <div style={{display: 'none'}}>
        <SpriteSvg/>
      </div>
      <Router>
        <RouterProvider>
          <Switch>
            <Route path={'/auth'}>
              <Auth/>
            </Route>
            <Route path={'/policies'}>
              <Policies />
            </Route>
            <RouteWithRedirect path='/'>
              <div className={classes.root}>
                <main className={classes.content}>
                  <MainFrame/>
                </main>
              </div>
            </RouteWithRedirect>
          </Switch>
        </RouterProvider>
      </Router>
    </>
  )

}

export default App
