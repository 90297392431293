import React from 'react'
import styles from './index.module.scss'
import { TaskItem } from 'models/hp/pipeline-task/pipeline-task.model'
import { PipelineTaskStatuses } from 'utils/const'
import FormItem from './FormItem'
import { Grid, Button, useTheme, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import NavigationBtns from './NavigationBtns'

const getDefaultTask = () => {
  return {
    id: '',
    name: '',
    type: PipelineTaskStatuses.government__1,
    text: '',
    role: '',
    date: undefined,
    assignedUser: undefined,
    assignedUserId: undefined,
    month: undefined,
    step: '0'
  }
}

interface TaskState {
  [key: string]: TaskItem
}

const RelationshipWorksheet = () => {
  const theme = useTheme()

  const [tasks, setTasks] = React.useState<TaskState>({
    '': getDefaultTask()
  })

  const addNew = () => {
    setTasks(prev => ({
      ...prev,
      '': getDefaultTask()
    }))
  }

  const handleSave = (task: TaskItem, isCreating?: boolean) => {
    if (isCreating) {
      setTasks(prev => {
        delete prev['']
        return {
          ...prev,
          [task.id]: task
        }
      })
    } else {
      setTasks(prev => ({
        ...prev,
        [task.id]: task
      }))
    }
  }

  const emptyRowAdded: boolean = !!tasks['']
  return (
    <div className={styles['block']}>
      <Typography variant='h3'>Relationship Worksheet</Typography>
      <Grid
        container
        justifyContent='flex-end'
        className={styles['gutter-bottom']}
      >
        <Button
          color='primary'
          disabled={emptyRowAdded}
          variant='outlined'
          onClick={addNew}
          startIcon={(
            <SvgIcon
              name='plus'
              color={emptyRowAdded ? theme.palette.grey['400'] : theme.palette.primary.main}
            />
          )}
        >
          Add new
        </Button>
      </Grid>
      <div className={styles['gutter-bottom']}>
        {Object.values(tasks).map(task => (
          <FormItem
            key={task.id}
            task={task}
            handleSave={handleSave}
          />
        ))}
      </div>
      <div>
        <NavigationBtns />
      </div>
    </div>
  )
}

export default RelationshipWorksheet