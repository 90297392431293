import React from 'react'
import { Grid, Paper, Button, Typography, useTheme, IconButton } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'
import PscImg, { PscProps } from 'components/v2/PscImg'
import WithTooltip from 'components/WithTooltip'
import { copyToClipboard } from 'common/copy'
import { makeStyles } from '@material-ui/core/styles'

type ActionType = 'discuss' | 'share' | 'redirect' | 'download' | 'like' | 'delete' | 'navigate' | 'stepCompleted' | 'snooze'
type Actions = {
  [key in ActionType]?: {
    element?: () => React.ReactNode,
    handler?: () => void
    state?: boolean,
  }
}

interface Props {
  title: string
  titleClass?: string
  type?: string
  activeContract?: boolean
  pscCode?: string
  actions?: Actions
  children?: React.ReactNode
  previewProps?: PscProps
  isLink?: boolean
}

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
})

const Header = ({ title, titleClass, activeContract, type, pscCode, actions, children, previewProps, isLink }: Props) => {
  const theme = useTheme()
  const classes = useStyles()

  const renderTitle = () => {
    if (type && type === 'Hot Fill') {
      return `May need subs: ${title}`
    }
    if (type && type === 'Re-compete') {
      return `May re-compete: ${title}`
    }
    if (activeContract === false) {
      return `Expired: ${title}`
    }
    return title
  }

  return (
    <Paper className={styles['header']}>
      <Grid container alignItems='center' justifyContent='space-between' spacing={3} wrap='nowrap'>
        <Grid item className={styles['header-img']}>
          <PscImg
            {...previewProps}
            pscCode={pscCode}
          />
        </Grid>
        <Grid item className={styles['header-text']}>
          <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'>
            <Grid item md={8}>
              <Typography
                variant='h2'
                className={[
                  previewProps && classes.multiLineEllipsis,
                  styles['header-title'],
                  titleClass,
                  actions?.navigate && styles['header-title-clickable']
                ].filter(x => !!x).join(' ')}
                onClick={actions?.navigate?.handler}
                style={{color: isLink ? theme.palette.info.dark : ''}}
              >
                { renderTitle() }
              </Typography>
            </Grid>

            {actions && (actions.like || actions.delete || actions.stepCompleted || actions.snooze) && (
              <Grid item md={4} className={styles['header-actions']}>
                {actions.like && (
                  actions.like.element ? actions.like.element() : (
                    <Grid item md={8} sm={8}>
                      {!actions.like.state && (
                        <Button
                          fullWidth
                          variant='outlined'
                          onClick={actions.like.handler}
                          color={'primary'}
                        >
                          Add to Pipeline
                        </Button>
                      )}
                    </Grid>
                  )
                )}
                {actions.delete && (
                  actions.delete.element ? actions.delete.element() : (
                    <IconButton
                      onClick={actions.delete.handler}
                      style={actions.delete.state ? {backgroundColor: theme.palette.error.main} : undefined}
                    >
                      <SvgIcon
                        name='trash'
                        color={actions.delete.state ? theme.palette.error.contrastText : theme.palette.error.main}
                        width={30}
                        height={30}
                      />
                    </IconButton>
                  )
                )}
                {actions.stepCompleted && (
                  <Button
                    variant='contained'
                    onClick={actions.stepCompleted.handler}
                    color={'primary'}
                    size='small'
                  >
                    Completed
                  </Button>
                )}
                {actions.snooze && (
                  <Button
                    variant='contained'
                    onClick={actions.snooze.handler}
                    style={{marginLeft: '4px'}}
                    size='small'
                  >
                    Snooze (1 week)
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          { actions && (
            <Grid container>
              {actions.discuss && (
                <Button
                  color='primary'
                  startIcon={<SvgIcon name='comment-lines' color={theme.palette.primary.main} />}
                  onClick={actions.discuss.handler}
                >
                  Discuss
                </Button>
              )}
              {actions.share && (
                <WithTooltip title='Copied!' onOpen={() => copyToClipboard(window.location.href)}>
                  <Button
                    color='primary'
                    startIcon={<SvgIcon name='share-alt' color={theme.palette.primary.main} />}
                    onClick={actions.share.handler}
                  >
                    Share
                  </Button>
                </WithTooltip>
              )}
              {actions.redirect && (
                <Button
                  color='primary'
                  startIcon={<SvgIcon name='external-link-alt' color={theme.palette.primary.main} />}
                  onClick={actions.redirect.handler}
                >
                  SAM.gov
                </Button>
              )}
              {actions.download && (
                <Button
                  color='primary'
                  startIcon={<SvgIcon name='cloud-download' color={theme.palette.primary.main} />}
                  onClick={actions.download.handler}
                >
                  Download
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {children}
    </Paper>
  )
}

export default Header