import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { IVerifyForm, VERIFY_FORM_INIT_VALUE, VERIFY_FORM_VALIDATION_SCHEMA } from './components/Form'
import FormRenderer from './components/FormRenderer'
import { useDispatch } from 'react-redux'
import { getUserAction, userVerifyNumberAction } from 'store/common/user/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { afterSignUpAction, signUpFromDetails } from 'store/common/sign-up/actions'
import { getQueryValueByKey } from 'common/query'

const Verify = () => {
  const { email } = useParams()
  const history = useHistory()

  const { show } = useNotification()

  const rfpId = getQueryValueByKey('rfpId', history.location.search)

  const dispatch = useDispatch()

  const [ isRequested, setIsRequested ] = useState<boolean>(false)

  const onSubmit = async (values: IVerifyForm) => {
    setIsRequested(prev => !prev)
    try {
      await userVerifyNumberAction(email, +values.verificationCode)
      setIsRequested(prev => !prev)
      rfpId ? dispatch(signUpFromDetails(rfpId)) : dispatch(afterSignUpAction(true))
      getUserAction()(dispatch)
      history.push('/auth/starter-search')
    } catch (error) {
      setIsRequested(prev => !prev)
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
    }
  }

  return (
    <Formik
      initialErrors={VERIFY_FORM_INIT_VALUE}
      initialValues={VERIFY_FORM_INIT_VALUE}
      validationSchema={VERIFY_FORM_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IVerifyForm>) => (
        <FormRenderer {...formikProps} isRequested={isRequested} email={email}/>
      )}
    </Formik>
  )
}

export default Verify