import { Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './index.module.scss'
import SvgIcon from '../SvgIcon'
import React from 'react'

interface Props {
  title: string
  onClose: () => void
  children: React.ReactNode
  inBlock?: boolean
  className?: string
}

const useStyles = makeStyles({
  root: {
    padding: '0'
  }
})

const InfoDialog = ({ title, onClose, children, className, inBlock = false }: Props) => {

  const theme = useTheme()
  const classes = useStyles()

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle className={[ classes.root, styles['header-wrapper'] ].join(' ').trim()}>
        <div className={styles['header']} onClick={onClose}>
          <SvgIcon
            className={styles['back-icon']} color={theme.palette.primary.main} width={24} height={24}
            name='arrow-left'/>
          <Typography className={styles['back-text']} variant='body1'>
            Back
          </Typography>
        </div>
        <div>
          <Typography className={styles['title']} variant='h4'>{title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent className={[ classes.root, styles[inBlock ? 'body-wrapper' : ''] ].join(' ').trim()}>
        <div className={[ className, styles[inBlock ? 'body-block' : 'body'] ].join(' ').trim()}>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default InfoDialog
