import React from 'react'
import styles from './index.module.scss'
import { Button, Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { FormikProps } from 'formik'
import { IPiplinePendingAward, IPiplinePendingAwardsBody } from 'models/hp/pipeline/pipeline.interface'
import { options } from './options'

interface SaveModuleProps {
    activeContract: IPiplinePendingAward
    setActiveContract: (value: IPiplinePendingAward | null) => void
    formikProps: FormikProps<IPiplinePendingAwardsBody>
}

const SaveModule = ({activeContract, setActiveContract, formikProps}: SaveModuleProps) => {

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const descriptionArray = formikProps.values.description
    if(descriptionArray.includes(name)) {
      formikProps.setFieldValue('description', descriptionArray.filter(el => el !== name))
    } else {
      formikProps.setFieldValue('description', [...descriptionArray, name])
    }
  }

  const handleCloseForm = () => {
    formikProps.resetForm()
    setActiveContract(null)
  }

  const handleSubmit = () => {
    formikProps.submitForm()
      .then(() => {
        handleCloseForm()
      })
  }

  return (
    <div className={styles['pending-award-form-wrapper']}>
      <Paper className={styles['pending-award-form']}>
        <div className={styles['pending-award-form-header']}>
          <div className={styles['pending-award-form-header-close-btn']} onClick={handleCloseForm}>
            <SvgIcon width={20} height={20} name='multiply' />
          </div>
          <SvgIcon width={24} height={24} name='contract' />
          <div className={styles['pending-award-form-header-list']}>
            <Typography className={styles['pending-award-item-contract-item-info-name']}>{activeContract.title}</Typography>
            <Typography className={styles['pending-award-item-contract-item-info-agency']}>{activeContract.typeOfSetAside || '-'}</Typography>  
          </div>
        </div>
        <div className={styles['pending-award-form-info']}>
          <Typography className={styles['pending-award-form-info-main']}>{
            formikProps.values.outcome ?
              `Do you know why you ${formikProps.values.outcome === 'WON' ? 'won' : 'lost'}?` :
              'Do you know why you Won/Lost?'
          }</Typography>
          <Typography className={styles['pending-award-form-info-secondary']}>Select all that apply</Typography>
        </div>
        <div className={styles['pending-award-form-checkboxes']}>
          {
            options.map(option => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    color={'primary'}
                    name={option.name}
                    checked={formikProps.values.description.includes(option.name)}
                    onChange={(e) => handleCheckbox(e)}
                  />
                }
                label={
                  <Typography className={styles['pending-award-form-checkbox-label']}>{option.label}</Typography>
                }
              />
            ))
          }        
        </div>
        <Button 
          variant='contained' 
          color={'primary'}
          disabled={!formikProps.isValid}
          onClick={handleSubmit}
        >
              Save
        </Button>
        {formikProps.errors.outcome || !formikProps.values.outcome ? (
          <Typography className={styles['pending-award-form-error']}>Select outcome</Typography>
        ) : null}
      </Paper>
    </div>
  )
}

export default SaveModule