import React from 'react'
import styles from '../../index.module.scss'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import { numberToStrWithRound } from 'common/number'
import { getDiffInDates } from 'helpers/date'

interface ContractCardProps {
    contract: IPiplineTimeline
    setContractDetails: (value: IPiplineTimeline) => void
    contractDetails: IPiplineTimeline | null
    setOpenAssignedDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const ContractCard = ({contract, setContractDetails, contractDetails, setOpenAssignedDialog}: ContractCardProps) => {
  return (
    <>
      <div className={classNames(styles['gantt-task-row'], {
        [styles['gantt-task-row-active']]: contractDetails && contract.id === contractDetails.id
      })} onClick={() => setContractDetails(contract)}>
        <div className={classNames(styles['gant-task-item-column'], styles['gant-task-header-column-input'])}>
          <div className={styles['gant-task-item-info']}>
            <div className={styles['gant-task-item-info-icon']}>
              <SvgIcon name='contract' width={23} height={23} />
            </div>
            <div className={styles['gant-task-item-info-list']}>
              <Typography className={styles['gant-task-item-info-name']}>{contract.title || '-'}</Typography>
              <Typography className={styles['gant-task-item-info-agency']}>{contract.department || '-'}</Typography>
            </div>
          </div>
        </div>
        <div className={classNames(styles['gant-task-item-column'], styles['gant-task-header-column-assigned'])} onClick={() => setOpenAssignedDialog(true)}>
          <Typography className={styles['gant-task-item-column-value']}>
            {contract.assignedUser.firstName && contract.assignedUser.lastName ?
              `${contract.assignedUser.firstName} ${contract.assignedUser.lastName}` :
              '-'}
          </Typography>
        </div>
        <div className={classNames(styles['gant-task-item-column'], styles['gant-task-header-column-value'])}>
          <Typography className={styles['gant-task-item-column-value']}>{contract.calc.estimatedValue ? numberToStrWithRound(Number(contract.calc.estimatedValue)) : '-'}</Typography>
        </div>
        <div className={classNames(styles['gant-task-item-column'], styles['gant-task-header-column-percent'])}>
          {contract.calc.pWin ?
            <div className={styles['gant-task-item-column-percent']}>
              <div className={styles['gant-task-item-column-percent-cube']} />
              <Typography className={styles['gant-task-item-column-value']}>{contract.calc.pWin}%</Typography>
            </div> : '-'
          }
        </div>
        <div className={classNames(styles['gant-task-item-column'], styles['gant-task-header-column-due'])}>
          <Typography className={styles['gant-task-item-column-value']}>
            {getDiffInDates(new Date(), contract.responseDeadLine)}
            <br/>
              days
          </Typography>
        </div>
      </div>
    </>
  )
}

export default ContractCard