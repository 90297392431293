import React from 'react'
import styles from './index.module.scss'

export interface IEducationProgressData {
  planning?: boolean
  auditioning?: boolean
  luckyWinning?: boolean
  processWinning?: boolean
}

interface SvgProps {
  data: IEducationProgressData
}

const EducationProgressBarSvg = ({data}: SvgProps) => {
  return (
    <div className={styles['svg-container']} >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox={'0 0 512 512'}>
        <path
          style={{fill: data.planning ? '#fcc44d' : '#ffe4b1'}}
          d='M254.212 1.04706C248.322 4.24094 245.577 16.2955 242.752 22C233.291 41.1078 225.027 60.8075 215.742 80C213.323 85 204.137 98.3518 207.071 103.772C210.133 109.429 223.662 107 229 107L284 107C289.388 107 299.969 109.007 304.566 105.821C310.349 101.812 301.449 88.4453 299.248 84C288.798 62.897 279.516 41.2012 269.258 20C267.018 15.3699 261.891 -3.11676 254.212 1.04706z'/>
        <path
          style={{fill: data.auditioning ? '#fd6869' : '#f3aeae'}}
          d='M195.043 129.742C188.196 132.518 184.35 146.643 181.576 153C172.608 173.553 162.268 193.515 153.139 214C150.922 218.974 138.935 236.683 145.319 240.972C148.837 243.336 155.947 242 160 242L198 242L318 242L352 242C356.356 242 362.792 243.173 366.852 241.397C374.756 237.939 362.558 219.889 360.424 215C352.045 195.797 342.729 176.944 333.781 158C330.031 150.061 327.07 138.909 321.351 132.228C315.832 125.781 298.753 129 291 129L224 129C215.657 129 202.828 126.586 195.043 129.742z'/>
        <path
          style={{fill: data.luckyWinning ? '#1dd0a0' : '#a9f6e6'}}
          d='M130.213 266.028C124.156 269.602 120.316 283.72 117.576 290C108.743 310.241 98.2561 329.76 89.4244 350C87.276 354.924 75.9245 371.676 82.3187 375.972C87.0608 379.159 98.4444 377 104 377L161 377L358 377L410 377C415.345 377 426.109 379.044 430.681 375.972C437.07 371.681 425.946 354.91 423.691 350C414.633 330.271 404.616 310.928 396 291C393.183 284.484 389.063 269.73 382.787 266.028C378.758 263.651 371.52 265 367 265L329 265L188 265L147 265C142.248 265 134.441 263.533 130.213 266.028z'/>
        <path
          style={{fill: data.processWinning ? '#54a0fe' : '#a2c8fa'}}
          d='M67.213 401.028C61.2397 404.552 57.6669 417.89 54.7809 424C45.201 444.281 35.6684 464.616 26.3086 485C24.1683 489.661 13.1307 506.603 18.6042 510.972C23.6744 515.019 40.6166 512 47 512L125 512L397 512L468 512C474.297 512 489.35 514.773 494.527 510.972C500.044 506.922 488.957 489.578 486.742 485C476.484 463.799 467.203 442.104 456.752 421C453.901 415.242 450.887 402.161 443.957 400.318C435.67 398.114 424.567 400 416 400L356 400L163 400L94 400C86.9662 400 73.3701 397.395 67.213 401.028z'/>
      </svg>
    </div>
  )
}

export default EducationProgressBarSvg



