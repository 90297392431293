import React from 'react'
import styles from './index.module.scss'
import SelfEvaluation from './SelfEvaluation'
import ContractResearch from './ContractResearch'

const Research = () => {
  return (
    <div className={styles['wrapper']}>
      <SelfEvaluation/>
      <div style={{ height: '36px' }}/>
      <ContractResearch/>
    </div>
  )
}

export default Research
