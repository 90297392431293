export const copyToClipboard = (text: string) => {
  let textArea
  textArea = document.createElement('textarea')
  document.body.appendChild(textArea)
  textArea.value = text
  textArea.select()
  document.execCommand('copy')
  if (textArea && textArea.parentNode) {
    textArea.parentNode.removeChild(textArea)
  }
}
