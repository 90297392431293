import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './index.module.scss'
import { ReactComponent as MainIcon } from 'assets/svg-icons/icon-vertical.svg'
import { Button, Grid, useTheme } from '@material-ui/core'
import { IMainRoute } from 'common/routes'
import { trimRoute } from 'helpers/route'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { UnregisterCallback } from 'history'
import { useSelector } from 'react-redux'
import { getUserIncumbentGroupSelector, userSelector } from 'store/common/user/selectors'
import { UserIncumbentGroup } from 'models/common/user/user.model'
import { hpRfpDetailsSelector } from 'store/hp/rfp/selector'
import { autoFilters } from 'helpers/search'
import { ProfileMenu } from './ProfileMenu'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import LeftMenuItem from './LeftMenuItem'
import { IMainFrameContext } from 'components/MainFrame/context/MainFrameContext'
import classNames from 'classnames'
import SvgIcon from 'components/SvgIcon'

interface IProps {
  className?: string
  MainFrameContext: React.Context<IMainFrameContext>
}

const LeftMenu = ({ className, MainFrameContext }: IProps) => {
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()

  const {isHiddenSidebar, setHiddenSidebar} = useContext(MainFrameContext)

  const user = useSelector(userSelector) as IUserBaseModel
  const userIncumbentGroups = useSelector(getUserIncumbentGroupSelector) as UserIncumbentGroup[]
  const rfpDetails = useSelector(hpRfpDetailsSelector)

  const mainRoutes: IMainRoute[] = [
    {
      name: 'Contract \n\r Search',
      code: 'contracts',
      logoName: 'search',
      prevState: autoFilters.extractData(rfpDetails.data),
    },
    {
      name: 'Pipeline',
      code: 'pipeline/opportunities',
      logoName: 'filter',
    },
  ]
  const [currentRoute, setCurrentRoute] = useState<IMainRoute | null>(mainRoutes[0])

  const handleRoute = useCallback(() => {
    let childRoute = null
    const current = mainRoutes.find(r => {
      const from = new URL(window.location.href).searchParams.get('from') || ''
      const path = trimRoute(history.location.pathname)
      if (r.code === path || r.code === from) {
        return r
      }

      const childCode = r.children?.find(i => i.code === path || i.code === from)
      if (childCode && (childCode.code === path || childCode.code === from)) {
        childRoute = childCode
        return true
      }
      return false
    })
    setCurrentRoute(childRoute || current || null)
  }, [history, userIncumbentGroups])

  const unregisterHistoryListener: UnregisterCallback = history.listen(handleRoute)

  useEffect(() => {
    handleRoute()
    return () => {
      return unregisterHistoryListener()
    }
  }, [handleRoute, unregisterHistoryListener])


  const isIncludesOutlineInLocation = () => {
    const findCurrentTab = location.pathname.split('/')[3]
    return ['outline', 'compliance'].includes(findCurrentTab)
  }

  return (
    <div
      className={classNames([styles['container'], className].join(' ').trim(), {
        [styles['hidden']]: isHiddenSidebar
      })}
      style={{ 
        backgroundColor: theme.palette.grey['700'],
      }}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        className={styles['menu-container']}
      >
        {/* Menu items */}
        <div>
          <Grid container alignItems='center' justifyContent='center' style={{paddingBottom: '24px'}}>
            <Link to={'/'}>
              <MainIcon />
            </Link>
            {isIncludesOutlineInLocation() ? (
              <div className={classNames(styles['hidden-btn'], {
                [styles['hidden-btn-active']]: isHiddenSidebar
              })}  onClick={() => setHiddenSidebar(!isHiddenSidebar)}>
                <SvgIcon name="angle-down" color={theme.palette.primary.contrastText} width={30} height={30} />
              </div>
            ) : null}
          </Grid>

          {mainRoutes.map((route, idx) => (
            <LeftMenuItem
              key={idx}
              route={route}
              currentRoute={currentRoute}
            />
          ))}
        </div>
        {/* Profile menu and sign-in / sign-up buttons */}
        <div className={styles['login-signup-wrapper']}>
          {user ? (
            <ProfileMenu currentRoute={currentRoute} />
          ) : (
            <div className={styles['login-signup-block']}>
              <Link to='/auth/sign-in'>
                <Button
                  variant='outlined'
                  color='primary'
                  className={styles['login-btn']}
                  size='small'
                  fullWidth
                >
                  Log in
                </Button>
              </Link>
              <Link to='/auth/sign-up'>
                <Button 
                  variant='contained' 
                  color='primary'
                  size='small'
                  fullWidth
                >
                  Sign up
                </Button>
              </Link>
            </div>
          )}
        </div>
      </Grid>
    </div>
  )
}

export default LeftMenu
