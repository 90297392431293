import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Profile from './Profile'
import MarketResearch from './MarketResearch'
import MarketResearchTools from './MarketResearchTools'
import ProtectedRoute from 'modules/ProtectedRoute'

const User = () => {
  return (
    <Switch>
      <ProtectedRoute path='/user/profile'>
        <Profile/>
      </ProtectedRoute>
      <Route path='/user/market-research/tools'>
        <MarketResearchTools/>
      </Route>
      <ProtectedRoute path='/user/market-research'>
        <MarketResearch/>
      </ProtectedRoute>
    </Switch>
  )
}

export default User
