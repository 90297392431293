import { Checkbox, Grid, Typography } from '@material-ui/core'
import styles from './index.module.scss'
import React from 'react'
import { SimilarCompanyItem } from 'models/hp/market-research/market-research.model'
import { numberToStrWithRound } from 'common/number'
import { cleanAwardTitle } from 'helpers/string'
import { ISimilarCompanyMapper } from './index'

interface Props {
  item: SimilarCompanyItem
  addCompany: (company: SimilarCompanyItem) => void
  companies: ISimilarCompanyMapper
}

export const CompanyItem = ({companies, addCompany, item}: Props) => {
  return (
    <div key={item.name}>
      <Grid container alignItems='center'>
        <Grid item>
          <Checkbox
            color='primary'
            checked={!!companies[item.name]}
            onClick={() => addCompany(item)}
          />
        </Grid>
        <Grid item md={11}>
          <Typography variant='h5'>
            {[item.name, item.duns].filter(x => !!x).join(' | ')}
          </Typography>
        </Grid>
      </Grid>
      <ul className={styles['inner-list']}>
        {item.items.map((item, idx) => (
          <li key={idx} >
            {cleanAwardTitle(item.name)} {item.value && <b>({numberToStrWithRound(Number(item.value))})</b>}
          </li>
        ))}
      </ul>
    </div>
  )
}