import React from 'react'
import {Button} from '@material-ui/core'
import InfoDialog from '../InfoDialog'
import styles from './index.module.scss'

interface Props {
  onClose: () => void
  onConfirm: () => void
}

const CancelSubscriptionConfirmationModal = ({ onClose, onConfirm }: Props) => {
  return (
    <InfoDialog
      title='Subscription cancellation'
      onClose={onClose}
    >
      <div>
        <h4>Cancel your future automatic payments to FedScout Subscription Store</h4>
        <h5>Important: If you don’t cancel at least 1 day before the due date, your payment might still go through.</h5>
        <p>Once you cancel, you’ll have to set up automatic payments again to pay with PayPal. Your subscription is still active,
          so visit FedScout Subscription Store to set a new way to pay or to cancel.</p>
        <div className={styles['cancel-button-wrapper']}>
          <Button
            className={styles['cancel-button']}
            fullWidth
            variant='contained'
            color='primary'
            onClick={onConfirm}
          >Cancel subscription</Button>
        </div>
      </div>
    </InfoDialog>
  )
}

export default CancelSubscriptionConfirmationModal