import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { FilterOption } from 'models/hp/search/search.model'

@jsonObject
export class GroupedCodeResponseModel extends BaseResponseModel {
  @jsonArrayMember(FilterOption)
  data: FilterOption[] = []
}

@jsonObject
export class MarketGroupedCode {
  @jsonMember({ constructor: Number })
  id: number =  0

  @jsonMember({ constructor: String })
  code: string =  ''

  @jsonMember({ constructor: String })
  name: string =  ''

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) || 0 })
  like: number =  0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) || 0 })
  dislike: number =  0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) || 0 })
  skip: number = 0
}

@jsonObject
export class MarketGroupedCodePayload {
  @jsonArrayMember(MarketGroupedCode)
  classificationCodes: MarketGroupedCode[] = []

  @jsonArrayMember(MarketGroupedCode)
  naicsCodes: MarketGroupedCode[] = []
}

@jsonObject
export class MarketGroupedCodeResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: MarketGroupedCodePayload })
  data: MarketGroupedCodePayload = new MarketGroupedCodePayload()
}