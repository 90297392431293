import React from 'react'
import styles from './index.module.scss'
import { Modal as MuiModal, useTheme} from '@material-ui/core'
import classNames from 'classnames'
import SvgIcon from 'components/SvgIcon'

export type Size = 'md' | 'lg' | 'xl' | 'xxl' | 'auto' // check styles

export interface ModalProps {
    title?: string
    description?: string
    isOpen: boolean
    onClose: () => void
    children: JSX.Element | React.ReactNode
    size?: Size
}

const Modal = ({title, description, isOpen, onClose, children, size = 'md'}: ModalProps) => {
  const theme = useTheme()
  return (
    <MuiModal open={isOpen} onClose={onClose}>
      <>
        <div className={classNames(styles['modal'], styles[`modal-size-${size}`])}>
          <div className={styles['modal-head']}>
            {title ? <h3 className={styles['modal-title']}>{title}</h3> : null}
            {description ? <p className={styles['modal-description']}>{description}</p> : null}
            <div className={styles['modal-close-icon']} onClick={onClose}>
              <SvgIcon 
                name='multiply' 
                width={20} 
                height={20} 
                color={theme.palette.grey['700']} 
              />
            </div>
          </div>
          <div className={styles['modal-content']}>
            {children}
          </div>
        </div>
      </>
    </MuiModal>
  )
}

export default Modal