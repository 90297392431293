import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IFilterOption } from 'models/hp/search/search.interface'
import { naicsCodeCountSelector, naicsCodesSelector } from 'store/hp/grouped-code/selectors'
import styles from './index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import {
  companyProfileSelectedNaicsOptions
} from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue, removeCompanySearchFilterValues,
  setCompanySearchFilterValue, setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'

const NaicsFilter = () => {
  const dispatch = useDispatch()

  const naicsCodesOptions: IFilterOption[] = useSelector(naicsCodesSelector) as IFilterOption[]
  const selectedNaicsOptions: string[] = useSelector(companyProfileSelectedNaicsOptions)
  const naicsCodeCount: number = useSelector(naicsCodeCountSelector) as number

  const handleChange = (value: string | number, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('naicsCodes', value)
    } else {
      removeCompanySearchFilterValue(dispatch)('naicsCodes', value)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('naicsCodes', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('naicsCodes', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={naicsCodeCount}
        iconType='naics'
        handlePartialChange={handlePartialChange}
        filterTitle='Industry Area (NAICS)'
        selectField='value'
        filterOptions={naicsCodesOptions}
        selectedFilterOptions={selectedNaicsOptions}
        handleChange={handleChange}
      />
    </div>
  )
}

export default NaicsFilter

