import React from 'react'
import { useHistory } from 'react-router-dom'
import Grayed from 'components/v2/Text/Grayed'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { TPlan, PLANS } from 'constants/payment'

export const can = (user?: IUserBaseModel, from?: TPlan): boolean => {
  const userPlanIndex = user && user.paymentPlan && PLANS.findIndex(x => x === user.paymentPlan?.plan)
  const fromPlanIndex = from && PLANS.findIndex(x => x === from)
  return Boolean(userPlanIndex && (!fromPlanIndex || userPlanIndex >= fromPlanIndex))
}

interface Props {
  text: string
  textClickable?: boolean
  textStyle?: React.CSSProperties
  children: React.ReactNode
  user?: IUserBaseModel
  from?: TPlan
  transparent?: boolean
}

export const CanComponent = ({user, from, children, text, transparent, textStyle, textClickable = true}: Props) => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/plans')
  }

  const handleCreateAccountClick = () => {
    // history.push('/auth/sign-up')
    history.push('/auth/sign-in')
  }

  if (!user && transparent) {
    return (
      <div
        onClick={handleCreateAccountClick}
        style={{cursor: 'pointer'}}
      >
        <div style={{pointerEvents: 'none'}}>{children}</div>
      </div>
    )
  }

  if (!user) {
    return (
      <Grayed
        style={{
          cursor: textClickable ? 'pointer' : 'default',
          ...textStyle
        }}
        onClick={() => textClickable && handleCreateAccountClick()}
      >
        Create account
      </Grayed>
    )
  }

  if (!can(user, from)) {
    return (
      <Grayed
        style={{
          cursor: textClickable ? 'pointer' : 'default',
          ...textStyle
        }}
        onClick={() => textClickable && handleClick()}
      >
        {text}
      </Grayed>
    )
  }

  return <>{children}</>
}