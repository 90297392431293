import React from 'react'
import { IFilterOption } from 'models/hp/search/search.interface'
import { useSelector } from 'react-redux'
import { pscCodeCountSelector, pscCodesSelector } from 'store/hp/grouped-code/selectors'
import styles from '../Sap/Filter/index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean) => void
  selectedOptions: number[]
}

const PscFilter = ({ handleChange, handlePartialChange, selectedOptions }: Props) => {
  const pscCodesOptions: IFilterOption[] = useSelector(pscCodesSelector) as IFilterOption[]
  const pscCount: number = useSelector(pscCodeCountSelector) as number

  return (
    <div className={styles['paper']}>
      <SearchFilter
        searchLabel='Function search'
        allCount={pscCount}
        iconType='psc'
        filterTitle='R&D, SBIR, Product or Service'
        filterOptions={pscCodesOptions}
        selectedFilterOptions={selectedOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default React.memo(PscFilter, (prev, next) => prev.selectedOptions === next.selectedOptions)