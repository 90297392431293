import React from 'react'
import styles from './index.module.scss'
import { Button, Grid, Typography, Paper } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface BigHintProps {
  close?: () => void
}

export const BigHint = ({ close }: BigHintProps) => {
  return (
    <Grid
      container
      style={{height: '100%'}}
      direction='column'
    >
      <Paper className={styles['paper']}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>Find Expiring Contracts</Typography>
          <SvgIcon
            name='multiply'
            width={30}
            height={30}
            className={styles['clickable']}
            onClick={close}
          />
        </Grid>
        <Typography>Difficulty: <b>Mid</b></Typography>
        <Typography>Time: <b>15 min</b></Typography>
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
          <Grid item md={6}>
            <SvgIcon name='film' width={250} height={250}/>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>Why Finding Expiring Contract is important</Typography>
            <Typography gutterBottom>
              There’s an old saying in the government market:
            </Typography>
            <Typography gutterBottom>
              “If you found it on SAM.gov you’re already too late”
            </Typography>
            <Typography>
              And while we don’t entirely agree with this there is a lot of truth to it.
            </Typography>
            <Typography>
              The best way for you to win a contract is to start meeting the customer, influencing the requirements, and
              building your team before the RFI is even written. Which means you need to start working 12-18 months
              before the RFP comes out.
            </Typography>
            <Typography>
              And how do you find expiring contracts that might be interesting?
            </Typography>
            <Typography>
              Well a time honored way is to look for contracts that similar companies currently have that are expiring
              in the next couple years
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>What to do with Expiring Contracts</Typography>
            <Typography gutterBottom>
              We already sort of addressed this in the why section but once you find an expiring contract that you’d
              like to win you should:
            </Typography>
            <ol>
              <li><b>Decide whether you want to win it directly or collaborate with
                the current contract holder</b></li>
              <li>
                <b>Win it directly:</b>
                <ol>
                  <li>Cool this is a low probably high reward move</li>
                  <li>Start building your relationship with the customer</li>
                  <li>Read the RFP for the current version of the contract</li>
                  <li>Start planning your response</li>
                  <li>Start building your team</li>
                </ol>
              </li>
              <li>
                <b>Collaborate with the current contract holder:</b>
                <ol>
                  <li>Good idea. There is a lot of pie to go around, and if you are a new vendor working with people is
                    a winning strategy
                  </li>
                  <li>Start building your relationship with the current contract holder</li>
                </ol>
              </li>
            </ol>
            <Typography>
              <b>Micro-purchase (aka purchase cards):</b> If the government is buying a product of service less than
              $10K then
              they can pretty much buy it like you buy stuff. The government identifies a need then they go buy it from
              a commercial vendor (e.g. Amazon, Home Depot, or you) using their government credit card. Super easy
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>How to find expiring contracts</Typography>
            <Typography gutterBottom>
              There are a few ways, but this tool is specifically for finding expiring contracts that similar companies have.
            </Typography>
            <Typography gutterBottom>
              So put in one or more companies, hit Find, and we’ll give you a chart of what’s expiring when
            </Typography>
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export const SmallHint = () => {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{height: '100%'}}
      direction='column'
    >
      <Typography variant='h3' gutterBottom>Enter on or more keywords and hit</Typography>
      <Button
        color='primary'
        variant='contained'
      >
        Find expiring contracts
      </Button>
      <Typography
        variant='h3'
        style={{marginTop: '12px'}}
      >
        To get started
      </Typography>
    </Grid>
  )
}