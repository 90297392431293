import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, useTheme } from '@material-ui/core'
import { IMainRoute } from 'common/routes'
import SvgIcon from '../SvgIcon'
import styles from './index.module.scss'
import { setCurrentEducationPhaseClass } from 'store/hp/education/action'
import { EDUCATION_ACADEMY_LINK, PlanningPhaseClasses } from 'utils/constants/education'
import { useDispatch } from 'react-redux'

interface Props {
  route: IMainRoute
  currentRoute: IMainRoute | null
}

const LeftMenuItem = ({route, currentRoute}: Props) => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const handleClose = (innerRoute: IMainRoute) => {
    if (innerRoute.code) {
      history.push(`/${innerRoute.code}`)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setCurrentEducationPhaseClass(dispatch)(PlanningPhaseClasses[0])
    //redirect to fedscout academy
    if(route.code === 'education') {
      window.open(EDUCATION_ACADEMY_LINK, '_blank', 'noreferrer')
      return
    }
    if (route.popupChildren) {
      handleClose(route.popupChildren[0])
    } else if (route.code) {
      history.push(`/${route.code}`, route.prevState)
    } else {
      route.handler && route.handler()
    }
  }
  const activeRoute = currentRoute ? route.code === currentRoute.code : false

  const renderChild = activeRoute || route.children?.some(() => route.name === currentRoute?.parentName)

  const getIconColor = () => {
    if (activeRoute) {
      return theme.palette.warning.main
    } else if (renderChild || route.parentName) {
      return theme.palette.common.white
    }
    return theme.palette.grey['500']
  }

  return (
    <div className={[styles['item'], renderChild ? styles['active-block'] : undefined].join(' ').trim()}>
      <div
        onClick={handleClick}
        className={[styles['block'], activeRoute ? styles['active'] : ''].join(' ').trim()}
      >
        <SvgIcon
          height={30} width={30} name={route.logoName} className={styles['logo']}
          color={getIconColor()}
        />
        <Typography
          className={styles['name']} variant='subtitle2'
          style={{
            color: activeRoute ? theme.palette.warning.main : theme.palette.common.white,
            fontWeight: renderChild || !!route.parentName ? 'bold' : 'normal'
          }}
        >
          {route.name}
        </Typography>
      </div>
      {renderChild && route.children && route.children.length > 0 && route.children.map((child, idx) => (
        <LeftMenuItem
          key={idx}
          currentRoute={currentRoute}
          route={child}
        />
      ))}
      {!route.parentName &&
        <div style={{borderBottom: `1px solid ${theme.palette.grey['600']}`}}/>
      }
    </div>
  )
}

export default LeftMenuItem