import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import {
  IFilterOption, ISearchDTO,
  workLocations
} from './search.interface'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { HpMarketResearchRfp } from 'models/hp/rfp/rfp.model'

@jsonObject
export class FilterOption implements IFilterOption {
  static subDeserializer(sub: any): any {
    if (!sub || !Array.isArray(sub)) {
      return []
    }
    return sub.map(item => ({
      id: this.idDeserializer(item.id),
      text: item.title || item.name || item.subtierHP || item.subtierSAM,
      value: item.value,
      sub: this.subDeserializer(item.sub),
      departmentHP: item.departmentHP,
      subtierHP: item.subtierHP,
      subtierHPShort: item.subtierHPShort,
      departmentHPShort: item.departmentHPShort,
      departmentSAM: item.departmentSAM,
      subtierSAM: item.subtierSAM
    }))
  }
  static idDeserializer(id: any) {
    if (!id) {
      return
    }
    return Number.isInteger(id) ? id : Number(id) || 0
  }

  public get text(): string {
    const text = (this.title || this.name || this.subtierHP || this.subtierSAM) as string
    if (this.auxiliary) {
      return `${text} /n ${this.auxiliary}`
    }
    return text
  }

  @jsonMember({
    constructor: Number,
    deserializer: (id) => FilterOption.idDeserializer(id)
  })
  id: number = 0

  // get all text names possible (will be passed to text getter)
  @jsonMember({constructor: String})
  title?: string = ''

  @jsonMember({constructor: String})
  name?: string = ''

  @jsonMember({constructor: String})
  subtierHP?: string = ''

  @jsonMember({constructor: String})
  subtierSAM?: string = ''

  @jsonMember({constructor: String})
  departmentHP?: string = ''

  @jsonMember({constructor: String})
  subtierHPShort?: string = ''

  @jsonMember({constructor: String})
  departmentHPShort?: string = ''

  @jsonMember({constructor: String})
  departmentSAM?: string = ''

  @jsonMember({constructor: String})
  value?: string = ''

  @jsonMember({constructor: String})
  code?: string = ''

  @jsonArrayMember(() => FilterOption, { deserializer: (json) => FilterOption.subDeserializer(json)})
  sub?: FilterOption[] = []

  @jsonMember({constructor: String})
  auxiliary?: string = ''
}

@jsonObject
export class DuaDateRangeModel {
  @jsonMember({ constructor: Number })
  min: number = 1

  @jsonMember({ constructor: Number })
  max: number = 180
}

@jsonObject
export class SearchModel implements ISearchDTO {
  @jsonMember({ constructor: Number })
  id: number = 0

  @jsonMember({ constructor: String })
  name = ''

  @jsonArrayMember(String, { deserializer: (json) => json.map((i: any) => i.name) })
  keywords: string[] = []

  @jsonArrayMember(Number)
  contractTypeIds: number[] = []

  @jsonArrayMember(Number)
  groupedCodeIds: number[] = []

  @jsonArrayMember(Number)
  pscCodeIds: number[] = []

  @jsonArrayMember(Number)
  projectTypeIds: number[] = []

  @jsonArrayMember(Number)
  customerIds: number[] = []

  @jsonMember({ constructor: String })
  workLocation: workLocations = 'SPECIFIC'

  @jsonArrayMember(Number)
  locationIds: number[] = []

  @jsonArrayMember(Number)
  setAsideIds: number[] = []

  @jsonArrayMember(Number)
  dueDateIds: number[] = []

  @jsonMember({ constructor: DuaDateRangeModel })
  dueDateRange: DuaDateRangeModel = new DuaDateRangeModel()

  @jsonArrayMember(Number)
  estimatedValueIds: number[] = []

  @jsonArrayMember(Number)
  estimatedEffortIds: number[] = []

  @jsonMember({ constructor: Boolean, name: 'allAgencies' })
  allCustomers: boolean = true

  @jsonMember({ constructor: Boolean })
  allDueDates: boolean = false

  @jsonMember({ constructor: Boolean })
  allEstimatedEfforts: boolean = false

  @jsonMember({ constructor: Boolean })
  allEstimatedValues: boolean = false

  @jsonMember({ constructor: Boolean, name: 'allCodes' })
  allGroupedCodes: boolean = true

  @jsonMember({ constructor: Boolean, name: 'allPsc' })
  allPscCodes: boolean = true

  @jsonMember({ constructor: Boolean })
  allSetAsides: boolean = true

  @jsonMember({ constructor: Boolean })
  allProjectTypes: boolean = true

  @jsonMember({ constructor: Boolean })
  done = false

  @jsonMember({ constructor: Boolean })
  isDeleted: boolean = false

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonArrayMember(FilterOption)
  states: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  pscCodes: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  groupedCodes: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  customers: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  analyzedTypes: FilterOption[] = []

  @jsonMember({ constructor: String })
  pastPerformance: string | null = null
}

@jsonObject
export class SearchLightModel {
  @jsonMember({ constructor: Number })
  id = undefined

  @jsonMember({ constructor: String })
  name = ''

  @jsonMember({ constructor: Boolean })
  done = false
}

@jsonObject
export class SearchSetting {
  @jsonMember({ constructor: String })
  id = ''

  @jsonMember({ constructor: String })
  newSearchItemsNotification: string | null = null

  @jsonMember({ constructor: SearchLightModel })
  search = new SearchLightModel()
}

@jsonObject
export class SearchLightResponseModel extends BaseResponseModel {
  @jsonArrayMember(SearchLightModel)
  data: SearchLightModel[] = []
}

@jsonObject
export class SearchOptionsPayload {
  @jsonArrayMember(FilterOption)
  contractTypes: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  projectTypes: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  setAsides: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  dueDates: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  estimatedValues: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  estimatedEfforts: FilterOption[] = []

  @jsonArrayMember(FilterOption)
  analyzedTypes: FilterOption[] = []
}

@jsonObject
export class SearchOptionsResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SearchOptionsPayload })
  data: SearchOptionsPayload = new SearchOptionsPayload()
}

@jsonObject
export class SearchResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SearchModel })
  data: SearchModel = new SearchModel()
}

@jsonObject
export class SearchesResponseModel extends BaseResponseModel {
  @jsonArrayMember(SearchModel)
  data: SearchModel[] = []
}

@jsonObject
export class ManageSearchPayload {
  @jsonMember({ constructor: Number })
  id: number = 0

  @jsonMember({ constructor: String })
  name: string = ''
  // only certain fields were described, add more if required
}

@jsonObject
export class ManageSearchResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: ManageSearchPayload })
  data: ManageSearchPayload = new ManageSearchPayload()
}

@jsonObject
export class GetItemsResponseModel extends BasePaginationModel {
  @jsonArrayMember(HpMarketResearchRfp)
  data: HpMarketResearchRfp[] = []
}

@jsonObject
export class SearchListResponse extends BaseResponseModel {
  @jsonArrayMember(SearchModel)
  data: SearchModel[] = []
}

@jsonObject
export class SearchSettingsResponseModel extends BaseResponseModel {
  @jsonArrayMember(SearchSetting)
  data: SearchSetting[] = []
}
