import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import {
  companyAgenciesSelector, companyAgencyCountSelector
} from 'store/hp/company-profiles/options/selectors'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import {
  companyProfileSelectedCustomerOptions
} from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue,
  removeCompanySearchFilterValues,
  setCompanySearchFilterValue,
  setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'

const CustomerFilter = () => {

  const dispatch = useDispatch()

  const companyAgencies = useSelector(companyAgenciesSelector)
  const selectedCustomerOptions: string[] = useSelector(companyProfileSelectedCustomerOptions)
  const customerCount: number = useSelector(companyAgencyCountSelector)!

  const handleChange = (value: string | number, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('customerCodes', value)
    } else {
      removeCompanySearchFilterValue(dispatch)('customerCodes', value)
    }
  }

  const handlePartialChange = (values: Array<number | string>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('customerCodes', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('customerCodes', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={customerCount}
        iconType='department'
        filterTitle='Customers'
        handlePartialChange={handlePartialChange}
        selectField='value'
        filterOptions={companyAgencies!}
        selectedFilterOptions={selectedCustomerOptions}
        handleChange={handleChange}
      />
    </div>
  )
}

export default CustomerFilter
