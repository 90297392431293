import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import { BaseRegionModel } from 'models/hp/location/location.model'
import { GET_LOCATION_COUNT, GET_LOCATIONS, GET_LOCATIONS_ERROR, GET_LOCATIONS_SUCCESS } from './action'

const LocationsRecord = Record<IRecord<BaseRegionModel[]>>({
  loaded: false,
  loading: false,
  count: 0,
  data: []
})

export const locationsReducer = (state: Record<IRecord<BaseRegionModel[]>> = new LocationsRecord(), action: IAction) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return state
        .set('loading', true)
    case GET_LOCATION_COUNT:
      return state
        .set('count', action.payload)
    case GET_LOCATIONS_SUCCESS:
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('data', action.payload)
    case GET_LOCATIONS_ERROR:
      return state
        .set('loaded', false)
        .set('loading', false)
    default:
      return state
  }
}