import React from 'react'
import { YOUTUBE, VideoSourceType } from 'utils/const'

interface Props {
  autoplay?: boolean
  source?: VideoSourceType
}

const HelperVideo = ({ autoplay = true, source}: Props) => {
  return (
    <iframe
      style={{ height: '100%', width: '100%', maxWidth: '768px', minHeight: '434px' }}
      src={source ? YOUTUBE[source] : YOUTUBE.CONTRACT_SEARCH_HELPER(autoplay ? '?autoplay=1' : undefined)}
      title='YouTube video player' frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  )
}

export default HelperVideo