import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import ItemBlock from '../components/ItemBlock'

const ContractResearch = () => {
  const history = useHistory()

  return (
    <>
      <Typography variant='h3' gutterBottom>Contract Research</Typography>
      <Grid container spacing={3} justifyContent='space-between' alignItems='stretch'>
        <Grid item md={4}>
          <ItemBlock title='All Awards Library' icon='building2' onClick={() => history.push('/awards')}>
            <Typography variant='h5'>Research previous awards</Typography>
            <ul>
              <li><Typography>Get context for open contracts</Typography></li>
              <li><Typography>Explore a market</Typography></li>
              <li><Typography>Learn about purchasing trends</Typography></li>
              <li><Typography>Learn about company activities</Typography></li>
            </ul>
            {/*<Button
              fullWidth
              color='primary'
              onClick={() => history.push('/awards/categorized')}
            >
              Open Liked list
            </Button>*/}
          </ItemBlock>
        </Grid>
        <Grid item md={4}>
          <ItemBlock title='"Rent" a vehicle' icon='key-skeleton' onClick={() => history.push('/awards/idv')}>
            <Typography variant='h5'> Find single and multi-award vehicles that:</Typography>
            <ul>
              <li><Typography>Are open</Typography></li>
              <li><Typography>Might have extra ceiling</Typography></li>
              <li><Typography>You might be able to "rent" from the prime</Typography></li>
            </ul>
          </ItemBlock>
        </Grid>
        <Grid item md={4}>
          <ItemBlock title='Coming Soon' icon='question-circle'/>
        </Grid>
      </Grid>
    </>
  )
}

export default ContractResearch
