import React from 'react'
import { Grid, Button, styled } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { EDUCATION_FUNDING_SUBTITLE } from 'utils/constants/education-funding'

const StyledButton = styled(Button)(({theme}) => (
  {
    backgroundColor: theme.palette.grey['600'],
  }
))

const NavigationBtns = () => {
  const history = useHistory()

  const getUrl = (phase: string) => {
    return `/education/funding?phase=${phase}`
  }

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item md={1}>
        <Button
          fullWidth
          variant='outlined'
          onClick={() => history.push(getUrl(EDUCATION_FUNDING_SUBTITLE.RELATIONSHIP_BUILDING))}
        >Back
        </Button>
      </Grid>
      <Grid item md={1}>
        <StyledButton
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => history.push(getUrl(EDUCATION_FUNDING_SUBTITLE.SEEDING_TOPICS))}
        >Next</StyledButton>
      </Grid>
    </Grid>
  )
}

export default NavigationBtns