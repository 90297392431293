import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { DateModel } from '../due-timeline/due-timeline.model'
import { HpRfpModel } from 'models/hp/rfp/rfp.model'

@jsonObject
export class WinnerByTypeModel {
  @jsonMember(Number)
  govvet: number = 0

  @jsonMember(Number)
  incumbent: number = 0

  @jsonMember(Number)
  outsider: number = 0

  @jsonMember(Number)
  subtier: number = 0
}

@jsonObject
export class BpTotalModel {
  @jsonMember(Number)
  full: number = 0

  @jsonMember(Number)
  min: number = 0

  @jsonMember(Number)
  remaining: number = 0
}

@jsonObject
export class GeneralCalcModel {
  @jsonArrayMember(DateModel)
  timeline: DateModel[] = []

  @jsonMember({ constructor: WinnerByTypeModel })
  winnerByType?: WinnerByTypeModel

  @jsonMember({ constructor: BpTotalModel })
  bpTotal?: BpTotalModel

  @jsonArrayMember(HpRfpModel)
  expiredContracts?: HpRfpModel[] | null = null

  @jsonMember(Number || null)
  reCompete: number | null = null
}

@jsonObject
export class GeneralCalcResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: GeneralCalcModel })
  data: GeneralCalcModel = new GeneralCalcModel()
}