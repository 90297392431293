import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Paper } from '@material-ui/core'
import HealthRevenueItem from './components/HealthRevenueItem'
import { useDispatch, useSelector } from 'react-redux'
import { getPiplineHealth, getPiplineRevenue } from 'store/hp/pipeline/rfp/action'
import { pipelineHealthSelector, pipelineRevenueSelector } from 'store/hp/pipeline/rfp/selectors'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'

const HealthRevenue = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const health = useSelector(pipelineHealthSelector)
  const revenue = useSelector(pipelineRevenueSelector)

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    try {
      getPiplineHealth(dispatch)
      getPiplineRevenue(dispatch)
    } catch(e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <Paper className={styles['healthRevenue']}>
      <HealthRevenueItem
        title='Value of ops at each stage'
        chartType='ValueOfOps'
        isLoading={health.loading || !health.loaded}
        data={health.data}
      />
      <HealthRevenueItem 
        title='Estimated revenue based on pipeline'
        chartType='EstimatedRevenue'
        hasInfo={true}
        chartInfo={{
          title: 'test',
          description: 'test'
        }}
        isLoading={revenue.loading || !revenue.loaded}
        data={revenue.data}
      />
    </Paper>
  )
}

export default HealthRevenue