import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import {
  COMPANY_GOV_CON,
  COMPANY_GOV_CON_ERROR,
  COMPANY_GOV_CON_SIZE,
  COMPANY_GOV_CON_SIZE_ERROR,
  COMPANY_GOV_CON_SIZE_SUCCESS,
  COMPANY_GOV_CON_SUCCESS,
  COMPANY_GROUPED_CUSTOMERS,
  COMPANY_GROUPED_CUSTOMERS_COUNT,
  COMPANY_GROUPED_CUSTOMERS_ERROR,
  COMPANY_GROUPED_CUSTOMERS_SUCCESS,
  COMPANY_SET_ASIDE,
  COMPANY_SET_ASIDE_ERROR,
  COMPANY_SET_ASIDE_SUCCESS,
  COMPANY_VEHICLE,
  COMPANY_VEHICLE_ERROR,
  COMPANY_VEHICLE_SUCCESS
} from './actions'
import { GovConModel, GovConSizeModel } from 'models/hp/company-profiles/gov-con/gov-con.model'
import { FilterOption } from 'models/hp/search/search.model'
import { combineReducers } from 'redux'
import { CompanySetAsideModel } from 'models/hp/company-profiles/set-aside/set-aside.model'
import { VehicleModel } from 'models/hp/company-profiles/vehicle/vehicle.model'

export interface ICompanyOptionReducer {
  govCon: Record<IRecord<GovConModel[]>>
  govConSize: Record<IRecord<GovConSizeModel[]>>
  agencies: Record<IRecord<FilterOption[]>>
  setAsides: Record<IRecord<CompanySetAsideModel[]>>
  vehicles: Record<IRecord<VehicleModel[]>>
}

const CompanyGroupedCustomerCodesRecord = Record<IRecord<FilterOption[]>>({
  loading: false,
  loaded: false,
  count: 0,
  data: []
})

export const companyGroupedCustomersReducer = (state: Record<IRecord<FilterOption[]>> = new CompanyGroupedCustomerCodesRecord(), action: IAction) => {
  switch (action.type) {
    case COMPANY_GROUPED_CUSTOMERS:
      return state
        .set('loading', true)
    case COMPANY_GROUPED_CUSTOMERS_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case COMPANY_GROUPED_CUSTOMERS_COUNT:
      return state
        .set('count', action.payload)
    case COMPANY_GROUPED_CUSTOMERS_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)
    default:
      return state
  }
}

export const CompanyGovConRecord = Record<IRecord<GovConModel[]>>({
  loading: false,
  loaded: false,
  data: []
})

export const govConReducer = (state: Record<IRecord<GovConModel[]>> = new CompanyGovConRecord(), action: IAction) => {
  switch (action.type) {
    case COMPANY_GOV_CON:
      return state
        .set('loading', true)
    case COMPANY_GOV_CON_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case COMPANY_GOV_CON_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)
    default:
      return state
  }
}

const CompanyGovConSizeRecord = Record<IRecord<GovConSizeModel[]>>({
  loading: false,
  loaded: false,
  data: []
})

export const govConSizeReducer = (state: Record<IRecord<GovConSizeModel[]>> = new CompanyGovConSizeRecord(), action: IAction) => {
  switch (action.type) {
    case COMPANY_GOV_CON_SIZE:
      return state
        .set('loading', true)
    case COMPANY_GOV_CON_SIZE_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case COMPANY_GOV_CON_SIZE_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)
    default:
      return state
  }
}

const CompanySetAsideRecord = Record<IRecord<CompanySetAsideModel[]>>({
  loading: false,
  loaded: false,
  data: []
})

export const companySetAsideReducer = (state: Record<IRecord<CompanySetAsideModel[]>> = new CompanySetAsideRecord(), action: IAction) => {
  switch (action.type) {
    case COMPANY_SET_ASIDE:
      return state
        .set('loading', true)
    case COMPANY_SET_ASIDE_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case COMPANY_SET_ASIDE_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)
    default:
      return state
  }
}

const CompanyVehicleRecord = Record<IRecord<VehicleModel[]>>({
  loading: false,
  loaded: false,
  data: []
})

export const vehicleReducer = (state: Record<IRecord<VehicleModel[]>> = new CompanyVehicleRecord(), action: IAction) => {
  switch (action.type) {
    case COMPANY_VEHICLE:
      return state
        .set('loading', true)
    case COMPANY_VEHICLE_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case COMPANY_VEHICLE_SUCCESS:
      return state
        .set('loading', true)
        .set('loaded', true)
        .set('data', action.payload)
    default:
      return state
  }
}

// Combine all option reducers
export const rootCompanyOptionReducers = combineReducers<ICompanyOptionReducer>({
  govCon: govConReducer,
  govConSize: govConSizeReducer,
  agencies: companyGroupedCustomersReducer,
  setAsides: companySetAsideReducer,
  vehicles: vehicleReducer
})
