import React from 'react'
import { BaseRegionModel } from 'models/hp/location/location.model'
import { useSelector } from 'react-redux'
import { locationCountSelector, locationsSelector } from 'store/hp/location/selectors'
import RecursiveItem, { isChecked } from 'components/v2/Filters/SearchFilter/RecursiveItem'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'
import styles from '../../index.module.scss'
import { IFilterOption } from 'models/hp/search/search.interface'

interface Props {
  selectedLocations: IFilterOption[]
  handleChange: (id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
}

const ServiceLineLocationFilter = ({ handlePartialChange, handleChange, selectedLocations }: Props) => {

  const [showMain, setShowMain] = React.useState<boolean>(false)
  const [showMapper, setShowMapperValue] = React.useState<{ [key: string]: boolean }>({})

  const locationOptions: BaseRegionModel[] = useSelector(locationsSelector) as BaseRegionModel[]
  const locationCount: number = useSelector(locationCountSelector)!

  const renderItems = () => {
    return locationOptions.map(option => {
      const checked = isChecked(option.sub, selectedLocations.map(i => i.id), 'id')
      return (
        <div className={styles['select-block-wrap']} key={option.region}>
          <div className={styles['select-block']}>
            <FormControlLabel
              className={styles['select-input']}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => handlePartialChange(option.sub.map(item => item.id), !checked, option.sub)}
                  color='primary'
                />
              }
              label={option.region}
            />
            {option.sub && option.sub.length > 0 && (
              <Button
                className={showMapper[option.region] ? styles['show-btn-active'] : undefined}
                variant='text'
                disableRipple
                onClick={() => setShowMapperValue({ ...showMapper, [option.region]: !showMapper[option.region] })}
              >
                <SvgIcon
                  name='angle-down'
                />
              </Button>
            )}
          </div>
          {showMapper[option.region] && (
            <div className={styles['select-block-children']}>
              {option.sub.map(item => (
                <RecursiveItem
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  sub={item.sub || []}
                  option={item}
                  handleChange={handleChange}
                  handlePartialChange={() => {
                  }}
                  selected={selectedLocations.map(i => i.id)}
                />
              ))}
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <div className={styles['paper']}>
      <div className={styles['paper']}>
        <div className={styles['select-block']}>
          <FilterTitle
            title='Locations' allCount={locationCount} iconType='location'
            selectedLength={selectedLocations.length}/>
          {locationOptions && locationOptions.length > 0 && (
            <Button
              className={showMain ? styles['show-btn-active'] : undefined}
              variant='text'
              disableRipple
              onClick={() => setShowMain(!showMain)}
            >
              <SvgIcon
                name='angle-down'
              />
            </Button>
          )}
        </div>
        {showMain && (
          <div className={styles['select-block-children']}>
            {renderItems()}
          </div>
        )}
      </div>
    </div>
  )
}

export default ServiceLineLocationFilter
