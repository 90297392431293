import React  from 'react'
import { Grid, Typography } from '@material-ui/core'
import LikedInputRow from './LikedInputRow'
import styles from './index.module.scss'

export interface ILikedItem {
  name: string
  email: string
  phone: string
  linkedInProfile: string
}

const CustomerContacts = () => {

  return (
    <div className={styles['inner-block']}>
      <Typography className={styles['title']}>
        [Liked customer name]
      </Typography>
      <Grid className={styles['inputs-wrapper']}>
        <LikedInputRow />
        <LikedInputRow />
        <LikedInputRow />
      </Grid>
      <Typography className={styles['title']}>
        [Liked customer name]
      </Typography>
      <Grid className={styles['inputs-wrapper']}>
        <LikedInputRow />
        <LikedInputRow />
        <LikedInputRow />
      </Grid>

    </div>
  )
}

export default CustomerContacts