import React from 'react'
import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import { getSetAsidesAction } from 'store/hp/set-aside/actions'
import { BaseSetAsideModel } from 'models/hp/set-aside/set-aside.model'

interface Props {
  selectedSetAsides: number[]
  handleSelectedSetAsides: (id: number) => void
  processing: boolean
}

const ServiceLineSetAside = ({ handleSelectedSetAsides, selectedSetAsides, processing }: Props) => {

  const [setAsides, setSetAsides] = React.useState<BaseSetAsideModel[]>([])

  React.useEffect(() => {
    (async () => {
      const setAsides = await getSetAsidesAction()
      setSetAsides(setAsides.data)
    })()
  }, [setSetAsides])

  return (
    <>
      <Typography variant='h3' gutterBottom>Set-asides</Typography>
      <Paper className={styles['line-paper']}>
        <Grid container spacing={2}>
          {setAsides.sort((a, b) => selectedSetAsides.indexOf(b.id!) - selectedSetAsides.indexOf(a.id!))
            .map(item =>
              <Grid key={item.id} item md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedSetAsides.includes(item.id!)}
                      onChange={() => handleSelectedSetAsides(item.id!)}
                      color='primary'
                      disabled={processing}
                    />
                  }
                  label={item.title}
                />
              </Grid>
            )}
        </Grid>
      </Paper>
    </>
  )
}

export default ServiceLineSetAside
