import React, { ChangeEvent, useEffect } from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { IFilterOption } from 'models/hp/search/search.interface'
import RecursiveItem, { isChecked } from './RecursiveItem'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'
import { IconTypes } from './FilterTitle'
import FilterTitle from './FilterTitle'
import { Autocomplete } from '@material-ui/lab'
import TextField from 'components/v2/TextField'
import { userActivity } from 'store/common/owner/actions'

interface Props {
  options?: IFilterOption[]
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void
  onSelect?: (state: boolean, value: IFilterOption | null) => void
  searchLabel?: string
  filterTitle: string
  filterOpened?: boolean
  filterOptions: IFilterOption[]
  selectedFilterOptions: Array<string | number>
  handleChange: (id: number | string, state: boolean) => void
  handlePartialChange: (id: Array<string | number>, state: boolean) => void
  selectField?: 'id' | 'value'
  iconType?: IconTypes
  allCount?: number
}

const analyticsOpenMapper: { [key in IconTypes]?: string } = {
  psc: userActivity.activities.openPscFilter,
  naics: userActivity.activities.openNaicsFilter,
  date: userActivity.activities.openDueInFilter,
  location: userActivity.activities.openLocationFilter,
  department: userActivity.activities.openCustomerFilter,
  setAside: userActivity.activities.openSetAsideFilter,
  govCon: userActivity.activities.openMaturityFilter,
}

const SearchFilter = ({
  filterOptions,
  selectedFilterOptions,
  handleChange,
  handlePartialChange,
  filterTitle,
  selectField,
  iconType,
  allCount,
  options,
  onSearch,
  onSelect,
  searchLabel,
  filterOpened
}: Props) => {
  const [show, setShow] = React.useState<boolean>(filterOpened || false)

  const toggleFilter = () => {
    if (!show && iconType && analyticsOpenMapper[iconType]) {
      userActivity.event(analyticsOpenMapper[iconType]!)
    }
    setShow(!show)
  }

  useEffect(() => {
    setShow(filterOpened!!)

  }, [filterOpened])

  return (
    <div className={styles['paper']}>
      <div className={styles['select-block']}>
        <FilterTitle
          allCount={allCount} title={filterTitle} iconType={iconType}
          selectedLength={selectedFilterOptions.length}
        />
        {filterOptions && filterOptions.length > 0 && (
          <Button
            className={show ? styles['show-btn-active'] : undefined}
            variant='text'
            disableRipple
            onClick={toggleFilter}
          >
            <SvgIcon
              name='angle-down'
            />
          </Button>
        )}
      </div>
      {show && (
        <div className={styles['select-block-children-root']}>
          {options && onSearch && onSelect &&
            <Autocomplete
              style={{ margin: '15px 0' }}
              options={options}
              value={null}
              getOptionLabel={(option) => option?.text || ''}
              getOptionSelected={(option) => selectedFilterOptions.includes(option.id)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='filled'
                  label={`${searchLabel}`}
                  onChange={onSearch}
                />
              )}
              renderOption={(option) => {
                const selected = !option.sub?.length ? selectedFilterOptions.includes(option.id) : isChecked(option.sub, selectedFilterOptions, 'id')
                return (
                  <div style={{ width: '100%' }} onClick={() => onSelect(!selected, option)}>
                    <FormControlLabel
                      key={option.text}
                      control={
                        <Checkbox
                          checked={selected}
                          color='primary'
                        />
                      }
                      label={option.text}
                    />
                  </div>
                )
              }}
            />
          }
          {filterOptions.map(option => (
            <RecursiveItem
              selectField={selectField}
              key={option.id || option.text}
              id={option.id}
              text={option.text}
              value={option.value}
              option={option}
              sub={option.sub || []}
              handleChange={handleChange}
              handlePartialChange={handlePartialChange}
              selected={selectedFilterOptions}
              rootOption
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchFilter
