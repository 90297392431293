import React from 'react'
import styles from './index.module.scss'
import { getDayOfWeek, getDaysInMonth, getDiffInDates, getFormattedTextDate } from 'helpers/date'
import classNames from 'classnames'
import { months } from '../../month'
import { IPiplineTimeline, IPiplineTimelineDate } from 'models/hp/pipeline/pipeline.interface'
import { Theme } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

interface ICurrentDate {
    day: number;
    month: number;
    year: number;
    week: number;
    qtr: number;
}

export const generateHeadFromDays = (numMonths : number, month: Date, currentDate: ICurrentDate, data: IPiplineTimeline[]) => {
  let dayRows: JSX.Element[] = []
  let dayRow: JSX.Element[] = []
  for (let i = 0; i < numMonths; i++) {
    // create day and week rows
    const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1)
    const currYear = month.getFullYear()
    const currMonth = month.getMonth() + 1
    
    const isCurrentDate = (day: number) => {
      return Boolean(currentDate.day === day && currentDate.month === currMonth && currentDate.year === currYear)
    }
  
    for (let j = 1; j <= numDays; j++) {
      dayRow.push(
        <div key={j} className={styles['dayItem']}>
          <span className={classNames(styles['ganttTimePeriodSpan'], {
            [styles['ganttTimePeriodSpan-active']]: isCurrentDate(j)
          })}>
            {j}
          </span>
          {isCurrentDate(j) ? (
            <div className={styles['current-date-picker']}>
              <div  
                className={classNames(styles['current-date-picker-body'], 'date-picker')} 
                style={{height: data && data.length  ? `${(data.length * 60) + 5}px` : 0}}
              />
            </div>
          ) : null}
        </div>
      )
    }
    
    dayRows.push(
      <div key={i} className={styles['ganttTimePeriod']} style={{ outline: 'none' }}>
        <div className={styles['ganttTimePeriod-month']}>{months[month.getMonth()] + ' ' + month.getFullYear()}</div> 
        {dayRow}
      </div>
    )
      
    dayRow = []
    month.setMonth(month.getMonth() + 1)
  }
  return dayRows
}

export const generateGridFromDays = (data: IPiplineTimeline[], startMonth: Date, numMonths: number, theme: Theme) => {
  let taskRows: JSX.Element[] = []
  let taskRow: JSX.Element[] = []
  if (data.length) {
    data.forEach((task: IPiplineTimeline, index: number) => {
      let mnth = new Date(startMonth)
      for (let i = 0; i < numMonths; i++) {
        const curYear = mnth.getFullYear()
        const curMonth = mnth.getMonth() + 1
        const day = mnth.getDay()
    
        const numDays = getDaysInMonth(curYear, curMonth)
    
        for (let j = 1; j <= numDays; j++) {
          // color weekend cells differently
          const dayOfTheWeek = getDayOfWeek(curYear, curMonth - 1, j - 1)
          // add task and date data attributes
          const formattedDate = getFormattedTextDate(new Date(`${curYear}-${curMonth}-${j}`))
          taskRow.push(
            <div
              key={`${j}-${task.id}-${dayOfTheWeek}-${uuidv4()}`}
              className={styles['ganttTimePeriodCell']}
              style={{
                backgroundColor:
                      dayOfTheWeek === 'S' ? theme.palette.grey['100'] : theme.palette.primary.contrastText,
              }}
            >
              {task.dates.map((el: IPiplineTimelineDate, i: number) => {
                if(String(el?.start) === formattedDate) {
                  return (
                    <div 
                      key={`${el.id}/${i}`} 
                      className={classNames(styles['taskDuration'], {
                        [styles['taskDuration-first']]: i === 0 && task.dates.length !== 1,
                        [styles['taskDuration-last']]: i === task.dates.length - 1 && task.dates.length !== 1,
                        //duration coloring depending on id(1-4)
                        [styles['taskDuration-pre-rfi']]: el.id === 1,
                        [styles['taskDuration-rfi']]: el.id === 2,
                        [styles['taskDuration-pre-rfp']]: el.id === 3,
                        [styles['taskDuration-rfp']]: el.id === 4
                      })}
                      style={{
                        width: `calc(${getDiffInDates(new Date(el.start), new Date(el.end))} * 100%)`,
                      }} 
                    />
                  )
                }
                return null
              })}
            </div>
          )
        }
    
        taskRows.push(
          <div key={`${task.id}-${index}-${curMonth}-${day}-${index}-${uuidv4()}`} className={styles['ganttTimePeriod']}>
            {taskRow}
          </div>
        )
    
        taskRow = []
        mnth.setMonth(mnth.getMonth() + 1)
      }
    })
  }
  return taskRows
}



export const generateHeadFromMonth = (numMonths : number, month: Date, currentDate: ICurrentDate, data: IPiplineTimeline[]) => {
  let weekRows: JSX.Element[] = []
  let weekRow: JSX.Element[] = []
  for (let i = 0; i < numMonths; i++) {
    const firstWeek = moment(month).startOf('month').week()
    const firstWeekNextMonth = moment(month).add(1, 'months').startOf('month').week()
    const weeksCount = moment(month).isoWeeksInYear()
    const correctLastWeek = firstWeek >= 48 ? weeksCount + 1 : firstWeekNextMonth
    const currYear = month.getFullYear()
    const currMonth = month.getMonth() + 1 
      
    const isCurrentDate = (week: number) => {
      return Boolean(currentDate.week === week && currentDate.month === currMonth && currentDate.year === currYear)
    }

    for (let j = firstWeek; j < correctLastWeek; j++) {
      weekRow.push(
        <div key={j} className={styles['dayItem']}>
          <span className={classNames(styles['ganttTimePeriodSpan'], {
            [styles['ganttTimePeriodSpan-active']]: isCurrentDate(j)
          })}>
            {`W${j}`}
          </span>
          {isCurrentDate(j) ? (
            <div className={styles['current-date-picker']}>
              <div  
                className={styles['current-date-picker-body']} 
                style={{height: data && data.length  ? `${(data.length * 60) + 5}px` : 0}}
              />
            </div>
          ) : null}
        </div>
      )
    }
      
    weekRows.push(
      <div key={i} className={styles['ganttTimePeriod']} style={{ outline: 'none' }}>
        <div className={styles['ganttTimePeriod-month']}>{months[month.getMonth()] + ' ' + month.getFullYear()}</div> 
        {weekRow}
      </div>
    )
        
    weekRow = []
    month.setMonth(month.getMonth() + 1)
  }
  return weekRows
}

export const generateGridFromMonth = (data: IPiplineTimeline[], startMonth: Date, numMonths: number, theme: Theme) => {
  let taskRows: JSX.Element[] = []
  let taskRow: JSX.Element[] = []
  if (data.length) {
    data.forEach((task: IPiplineTimeline, index: number) => {
      let mnth = new Date(startMonth)
      for (let i = 0; i < numMonths; i++) {
        const curYear = mnth.getFullYear()
        const curMonth = mnth.getMonth() + 1
        const firstWeek = moment(mnth).startOf('month').week()
        const firstWeekNextMonth = moment(mnth).add(1, 'months').startOf('month').week()
        const weeksCount = moment(mnth).isoWeeksInYear()
        const correctLastWeek = firstWeek >= 48 ? weeksCount + 1 : firstWeekNextMonth
      
        for (let j = firstWeek; j < correctLastWeek; j++) {
          taskRow.push(
            <div
              key={`${j}-${task.id}-${uuidv4()}`}
              className={styles['ganttTimePeriodCell']}
              style={{
                backgroundColor: theme.palette.primary.contrastText,
              }}
            >
              {task.dates.map((el: IPiplineTimelineDate, i: number) => {
                let week = moment(el.start).isoWeek()
                let year = moment(el.start).year()
                if(week === j && curYear === year) {
                  return (
                    <div 
                      key={`${el.id}/${i}`} 
                      className={classNames(styles['taskDuration'], {
                        [styles['taskDuration-first']]: i === 0 && task.dates.length !== 1,
                        [styles['taskDuration-last']]: i === task.dates.length - 1 && task.dates.length !== 1,
                        //duration coloring depending on id(1-4)
                        [styles['taskDuration-pre-rfi']]: el.id === 1,
                        [styles['taskDuration-rfi']]: el.id === 2,
                        [styles['taskDuration-pre-rfp']]: el.id === 3,
                        [styles['taskDuration-rfp']]: el.id === 4
                      })}
                      style={{
                        width: `calc(${getDiffInDates(new Date(el.start), new Date(el.end), 'week') + 1} * 100%)`,
                      }} 
                    />
                  )
                }
                return null
              })}
            </div>
          )
        }
      
        taskRows.push(
          <div key={`${task.id}-${index}-${curMonth}-${index}-${uuidv4()}`} className={styles['ganttTimePeriod']}>
            {taskRow}
          </div>
        )
      
        taskRow = []
        mnth.setMonth(mnth.getMonth() + 1)
      }
    })
  }
  return taskRows
}

export const generateHeadFromYear = (numYears : number, month: Date, currentDate: ICurrentDate, data: IPiplineTimeline[]) => {
  let quarterRows: JSX.Element[] = []
  let quarterRow: JSX.Element[] = []

  const isCurrentDate = (qtr: number, year: number) => {
    return Boolean(currentDate.qtr === qtr && currentDate.year === year)
  }

  for (let i = 0; i < numYears; i++) {
    const currentYear = month.getFullYear()
    const countQuarter = 4
    for (let j = 1; j <= countQuarter; j++) {
      quarterRow.push(
        <div key={j} className={styles['dayItem']}>
          <span className={classNames(styles['ganttTimePeriodSpan'],  {
            [styles['ganttTimePeriodSpan-active']]: isCurrentDate(j, currentYear)
          })}>
            {`Q${j} ${currentYear}`}
          </span>
          {isCurrentDate(j, currentYear) ? (
            <div className={styles['current-date-picker']}>
              <div  
                className={styles['current-date-picker-body']} 
                style={{height: data && data.length  ? `${(data.length * 60) + 5}px` : 0}}
              />
            </div>
          ) : null}
        </div>
      )
    }
    quarterRows.push(
      <div key={i} className={styles['ganttTimePeriodQuarter']} style={{ outline: 'none' }}>
        <div className={styles['ganttTimePeriod-month']}>{currentYear}</div> 
        {quarterRow}
      </div>
    )
            
    quarterRow = []
    month.setFullYear(month.getFullYear() + 1)
  }
  return quarterRows
}

export const generateGridFromQuarter = (data: IPiplineTimeline[], startMonth: Date, numYears: number, theme: Theme) => {
  let taskRows: JSX.Element[] = []
  let taskRow: JSX.Element[] = []
  if (data.length) {
    data.forEach((task: IPiplineTimeline, index: number) => {
      let mnth = new Date(startMonth)
      for (let i = 0; i < numYears; i++) {
        const currentYear = mnth.getFullYear()
        const countQuarter = 4
        for (let j = 1; j <= countQuarter; j++) {
          taskRow.push(
            <div
              key={`${j}-${task.id}-${uuidv4()}`}
              className={styles['ganttTimePeriodCell']}
              style={{
                backgroundColor: theme.palette.primary.contrastText,
              }}
            >
              {task.dates.map((el: IPiplineTimelineDate, i: number) => {
                const quarter = moment(el.start).quarter()
                const year = moment(el.start).year()
                if(quarter === j && currentYear === year) {
                  return (
                    <div 
                      key={`${el.id}/${i}`} 
                      className={classNames(styles['taskDuration'], {
                        [styles['taskDuration-first']]: i === 0 && task.dates.length !== 1,
                        [styles['taskDuration-last']]: i === task.dates.length - 1 && task.dates.length !== 1,
                        //duration coloring depending on id(1-4)
                        [styles['taskDuration-pre-rfi']]: el.id === 1,
                        [styles['taskDuration-rfi']]: el.id === 2,
                        [styles['taskDuration-pre-rfp']]: el.id === 3,
                        [styles['taskDuration-rfp']]: el.id === 4
                      })}
                      style={{
                        width: `calc(${getDiffInDates(new Date(el.start), new Date(el.end), 'quarter') + 1} * 100%)`,
                      }} 
                    />
                  )
                }
                return null
              })}
            </div>
          )
        }
      
        taskRows.push(
          <div key={`${task.id}-${index}-${index}-${uuidv4()}`} className={styles['ganttTimePeriodQuarter']}>
            {taskRow}
          </div>
        )
      
        taskRow = []
        mnth.setFullYear(mnth.getFullYear() + 1)
      }
    })
  }
  return taskRows
}