import React from 'react'
import { Grid, Typography, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'

const LeftBlock = () => {
  const theme = useTheme()

  return (
    <div className={styles['form-left-block']}>
      <div className={styles['form-block-inner']}>
        <Typography
          variant='h4'
          className={styles['form-title']}
          style={{color: theme.palette.primary.contrastText}}
        >
          <div>FedScout:</div>
          <div>Government Contracting</div>
          <div>tools designed for life</div>
        </Typography>
        <Typography
          variant='h4'
          className={styles['form-title']}
          style={{color: theme.palette.primary.contrastText}}
        >
          <div>Get started with <span style={{color: theme.palette.warning.main}}>FREE tools</span></div>
          <div><span style={{color: theme.palette.warning.main}}>and upgrade</span> as you grow</div>
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            {/* TODO: Add pages when they will be ready */}
            <a href='#'>
              <SvgIcon
                name='facebook'
                color={theme.palette.primary.contrastText}
                width={30}
                height={30}
              />
            </a>
          </Grid>
          <Grid item>
            <a href='#'>
              <SvgIcon
                name='twitter'
                color={theme.palette.primary.contrastText}
                width={30}
                height={30}
              />
            </a>
          </Grid>
          <Grid item>
            <a href='#'>
              <SvgIcon
                name='linkedin'
                color={theme.palette.primary.contrastText}
                width={30}
                height={30}
              />
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default LeftBlock