import React, { useState } from 'react'
import styles from './index.module.scss'
import LeftMenu from 'components/LeftMenu'
import Main from 'modules/Main'
import { MainFrameContext } from './context/MainFrameContext'

const MainFrame = () => {
  const [isHiddenSidebar, setHiddenSidebar] = useState<boolean>(false)
  
  return (
    <MainFrameContext.Provider value={{isHiddenSidebar, setHiddenSidebar}}>
      <div className={styles['container']}>
        <div className={styles['content-wrapper']}>
          <LeftMenu className={styles['left-menu']} MainFrameContext={MainFrameContext} />
          <div 
            className={styles['main-wrapper']}
            style={{
              width: !isHiddenSidebar ? 'calc(100% - 92px)' : '100%'
            }}
          >
            <Main/>
          </div>
        </div>
      </div>
    </MainFrameContext.Provider>
    
  )
}

export default MainFrame