import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory } from 'react-router-dom'
import { Paper, Button, Grid, Typography } from '@material-ui/core'
import { deleteSimilarCompaniesGroup, getSimilarCompaniesGroups } from 'store/hp/market-research/actions'
import { MarketSimilarCompanyGroup } from 'models/hp/companies/companies.model'
import ListItem from '../components/ListItem'

const ProfileSimilarCompanies = () => {
  const history = useHistory()

  const [groups, setGroups] = React.useState<MarketSimilarCompanyGroup[]>([])

  const fetchSimilarCompaniesGroups = () => {
    getSimilarCompaniesGroups()
      .then((data) => setGroups(data))
      .catch(console.log)
  }

  useEffect(() => {
    fetchSimilarCompaniesGroups()
  }, [])

  const onDeleteGroup = async (group: MarketSimilarCompanyGroup) => {
    await deleteSimilarCompaniesGroup(group)
    fetchSimilarCompaniesGroups()
  }

  return (
    <Paper className={styles['paper']}>
      <Grid container style={{padding: '12px 0 24px'}}>
        {
          groups.length > 0 ? groups.map(group => (
            <ListItem
              item={group}
              onDelete={onDeleteGroup}
              key={group.id}
            />
          )) : (
            <Grid item>
              <Typography>No similar company saved yet</Typography>
            </Grid>
          )
        }
      </Grid>
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/user/market-research/tools/similar-company')}
      >
        Similar Company tool
      </Button>
    </Paper>
  )
}

export default ProfileSimilarCompanies