import React, { ChangeEvent, useRef, useState } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { uploadContractsFromFile } from 'store/hp/rfp/action'
import styles from './index.module.scss'
import ButtonWithLoader from 'components/ButtonWithLoader'
import { defaultSnackbarOrigin, useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { adminUploadContractsFromFile } from 'store/common/owner/actions'

interface Props {
  fromAdmin: boolean
}

const ContractUploader = ({ fromAdmin }: Props) => {

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState('')
  const [requesting, setRequesting] = useState()

  const notification = useRef(useNotification())

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e?.target?.files && e?.target?.files.length > 0 && setFileName(e?.target?.files[0].name)
    const file = e?.target?.files ? e?.target?.files[0] : null
    if (!file) {
      return
    }
    const res: string | ArrayBuffer | null = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event!.target!.result)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      reader.readAsDataURL(file)
    })
    res && typeof res === 'string' && setFile(res.split(',')[1])
  }

  const onDeleteSelectedFile = () => {
    setFile(null)
    setFileName('')
  }

  const onUpload = async () => {
    try {
      setRequesting(true)
      fromAdmin ? await adminUploadContractsFromFile(file as any) : await uploadContractsFromFile(file as any)
      setRequesting(false)
      notification.current.show('Your contracts successful uploaded', NotificationTypeList.Success)
      onDeleteSelectedFile()
    } catch (error) {
      setRequesting(false)
      notification.current.show(getErrorMessage(error), NotificationTypeList.Error, defaultSnackbarOrigin, 60000)
    }
  }

  return (
    <div>
      <Typography variant={fromAdmin ? 'h4' : 'h3'} gutterBottom>Upload Contracts</Typography>
      <Paper className={styles['contract-uploader']}>
        <div className={styles['action-row']}>
          <Button className={styles['action']} variant='contained' component='label' disabled={requesting}>
            Choose File(XLSX), MAX 50MB
            <input
              key={file}
              accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              disabled={requesting} hidden type='file' onChange={onFileChange}/>
          </Button>
          <div className={styles['action']}>
            <Button
              color='primary'
              variant='outlined'
              target='_blank'
              href='/excel-templates/empty-template.xlsx'>
              Download empty template
            </Button>
            <Typography color='error'>
              Note: Red columns are required
            </Typography>
          </div>
          <Button
            className={styles['action']}
            color='primary'
            target='_blank'
            variant='outlined'
            href='/excel-templates/example.xlsx'>
            Download example template
          </Button>
        </div>
        {fileName &&
        <div className={styles['selected-file']}>
          <Typography variant='h5' style={{ margin: 'auto 15px auto 0' }}>{fileName}</Typography>
          <Button color='primary' variant='outlined' onClick={onDeleteSelectedFile}>Delete</Button>
        </div>
        }
        <ButtonWithLoader
          style={{ margin: '10px auto auto' }} color='primary' variant='outlined' onClick={onUpload}
          disabled={!file}
          isRequested={requesting}>
          Upload
        </ButtonWithLoader>
      </Paper>
    </div>
  )
}

export default ContractUploader
