import React from 'react'
import styles from '../Sap/index.module.scss'
import { useHistory } from 'react-router-dom'
import { Paper, Button } from '@material-ui/core'

const ExpiringContracts = () => {
  const history = useHistory()

  return (
    <Paper className={styles['paper']}>
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/user/market-research/tools/expiring-contracts')}
      >
        Expiring contracts tool
      </Button>
    </Paper>
  )
}

export default ExpiringContracts