import React from 'react'
import styles from './index.module.scss'
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { useSelector } from 'react-redux'
import { setActiveGroup } from 'store/common/user-group/actons'
import { userGroupList } from 'store/common/user-group/selectors'

const MyGroups = () => {
  const theme = useTheme()
  const groups = useSelector(userGroupList)
  const notification = useNotification()

  const switchGroup = (groupId: string) => {
    setActiveGroup(groupId).then(() => {
      window.location.reload()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  const leaveGroup = (groupId: string) => {
    setActiveGroup(groupId, true).then(() => {
      window.location.reload()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  return (
    <>
      <Typography variant='h4'>My Groups</Typography>
      <Paper className={styles['clients-paper']}>
        {groups.data.length && !groups.loading ? (
          <Grid>
            {
              groups.data.map(group => (
                <Grid container justifyContent='space-between' alignItems='center' className={styles['clients-item']} key={group.id}>
                  <Typography color={group.active ? 'primary' : undefined} variant='body2'>{group.name} {group.active && '(Active group)'}</Typography>
                  {group.active ?
                    <Button 
                      variant='contained' 
                      color='primary' 
                      style={{background: theme.palette.error.main}}  
                      onClick={() => leaveGroup(group.userGroupId)}
                    >
                        Leave the group
                    </Button>
                    : 
                    <Button variant='contained' color='primary' onClick={() => switchGroup(group.userGroupId)}>Switch to this group</Button>
                  }
                </Grid>
              ))
            }
          </Grid>
        ) : null
        }
        { !groups.data.length && !groups.loading &&
            <Typography align='center' variant='subtitle1'>No groups found.</Typography>
        }
        {
          groups.loading && 
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
        }
      </Paper>
    </>
  )
}

export default MyGroups