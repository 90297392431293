import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import {
  GET_PSC_CODES_ERROR,
  GET_PSC_CODES_SUCCESS,
  GET_PSC_CODES,
  GET_NAICS_CODES_ERROR,
  GET_NAICS_CODES_SUCCESS,
  GET_NAICS_CODES, PSC_CODE_COUNT, NAICS_CODE_COUNT
} from './actions'

export interface IGroupedCodesData {
  pscCount: number
  naicsCount: number
  naicsCodes: any[]
  pscCodes: any[]
}

const GroupedCodesRecord = Record<IRecord<IGroupedCodesData>>({
  loading: false,
  loaded: false,
  data: {
    naicsCount: 0,
    pscCount: 0,
    naicsCodes: [],
    pscCodes: []
  }
})

export const groupedCodesReducer = (state: Record<IRecord<IGroupedCodesData>> = new GroupedCodesRecord(), action: IAction<any[]>) => {
  switch (action.type) {
    case GET_NAICS_CODES:
    case GET_PSC_CODES:
      return state
        .set('loading', true)
    case GET_NAICS_CODES_ERROR:
    case GET_PSC_CODES_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case NAICS_CODE_COUNT:
      return state
        .setIn(['data', 'naicsCount'], action.payload)
    case GET_NAICS_CODES_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .setIn(['data', 'naicsCodes'], action.payload)
    case PSC_CODE_COUNT:
      return state
        .setIn(['data', 'pscCount'], action.payload)
    case GET_PSC_CODES_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .setIn(['data', 'pscCodes'], action.payload)
    default:
      return state
  }
}