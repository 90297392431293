import React from 'react'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import {
  setAsideOptions as setAsideOptionsAction
} from 'store/hp/search/selectors'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  selectedOptions: string[]
}

const SetAsideFilter = ({ handleChange, selectedOptions }: Props) => {
  const setAsideOptions: IFilterOption[] = useSelector(setAsideOptionsAction)

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={setAsideOptions.length}
        iconType='setAside'
        filterTitle='Set Asides'
        filterOptions={setAsideOptions}
        selectedFilterOptions={selectedOptions}
        handleChange={handleChange}
        handlePartialChange={() => {}}
      />
    </div>
  )
}

export default SetAsideFilter