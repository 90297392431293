import React from 'react'
import { IFilterOption } from 'models/hp/search/search.interface'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import styles from '../../index.module.scss'
import { IconStrategy } from 'components/v2/Filters/SearchFilter/FilterTitle'
import SvgIcon from 'components/SvgIcon'
import { actionTypes } from '../Row'
import Modal from 'components/v2/Modal'

interface Props {
  type: actionTypes
  title: string
  isEdit: boolean
  list: IFilterOption[]
  handleDelete: (type: actionTypes, id: number | string, state: boolean, option?: IFilterOption, vieRequest?: boolean) => void
}

const GeneralColumn = ({ list, isEdit, title, handleDelete, type }: Props) => {

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const theme = useTheme()

  const lessShow = [...list].slice(0, 4)

  const modalNameByType: { [key: string]: string } = {
    'psc': 'PSC',
    'naics': 'NAICS',
    'location': 'Locations'
  }

  return (
    <Grid item md={4}>
      <Typography variant='h4' className={styles['title']}>
        {IconStrategy['psc']}
        {title}
      </Typography>
      <hr/>
      {lessShow.map(i => (
        <div key={i.id} className={styles['block-row']}>
          <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
          <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{i.text}</Typography>
          {isEdit &&
          <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            <SvgIcon
              onClick={() => handleDelete(type, i.id, false, undefined, true)}
              name='trash'
              color={theme.palette.warning.light}
              width={30}
              height={30}
            />
          </div>
          }
        </div>
      ))}
      {list.length > 4 &&
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalOpen(true)}
      >
        More
      </Button>
      }
      {modalOpen &&
      <Modal
        title={modalNameByType[type]}
        open={modalOpen}
        size='md'
        onClose={() => setModalOpen(false)}
      >
        {list.map(i => (
          <div key={i.id} className={styles['block-row']}>
            <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
            <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{i.text}</Typography>
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
              <SvgIcon
                onClick={() => handleDelete(type, i.id, false, undefined, true)}
                name='trash'
                color={theme.palette.warning.light}
                width={30}
                height={30}
              />
            </div>
          </div>
        ))}
      </Modal>
      }
    </Grid>
  )
}

export default GeneralColumn
