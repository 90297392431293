import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { IResetPasswordForm, RESET_PASSWORD_INIT_VALUE, RESET_PASSWORD_VALIDATION_SCHEMA } from './components/Form'
import FormRenderer from './components/FormRenderer'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { userRecoverPasswordResetAction } from 'store/common/user/actions'
import { useHistory, useParams } from 'react-router-dom'

const ResetPassword = () => {

  const { email, token } = useParams()
  const history = useHistory()

  const { show } = useNotification()

  const [ isRequested, setIsRequested ] = useState<boolean>(false)

  const onSubmit = async (values: IResetPasswordForm) => {
    setIsRequested(prev => !prev)
    try {
      const response = await userRecoverPasswordResetAction(values.password, email, token)
      setIsRequested(prev => !prev)
      response.message && show(response.message, NotificationTypeList.Success)
      history.push({ pathname: '/auth/sign-in', search: history.location.search })
    } catch (error) {
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
      setIsRequested(prev => !prev)
    }
  }

  return (
    <Formik
      initialErrors={RESET_PASSWORD_INIT_VALUE}
      initialValues={RESET_PASSWORD_INIT_VALUE}
      validationSchema={RESET_PASSWORD_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formProps: FormikProps<IResetPasswordForm>) => (
        <FormRenderer {...formProps} isRequested={isRequested}/>
      )}
    </Formik>
  )
}

export default ResetPassword