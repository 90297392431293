import { IRootState } from '../../reducers'
import { createSelector } from 'reselect'
import { FilterOption } from 'models/hp/search/search.model'

const agencies = (state: IRootState) => state.agencies

export const agenciesLoadingSelector = createSelector(
  agencies,
  (agencies) => agencies.get('loading')
)
export const agenciesLoadedSelector = createSelector(
  agencies,
  (agencies) => agencies.get('loaded')
)
export const agenciesSelector = createSelector(
  agencies,
  (agencies) => agencies.get('data')
)
export const agenciesSubSelector = createSelector(
  agencies,
  (agencies) => agencies.get('data')?.reduce((acc, el) => {
    return acc.concat(el.sub as any)
  }, [])
)
export const agenciesSubSelectorByName = (name: string) => createSelector(
  agencies,
  (agencies) => {
    const subAgencies = agencies.get('data')?.reduce((acc, el) => {
      return acc.concat(el.sub as FilterOption[])
    }, [] as FilterOption[])
    return subAgencies?.find((el: FilterOption) => el.text === name)?.sub || []
  }
)

export const agencyCountSelector = createSelector(
  agencies,
  agencies => agencies.get('count')
)

export const  agencyDepartmentMapperSelector = createSelector(
  agencies,
  agencies => agencies.get('departmentMapper')
)

export const  agencySubtierMapperSelector = createSelector(
  agencies,
  agencies => agencies.get('subtierMapper')
)
