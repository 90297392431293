import { Record } from 'immutable'
import {
  GET_FILTER_OPTIONS,
  GET_FILTER_OPTIONS_ERROR,
  GET_FILTER_OPTIONS_SUCCESS,
  SET_SEARCH_FILTER_VALUE,
  SET_SEARCH_FILTER_VALUES,
  REMOVE_SEARCH_FILTER_VALUE,
  REMOVE_SEARCH_FILTER_VALUES,
  RESET_SEARCH_FILTERS,
  SET_SEARCH_FILTER_VALUE_BOOL,
  GET_SEARCHES,
  GET_SEARCHES_SUCCESS,
  GET_SEARCHES_ERROR,
  GET_SEARCH_DETAILS,
  GET_SEARCH_DETAILS_SUCCESS,
  GET_SEARCH_DETAILS_ERROR,
  SET_SEARCH_FILTER_DATA,
  SET_MODAL_OPENED,
  CREATE_SEARCH, 
  UPDATE_SEARCH, 
  DELETE_SEARCH, 
  REMOVE_SEARCH_DETAILS, 
  SET_NORMALIZED_OPTIONS, 
  UPDATE_NORMALIZED_OPTIONS,
  UPDATE_SEARCH_FILTER_VALUES
} from './actions'
import { IAction } from 'types/common'
import { convertStringToKeywords } from 'helpers/search'
import { SearchModel, SearchOptionsPayload } from 'models/hp/search/search.model'
import { INormalizedFilterOptions, ISearchDTO, ISearchLightModel } from 'models/hp/search/search.interface'

export type TSearchOpenedModals = 'savedSearch' | 'maturityFilter' | 'pscCodeFilter'
export interface ISearchRecord {
  filterOptions: {
    loaded: boolean
    loading: boolean
    data: SearchOptionsPayload
  }
  normalizedFilterOptions: {
    data: INormalizedFilterOptions
  }
  searchFilters: ISearchDTO
  selectedSearch: {
    loading: boolean
    loaded: boolean
    data: SearchModel | null
  }
  searches: {
    loaded: boolean
    loading: boolean
    data: SearchModel[] | null
  },
  openedModals: {
    [key in TSearchOpenedModals]: boolean
  }
}

export const defaultSearchFilters = (): ISearchDTO => {
  const queryParams = new URLSearchParams(window.location.search)

  // TODO: Refactor this check to use separate storage for pipeline search and contracts search filters
  const shouldParse = ['/contracts'].some(route => window.location.pathname === route)

  const defaultKeywords = shouldParse ? queryParams.get('keywords') || '' : ''
  const defaultNaicsCodes = shouldParse ? queryParams.get('naics')?.split(',').map(id => Number(id)) || [] : []
  const defaultAnalyzedTypes = shouldParse ? queryParams.get('analyzedTypes')?.split(',').map(id => Number(id)) || [] : []
  const defaultPscCodes = shouldParse ? queryParams.get('psc')?.split(',').map(id => Number(id)) || [] : []
  const defaultCustomers = shouldParse ? queryParams.get('customers')?.split(',').map(id => Number(id)) || [] : []
  const defaultLocations = shouldParse ? queryParams.get('locations')?.split(',').map(id => Number(id)) || [] : []
  const defaultSetAsides = shouldParse ? queryParams.get('setAsides')?.split(',').map(id => Number(id)) || [] : []
  const defaultPastPerformance = shouldParse ? queryParams.get('pastPerformance') || null : null
  const defaultProjectTypes = shouldParse ? queryParams.get('projectTypes')?.split(',').map(id => Number(id)) || [] : []
  const defaultDueDates = shouldParse ? queryParams.get('dueDates')?.split(',').map(id => Number(id)) || [] : []

  return {
    keywords: convertStringToKeywords(defaultKeywords) || [],
    contractTypeIds: [],
    customerIds: defaultCustomers,
    allCustomers: true,
    dueDateIds: defaultDueDates,
    allDueDates: false,
    estimatedEffortIds: [],
    allEstimatedEfforts: false,
    estimatedValueIds: [],
    allEstimatedValues: false,
    groupedCodeIds: defaultNaicsCodes,
    allGroupedCodes: true,
    locationIds: defaultLocations,
    workLocation: 'SPECIFIC',
    projectTypeIds: defaultProjectTypes,
    allProjectTypes: true,
    pscCodeIds: defaultPscCodes,
    allPscCodes: true,
    setAsideIds: defaultSetAsides,
    analyzedTypeIds: defaultAnalyzedTypes,
    allSetAsides: true,
    createdAt: undefined,
    pastPerformance: defaultPastPerformance,
    excludeIds: []
  }
}

const SearchRecord = Record<ISearchRecord>({
  filterOptions: {
    loaded: false,
    loading: false,
    data: new SearchOptionsPayload()
  },
  normalizedFilterOptions: {
    data: {
      contractTypes: {},
      projectTypes: {},
      setAsides: {},
      dueDates: {},
      estimatedValues: {},
      estimatedEfforts: {},
      locations: {},
      analyzedTypes: {}
    }
  },
  searchFilters: defaultSearchFilters(),
  selectedSearch: {
    loading: false,
    loaded: false,
    data: null
  },
  searches: {
    loaded: false,
    loading: false,
    data: null
  },
  openedModals: {
    savedSearch: false,
    maturityFilter: false,
    pscCodeFilter: false
  }
})

export const hpSearch = (
  state: Record<ISearchRecord> = new SearchRecord(),
  action: IAction
) => {
  switch (action.type) {
    case GET_SEARCHES:
      return state
        .setIn(['searches', 'loading'], true)
    case GET_SEARCHES_SUCCESS:
      return state
        .setIn(['searches', 'loading'], false)
        .setIn(['searches', 'loaded'], true)
        .setIn(['searches', 'data'], action.payload)
    case GET_SEARCHES_ERROR:
      return state
        .setIn(['searches', 'loading'], false)
        .setIn(['searches', 'loaded'], true)
        .setIn(['searches', 'data'], []) // set empty array as a valid response

    case GET_SEARCH_DETAILS:
      return state
        .setIn(['selectedSearch', 'loading'], true)
    case GET_SEARCH_DETAILS_SUCCESS:
      return state
        .setIn(['selectedSearch', 'loading'], false)
        .setIn(['selectedSearch', 'loaded'], true)
        .setIn(['selectedSearch', 'data'], action.payload)
    case GET_SEARCH_DETAILS_ERROR:
      return state
        .setIn(['selectedSearch', 'loading'], false)
        .setIn(['selectedSearch', 'loaded'], false)
    case REMOVE_SEARCH_DETAILS:
      return state
        .setIn(['selectedSearch', 'loading'], false)
        .setIn(['selectedSearch', 'loaded'], false)
        .setIn(['selectedSearch', 'data'], null)

    case GET_FILTER_OPTIONS:
      return state
        .setIn(['filterOptions', 'loading'], true)
    case GET_FILTER_OPTIONS_SUCCESS:
      return state
        .setIn(['filterOptions', 'loading'], false)
        .setIn(['filterOptions', 'loaded'], true)
        .setIn(['filterOptions', 'data'], action.payload)
    case SET_NORMALIZED_OPTIONS:
      return state
        .updateIn(['normalizedFilterOptions', 'data'], (data) => ({...data as object, ...action.payload }))
    case UPDATE_NORMALIZED_OPTIONS:
      return state
        .updateIn(['normalizedFilterOptions', 'data'], (data) => ({...data as object, [action.payload.key]: action.payload.data }))
    case GET_FILTER_OPTIONS_ERROR:
      return state
        .setIn(['filterOptions', 'loading'], false)
    case SET_SEARCH_FILTER_VALUE_BOOL:
      return state
        .setIn(['searchFilters', action.payload.key], action.payload.value)
    case SET_SEARCH_FILTER_VALUE:
      return state
        .updateIn(['searchFilters', action.payload.key], (arr: any) => [...arr, action.payload.value])
    case SET_SEARCH_FILTER_VALUES:
      return state
        .mergeIn(['searchFilters', action.payload.key], action.payload.value)
    case UPDATE_SEARCH_FILTER_VALUES:
      return state
        .updateIn(['searchFilters', action.payload.key], () => action.payload.value)
    case REMOVE_SEARCH_FILTER_VALUE:
      return state
        .updateIn(['searchFilters', action.payload.key], (arr: any) => arr.filter((x: any) => x !== action.payload.value))
    case REMOVE_SEARCH_FILTER_VALUES:
      return state
        .updateIn(['searchFilters', action.payload.key], (arr: any) => arr.filter((x: any) => !action.payload.value.includes(x)))
    case SET_SEARCH_FILTER_DATA:
      return state
        .setIn(['searchFilters', 'keywords'], action.payload.keywords)
        .setIn(['searchFilters', 'contractTypeIds'], action.payload.contractTypeIds.length > 0
          ? action.payload.contractTypeIds
          : action.payload.contractTypes ? action.payload.contractTypes.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'customerIds'], action.payload.customerIds.length > 0
          ? action.payload.customerIds
          : action.payload.customers ? action.payload.customers.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allCustomers'], action.payload.allCustomers)
        .setIn(['searchFilters', 'dueDateIds'], action.payload.dueDateIds.length > 0
          ? action.payload.dueDateIds
          : action.payload.dueDates ? action.payload.dueDates.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allDueDates'], action.payload.allDueDates)
        .setIn(['searchFilters', 'estimatedEffortIds'], action.payload.estimatedEffortIds.length > 0
          ? action.payload.estimatedEffortIds
          : action.payload.estimatedEfforts ? action.payload.estimatedEfforts.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allEstimatedEfforts'], action.payload.allEstimatedEfforts)
        .setIn(['searchFilters', 'estimatedValueIds'], action.payload.estimatedValueIds.length > 0
          ? action.payload.estimatedValueIds
          : action.payload.estimatedValues ? action.payload.estimatedValues.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allEstimatedValues'], action.payload.allEstimatedValues)
        .setIn(['searchFilters', 'groupedCodeIds'], action.payload.groupedCodeIds.length > 0
          ? action.payload.groupedCodeIds
          : action.payload.groupedCodes ? action.payload.groupedCodes.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allGroupedCodes'], action.payload.allGroupedCodes)
        .setIn(['searchFilters', 'locationIds'], action.payload.locationIds.length > 0
          ? action.payload.locationIds
          : action.payload.states ? action.payload.states.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'workLocation'], action.payload.workLocation)
        .setIn(['searchFilters', 'projectTypeIds'], action.payload.projectTypeIds.length > 0
          ? action.payload.projectTypeIds
          : action.payload.projectTypes ? action.payload.projectTypes.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allProjectTypes'], action.payload.allProjectTypes)
        .setIn(['searchFilters', 'pscCodeIds'], action.payload.pscCodeIds.length > 0
          ? action.payload.pscCodeIds
          : action.payload.pscCodes ? action.payload.pscCodes.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allPscCodes'], action.payload.allPscCodes)
        .setIn(['searchFilters', 'setAsideIds'], action.payload.setAsideIds.length > 0
          ? action.payload.setAsideIds
          : action.payload.setAsides ? action.payload.setAsides.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'analyzedTypeIds'], action.payload.analyzedTypeIds?.length > 0
          ? action.payload.analyzedTypeIds
          : action.payload.analyzedTypes ? action.payload.analyzedTypes.map((i: any) => i.id) : []
        )
        .setIn(['searchFilters', 'allSetAsides'], action.payload.allSetAsides)
        .setIn(['searchFilters', 'createdAt'], action.payload.createdAt)
        .setIn(['searchFilters', 'pastPerformance'], action.payload.pastPerformance)
 
    case RESET_SEARCH_FILTERS:
      return state
        .set('searchFilters', defaultSearchFilters())

    case SET_MODAL_OPENED:
      return state
        .setIn(['openedModals', action.payload.key], action.payload.state)

    case CREATE_SEARCH:
      return state
        .updateIn(['searches', 'data'], (searches: any) => searches.concat(action.payload))
    case UPDATE_SEARCH:
      return state
        .updateIn(
          ['searches', 'data'],
          (searches: any) => searches.map((search: ISearchLightModel) =>
            search.id === action.payload.id ? {...search, name: action.payload.name} : search ))
    case DELETE_SEARCH:
      return state
        .updateIn(
          ['searches', 'data'],
          (searches: any) => searches.filter((search: ISearchLightModel) => search.id !== action.payload.id))
    default:
      return state
  }
}