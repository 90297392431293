import { jsonMember, jsonObject } from 'typedjson'
import { IPscDescriptionModel } from './psc.interface'

@jsonObject
export class PscDescriptionModel implements IPscDescriptionModel {
  @jsonMember({ constructor: String })
  majorName: string = ''

  @jsonMember({ constructor: String })
  minorName: string = ''

  @jsonMember({ constructor: String })
  majorNumber: string = ''

  @jsonMember({ constructor: String })
  fullNumber: string = ''

  @jsonMember({ constructor: String })
  fullName: string = ''

  @jsonMember({ constructor: String })
  minorNumber: string = ''

  @jsonMember({ constructor: Number })
  id: number | null = null
}