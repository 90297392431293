import { applyMiddleware, createStore, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { root as rootReducer, IRootState } from './reducers'
// import loggerMiddleware from 'redux-logger'

declare global {
  interface Window {
    store: Store<IRootState>
  }
}

export function configureStore() {
  const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const middlewares = [
    thunkMiddleware,
    // isDevelopment && loggerMiddleware

  ].filter(m => !!m)

  const enhancer = applyMiddleware(...middlewares)
  const store = createStore(rootReducer, undefined, enhancer)

  if (isDevelopment) {
    window.store = store
  }

  return store
}
