import moment from 'moment'
import { PipelinePageType, PipelineTaskPageType } from 'utils/const'
import { IFilterOption } from 'models/hp/search/search.interface'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { TaskItem } from 'models/hp/pipeline-task/pipeline-task.model'
import { pipelineTypes } from './rfp'
import { IPipelineDashboardCounters } from 'store/hp/pipeline/dashboard/reducer'

export const contractTypes: { [key: string]: IFilterOption[] } = {
  [PipelineTaskPageType.marketing]: [
    {id: 'Podcast', text: 'Podcast'},
    {id: 'Article', text: 'Article'},
    {id: 'Research/Survey', text: 'Research/Survey'},
    {id: 'Other', text: 'Other'}
  ],
  [PipelineTaskPageType.government]: [
    {id: 'Agency Leadership', text: 'Agency Leadership' },
    {id: 'Program Officer', text: 'Program Officer' },
    {id: 'End User', text: 'End User' },
    {id: 'Contracting Officer', text: 'Contracting Officer' },
    {id: 'Small Business Officer', text: 'Small Business Officer' }
  ],
  [PipelineTaskPageType.partner]: [
    {id: 'Company Leadership', text: 'Company Leadership'},
    {id: 'Account Manager', text: 'Account Manager'},
    {id: 'Capture Manager', text: 'Capture Manager'},
    {id: 'Proposal Manager', text: 'Proposal Manager'},
    {id: 'Vendor Manager', text: 'Vendor Manager'}
  ],
  [PipelineTaskPageType.business]: [
    {id: 'Registrations & Certifications', text: 'Registrations & Certifications'},
    {id: 'Service providers', text: 'Service providers'},
    {id: 'Marketing & proposal materials', text: 'Marketing & proposal materials'},
    {id: 'Events & associations', text: 'Events & associations'},
  ],
  [PipelineTaskPageType.hire]: [
    {id: 'Prospective hire', text: 'Prospective hire'},
    {id: 'OEM', text: 'OEM'},
  ]
}

export const actionTitles: {[key in PipelineTaskPageType]: string} = {
  [PipelineTaskPageType.partner]: 'Add Partner',
  [PipelineTaskPageType.marketing]: 'Create Task',
  [PipelineTaskPageType.government]: 'Add Customer',
  [PipelineTaskPageType.business]: 'Add Task',
  [PipelineTaskPageType.hire]: 'Add Hire/OEM',
}

export const actionUpdateTitles: {[key in PipelineTaskPageType]: string} = {
  [PipelineTaskPageType.partner]: 'Update Partner',
  [PipelineTaskPageType.marketing]: 'Update Task',
  [PipelineTaskPageType.government]: 'Update Customer',
  [PipelineTaskPageType.business]: 'Update Task',
  [PipelineTaskPageType.hire]: 'Update Hire/OEM',
}

export const inputNameTitles: {[key in PipelineTaskPageType]: string} = {
  [PipelineTaskPageType.partner]: 'Partner name',
  [PipelineTaskPageType.marketing]: 'Task name',
  [PipelineTaskPageType.government]: 'Customer name',
  [PipelineTaskPageType.business]: 'Task name',
  [PipelineTaskPageType.hire]: 'Name',
}

export const inputOwnerNameTitles: {[key in PipelineTaskPageType]: string} = {
  [PipelineTaskPageType.partner]: 'Assign relationship owner',
  [PipelineTaskPageType.marketing]: 'Assign task owner',
  [PipelineTaskPageType.government]: 'Assign relationship owner',
  [PipelineTaskPageType.business]: 'Assign task owner',
  [PipelineTaskPageType.hire]: 'Assign relationship owner',
}

export const getCurrentContractTypes = (currentPage: PipelineTaskPageType): IFilterOption[] => {
  return contractTypes[currentPage] as IFilterOption[] || []
}

export const getUserText = (user: IUserBaseModel): string => {
  return [user.firstName, user.lastName].filter(x => !!x).join(' ')
}

export const detectPipelinePageByRfpType = (type: string) => {
  const strategy = [
    {
      page: PipelinePageType.open,
      types: pipelineTypes.open
    },
    {
      page: PipelinePageType.distant,
      types: pipelineTypes.distant
    },
    {
      page: PipelinePageType.upcoming,
      types: pipelineTypes.upcoming
    },
    {
      page: PipelinePageType.award,
      types: pipelineTypes.open
    }
  ]
  const found = strategy.find(strategyItem => strategyItem.types.includes(type))
  return found ? found.page : null
}

export const combinedPipeline = {
  rfp: {
    steps: {
      owner: 'owner',
      scan: 'scan',
      preScreen: 'pre-screen',
      incumbent: 'incumbent',
      companiesPartners: 'companies-partners',
      tasksToComplete: 'tasks-to-complete',
      valueOfPursuing: 'value-of-pursuing',
      chancesOfWinning: 'chances-of-winning',
      effortToPursue: 'effort-to-pursue',
      goNogo: 'go-nogo',
    },
    get stepsOrder() {
      return [
        this.steps.owner,
        this.steps.scan,
        this.steps.preScreen,
        this.steps.incumbent,
        this.steps.companiesPartners,
        this.steps.tasksToComplete,
        this.steps.valueOfPursuing,
        this.steps.chancesOfWinning,
        this.steps.effortToPursue,
        this.steps.goNogo
      ]
    },
    get titles() {
      return {
        [this.steps.owner]: () => 'Assign an owner for: ',
        [this.steps.scan]: () => 'Scan: ',
        [this.steps.preScreen]: () => 'Pre-screen: ',
        [this.steps.incumbent]: () => 'Research the incumbent: ',
        [this.steps.companiesPartners]: () => 'Identify companies to work with: ',
        [this.steps.tasksToComplete]: () => 'Identify which tasks you can complete: ',
        [this.steps.valueOfPursuing]: () => 'Assess the value of pursuing: ',
        [this.steps.chancesOfWinning]: () => 'Assess your chances of winning: ',
        [this.steps.effortToPursue]: () => 'Assess the effort of pursue: ',
        [this.steps.goNogo]: () => 'Make Go/No-Go: ',
      }
    },
    getNextStep(currentStep: string | null): number {
      if (currentStep === null) {
        return 0
      }
      const nextStep = Number(currentStep) + 1 // get next step by iterating value
      if (!this.stepsOrder[nextStep]) {
        return Number(currentStep)
      }
      return nextStep
    },
    getTitle(currentStep: string | null) {
      const step = this.stepsOrder[this.getNextStep(currentStep)]
      return this.titles[step]()
    },
    areStepsCompleted(step: string | null) {
      if (!step) return false
      const nextStep = Number(step) + 1
      return !this.stepsOrder[nextStep]
    }
  },
  task: {
    steps: {
      partners: 'partners',
      decisionMakers: 'decision-makers',
      owner: 'owner',
      engage: 'engage'
    },
    get stepsOrder() {
      return [
        this.steps.partners,
        this.steps.decisionMakers,
        this.steps.owner,
        this.steps.engage
      ]
    },
    get titles() {
      return {
        [PipelineTaskPageType.partner]: {
          [this.steps.partners]: (title?: string) => 'Find potential partners',
          [this.steps.decisionMakers]: (title: string) => 'Find decision makers at potential partners',
          [this.steps.owner]: (title: string) => `Assign an owner for: ${title}`,
          [this.steps.engage]: (title: string) => `Engage: ${title}`,
        },
        [PipelineTaskPageType.government]: {
          [this.steps.partners]: (title: string) => 'Find potential customers',
          [this.steps.decisionMakers]: (title: string) => 'Find decision makers at potential customers',
          [this.steps.owner]: (title: string) => `Assign an owner for: ${title}`,
          [this.steps.engage]: (title: string) => `Engage: ${title}`,
        },
        [PipelineTaskPageType.marketing]: {
          [this.steps.partners]: (title: string) => 'Find potential partners',
          [this.steps.decisionMakers]: (title: string) => 'Find decision makers at potential partners',
          [this.steps.owner]: (title: string) => `Assign an owner for: ${title}`,
          [this.steps.engage]: (title: string) => `Engage: ${title}`,
        },
        [PipelineTaskPageType.business]: {
          [this.steps.partners]: (title: string) => 'Find potential partners',
          [this.steps.decisionMakers]: (title: string) => 'Find decision makers at potential partners',
          [this.steps.owner]: (title: string) => `Assign an owner for: ${title}`,
          [this.steps.engage]: (title: string) => `Engage: ${title}`,
        },
        [PipelineTaskPageType.hire]: {
          [this.steps.partners]: (title: string) => 'Find potential partners',
          [this.steps.decisionMakers]: (title: string) => 'Find decision makers at potential partners',
          [this.steps.owner]: (title: string) => `Assign an owner for: ${title}`,
          [this.steps.engage]: (title: string) => `Engage: ${title}`,
        },
      }
    },
    getCurrentStep(step: string) {
      const currentStep = Number(step)
      return this.stepsOrder[currentStep]
    },
    getNextStep(currentStep: string | null): number {
      if (currentStep === null) {
        return 0
      }
      const nextStep = Number(currentStep) + 1 // get next step by iterating value
      if (!this.stepsOrder[nextStep]) {
        return Number(currentStep)
      }
      return nextStep
    },
    getTitle(item: TaskItem) {
      const pageType = item.type.split('__')[0] as PipelineTaskPageType
      const step = this.stepsOrder[Number(this.getNextStep(item.step))]
      return this.titles[pageType][step](item.name)
    },
    areStepsCompleted(taskStep: string | null): boolean {
      if (!taskStep) return false
      const nextStep = Number(taskStep) + 1
      return !this.stepsOrder[nextStep]
    }
  }
}

export const getSnoozeDate = (): Date => {
  return moment().add(1, 'week').toDate()
}

export const validateSnooze = (date?: Date): boolean => {
  return date ? date > new Date() : false
}

export const pipelineHealthGradeCalculator = (grades: IPipelineDashboardCounters): IPipelineDashboardCounters => {
  const amounts = {
    [PipelinePageType.upcoming]: [
      {handler: (val: number) => val >= 3 && val <= 4, points: 5},
      {handler: (val: number) => val >= 1 && val <= 2, points: 3},
      {handler: (val: number) => val >= 5, points: -2},
      {handler: (val: number) => val === 0, points: 0},
    ],
    [PipelinePageType.open]: [
      {handler: (val: number) => val >= 3, points: -2},
      {handler: (val: number) => val === 2, points: 3},
      {handler: (val: number) => val === 1, points: 2},
      {handler: (val: number) => val === 0, points: 0},
    ],
    [PipelinePageType.distant]: [
      {handler: (val: number) => val >= 3 && val <= 4, points: 5},
      {handler: (val: number) => val >= 1 && val <= 2, points: 3},
      {handler: (val: number) => val >= 5, points: -2},
      {handler: (val: number) => val === 0, points: 0},
    ]
  }
  return {
    [PipelinePageType.upcoming]: amounts[PipelinePageType.upcoming].find(x => x.handler(grades[PipelinePageType.upcoming]))?.points || 0,
    [PipelinePageType.open]: amounts[PipelinePageType.open].find(x => x.handler(grades[PipelinePageType.open]))?.points || 0,
    [PipelinePageType.distant]: amounts[PipelinePageType.distant].find(x => x.handler(grades[PipelinePageType.distant]))?.points || 0,
  }
}

