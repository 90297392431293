import React from 'react'
import styles from 'components/v2/Wrapper/index.module.scss'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

interface BigHintProps {
  close?: () => void
}

export const BigHint = ({close}: BigHintProps) => {
  return (
    <Grid
      container
      style={{height: '100%'}}
      direction='column'
    >
      <Paper className={styles['paper']}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>Find Similar Companies</Typography>
          <SvgIcon
            name='multiply'
            width={30}
            height={30}
            className={styles['clickable']}
            onClick={close}
          />
        </Grid>
        <Typography>Difficulty: <b>Low</b></Typography>
        <Typography>Time: <b>5 min</b></Typography>
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
          <Grid item md={6}>
            <SvgIcon name='film' width={250} height={250}/>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>Why Similar Companies are important</Typography>
            <Typography gutterBottom>
              The government market is one of the last bastions of schmoozing, handing out business cards, powerful
              trade associations, and boozy business happy hours. And this is because the government market floats
              on
              a
              sea of relationships.
            </Typography>
            <Typography gutterBottom>
              We CAN NOT overstate how important relationships with other companies in your space are. Whether you
              are
              brand new or a top 10 prime your ability to bid and win work is directly related to your ability to
              build
              winning teams.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>What You’ll Do With Similar Company info</Typography>
            <Typography gutterBottom>
              <b>Try to eat their lunch:</b> One of the best ways to find sales opportunities is to look at the
              contracts
              similar companies have that are expiring in the next ~2 years and then building a plan to take them.
            </Typography>
            <Typography>
              <b>Invite yourself to lunch:</b> If you are new vendor you may not be able to take someone else’s
              contract,
              but 24-18 months before expiration the current contract holder is going to start planning to win the
              next generation of the contract (and statistically they probably will). So start building a
              relationship
              now and convince them to bring you onto their team when they bid the re-compete.
            </Typography>
            <Typography>
              <b>Go get lunch together:</b> If you’re a new vendor you are going to be teaming, subbing, and
              partnering
              for at least your first 3-6 contracts. So start building relationships with similar companies today so
              that when you find a contract that is great, but a little too big for you, you can partner with them
              to
              win the work together.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' gutterBottom>How to find similar companies</Typography>
            <Typography gutterBottom>
              We’ve made this simple. Put in a couple keywords and any other filters that you want, and we’ll return
              a
              list of relevant companies along with a sampling of contracts that they’ve won in the last couple
              years.
            </Typography>
            <Typography gutterBottom>
              Select the companies that look promising and we’ll save them for easy reference later and email the
              list
              to you.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export const SmallHint = () => {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{height: '100%'}}
      direction='column'
    >
      <Typography variant='h3' gutterBottom>Enter on or more keywords and hit</Typography>
      <Button
        color='primary'
        variant='contained'
      >
        Find similar companies
      </Button>
      <Typography
        variant='h3'
        style={{marginTop: '12px'}}
      >
        To get started
      </Typography>
    </Grid>
  )
}