import React from 'react'
import styles from './index.module.scss'
import ButtonLink from 'components/v3/ButtonLink'
import { Button, Typography } from '@material-ui/core'
import { useTour } from '@reactour/tour'
import { setTutorial } from 'utils/localStorage'

interface InternalFlowLayoutProps {
    children?: JSX.Element | React.Component
    type?: string
}

const TutorialLayout = ({children, type = 'tutorialContractSearch'}: InternalFlowLayoutProps) => {
  const {setIsOpen, setCurrentStep, currentStep, steps} = useTour()
  const first = currentStep === 0
  const last = currentStep === steps.length - 1

  const onNext = () => {
    if (last) {
      setIsOpen(false)
      setCurrentStep(0)
      setTutorial(type)
    } else {
      setCurrentStep((s) => (s === steps?.length! - 1 ? 0 : s + 1))
    }
  }

  const onPrev = () => {
    if (first) {
      setCurrentStep((s) => steps?.length! - 1)
    } else {
      setCurrentStep((s) => s - 1)
    }
  }

  const handleSkip = () => {
    setTutorial(type)
    setIsOpen(false)
  }

  return (
    <div className={styles['internal-wrapper']}>
      {
        children ? (
          <div className={styles['internal-content']}>
            {children}
          </div>
        ) : null
      }
      <div className={styles['internal-control']}>
        <div className={styles['internal-control-badge']}>
          <Typography className={styles['internal-control-badge-value']}>{currentStep + 1 + ' of ' + steps.length}</Typography>
        </div>
        <div className={styles['internal-control-actions']}>
          {last ? null : <ButtonLink title='Skip' onClick={handleSkip} />}
          {
            first ? null : (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={onPrev}
              >
                Previous
              </Button>
            )
          }
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={onNext}
          >
            {last ? 'Ok' : 'Next'}  
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TutorialLayout