import axios, { AxiosResponse, Method } from 'axios'
import { TypedJSON, Constructor } from 'typedjson'
import { getToken } from './localStorage'
import { getTokenFromSession } from './sessionStorage'

interface RequestData {
  body?: any
  schema?: Constructor<any>
  params?: any
  fileName?: string
  token?: string,
  lazyUser?: boolean
  headers?: {
    [key: string]: string
  },
  noHeaders?: boolean
}

export async function request<T>(method: Method, url: string, opts?: RequestData, withCredential: boolean = true): Promise<AxiosResponse<T>> {
  let headers: { [key: string]: any } = {
    'ff-client': 'web',
    'ff-version': 'latest', // latest always
  }
  if (withCredential) {
    headers = {
      ...headers,
      Authorization: opts && opts.token
        ? opts.token
        : getToken()
          ? getToken()
          : opts?.lazyUser && getTokenFromSession(),
    }
  }
  if (opts?.headers) {
    headers = {
      ...headers,
      ...opts.headers
    }
  }
  const res = await axios.request({
    method,
    url,
    data: opts && opts.body,
    params: opts && opts.params,
    headers: !opts?.noHeaders ? headers : undefined
  })

  if (opts && opts.schema) { // parse json according to model
    const serializer = new TypedJSON(opts.schema)
    res.data = Array.isArray(res.data) ? serializer.parseAsArray(res.data) : serializer.parse(res.data)
  }

  return res
}

export async function requestAndDownload(method: Method, url: string, opts?: RequestData, withCredential: boolean = true): Promise<void> {
  const res = await axios.request({
    method,
    url,
    data: opts && opts.body,
    params: opts && opts.params,
    headers: withCredential && {
      Authorization: getToken(),
      'ff-client': 'web',
      'ff-version': 'latest' // latest always
    }
  })
  const fileName = opts?.fileName || `response-${new Date().getTime()}`
  const linkUrl = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = linkUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}