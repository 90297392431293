import React from 'react'
import { getCurrentContractTypes, inputNameTitles } from 'helpers/pipeline'
import { PipelineTaskPageType, PipelineTaskStatuses } from 'utils/const'
import { Grid } from '@material-ui/core'
import TextField from 'components/v2/TextField'
import Select from 'components/v2/Select'
import ButtonWithLoader from 'components/ButtonWithLoader'
import { Formik } from 'formik'
import { TaskItem } from 'models/hp/pipeline-task/pipeline-task.model'
import * as Yup from 'yup'
import { createPipelineTask, managePipelineTask } from 'store/hp/pipeline/task/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { useDispatch } from 'react-redux'
import { useNotification } from 'context/NotificationContext'
import DepartmentSelect from './DepartmentSelect'

const validationSchema = Yup.object({
  name: Yup.string().required('Field is required'),
  role: Yup.string().required('Field is required'),
  text: Yup.string().optional()

})

interface Props {
  task: TaskItem
  handleSave: (task: TaskItem, isCreating?: boolean) => void
}

const FormItem = ({task, handleSave}: Props) => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const isCreating = !task.id

  const [processing, setProcessing] = React.useState(false)

  const handleSubmit = async (values: TaskItem) => {
    setProcessing(true)
    try {
      const res = isCreating
        ? (await createPipelineTask(dispatch)(values))
        : (await managePipelineTask(dispatch)(task.id, values))
      setProcessing(false)
      const msg = isCreating
        ? 'Task successfully created, you can find it in Pipeline / Government section'
        : 'Task successfully updated'
      notification.show(msg, NotificationTypeList.Success)

      handleSave(res || values, isCreating)
    }
    catch (e) {
      setProcessing(false)
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          ...task,
          name: task.name,
          role: (task.role || getCurrentContractTypes(PipelineTaskPageType.government)[0].text! || '') as PipelineTaskStatuses,
          type: task.type ||  'government__1',
          text: task.text || ''
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          errors,
          isValid
        }) => (
          <Grid container spacing={2} direction='row' alignItems='center'>
            <Grid item md={5}>
              <TextField
                label={inputNameTitles['government']}
                variant='filled'
                value={values.name}
                onChange={handleChange('name')}
                fullWidth
                error={!!(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={3}>
              <Select
                fullWidth={true}
                label='Select role'
                options={getCurrentContractTypes(PipelineTaskPageType.government).map(type => ({ key: type.text, value: type.id }))}
                onClick={handleChange('role')}
                value={values['role'] || ''}
              />
            </Grid>
            <Grid item md={3}>
              <DepartmentSelect
                onChange={handleChange('text')}
                value={values.text || ''}
              />
            </Grid>
            <Grid item md={1}>
              <Grid container justifyContent='space-between'>
                <ButtonWithLoader
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={() => handleSubmit()}
                  isRequested={processing}
                >
                  Save
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  )
}

export default FormItem