import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { ISignInForm, SIGN_IN_FORM_INIT_VALUE, SIGN_IN_FORM_VALIDATION_SCHEMA } from './components/Form'
import FormRenderer from './components/FormRender'
import { useNotification } from 'context/NotificationContext'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAction, userSignInAction } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { useHistory } from 'react-router-dom'
import { UserResponseModel } from 'models/common/user/user.model'
import { userErrorSelector } from 'store/common/user/selectors'

const SignIn = () => {
  const { show } = useNotification()
  const history = useHistory()
  const dispatch = useDispatch()
  const userErrors = useSelector(userErrorSelector) as Array<{message: string}>
  const notifications = useNotification()

  const [isRequested, setIsRequested] = useState<boolean>(false)

  const onSubmit = async (values: ISignInForm) => {
    setIsRequested(true)
    try {
      await userSignInAction(values)
      const user = await getUserAction()(dispatch) as UserResponseModel
      if(user.data?.status === 'lazy') {
        history.push('/user/profile')
      } else {
        history.push('/contracts')
      }
    } catch (error) {
      setIsRequested(false)
      error.data.errors.length && error.data.errors[0].message && show(error.data.errors[0].message, NotificationTypeList.Error)
      // Redirect to verify page
      if (error.status === 403) {
        history.push(`/auth/verify/${values.email}`)
      }
    }
  }

  useEffect(() => {
    if (userErrors && userErrors.length > 0) {
      notifications.show(userErrors[0].message, NotificationTypeList.Error)
    }
  }, [userErrors])

  return (
    <Formik
      initialErrors={SIGN_IN_FORM_INIT_VALUE}
      initialValues={SIGN_IN_FORM_INIT_VALUE}
      validationSchema={SIGN_IN_FORM_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formProps: FormikProps<ISignInForm>) => (
        <FormRenderer {...formProps} isRequested={isRequested}/>
      )}
    </Formik>
  )
}

export default SignIn
