import { IBaseResponseModel } from 'models/base/base.interfaces'
import { FilterOption } from '../search/search.model'

export interface IBaseAgencyModel {
  id: number | undefined
  departmentHP: string
  subtierHP: string
  departmentSAM: string
  subtierSAM: string
}

export interface IGroupedAgencyModel {
  departmentHP: string
  sub: any[]
}

export interface IAgencyResponseModel extends IBaseResponseModel {
  data: IGroupedAgencyModel[]
}

export enum CustomersMapperFieldType {
  subtierSAM = 'subtierSAM',
  departmentSAM = 'departmentSAM',
}

export interface ICustomMapperCustomers {
  [key: string]: FilterOption
}