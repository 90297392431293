import React, { useRef, useState } from 'react'
import styles from './index.module.scss'
import { Typography, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { DateType } from '../../index'
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside'

interface TimelineSelectProps {
    dateType: DateType
    setDateType: (value: DateType) => void
}

const TimelineSelect = ({dateType, setDateType}: TimelineSelectProps) => {
  const theme = useTheme()
  const [isOpenSelect, setOpenSelect] = useState<boolean>(false)
  const selectRef = useRef(null)

  useOnClickOutside(selectRef, () => setOpenSelect(false))
    
  const handleSelect = (value: DateType) => {
    setDateType(value)
    setOpenSelect(false)
  }

  return (
    <div className={styles['timeline-header-controls-select-wrapper']} ref={selectRef}>
      <Typography className={styles['timeline-header-controls-select-title']}>Show by</Typography>
      <div className={styles['timeline-header-controls-select']}>
        <div className={styles['timeline-header-controls-select-head']} onClick={() => setOpenSelect(prev => !prev)}>
          <Typography className={styles['timeline-header-controls-select-text']}>{dateType}</Typography>
          <SvgIcon name='angle-down' />
        </div>
        {
          isOpenSelect ? (
            <div className={styles['timeline-header-controls-select-menu']}>
              <div className={styles['timeline-header-controls-select-menu-item']} onClick={() => handleSelect(DateType.week)}>
                <Typography className={styles['timeline-header-controls-select-text']}>{DateType.week}</Typography>
                {DateType.week === dateType ? <SvgIcon name='check' color={theme.palette.success.dark} /> : null}
              </div>
              <div className={styles['timeline-header-controls-select-menu-item']} onClick={() => handleSelect(DateType.month)}>
                <Typography className={styles['timeline-header-controls-select-text']}>{DateType.month}</Typography>
                {DateType.month === dateType ? <SvgIcon name='check' color={theme.palette.success.dark} /> : null}
              </div>
              <div className={styles['timeline-header-controls-select-menu-item']} onClick={() => handleSelect(DateType.quarter)}>
                <Typography className={styles['timeline-header-controls-select-text']}>{DateType.quarter}</Typography>
                {DateType.quarter === dateType ? <SvgIcon name='check' color={theme.palette.success.dark} /> : null}
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default TimelineSelect