import React from 'react'
import styles from '../Sap/Filter/index.module.scss'
import { useSelector } from 'react-redux'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'
import { setAsideOptions as setAsideOptionsAction } from 'store/hp/search/selectors'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean) => void
  selectedOptions: number[]
}

const SetAsideFilter = ({ handleChange, handlePartialChange, selectedOptions }: Props) => {
  const setAsideOptions: IFilterOption[] = useSelector(setAsideOptionsAction)

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={setAsideOptions.length}
        iconType='setAside'
        filterTitle='Set Asides'
        filterOptions={setAsideOptions}
        selectedFilterOptions={selectedOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default React.memo(SetAsideFilter, (prev, next) => prev.selectedOptions === next.selectedOptions)