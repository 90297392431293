export type TRole = 'client' | 'qualifier' | 'manager' | 'owner' | 'advisor'

export const ROLES: TRole[] = ['client', 'qualifier', 'manager', 'owner', 'advisor']

export const mapper: {[key in TRole]: string} = {
  client: 'Client',
  advisor: 'Advisor',
  qualifier: 'Manager',
  manager: 'Leader',
  owner: 'Owner',
}

export enum InvitationType {
  invitation = 'invitation',
  request = 'request'
}

export enum SuggestionStatus {
  go = 'go',
  noGo = 'no-go'
}