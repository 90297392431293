import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import {
  CreateUserGroupInvitationResponseModel,
  GroupModelResponseModel,
  UserGroupPendingResponseModel,
  UserGroupResponseModel,
  UserListResponseModel
} from 'models/common/user/user.model'
import { BaseResponseModel } from 'models/base/base.model'

export const USER_GROUP_GET_USER_LIST = 'USER_GROUP_GET_USER_LIST'
export const USER_GROUP_GET_USER_LIST_SUCCESS = 'USER_GROUP_GET_USER_LIST_SUCCESS'
export const USER_GROUP_GET_USER_LIST_ERROR = 'USER_GROUP_GET_USER_LIST_ERROR'

export const GET_USER_GROUP_INVITATION = 'GET_USER_GROUP_INVITATION'
export const GET_USER_GROUP_INVITATION_SUCCESS = 'GET_USER_GROUP_INVITATION_SUCCESS'
export const GET_USER_GROUP_INVITATION_ERROR = 'GET_USER_GROUP_INVITATION_ERROR'

export const GET_USER_PENDING_INVITATION = 'GET_USER_PENDING_INVITATION'
export const GET_USER_PENDING_INVITATION_SUCCESS = 'GET_USER_PENDING_INVITATION_SUCCESS'
export const GET_USER_PENDING_INVITATION_ERROR = 'GET_USER_PENDING_INVITATION_ERROR'

export const GET_LIST_OF_GROUPS = 'GET_LIST_OF_GROUPS'
export const GET_LIST_OF_GROUPS_SUCCESS = 'GET_LIST_OF_GROUPS_SUCCESS'
export const GET_LIST_OF_GROUPS_ERROR = 'GET_LIST_OF_GROUPS_ERROR'

export const getUserList = (dispatch: Dispatch) => async (page: number, limit?: number): Promise<void> => {
  try {
    dispatch({ type: USER_GROUP_GET_USER_LIST })

    const res = await request<UserListResponseModel>(RequestMethods.Get, Paths.UserGroup.list(page, limit), {
      schema: UserListResponseModel
    })
    dispatch({
      type: USER_GROUP_GET_USER_LIST_SUCCESS,
      payload: res
    })
  }
  catch (e) {
    dispatch({
      type: USER_GROUP_GET_USER_LIST_ERROR
    })
    throw e
  }
}

export const updateUserRole = async (userId: string, userGroupId: string, role: string): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Put, Paths.UserGroup.updateRole(userId), {
    schema: BaseResponseModel,
    body: { role }
  })
}

export const createUserGroupInvitation = async(email: string): Promise<CreateUserGroupInvitationResponseModel> => {
  const data = await request<CreateUserGroupInvitationResponseModel>(RequestMethods.Post, Paths.UserGroup.invitation(), {
    schema: CreateUserGroupInvitationResponseModel,
    body: { email }
  })
  return data.data
}

export const createUserGroupIvitationToJoin = async(email: string) => {
  await request<CreateUserGroupInvitationResponseModel>(RequestMethods.Put, Paths.UserGroup.invitation(), {
    schema: CreateUserGroupInvitationResponseModel,
    body: { email }
  })
}

export const getUserGroupIvitations = (dispatch: Dispatch) => async() => {
  try {
    dispatch({
      type: GET_USER_GROUP_INVITATION,
    })
    const res = await request<UserGroupResponseModel>(RequestMethods.Get, Paths.UserGroup.invitation(), {
      schema: UserGroupResponseModel
    })
    dispatch({
      type: GET_USER_GROUP_INVITATION_SUCCESS,
      payload: res
    })
  } catch(e) {
    dispatch({
      type: GET_USER_GROUP_INVITATION_ERROR
    })
  }
}

export const getUserPendingIvitations = (dispatch: Dispatch) => async() => {
  try {
    dispatch({
      type: GET_USER_PENDING_INVITATION
    })
    const res = await request<UserGroupPendingResponseModel>(RequestMethods.Get, Paths.UserGroup.invitationPending(), {
      schema: UserGroupPendingResponseModel
    })
    dispatch({
      type: GET_USER_PENDING_INVITATION_SUCCESS,
      payload: res
    })
  } catch(e) {
    dispatch({
      type: GET_USER_PENDING_INVITATION_ERROR
    })
    throw e
  }
}

export const acceptUserInvitationRequestInGroup = async(id: string) => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.UserGroup.acceptInvitation(id), {
    schema: BaseResponseModel,
  })
}

export const acceptUserInvitationInGroup = async(id: string) => {
  await request<BaseResponseModel>(RequestMethods.Put, Paths.UserGroup.acceptInvitation(id), {
    schema: BaseResponseModel,
  })
}

export const deleteUserInvitation = async(id: string) => {
  await request<BaseResponseModel>(RequestMethods.Delete, Paths.UserGroup.deleteInvitation(id), {
    schema: BaseResponseModel,
  })
}

export const deleteUserSelfInvitation = async(id: string) => {
  await request<BaseResponseModel>(RequestMethods.Delete, Paths.UserGroup.deleteSelfInvitation(id), {
    schema: BaseResponseModel,
  })
}

export const getListOfGroupsUserBelongsTo = (dispatch: Dispatch) => async() => {
  try {
    dispatch({
      type: GET_LIST_OF_GROUPS
    })
    const res = await request<GroupModelResponseModel>(RequestMethods.Get, Paths.UserGroup.getUserGroups(), {
      schema: GroupModelResponseModel
    })
    dispatch({
      type: GET_LIST_OF_GROUPS_SUCCESS,
      payload: res
    })
  } catch(e) {
    dispatch({
      type: GET_LIST_OF_GROUPS_ERROR
    })
    throw e
  }
}

export const setActiveGroup = async(id: string, mode: boolean = false) => {
  await request<BaseResponseModel>(RequestMethods.Put, Paths.UserGroup.setActiveUserGroup(id, mode), {
    schema: BaseResponseModel,
  })
}

export const deleteUserFromGroup = async(id: string) => {
  await request<BaseResponseModel>(RequestMethods.Delete, Paths.UserGroup.deleteUserFromGroup(id), {
    schema: BaseResponseModel,
  })
}

