import { HeaderType, Note, NotesMapper, PrepareNotes, TabType, TitlesType } from 'models/hp/rfp/calc/calc.interface'
import { DraggableLocation } from 'react-beautiful-dnd'
import { Dispatch } from 'redux'
import { updateResponseCompliance, updateResponseOutline } from 'store/hp/rfp/action'

// Sorting the list by chain of previous IDs 
export const mapSort = (linkedList: Note[]) => {
  const sortedList = []
  const map = new Map()
  let currentId = null
  
  // index the linked list by previous_item_id
  for (let i = 0; i < linkedList.length; i++) {
    const item = linkedList[i]
    if (!item.prevId) {
      // first item
      currentId = item.id
      sortedList.push(item)
    } else {
      map.set(item.prevId, i)
    }
  }

  while (sortedList.length < linkedList.length) {
    // get the item with a previous item ID referencing the current item
    const nextItem: Note = linkedList[map.get(currentId)]
    sortedList.push(nextItem)
    currentId = nextItem.id
  }

  return sortedList
}

export const getSourceAndDestionationData = (prepareNotes: PrepareNotes[], source: DraggableLocation, destination?: DraggableLocation) => {
  const sourceNotes = prepareNotes.find(el => el.id === source.droppableId)?.context
  const destinationNotes = prepareNotes.find(el => el.id === destination?.droppableId)?.context
  const sourceIndexNote = sourceNotes ? sourceNotes.find((_, index) => index === source.index) : null
  const destinationIndexNote = destinationNotes ? destinationNotes.find((_, index) => index === destination?.index) : null
  return {
    sourceNotes,
    destinationNotes,
    sourceIndexNote,
    destinationIndexNote
  }
}

// Removing a note from the original index
export const removeNoteFromSourceIndex = (notes: Note[], sourceIndexNote: Note) => {
  const {prevId, nextId} = sourceIndexNote
  const prev = notes.find(el => el.id === prevId && el.content === 'context')
  const next = notes.find(el => el.id === nextId && el.content === 'context')

  return {
    prev,
    next,
    updatedNotes: [
      prev ? {...prev, nextId: next ? next.id : undefined} : null,
      next ? {...next, prevId: prev ? prev.id : undefined} : null,
    ].filter(el => Boolean(el)) as Note[]
  }
}

// Get a note depending on the direction of the array change
export const prevOrNextNoteInDirection = (destination: DraggableLocation, notes: Note[], direction: boolean) => {
  return notes?.find((el, index) => {
    const res = direction ? destination.index - 1 : destination.index + 1
    return el.content === 'context' && index === res
  })
}

export const notesForChangesWithinOneParentId = (indexDifference: number, step: number, direction: boolean, destinationIndexNote: Note, sourceIndexNote: Note, currentDirectionNote?: Note, sourceNoteInDirection?: Note) => {
  const variantDestinationNote = indexDifference < step ? {
    ...destinationIndexNote,
    prevId: direction ? sourceIndexNote.id : sourceIndexNote.prevId,
    nextId: direction ? sourceIndexNote.nextId : sourceIndexNote.id
  } : {
    ...destinationIndexNote,
    prevId: direction ? sourceIndexNote.id : destinationIndexNote.prevId,
    nextId: direction ? destinationIndexNote.nextId : sourceIndexNote.id
  }

  return [
    sourceNoteInDirection && indexDifference < step ? {
      ...sourceNoteInDirection,
      prevId: direction ? destinationIndexNote.id : sourceNoteInDirection?.prevId,
      nextId: direction ? sourceNoteInDirection?.nextId : destinationIndexNote.id
    } : null,
    currentDirectionNote ? {
      ...currentDirectionNote,
      prevId: direction ? currentDirectionNote.prevId : sourceIndexNote.id,
      nextId: direction ? sourceIndexNote.id : currentDirectionNote.nextId
    } : null,
    variantDestinationNote,
    {...sourceIndexNote,
      prevId: direction ? currentDirectionNote?.id : destinationIndexNote.id,
      nextId: direction ? destinationIndexNote.id : currentDirectionNote?.id
    },
  ]
}

export const notesForChangesBetweenParentIds = (sourceIndexNote: Note, destinationIndexNote?: Note | null, currentDirectionNote?: Note, destination?: DraggableLocation) => {
  const variantSourceIndexNote = !destinationIndexNote && currentDirectionNote?.id ? {
    ...sourceIndexNote,
    prevId: currentDirectionNote.id,
    nextId: undefined
  } : destination?.index === 0 && destinationIndexNote ? {
    ...sourceIndexNote,
    nextId: destinationIndexNote.id,
    prevId: undefined,
  } : {...sourceIndexNote,
    prevId: currentDirectionNote?.id,
    nextId: destinationIndexNote ?  destinationIndexNote.id : null,
  }

  return [
    !destinationIndexNote && currentDirectionNote ? {
      ...currentDirectionNote,
      nextId: sourceIndexNote.id
    } : null,
    destinationIndexNote && currentDirectionNote && destination?.index !== 0 ? {
      ...currentDirectionNote,
      prevId: currentDirectionNote.prevId,
      nextId: sourceIndexNote.id
    } : null,
    destinationIndexNote ? {
      ...destinationIndexNote,
      prevId: sourceIndexNote.id,
      nextId: destinationIndexNote.nextId
    } : null,
    variantSourceIndexNote ? {
      ...variantSourceIndexNote,
      parentId: destination!.droppableId
    } : null,
  ]
}

export const filterNotes = (notes: Note[]) => {
  return notes.filter(el => Boolean(el))
}

export const findNoteByOrder = (notes: Note[] | PrepareNotes[], index?: number): Note | PrepareNotes | undefined => {
  return notes.find((_, idx) => idx === index)
}

export const findNoteById = (notes: Note[] | PrepareNotes[], id?: string): Note | PrepareNotes | undefined => {
  return notes.find((note) => note.id === id)
}

// Function for single headers dnd
export const getSourceAndDestionationDataOnlyHeaders = (prepareNotes: Note[], source: DraggableLocation, destination?: DraggableLocation) => {
  const sourceNote = findNoteByOrder(prepareNotes, source.index)
  const destinationNote = findNoteByOrder(prepareNotes, destination?.index)

  return {
    sourceNote,
    destinationNote
  }
}

// Removing a note from the original index
export const removeNoteFromSourceIndexOnlyHeaders = (notes: Note[], sourceIndexNote: Note) => {
  const {prevId, nextId} = sourceIndexNote
  const prev = notes.find(el => el.id === prevId)
  const next = notes.find(el => el.id === nextId)

  return {
    prev,
    next,
    updatedNotes: [
      prev ? {...prev, nextId: next ? next.id : undefined} : null,
      next ? {...next, prevId: prev ? prev.id : undefined} : null,
    ].filter(el => Boolean(el)) as Note[]
  }
}

// Get a note depending on the direction of the array change
export const prevOrNextNoteInDirectionOnlyHeaders = (destination: DraggableLocation, notes: Note[], direction: boolean) => {
  const res = direction ? destination.index - 1 : destination.index + 1
  return notes?.find((_, index) => {
    return index === res
  })
}

export const changeTitles = (
  destination: DraggableLocation,
  source: DraggableLocation,
  prepareNotes: Note[],
  notes: Note[],
  type: TabType,
  dispatch: Dispatch<any>,
  rfpId: string
) => {
  if (destination.index === source.index) { // no updates, element was dropped on the same place
    return
  }

  const { sourceNote, destinationNote } = getSourceAndDestionationDataOnlyHeaders(prepareNotes, source, destination)
  if (!sourceNote || !destinationNote) return

  const { 
    updatedNotes: updatedSourceNotes,
    prev: sourcePrevNote,
    next: sourceNextNote
  } = removeNoteFromSourceIndexOnlyHeaders(notes, sourceNote)
  const direction = source.index > destination.index ? true : false
  const indexDifference = Math.abs(source.index - destination.index)
  const step = 2
  const currentDirectionNote = prevOrNextNoteInDirectionOnlyHeaders(destination, prepareNotes, direction) 
  const sourceNoteInDirection = direction ? sourceNextNote : sourcePrevNote
  const updatedDestinationNotes = notesForChangesWithinOneParentId(indexDifference, step, direction, destinationNote, sourceNote, currentDirectionNote, sourceNoteInDirection)
  const res = indexDifference >= step ? [...updatedSourceNotes, ...updatedDestinationNotes] : updatedDestinationNotes
  const filterRes = filterNotes(res as Note[])
  
  const updateNotesByType = {
    'outline': () => updateResponseOutline(dispatch)(rfpId, filterRes),
    'compliance': () => updateResponseCompliance(dispatch)(rfpId, filterRes)
  }

  return updateNotesByType[type]()
}

export const addContextNoteToTitles = (mapper: NotesMapper, prepareNotes: PrepareNotes[], sourceId: string, destination: DraggableLocation): Note[] => {
  const destinationIndex = destination.index

  // first remove source element from existing list by changing prev and next pointers
  const sourceNote = {...mapper[sourceId]}
  const sourceNotePrev = {...mapper[sourceNote.prevId as string]}
  const sourceNoteNext = {...mapper[sourceNote.nextId as string]}

  sourceNotePrev && (sourceNotePrev.nextId = sourceNoteNext?.id || null)
  sourceNoteNext && (sourceNoteNext.prevId = sourceNotePrev?.id || null)

  // put source element in destination list after element by default
  let sourcePlacedAfterLastNote = false
  let destinationNote = {...findNoteByOrder(prepareNotes, destinationIndex)} as PrepareNotes
  if (!destinationNote || !destinationNote.id) {
    sourcePlacedAfterLastNote = true
    destinationNote = {...prepareNotes[prepareNotes.length - 1]}
  }
  const destinationNotePrev = {...mapper[destinationNote.prevId as string]}
  const destinationNoteNext = {...mapper[destinationNote.nextId as string]}

  sourceNote.nextId = sourceNote.prevId = null
  if (!sourcePlacedAfterLastNote) {
    sourceNote.nextId = destinationNote.id
    destinationNote.prevId = sourceNote.id
    destinationNotePrev && (destinationNotePrev.nextId = sourceNote.id)
    destinationNotePrev && (sourceNote.prevId = destinationNotePrev.id)
  } else {
    destinationNote.nextId = sourceNote.id
    sourceNote.prevId = destinationNote.id
  }

  return [
    sourceNotePrev,
    sourceNoteNext,
    { // converting context element to title element
      ...sourceNote,
      content: 'title' as HeaderType,
      parentId: sourceNote.id,
      titleType: 'H1' as TitlesType,
    },
    destinationNote,
    destinationNoteNext,
    destinationNotePrev
  ].filter(x => !!x.id)
}

export const addTitleNoteToContext = (mapper: NotesMapper, prepareNotes: PrepareNotes[], source: DraggableLocation, destination: DraggableLocation): Note[] => {
  const sourceId = source.droppableId.replace('__title', '')

  if (sourceId === destination.droppableId) {
    return [] // No changes, title element was dropped on his context section
  }

  // remove source element from list
  const sourceNote = {...mapper[sourceId]}
  const sourceNotePrev = {...mapper[sourceNote.prevId as string]}
  const sourceNoteNext = {...mapper[sourceNote.nextId as string]}
  const sourceParent = findNoteById(prepareNotes, sourceId) as PrepareNotes

  sourceNotePrev && (sourceNotePrev.nextId = sourceNoteNext?.id || null)
  sourceNoteNext && (sourceNoteNext.prevId = sourceNotePrev?.id || null)
  
  const destinationParent = findNoteById(prepareNotes, destination.droppableId) as PrepareNotes

  // find destination element by provided index OR take the last element
  let sourcePlacedAfterLastNote = false
  let destinationNote = {...findNoteByOrder(destinationParent.context, destination.index)} as Note
  if (!destinationNote || !destinationNote.id) { // element was dropped after last element in title context
    sourcePlacedAfterLastNote = true
    destinationNote = {...destinationParent.context[destinationParent.context.length - 1]}
  }
  const destinatinationNotePrev = {...mapper[destinationNote.prevId as string]}
  const destinatinationNoteNext = {...mapper[destinationNote.nextId as string]}
  
  if (!sourceParent || !destinationParent) {
    return [] // Nothing to do, titles not found
  }

  let updatedSourceContext: Note[] = []
  // handle if destination doesn't have context items
  if (destinationParent.context.length === 0) {
    sourceNote.prevId = sourceNote.nextId = null
    if (sourceParent.context.length !== 0) { // if source note has context items (put them right after title)
      sourceNote.nextId = sourceParent.context[0].id // title should point on first element of his context
      updatedSourceContext = sourceParent.context.map((note, idx) => {
        return {
          ...note,
          prevId: idx === 0 ? sourceNote.id : note.prevId, // first note of context should point on title
          nextId: idx === sourceParent.context.length - 1 ? null : note.nextId,
          parentId: destinationParent.id,
        }
      })
    }
  // handle if destination have context elements
  } else {
    sourceNote.prevId = sourceNote.nextId = null
    if (!sourcePlacedAfterLastNote) { // source drops before destination element by default
      sourceNote.prevId = destinatinationNotePrev ? destinatinationNotePrev.id : null
      destinatinationNotePrev && (destinatinationNotePrev.nextId = sourceNote.id)
      destinationNote.prevId = sourceNote.id
      sourceNote.nextId = destinationNote.id

      if (sourceParent.context.length !== 0) { // if source element has context items
        sourceNote.nextId = sourceParent.context[0].id
        destinationNote.prevId = sourceParent.context[sourceParent.context.length - 1].id
        updatedSourceContext = sourceParent.context.map((note, idx) => {
          return {
            ...note,
            prevId: idx === 0 ? sourceNote.id : note.prevId,
            nextId: idx === sourceParent.context.length - 1 ? destinationNote.id : note.nextId,
            parentId: destinationParent.id,
          }
        })
      }
    } else { // source drops after last element
      sourceNote.prevId = destinationNote.id
      destinationNote.nextId = sourceNote.id
      
      if (sourceParent.context.length !== 0) { // if source element has context items
        sourceNote.nextId = sourceParent.context[0].id
        updatedSourceContext = sourceParent.context.map((note, idx) => {
          return {
            ...note,
            prevId: idx === 0 ? sourceNote.id : note.prevId,
            nextId: idx === sourceParent.context.length - 1 ? null : note.nextId,
            parentId: destinationParent.id,
          }
        })
      }
    }
  }
 
  return [
    { // converting title element to context element
      ...sourceNote,
      titleType: null,
      parentId: destinationParent.id,
      content: 'context' as HeaderType
    },
    sourceNotePrev,
    sourceNoteNext,
    ...updatedSourceContext,

    destinationNote,
    destinatinationNotePrev,
    destinatinationNoteNext
  ].filter(x => !!x.id)
}