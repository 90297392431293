import React from 'react'
import { useSelector } from 'react-redux'
import { ROLES, TRole  } from 'constants/user-group'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'

interface Props {
  from: TRole
  children?: React.ReactNode | React.ReactNodeArray
}

export const groupCan = (user: IUserBaseModel, from: TRole): boolean => {
  const userRole = user.userGroup?.role || user.role
  const index = ROLES.findIndex(role => role === from)
  const userIndex = ROLES.findIndex(role => role === userRole)
  return userIndex >= index
}

const GroupCan = ({ from, children }: Props) => {
  const user = useSelector(userSelector) as IUserBaseModel
  if (!user || !groupCan(user, from)) {
    return null
  }
  return (
    <>
      {children}
    </>
  )
}

export default GroupCan