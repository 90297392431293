import React, { useState } from 'react'
import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { MIN_PASSWORD_LEN } from 'utils/const'
import { useNotification } from 'context/NotificationContext'
import { userChangePassword } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { Formik, FormikProps } from 'formik'
import FormRenderer from './FormRenderer'

export interface IChangePasswordForm {
  password: string
  newPassword: string
}

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = Yup.object({
  password: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Current password'))
    .min(MIN_PASSWORD_LEN, ERROR_MESSAGES.Field.MinLen('Current password', MIN_PASSWORD_LEN)),
  newPassword: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('New password'))
    .min(MIN_PASSWORD_LEN, ERROR_MESSAGES.Field.MinLen('New password', MIN_PASSWORD_LEN))
})


interface Props {
  cancelUpdate: () => void
}

const Form = ({ cancelUpdate }: Props) => {
  const { show } = useNotification()

  const [ isRequested, setIsRequested ] = useState(false)

  const onSubmit = async (values: IChangePasswordForm) => {
    setIsRequested(true)
    try {
      const response = await userChangePassword(values.password, values.newPassword)
      setIsRequested(false)
      show(response?.message || 'Password successfully updated', NotificationTypeList.Success)
      cancelUpdate() // Close form
    } catch (error) {
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
      setIsRequested(false)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{ password: '', newPassword: '' }}
        validationSchema={CHANGE_PASSWORD_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formProps: FormikProps<IChangePasswordForm>) => (
          <FormRenderer
            {...formProps}
            isRequested={isRequested}
            cancelUpdate={cancelUpdate}
          />
        )}
      </Formik>
    </div>
  )
}

export default Form