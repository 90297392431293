import React, { useState } from 'react'
import styles from './index.module.scss'
import { Button, InputAdornment, TextField, useTheme } from '@material-ui/core'
import { convertStringToKeywords } from 'helpers/search'
import SvgIcon from 'components/SvgIcon'

interface Props {
  onSearch: (value: string[]) => void
}

const SearchClassInput = (props: Props) => {
  const theme = useTheme()

  const [searchValue, setSearchValue] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleOnSearch = () => {
    const searchTerm = convertStringToKeywords(searchValue) || []
    props.onSearch(searchTerm)
  }

  const handleKeyboardChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleOnSearch()
    }
  }

  return (
    <TextField
      onChange={handleChange}
      onKeyPress={handleKeyboardChange}
      value={searchValue}
      variant='outlined'
      placeholder='Search'
      color='primary'
      fullWidth
      InputProps={{
        classes: {root: styles['keyword-input']},
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon name='search' width={24} height={24}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <Button
              onClick={handleOnSearch}
              style={{backgroundColor: theme.palette.primary.contrastText}}
              variant='text'
              color='primary'
              disableRipple
            >
              Search
            </Button>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchClassInput