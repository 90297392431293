import React  from 'react'
import styles from 'modules/Main/Education/pages/main/phases/index.module.scss'
import DropdownTitle from 'components/DropdownTitle'
import { PlanningPhaseSearchList,
  PlanningPhaseClasses
} from 'utils/constants/education'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import DropdownItem from 'components/DropdownItem'
import { PhaseProps } from 'modules/Main/Education/pages/main/phases/index'

const PlanningPhase = ({opened, toggle, setActiveSubtitle, selectedSubtitle, searchTerm}: PhaseProps) => {

  const phaseClasses = () => {
    if (searchTerm.length) {
      return filteredBySearchTermPhaseClasses()
    }
    return PlanningPhaseClasses
  }

  const filteredBySearchTermPhaseClasses = () => {
    let phaseClasses: IEducationPhaseClass[] = []
    searchTerm.forEach((word: string) => {
      const unifiedWord = word.toLowerCase()
      PlanningPhaseSearchList.forEach((phase: IEducationPhaseClass) => {
        if (phase.title.toLowerCase().includes(unifiedWord)) {
          phaseClasses.push(phase)
        }
      })
    })
    return phaseClasses
  }


  return (
    <div className={[styles['education-block'], opened ? styles['opened'] : ''].join(' ')}>
      <DropdownTitle
        iconType={'planning'}
        title={'Planning Phase'}
        show={opened}
        toggle={toggle}
      />
      {opened && (
        <div>
          {phaseClasses().map((phase: IEducationPhaseClass) => {
            return (
              <DropdownItem
                key={phase.name}
                currentSubtitle={selectedSubtitle}
                subtitle={phase}
                onSelectSubtitle={setActiveSubtitle}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default PlanningPhase