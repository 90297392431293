import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Modal from 'components/v2/Modal'
import TextField from 'components/v2/TextField'
import { Typography, Button } from '@material-ui/core'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Field is required')
})

interface Props {
  opened: boolean
  onClose: () => void
  onConfirm: (name: string) => void
  searchName?: string
  processing?: boolean
  isUpdate?: boolean
}

const SearchManagementModal = ({opened, searchName, onClose, onConfirm, processing, isUpdate}: Props) => {
  return (
    <Formik
      initialValues={{
        name: searchName || ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => { onConfirm(values.name) }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Modal
          onClose={onClose}
          open={opened}
        >
          <Typography variant='h4'>{`${isUpdate ? 'Rename' : 'Name'} this search`}</Typography>
          <TextField
            label='Search name'
            variant='filled'
            value={values.name}
            onChange={handleChange('name')}
            onBlur={handleBlur('name')}
            fullWidth
            error={!!(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <div style={{paddingTop: '12px'}}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleSubmit()}
              disabled={processing}
            >Confirm</Button>
          </div>
        </Modal>
      )}
    </Formik>

  )
}

export default SearchManagementModal