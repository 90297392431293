import { IBaseRegionModel, ILocationResponseModel } from './location.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { FilterOption } from 'models/hp/search/search.model'

@jsonObject
export class BaseRegionModel implements IBaseRegionModel {

  @jsonMember({ constructor: Number })
  id?: number = undefined

  public get text(): string {
    return this.region
  }

  @jsonMember({ constructor: String })
  region: string = ''

  @jsonArrayMember(FilterOption)
  sub: FilterOption[] = []

}

@jsonObject
export class MarketLocationSubModel {
  @jsonMember({ constructor: Number })
  id: number = 0

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: String })
  code: string = ''

  @jsonMember({constructor: Number})
  marketSize: number = 0
}

@jsonObject
export class MarketLocationModel {
  @jsonMember({ constructor: String })
  region: string = ''

  @jsonArrayMember(MarketLocationSubModel)
  sub: MarketLocationSubModel[] = []
}

@jsonObject
export class LocationResponseModel extends BaseResponseModel implements ILocationResponseModel {
  @jsonArrayMember(BaseRegionModel)
  data: BaseRegionModel[] = []
}

@jsonObject
export class MarketLocationsResponseModel extends BaseResponseModel {
  @jsonArrayMember(MarketLocationModel)
  data: MarketLocationModel[] = []
}