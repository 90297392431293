import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { configureStore } from 'store'
import { MuiThemeProvider } from '@material-ui/core'
import { NotificationProvider } from './context/NotificationContext'
import themeV2 from 'utils/themeV2'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={themeV2}>
      <NotificationProvider>
        <App/>
      </NotificationProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
