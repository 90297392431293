import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Modal from 'components/v2/Modal'
import TextField from 'components/v2/TextField'
import styles from 'components/v2/LazyAccountCreationModal/index.module.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Field is required'),
  name: Yup.string().required('Field is required')
})

interface Props {
  opened: boolean
  onClose: () => void
  onConfirm: (email: string, name: string) => void
  searchName?: string
  title?: string
  processing?: boolean
}

const SearchManagementForLazyAccountUserModal = ({opened, title, searchName, onClose, onConfirm, processing}: Props) => {
  return (
    <Formik
      initialValues={{
        name: searchName || '',
        email: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onConfirm(values.name, values.email)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Modal
          title={title || 'Name this search'}
          onConfirm={handleSubmit}
          confirmLoading={processing}
          onClose={onClose}
          open={opened}
        >
          <TextField
            label='Search name'
            variant='filled'
            value={values.name}
            onChange={handleChange('name')}
            onBlur={handleBlur('name')}
            fullWidth
            error={!!(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <div style={{height: '16px'}} />
          <TextField
            onBlur={handleBlur('email')}
            error={!!touched.email && !!errors.email}
            helperText={!!touched.email && !!errors.email && errors.email}
            value={values.email}
            onChange={handleChange('email')}
            className={styles['field']}
            label='Your email address'
            fullWidth
            variant='filled'
          />
        </Modal>
      )}
    </Formik>

  )
}

export default SearchManagementForLazyAccountUserModal