import { URL } from './const'
import { Pagination } from 'models/base/base.interfaces'
import { PipelineTaskFilters } from 'models/hp/pipeline-task/pipeline-task.interface'

const baseV1 = (url: string) => `${URL}/api/v1/${url}`
const baseV2 = (url: string) => `${URL}/api/v2/${url}`

class Paths {
  static User = class {
    static signUp = baseV1('auth/register')
    static signIn = baseV1('auth/login')
    static getMe = baseV1('auth/current-user')
    static settings = baseV1('user-settings')
    static doVerify = baseV1('auth/verify')
    static resend = baseV1('auth/resend')
    static recoverPassword = baseV1('auth/forgot')
    static recoverCodeCheck = baseV1('auth/check-code')
    static recoverResetPassword = baseV1('auth/reset-password')
    static changePassword = baseV1('auth/update-password')
    static updateProfile = baseV1('auth/current-user')
    static cancelSubscription = baseV1('payment/paypal/cancel')
    static createLazy = baseV1('auth/register/lazy')

    static OAuth = class {
      static gmail = baseV1('auth/oauth/google')
      static outlook = baseV1('auth/oauth/outlook')
    }
  }

  static UserGroup = class {
    static list = (page: number, limit?: number) => {
      const query = [`page=${page}`, limit && `limit=${limit}`].filter(x => !!x).join('&')
      return baseV1(`user-group/users?${query}`)
    }
    static updateRole = (id: string) => baseV1(`user-group/user/${id}/role`)
    static invitation = () => baseV1('user-group/invitation')
    static invitationPending = () => baseV1('user-group/invitation/pending')
    static acceptInvitation = (id: string) => baseV1(`user-group/invitation/${id}/accept`)
    static deleteInvitation = (id: string) => baseV1(`user-group/invitation/${id}`)
    static deleteSelfInvitation = (id: string) => baseV1(`user-group/invitation/${id}/pending`)
    static getUserGroups = () => baseV1('user-group/groups')
    static setActiveUserGroup = (id: string, mode?: boolean) => baseV1(`user-group/groups/${id}${mode ? '?mode=quit' : ''}`)
    static deleteUserFromGroup = (id: string) => baseV1(`user-group/user/${id}`)
  }

  static Owner = class {
    static Token = class {
      static get = baseV1('owner/token')
      static create = baseV1('owner/token')
      static update = (id: string) => baseV1(`owner/token/${id}`)
      static delete = (id: string) => baseV1(`owner/token/${id}`)
    }
    static User = class {
      static get = ({ page, limit, q, sort }: Pagination) =>
        baseV1(`owner/user?page=${page}&limit=${limit}${q ? `&q=${q}` : ''}${sort?.field ? `&field=${sort.field}` : ''}${sort?.order ? `&sort=${sort.order}` : ''}`)
      static download = baseV1('owner/user/report')
      static downloadActivities = baseV1('owner/user/activity-report')
      static getById = (id: number) => baseV1(`owner/user/${id}`)
      static manageSubscription = (id: number) => baseV1(`owner/user/${id}/plan`)
      static deleteSubscription = (id: number) => baseV1(`owner/user/${id}/plan`)
      static setUserActivity = baseV1('owner/user/activity')
    }
    static Contract = class {
      static upload = baseV1('owner/rfp/upload')
    }
    static Feedback = class {
      static getList = ({page, limit}: Pagination) =>
        baseV1(`owner/feedback?page=${page}&limit=${limit}`)
      static getById = (id: string) => baseV1(`owner/feedback/${id}`)
    }
  }

  static Common = class {
    static Ads = class {
      static getItems = baseV1('ads')
      static sendItem = (id: string) => baseV1(`ads/${id}`)
    }
  }

  static Hp = class {
    static Search = class {
      static getAll = baseV1('search')
      static create = baseV1('search')
      static update = (id: string) => baseV1(`search/${id}`)
      static getById = (id: number) => baseV1(`search/${id}`)
      static getAllFulfilled = baseV1('search/all')
      static createWithKeywords = baseV1('search/with-keywords')
      static updateWithKeywords = (searchId: number) => baseV1(`search/${searchId}/with-keywords`)
      static getOptions = baseV1('search/options')

      static createFulfilled = baseV2('search')
      static updateFulfilled = (searchId: number) => baseV2(`search/${searchId}`)
      static delete = (searchId: number) => baseV1(`search/${searchId}`)
      static done = (searchId: number) => baseV1(`search/${searchId}/done`)

      static Partial = class {
        static keywords = (id: string | number) => baseV1(`search/${id}/keywords`)
        static codes = (id: string | number) => baseV1(`search/${id}/grouped-codes`)
        static locations = (id: string | number) => baseV1(`search/${id}/locations`)
        static agencies = (id: string | number) => baseV1(`search/${id}/agencies`)
      }
      static updateName = (searchId: number) => baseV1(`search/${searchId}`)
      static searches = baseV2('search')

      static Settings = class {
        static get = baseV1('search/settings')
        static manage = (id: string) => baseV1(`search/settings/${id}`)
      }
    }

    static Tinder = class {
      static getAll = baseV1('rfps/tinder')
    }

    static ContractTypes = class {
      static getContractTypes = baseV1('contract-types')
      static getSearchContractTypes = (searchId: number) => baseV1(`search/${searchId}/contract-types`)
      static createContractTypeBySearch = (searchId: number) => baseV1(`search/${searchId}/contract-types`)
    }

    static Keywords = class {
      static getKeywordsBySearchId = (searchId: number) => baseV1(`search/${searchId}/keywords`)
      static setKeywordsBySearchId = (searchId: number) => baseV1(`search/${searchId}/keywords`)
    }

    static GroupedCodes = class {
      static getAll = baseV1('grouped-codes')
      static getNaicsCodes = baseV1('grouped-codes/naics')
      static getPscCodes = baseV1('grouped-codes/psc')
      static createWithSearch = (searchId: number) => baseV1(`search/${searchId}/grouped-codes`)
      static updateSearchCodeNames = (searchId: number) => baseV1(`search/${searchId}/grouped-code-names`)
      static getCreatedBySearchId = (searchId: number) => baseV1(`search/${searchId}/grouped-psc-codes`)
    }

    static ProjectTypes = class {
      static getAll = baseV1('project-types')
      static createWithSearch = (searchId: number) => baseV1(`search/${searchId}/project-types`)
    }

    static Agencies = class {
      static getAll = baseV1('agencies')
      static getAllGrouped = baseV1('agencies/group')
      static getAllByDepartment = baseV1('agencies/department')
      static createWithSearch = (searchId: number) => baseV1(`search/${searchId}/agencies`)
    }

    static Locations = class {
      static getAll = baseV1('locations')
      static createWithSearch = (searchId: number) => baseV1(`search/${searchId}/locations`)
    }

    static SetAsides = class {
      static getAll = baseV1('set-asides')
      static create = (searchId: number) => baseV1(`search/${searchId}/set-asides`)
    }

    static Rfp = class {
      static upload = baseV1('hp/rfp/upload')
      static getFiltered = baseV1('rfps')
      static getById = (id: string) => baseV1(`rfps/${id}`)
      static getBySearchParams = (noticeId?: string, title?: string, id?: string) => {
        const params = [
          noticeId ? 'noticeId=' + noticeId : null,
          title ? 'title=' + title : null,
          id ? 'id=' + id : null
        ].filter(x => !!x).join('&')
        return baseV2('rfps/details?' + params)
      }
      static download = baseV1('hp/rfp/download')
    }

    static RfpDocuments = class {
      static getAll = (id: string) => baseV1(`hp/rfp/${id}/document-synthesis`)
      static getByType = (id: string, type: string) => baseV1(`hp/rfp/${id}/document-synthesis?type=${type}`)
      static getDownloadLink = (noticeId: string) => baseV1(`hp/rfp/${noticeId}/document/download-link`)
    }

    static RfpAnalysis = class {
      static getItems = (id: string, types: string[], skipItems: number) => baseV1(`hp/rfp/${id}/analyze/item?type=${types.join(',')}&offset=${skipItems}`)
      static setChoice = (id: string, analysisId: string) => baseV1(`hp/rfp/${id}/analyze/item/${analysisId}`)
      static deleteChoice = (id: string, choiceId: string) => baseV1(`hp/rfp/${id}/analyze/item/${choiceId}`)
      static wiredIndicators = (id: string) => baseV1(`hp/rfp/${id}/wired-indicators`)
    }

    static RfpDiscuss = class {
      static messages = (id: string) => baseV1(`hp/rfp/${id}/discussion`)
    }

    static RfpDownload = class {
      static getLink = (id: string) => baseV1(`hp/rfp/report/generate/${id}`)
    }

    static Analysis = class {
      static getPricing = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/analyze/pricing`)
      static getEvaluation = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/analyze/evaluation`)
      static tasks = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/analyze/task`)
      static setTasks = (rfpId: string, type: string) => baseV1(`hp/rfp/${rfpId}/analyze/task?type=${type}`)
      static getTasks = (rfpId: string, page: number) => baseV1(`hp/rfp/${rfpId}/analyze/task?page=${page}`)
    }

    static Categorize = class {
      static getSegmented = (options?: { type: string, offset?: number }) => options
        ? baseV1(`rfps/segmented?type=${options.type}&offset=${options.offset || 0}`)
        : baseV1('rfps/segmented')
      static setToRfp = (rfpId: string) => baseV1(`rfps/${rfpId}/categorized`)
      static download = baseV1('rfps/categorized/download')
    }

    static Upcoming = class {
      static getItems = (offset: number = 0) => baseV1(`rfps/categorized/upcoming?offset=${offset}`)
    }

    static GoNoGo = class {
      static getSuggestions = (id: string) =>  baseV1(`hp/rfp/${id}/go-no-go`)
    }

    static Calculators = class {
      static PWin = class {
        static getByRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/p-win`)
        static set = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/p-win`)
        static setAnonymous = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/p-win/anonymous`)
      }
      static CostPursue = class {
        static getByRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/pursue-cost`)
        static set = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/pursue-cost`)
        static setAnonymous = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/pursue-cost/anonymous`)
      }
      static EstValue = class {
        static getByRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/est-value`)
        static setToRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/est-value`)
        static setAnonymous = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/est-value/anonymous`)
      }
      static ProposalLength = class {
        static getByRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/proposal`)
        static setToRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/proposal`)
        static setAnonymous = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/proposal/anonymous`)
      }
      static DueTimeline = class {
        static getByRfp = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/timeline`)
        static getAnonymous = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/calc/timeline/anonymous`)
        static getTimline = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/timeline`)
      }
      static General = class {
        static getGeneral = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/general`)
        static work = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/work`)
      }
      static Qualifying = class {
        static getMembersTime = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/members-time`)
        static getRisk = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/risk-effort`)
        static setRisk = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/risk-effort`)
        static getHPPWin = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/p-win`)
        static getQualifySub = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/p-win/sub`)
        static getQualifyPrime = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/p-win/prime`)
        static getQualifyPrimeSBIR = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/p-win/prime-sbir`)
        static getTasks = (rfpId: string, type: string, limit: number, offset: number, page: number) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/tasks?type=${type}&limit=${limit}&offset=${offset}&page=${page}`)
        static getIncumbents = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/incumbents`)
        static getIncumbentPerformance = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/incumbent-performance`)
        static getSubsequentAwards = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/subsequent-awards`)
        static getIncumbentCustomerConnection = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/customer-connection`)
        static getCompetitorLandscape = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/competitor-landscape`)
        static getCompetitivePosition = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/competitive-position`)
        static getSummary = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/summary`)
        static getSelfAssessmentInput = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/self-assessment`)
        static setSelfAssessmentInputSelfScore = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/self-assessment/rate`)
        static getSubbing = (rfpId: string) => baseV2(`hp/rfp/${rfpId}/calc/qualifying/subbing`)
      }
    }

    static CompanyProfiles = class {
      static getAll = baseV1('hp/company-profile')
      static getByName = (q: string) => baseV1(`hp/company-profile?q=${q}`)
      static getCluster = baseV1('hp/company-profile/cluster')
      static getDetails = (duns: string) => baseV1(`hp/company-profile/${duns}`)
      static getSubToDetails = (duns: string) => baseV1(`hp/company-profile/${duns}/sub-to`)
      static getPrimeToDetails = (duns: string) => baseV1(`hp/company-profile/${duns}/prime-to`)
      static getCompanyGroupedCustomersOption = baseV1('hp/company-profile/search/options/grouped-customers')
      static getCompanyGovConOption = baseV1('hp/company-profile/search/options/gov-cons')
      static getCompanyGovConSizeOption = baseV1('hp/company-profile/search/options/sizes')
      static getCompanySetAsideOption = baseV1('hp/company-profile/search/options/set-asides')
      static getCompanyVehicleOption = baseV1('hp/company-profile/search/options/vehicles')
      static getCompanyVehicleDetails = (duns: string) => baseV1(`hp/company-profile/${duns}/vehicle-details`)
      static getPrimeServiceCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/prime/service-line-details/${type}`)
      static getSubServiceCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/sub/service-line-details/${type}`)
      static getPrimeEndingSoonCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/prime/ending-soon-details/${type}`)
      static getSubEndingSoonCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/sub/ending-soon-details/${type}`)
      static getPrimeCustomerCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/prime/customer-details/${type}`)
      static getSubCustomerCompanyAwards = (duns: string, type: string) => baseV1(`hp/company-profile/${duns}/sub/customer-details/${type}`)
      static setCategorized = (duns: string) => baseV1(`hp/company-profile/${duns}/categorized`)
      static setGroupCategorized = () => baseV1('hp/company-profile/categorized/list')
    }

    static InterestedVendors = class {
      static getAll = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/vendor`)
      static getByType = (rfpId: string, type: string) => baseV1(`hp/rfp/${rfpId}/vendor?type=${type}`)
      static getById = (rfpId: string, vendorId: string) => baseV1(`hp/rfp/${rfpId}/vendor/${vendorId}`)
      static create = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/vendor`)
    }

    static Incumbents = class {
      static getUserGroup = baseV1('hp/incumbents/user-groups')
      static getAll = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/incumbents`)
      static getEstimated = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/incumbents/estimated`)
      static predecessors = (rfpId: string) => baseV1(`hp/rfp/${rfpId}/incumbents/predecessors`)
    }

    static Poc = class {
      static subscribe = baseV1('poc/subscribe')
      static unsubscribe = baseV1('poc/unsubscribe')
    }

    static MarketResearch = class {
      static research = baseV1('hp/market-research')
      static items = (id?: string | number, page?: number) =>
        baseV1(`hp/market-research?${[id && `searchId=${id}`, page && `page=${page}`].filter(x => !!x).join('&')}`)
      static categorization = (rfpId: string) => baseV1(`hp/market-research/${rfpId}/categorization`)
      static setAside = baseV1('hp/market-research/set-asides')
      static customer = baseV1('hp/market-research/customers')
      static companies = baseV1('hp/market-research/companies')
      static Results = class {
        static keywords = (id: string | number) => baseV1(`hp/market-research/results/keywords?searchId=${id}`)
        static codes = (id: string | number) => baseV1(`hp/market-research/results/codes?searchId=${id}`)
        static locations = (id: string | number) => baseV1(`hp/market-research/results/locations?searchId=${id}`)
        static agencies = (id: string | number) => baseV1(`hp/market-research/results/agencies?searchId=${id}`)
        static setAsides = (id: string) => baseV1(`hp/market-research/results/set-asides?searchId=${id}`)
        static marketSize = (id: string | number) => baseV1(`hp/market-research/results/market-size?searchId=${id}`)
        static marketSizeReport = (id: string | number) => baseV1(`hp/market-research/results/report?searchId=${id}`)
      }
      static Tools = class {
        static sap = baseV1('hp/market-research/tools/sap')
        static contracts = (range?: number, from?: number) => typeof range === 'number'
          ? baseV1(`hp/market-research/tools/contracts?range=${range}&from=${from || 0}`)
          : baseV1('hp/market-research/tools/contracts')
        static companies = (page: number) => baseV1(`hp/market-research/tools/companies?page=${page}`)
        static codes = baseV1('hp/market-research/tools/codes')
        static keywords = baseV1('hp/market-research/tools/keywords')
      }
    }

    static Awards = class {
      static getList = ({ limit = 20, page = 0 }) => baseV1(`hs/rfp?limit=${limit}&page=${page}`)
      static getDetails = (id: string) => baseV1(`hs/rfp/${id}`)
      static getDetailsByQuery = (id: string) => baseV1(`hs/rfp?awardIdPiid=${id}`)
      static getCategorizedList = ({ limit = 20, page = 0 }) => baseV1(`hs/rfp/categorized?limit=${limit}&page=${page}`)
      static categorized = (id: string) => baseV1(`hs/rfp/${id}/categorized`)
      static report = baseV1('hs/rfp/categorized/report')
      static transactions = (id: string) => baseV1(`hs/rfp/${id}/transactions`)
      static getDashboard = baseV1('hs/rfp/dashboard')
      static getDashboardSimple = baseV1('hs/rfp/dashboard?simple=true')
      static getSpendingChart = (id: string) => baseV1(`hs/rfp/${id}/sections/actions-funding/spending`)
      static getTransactions = (id: string) => baseV1(`hs/rfp/${id}/sections/actions-funding/transactions`)
      static getAwardSize = (id: string) => baseV1(`hs/rfp/${id}/sections/profit/award-size`)
      static getTransition = (id: string) => baseV1(`hs/rfp/${id}/sections/profit/transition`)
      static getMarketSize = (id: string) => baseV1(`hs/rfp/${id}/sections/profit/market-size`)
      static getSummary = (contractTransactionUniqueKey: string) => baseV1(`hs/rfp/${contractTransactionUniqueKey}/sections/profit/summary`)
      static getSummaryOptions = (contractTransactionUniqueKey: string) => baseV1(`hs/rfp/${contractTransactionUniqueKey}/sections/profit/summary/options`)
      static getTransitionMarketOptions = (id: string) => baseV1(`hs/rfp/${id}/sections/transition-market/overview/options`)
      static getTransitionMarketOverview = (id: string) => baseV1(`hs/rfp/${id}/sections/transition-market/overview`)
      static getTransitionMarketOverviewByType = (id: string, type: number) => baseV1(`hs/rfp/${id}/sections/transition-market/overview?type=${type}`)
      static getTransitionMarketUSG = (id: string) => baseV1(`hs/rfp/${id}/sections/transition-market/usg-market`)
      static getGovContractsOverview = (id: string) => baseV1(`hs/rfp/${id}/sections/gov-contacts/overview`)
    }

    static Pipeline = class {
      static getPipelineBoardList = (query?: string) => baseV1(`hp/pipeline/rfp${query ? `?type=${query}` : ''}`)
      static managePipelineRfp = (id: number) => baseV1(`hp/pipeline/rfp/${id}`)
      static getPipelineRfpQuestions = (id: number, type: string) => baseV1(`hp/pipeline/rfp/${id}/questions?type=${type}`)
      static setPipelineRfpAnswer = (id: number, questionId: number) => baseV1(`hp/pipeline/rfp/${id}/answers/${questionId}`)
      static deletePipelineRfpAnswer = (id: number, answerId: string) => baseV1(`hp/pipeline/rfp/${id}/answers/${answerId}`)
      static getPipelineRfpSteps = (id: number) => baseV1(`hp/pipeline/rfp/${id}/steps`)

      static getPipelineTasksBoardList = (currentPage?: string, filters?: PipelineTaskFilters) => {
        const query = [
          currentPage && `type=${currentPage}`,
          filters?.owners?.length && `owner=${encodeURIComponent(filters.owners[0])}`,
          filters?.types?.length && `role=${encodeURIComponent(filters.types[0])}`,
          filters?.keywords?.length && `keywords=${encodeURIComponent(filters.keywords[0])}`
        ].filter(x => !!x).join('&')
        return baseV1(`hp/pipeline/task?${query}`)
      }
      static managePipelineTask = (taskId: string) => baseV1(`hp/pipeline/task/${taskId}`)
      static createPipelineTask = () => baseV1('hp/pipeline/task')
      static getTaskQuestions = (taskId: string, type?: string) => baseV1(`hp/pipeline/task/${taskId}/questions?${type ? 'type='+type : ''}`)
      static manageTaskAnswer = (taskId: string, questionId: number | string) => baseV1(`hp/pipeline/task/${taskId}/answers/${questionId}`)
      static getComments = (taskId: string) => baseV1(`hp/pipeline/task/${taskId}/comments`)
      static addComment = (taskId: string) => baseV1(`hp/pipeline/task/${taskId}/comments`)
      static deleteComment = (taskId: string, commentId: number) => baseV1(`hp/pipeline/task/${taskId}/comments/${commentId}`)
      static taskById = (taskId: string) => baseV1(`hp/pipeline/task/${taskId}`)
      static dashboard = baseV1('hp/pipeline/dashboard')
      static dashboardCount = baseV2('hp/pipeline/dashboard')
      static setDashBoardProjected = baseV1('hp/pipeline/dashboard/projected')
      static combined = baseV1('hp/pipeline/combined')
      static timeline = (page?: number, offset?: number, limit?: number, field?: string, order?: string) => baseV2(`hp/pipeline/timeline?page=${page}${offset ? `&offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}${field ? `&field=${field}` : ''}${order ? `&order=${order}` : ''}`)
      static health = baseV2(`hp/pipeline/health`)
      static revenue = baseV2(`hp/pipeline/revenue`)
      static pendingAwards = (offset: number) => baseV2(`hp/pipeline/pending-awards?offset=${offset}`)
      static pendingAwardsOutcome = (rfpId: string) => baseV2(`hp/pipeline/pending-awards/${rfpId}`)
      static piplineUserAsignment = (rfpId: string) => baseV2(`hp/pipeline/timeline/${rfpId}/user-assignment`)
    }

    static PipelineTaskCard = class {
      static signOff = (rfpId: string, type: string) =>  baseV2(`hp/pipeline-task-cards/${rfpId}/tasks/sign-off?type=${type}`)
      static cardsList = (rfpId: string) => baseV2(`hp/pipeline-task-cards/${rfpId}/tasks`)
      static changeStage = (rfpId: string, taskId: string) => baseV2(`hp/pipeline-task-cards/${rfpId}/tasks/${taskId}`)
    }

    static Feedback = class {
      static send = baseV1('feedback')
    }

    static EducationWorkplan = class {
      static upload = baseV1('hp/education-workplan/rm/contacts-uploader')
      static getContactsChart = baseV1('hp/education-workplan/rm/dashboard')
      static getContactsList = (agencyId: string) => baseV1(`hp/education-workplan/rm/connections/${agencyId}`)
      static getContactsById = (agencyId: number, positionId: string, offset: number) => baseV1(`hp/education-workplan/rm/contacts/${agencyId}/${positionId}?offset=${offset}`)
      static getCustomerFinderDashboard = baseV1('hp/education-workplan/cf/dashboard')
      static getCustomerFinderContacts = (agencyId: number) => baseV1(`hp/education-workplan/cf/connections/${agencyId}`)
      static getPartnersDashboard = baseV1('hp/education-workplan/pf/dashboard')
      static getPartnersDashboardById = (companyId: number, offset: number) => baseV1(`hp/education-workplan/pf/dashboard/${companyId}?offset=${offset}`)
      static getPartnersFinderContacts = (companyId: string) => baseV1(`hp/education-workplan/pf/connections/${companyId}`)
      static getPartnersFinderContactsById = (companyId: string, positionId: string, offset: number) => baseV1(`hp/education-workplan/pf/contacts/${companyId}/${positionId}?offset=${offset}`)
    }

    static Strategy = class {
      static winThemes = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/win-themes${subType ? `?subType=${subType}` : ''}`)
      static activities = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/activities${subType ? `?subType=${subType}` : ''}`)
      static pastPerformance = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/past-performance${subType ? `?subType=${subType}` : ''}`)
      static pastPerformanceRecommendations = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/past-performance-recommendations${subType ? `?subType=${subType}` : ''}`)
      static deleteById = (rfpId: string, strategyId: string) => baseV2(`hp/rfp/${rfpId}/calc/strategy/${strategyId}`)
      static evaluationCriteria = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/evaluation-criteria${subType ? `?subType=${subType}` : ''}`)
      static process = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/process${subType ? `?subType=${subType}` : ''}`)
      static openQuestions = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/open-questions${subType ? `?subType=${subType}` : ''}`)
      static processNaics = (id: string, code?: string) => baseV2(`hp/rfp/${id}/calc/strategy/process/naics${code ? `?code=${code}` : ''}`)
      static processSetAside = (id: string, code?: string) => baseV2(`hp/rfp/${id}/calc/strategy/process/set-aside${code ? `?code=${code}` : ''}`)
      static summary = (id: string, subType?: string) => baseV2(`hp/rfp/${id}/calc/strategy/summary${subType ? `?subType=${subType}` : ''}`)
    }

    static Response = class {
      static outline = (id: string) => baseV2(`hp/rfp/${id}/calc/response/outline`)
      static outlineUpdate = (id: string, outlineId: string) => baseV2(`hp/rfp/${id}/calc/response/outline/${outlineId}`)
      static compliance = (id: string, field?: string) => baseV2(`hp/rfp/${id}/calc/response/compliance${field ? `?field=${field}` : ''}`)
    }
  }

  static Fp = class {
    static Search = class {
      static getAll = baseV1('fp/search')
      static createWithKeywords = baseV1('fp/search/with-keywords')
      static updateWithKeywords = (searchId: number) => baseV1(`fp/search/${searchId}/with-keywords`)
    }

    static ProductStatus = class {
      static getAll = baseV1('fp/product-status')
      static setToSearch = (searchId: number) => baseV1(`fp/search/${searchId}/product-status`)
    }

    static ProductFit = class {
      static getAll = baseV1('fp/product-fit')
      static setToSearch = (searchId: number) => baseV1(`fp/search/${searchId}/product-fit`)
    }

    static Waiting = class {
      static getAll = baseV1('fp/waiting')
      static setToSearch = (searchId: number) => baseV1(`fp/search/${searchId}/waiting`)
    }

    static Amount = class {
      static getAll = baseV1('fp/amount')
      static setToSearch = (searchId: number) => baseV1(`fp/search/${searchId}/amount`)
    }

    static Rfp = class {
      static getFiltered = baseV1('fp/rfp')
      static getById = (id: string) => baseV1(`fp/rfp/${id}`)
    }

    static Categorize = class {
      static setToRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/categorized`)
      static deleteFromRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/categorized`)
    }

    static RfpDiscuss = class {
      static messages = (id: string) => baseV1(`fp/rfp/${id}/discussion`)
    }

    static Calculators = class {
      static PWin = class {
        static getByRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/p-win`)
        static set = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/p-win`)
        static setAnonymous = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/p-win/anonymous`)
      }
      static CostPursue = class {
        static getByRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/pursue-cost`)
        static set = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/pursue-cost`)
        static setAnonymous = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/pursue-cost/anonymous`)
      }
      static EstValue = class {
        static getByRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/est-value`)
        static setToRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/est-value`)
        static set = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/est-value`)
        static setAnonymous = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/est-value/anonymous`)
      }
      static ProposalLength = class {
        static getByRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/proposal`)
        static setToRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/proposal`)
        static setAnonymous = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/proposal/anonymous`)
      }
      static DueTimeline = class {
        static getByRfp = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/timeline`)
        static getAnonymous = (rfpId: string) => baseV1(`fp/rfp/${rfpId}/calc/timeline/anonymous`)
      }
    }
  }
}

export class ExternalPaths {
  static Rfp = class {
    static Documents = class {
      static downloadAll = (noticeId: string) => `https://sam.gov/api/prod/opps/v3/opportunities/${noticeId}/resources/download/zip`
    }
  }
}

export default Paths
