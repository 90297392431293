import React, {useState, useEffect} from 'react'
import {  CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { contactsChartSelector, contactsListtSelector } from 'store/hp/education/selector'
import { IContactList } from 'models/hp/education/education.interface'
import { getContactsList } from 'store/hp/education/action'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import RelationshipConnectionsItem from './RelationshipConectionsItem'

export enum PriorityEnum {
  key = 'Key',
  other = 'Other'
}

const RelationshipMap = () => {
  const dispatch = useDispatch()
  const {data: chartData} = useSelector(contactsChartSelector)
  const {data: contactsList, loading: contactsLoading, loaded: contactsListLoaded} = useSelector(contactsListtSelector)
  const notification = useNotification()
  const [keysData, setKeysData] = useState<IContactList[] | null>(null)
  const [otherData, setOtherData] = useState<IContactList[] | null>(null)

  const getContacts = (agencyId: string) => {
    getContactsList(dispatch)(agencyId)
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  useEffect(() => {
    if(contactsListLoaded) {
      setKeysData(getPriorityItem(PriorityEnum.key) || null)
      setOtherData(getPriorityItem(PriorityEnum.other) || null)
    }
  }, [contactsListLoaded, contactsList])

  const getPriorityItem = (type: PriorityEnum) => {
    if(contactsList && contactsList.length) {
      return contactsList.filter(el => el.priority === type)
    }
  }
  
  return (
    <div className={styles['relationship-map-wrapper']}>
      <Paper style={{padding: '12px'}}>
        <Typography variant='h4'>Relationship map:</Typography>
        <Grid container alignItems='center' spacing={2} className={styles['relationship-map-head']}>
          <Grid item>
            <Grid container alignItems='center'>
              <div className={styles['progress-key-head']} />
              <Typography variant='subtitle1'>Key jobs</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems='center'>
              <div className={styles['progress-other-head']} />
              <Typography variant='subtitle1'>Other</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container wrap='wrap' className={styles['relationship-map-list']}>
          {
            chartData?.agencies && chartData?.agencies.length && chartData.agencies.map((el, index) => (
              <Grid container alignItems='center' spacing={2} className={styles['relationship-map-item']} key={index + el.agency}>
                <Grid item md={2}>
                  <Typography variant='subtitle2' className={styles['relationship-map-agency']} onClick={() => getContacts(String(el.id))}>{el.agency}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant='subtitle2' className={styles['relationship-map-sub-agency']} onClick={() => getContacts(String(el.id))}>{el.subagency}</Typography>
                </Grid>
                <Grid item md={8}>
                  <Grid container alignItems='center' spacing={2}>     
                    <Paper style={{width: `${(100 * el.positions.key) / chartData.total}%`}} className={styles['progress-item-keys']}>
                      <Typography variant='subtitle1' className={styles['progress-item-value']}>{el.positions.key}</Typography>
                    </Paper>    
                    <Paper style={{width: `${(100 * el.positions.other) / chartData.total}%`}} className={styles['progress-item-other']}>
                      <Typography variant='subtitle1' className={styles['progress-item-value']}>{el.positions.other}</Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Paper>
      <Grid container className={styles['connection-details']} spacing={2}>
        {
          contactsLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )
        }
        {
          !contactsLoading && (
            <>
              <RelationshipConnectionsItem data={keysData} />
              <RelationshipConnectionsItem data={otherData} />
            </>
          )
        }
      </Grid>
    </div>
  )
}

export default RelationshipMap