import React from 'react'
import {  Button, Grid, Paper, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import { IContactList, ICustomerFinderAwards } from 'models/hp/education/education.interface'
import { SubAgencySpendingEnum } from './SubAgencySpending'
import { useDispatch } from 'react-redux'
import { useNotification } from 'context/NotificationContext'
import { createPipelineTask } from 'store/hp/pipeline/task/actions'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { PipelineTaskStatuses } from 'utils/const'
import { categorizeItem } from 'store/hp/awards/listing/actions'

interface RelationshipConnectionsItemProps {
    connections?: IContactList[] | null
    awards?: ICustomerFinderAwards
    type: SubAgencySpendingEnum
}

const SubAgencyConnectionsItem = ({connections, awards, type}: RelationshipConnectionsItemProps) => {
  const dispatch = useDispatch()
  const notification = useNotification()
  
  const createTask = async (firstName: string, lastName: string) => {
    try {
      await createPipelineTask(dispatch)({
        type: PipelineTaskStatuses.government__1,
        name: `${firstName} ${lastName}`,
        role: 'Agency Leadership',
        date: undefined,
        text: '',
        assignedUser: undefined,
        assignedUserId: undefined,
        step: null
      })
      notification.show('Task successfully created', NotificationTypeList.Success)
    }
    catch (e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleCategorize = (id: string) => {
    categorizeItem(dispatch)(id)
      .then(() => notification.show('Contract added to Liked list', NotificationTypeList.Success))
      .catch((e) => notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  const renderConnections = () => {
    if(connections && connections.length) {
      return (
        <Grid item md={6}>
          <Paper style={{padding: '12px'}} className={styles['connection-details-item']}>
            <Typography className={styles['connection-details-title']} variant='h4'>{type === SubAgencySpendingEnum.connections ? 'Connections' : 'Recent new-vendor friendly awards'}:</Typography>
            {connections.map(el => (
              <Grid className={styles['contacts-list-item']} key={el.id}>
                <Typography variant='subtitle1' className={styles['contacts-list-item-title']}>{el.title}</Typography>
                <Grid container className={styles['contacts-list']}>
                  {
                    el.contacts.length && el.contacts.map(contact => (
                      <Grid container spacing={2} justifyContent='space-between' alignItems='center' className={styles['contacts-item']} key={contact.id}>
                        <Grid item md={9}>
                          <Typography variant='subtitle1'>{`${contact.firstName} ${contact.lastName}`}</Typography>
                          <Typography variant='subtitle2'>{contact.position}</Typography>
                          <Typography variant='subtitle2'>{contact.company}</Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Grid container justifyContent='flex-end'>
                            <Button 
                              variant='contained'
                              onClick={() => createTask(contact.firstName, contact.lastName)}
                            >
                            Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
            ))
            }
          </Paper>
        </Grid>
      )
    }
    return null
  }

  const renderAwards = () => {
    if(awards && awards.rows.length) {
      return (
        <Grid item md={6}>
          <Paper style={{padding: '12px'}} className={styles['connection-details-item']}>
            <Typography className={styles['connection-details-title']} variant='h4'>{type === SubAgencySpendingEnum.connections ? 'Connections' : 'Recent new-vendor friendly awards'}:</Typography>
            {awards.rows.map(el => (
              <Grid className={styles['contacts-list-item']} key={el.id}>
                <Grid container justifyContent='space-between' alignItems='center' className={styles['awards-list']}>
                  <Grid container spacing={2} justifyContent='space-between' alignItems='center' className={styles['contacts-item']}>
                    <Grid item md={9}>
                      <Typography variant='subtitle2'>{el.awardDescription}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Grid container justifyContent='flex-end'>
                        <Button 
                          variant='contained'
                          onClick={() => handleCategorize(el.id)}
                        >
                            Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
            }
          </Paper>
        </Grid>
      )
    }
    return null
  }

  return (
    <>
      {renderConnections()}
      {renderAwards()}
    </>
  )
}

export default SubAgencyConnectionsItem