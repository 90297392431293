import { InvitationType } from 'constants/user-group'
import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const userGroupBaseSelector = (state: IRootState) => state.userGroup

export const userGroupListLoadingSelector = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('loading')
)

export const userGroupListLoadedSelector = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('loaded')
)

export const userGroupListSelector = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('data')
)

export const userGroupCountSelector = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('count')
)

export const userGroupInvitations = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('invitations').length ? userGroup.get('invitations').filter(el => el.type === InvitationType.invitation) :  userGroup.get('invitations')
)

export const userGroupRequest = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('invitations').length ? userGroup.get('invitations').filter(el => el.type === InvitationType.request) :  userGroup.get('invitations')
)

export const userGroupInvitationsPending = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('invitationsPending').length ? userGroup.get('invitationsPending').filter(el => el.type === InvitationType.invitation) :  userGroup.get('invitationsPending')
)

export const userGroupInvitationsRequestPending = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('invitationsPending').length ? userGroup.get('invitationsPending').filter(el => el.type === InvitationType.request) :  userGroup.get('invitationsPending')
)

export const userGroupList = createSelector(
  userGroupBaseSelector,
  userGroup => userGroup.get('groupsList')
)