import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import EducationProgressBarSvg, { IEducationProgressData } from 'modules/Main/Education/components/EducationProgressBarSvg'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { educationPhaseProgressSelector } from 'store/hp/education/selector'

const EducationProgressBar = () => {
  const educationProgressData = useSelector(educationPhaseProgressSelector) as IEducationProgressData

  return (
    <Grid container className={styles['education-progress-container']}>
      <Grid item md={6}>
        <Typography variant={'h4'}>
          Education
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Typography variant={'h4'} style={{textAlign: 'right'}}>
          Phases
        </Typography>
      </Grid>
      <Grid item md={12} className={styles['education-triangle']}>
        <EducationProgressBarSvg data={educationProgressData}/>
        <div className={styles['indicators-block']}>
          <Typography variant={'subtitle2'}>Planning</Typography>
          <Typography variant={'subtitle2'}>Auditioning</Typography>
          <Typography variant={'subtitle2'}>Lucky Winning</Typography>
          <Typography variant={'subtitle2'}>Process Winning</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default EducationProgressBar