import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  PIPELINE_RFP_BOARD_LIST,
  PIPELINE_RFP_BOARD_LIST_SUCCESS,
  PIPELINE_RFP_BOARD_LIST_ERROR,
  GET_PIPLINE_TIMELINE,
  GET_PIPLINE_TIMELINE_SUCCESS,
  GET_PIPLINE_TIMELINE_ERROR,
  GET_PIPLINE_HEALTH,
  GET_PIPLINE_HEALTH_SUCCESS,
  GET_PIPLINE_HEALTH_ERROR,
  GET_PIPLINE_REVENUE,
  GET_PIPLINE_REVENUE_SUCCESS,
  GET_PIPLINE_REVENUE_ERROR,
  GET_PIPLINE_PENDING_AWARDS,
  GET_PIPLINE_PENDING_AWARDS_SUCCESS,
  GET_PIPLINE_PENDING_AWARDS_ERROR,
  SET_PIPLINE_PENDING_AWARDS,
  SET_PIPLINE_PENDING_AWARDS_SUCCESS,
  SET_PIPLINE_PENDING_AWARDS_ERROR,
  RESET_PIPLINE_PENDING_AWARDS,
  UPDATE_PIPLINE_PENDING_AWARDS,
  PIPELINE_USER_ASSIGNMENT_SUCCESS
} from 'store/hp/pipeline/rfp/action'
import { HpRfpModel } from 'models/hp/rfp/rfp.model'
import { PiplinePendingAwardsResponseModel, PiplineTimelineModel, PiplineTimelineResponseModel } from 'models/hp/pipeline/pipeline.model'
import { IPiplineHealthItem, IPiplinePendingAward, IPiplineRevenueItem } from 'models/hp/pipeline/pipeline.interface'

export interface IPipelineBoardListRecord {
  listing: {
    loaded: boolean
    loading: boolean
    count: number
    data: HpRfpModel[]
  }
  type: string,
  timeline: {
    data: PiplineTimelineResponseModel
    loading: boolean
    loaded: boolean
  }
  health: {
    loading: boolean
    loaded: boolean
    data: IPiplineHealthItem[]
  }
  revenue: {
    loading: boolean
    loaded: boolean
    data: IPiplineRevenueItem[]
  },
  pendingAwards: {
    loading: boolean
    loaded: boolean
    data: PiplinePendingAwardsResponseModel
  }
}

const PipelineBoardListRecord = Record<IPipelineBoardListRecord>({
  listing: {
    loaded: false,
    loading: false,
    count: 0,
    data: [],
  },
  type: '',
  timeline: {
    data: new PiplineTimelineResponseModel(),
    loading: false,
    loaded: false
  },
  health: {
    loading: false,
    loaded: false,
    data: []
  },
  revenue: {
    loading: false,
    loaded: false,
    data: []
  },
  pendingAwards: {
    loading: false,
    loaded: false,
    data: new PiplinePendingAwardsResponseModel()
  }
})

export const pipelineBoardListReducer = (state: Record<IPipelineBoardListRecord> = new PipelineBoardListRecord(), action: IAction) => {
  switch (action.type) {
    case PIPELINE_RFP_BOARD_LIST:
      return state
        .setIn(['listing', 'loading'], true)
    case PIPELINE_RFP_BOARD_LIST_ERROR:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], false)
    case PIPELINE_RFP_BOARD_LIST_SUCCESS:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], true)
        .setIn(['listing', 'data'], action.payload)
        .setIn(['listing', 'count'], action.payload.length)
    case GET_PIPLINE_TIMELINE:
      return state
        .setIn(['timeline', 'loading'], true)
    case GET_PIPLINE_TIMELINE_SUCCESS:
      return state
        .setIn(['timeline', 'loading'], false)
        .setIn(['timeline', 'loaded'], true)
        .setIn(['timeline', 'data'], action.payload)
    case GET_PIPLINE_TIMELINE_ERROR:
      return state
        .setIn(['timeline', 'loading'], false)
    case GET_PIPLINE_HEALTH:
      return state
        .setIn(['health', 'loading'], true)
    case GET_PIPLINE_HEALTH_SUCCESS:
      return state
        .setIn(['health', 'loading'], false)
        .setIn(['health', 'loaded'], true)
        .setIn(['health', 'data'], action.payload)
    case GET_PIPLINE_HEALTH_ERROR:
      return state
        .setIn(['health', 'loading'], false)
    case GET_PIPLINE_REVENUE:
      return state
        .setIn(['revenue', 'loading'], true)
    case GET_PIPLINE_REVENUE_SUCCESS:
      return state
        .setIn(['revenue', 'loading'], false)
        .setIn(['revenue', 'loaded'], true)
        .setIn(['revenue', 'data'], action.payload)
    case GET_PIPLINE_REVENUE_ERROR:
      return state
        .setIn(['revenue', 'loading'], false)
    case GET_PIPLINE_PENDING_AWARDS:
      return state
        .setIn(['pendingAwards', 'loading'], true)
    case GET_PIPLINE_PENDING_AWARDS_SUCCESS:
      return state
        .setIn(['pendingAwards', 'loading'], false)
        .setIn(['pendingAwards', 'loaded'], true)
        .updateIn(['pendingAwards', 'data'], (data: any) => ({
          ...data,
          count: action.payload.count,
          data: [...data.data, ...action.payload.data]
        }))
    case GET_PIPLINE_PENDING_AWARDS_ERROR:
      return state
        .setIn(['pendingAwards', 'loading'], false)
    case SET_PIPLINE_PENDING_AWARDS:
      return state
        .setIn(['pendingAwards', 'loading'], true)
    case SET_PIPLINE_PENDING_AWARDS_SUCCESS:
      return state
        .setIn(['pendingAwards', 'loading'], false)
    case SET_PIPLINE_PENDING_AWARDS_ERROR:
      return state
        .setIn(['pendingAwards', 'loading'], false)
    case RESET_PIPLINE_PENDING_AWARDS:
      return state
        .setIn(['pendingAwards', 'loaded'], false)
        .setIn(['pendingAwards', 'loading'], false)
        .setIn(['pendingAwards', 'data'], new PiplinePendingAwardsResponseModel())
    case UPDATE_PIPLINE_PENDING_AWARDS:
      return state
        .updateIn(['pendingAwards', 'data'], (data: any) => ({
          ...data,
          data: data.data.map((el: IPiplinePendingAward) => el.id === action.payload.id ? {
            ...el,
            pipelineAnalysis: action.payload.values
          } : el)
        }))
    case PIPELINE_USER_ASSIGNMENT_SUCCESS:
      return state
        .updateIn(['timeline', 'data'], (data: any) => {
          return {
            ...data,
            data: data.data.map((el: PiplineTimelineModel) => el.id === action.payload.rfpId ? {
              ...el,
              assignedUser: action.payload.assignedUser
            } : el)
          }
        })
    default:
      return state
  }
}