import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  USER_BASE,
  USER_BASE_ERROR,
  RESET_USER,
  USER_SETTINGS,
  USER_SETTINGS_ERROR,
  USER_INCUMBENT_GROUP,
  USER_INCUMBENT_GROUP_ERROR,
  UPDATE_TIMER_FOR_LAZY_ACCOUNT,
  SET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT,
  RESET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT
} from './actions'
import {
  UserIncumbentGroupResponse, UserLazyAccountPromptConditionModel,
  UserResponseModel, UserSettingsResponseModel
} from 'models/common/user/user.model'
import {
  IUserGetResponseModel, IUserSettingsGetResponseModel
} from 'models/common/user/user.interfaces'

export interface IUserRecord {
  data: IUserGetResponseModel
  settings: IUserSettingsGetResponseModel
  incumbentGroup: UserIncumbentGroupResponse
  userLazyAccountPromptCondition: UserLazyAccountPromptConditionModel
}

const UserGetRecord = Record<IUserRecord>({
  data: new UserResponseModel(),
  settings: new UserSettingsResponseModel(),
  incumbentGroup: {data: [], message: '', errors: undefined, success: false},
  userLazyAccountPromptCondition: new UserLazyAccountPromptConditionModel()
})

export const userGetReducer = (state: Record<IUserRecord> = new UserGetRecord(), action: IAction) => {
  switch (action.type) {
    case USER_BASE:
      return state
        .update('data', (data) => ({
          ...data,
          data: action.payload.data,
          errors: undefined
        }))
    case USER_BASE_ERROR:
      return state
        .update('data', (data) => ({
          ...data,
          data: undefined,
          errors: action.error
        }))
    case USER_SETTINGS:
      return state
        .update('settings', (data) => ({
          ...data,
          data: action.payload.data,
          errors: undefined
        }))
    case USER_SETTINGS_ERROR:
      return state
        .update('settings', (data) => ({
          ...data,
          data: undefined,
          errors: action.error
        }))
    case RESET_USER:
      return state
        .update('data', (data) => ({
          ...data,
          data: undefined,
          errors: undefined
        }))
    case USER_INCUMBENT_GROUP:
      return state
        .set('incumbentGroup', {
          ...action.payload,
          data: action.payload.data,
          errors: undefined
        })
    case USER_INCUMBENT_GROUP_ERROR:
      return state
        .set('incumbentGroup', {
          ...action.payload,
          data: undefined,
          errors: action.payload
        })
    case UPDATE_TIMER_FOR_LAZY_ACCOUNT:
      return state
        .update('userLazyAccountPromptCondition', data => ({
          ...data,
          isMoreThan30SecOnPage: action.payload.data
        }))
    case SET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT:
      return state
        .update('userLazyAccountPromptCondition', data => ({
          ...data,
          viewedContracts: data.viewedContracts.concat(action.payload.data)
        }))
    case RESET_VIEWED_CONTRACT_FOR_LAZY_ACCOUNT:
      return state
        .set('userLazyAccountPromptCondition', new UserLazyAccountPromptConditionModel())
    default:
      return state
  }
}
