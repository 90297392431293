import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { searchSelector } from 'store/hp/market-research/selectors'
import { useNotification } from 'context/NotificationContext'
import { getAgenciesResults } from 'store/hp/market-research/actions'
import { addSearchAgencies as addSearchAgenciesAction } from 'store/hp/search/actions'
import { updateSearchFilter as updateSearchFilterAction } from 'store/hp/market-research/actions'
import { MarketAgency } from 'models/hp/agencies/agencies.model'
import { Grid, Typography, Checkbox, CircularProgress } from '@material-ui/core'
import ActionsBar from '../../components/ActionsBar'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import BorderLinearProgress from '../../components/BorderLinearProgress'
import styles from '../../index.module.scss'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

interface CheckedAgencies {
  [key: string]: boolean
}

interface AgenciesHierarchy {
  [key: string]: MarketAgency[]
}

const SearchAgencies = () => {
  const history = useHistory()
  const search = useSelector(searchSelector) as SearchModel
  const dispatch = useDispatch()
  const { show } = useNotification()

  const [requesting, setRequesting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [agenciesResults, setAgenciesResults] = React.useState<AgenciesHierarchy>({})
  const [checkedAgencies, setCheckedAgencies] = React.useState<CheckedAgencies>({})
  const [totalContracts, setTotalContracts] = React.useState(1)

  const handleChange = (id: number) => () => {
    setCheckedAgencies(val => ({
      ...val,
      [id]: !val[id]
    }))
  }

  const handleChangeParent = (departmentKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const state = !agenciesResults[departmentKey].every(item => checkedAgencies[item.id])
    const items = Object.values(agenciesResults[departmentKey]).reduce((acc, item) => {
      acc[item.id] = state
      return acc
    }, {} as CheckedAgencies)
    setCheckedAgencies(val => ({
      ...val,
      ...items
    }))
  }

  const addAgencies = async () => {
    try {
      setRequesting(true)

      const agencies: string[] = Object.keys(checkedAgencies).reduce((acc, item) => {
        if (checkedAgencies[item] && Number(item) > 0) {
          (acc as string[]).push(item)
        }
        return acc
      }, [])

      await addSearchAgenciesAction(search.id, agencies)
      await updateSearchFilterAction(dispatch)('customerIds', agencies)

      setRequesting(false)
    }
    catch (e) {
      setRequesting(false)
    }
  }

  const handleStop = async () => {
    try {
      await addAgencies()
      show('Changes saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleContinue = async () => {
    try {
      await addAgencies()
      history.push('/user/market-research/market-size')
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    setLoading(true)
    getAgenciesResults(search.id)
      .then(res => {
        let contracts = 0
        setAgenciesResults(res.reduce((acc, item) => {
          if (!acc[item.department]) {
            acc[item.department] = [item]
          } else {
            acc[item.department].push(item)
          }
          contracts += item.marketSize
          return acc
        }, {} as AgenciesHierarchy))
        setTotalContracts(contracts)
        setCheckedAgencies(res.reduce((acc, item) => {
          acc[item.id] = search.customerIds.length > 0
            ? search.customerIds.includes(item.id)
            : true
          return acc
        }, {} as CheckedAgencies))
      })
      .finally(() => setLoading(false))
  }, [search.id])

  if (loading) {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Typography variant='h2'>Customers</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_CUSTOMER} />
            <Typography variant='h4'>
              Your customers
            </Typography>
            <Typography>
              • As a new vendor you can only go after a couple government customers initially, so we recommend choosing:
            </Typography>
            <Typography>
              • The top consumers of your products/services
            </Typography>
            <Typography>
              • Any agencies/customers you have a connection to
            </Typography>
            <Typography gutterBottom>
              • Unselecting the rest
            </Typography>
          </Grid>
        </Grid>
        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>
            Average number of contracts/year/customer
          </Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']}>
            <Grid className={styles['form']}>
              <div>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item md={1} style={{ marginRight: '-40px' }}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Use</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Agency</Typography>
                  </Grid>
                  <Grid item md={1} style={{ marginRight: '-40px' }}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold', paddingLeft: '10px' }}>#</Typography>
                  </Grid>
                  <Grid item md={8} />
                </Grid>


                {agenciesResults && Object.entries(agenciesResults).map(([departmentKey, items], idx) => (
                  <Grid container key={idx} style={{ marginBottom: '16px' }}>
                    <Grid item md={12}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item md={1} style={{ marginRight: '-40px' }}>
                          <Checkbox
                            color='primary'
                            checked={items.every(item => checkedAgencies[item.id]) || departmentKey === 'NO DEPARTMENT'}
                            onChange={handleChangeParent(departmentKey)}
                            disabled={departmentKey === 'NO DEPARTMENT'}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Typography><b>{departmentKey}</b></Typography>
                        </Grid>
                        <Grid item md={1} style={{ marginRight: '-40px' }} />
                        <Grid item md={8} />
                      </Grid>
                    </Grid>
                    <Grid item style={{ paddingLeft: '16px' }} md={12}>
                      {items.map((item, idx) => (
                        <Grid container justifyContent='space-between' alignItems='center' key={idx}>
                          <Grid item md={1} style={{ marginRight: '-40px' }}>
                            <Checkbox
                              name='select-keywords'
                              color={'primary'}
                              value={item.id}
                              onChange={handleChange(item.id)}
                              checked={checkedAgencies[item.id] || !item.id || false}
                              disabled={!item.id}
                            />
                          </Grid>
                          <Grid item md={2}>
                            <Typography variant='body1'>{item.subTier || item.department}</Typography>
                          </Grid>
                          <Grid item md={1} style={{ marginRight: '-40px' }}>
                            <Typography>
                              <b>{item.marketSize}</b>
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            <BorderLinearProgress
                              color='primary'
                              transparent={true}
                              value={Math.round(item.marketSize / totalContracts * 100)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </div>
              <ActionsBar
                handleGoBack={() => history.goBack()}
                handleContinue={handleContinue}
                handleStop={handleStop}
                requesting={requesting}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div >
  )
}

export default SearchAgencies