import React from 'react'
import styles from 'modules/Main/Education/pages/main/phases/index.module.scss'
import DropdownTitle from 'components/DropdownTitle'
import { PhaseProps } from 'modules/Main/Education/pages/main/phases/index'

const ProcessDrivenPhase = ({opened, toggle}: PhaseProps) => {

  return (
    <div className={[styles['education-block'], styles['disabled']].join(' ')}>
      <DropdownTitle
        iconType={'processDriven'}
        title={'Process Driven Phase'}
        show={opened}
        toggle={toggle}
      />
    </div>
  )
}

export default ProcessDrivenPhase