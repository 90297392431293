import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { data, options } from './options'
import { IPiplineRevenueItem } from 'models/hp/pipeline/pipeline.interface'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface EstimatedRevenueChartProps {
  data: IPiplineRevenueItem[]
}

const EstimatedRevenueChart = ({data: chartData}: EstimatedRevenueChartProps) => {
  return <Bar options={options} data={data(chartData)} />
}

export default EstimatedRevenueChart
