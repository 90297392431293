import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { IVerifyPasswordForm, VERIFY_FORM_INIT_VALUE, VERIFY_FORM_VALIDATION_SCHEMA } from './components/Form'
import { useParams, useHistory } from 'react-router-dom'
import FormRenderer from './components/FormRenderer'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { userRecoverPasswordCodeCheckAction } from 'store/common/user/actions'

const CheckPasswordToken = () => {

  const { email } = useParams()
  const history = useHistory()

  const { show } = useNotification()

  const [ isRequested, setIsRequested ] = useState<boolean>(false)

  const onSubmit = async (values: IVerifyPasswordForm) => {
    setIsRequested(prev => !prev)
    try {
      const response = await userRecoverPasswordCodeCheckAction(email, +values.verificationCode)
      setIsRequested(prev => !prev)
      history.push({
        pathname: `/auth/recover/reset-password/${email}/${response.recovery_token}`,
        search: history.location.search
      })
    } catch (error) {
      setIsRequested(prev => !prev)
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
    }
  }

  return (
    <Formik
      initialErrors={VERIFY_FORM_INIT_VALUE}
      initialValues={VERIFY_FORM_INIT_VALUE}
      validationSchema={VERIFY_FORM_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IVerifyPasswordForm>) => (
        <FormRenderer {...formikProps} isRequested={isRequested}/>
      )}
    </Formik>
  )
}

export default CheckPasswordToken