import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface IButtonWithLoaderProps extends ButtonProps {
  isRequested: boolean
}

const useStyles = makeStyles({
  root: {
    margin: '0 auto'
  }
})

const ButtonWithLoader = (props: IButtonWithLoaderProps) => {
  const {  isRequested, children, disabled, ...buttonProps } = props
  const classes = useStyles()
  return (
    <>
      <Button
        {...buttonProps}
        disabled={disabled || isRequested}
      >
        {
          isRequested
            ? <CircularProgress size={24} className={classes.root}/>
            : children
        }

      </Button>
    </>
  )
}

export default ButtonWithLoader
