import { 
  ICompetitivePositionResponseModel,
  ICompetitorLandscapeResponseModel,
  ICustomerConnectionResponseModel,
  IIncumbentperformanceResponseModel,
  IIncumbentsResponseModel,
  IMemebersTimeModel,
  IMemebersTimeResponseModel,
  IQualify,
  IQualifyPrimeResponseModel,
  IQualifyPrimeSBIRResponseModel,
  IQualifyPrimeSBIRValues,
  IQualifyPrimeValues,
  IQualifySubResponseModel,
  IQualifySubValues,
  IRiskAndEffort,
  IRiskAndEffortData,
  IRiskAndEffortResponseModel,
  ISaveCompetitivePosition,
  ISaveCompetitorLandscape,
  ISaveCustomerConnection,
  ISaveIncumbent,
  ISaveIncumbentPerformance,
  ISaveSelfAssessmentInputSelfScore,
  ISaveSubsequentAward,
  ISaveSummary,
  IScore,
  IScoreResponseModel,
  ISelfAssessmentInputResponseModel,
  ISetSubbingResponseModel,
  ISetTaskData,
  ISetTaskDataResponseModel,
  ISubbingResponseModel,
  ISubsequentAwardsResponseModel,
  ISummaryResponseModel,
  ITaskItem,
  ITasksCalc,
  ITasksCalcResponseModel,
} from 'models/common/calculators/qualifying/qualifying.interface'
import {
  CompetitivePositionResponseModel,
  CompetitorLandscapeResponseModel,
  CustomerConnectionResponseModel,
  IncumbensResponseModel,
  IncumbentPerformanceResponseModel,
  MemebersTimeResponseModel,
  QualifyPrimeResponseModel,
  QualifyPrimeSBIRResponseModel,
  QualifySubResponseModel,
  RiskAndEffortResponseModel,
  ScoreResponseModel,
  SelfAssessmentResponseModel,
  SetSubbingResponseModel,
  SetTasksResponseModel,
  SubbingResponseModel,
  SubsequentAwardsResponseModel,
  SummaryResponseModel,
  TasksCalcResponseModel
} from 'models/common/calculators/qualifying/qualifying.model'
import { Dispatch } from 'redux'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'

export const GET_RISK_AND_EFFORT_CALC = 'GET_RISK_AND_EFFORT_CALC'
export const GET_RISK_AND_EFFORT_CALC_SUCCESS = 'GET_RISK_AND_EFFORT_CALC_SUCCESS'
export const GET_RISK_AND_EFFORT_CALC_ERROR = 'GET_RISK_AND_EFFORT_CALC_ERROR'

export const GET_QUALIFY_SUB = 'GET_QUALIFY_SUB'
export const GET_QUALIFY_SUB_SUCCESS = 'GET_QUALIFY_SUB_SUCCESS'
export const GET_QUALIFY_SUB_ERROR = 'GET_QUALIFY_SUB_ERROR'

export const GET_QUALIFY_PRIME = 'GET_QUALIFY_PRIME'
export const GET_QUALIFY_PRIME_SUCCESS = 'GET_QUALIFY_PRIME_SUCCESS'
export const GET_QUALIFY_PRIME_ERROR = 'GET_QUALIFY_PRIME_ERROR'

export const GET_QUALIFY_PRIME_SBIR = 'GET_QUALIFY_PRIME_SBIR'
export const GET_QUALIFY_PRIME_SBIR_SUCCESS = 'GET_QUALIFY_PRIME_SBIR_SUCCESS'
export const GET_QUALIFY_PRIME_SBIR_ERROR = 'GET_QUALIFY_PRIME_SBIR_ERROR'

export const SET_SCORE_QUALIFY_PRIME_SBIR = 'SET_SCORE_QUALIFY_PRIME_SBIR'
export const UPDATE_QUALIFY_PRIME_SBIR = 'UPDATE_QUALIFY_PRIME_SBIR'
export const DELETE_QUALIFY_PRIME_SBIR = 'DELETE_QUALIFY_PRIME_SBIR'

export const SET_SCORE_QUALIFY_SUB = 'SET_SCORE_QUALIFY_SUB'
export const UPDATE_QUALIFY_SUB = 'UPDATE_QUALIFY_SUB'
export const DELETE_QUALIFY_SUB = 'DELETE_QUALIFY_SUB'

export const SET_SCORE_QUALIFY_PRIME = 'SET_SCORE_QUALIFY_PRIME'
export const UPDATE_QUALIFY_PRIME = 'UPDATE_QUALIFY_PRIME'
export const DELETE_QUALIFY_PRIME = 'DELETE_QUALIFY_PRIME'

export const GET_TASKS_CALC = 'GET_TASKS_CALC'
export const GET_TASKS_CALC_SUCCESS = 'GET_TASKS_CALC_SUCCESS'
export const GET_TASKS_CALC_ERROR = 'GET_TASKS_CALC_ERROR'

export const UPDATE_TASKS_CALC = 'UPDATE_TASKS_CALC'
export const UPDATE_TASKS_CALC_SUCCESS = 'UPDATE_TASKS_CALC_SUCCESS'
export const UPDATE_TASKS_CALC_ERROR = 'UPDATE_TASKS_CALC_ERROR'

export const SET_TASKS_CALC = 'SET_TASKS_CALC'
export const SET_TASKS_CALC_SUCCESS = 'SET_TASKS_CALC_SUCCESS'
export const SET_TASKS_CALC_ERROR = 'SET_TASKS_CALC_ERROR'

export const GET_INCUMBENTS = 'GET_INCUMBENTS'
export const GET_INCUMBENTS_SUCCESS = 'GET_INCUMBENTS_SUCCESS'
export const GET_INCUMBENTS_ERROR = 'GET_INCUMBENTS_ERROR'

export const SET_INCUMBENTS = 'SET_INCUMBENTS'
export const SET_INCUMBENTS_SUCCESS = 'SET_INCUMBENTS_SUCCESS'
export const SET_INCUMBENTS_ERROR = 'SET_INCUMBENTS_ERROR'

export const GET_INCUMBENT_PERFORMANCE = 'GET_INCUMBENT_PERFORMANCE'
export const GET_INCUMBENT_PERFORMANCE_SUCCESS = 'GET_INCUMBENT_PERFORMANCE_SUCCESS'
export const GET_INCUMBENT_PERFORMANCE_ERROR = 'GET_INCUMBENT_PERFORMANCE_ERROR'

export const GET_SUBSEQUENT_AWARDS = 'GET_SUBSEQUENT_AWARDS'
export const GET_SUBSEQUENT_AWARDS_SUCCESS = 'GET_SUBSEQUENT_AWARDS_SUCCESS'
export const GET_SUBSEQUENT_AWARDS_ERROR = 'GET_SUBSEQUENT_AWARDS_ERROR'

export const GET_CUSTOMER_CONNECTION = 'GET_CUSTOMER_CONNECTION'
export const GET_CUSTOMER_CONNECTION_SUCCESS = 'GET_CUSTOMER_CONNECTION_SUCCESS'
export const GET_CUSTOMER_CONNECTION_ERROR = 'GET_SUBSEQUENT_AWARDS_SUCCESS_ERROR'

export const GET_COMPETITOR_LANDSCAPE = 'GET_COMPETITOR_LANDSCAPE'
export const GET_COMPETITOR_LANDSCAPE_SUCCESS = 'GET_COMPETITOR_LANDSCAPE_SUCCESS'
export const GET_COMPETITOR_LANDSCAPE_ERROR = 'GET_COMPETITOR_LANDSCAPE_ERROR'

export const GET_COMPETITIVE_POSITION = 'GET_COMPETITIVE_POSITION'
export const GET_COMPETITIVE_POSITION_SUCCESS = 'GET_COMPETITIVE_POSITION_SUCCESS'
export const GET_COMPETITIVE_POSITION_ERROR = 'GET_COMPETITIVE_POSITION_ERROR'

export const GET_SUMMARY = 'GET_SUMMARY'
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS'
export const GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR'

export const SET_SUMMARY = 'SET_SUMMARY'
export const SET_SUMMARY_SUCCESS = 'SET_SUMMARY_SUCCESS'
export const SET_SUMMARY_ERROR = 'SET_SUMMARY_ERROR'

export const GET_SELF_ASSESSMENT_INPUT = 'GET_SELF_ASSESSMENT_INPUT'
export const GET_SELF_ASSESSMENT_INPUT_SUCCESS = 'GET_SELF_ASSESSMENT_INPUT_SUCCESS'
export const GET_SELF_ASSESSMENT_INPUT_ERROR = 'GET_SELF_ASSESSMENT_INPUT_ERROR'

export const SET_SELF_ASSESSMENT_INPUT_SELF_SCORE = 'SET_SELF_ASSESSMENT_INPUT_SELF_SCORE'
export const SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_SUCCESS = 'SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_SUCCESS'
export const SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_ERROR = 'SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_ERROR'

export const UPDATE_SELF_ASSESSMENT_INPUT_SELF_SCORE = 'UPDATE_SELF_ASSESSMENT_INPUT_SELF_SCORE'

export const GET_SUBBING = 'GET_SUBBING'
export const GET_SUBBING_SUCCESS = 'GET_SUBBING_SUCCESS'
export const GET_SUBBING_ERROR = 'GET_SUBBING_ERROR'

export const SET_SUBBING = 'SET_SUBBING'
export const SET_SUBBING_SUCCESS = 'SET_SUBBING_SUCCESS'
export const SET_SUBBING_ERROR = 'SET_SUBBING_ERROR'

export const UPDATE_SUBBING = 'UPDATE_SUBBING'
  
export const getMembersTime = async (rfpId: string): Promise<IMemebersTimeModel[]> => {
  try {
    const response = await request<IMemebersTimeResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getMembersTime(rfpId), {
      schema: MemebersTimeResponseModel
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getRiskAndEffort = (dispatch: Dispatch) => async (rfpId: string): Promise<IRiskAndEffort> => {
  try {
    dispatch({
      type: GET_RISK_AND_EFFORT_CALC
    })
    const response = await request<IRiskAndEffortResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getRisk(rfpId), {
      schema: RiskAndEffortResponseModel
    })
    dispatch({
      type: GET_RISK_AND_EFFORT_CALC_SUCCESS,
      payload: response.data.data
    })
    return response.data.data
  } catch (error) {
    dispatch({
      type: GET_RISK_AND_EFFORT_CALC_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}


export const setRiskAndEffort = (dispatch: Dispatch) => async (rfpId: string, values: IRiskAndEffortData): Promise<IMemebersTimeModel[]> => {
  try {
    const response = await request<IMemebersTimeResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.setRisk(rfpId), {
      schema: MemebersTimeResponseModel,
      body: values
    })
    dispatch({
      type: GET_RISK_AND_EFFORT_CALC_SUCCESS,
      payload: {
        id: 'some-unnecessary-id',
        type: 'risk-effort',
        data: values
      }
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}


export const getQualifySub = (dispatch: Dispatch) => async (rfpId: string): Promise<IQualify<IQualifySubValues>> => {
  try {
    dispatch({
      type: GET_QUALIFY_SUB
    })
    const response = await request<IQualifySubResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getQualifySub(rfpId), {
      schema: QualifySubResponseModel
    })
    dispatch({
      type: GET_QUALIFY_SUB_SUCCESS,
      payload: response.data.data
    })
    return response.data.data
  } catch (error) {
    dispatch({
      type: GET_QUALIFY_SUB_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}

export const getQualifyPrime = (dispatch: Dispatch) => async (rfpId: string): Promise<IQualify<IQualifyPrimeValues>> => {
  try {
    dispatch({
      type: GET_QUALIFY_PRIME
    })
    const response = await request<IQualifyPrimeResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getQualifyPrime(rfpId), {
      schema: QualifyPrimeResponseModel
    })
    dispatch({
      type: GET_QUALIFY_PRIME_SUCCESS,
      payload: response.data.data
    })
    return response.data.data
  } catch (error) {
    dispatch({
      type: GET_QUALIFY_PRIME_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}

export const getQualifyPrimeSBIR = (dispatch: Dispatch) => async (rfpId: string): Promise<IQualify<IQualifyPrimeSBIRValues>> => {
  try {
    dispatch({
      type: GET_QUALIFY_PRIME_SBIR
    })
    const response = await request<IQualifyPrimeSBIRResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getQualifyPrimeSBIR(rfpId), {
      schema: QualifyPrimeSBIRResponseModel
    })
    dispatch({
      type: GET_QUALIFY_PRIME_SBIR_SUCCESS,
      payload: response.data.data
    })
    return response.data.data
  } catch (error) {
    dispatch({
      type: GET_QUALIFY_PRIME_SBIR_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}

export const setQualifySub = (dispatch: Dispatch) => async (rfpId: string, values: {[key: string]: boolean | string}): Promise<IScore> => {
  try {
    dispatch({
      type: UPDATE_QUALIFY_SUB,
      payload: values
    })
    const response = await request<IScoreResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getQualifySub(rfpId), {
      schema: ScoreResponseModel,
      body: values
    })
    dispatch({
      type: SET_SCORE_QUALIFY_SUB,
      payload: response.data.data.score
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const setQualifyPrime = (dispatch: Dispatch) => async (rfpId: string, values: {[key: string]: boolean | string}): Promise<IScore> => {
  try {
    dispatch({
      type: UPDATE_QUALIFY_PRIME,
      payload: values
    })
    const response = await request<IScoreResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getQualifyPrime(rfpId), {
      schema: ScoreResponseModel,
      body: values
    })
    dispatch({
      type: SET_SCORE_QUALIFY_PRIME,
      payload: response.data.data.score
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const setQualifyPrimeSBIR = (dispatch: Dispatch) => async (rfpId: string, values: {[key: string]: boolean | string}): Promise<IScore> => {
  try {
    dispatch({
      type: UPDATE_QUALIFY_PRIME_SBIR,
      payload: values
    })
    const response = await request<IScoreResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getQualifyPrimeSBIR(rfpId), {
      schema: ScoreResponseModel,
      body: values
    })
    dispatch({
      type: SET_SCORE_QUALIFY_PRIME_SBIR,
      payload: response.data.data.score
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const deleteQualifySub = (dispatch: Dispatch) => async (rfpId: string) => {
  try {
    await request<IScoreResponseModel>(RequestMethods.Delete, Paths.Hp.Calculators.Qualifying.getQualifySub(rfpId), {
      schema: ScoreResponseModel,
    })
    dispatch({
      type: DELETE_QUALIFY_SUB,
    })
  } catch (error) {
    throw error.response.data.errors
  }
}

export const deleteQualifyPrime = (dispatch: Dispatch) => async (rfpId: string) => {
  try {
    await request<IScoreResponseModel>(RequestMethods.Delete, Paths.Hp.Calculators.Qualifying.getQualifyPrime(rfpId), {
      schema: ScoreResponseModel,
    })
    dispatch({
      type: DELETE_QUALIFY_PRIME,
    })
  } catch (error) {
    throw error.response.data.errors
  }
}

export const deleteQualifyPrimeSBIR = (dispatch: Dispatch) => async (rfpId: string) => {
  try {
    await request<IScoreResponseModel>(RequestMethods.Delete, Paths.Hp.Calculators.Qualifying.getQualifyPrimeSBIR(rfpId), {
      schema: ScoreResponseModel,
    })
    dispatch({
      type: DELETE_QUALIFY_PRIME_SBIR,
    })
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getTasks = (dispatch: Dispatch) => async (rfpId: string, type: string, limit: number, offset: number, page: number): Promise<ITasksCalcResponseModel> => {
  try {
    dispatch({
      type: GET_TASKS_CALC
    })
    const response = await request<ITasksCalcResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getTasks(rfpId, type, limit, offset, page), {
      schema: TasksCalcResponseModel
    })
    dispatch({
      type: GET_TASKS_CALC_SUCCESS,
      payload: {
        data: response.data.data,
        count: response.data.count
      }
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_TASKS_CALC_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}


export const updateTasks = (dispatch: Dispatch) => async (rfpId: string, type: string, limit: number, offset: number, page: number): Promise<ITasksCalc> => {
  try {
    dispatch({
      type: UPDATE_TASKS_CALC
    })
    const response = await request<ITasksCalcResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getTasks(rfpId, type, limit, offset, page), {
      schema: TasksCalcResponseModel
    })
    dispatch({
      type: UPDATE_TASKS_CALC_SUCCESS,
      payload: response.data.data,
    })
    return response.data
  } catch (error) {
    dispatch({
      type: UPDATE_TASKS_CALC_ERROR,
      payload: error
    })
    throw error.response.data.errors
  }
}


export const setTasks = (dispatch: Dispatch) => async (rfpId: string, type: string, values: ITaskItem[]): Promise<ISetTaskData> => {
  try {
    dispatch({
      type: SET_TASKS_CALC
    })
    const response = await request<ISetTaskDataResponseModel>(RequestMethods.Post, Paths.Hp.Analysis.setTasks(rfpId, type), {
      schema: SetTasksResponseModel,
      body: { values }
    })
    dispatch({
      type: SET_TASKS_CALC_SUCCESS
    })
    return response.data.data
  } catch (error) {
    dispatch({
      type: SET_TASKS_CALC_ERROR
    })
    throw error.response.data.errors
  }
}

export const getIncumbents = (dispatch: Dispatch) => async (rfpId: string): Promise<IIncumbentsResponseModel> => {
  try {
    dispatch({
      type: GET_INCUMBENTS
    })
    const response = await request<IIncumbentsResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getIncumbents(rfpId), {
      schema: IncumbensResponseModel
    })
    dispatch({
      type: GET_INCUMBENTS_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_INCUMBENTS_ERROR
    })
    throw error.response.data.errors
  }
}

export const setIncumbents = (dispatch: Dispatch) => async (rfpId: string, data: ISaveIncumbent) => {
  try {
    dispatch({
      type: SET_INCUMBENTS
    })
    const response = await request<IIncumbentsResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getIncumbents(rfpId), {
      schema: IncumbensResponseModel,
      body: data
    })
    dispatch({
      type: SET_INCUMBENTS_SUCCESS,
    })
    return response.data
  } catch (error) {
    dispatch({
      type: SET_INCUMBENTS_ERROR
    })
    throw error.response.data.errors
  }
}

export const getIncumbentPerformance = (dispatch: Dispatch) => async (rfpId: string): Promise<IIncumbentperformanceResponseModel> => {
  try {
    dispatch({
      type: GET_INCUMBENT_PERFORMANCE
    })
    const response = await request<IIncumbentperformanceResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getIncumbentPerformance(rfpId), {
      schema: IncumbentPerformanceResponseModel
    })
    dispatch({
      type: GET_INCUMBENT_PERFORMANCE_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_INCUMBENT_PERFORMANCE_ERROR
    })
    throw error.response.data.errors
  }
}


export const setIncumbentPerformance = async (rfpId: string, data: ISaveIncumbentPerformance) => {
  try {
    const response = await request<IIncumbentperformanceResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getIncumbentPerformance(rfpId), {
      schema: IncumbentPerformanceResponseModel,
      body: data
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}


export const getSubsequentAwards = (dispatch: Dispatch) => async (rfpId: string): Promise<ISubsequentAwardsResponseModel> => {
  try {
    dispatch({
      type: GET_SUBSEQUENT_AWARDS
    })
    const response = await request<ISubsequentAwardsResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getSubsequentAwards(rfpId), {
      schema: SubsequentAwardsResponseModel
    })
    dispatch({
      type: GET_SUBSEQUENT_AWARDS_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_SUBSEQUENT_AWARDS_ERROR
    })
    throw error.response.data.errors
  }
}

export const setSubsequentAwards = async (rfpId: string, data: ISaveSubsequentAward) => {
  try {
    const response = await request<IIncumbentperformanceResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getSubsequentAwards(rfpId), {
      schema: IncumbentPerformanceResponseModel,
      body: data
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}


export const getIncumbentCustomerConnection = (dispatch: Dispatch) => async (rfpId: string): Promise<ICustomerConnectionResponseModel> => {
  try {
    dispatch({
      type: GET_CUSTOMER_CONNECTION
    })
    const response = await request<ICustomerConnectionResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getIncumbentCustomerConnection(rfpId), {
      schema: CustomerConnectionResponseModel
    })
    dispatch({
      type: GET_CUSTOMER_CONNECTION_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_CONNECTION_ERROR
    })
    throw error.response.data.errors
  }
}


export const setCustomerConnection = async (rfpId: string, data: ISaveCustomerConnection) => {
  try {
    const response = await request<ICustomerConnectionResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getIncumbentCustomerConnection(rfpId), {
      schema: CustomerConnectionResponseModel,
      body: data
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getCompetitorLandscape = (dispatch: Dispatch) => async (rfpId: string): Promise<ICompetitorLandscapeResponseModel> => {
  try {
    dispatch({
      type: GET_COMPETITOR_LANDSCAPE
    })
    const response = await request<ICompetitorLandscapeResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getCompetitorLandscape(rfpId), {
      schema: CompetitorLandscapeResponseModel
    })
    dispatch({
      type: GET_COMPETITOR_LANDSCAPE_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_COMPETITOR_LANDSCAPE_ERROR
    })
    throw error.response.data.errors
  }
}

export const setCompetitorLandscape = async (rfpId: string, data: ISaveCompetitorLandscape) => {
  try {
    const response = await request<ICompetitorLandscapeResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getCompetitorLandscape(rfpId), {
      schema: CompetitorLandscapeResponseModel,
      body: data
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getCompetitivePosition = (dispatch: Dispatch) => async (rfpId: string): Promise<ICompetitivePositionResponseModel> => {
  try {
    dispatch({
      type: GET_COMPETITIVE_POSITION
    })
    const response = await request<ICompetitivePositionResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getCompetitivePosition(rfpId), {
      schema: CompetitivePositionResponseModel
    })
    dispatch({
      type: GET_COMPETITIVE_POSITION_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_COMPETITIVE_POSITION_ERROR
    })
    throw error.response.data.errors
  }
}

export const setCompetitivePosition = async (rfpId: string, data: ISaveCompetitivePosition) => {
  try {
    const response = await request<ICompetitivePositionResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getCompetitivePosition(rfpId), {
      schema: CompetitivePositionResponseModel,
      body: data
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getSummary = (dispatch: Dispatch) => async (rfpId: string): Promise<ISummaryResponseModel> => {
  try {
    dispatch({
      type: GET_SUMMARY
    })
    const response = await request<ISummaryResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getSummary(rfpId), {
      schema: SummaryResponseModel
    })
    dispatch({
      type: GET_SUMMARY_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_SUMMARY_ERROR
    })
    throw error.response.data.errors
  }
}

export const setSummary  = (dispatch: Dispatch) => async (rfpId: string, data: ISaveSummary) => {
  try {
    dispatch({
      type: SET_SUMMARY
    })
    const response = await request<ISummaryResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getSummary(rfpId), {
      schema: SummaryResponseModel,
      body: data
    })
    dispatch({
      type: SET_SUMMARY_SUCCESS
    })
    return response.data
  } catch (error) {
    dispatch({
      type: SET_SUMMARY_ERROR
    })
    throw error.response.data.errors
  }
}

export const getSelfAssessmentInput = (dispatch: Dispatch) => async (rfpId: string): Promise<ISelfAssessmentInputResponseModel> => {
  try {
    dispatch({
      type: GET_SELF_ASSESSMENT_INPUT
    })
    const response = await request<SelfAssessmentResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getSelfAssessmentInput(rfpId), {
      schema: SelfAssessmentResponseModel
    })
    dispatch({
      type: GET_SELF_ASSESSMENT_INPUT_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_SELF_ASSESSMENT_INPUT_ERROR
    })
    throw error.response.data.errors
  }
}

export const setSelfAssessmentInputSelfScore  = (dispatch: Dispatch) => async (rfpId: string, data: ISaveSelfAssessmentInputSelfScore[]) => {
  try {
    dispatch({
      type: SET_SELF_ASSESSMENT_INPUT_SELF_SCORE
    })
    const response = await request<ISetSubbingResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.setSelfAssessmentInputSelfScore(rfpId), {
      schema: SetSubbingResponseModel,
      body: {values: data}
    })
    dispatch({
      type: SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_SUCCESS,
      payload: response.data.data.grade
    })
    return response.data
  } catch (error) {
    dispatch({
      type: SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_ERROR
    })
    throw error.response.data.errors
  }
}

export const updateSelfAssessmentInputSelfScore = (disaptch: Dispatch) => (data: {[key: string]: number | boolean}) => {
  disaptch({
    type: UPDATE_SELF_ASSESSMENT_INPUT_SELF_SCORE,
    payload: data
  })
}

export const getSubbing = (dispatch: Dispatch) => async (rfpId: string): Promise<ISubbingResponseModel> => {
  try {
    dispatch({
      type: GET_SUBBING
    })
    const response = await request<ISubbingResponseModel>(RequestMethods.Get, Paths.Hp.Calculators.Qualifying.getSubbing(rfpId), {
      schema: SubbingResponseModel
    })
    dispatch({
      type: GET_SUBBING_SUCCESS,
      payload: response.data.data
    })
    return response.data
  } catch (error) {
    dispatch({
      type: GET_SUBBING_ERROR
    })
    throw error.response.data.errors
  }
}

export const setSubbing = (dispatch: Dispatch) => async (rfpId: string, data: {[key: string]: string}) => {
  dispatch({
    type: SET_SUBBING
  })
  try {
    const response = await request<ISetSubbingResponseModel>(RequestMethods.Post, Paths.Hp.Calculators.Qualifying.getSubbing(rfpId), {
      schema: SetSubbingResponseModel,
      body: data
    })
    dispatch({
      type: SET_SUBBING_SUCCESS,
      payload: response.data.data.grade
    })
    return response.data
  } catch (error) {
    dispatch({
      type: SET_SUBBING_ERROR
    })
    throw error.response.data.errors
  }
}

export const updateSubbing = (dispatch: Dispatch) => (data: {[key: string]: string}) => {
  dispatch({
    type: UPDATE_SUBBING,
    payload: data
  })
}