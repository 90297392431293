import { userBaseResponseSelector } from 'store/common/user/selectors'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Redirect, Route, useHistory, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React, { useEffect, useRef } from 'react'
import { NotificationTypeList } from '../../components/NotificationType'
import { useNotification } from '../../context/NotificationContext'

interface IProtectedRouteProps extends RouteProps {
  path: string
  children?: React.ReactNode
}

const useStyles = makeStyles({
  root: {
    margin: 'auto'
  }
})

const ProtectedRoute = ({ path, children, ...restProps }: IProtectedRouteProps) => {
  const classes = useStyles()

  const user = useSelector(userBaseResponseSelector)
  const notification = useRef(useNotification())
  const history = useHistory()

  // Handle user data for notifications
  useEffect(() => {
    if (user.errors && notification.current) {
      notification.current.show(user.errors[0].message, NotificationTypeList.Error)
    }
  }, [ user ])


  // Loader if user not logged in and request in pending OR is not  send
  if (!user.data && !user.errors) {
    return <CircularProgress className={classes.root} size={50}/>
  }

  if (!user.data || !!user.errors) {
    const redirect = history.location.pathname.length > 1 ? `?redirect=${history.location.pathname}` : ''
    return <Redirect to={{ search: redirect, pathname: '/auth/sign-in' }}/>
  }

  return (
    <Route path={path} {...restProps}>
      {children}
    </Route>
  )
}

export default ProtectedRoute
