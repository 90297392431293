import React, { useState } from 'react'
import styles from '../index.module.scss'
import { useSelector } from 'react-redux'
import { Typography, Button } from '@material-ui/core'
import { userBaseResponseSelector } from 'store/common/user/selectors'
import Form from './Form'

const Name = () => {
  const [toggler, setToggler] = useState(false)

  const user = useSelector(userBaseResponseSelector)

  const renderPresentation = () => {
    return (
      <>
        <div className={styles['text']}>
          <Typography variant='h5'>First: {user.data?.firstName}</Typography>
          <Typography variant='h5'>Last: {user.data?.lastName}</Typography>
        </div>
        <Button
          variant='text'
          onClick={() => setToggler(true)}
          color='primary'
        >
          Change name
        </Button>
      </>
    )
  }
  const renderForm = () => {
    return (
      <Form cancelUpdate={() => setToggler(false)} />
    )
  }

  return (
    <div>
      <Typography variant='h4'>Name</Typography>
      {toggler ? renderForm() : renderPresentation()}
    </div>
  )
}

export default Name