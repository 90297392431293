import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid } from '@material-ui/core'
import RelashionshipUploader from './RelashionshipUploader/RelashionshipUploader'
import FileUploader from './FileUploader/FileUploader'
import RelationshipMap from './RelationshipMap/RelationshipMap'
import { useDispatch, useSelector } from 'react-redux'
import { getContactsChart } from 'store/hp/education/action'
import { contactsChartSelector } from 'store/hp/education/selector'

const EducationDashboard = () => {
  const dispatch = useDispatch()
  const [isViewFileUpoloader, setViewFileUploader] = useState<boolean>(false)
  const {data: chartData, loading: chartLoading, loaded: chartLoaded} = useSelector(contactsChartSelector)

  useEffect(() => {
    if(!isViewFileUpoloader && !chartLoaded) {
      getContactsChartRequest()
    }
    if(chartData && !chartData.agencies.length) {
      setViewFileUploader(true)
    }
  }, [chartData])

  const getContactsChartRequest = () => {
    getContactsChart(dispatch)
      .then(() => {
        setViewFileUploader(false)
      })
      .catch(() => {
        setViewFileUploader(true)
      })
  }
  
  return (
    <div className={styles['education-dashboard']}>
      <Grid container className={styles['education-dashboard-list']} spacing={2}>
        <Grid item md={3}>
          <RelashionshipUploader />
        </Grid>
        <Grid item md={9}>
          { (isViewFileUpoloader 
              && !chartLoading 
              && !chartLoaded) 
              || (!chartData?.agencies.length && chartLoaded) 
            ? <FileUploader /> 
            : null }
          { !chartLoading && chartData?.agencies.length ? <RelationshipMap /> : null }
          { chartLoading && (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
   
  )
}

export default EducationDashboard