import { ERROR_MESSAGES } from 'utils/message'
import * as Yup from 'yup'

const numberValidation = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

const inputValidation = (minValue?: number, maxValue?: number) => {
  return {
    requiredNumber: Yup.string().required(ERROR_MESSAGES.Field.Required('Field')).matches(
      numberValidation,
      'Not valid'
    ),
    number: Yup.string().matches(
      numberValidation,
      ERROR_MESSAGES.Field.Required('Field')
    ),
    required: Yup.string().required(ERROR_MESSAGES.Field.Required('Field')),
    array: Yup.array().min(1, ERROR_MESSAGES.Field.Required('Field')),
    notRequired: Yup.string().notRequired()
  }
}

export default inputValidation
