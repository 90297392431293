import * as React from 'react'
import styles from './index.module.scss'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import Divider from 'components/v2/Divider'

interface Props<T> {
  item: T
  onDelete: (item: T) => void
}

const SimilarCompanyGroupItem = ({item, onDelete}: Props<any>) => {
  const theme = useTheme()
  const title = item.groupName
  const subItems = item.companies || item.keywords

  return (
    <Grid item md={4} className={styles['group-item']}>
      <Typography variant='h4'>{title}</Typography>
      <Divider />
      <Grid
        container
        className={styles['group-item-content']}
      >
        <Grid item className={styles['btn']}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => onDelete(item)}
          >
            Delete
          </Button>
        </Grid>
        <Grid item className={styles['group-companies-wrapper']}>
          {
            subItems && subItems.map((subItem: any) => {
              const title = subItem.companyName || subItem.keyword
              return (
                <Grid
                  key={subItem.id}
                  container
                >
                  <SvgIcon name='arrow-right' color={theme.palette.warning.main} width={24} height={24} />
                  <Typography>{title}</Typography>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SimilarCompanyGroupItem