import React from 'react'
import styles from './index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { useDispatch, useSelector } from 'react-redux'
import { govConSizesSelector } from 'store/hp/company-profiles/options/selectors'
import {
  companyProfileSelectedSizeOptions
} from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue, removeCompanySearchFilterValues,
  setCompanySearchFilterValue, setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'

const GovConSizeFilter = () => {

  const dispatch = useDispatch()

  const govConSizeSelector = useSelector(govConSizesSelector)
  const selectedSizeOptions: number[] = useSelector(companyProfileSelectedSizeOptions)

  const handleChange = (value: string | number, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('sizeIds', +value)
    } else {
      removeCompanySearchFilterValue(dispatch)('sizeIds', +value)
    }
  }

  const handlePartialChange = (values: Array<number | string>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('sizeIds', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('sizeIds', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={govConSizeSelector?.length}
        iconType='size'
        handlePartialChange={handlePartialChange}
        filterTitle='Company Sizes'
        filterOptions={govConSizeSelector!}
        selectedFilterOptions={selectedSizeOptions}
        handleChange={handleChange}
      />
    </div>
  )
}

export default GovConSizeFilter
