import React from 'react'
import styles from './index.module.scss'
import { Skeleton } from '@material-ui/lab'

const Skeletons = () => {
  return (
    <div className={styles['skeletons']}>
      <div className={styles['skeletons-title']}>
        <Skeleton  variant="text" animation='wave' height={40} width='70%' />
      </div>
      <div className={styles['skeletons-chart']}>
        <div className={styles['skeletons-chart-item']}>
          <Skeleton variant="rect" animation='wave' height={280} width='80px' />
          <Skeleton variant="rect" animation='wave' height={30} width='80px' />
        </div>
        <div className={styles['skeletons-chart-item']}>
          <Skeleton variant="rect" animation='wave' height={280} width='80px' />
          <Skeleton variant="rect" animation='wave' height={30} width='80px' />
        </div>
        <div className={styles['skeletons-chart-item']}>
          <Skeleton variant="rect" animation='wave' height={280} width='80px' />
          <Skeleton variant="rect" animation='wave' height={30} width='80px' />
        </div>
        <div className={styles['skeletons-chart-item']}>
          <Skeleton variant="rect" animation='wave' height={280} width='80px' />
          <Skeleton variant="rect" animation='wave' height={30} width='80px' />
        </div>
        <div className={styles['skeletons-chart-item']}>
          <Skeleton variant="rect" animation='wave' height={280} width='80px' />
          <Skeleton variant="rect" animation='wave' height={30} width='80px' />
        </div>
      </div>
      <div className={styles['skeletons-targets']}>
        <div className={styles['skeletons-targets-head']}>
          <Skeleton  variant="text" animation='wave' height={30} width='40%' />
          <Skeleton  variant="text" animation='wave' height={30} width='20%' />
        </div>
        <div className={styles['skeletons-targets-list']}>
          <Skeleton  variant="text" animation='wave' height={30} width='15%' />
          <Skeleton  variant="text" animation='wave' height={30} width='15%' />
          <Skeleton  variant="text" animation='wave' height={30} width='15%' />
          <Skeleton  variant="text" animation='wave' height={30} width='15%' />
          <Skeleton  variant="text" animation='wave' height={30} width='15%' />
        </div>
      </div>
    </div>
  )
}

export default Skeletons