import React from 'react'
import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'
import { IFilterOption } from 'models/hp/search/search.interface'

export const isChecked = (subs: IFilterOption[], selected: Array<number | string>, selectField: 'id' | 'value' | 'code'): boolean => {
  for (const item of subs) {
    if (!item.sub || (item.sub.length === 0 && selected.includes(item[selectField!]!))) {
      return true
    }
    if (item.sub && item.sub.length > 0) {
      const temp = isChecked(item.sub, selected, selectField)
      if (temp) {
        return true
      }
    }
  }
  return false
}

interface Props {
  id: number | string
  text: string
  value?: string
  sub: IFilterOption[]
  selected: Array<string | number>
  handleChange: (id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (id: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
  selectField?: 'id' | 'value'
  option?: IFilterOption,
  rootOption?: boolean
}

const RecursiveFilterItem = ({id, text, value, sub, selected, selectField = 'id', option,...props}: Props) => {
  const [show, setShow] = React.useState<boolean>(false)

  const checked = selected.includes(selectField === 'value' ? value! : id) || isChecked(sub, selected, selectField)

  const children: IFilterOption[] = []

  const getChildrenIds = (subs: IFilterOption[]) => {
    subs.forEach(item => {
      if (item.sub && item.sub.length > 0) {
        getChildrenIds(item.sub)
      } else {
        children.push(item)
      }
    })
  }

  const handleChange = () => {
    const v = selectField === 'id' ? id : value!
    if (checked) {// unchecked in fact
      if (!sub || sub.length === 0) {
        props.handleChange(v, false, option)
      } else {
        getChildrenIds(sub)
        props.handlePartialChange(children.map(i => i[selectField]!), false, children)
      }
    } else { // checked in fact
      if (!sub || sub.length === 0) {
        props.handleChange(v, true, option)
      } else {
        getChildrenIds(sub)
        props.handlePartialChange(children.map(i => i[selectField]!), true, children)
      }
    }
  }

  const showText = (): React.ReactNode => {
    const str = text.split(' /n ')
    return (
      <>
        <Typography>{str[0]}</Typography>
        {str[1] && <Typography variant='subtitle1'>({str[1]})</Typography>}
      </>
    )
  }

  return (
    <div className={styles['select-block-wrap']}>
      <div className={`${styles['select-block']} ${props.rootOption && styles['select-block-root']}`}>
        <FormControlLabel
          key={id}
          className={styles['select-input']}
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              color='primary'
            />
          }
          label={showText()}
        />
        {sub.length > 0 && (
          <Button
            className={show ? styles['show-btn-active'] : undefined}
            variant='text'
            disableRipple
            onClick={() => setShow(!show)}
          >
            <SvgIcon
              name='angle-down'
            />
          </Button>
        )}
      </div>
      {show && (
        <div className={styles['select-block-children']}>
          {sub.length > 0 && sub.map(option => (
            <RecursiveFilterItem
              key={option.id || option.text}
              id={option.id}
              text={option.text}
              value={option.value}
              option={option}
              selectField={selectField}
              sub={option.sub as IFilterOption[]}
              selected={selected}
              handleChange={props.handleChange}
              handlePartialChange={props.handlePartialChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default RecursiveFilterItem