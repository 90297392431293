import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { getErrorMessage } from 'helpers/errors'
import { can } from 'components/v2/Can'
import { userSelector, userSettingsSelector } from 'store/common/user/selectors'
import { IUserBaseModel, IUserSettings } from 'models/common/user/user.interfaces'
import { updateUserSettings } from 'store/common/user/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'

const Ads = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector) as IUserBaseModel
  const notification = useRef(useNotification())
  const userSettings: IUserSettings | null = useSelector(userSettingsSelector) as IUserSettings

  const [showAds, setShowAds] = useState(true)
  const [processing, setProcessing] = useState(false)

  const handleChange = async (_: any, checked: boolean) => {
    setShowAds(prevState => !prevState)
    setProcessing(true)
    try {
      await updateUserSettings(dispatch)({
        ...userSettings!,
        hpAdsShow: checked
      })
      notification.current.show('Settings updated', NotificationTypeList.Success)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
      setShowAds(prevState => !prevState)
    }
    setProcessing(false)
  }

  useEffect(() => {
    const value = typeof userSettings?.hpAdsShow === 'boolean' ? userSettings.hpAdsShow : true
    setShowAds(value)
  }, [userSettings])

  return (
    <div>
      <Typography variant='h4'>Show advertisement:</Typography>
      {
        !can(user, 'growth') && (
          <Typography gutterBottom>(Can only be changed on Growth Plan or Higher)</Typography>
        )
      }
      <FormControlLabel
        control={
          <Checkbox
            checked={showAds}
            onChange={handleChange}
            color='primary'
            disabled={!can(user, 'growth') || processing}
          />
        }
        label={showAds ? 'Yes' : 'No'}
      />
    </div>
  )
}

export default Ads