import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title?: string
  description?: string
  keywords?: string,
  url?: string
}

const SeoData = ({
  title = '',
  description = '',
  keywords,
  url = window.location.origin,
}: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description}/>
      <meta name='keywords' content={keywords}/>
      <meta name='twitter:title' content={title}/>
      <meta name='twitter:description' content={description}/>
      <meta property='og:url' content={url}/>
      <meta property='og:type' content='website'/>
      <meta property='og:title' content={title}/>
      <meta property='og:description' content={description}/>
      <link rel='canonical' href={url}/>
    </Helmet>
  )
}

export default SeoData
