import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { pagesData } from 'modules/Main/Hp/RfpInnerRoutesNew/pagesData'
import * as H from 'history'

export const isOldPath = (splitLocations: string[]): [boolean, string] => {
  const loc = splitLocations[3]

  // if not included - url is old (no separation by sections: overview, documents etc...)
  return [!pagesData().map(item => item.path).includes(loc), loc]
}

export const getCurrentPageName = (splitLocations: string[]): string => {
  const [isOld, location] = isOldPath(splitLocations)
  if (!isOld) {
    return location
  } else {
    return pagesData()[0].path // take Overview
  }
}


export const handleStep = (path: string, history: H.History<unknown>, user: IUserBaseModel, splitLocation: string[], isProtected?: boolean,) => {
  const newPath = [...splitLocation]

  const [isOld] = isOldPath(splitLocation)
  isOld
    ? newPath.splice(3, 0, path) // add tab name to existing url
    : newPath.splice(3, 1, path) // replace overview tab url with other tabs

  if((isProtected && user) || !isProtected) {
    history.push(newPath.join('/'))
  }
}