import React, { useContext, useReducer } from 'react'
import { SnackbarOrigin } from '@material-ui/core'
import { NotificationTypeList, notificationTypes } from 'components/NotificationType'

export enum SnackbarPosition {
  VerticalBottom = 'bottom',
  VerticalTop = 'top',
  HorizontalLeft = 'left',
  HorizontalCenter = 'center',
  HorizontalRight = 'right'
}

export const defaultSnackbarOrigin: SnackbarOrigin = {
  vertical: SnackbarPosition.VerticalBottom,
  horizontal: SnackbarPosition.HorizontalLeft
}
const defaultAutoHideDuration = 3000 // 3 Seconds


enum States {
  Show = 'show',
  Hide = 'hide'
}

type states = States.Show | States.Hide

interface IState {
  type: notificationTypes
  visible: boolean
  message?: string
  anchorOrigin: SnackbarOrigin,
  autoHideDuration: number
}

interface IAction {
  type: states
  payload: IState
}

export interface INotificationContext {
  autoHideDuration: number
  visible: boolean
  message?: string
  anchorOrigin: SnackbarOrigin,
  show: (message: string, type: notificationTypes, anchorOrigin?: SnackbarOrigin, autoHideDuration?: number) => void
  hide: () => void
  type: notificationTypes | undefined
}

const NotificationContext = React.createContext<INotificationContext>({
  visible: true,
  message: '',
  autoHideDuration: defaultAutoHideDuration,
  anchorOrigin: { ...defaultSnackbarOrigin },
  type: undefined,
  show: () => {
  },
  hide: () => {
  }
})

export const useNotification = () => useContext<INotificationContext>(NotificationContext)

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case States.Show:
      return {
        ...state,
        visible: true,
        message: action.payload.message,
        anchorOrigin: action.payload.anchorOrigin,
        autoHideDuration: action.payload.autoHideDuration,
        type: action.payload.type
      }
    case States.Hide:
      return {
        ...state,
        visible: false,
        message: '',
      }
    default:
      return state
  }
}

interface INotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider = ({ children }: INotificationProviderProps) => {

  const [ state, dispatch ] = useReducer(reducer, {
    visible: false,
    message: '',
    anchorOrigin: { ...defaultSnackbarOrigin },
    autoHideDuration: defaultAutoHideDuration,
    type: NotificationTypeList.Success
  })

  const show = (message: string, type: notificationTypes, anchorOrigin: SnackbarOrigin = {
    ...defaultSnackbarOrigin
  }, autoHideDuration = defaultAutoHideDuration) => dispatch({
    type: States.Show,
    payload: {
      message, anchorOrigin, visible: true, autoHideDuration: autoHideDuration, type
    }
  })


  const hide = () => dispatch({
    type: States.Hide,
    payload: {
      message: '',
      anchorOrigin: { ...defaultSnackbarOrigin },
      visible: false,
      autoHideDuration: defaultAutoHideDuration,
      type: NotificationTypeList.Success
    }
  })

  const { visible, message, anchorOrigin, autoHideDuration, type } = state

  return (
    <NotificationContext.Provider
      value={{
        autoHideDuration,
        visible,
        message,
        anchorOrigin,
        type,
        show,
        hide,
      }}>
      {children}
    </NotificationContext.Provider>
  )

}
