import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acceptUserInvitationInGroup, deleteUserSelfInvitation, getListOfGroupsUserBelongsTo, getUserPendingIvitations } from 'store/common/user-group/actons'
import { userGroupInvitationsPending, userGroupListLoadingSelector } from 'store/common/user-group/selectors'
import styles from './index.module.scss'

const Invitations = () => {
  const invitations = useSelector(userGroupInvitationsPending)
  const invitationsLoading = useSelector(userGroupListLoadingSelector)
  const dispatch = useDispatch()
  const notification = useNotification()
  
  const denyInvitation = (id: string) => {
    deleteUserSelfInvitation(id).then(() => {
      getUserPendingIvitations(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }
  
  const approveInvitation = (id: string) => {
    acceptUserInvitationInGroup(id).then(() => {
      getUserPendingIvitations(dispatch)()
      getListOfGroupsUserBelongsTo(dispatch)()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }
  return (
    <>
      <Typography variant='h4'>Invitations</Typography>
      <Paper className={styles['clients-paper']}>
        { !invitations.length && !invitationsLoading &&
            <Typography align='center' variant='subtitle1'>No invitations found.</Typography>
        }
        {
          invitationsLoading && 
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30}/>
            </Grid>
        }
        {
          invitations.length && !invitationsLoading ? (
            <Grid>
              {
                invitations.map(el => (
                  <Grid className={styles['clients-item']} container alignItems='center' justifyContent='space-between' spacing={2} key={el.id}>
                    <Grid item md={6}>
                      <Typography variant='body2'>{el.user.firstName} {el.user.lastName}</Typography>
                      <Typography variant='body2'>{el.user.email}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                          <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={() => approveInvitation(el.id)}
                          >
                          Approve
                          </Button>
                        </Grid>
                        <Grid item >
                          <Button 
                            style={{background: 'orange'}} 
                            variant='contained' 
                            color='primary' 
                            onClick={() => denyInvitation(el.id)}
                          >
                          Deny
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
          ) : null
        }
      
      </Paper>
    </>
  )
}

export default Invitations