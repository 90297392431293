import { Dispatch } from 'redux'
import { IAwardSearchRecord } from 'store/hp/awards/search/reducer'
import { DashboardResponseModel } from 'models/hp/awards/awards.model'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'

export const AWARD_DASHBOARD = 'AWARD_DASHBOARD'
export const AWARD_DASHBOARD_ERROR = 'AWARD_DASHBOARD_ERROR'
export const AWARD_DASHBOARD_SUCCESS = 'AWARD_DASHBOARD_SUCCESS'

export const getDashboard = (dispatch: Dispatch) => async (filters: IAwardSearchRecord): Promise<void> => {
  dispatch({
    type: AWARD_DASHBOARD
  })
  try {
    const res = await request<DashboardResponseModel>(RequestMethods.Post, Paths.Hp.Awards.getDashboard, {
      schema: DashboardResponseModel,
      body: filters || {}
    })
    dispatch({
      type: AWARD_DASHBOARD_SUCCESS,
      payload: res.data
    })
  }
  catch (e) {
    dispatch({
      type: AWARD_DASHBOARD_ERROR
    })
    throw e
  }
}