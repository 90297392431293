import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SignIn from './SignIn'
import SignUp from './SignUp'
import Verify from './Verify'
import Password from './Password'
import SeoData from 'components/v2/SeoData'
import { SEO_DATA } from 'utils/const'
import SearchStarter from './SearchStarter'
import OAuth from 'modules/Auth/OAuth'

const Auth = () => {
  return (
    <>
      <SeoData
        title={SEO_DATA.title}
        description={SEO_DATA.description}
      />
      <Switch>
        <Route path={'/auth/sign-in'}>
          <SignIn/>
        </Route>
        <Route path={'/auth/sign-up'}>
          <SignUp/>
        </Route>
        <Route path={'/auth/verify/:email'}>
          <Verify/>
        </Route>
        <Route path={'/auth/recover'}>
          <Password/>
        </Route>
        <Route path='/auth/starter-search'>
          <SearchStarter/>
        </Route>
        <Route path='/auth/oauth'>
          <OAuth/>
        </Route>
      </Switch>
    </>
  )
}

export default Auth
