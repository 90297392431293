import React from 'react'
import styles from '../../index.module.scss'
import { Grid, Typography, Radio, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SvgIcon from 'components/SvgIcon'
import TextField from 'components/v2/TextField'
import ActionsBar from '../../components/ActionsBar'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { runMarketResearch as runMarketResearchAction } from 'store/hp/market-research/actions'
import { searchSelector } from 'store/hp/market-research/selectors'
import { NotificationTypeList } from 'components/NotificationType'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import HelperVideo from 'components/v2/HelperVideoModal'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

const validationSchema = Yup.object({
  type: Yup.string().required(ERROR_MESSAGES.Field.Required('Field')),
  keywords: Yup.array()
    .min(1, ERROR_MESSAGES.Field.Required('Field'))
    .required(ERROR_MESSAGES.Field.Required('Field'))
})

const fillKeywords = (keywords: string[]) => {
  const border = 6
  if (keywords.length === border) {
    return keywords
  }
  return keywords.concat(new Array(border - keywords.length).fill(''))
}

interface FormValues {
  type: string
  keywords: string[]
}

const SearchKeywords = () => {
  const history = useHistory()
  const theme = useTheme()
  const { show } = useNotification()

  const search = useSelector(searchSelector) as SearchModel

  const runMarketResearch = (values: FormValues) => {
    return runMarketResearchAction(search.id, values.keywords, values.type)
  }
  const handleStop = async (values: FormValues) => {
    try {
      await runMarketResearch(values)
      show('Changes saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }
  const handleContinue = async (values: FormValues) => {
    try {
      await runMarketResearch(values)
      history.push('/user/market-research/categorization')
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <div>
      <Typography variant='h2'>Choose the Type of Work You Do & Keywords</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideo source={VideoSourceType.MARKET_RESEARCH_CHOOSING_KEYWORDS}/>
            <Typography variant='h4'>Selecting product, service, or R&D::</Typography>
            <Typography>
              • Select whether your idea is for a product, services, or R&D company. If you use a mixture
              of products and services to meet your customers’ needs then consider:
            </Typography>
            <Typography>
              • Choose the dominant one. For example:
            </Typography>
            <Typography>
              • <b>Choose services:</b> If the products you use are incidental, like the cleaning products you’d use when
              delivering janitorial services
            </Typography>
            <Typography>
              • <b>Choose product:</b> If the services you provide are secondary, like installation services when you sell
              your product
            </Typography>
            <Typography>
              • If you business is truly providing a mixture of products and services consider conducting separate market
              researches, one for each side of your business
            </Typography>
            <Typography>
              • A note on R&D: only select R&D if you are actually research and designing NEW products for the government.
            </Typography>
            <Typography gutterBottom>
              • In your profile, and in the downloadable Market Guide at the end you can compare between the different
              product/service lines you are considering
            </Typography>
            <Typography variant='h4'>
              Selecting keywords
            </Typography>
            <Typography>
              • Add some keywords related to the product/service you are exploring.
            </Typography>
            <Typography>
              • It can be hard to know which keywords the government uses, so add any keywords you think the government might
              use and we'll help you figure out which of those are actually
              being used.
            </Typography>
            <Typography>
              • If you put multiple words in a box they will be treated as a single entity (as if you put “”
              around the words)
            </Typography>
            <Typography gutterBottom>
              • If you are researching ideas that are similar to one another make sure to use keywords that
              are specific to each idea. If you enter the same keywords for two ideas you are going to get the same results
              for both, which doesn't really help you choose the best one.
            </Typography>
            <Typography variant='h4'>
              Example:
            </Typography>
            <Typography>
              • If you search for "Dog walking"
            </Typography>
            <Typography>
              • This contract would be returned: Vendor will provide dog walking and kenneling
              services
            </Typography>
            <Typography>
              • And this contract would be returned: Vendor will provide dog kenneling services
              and their facilities will have a walking yard for canine exercising
              services
            </Typography>
            <Typography gutterBottom>
              • And this contract would NOT be returned: Vendor will provide dog kenneling
              services and their facilities will have a walking yard for canine exercising
            </Typography>
          </Grid>
        </Grid>
        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>
            Select whether you sell Products, Services or R&D & enter a few keywords
          </Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']}>
            <Formik
              initialValues={{
                type: 'product',
                keywords: fillKeywords(search.keywords) || ['', '', '', '', '', '']
              }}
              onSubmit={handleContinue}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors
              }) => (
                <form className={styles['form']} onSubmit={handleSubmit}>
                  <Grid container justifyContent='space-between' style={{ width: '100%', marginBottom: '20px' }}>
                    <Grid item style={{ width: '30%' }}>
                      <Typography variant='h4'>1)Choose one</Typography>
                      <Grid item className={styles['centered-content']}>
                        <SvgIcon name='box' width={40} height={30} />
                        <Typography>Product</Typography>
                        <Radio
                          name='type-selection'
                          checked={values.type === 'product'}
                          onChange={handleChange('type')}
                          value='product'
                          color='primary'
                        />
                      </Grid>
                      <Grid item className={styles['centered-content']}>
                        <SvgIcon name='setting' width={40} height={30} />
                        <Typography>Service</Typography>
                        <Radio
                          name='type-selection'
                          checked={values.type === 'service'}
                          onChange={handleChange('type')}
                          value='service'
                          color='primary'
                        />
                      </Grid>
                      <Grid item className={styles['centered-content']}>
                        <SvgIcon name='search' width={40} height={30} />
                        <Typography>R&D</Typography>
                        <Radio
                          name='type-selection'
                          checked={values.type === 'r&d'}
                          onChange={handleChange('type')}
                          value='r&d'
                          color='primary'
                        />
                      </Grid>
                    </Grid>
                    <Grid item style={{ width: '50%' }}>
                      <Typography variant='h4'>2) Enter keywords:</Typography>

                      {values.keywords.map((keyword, idx) => (
                        <div key={idx} className={styles['gutter-bottom']}>
                          <TextField
                            fullWidth
                            variant={'filled'}
                            label={'Keyword'}
                            onChange={handleChange(`keywords[${idx}]`)}
                            onBlur={handleBlur(`keywords[${idx}]`)}
                            value={values.keywords[idx]}
                          />
                        </div>
                      ))}
                      {touched.keywords && (touched.keywords as unknown as boolean[]).some(x => x) && values.keywords.every(x => !x) && (
                        <div
                          style={{ color: theme.palette.error.main }}
                        >
                          One of keywords should be entered
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <ActionsBar
                    handleGoBack={() => history.goBack()}
                    handleContinue={handleSubmit}
                    handleStop={() => handleStop(values)}
                  />
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchKeywords