import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import { appendTrashedRfps, getTrashedRfps, resetTrashedRfps } from 'store/hp/rfp/action'
import { useDispatch, useSelector } from 'react-redux'
import { hpRfpTrashedCountSelector, hpRfpTrashedLoadedSelector, hpRfpTrashedLoadingSelector, hpRfpTrashedSelector } from 'store/hp/rfp/selector'
import Skeletons from './components/Skeletons'
import { HpRfpModel } from 'models/hp/rfp/rfp.model'
import ContractItem from './components/ContractItem'
import ScrollManager from 'components/ScrollManager'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'

const Archive = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const archive = useSelector(hpRfpTrashedSelector) as HpRfpModel[]
  const archiveCount = useSelector(hpRfpTrashedCountSelector) as number
  const archiveLoading = useSelector(hpRfpTrashedLoadingSelector)
  const archiveLoaded = useSelector(hpRfpTrashedLoadedSelector)

  useEffect(() => {
    getTrashedRfps(dispatch)()
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
    return () => {
      resetTrashedRfps(dispatch)()
    }
  }, [])

  const loadMore = async (offset: number) => {
    try {
      if(!archiveLoading) {
        await appendTrashedRfps(dispatch)(offset)
      }
    } catch (e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const body = () => {
    if(!archiveLoaded) {
      return <Skeletons />
    }

    if(archive && archive.length > 0) {
      return (
        <ScrollManager scrollKey={'hp-rfp-archive'}>
          {({connectScrollTarget, onScrollBottom}: any) => (
            <div className={styles['archive-list-wrapper']} ref={(ref) => {
              connectScrollTarget(ref)
              onScrollBottom(ref, () => loadMore(archive.length))
            }}>
              <div className={styles['archive-list']}>
                {
                  archive.map(el => (
                    <ContractItem key={el.id} contract={el} />
                  ))
                }
                {archiveCount > archive.length && (
                  <div className={styles['archive-progress']}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          )}
        </ScrollManager>
      )
    }

    if(!archiveLoading && !archive.length) {
      return <Typography style={{textAlign: 'center'}} className={styles['archive-title']}>Not found data</Typography>
    }
  }

  return (
    <Paper className={styles['archive']}>
      <Typography className={styles['archive-title']}>Archive</Typography>
      {body()}
    </Paper>
  )
}

export default Archive