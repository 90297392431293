import React from 'react'
import { Grid } from '@material-ui/core'
import { LikedItem } from 'models/hp/education/education.model'
import styles from './index.module.scss'


const LikedInputRow = () => {
  const [likedItem] = React.useState(new LikedItem())

  const onInputChanged = (field: string) => (e: any) => {
    likedItem[field as keyof LikedItem] = e.target.value
  }

  return (
    <Grid container className={styles['input-row']}>
      <Grid item md={3} className={styles['input-row-item']}>
        <input value={likedItem.name} onClick={onInputChanged('name')} placeholder={'name'}/>
      </Grid>
      <Grid item md={3} className={styles['input-row-item']}>
        <input value={likedItem.name} onClick={onInputChanged('email')} placeholder={'email'}/>
      </Grid>
      <Grid item md={2} className={styles['input-row-item']}>
        <input value={likedItem.name} onClick={onInputChanged('phone')} placeholder={'phone'}/>
      </Grid>
      <Grid item md={4} className={styles['input-row-item']}>
        <input value={likedItem.name} onClick={onInputChanged('linkedIn')} placeholder={'linkedIn profile'}/>
      </Grid>
    </Grid>
  )
}

export default LikedInputRow