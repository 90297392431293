import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { HpRfpAwardModel, HpRfpModel, HpRfpUserGroupModel, HpRfpAssessing } from './rfp.model'
import { BasePaginationModel } from '../../base/base.model'
import { HpRfpTinderLightCalcModel } from './calc/calc.model'
import { clearHtmlString } from 'helpers/string'
import { UserBaseModel } from 'models/common/user/user.model'

@jsonObject
export class RfpTinderModel extends HpRfpModel {
  @jsonMember({ constructor: String })
  department: string = ''

  @jsonMember({ constructor: String, deserializer: (json) => clearHtmlString(json) })
  description: string = ''

  @jsonMember({ constructor: String })
  subTier: string = ''

  @jsonMember({ constructor: String, deserializer: (json) => json || 'Standard Contract' })
  analyzedType: string = ''

  @jsonMember({ constructor: String })
  classificationCodeName: string = ''

  @jsonMember({ constructor: HpRfpUserGroupModel })
  userGroup: HpRfpUserGroupModel | null = null

  @jsonMember({ constructor: HpRfpTinderLightCalcModel })
  calc: HpRfpTinderLightCalcModel = new HpRfpTinderLightCalcModel()

  @jsonMember({ constructor: HpRfpAwardModel })
  award?: HpRfpAwardModel = new HpRfpAwardModel()

  @jsonMember({ constructor:  HpRfpAssessing})
  assessings?: HpRfpAssessing = new HpRfpAssessing()

  @jsonMember({constructor: UserBaseModel})
  assignedUser?: UserBaseModel | undefined = undefined

  @jsonMember({constructor: Number})
  assignedUserId?: number | undefined = undefined

  @jsonMember({constructor: String, deserializer: (step) => typeof step === 'number' ? step.toString() : step})
  step: string | null = null

  @jsonMember({constructor: String})
  active?: string = 'Yes'
}

@jsonObject
export class RfpTinderResponseModel extends BasePaginationModel {
  @jsonArrayMember(RfpTinderModel)
  data: RfpTinderModel[] = []
}
