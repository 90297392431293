import * as React from 'react'
import { Grid } from '@material-ui/core'
import styles from './index.module.scss'

interface Props {
  banner: string
  children: React.ReactNode
}

const Layer = ({banner, children}: Props) => {
  return (
    <Grid className={styles['layer']} lg={6} item md={10} sm={10} xs={10}>
      <img src={banner} alt={'banner'}/>
      <Grid className={styles['links-container']} container justifyContent={'space-around'} alignContent={'stretch'}>
        {children}
      </Grid>
    </Grid>
  )
}

export default Layer
