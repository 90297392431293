import { ISetAsideBaseModel, ISetAsideResponseModel } from './set-aside.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class BaseSetAsideModel implements ISetAsideBaseModel {
  @jsonMember({ constructor: Number })
  id: number | undefined = undefined

  @jsonMember({ constructor: String })
  title: string = ''
}

@jsonObject
export class SetAsideResponseModel extends BaseResponseModel implements ISetAsideResponseModel {
  @jsonArrayMember(BaseSetAsideModel)
  data: BaseSetAsideModel[] = []
}