import React from 'react'
import styles from './index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { BaseRegionModel } from 'models/hp/location/location.model'
import { locationCountSelector, locationsSelector } from 'store/hp/location/selectors'
import {
  companyProfileAllSelectedStats,
} from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue,
  removeCompanySearchFilterValues,
  setCompanySearchFilterValue,
  setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import RecursiveItem, { isChecked } from 'components/v2/Filters/SearchFilter/RecursiveItem'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'

const StateFilter = () => {

  const dispatch = useDispatch()

  const [showMain, setShowMain] = React.useState<boolean>(false)
  const [showMapper, setShowMapperValue] = React.useState<{[key: string]: boolean}>({})

  const locationOptions: BaseRegionModel[] = useSelector(locationsSelector) as BaseRegionModel[]
  const selectedStatesOptions: string[] = useSelector(companyProfileAllSelectedStats)
  const locationCount: number = useSelector(locationCountSelector)!

  const handleChange = (code: number | string, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('stateCodes', code)
    } else {
      removeCompanySearchFilterValue(dispatch)('stateCodes', code)
    }
  }

  const handlePartialAllChange = (state: boolean, values: string[]) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('stateCodes', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('stateCodes', values)
    }
  }

  const renderItems = () => {
    return locationOptions.map(option => {
      const checked = isChecked(option.sub, selectedStatesOptions, 'code')
      return (
        <div className={styles['select-block-wrap']} key={option.region}>
          <div className={styles['select-block']}>
            <FormControlLabel
              className={styles['select-input']}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() =>
                    handlePartialAllChange(!checked, option.sub.map(item => item.code!))
                  }
                  color='primary'
                />
              }
              label={option.region}
            />
            {option.sub && option.sub.length > 0 && (
              <Button
                className={showMapper[option.region] ? styles['show-btn-active'] : undefined}
                variant='text'
                disableRipple
                onClick={() => setShowMapperValue({...showMapper, [option.region]: !showMapper[option.region]})}
              >
                <SvgIcon
                  name='angle-down'
                />
              </Button>
            )}
          </div>
          {showMapper[option.region] && (
            <div className={styles['select-block-children']}>
              {option.sub.map(item => (
                <RecursiveItem
                  selectField='value'
                  value={item.code}
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  sub={item.sub || []}
                  handleChange={handleChange}
                  handlePartialChange={() => {
                  }}
                  selected={selectedStatesOptions}
                />
              ))}
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <div className={styles['paper']}>
      <div className={styles['select-block']}>
        <FilterTitle title='Locations' iconType='location' allCount={locationCount} selectedLength={selectedStatesOptions.length}/>
        {locationOptions && locationOptions.length > 0 && (
          <Button
            className={showMain ? styles['show-btn-active'] : undefined}
            variant='text'
            disableRipple
            onClick={() => setShowMain(!showMain)}
          >
            <SvgIcon
              name='angle-down'
            />
          </Button>
        )}
      </div>
      {showMain && (
        <div className={styles['select-block-children-root']}>
          {renderItems()}
        </div>
      )}
    </div>
  )
}

export default StateFilter
