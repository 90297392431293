import React from 'react'
import { Switch } from 'react-router-dom'
import { pagesData } from './pagesData'
import PiplineLayout from './components/PiplineLayout'

const PipelineBoardScreenNew = () => {
  return (
    <Switch>
      {
        pagesData().map((page, index) => (
          <PiplineLayout key={index}>
            {page.component}
          </PiplineLayout>
        ))
      }
    </Switch>
  )
}

export default PipelineBoardScreenNew