import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { MarketCodesGroupedResponseModel } from 'models/hp/market-research/market-research.model'
import { BigHint, SmallHint } from './components/ListingHints'
import { CodesGroup, CodeType } from './CodesGroup'
import Wrapper from 'components/v2/Wrapper'

export interface ICode {
  name: string
  code: string
  type: string
}

export interface ICodeMapper {
  [key: string]: ICode | undefined
}

interface Props {
  listingData: MarketCodesGroupedResponseModel | undefined
  listingLoading: boolean,
  codes: ICodeMapper
  addCode: (company: ICode) => void
}

let initLoading = true

const Listing = ({listingData, listingLoading, codes, addCode}: Props) => {
  const [hintClosed, setHintClosed] = React.useState(false)

  const renderBody = () => {
    if (listingLoading) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <CircularProgress color='primary'/>
        </Grid>
      )
    }

    if (!initLoading && !listingData) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <Typography variant='h4'>Nothing was found, please try another filters</Typography>
        </Grid>
      )
    }

    if (initLoading) {
      if (listingLoading || (listingData)) {
        initLoading = false
      } else {
        if (hintClosed) {
          initLoading = false
          return (
            <Wrapper>
              <SmallHint />
            </Wrapper>
          )
        }
        return (
          <Wrapper>
            <BigHint close={() => setHintClosed(true)} />
          </Wrapper>
        )
      }
    }

    if (listingData) {
      return (
        <div>
          <Grid container className={styles['strong-block']}>
            <Grid item md={6}>
              <CodesGroup
                type={CodeType.naicsCodes}
                key='Strongly matching NAICS codes'
                title='Strongly matching NAICS codes'
                codes={listingData.matched.naicsCodes}
                addCode={addCode}
                selectedCodes={codes}
              />
            </Grid>

            <Grid item md={6}>
              <CodesGroup
                type={CodeType.pscCodes}
                key='Strongly matching PSC codes'
                title={'Strongly matching PSC codes'}
                codes={listingData.matched.pscCodes}
                addCode={addCode}
                selectedCodes={codes}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6}>
              <CodesGroup
                type={CodeType.naicsCodes}
                key='Other related NAICS codes'
                title={'Other related NAICS codes'}
                codes={listingData.related.naicsCodes}
                addCode={addCode}
                selectedCodes={codes}
              />
            </Grid>
            <Grid item md={6}>
              <CodesGroup
                type={CodeType.pscCodes}
                key='Other related PSC codes'
                title={'Other related PSC codes'}
                codes={listingData.related.pscCodes}
                addCode={addCode}
                selectedCodes={codes}
              />
            </Grid>
          </Grid>
        </div>

      )
    }
  }

  useEffect(() => {
    return () => {
      initLoading = true
    }
  }, [])

  if (listingData && initLoading) {
    initLoading = false
  }

  return (
    <Grid
      container
      spacing={2}
      className={styles['list']}
      direction='column'
      wrap='nowrap'
      justifyContent='flex-start'
    >
      {renderBody()}
    </Grid>
  )
}

export default Listing