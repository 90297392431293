import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class EndingSoonModel {
  @jsonMember({constructor: Number})
  amount: number = 0

  @jsonMember({constructor: String})
  pscPrefix: string = ''

  @jsonMember({constructor: String})
  pscShortName: string = ''
}
