import { Checkbox, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { MarketCodeItem } from 'models/hp/market-research/market-research.model'
import Divider from 'components/v2/Divider'
import { ICode, ICodeMapper } from './index'

export enum CodeType {
  pscCodes = 'pscCodes',
  naicsCodes = 'naicsCodes'
}

interface Props {
  codes: MarketCodeItem[]
  title: string
  type: CodeType
  addCode: (code: ICode) => void
  selectedCodes: ICodeMapper
}

export const CodesGroup = ({title, addCode, codes, selectedCodes, type}: Props) => {
  return (
    <div key={title}>
      <Typography variant='h4'>{title}</Typography>
      <Divider gutterBottom/>
      {
        codes.length > 0 ? codes.map(code => {
          const mapCode = code as ICode
          mapCode.type = type
          return (
            <Grid
              key={code.code}
              container
              alignItems='center'
              wrap='nowrap'
            >
              <Grid item>
                <Checkbox
                  color='primary'
                  checked={!!selectedCodes[code.code]}
                  onClick={() => addCode(mapCode)}
                />
              </Grid>
              <Grid item md={11}>
                <Typography variant='h5'>
                  {[code.code, code.name].filter(x => !!x).join(': ')}
                </Typography>
              </Grid>
            </Grid>
          )
        }) : (
          <Typography variant='h5'>Not found</Typography>
        )
      }
    </div>
  )
}