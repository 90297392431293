import React from 'react'
import styles from './index.module.scss'
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import TextField from 'components/v2/TextField'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { userGroupInvitations, userGroupListLoadingSelector } from 'store/common/user-group/selectors'
import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { createUserGroupInvitation, deleteUserInvitation, getUserGroupIvitations } from 'store/common/user-group/actons'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import SvgIcon from 'components/SvgIcon'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'

interface InviteTeamForm {
  email: string
}

interface Props {
  updateCurrentUserList: () => void
}

const InviteTeam = ({ updateCurrentUserList }: Props) => {
  const theme = useTheme()
  const invitations = useSelector(userGroupInvitations)
  const invitationsLoading = useSelector(userGroupListLoadingSelector)
  const dispatch = useDispatch()
  const notification = useNotification()
  const user = useSelector(userSelector) as IUserBaseModel
  
  const onSubmit = ({email}: InviteTeamForm, {resetForm}: FormikHelpers<InviteTeamForm>) => {
    createUserGroupInvitation(email).then((res) => {
      if (res.message) { // lazy user created (wasn't found in a database)
        notification.show(res.message, NotificationTypeList.Success)
        updateCurrentUserList()
      } else { // found in a database (just invite in a group)
        resetForm()
        getUserGroupIvitations(dispatch)()
      }
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
      resetForm()
    })
  }

  const removeRequest = (id: string) => {
    deleteUserInvitation(id).then(() => {
      getUserGroupIvitations(dispatch)()
    })
  }
  
  return (
    <>
      <Typography variant='h4'>Invite team members & outside advisors</Typography>
      <Paper className={styles['user-group-paper']}>
        <Formik 
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required(ERROR_MESSAGES.Field.Required('Email'))
              .email(ERROR_MESSAGES.Field.NotValid('Email')),
          })}
          onSubmit={onSubmit}
          initialErrors={{
            email: ''
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            errors,
            isValid,
            isSubmitting,
          }: FormikProps<InviteTeamForm>) =>  (
            <Grid container spacing={2} alignItems='center' >
              <Grid item md={9}>
                <TextField
                  disabled={user.userGroup?.role !== 'manager'}
                  onBlur={handleBlur('email')}
                  error={!!touched.email && !!errors.email}
                  helperText={!!touched.email && !!errors.email && errors.email}
                  value={values.email}
                  onChange={handleChange('email')}
                  className={styles['field']}
                  label='Email'
                  fullWidth
                  variant='filled'
                />
              </Grid>
              <Grid item md={3}>
                <Button   
                  variant='contained' 
                  color='primary'
                  fullWidth
                  disabled={!isValid || isSubmitting || user.userGroup?.role !== 'manager'} 
                  onClick={() => handleSubmit()}
                >
                    Send
                </Button>
              </Grid>
            </Grid>
          )
          }
        </Formik>
        <Typography className={styles['user-group-invitations-list']} variant='subtitle1'>Your invitations list:</Typography>
        {
          invitations.length && !invitationsLoading ?
            <Grid>
              {
                invitations.map(el => (
                  <Grid container spacing={2} justifyContent='space-between' alignItems='center' className={styles['user-item']} key={el.id}>
                    <Grid item md={10}>
                      <Typography variant='body2'>{el.invitedUser.firstName} {el.invitedUser.lastName}</Typography>
                      <Typography variant='body2'>{el.invitedUser.email}</Typography>
                    </Grid>
                    <Grid>
                      <Button disabled={user.userGroup?.role !== 'manager'} onClick={() => removeRequest(el.id)}>
                        <SvgIcon name='multiply' width={18} height={18} color={theme.palette.grey['500']}/>
                      </Button>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid> : null
        }
        { !invitations.length && !invitationsLoading &&
            <Typography align='center' variant='subtitle1'>No invitations found.</Typography>
        }
        {
          invitationsLoading && 
          <Grid container alignItems='center' justifyContent='center'>
            <CircularProgress size={30}/>
          </Grid>
        }
      </Paper>
    </>
       
  )
}

export default InviteTeam