import React from 'react'
import Modal from 'components/v2/Modal'
import TextField from 'components/v2/TextField'

interface Props {
  onClose: () => void
  onConfirm: (token: string) => void
}

const CreateTokenModal = ({onClose, onConfirm}: Props) => {
  const [token, setToken] = React.useState('')

  const handleConfirm = () => {
    onConfirm(token)
  }

  return (
    <Modal
      title='Add new token'
      open={true}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <TextField
        label={'Token'}
        variant='filled'
        onChange={(e) => setToken(e.target.value)}
        value={token}
        fullWidth
      />
    </Modal>
  )
}

export default CreateTokenModal