import { IGroupCodeData, IGroupedAgenciesForm, ILocationFormData } from './reducers'

export const SEARCH_ID_NAME_KEYWORD = 'SEARCH_ID_NAME_KEYWORD'
export const SEARCH_CONTRACT_TYPES = 'SEARCH_CONTRACT_TYPES'
export const SEARCH_GROUPED_CODES = 'SEARCH_GROUPED_CODES'
export const SEARCH_PROJECT_TYPES = 'SEARCH_PROJECT_TYPES'
export const SEARCH_AGENCIES = 'SEARCH_AGENCIES'
export const SEARCH_LOCATIONS = 'SEARCH_LOCATIONS'
export const SEARCH_SET_ASIDES = 'SEARCH_SET_ASIDES'
export const SEARCH_CLEAR = 'SEARCH_CLEAR'

export const setSearchIdNameKeywords = (id: number, name: string, keywords: string) => ({
  type: SEARCH_ID_NAME_KEYWORD,
  payload: { id, name, keywords }
})

export const setSearchContractTypes = (contractTypeIds: number[]) => ({
  type: SEARCH_CONTRACT_TYPES,
  payload: { contractTypeIds }
})

export const setSearchGroupedPscCodes = (groupTypes: { [key: string]: IGroupCodeData }, all: boolean) => ({
  type: SEARCH_GROUPED_CODES,
  payload: { groupTypes: { data: groupTypes, all } }
})

export const setSearchProjectTypeIds = (projectTypeIds: number[]) => ({
  type: SEARCH_PROJECT_TYPES,
  payload: { projectTypeIds }
})

export const setAgenciesAction = (data: IGroupedAgenciesForm) => ({
  type: SEARCH_AGENCIES,
  payload: { agencies: data }
})

export const setSearchLocationAction = (locations: ILocationFormData) => ({
  type: SEARCH_LOCATIONS,
  payload: { locations }
})

export const setSearchSetAsideAction = (setAsideIds: number[]) => ({
  type: SEARCH_SET_ASIDES,
  payload: { setAsideIds }
})

export const setSearchEmpty = () => ({
  type: SEARCH_CLEAR
})