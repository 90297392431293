import { Dispatch } from 'redux'
import { groupTypes, ICompanySearchRecord } from '../search/reducers'
import { request } from 'utils/http'
import {
  CompanyProfileClusteringResponseModel,
  CompanyProfileDetailModel, CompanyProfileInlineListResponseModel,
  CompanyProfilePreviewModel,
  CompanyProfilePreviewModelResponse
} from 'models/hp/company-profiles/base/company-profile.model'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { ICompanyProfileParams } from './reducers'
import { BaseResponseModel } from 'models/base/base.model'

export const COMPANY_PROFILE_LIST = 'COMPANY_PROFILE_LIST'
export const COMPANY_PROFILE_LIST_ERROR = 'COMPANY_PROFILE_LIST_ERROR'
export const COMPANY_PROFILE_LIST_SUCCESS = 'COMPANY_PROFILE_LIST_SUCCESS'
export const COMPANY_PROFILE_LIST_APPEND_SUCCESS = 'COMPANY_PROFILE_LIST_APPEND_SUCCESS'
export const COMPANY_PROFILE_LIST_LOADED = 'COMPANY_PROFILE_LIST_LOADED'
export const COMPANY_PROFILE_FILTER = 'COMPANY_PROFILE_FILTER'
export const COMPANY_LIKE_ACTION = 'COMPANY_LIKE_ACTION'
export const COMPANY_PROFILE_LIST_RESET = 'COMPANY_PROFILE_LIST_RESET'

export const COMPANY_RANKED_LIST = 'COMPANY_RANKED_LIST'

// Actions for groups
export const getCompanyGroupFilteredAction = (dispatch: Dispatch) => async (type: groupTypes, params: ICompanyProfileParams, body: ICompanySearchRecord): Promise<void> => {
  dispatch({
    type: COMPANY_PROFILE_LIST
  })
  dispatch({
    type: COMPANY_PROFILE_FILTER,
    payload: params
  })
  try {
    const response = await request<CompanyProfileClusteringResponseModel>(RequestMethods.Post, Paths.Hp.CompanyProfiles.getCluster, {
      schema: CompanyProfileClusteringResponseModel,
      params,
      body: {...body, clusterType: type, location: body.stateCodes}
    })
    dispatch({
      type: COMPANY_PROFILE_LIST_SUCCESS,
      payload: response.data.data.items
    })
    dispatch({
      type: COMPANY_RANKED_LIST,
      payload: response.data.data.rankedItems
    })
  } catch (error) {
    dispatch({
      type: COMPANY_PROFILE_LIST_ERROR
    })
    throw error.response.data.errors
  }
}

export const appendCompanyGroupFilteredAction = (dispatch: Dispatch) => async (type: groupTypes, params: ICompanyProfileParams, body: ICompanySearchRecord) => {
  dispatch({
    type: COMPANY_PROFILE_FILTER,
    payload: params
  })
  try {
    const response = await request<CompanyProfileClusteringResponseModel>(RequestMethods.Post, Paths.Hp.CompanyProfiles.getCluster, {
      schema: CompanyProfileClusteringResponseModel,
      params,
      body: {...body, clusterType: type, location: body.stateCodes}
    })
    dispatch({
      type: COMPANY_PROFILE_LIST_APPEND_SUCCESS,
      payload: response.data.data.items
    })
  } catch (error) {
    dispatch({
      type: COMPANY_PROFILE_LIST_ERROR
    })
    throw error.response.data.errors
  }
}

export const getCompanyFilteredListAction = (dispatch: Dispatch) => async (params: ICompanyProfileParams, body: ICompanySearchRecord): Promise<void> => {
  dispatch({
    type: COMPANY_PROFILE_LIST
  })
  dispatch({
    type: COMPANY_PROFILE_FILTER,
    payload: params
  })
  try {
    const response = await request<CompanyProfilePreviewModelResponse>(RequestMethods.Post, Paths.Hp.CompanyProfiles.getAll, {
      schema: CompanyProfilePreviewModelResponse,
      params,
      body: body
    })
    dispatch({
      type: COMPANY_PROFILE_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_PROFILE_LIST_ERROR
    })
    throw error.response.data.errors
  }
}

export const appendCompanyFilteredListAction = (dispatch: Dispatch) => async (params: ICompanyProfileParams, body: ICompanySearchRecord) => {
  dispatch({
    type: COMPANY_PROFILE_FILTER,
    payload: params
  })
  try {
    const response = await request<CompanyProfilePreviewModelResponse>(RequestMethods.Post, Paths.Hp.CompanyProfiles.getAll, {
      schema: CompanyProfilePreviewModelResponse,
      params,
      body: body
    })
    dispatch({
      type: COMPANY_PROFILE_LIST_APPEND_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_PROFILE_LIST_ERROR
    })
    throw error.response.data.errors
  }
}

export const setCompanyListingLoadedAction = (dispatch: Dispatch) => (state: boolean) => {
  dispatch({
    type: COMPANY_PROFILE_LIST_LOADED,
    payload: state
  })
}

export const setCompanyCategorized = (dispatch: Dispatch) => async (company: CompanyProfilePreviewModel | CompanyProfileDetailModel): Promise<void> => {
  try {
    await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.CompanyProfiles.setCategorized(company.duns), {
      body: {type: 'like'},
      schema: BaseResponseModel
    })
    dispatch({
      type: COMPANY_LIKE_ACTION,
      payload: {...company, categorizationType: 'like'}
    })
  } catch (error) {
    throw  error.response.data.errors
  }
}

export const setGroupCompanyCategorized = async (company: number[]): Promise<void> => {
  try {
    await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.CompanyProfiles.setGroupCategorized(), {
      body: {type: 'like', duns: company},
      schema: BaseResponseModel
    })
  } catch (error) {
    throw  error.response.data.errors
  }
}

export const getCompanyListByName = async (query: string) => {
  const res = await request<CompanyProfileInlineListResponseModel>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getByName(query), {
    schema: CompanyProfileInlineListResponseModel
  })
  return res.data.data
}