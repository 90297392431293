import React, { ChangeEvent } from 'react'
import { Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import TextField from 'components/v2/TextField'
import { AgencyModel } from 'models/hp/agencies/agencies.model'
import { FilterOption } from 'models/hp/search/search.model'
import { searchAgenciesByDepartmentAction } from 'store/hp/agency/actions'

let searchDebounceTimer: number | null = null

interface Props {
  onChange: (value: string) => void
  value: string
}

const DepartmentSelect = ({onChange, value}: Props) => {
  const [options, setOptions] = React.useState<Array<AgencyModel | FilterOption>>([])

  const loadOptions = async (value: string) => {
    const response = await searchAgenciesByDepartmentAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const val = value
    ? options.find(option => option.departmentHP === value || (option as FilterOption).subtierHP === value)
      || { id: 123, departmentHP: value, subtierHP: value, text: value }
    : null

  return (
    <Autocomplete
      id='some-autocomplete'
      options={options}
      filterOptions={options => options}
      value={val}
      getOptionLabel={(option) => (option as FilterOption).subtierHP || option?.departmentHP || ''}
      renderInput={params => (
        <TextField
          {...params}
          variant='filled'
          label='Select customer'
          onChange={onSearch}
        />
      )}
      renderOption={(option) => {
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => onChange((option as FilterOption).subtierHP || option.departmentHP!)}
          >
            <Typography variant='h5'>{option.text}</Typography>
          </div>
        )
      }}
    />
  )
}

export default DepartmentSelect