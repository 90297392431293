import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { searchSelector } from 'store/hp/market-research/selectors'
import { useNotification } from 'context/NotificationContext'
import { getKeywordsResults } from 'store/hp/market-research/actions'
import { addSearchKeywords as addSearchKeywordsAction } from 'store/hp/search/actions'
import { HpMarketResearchKeywordResult } from 'models/hp/rfp/rfp.model'
import { Grid, Typography, Checkbox, Button, CircularProgress } from '@material-ui/core'
import BorderLinearProgress from '../../components/BorderLinearProgress'
import ActionsBar from '../../components/ActionsBar'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { marketResearchFilterPercentage } from 'helpers/search'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

interface CheckedKeywords {
  [key: string]: boolean
}

const KeywordsResults = () => {
  const history = useHistory()
  const search = useSelector(searchSelector) as SearchModel
  const { show } = useNotification()

  const [keywordsResults, setKeywordsResults] = React.useState<HpMarketResearchKeywordResult[]>([])
  const [checkedKeywords, setCheckedKeywords] = React.useState<CheckedKeywords>({})

  const [loading, setLoading] = React.useState(false)

  const handleChange = (keyword: string) => () => {
    setCheckedKeywords(val => ({
      ...val,
      [keyword]: !val[keyword]
    }))
  }

  const addKeywords = async () => {
    const keywords: string[] = Object.keys(checkedKeywords).reduce((acc, item) => {
      if (checkedKeywords[item]) {
        (acc as string[]).push(item)
      }
      return acc
    }, [])
    await addSearchKeywordsAction(search.id, keywords)
  }

  const handleStop = async () => {
    try {
      await addKeywords()
      show('Changes saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleContinue = async () => {
    try {
      await addKeywords()
      history.push('/user/market-research/codes')
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    setLoading(true)
    getKeywordsResults(search.id)
      .then(res => {
        setKeywordsResults(res)
        setCheckedKeywords(res.reduce((acc, item) => {
          acc[item.keyword] = marketResearchFilterPercentage.handler(item) > marketResearchFilterPercentage.percent
          return acc
        }, {} as CheckedKeywords))
      })
      .finally(() => setLoading(false))
  }, [search.id])

  if (loading) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <CircularProgress color='primary' />
      </Grid>
    )
  }

  return (
    <div>
      <Typography variant='h2'>Assess the keywords you chose</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_SELECT_KEYWORDS}/>
            <Typography variant='h4'>
              Select your Keywords
            </Typography>
            <Typography>
              • Now lets see how effective our different keywords were at getting the right kinds of contracts
            </Typography>
            <Typography>
              • The bars show the ratio of contracts you liked to trashed for each of your keywords
            </Typography>
            <Typography>
              • If there are keywords that performed poorly (you trashed all/almost all of those contracts)
              consider unselecting them
            </Typography>
            <Typography gutterBottom>
              • If you want to edit the keywords you’ve chosen click the “Choose new keywords” button
              and start again
            </Typography>
          </Grid>
        </Grid>

        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>Ratio of Contracts you Liked v. Trashed by keyword:</Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']} item md={12}>
            <Grid className={styles['form']}>
              <Grid container alignItems='center'>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                  <div className={styles['liked-indicator']} />
                  <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Liked</Typography>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={styles['trashed-indicator']} />
                  <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Trashed</Typography>
                </Grid>
              </Grid>
              <div className={styles['analys-items-wrapper']}>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item md={1} style={{ paddingLeft: '7px' }}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Use</Typography>
                  </Grid>
                  <Grid item md={2} style={{paddingRight: '10px'}}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Keyword</Typography>
                  </Grid>
                  <Grid item md={9}>
                    <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Liked v. Trashed</Typography>
                  </Grid>
                </Grid>
                {keywordsResults.map((keyword, idx) => (
                  <Grid container justifyContent='space-between' alignItems='center' key={idx}>
                    <Grid item md={1} style={{marginRight: '-40px'}}>
                      <Checkbox
                        name='select-keywords'
                        color={'primary'}
                        value={keyword.keyword}
                        onChange={handleChange(keyword.keyword)}
                        checked={checkedKeywords[keyword.keyword] || false}
                      />
                    </Grid>
                    <Grid item md={2} style={{paddingRight: '10px'}}>
                      <Typography variant='body1'>{keyword.keyword}</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <BorderLinearProgress
                        value={marketResearchFilterPercentage.handler(keyword)}
                      />
                    </Grid>
                  </Grid>
                ))}
              </div>

              <Grid item md={12}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <Button
                      size='large'
                      color='primary'
                      variant='contained'
                      onClick={() => history.push('/user/market-research/keywords')}
                    >
                      Choose new keywords
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <ActionsBar
                handleContinue={handleContinue}
                handleStop={handleStop}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default KeywordsResults