import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class BaseStateModel {
  @jsonMember({ constructor: String })
  code: string = ''

  @jsonMember({ constructor: Number })
  id: number = 0

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Number })
  regionId: number = 0
}

@jsonObject
export class BaseCityModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  code: string = ''

  @jsonMember({ constructor: String })
  name: string = ''
}
