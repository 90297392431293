import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import styles from './index.module.scss'

const MarketingConcepts = () => {
  return (
    <div className={styles['inner-block']}>
      <Typography variant={'h4'}>Will you start building and releasing any broadcast content to build you brand:</Typography>
      <div>
        <Grid container>
          <Grid item md={3} style={{padding: '10px'}}>
            <Typography variant={'h4'}>Podcast</Typography>
          </Grid>
          <Grid item md={6}>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Episode Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Episode Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Episode Idea'}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={3} style={{padding: '10px'}}>
            <Typography variant={'h4'}>Articles/ Guides</Typography>
          </Grid>
          <Grid item md={6}>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Article/Guide Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Article/Guide Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Article/Guide Idea'}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={3} style={{padding: '10px'}}>
            <Typography variant={'h4'}>Research Projects</Typography>
          </Grid>
          <Grid item md={6}>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Idea'}/>
            </Grid>
            <Grid className={styles['input-row-item']}>
              <input value={''} placeholder={'Idea'}/>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default MarketingConcepts