import React from 'react'
import SvgIcon from 'components/SvgIcon'
import { useSelector } from 'react-redux'
import { userBaseResponseSelector, userPaymentPlanSelector } from 'store/common/user/selectors'
import { PLAN } from 'constants/payment'
import { PAYMENT } from 'utils/const'
import PaypalButtonWrapper from 'components/PaypalButtonWrapper'
import CancelSubscriptionButton from 'components/PaypalButtonWrapper/CancelSubscriptionButton'
import styles from './index.module.scss'

const PaymentPlan = () => {
  const plan = useSelector(userPaymentPlanSelector)
  const user = useSelector(userBaseResponseSelector)

  const isCurrentPlan = (name: PLAN | null) => {
    return plan === name
  }

  return (
    <div className={styles['container']}>
      <div className={styles['opportunity_scout']}>
        <div className={styles['pricing']}>
          <h2 className={styles['pricing__title']}>
          Opportunity Scout
          </h2>
          <h3 className={styles['pricing__subtitle']}>
          Opportunity Scout empowers you with professional federal sales intelligence, whether you’re new to the
          federal market or have decades of experience. Find, analyze and track contracts from your phone or laptop
          with our intuitive interface, process manager, and decision wizards.
          </h3>
          <div className={styles['pricing__tables']}>
            <div className={[styles['pricing__table'], isCurrentPlan(null) && styles['active']].join(' ').trim()}>
              <div className={styles['pricing__table__top']}>
                <div className={styles['pricing__table__title']}>Free</div>
                <div className={styles['pricing__table__price']}><span>$0</span>/Month</div>
              </div>

              <div className={styles['pricing__table__features']}>
                <p>For teams exploring the federal market</p>
                <strong>Search</strong>
                <ul>
                  <li>
                    <SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>
                  Auto search creator
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All basic
                  search fields
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of saved
                  searches: 2 per team
                  </li>
                </ul>
                <strong>Pipeline Management</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Like/Archive
                  opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of items in
                  your liked list: 10
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Push
                  notifications to your phone
                  </li>
                </ul>
                <strong>Opportunity Information</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All SAM data
                  </li>
                </ul>
                <strong>Evaluation</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculators
                  </li>
                </ul>
                <strong>Actions</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Share an
                  opportunity
                  </li>
                </ul>
                <strong>Home Screen</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>News</li>
                </ul>
                <strong>Ads</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Mandatory</li>
                </ul>
              </div>
            </div>

            <div className={[styles['pricing__table'], isCurrentPlan(PLAN.SUB) && styles['active']].join(' ').trim()}>
              <div className={styles['pricing__table__top']}>
                <div className={styles['pricing__table__title']}>Pro</div>
                <div className={styles['pricing__table__price']}><span>$20</span>/Month</div>
              </div>

              <div className={styles['pricing__table__features']}>
                <p>For teams looking for their first win</p>
                <strong>Search</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Auto search
                  creator
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All basic
                  search fields
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of saved
                  searches: 4 per team
                  </li>
                </ul>
                <strong>Pipeline Management</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Like/Archive
                  opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of items in
                  your liked list: 20
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Push
                  notifications to your phone
                  </li>
                </ul>
                <strong>Opportunity Information</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All SAM data
                  </li>
                </ul>
                <strong>Evaluation</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculators
                  </li>
                </ul>
                <strong>Actions</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Team
                  discussion
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Share an
                  opportunity
                  </li>
                </ul>
                <strong>Home Screen</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Pipeline
                  analytics
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>News</li>
                </ul>
                <strong>Ads</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Mandatory</li>
                </ul>
              </div>
              {
                !isCurrentPlan(PLAN.SUB) ? (
                  <div className={styles['pricing__table__bottom']}>
                    <PaypalButtonWrapper
                      userId={user?.data?.id}
                      planId={PAYMENT.PLAN_STANDARD}
                      planName='Pro'
                    />
                  </div>
                ) : (
                  <div className={styles['pricing__table__bottom']}>
                    <CancelSubscriptionButton />
                  </div>
                )
              }
            </div>

            <div className={[styles['pricing__table'], isCurrentPlan(PLAN.PRIME) && styles['active']].join(' ').trim()}>
              <div className={styles['pricing__table__top']}>
                <div className={styles['pricing__table__title']}>Growth</div>
                <div className={styles['pricing__table__price']}><span>$100</span>/Month</div>
              </div>

              <div className={styles['pricing__table__features']}>
                <p>For established small businesses ready to grow</p>
                <strong>Search</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Auto search
                  creator
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All basic
                  search fields
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of saved
                  searches: Unlimited
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Include
                  projected opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Search for
                  Non-beta SAM results
                  </li>
                </ul>
                <strong>Pipeline Management</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Like/Archive
                  opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of items in
                  your liked list: Unlimited
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Segmented
                  pipeline
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Push
                  notifications to your phone
                  </li>
                </ul>
                <strong>Opportunity Information</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All SAM data
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Key text
                  extraction
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Estimated
                  value
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Estimated
                  collaborators/ competitors
                  </li>
                </ul>
                <strong>Evaluation</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculators
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculator
                  auto fill
                  </li>
                </ul>
                <strong>Actions</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Team
                  discussion
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Share an
                  opportunity
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Download a
                  Report
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Create your
                  own opportunity
                  </li>
                </ul>
                <strong>Home Screen</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Pipeline
                  analytics
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>News</li>
                </ul>
                <strong>Ads</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Optional</li>
                </ul>
              </div>
              {
                !isCurrentPlan(PLAN.PRIME) ? (
                  <div className={styles['pricing__table__bottom']}>
                    <PaypalButtonWrapper
                      userId={user?.data?.id}
                      planId={PAYMENT.PLAN_GROWTH}
                      planName='Growth'
                    />
                  </div>
                ) : (
                  <div className={styles['pricing__table__bottom']}>
                    <CancelSubscriptionButton />
                  </div>
                )
              }
            </div>

            <div className={[styles['pricing__table'], isCurrentPlan(PLAN.LARGE) && styles['active']].join(' ').trim()}>
              <div className={styles['pricing__table__top']}>
                <div className={styles['pricing__table__title']}>Enterprise</div>
                <div className={styles['pricing__table__price']}><span>$500</span>/Month</div>
              </div>

              <div className={styles['pricing__table__features']}>
                <p>For multi-division teams focused on expansion</p>
                <strong>Search</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Auto search
                    creator
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All basic
                    search fields
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of saved
                    searches: Unlimited
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Include
                    projected opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Search for
                    Non-beta SAM results
                  </li>
                </ul>
                <strong>Pipeline Management</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Like/Archive
                    opportunities
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/># of items in
                    your liked list: Unlimited
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Segmented
                    pipeline
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Push
                    notifications to your phone
                  </li>
                </ul>
                <strong>Opportunity Information</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>All SAM data
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Key text
                    extraction
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Estimated
                    value
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Estimated
                    collaborators/ competitors
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Deep content
                    analysis
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Wired contract
                    indicators
                  </li>
                </ul>
                <strong>Evaluation</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculators
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Calculator
                    auto fill
                  </li>
                </ul>
                <strong>Actions</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Team
                    discussion
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Share an
                    opportunity
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Download a
                    Report
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Create your
                    own opportunity
                  </li>
                </ul>
                <strong>Home Screen</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Pipeline
                    analytics
                  </li>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>News</li>
                </ul>
                <strong>Ads</strong>
                <ul>
                  <li><SvgIcon className={styles['icon']} height={15} width={15} name='checkmark-yellow'/>Optional</li>
                </ul>
              </div>
              {
                !isCurrentPlan(PLAN.LARGE) ? (
                  <div className={styles['pricing__table__bottom']}>
                    <PaypalButtonWrapper
                      userId={user?.data?.id}
                      planId={PAYMENT.PLAN_ENTERPRISE}
                      planName='Enterprise'
                    />
                  </div>
                ) : (
                  <div className={styles['pricing__table__bottom']}>
                    <CancelSubscriptionButton />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentPlan
