import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { UserBaseModel } from 'models/common/user/user.model'
import Modal from 'components/v2/Modal'
import TextField from 'components/v2/TextField'
import { FormControl, Grid, Button, Typography } from '@material-ui/core'
import SearchUpdater from './SearchUpdater'

interface Props {
  handleSave: (name: string, email?: string) => Promise<void>
  handleUpdateSearch: (searchId: number) => Promise<void>
  onClose: () => void
  user?: UserBaseModel
  createSearchTitle?: string
  updateSearchTitle?: string
}

const NameModal = ({
  handleSave,
  onClose,
  handleUpdateSearch,
  user,
  createSearchTitle = 'Save these keywords as a new search',
  updateSearchTitle = 'Update a saved search with these keywords'
}: Props) => {
  const [loading, setLoading] = React.useState(false)

  const validation = {
    name: Yup.string().required('Field is required'),
    email: Yup.string().email('Field is invalid').required('Field is required')
  }
  if (user) {
    delete validation.email
  }
  const validationSchema = Yup.object(validation)

  const save = async (name: string, email?: string) => {
    setLoading(true)
    handleSave(name, email)
      .finally(() => setLoading(false))
  }

  return (
    <Formik
      initialValues={{name: '', email: ''}}
      onSubmit={values => save(values.name, values.email)}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Modal
          onClose={onClose}
          open={true}
          size='xl'
        >
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography variant='h4'>{createSearchTitle}</Typography>
              <FormControl fullWidth style={{paddingBottom: '12px'}}>
                <TextField
                  label='Search name'
                  variant='filled'
                  value={values.name}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  fullWidth
                  error={!!(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </FormControl>
              {!user && (
                <FormControl fullWidth>
                  <TextField
                    label='Your email'
                    variant='filled'
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    fullWidth
                    error={!!(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              )}
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item md={6}>
              <SearchUpdater
                title={updateSearchTitle}
                handleSave={handleUpdateSearch}
              />
            </Grid>
          </Grid>
        </Modal>
      )}
    </Formik>
  )
}

export default NameModal