import React from 'react'
import styles from '../index.module.scss'
import { Grid, Typography } from '@material-ui/core'
import {
  CompanyProfileRankedResponseModel,
  CompanyProfileWithRankedModel
} from 'models/hp/company-profiles/base/company-profile.model'
import RankingItem from 'components/v2/Company/RankingItem'

interface Props {
  rankedList: CompanyProfileRankedResponseModel
  onGoToDetails: (data: { name?: string, duns: string }) => void
}

const RankedList = ({ rankedList, onGoToDetails }: Props) => {
  return (
    <div className={styles['ranking-list']}>
      <Typography style={{ textAlign: 'center', margin: '15px 0' }} variant='h3'>
        Company capabilities search
      </Typography>
      {rankedList.data.length > 0 ? rankedList.data.map((item: CompanyProfileWithRankedModel, idx: number) => (
        <RankingItem
          key={idx}
          rankItem={item}
          onClick={() => onGoToDetails({
            duns: item.duns,
            name: item.name
          })}
        />
      )) : <Grid container justifyContent='center' alignItems='center'>
        <Typography>Nothing was found, please try another filters</Typography>
      </Grid>}
    </div>
  )
}

export default RankedList