import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory } from 'react-router-dom'
import { Paper, Button, Grid, Typography } from '@material-ui/core'
import { MarketAgency } from 'models/hp/agencies/agencies.model'
import { getMarketCustomers } from 'store/hp/market-research/actions'

const Sap = () => {
  const history = useHistory()

  const [customers, setCustomers] = React.useState<MarketAgency[]>([])

  useEffect(() => {
    getMarketCustomers()
      .then((data) => setCustomers(data))
      .catch(console.log)
  }, [])

  return (
    <Paper className={styles['paper']}>
      <Typography variant='h4'>Selected customers:</Typography>
      <Grid container spacing={2} style={{padding: '12px 0 24px'}}>
        {
          customers.length > 0 ? customers.map(customer => (
            <Grid
              key={customer.id}
              md={4}
              item
            >
              <Typography><b>Department:</b> {customer.department}</Typography>
              <Typography><b>Sub-agency:</b> {customer.subTier || '---'}</Typography>
            </Grid>
          )) : (
            <Grid item>
              <Typography>No customer selected yet</Typography>
            </Grid>
          )
        }
      </Grid>
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/user/market-research/tools/sap')}
      >
        SAP/Micropurchase tool
      </Button>
    </Paper>
  )
}

export default Sap