import { Dispatch } from 'redux'
import { ILocationResponseModel } from 'models/hp/location/location.interface'
import { LocationResponseModel } from 'models/hp/location/location.model'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'
import { setOptionCount } from 'common/filter'
import { UPDATE_NORMALIZED_OPTIONS } from '../search/actions'

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR'
export const GET_LOCATION_COUNT = 'LOCATION_COUNT'

export const getLocationsAction = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: GET_LOCATIONS
  })
  try {
    const response = await request<ILocationResponseModel>(RequestMethods.Get, Paths.Hp.Locations.getAll, {
      schema: LocationResponseModel
    })
    
    const normalizedLocations = response.data.data.reduce((acc, item) => {
      const subItems = item.sub.reduce((acc, subItem) => {
        return {
          ...acc,
          [subItem.id]: subItem
        }
      }, {})
      return {
        ...acc,
        ...subItems
      }
    }, {})

    dispatch({
      type: UPDATE_NORMALIZED_OPTIONS,
      payload: {
        key: 'locations',
        data: normalizedLocations
      }
    })

    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: response.data.data
    })
    const count = response.data.data.reduce((acc, item) => {
      return item.sub.length + acc
    }, 0)
    setOptionCount(dispatch)(GET_LOCATION_COUNT, [], count)
  } catch (error) {
    dispatch({
      type: GET_LOCATIONS_ERROR
    })
    throw error.response.data.errors
  }
}
