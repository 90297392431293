import { CountryModel } from 'models/common/location/country/country.model'
import { StateModel } from 'models/common/location/state/state.model'
import { CityModel } from 'models/common/location/city/city.model'
import { IPlaceOfPerfModel } from './place-of-perf.interface'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class PlaceOfPerfModel implements IPlaceOfPerfModel {
  @jsonMember({ constructor: CityModel })
  city?: CityModel

  @jsonMember({ constructor: CountryModel })
  country?: CountryModel

  @jsonMember({ constructor: StateModel })
  state?: StateModel
}