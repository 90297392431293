import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Education from 'modules/Main/Education/pages/main'
import Funding from 'modules/Main/Education/pages/funding'
import RelationshipWorksheet from 'modules/Main/Education/pages/funding/RelationshipWorksheet'
import EducationDualVc from 'modules/Main/Education/pages/dual-vc'
import EducationDashboard from 'modules/Main/Education/pages/dashboard'
import CustomerFinder from './pages/CustomerFinder'
import ProtectedRoute from 'modules/ProtectedRoute'
import PartnersFinder from './pages/PartnersFinder'

const EducationRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path='/education'>
        <Education />
      </Route>
      <Route exact={true} path='/education/funding'>
        <Funding />
      </Route>
      <Route exact={true} path='/education/funding/worksheet'>
        <RelationshipWorksheet />
      </Route>
      <Route exact={true} path='/education/dual-use'>
        <EducationDualVc />
      </Route>
      <ProtectedRoute exact={true} path='/education/relationship-map'>
        <EducationDashboard />
      </ProtectedRoute>
      <ProtectedRoute exact={true} path='/education/customer-finder'>
        <CustomerFinder />
      </ProtectedRoute>
      <ProtectedRoute exact={true} path='/education/partners-finder'>
        <PartnersFinder />
      </ProtectedRoute>
    </Switch>
  )
}

export default EducationRoutes
