import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import {
  comingSoonEducationPages,
  EDUCATION_IFRAME_SOURCE,
  EDUCATION_SUBTITLE, PlanningPhaseNavigationList,
  PlanningPhaseSearchList
} from 'utils/constants/education'
import { useHistory } from 'react-router-dom'
import EducationNavigationButtons from 'modules/Main/Education/components/EducationNavigationButtons'
import {
  redirectAccordingEducationPhase,
  setCurrentEducationPhaseClass, setPhaseProgressValue
} from 'store/hp/education/action'
import { useDispatch, useSelector } from 'react-redux'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { selectedEducationPhaseClassSelector } from 'store/hp/education/selector'
import ClassesAndExercises, { Phases } from 'modules/Main/Education/pages/main/phases'
import { IFilterOption } from 'models/hp/search/search.interface'
import {
  filterOptionsLoadedSelector,
  projectTypeOptions as projectTypeOptionsAction,
  selectedProjectTypeOptions as selectedProjectTypeOptionsAction
} from 'store/hp/search/selectors'
import EducationProgressBar from 'modules/Main/Education/components/EducationProgressBar'
import { getSearchOptions, removeSearchFilterValues } from 'store/hp/search/actions'
import PartnerContacts from './PartnerContacts'
import CustomerContacts from './CustomerContacts'
import MarketingConcepts from './MarketingConcepts'


const Education = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedSubtitle = useSelector(selectedEducationPhaseClassSelector)
  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector)
  const selectedEducationClass = useSelector(selectedEducationPhaseClassSelector)
  const projectTypeOptions: IFilterOption[] = useSelector(projectTypeOptionsAction)
  const selectedProjectTypeOptions: number[] = useSelector(selectedProjectTypeOptionsAction) as number[]

  const [openedPhases, setPhase] = useState<Phases[]>([])

  const currentPhaseClassIndex = PlanningPhaseNavigationList.findIndex((phase: IEducationPhaseClass) => phase.name === selectedEducationClass.name)
  const lastClass = currentPhaseClassIndex + 1 === PlanningPhaseNavigationList.length


  const onNext = () => {
    if (lastClass) {
      setPhaseProgressValue(dispatch)('planning', true)
      return
    }
    let nextPhaseClass = currentPhaseClassIndex < PlanningPhaseNavigationList.length ? PlanningPhaseNavigationList[currentPhaseClassIndex + 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(nextPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, nextPhaseClass, projectTypeOptions)
  }

  const onPrevious = () => {
    let previousPhaseClass = currentPhaseClassIndex ? PlanningPhaseNavigationList[currentPhaseClassIndex - 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(previousPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, previousPhaseClass, projectTypeOptions)
  }

  const setOpenedPhase = (phase: Phases) => () => {
    const newOpenedList = openedPhases
    const idx = newOpenedList.findIndex(p => p === phase)
    if (idx > -1) {
      newOpenedList.splice(idx, 1)
    } else {
      newOpenedList.push(phase)
    }
    setPhase([...newOpenedList])
  }

  useEffect(() => {
    if(!openedPhases.includes('planning') && PlanningPhaseSearchList.includes(selectedSubtitle)) {
      setPhase([...openedPhases, 'planning'])
    }
  }, [selectedSubtitle])

  useEffect(() => {
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
  }, [])

  const setSelectedSubtitle = (subtitle: IEducationPhaseClass) => {
    setCurrentEducationPhaseClass(dispatch)(subtitle)
    if (EDUCATION_IFRAME_SOURCE[subtitle.name]) {
      return
    }
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, subtitle, projectTypeOptions)
  }

  return (
    <Grid container className={styles['education']}>
      <Grid item md={3} className={styles['education-left-block']}>
        <EducationProgressBar />
        <ClassesAndExercises
          selectedSubtitle={selectedSubtitle}
          setSelectedSubtitle={setSelectedSubtitle}
          openedPhases={openedPhases}
          setOpenedPhase={setOpenedPhase}
        />
        <EducationNavigationButtons
          onNext={onNext}
          onPrevious={onPrevious}
          hasNext={!lastClass}
          hasPrevious={!!currentPhaseClassIndex}
        />
      </Grid>
      <Grid className={styles['education-content']} item md={9}>
        {selectedSubtitle && EDUCATION_IFRAME_SOURCE[selectedSubtitle.name] && (
          <iframe src={EDUCATION_IFRAME_SOURCE[selectedSubtitle.name]} title={selectedSubtitle.title}/>
        )}
        {comingSoonEducationPages.includes(selectedSubtitle.name as EDUCATION_SUBTITLE) && (
          <div className={styles['coming-soon-block']}>
            <p>Coming soon...</p>
          </div>
        )}
        {
          selectedSubtitle && selectedSubtitle.name === EDUCATION_SUBTITLE.PARTNERS_CONTACTS && (
            <PartnerContacts />
          )
        }
        {
          selectedSubtitle && selectedSubtitle.name === EDUCATION_SUBTITLE.CUSTOMER_CONTACTS && (
            <CustomerContacts />
          )
        }
        {
          selectedSubtitle && selectedSubtitle.name === EDUCATION_SUBTITLE.MARKETING_CONCEPTS && (
            <MarketingConcepts />
          )
        }
      </Grid>
    </Grid>
  )
}

export default Education