import React from 'react'
import { Snackbar } from '@material-ui/core'
import { useNotification } from 'context/NotificationContext'
import NotificationType from '../NotificationType'

const NotificationSnackbar = () => {

  const { visible, hide, message, anchorOrigin, autoHideDuration, type } = useNotification()

  if (!visible && !!type) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      open={visible}
      onClose={hide}
    >
      <NotificationType onClose={hide} type={type}>
        {message}
      </NotificationType>
    </Snackbar>
  )
}

export default NotificationSnackbar