import { numberToStrWithRound } from 'common/number'
import { IPiplineRevenueItem } from 'models/hp/pipeline/pipeline.interface'

export const options = {
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      ticks: {
        color: '#14142B',
        font: {
          family: 'Inter',
          size: 12,
          weight: 600
        },
      }
    },
    y: {
      stacked: true,
      ticks: {
        color: '#6E7191',
        font: {
          family: 'Inter',
          size: 12,
        },
        callback: function(value: string) {
          return numberToStrWithRound(Number(value))
        }
      }
    },
  },
}



export const data = (data: IPiplineRevenueItem[]) => {
  const labels = data.map(el => el.year)
  return {
    labels,
    datasets: [
      {
        label: 'Value',
        data: data.map(el => el.value),
        backgroundColor: '#ADBFED',
        stack: 'Stack 0',
        borderRadius: 6
      },
      // {
      //   label: 'Dataset 3',
      //   data: [5, 35, 70, 80, 95],
      //   backgroundColor: '#7494E6',
      //   stack: 'Stack 0',
      //   borderRadius: 6
      // },
    ],
  }
}