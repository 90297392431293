import React from 'react'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import styles from '../../index.module.scss'
import SvgIcon from 'components/SvgIcon'
import Modal from 'components/v2/Modal'

interface Props {
  isEdit: boolean
  keywords: string[]
  onDelete: (keyword: string) => void
}

const KeywordColumn = ({ keywords, isEdit, onDelete }: Props) => {

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const lessShow = [...keywords].slice(0, 4)
  const theme = useTheme()

  return (
    <Grid item md={4}>
      <Typography variant='h4'>KEYWORDS</Typography>
      <hr/>
      {lessShow.map((item) => (
        <div key={item} className={styles['block-row']}>
          <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
          <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{item}</Typography>
          {isEdit &&
          <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            <SvgIcon
              onClick={() => onDelete(item)}
              name='trash'
              color={theme.palette.warning.light}
              width={30}
              height={30}
            />
          </div>
          }
        </div>
      ))}
      {keywords.length > 4 &&
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalOpen(true)}
      >
        More
      </Button>
      }
      {modalOpen &&
      <Modal
        title='Keywords'
        open={modalOpen}
        size='md'
        onClose={() => setModalOpen(false)}
      >
        {keywords.map(i => (
          <div key={i} className={styles['block-row']}>
            <SvgIcon name='arrow-right' color={theme.palette.warning.light}/>
            <Typography style={{ color: theme.palette.primary.dark, marginLeft: '8px' }}>{i}</Typography>
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
              <SvgIcon
                onClick={() => onDelete(i)}
                name='trash'
                color={theme.palette.warning.light}
                width={30}
                height={30}
              />
            </div>
          </div>
        ))}
      </Modal>
      }
    </Grid>
  )
}

export default KeywordColumn
