import React, { useEffect } from 'react'
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Grid, CircularProgress, Typography, TablePagination, Button, useTheme } from '@material-ui/core'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { deleteUserFromGroup, getUserGroupIvitations, getUserList } from 'store/common/user-group/actons'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import ManageRoleBtn from './ManageRoleBtn'
import { userGroupListSelector, userGroupCountSelector } from 'store/common/user-group/selectors'
import InviteAndRequests from './InviteAndRequests'
import SvgIcon from 'components/SvgIcon'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'

const UserGroup = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const storedUser = useSelector(userSelector) as IUserBaseModel
  const userList = useSelector(userGroupListSelector)
  const userCount = useSelector(userGroupCountSelector)
  const notification = useNotification()

  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)

  const updateCurrentUserList = () => {
    return getUserList(dispatch)(page)
  }

  useEffect(() => {
    getUserGroupIvitations(dispatch)()
  }, [])

  useEffect(() => {
    setLoading(true)
    updateCurrentUserList()
      .finally(() => setLoading(false))
  }, [page])

  if (loading) {
    return (
      <Grid container alignItems='center' justifyContent='center'>
        <CircularProgress size={30} />
      </Grid>
    )
  }

  const deleteUser = (id: string) => {
    deleteUserFromGroup(id).then(() => {
      updateCurrentUserList()
    }).catch(e => {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    })
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>My team members:</Typography>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              {storedUser.userGroup?.role === 'manager' && <TableCell align='right'>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.firstName} {user.lastName} {user.email === storedUser.email && '(You)'}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <ManageRoleBtn
                    storedUser={storedUser}
                    user={user}
                  />
                </TableCell>
                {storedUser.userGroup?.role === 'manager' &&
                  <TableCell align='right'>
                    { storedUser.id !== user.id &&
                      <Button onClick={() => deleteUser(String(user.id))}>
                        <SvgIcon name='multiply' width={18} height={18} color={theme.palette.error.main}/>
                      </Button>
                    }
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        page={page}
        count={userCount}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(ev, p) => setPage(p)}
      />
      <InviteAndRequests
        updateCurrentUserList={updateCurrentUserList}
      />
    </div>
  )
}

export default UserGroup