import { Dispatch } from 'redux'
import { RequestMethods } from 'utils/const'
import { request } from 'utils/http'
import Paths from 'utils/paths'
import { sortDirections } from 'common/sort'
import {
  IHPStrategyItem,
  IHPStrategyPastPerformanceKeyPersonel,
  IHPStrategyPastPerformanceKeyPersonelData,
  IHPStrategyPastPerformanceNoKeyPersonel,
  IHPStrategyPastPerformanceNoKeyPersonelData,
  IHPStrategySummaryDataModel,
  IHPStrategySummaryValues,
  IHPStrategyWhinThemesData,
  IHpRfpChatAddMessageResponseModel,
  IHpRfpChatResponseModel,
  IHpRfpDetailModel,
  IHpRfpDetailResponseModel,
  IHpRfpReportResponseModel,
  TActivities,
  TCategorization,
  TEvalueationCriteria,
  TOpenQuestions,
  TPastPerformance,
  TProcess,
  TSummary,
  TWinThemes
} from 'models/hp/rfp/rfp.interface'
import {
  CategorizedHpRfpModel,
  CategorizedHpRfpResponseModel,
  HpRfpChatResponseModel,
  HpRfpDetailResponseModel,
  HpRfpReportResponseModel,
  TrashedHpRfpResponseModel,
  HpRfpModel, HpRfpModelResponseModel, HpRfpChatAddMessageResponseModel, GoNoGoSuggestionResponseModel
} from 'models/hp/rfp/rfp.model'
import { ISearchDTO } from 'models/hp/search/search.interface'
import { RfpTinderModel, RfpTinderResponseModel } from 'models/hp/rfp/tinder.model'
import { userActivity } from 'store/common/owner/actions'
import { BaseResponseModel } from 'models/base/base.model'
import { convertSearchDto, convertSearchToDto } from 'helpers/search'
import { SuggestionStatus } from 'constants/user-group'
import { PipelineRfpStepsResponseModel } from 'models/hp/pipeline/pipeline.model'
import { UserBaseModel } from 'models/common/user/user.model'
import { SearchModel } from 'models/hp/search/search.model'
import { CreateResponseOutlineResposeModel, HpStrategyActivitiesResposeModel, HpStrategyEvaluationCriteriaResposeModel, HpStrategyOpenQuestionsResposeModel, HpStrategyPastPerformanceRecommendationsResposeModel, HpStrategyPastPerformanceResposeModel, HpStrategyProcessNaicsResposeModel, HpStrategyProcessResposeModel, HpStrategyProcessSetAsideResposeModel, HpStrategySummaryResposeModel, HpStrategyWinThemesResposeModel, NoteModel, ResponseOutlineResposeModel, SetHpStrategyWinThemesResposeModel } from 'models/hp/rfp/calc/calc.model'
import { FieldType, Note } from 'models/hp/rfp/calc/calc.interface'

export const HP_RFP_LIST = 'HP_RFP_LIST'
export const HP_RFP_LIST_SUCCESS = 'HP_RFP_LIST_SUCCESS'
export const HP_RFP_LIST_LOADED_FALSE = 'HP_RFP_LIST_LOADED_FALSE'
export const HP_RFP_LIST_APPEND_SUCCESS = 'HP_RFP_LIST_APPEND_SUCCESS'
export const HP_RFP_LIST_ERROR = 'HP_RFP_LIST_ERROR'

export const HP_RFP_LIST_FILTER = 'HP_RFP_LIST_FILTER'
export const HP_RFP_FILTER = 'HP_RFP_FILTER'

export const HP_RFP_DETAILS = 'HP_RFP_DETAILS'
export const HP_RFP_DETAILS_RESET = 'HP_RFP_DETAILS_RESET'

export const HP_RFP_CATEGORIZED = 'HP_RFP_CATEGORIZED'
export const HP_RFP_CATEGORIZED_SUCCESS = 'HP_RFP_CATEGORIZED_SUCCESS'
export const HP_RFP_CATEGORIZED_ERROR = 'HP_RFP_CATEGORIZED_ERROR'
export const HP_RFP_CATEGORIZED_RESET = 'HP_RFP_CATEGORIZED_RESET'

export const HP_RFP_TRASHED = 'HP_RFP_TRASHED'
export const HP_RFP_TRASHED_APPEND = 'HP_RFP_TRASHED_APPEND'
export const HP_RFP_TRASHED_SUCCESS = 'HP_RFP_TRASHED_SUCCESS'
export const HP_RFP_TRASHED_ERROR = 'HP_RFP_TRASHED_ERROR'
export const HP_RFP_TRASHED_RESET = 'HP_RFP_TRASHED_RESET'

export const HP_RFP_GET_DOWNLOAD_DOCUMENTS_LINK_SUCCESS = 'HP_RFP_GET_DOWNLOAD_DOCUMENTS_LINK_SUCCESS'
export const HP_RFP_GET_WORK_SUCCESS = 'HP_RFP_GET_WORK_SUCCESS'

export const HP_RFP_ITEM_CATEGORIZED = 'HP_RFP_ITEM_CATEGORIZED'
export const HP_RFP_CATEGORIZED_ITEM_CATEGORIZED = 'HP_RFP_CATEGORIZED_ITEM_CATEGORIZED'
export const HP_RFP_CATEGORIZED_ITEM_UNSET = 'HP_RFP_CATEGORIZED_ITEM_UNSET'
export const HP_RFP_ITEM_TRASHED_UNSET = 'HP_RFP_ITEM_TRASHED_UNSET'

export const CALCULATORS_SET_P_WIN = 'CALCULATORS_SET_P_WIN'
export const CALCULATORS_SET_ESTIMATED_VALUE = 'CALCULATORS_SET_ESTIMATED_VALUE'
export const CALCULATORS_SET_COST_TO_PURSUE = 'CALCULATORS_SET_COST_TO_PURSUE'
export const CALCULATORS_SET_PROPOSAL_LENGTH = 'CALCULATORS_SET_PROPOSAL_LENGTH'
export const CALCULATORS_SET_TASK = 'CALCULATORS_SET_TASK'

export const GET_GO_NO_GO_SUGGESTIONS = 'GET_GO_NO_GO_SUGGESTIONS'
export const GET_GO_NO_GO_SUGGESTIONS_SUCCESS = 'GET_GO_NO_GO_SUGGESTIONS_SUCCESS'
export const GET_GO_NO_GO_SUGGESTIONS_ERROR = 'GET_GO_NO_GO_SUGGESTIONS_ERROR'

export const SET_GO_NO_GO_SUGGESTION = 'SET_GO_NO_GO_SUGGESTION'
export const SET_GO_NO_GO_SUGGESTION_SUCCESS = 'SET_GO_NO_GO_SUGGESTION_SUCCESS'
export const SET_GO_NO_GO_SUGGESTION_ERROR = 'SET_GO_NO_GO_SUGGESTION_ERROR'

export const GET_PIPELINE_RFP_STEPS = 'GET_PIPELINE_RFP_STEPS'
export const GET_PIPELINE_RFP_STEPS_SUCCESS = 'GET_PIPELINE_RFP_STEPS_SUCCESS'
export const GET_PIPELINE_RFP_STEPS_ERROR = 'GET_PIPELINE_RFP_STEPS_ERROR'
export const SET_PIPELINE_RFP_STEP = 'SET_PIPELINE_RFP_STEP'

export const UPDATE_GO_NO_GO_SUGGESTIONS = 'UPDATE_GO_NO_GO_SUGGESTIONS'

export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS'

export const HP_RFP_RESET = 'HP_RFP_RESET'

export const GET_HP_STRATEGY_WIN_THEMES = 'GET_HP_STRATEGY_WIN_THEMES'
export const GET_HP_STRATEGY_WIN_THEMES_SUCCESS = 'GET_HP_STRATEGY_WIN_THEMES_SUCCESS'
export const GET_HP_STRATEGY_WIN_THEMES_ERROR = 'GET_HP_STRATEGY_WIN_THEMES_ERROR'

export const SET_HP_STRATEGY_WIN_THEMES = 'SET_HP_STRATEGY_WIN_THEMES'
export const SET_HP_STRATEGY_WIN_THEMES_SUCCESS = 'SET_HP_STRATEGY_WIN_THEMES_SUCCESS'
export const SET_HP_STRATEGY_WIN_THEMES_ERROR = 'SET_HP_STRATEGY_WIN_THEMES_ERROR'

export const DELETE_HP_STRATEGY_ITEM = 'DELETE_HP_STRATEGY_ITEM'
export const UPDATE_HP_STRATEGY_ITEM = 'UPDATE_HP_STRATEGY_ITEM'

export const GET_HP_STRATEGY_ACTIVITIES = 'GET_HP_STRATEGY_ACTIVITIES'
export const GET_HP_STRATEGY_ACTIVITIES_SUCCESS = 'GET_HP_STRATEGY_ACTIVITIES_SUCCESS'
export const GET_HP_STRATEGY_ACTIVITIES_ERROR = 'GET_HP_STRATEGY_ACTIVITIES_ERROR'

export const SET_HP_STRATEGY_ACTIVITIES = 'SET_HP_STRATEGY_ACTIVITIES'
export const SET_HP_STRATEGY_ACTIVITIES_SUCCESS = 'SET_HP_STRATEGY_ACTIVITIES_SUCCESS'
export const SET_HP_STRATEGY_ACTIVITIES_ERROR = 'SET_HP_STRATEGY_ACTIVITIES_ERROR'

export const GET_HP_STRATEGY_PAST_PERFORMANCE = 'GET_HP_STRATEGY_PAST_PERFORMANCE'
export const GET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS = 'GET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS'
export const GET_HP_STRATEGY_PAST_PERFORMANCE_ERROR = 'GET_HP_STRATEGY_PAST_PERFORMANCE_ERROR'

export const SET_HP_STRATEGY_PAST_PERFORMANCE = 'SET_HP_STRATEGY_PAST_PERFORMANCE'
export const SET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS = 'SET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS'
export const SET_HP_STRATEGY_PAST_PERFORMANCE_ERROR = 'SET_HP_STRATEGY_PAST_PERFORMANCE_ERROR'

export const GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS = 'GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS'
export const GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS = 'GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS'
export const GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR = 'GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR'

export const SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS = 'SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS'
export const SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS = 'SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS'
export const SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR = 'SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR'

export const GET_HP_STRATEGY_EVALUATION_CRITERIA = 'GET_HP_STRATEGY_EVALUATION_CRITERIA'
export const GET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS = 'GET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS'
export const GET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR = 'GET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR'

export const SET_HP_STRATEGY_EVALUATION_CRITERIA = 'SET_HP_STRATEGY_EVALUATION_CRITERIA'
export const SET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS = 'SET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS'
export const SET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR = 'SET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR'


export const GET_HP_STRATEGY_PROCESS = 'GET_HP_STRATEGY_PROCESS'
export const GET_HP_STRATEGY_PROCESS_SUCCESS = 'GET_HP_STRATEGY_PROCESS_SUCCESS'
export const GET_HP_STRATEGY_PROCESS_ERROR = 'GET_HP_STRATEGY_PROCESS_ERROR'

export const SET_HP_STRATEGY_PROCESS = 'SET_HP_STRATEGY_PROCESS'
export const SET_HP_STRATEGY_PROCESS_SUCCESS = 'SET_HP_STRATEGY_PROCESS_SUCCESS'
export const SET_HP_STRATEGY_PROCESS_ERROR = 'SET_HP_STRATEGY_PROCESS_ERROR'

export const GET_HP_STRATEGY_OPEN_QUESTIONS = 'GET_HP_STRATEGY_OPEN_QUESTIONS'
export const GET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS = 'GET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS'
export const GET_HP_STRATEGY_OPEN_QUESTIONS_ERROR = 'GET_HP_STRATEGY_OPEN_QUESTIONS_ERROR'

export const SET_HP_STRATEGY_OPEN_QUESTIONS = 'SET_HP_STRATEGY_OPEN_QUESTIONS'
export const SET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS = 'SET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS'
export const SET_HP_STRATEGY_OPEN_QUESTIONS_ERROR = 'SET_HP_STRATEGY_OPEN_QUESTIONS_ERROR'

export const GET_HP_STRATEGY_PROCESS_SET_ASIDE = 'GET_HP_STRATEGY_PROCESS_SET_ASIDE'
export const GET_HP_STRATEGY_PROCESS_SET_ASIDE_SUCCESS = 'GET_HP_STRATEGY_PROCESS_SET_ASIDE_SUCCESS'
export const GET_HP_STRATEGY_PROCESS_SET_ASIDE_ERROR = 'GET_HP_STRATEGY_PROCESS_SET_ASIDE_ERROR'

export const GET_HP_STRATEGY_PROCESS_NAICS = 'GET_HP_STRATEGY_PROCESS_NAICS'
export const GET_HP_STRATEGY_PROCESS_NAICS_SUCCESS = 'GET_HP_STRATEGY_PROCESS_NAICS_SUCCESS'
export const GET_HP_STRATEGY_PROCESS_NAICS_ERROR = 'GET_HP_STRATEGY_PROCESS_NAICS_ERROR'

export const GET_HP_STRATEGY_SUMMARY = 'GET_HP_STRATEGY_SUMMARY'
export const GET_HP_STRATEGY_SUMMARY_SUCCESS = 'GET_HP_STRATEGY_SUMMARY_SUCCESS'
export const GET_HP_STRATEGY_SUMMARY_ERROR = 'GET_HP_STRATEGY_SUMMARY_ERROR'

export const SET_HP_STRATEGY_SUMMARY = 'SET_HP_STRATEGY_SUMMARY'
export const SET_HP_STRATEGY_SUMMARY_SUCCESS = 'SET_HP_STRATEGY_SUMMARY_SUCCESS'
export const SET_HP_STRATEGY_SUMMARY_ERROR = 'SET_HP_STRATEGY_SUMMARY_ERROR'

export const GET_RESPONSE_OUTLINE = 'GET_RESPONSE_OUTLINE'
export const GET_RESPONSE_OUTLINE_SUCCESS = 'GET_RESPONSE_OUTLINE_SUCCESS'
export const GET_RESPONSE_OUTLINE_ERROR = 'GET_RESPONSE_OUTLINE_ERROR'

export const CREATE_RESPONSE_OUTLINE = 'CREATE_RESPONSE_OUTLINE'
export const CREATE_RESPONSE_OUTLINE_SUCCESS = 'CREATE_RESPONSE_OUTLINE_SUCCESS'
export const CREATE_RESPONSE_OUTLINE_ERROR = 'CREATE_RESPONSE_OUTLINE_ERROR'

export const DELETE_RESPONSE_OUTLINE = 'DELETE_RESPONSE_OUTLINE'
export const DELETE_RESPONSE_OUTLINE_SUCCESS = 'DELETE_RESPONSE_OUTLINE_SUCCESS'
export const DELETE_RESPONSE_OUTLINE_ERROR = 'DELETE_RESPONSE_OUTLINE_ERROR'

export const UPDATE_RESPONSE_OUTLINE = 'UPDATE_RESPONSE_OUTLINE'
export const UPDATE_RESPONSE_OUTLINE_SUCCESS = 'UPDATE_RESPONSE_OUTLINE_SUCCESS'
export const UPDATE_RESPONSE_OUTLINE_ERROR = 'UPDATE_RESPONSE_OUTLINE_ERROR'

export const GET_RESPONSE_COMPLIANCE = 'GET_RESPONSE_COMPLIANCE'
export const GET_RESPONSE_COMPLIANCE_SUCCESS = 'GET_RESPONSE_COMPLIANCE_SUCCESS'
export const GET_RESPONSE_COMPLIANCE_ERROR = 'GET_RESPONSE_COMPLIANCE_ERROR'

export const CREATE_RESPONSE_COMPLIANCE = 'CREATE_RESPONSE_COMPLIANCE'
export const CREATE_RESPONSE_COMPLIANCE_SUCCESS = 'CREATE_RESPONSE_COMPLIANCE_SUCCESS'
export const CREATE_RESPONSE_COMPLIANCE_ERROR = 'CREATE_RESPONSE_COMPLIANCE_ERROR'

export const DELETE_RESPONSE_COMPLIANCE = 'DELETE_RESPONSE_COMPLIANCE'
export const DELETE_RESPONSE_COMPLIANCE_SUCCESS = 'DELETE_RESPONSE_COMPLIANCE_SUCCESS'
export const DELETE_RESPONSE_COMPLIANCE_ERROR = 'DELETE_RESPONSE_COMPLIANCE_ERROR'

export const UPDATE_RESPONSE_COMPLIANCE = 'UPDATE_RESPONSE_COMPLIANCE'
export const UPDATE_RESPONSE_COMPLIANCE_SUCCESS = 'UPDATE_RESPONSE_COMPLIANCE_SUCCESS'
export const UPDATE_RESPONSE_COMPLIANCE_ERROR = 'UPDATE_RESPONSE_COMPLIANCE_ERROR'

export const UPDATE_RFP_CATEGORIZATION = 'UPDATE_RFP_CATEGORIZATION'

export interface IRfpFilter {
  searchId?: number
  limit?: number
  page?: number
  offset?: number
  date?: sortDirections
  title?: sortDirections
}

// TODO: Refactor FilterOption model deserializer to remove this shit
const convertFilterOptions = (filterOptions: ISearchDTO): any => {
  const keys = Object.keys(filterOptions) as Array<keyof ISearchDTO>
  const convertedFilters: any = {}
  keys.forEach(key => {
    if (Array.isArray(filterOptions[key]) && key !== 'keywords') {
      convertedFilters[key] = (filterOptions[key] as any[]).map((x: string) => parseInt(x) || x)
    }
  })
  return {
    ...filterOptions,
    ...convertedFilters
  }
}

export const getRfpList = async (params: IRfpFilter, body: ISearchDTO): Promise<RfpTinderModel[]> => {
  const response = await request<RfpTinderResponseModel>(RequestMethods.Post, Paths.Hp.Tinder.getAll, {
    schema: RfpTinderResponseModel,
    body: convertSearchDto(body, body.keywords.join(' ')),
    params: {...params, extended: true}
  })
  return response.data.data
}

export const getRfpFilteredListAction = (dispatch: Dispatch) => async (params: IRfpFilter, body: ISearchDTO): Promise<void> => {
  dispatch({
    type: HP_RFP_LIST
  })
  try {
    const response = await request<RfpTinderResponseModel>(RequestMethods.Post, Paths.Hp.Tinder.getAll, {
      schema: RfpTinderResponseModel,
      body: convertSearchDto(body, body.keywords.join(' ')),
      params: {...params, extended: true}
    })
    dispatch({
      type: HP_RFP_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: HP_RFP_LIST_ERROR
    })
    throw error
  }
}

export const appendRfpFilteredListAction = (dispatch: Dispatch) => async (params: IRfpFilter, body: ISearchDTO): Promise<void> => {
  dispatch({
    type: HP_RFP_LIST_FILTER,
    payload: params
  })
  try {
    const response = await request<RfpTinderResponseModel>(RequestMethods.Post, Paths.Hp.Tinder.getAll, {
      schema: RfpTinderResponseModel,
      body: convertFilterOptions(body),
      params: {...params, extended: true}
    })
    dispatch({
      type: HP_RFP_LIST_APPEND_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: HP_RFP_LIST_ERROR
    })
    throw error
  }
}

// TODO: Add load for all searches
export const getRfpFilteredByAllFiltersListAction = (dispatch: Dispatch) => async (params: IRfpFilter, searches: SearchModel[]): Promise<void> => {
  dispatch({
    type: HP_RFP_LIST
  })
  try {
    const responses = await Promise.all(searches.map(search => request<RfpTinderResponseModel>(RequestMethods.Post, Paths.Hp.Tinder.getAll, {
      schema: RfpTinderResponseModel,
      body: convertSearchToDto(search),
      params: {...params, extended: true}
    })))
    dispatch({
      type: HP_RFP_LIST_SUCCESS,
      payload: {
        count: responses.reduce((acc, res) => acc + res.data.count, 0),
        data: responses.reduce((acc, res) => acc.concat(res.data.data), [] as RfpTinderModel[])
      }
    })
  } catch (error) {
    dispatch({
      type: HP_RFP_LIST_ERROR
    })
    throw error
  }
}

export const appendRfpFilteredByAllFiltersListAction = (dispatch: Dispatch) => async (params: IRfpFilter, searches: SearchModel[]): Promise<void> => {
  dispatch({
    type: HP_RFP_LIST_FILTER,
    payload: params
  })
  try {
    const responses = await Promise.all(searches.map(search => request<RfpTinderResponseModel>(RequestMethods.Post, Paths.Hp.Tinder.getAll, {
      schema: RfpTinderResponseModel,
      body: convertSearchToDto(search),
      params: {...params, extended: true}
    })))
    dispatch({
      type: HP_RFP_LIST_APPEND_SUCCESS,
      payload: {
        data: responses.reduce((acc, res) => acc.concat(res.data.data), [] as RfpTinderModel[])
      }
    })
  } catch (error) {
    dispatch({
      type: HP_RFP_LIST_ERROR
    })
    throw error
  }
}

export const setPaginationAction = (dispatch: Dispatch) => (filters: IRfpFilter) => dispatch({
  type: HP_RFP_LIST_FILTER,
  payload: filters
})

export const getRfpDetailAction = async (noticeId?: string, title?: string, id?: string): Promise<IHpRfpDetailResponseModel> => {
  userActivity.event(userActivity.activities.rfpDetails, noticeId)

  const updatedTitle = title ? title.replace(/-/g, ' ') : title
  const response = await request<IHpRfpDetailResponseModel>(RequestMethods.Get, Paths.Hp.Rfp.getBySearchParams(noticeId, updatedTitle, id), {
    schema: HpRfpDetailResponseModel
  })
  return response.data
}

export const hpRfpSetDetailsAction = (rfpData: IHpRfpDetailResponseModel) => ({
  type: HP_RFP_DETAILS,
  payload: { ...rfpData }
})

export const resetRfpDetail = () => {
  return {
    type: HP_RFP_DETAILS_RESET
  }
}

export const resetRfp = () => {
  return {
    type: HP_RFP_RESET
  }
}

export const getHpRfpDiscussMessages = async (id: string, params: {limit: number, offset: number}): Promise<IHpRfpChatResponseModel> => {
  const response = await request<IHpRfpChatResponseModel>(RequestMethods.Get, Paths.Hp.RfpDiscuss.messages(id), {
    schema: HpRfpChatResponseModel,
    params
  })
  return response.data
}

export const sendHpRfpDiscussMessages = async (id: string, message: string): Promise<IHpRfpChatAddMessageResponseModel> => {
  const res = await request<IHpRfpChatAddMessageResponseModel>(RequestMethods.Post, Paths.Hp.RfpDiscuss.messages(id), {
    schema: HpRfpChatAddMessageResponseModel,
    body: {value: message}
  })
  return res.data
}

export const getRfpDownloadLink = async (id: string): Promise<IHpRfpReportResponseModel> => {
  const response = await request<IHpRfpReportResponseModel>(RequestMethods.Post, Paths.Hp.RfpDownload.getLink(id), {
    schema: HpRfpReportResponseModel
  })

  return response.data
}

export const getCategorizedRfps = (dispatch: Dispatch) => async () => {
  dispatch({
    type: HP_RFP_CATEGORIZED
  })
  try {
    const res = await request<CategorizedHpRfpResponseModel>(RequestMethods.Get, Paths.Hp.Categorize.getSegmented(), {
      schema: CategorizedHpRfpResponseModel
    })
    dispatch({
      type: HP_RFP_CATEGORIZED_SUCCESS,
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({
      type: HP_RFP_CATEGORIZED_ERROR
    })
    throw e
  }
}

export const resetCategorizedRfps = (dispatch: Dispatch) => () => {
  dispatch({
    type: HP_RFP_CATEGORIZED_RESET
  })
}

export const getTrashedRfps = (dispatch: Dispatch) => async () => {
  dispatch({
    type: HP_RFP_TRASHED
  })
  try {
    const res = await request<TrashedHpRfpResponseModel>(RequestMethods.Get, Paths.Hp.Categorize.getSegmented({type: 'archive'}), {
      schema: TrashedHpRfpResponseModel
    })
    dispatch({
      type: HP_RFP_TRASHED_SUCCESS,
      payload: res.data
    })
  }
  catch (e) {
    dispatch({
      type: HP_RFP_TRASHED_ERROR
    })
    throw e
  }
}

export const appendTrashedRfps = (dispatch: Dispatch) => async (offset: number): Promise<void> => {
  dispatch({
    type: HP_RFP_TRASHED
  })
  const res = await request<TrashedHpRfpResponseModel>(
    RequestMethods.Get,
    Paths.Hp.Categorize.getSegmented({type: 'archive', offset}),
    {
      schema: TrashedHpRfpResponseModel
    }
  )
  dispatch({
    type: HP_RFP_TRASHED_APPEND,
    payload: res.data
  })
}

export const resetTrashedRfps = (dispatch: Dispatch) => () => {
  dispatch({
    type: HP_RFP_TRASHED_RESET
  })
}

export const setRfpCategorization = (dispatch: Dispatch) => async (
  rfp: CategorizedHpRfpModel | HpRfpModel | RfpTinderModel | IHpRfpDetailModel,
  type: TCategorization,
  from?: string,
  lazyUser: boolean = false,
): Promise<void> => {
  try {
    // Fields only for the list of AWARDS
    if (rfp?.contractType === 'contractAward') {
      await request(RequestMethods.Post, Paths.Hp.Awards.categorized(rfp?.contractTransactionUniqueKey!), {body: { type: type }, lazyUser})
    } else {
      await request(RequestMethods.Post, Paths.Hp.Categorize.setToRfp(rfp.id), {body: { value: type }, lazyUser})
    }

    if (from && from === 'trash') {
      dispatch({
        type: HP_RFP_ITEM_TRASHED_UNSET,
        payload: rfp.id
      })
    } else if (!rfp.categorizationType) {
      dispatch({
        type: HP_RFP_ITEM_CATEGORIZED,
        payload: {
          rfp
        }
      })
    }

    userActivity.event(
      type === 'archive' ? userActivity.activities.rfpTrash : userActivity.activities.rfpLike,
      rfp.solicitationNumber
    )
  } catch (error) {
    error.response.data.errors.status = error.response.status
    throw error.response.data.errors
  }
}

export const removeRfpCategorization = (dispatch: Dispatch) => async (
  rfp: CategorizedHpRfpModel | HpRfpModel | RfpTinderModel | IHpRfpDetailModel
): Promise<void> => {
  try {
    const type: TCategorization = 'archive'
    await request(RequestMethods.Post, Paths.Hp.Categorize.setToRfp(rfp.id), {body: { value: type }})
    if (rfp?.categorizationType) {
      dispatch({
        type: HP_RFP_CATEGORIZED_ITEM_UNSET,
        payload: { rfp, type }
      })
    } else {
      dispatch({
        type: HP_RFP_ITEM_CATEGORIZED,
        payload: {
          rfp, type
        }
      })
    }
    userActivity.event(userActivity.activities.rfpTrash, rfp.solicitationNumber)
  } catch (error) {
    throw error.response.data.errors
  }
}

export const updateCategorizationType = (dispatch: Dispatch) => (categorization: TCategorization) => {
  dispatch({
    type: UPDATE_RFP_CATEGORIZATION,
    payload: categorization
  })
}

export const unsetRfpCategorization = (dispatch: Dispatch) => async (
  rfp: CategorizedHpRfpModel | HpRfpModel
): Promise<void> => {
  await request(RequestMethods.Delete, Paths.Hp.Categorize.setToRfp(rfp.id))
  dispatch({
    type: HP_RFP_ITEM_TRASHED_UNSET,
    payload: rfp.id
  })
}

export const setListingLoadedFalse = (dispatch: Dispatch) => {
  dispatch({
    type: HP_RFP_LIST_LOADED_FALSE
  })
}

export const calculatorSetPWin = (dispatch: Dispatch) => (value: string) => {
  dispatch({
    type: CALCULATORS_SET_P_WIN,
    payload: value
  })
}

export const calculatorSetEstimatedValue = (dispatch: Dispatch) => (value: string, estimatedValueCalculated: boolean) => {
  dispatch({
    type: CALCULATORS_SET_ESTIMATED_VALUE,
    payload: { value, estimatedValueCalculated }
  })
}

export const calculatorSetCostToPursue = (dispatch: Dispatch) => (values: {[key: string]: string | number | boolean}) => {
  dispatch({
    type: CALCULATORS_SET_COST_TO_PURSUE,
    payload: values
  })
}

export const calculatorSetProposalLength = (dispatch: Dispatch) => (numbers: number) => {
  dispatch({
    type: CALCULATORS_SET_PROPOSAL_LENGTH,
    payload: numbers
  })
}

export const calculatorSetTask = (dispatch: Dispatch) => (value: string) => {
  dispatch({
    type: CALCULATORS_SET_TASK,
    payload: value
  })
}

export const uploadContractsFromFile = async (base64: string) => {
  await request(RequestMethods.Post, Paths.Hp.Rfp.upload, { body: { base64 } })
}

export const getUpcoming = async (offset?: number): Promise<HpRfpModelResponseModel> => {
  const res = await request<HpRfpModelResponseModel>(RequestMethods.Get, Paths.Hp.Upcoming.getItems(offset), {
    schema: HpRfpModelResponseModel
  })
  return res.data
}

export const downloadRfps = async (body: ISearchDTO): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Rfp.download, {
    schema: BaseResponseModel,
    body
  })
  return res.data
}

export const downloadCategorized = async (): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Categorize.download, {
    schema: BaseResponseModel
  })
  return res.data
}

export const getGoNoGoSuggestions = (dispatch: Dispatch) => async (id: string) => {
  try {
    dispatch({
      type: GET_GO_NO_GO_SUGGESTIONS
    })
    const res = await request<GoNoGoSuggestionResponseModel>(RequestMethods.Get, Paths.Hp.GoNoGo.getSuggestions(id), {
      schema: GoNoGoSuggestionResponseModel
    })
    dispatch({
      type: GET_GO_NO_GO_SUGGESTIONS_SUCCESS,
      payload: res
    })
  } catch(e) {
    dispatch({
      type: GET_GO_NO_GO_SUGGESTIONS_ERROR
    })
    throw e
  }
}

export const setGoNoGoSuggestion = (dispatch: Dispatch) => async (id: string, value: SuggestionStatus, suggestionId: number) => {
  const res = await request<BaseResponseModel>(RequestMethods.Post,  Paths.Hp.GoNoGo.getSuggestions(id), {
    schema: BaseResponseModel,
    body: {value}
  })
  if(res.data.success) {
    dispatch({
      type: UPDATE_GO_NO_GO_SUGGESTIONS,
      payload: {
        id: suggestionId,
        value
      }
    })
  }
  return res
}

export const getPipelineRfpSteps = (dispatch: Dispatch) => async (rfpId: number): Promise<void> => {
  try {
    dispatch({ type: GET_PIPELINE_RFP_STEPS })
    const res = await request<PipelineRfpStepsResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.getPipelineRfpSteps(rfpId), {
      schema: PipelineRfpStepsResponseModel
    })
    dispatch({ type: GET_PIPELINE_RFP_STEPS_SUCCESS, payload: res.data.data })
  }
  catch (e) {
    dispatch({ type: GET_PIPELINE_RFP_STEPS_ERROR })
    throw e
  }
}

export const setPipelineRfpStep = (dispatch: Dispatch) => (step: string, user: UserBaseModel) => {
  dispatch({
    type: SET_PIPELINE_RFP_STEP,
    payload: {
      step, user
    }
  })
}

export const clearSuggestions = (dispatch: Dispatch) => () =>  {
  dispatch({
    type: CLEAR_SUGGESTIONS
  })
}

export const getWinThemes = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_WIN_THEMES })
    const res = await request<HpStrategyWinThemesResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.winThemes(rfpId), {
      schema: HpStrategyWinThemesResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_WIN_THEMES_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_WIN_THEMES_ERROR })
    throw e
  }
}

export const setWinThemes = (dispatch: Dispatch) => 
  async (rfpId: string, subType: TWinThemes, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_WIN_THEMES })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.winThemes(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_WIN_THEMES_SUCCESS
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_WIN_THEMES_ERROR })
      throw e
    }
  }

export const updateStrategyItem = (
  dispatch: Dispatch,
  type: string,
  values:
    IHPStrategyWhinThemesData |
    IHPStrategyItem<TWinThemes> |
    IHPStrategyItem<TActivities> |
    IHPStrategyItem<TPastPerformance, IHPStrategyPastPerformanceNoKeyPersonel> |
    IHPStrategyItem<TPastPerformance, IHPStrategyPastPerformanceKeyPersonel> |
    IHPStrategyItem<TPastPerformance> |
    IHPStrategyItem<TEvalueationCriteria> |
    IHPStrategyItem<TProcess> |
    IHPStrategyItem<TOpenQuestions> |
    IHPStrategyItem<TSummary,  IHPStrategySummaryDataModel>
) => {
  dispatch({
    type: UPDATE_HP_STRATEGY_ITEM,
    payload: {
      type,
      data: values
    }
  })
}

export const deleteStrategyItem = (dispatch: Dispatch, id: string, type: string) => {
  dispatch({
    type: DELETE_HP_STRATEGY_ITEM,
    payload: {
      type,
      id
    }
  })
}

export const deleteStrategyItemById = (dispatch: Dispatch) => 
  async (rfpId: string, strategyId: string, type: string): Promise<void> => {
    deleteStrategyItem(dispatch, strategyId, type)
    await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Delete, Paths.Hp.Strategy.deleteById(rfpId, strategyId), {
      schema: SetHpStrategyWinThemesResposeModel
    })
  }

export const getStrategyActivities = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_ACTIVITIES })
    const res = await request<HpStrategyActivitiesResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.activities(rfpId), {
      schema: HpStrategyActivitiesResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_ACTIVITIES_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_ACTIVITIES_ERROR })
    throw e
  }
}

export const setStrategyActivities = (dispatch: Dispatch) => 
  async (rfpId: string, subType: TActivities, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_ACTIVITIES })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.activities(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_ACTIVITIES_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_ACTIVITIES_ERROR })
      throw e
    }
  }

export const getStrategyPastPerformance = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_PAST_PERFORMANCE })
    const res = await request<HpStrategyPastPerformanceResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.pastPerformance(rfpId), {
      schema: HpStrategyPastPerformanceResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_PAST_PERFORMANCE_ERROR })
    throw e
  }
}

export const setStrategyPastPerformance = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TPastPerformance, values: IHPStrategyPastPerformanceNoKeyPersonelData | IHPStrategyPastPerformanceKeyPersonelData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_PAST_PERFORMANCE })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.pastPerformance(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_PAST_PERFORMANCE_ERROR })
      throw e
    }
  }

export const getStrategyPastPerformanceRecommendations = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS })
    const res = await request<HpStrategyPastPerformanceRecommendationsResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.pastPerformanceRecommendations(rfpId), {
      schema: HpStrategyPastPerformanceRecommendationsResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR })
    throw e
  }
}

export const setStrategyPastPerformanceRecommendations = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TPastPerformance, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.pastPerformanceRecommendations(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR })
      throw e
    }
  }

export const getStrategyEvaluationCriteria = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_EVALUATION_CRITERIA })
    const res = await request<HpStrategyEvaluationCriteriaResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.evaluationCriteria(rfpId), {
      schema: HpStrategyEvaluationCriteriaResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR })
    throw e
  }
}

export const setStrategyEvaluationCriteria = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TEvalueationCriteria, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_EVALUATION_CRITERIA })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.evaluationCriteria(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR })
      throw e
    }
  }

export const getStrategyProcess = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_PROCESS })
    const res = await request<HpStrategyProcessResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.process(rfpId), {
      schema: HpStrategyProcessResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_PROCESS_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_PROCESS_ERROR })
    throw e
  }
}

export const setStrategyProcess = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TProcess, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_PROCESS })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.process(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_PROCESS_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_PROCESS_ERROR })
      throw e
    }
  }

export const getStrategyOpenQuestions = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_OPEN_QUESTIONS })
    const res = await request<HpStrategyOpenQuestionsResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.openQuestions(rfpId), {
      schema: HpStrategyOpenQuestionsResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_OPEN_QUESTIONS_ERROR })
    throw e
  }
}

export const setStrategyOpenQuestions = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TOpenQuestions, values: IHPStrategyWhinThemesData, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_OPEN_QUESTIONS })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.openQuestions(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_OPEN_QUESTIONS_ERROR })
      throw e
    }
  }

export const getStrategyProcessSetAside = (dispatch: Dispatch) => async (rfpId: string, code: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_PROCESS_SET_ASIDE })
    const res = await request<HpStrategyProcessSetAsideResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.processSetAside(rfpId, code), {
      schema: HpStrategyProcessSetAsideResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_PROCESS_SET_ASIDE_SUCCESS, 
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_PROCESS_SET_ASIDE_ERROR })
    throw e
  }
}

export const getStrategyProcessNaics = (dispatch: Dispatch) => async (rfpId: string, code: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_PROCESS_NAICS})
    const res = await request<HpStrategyProcessNaicsResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.processNaics(rfpId, code), {
      schema: HpStrategyProcessNaicsResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_PROCESS_NAICS_SUCCESS, 
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_PROCESS_NAICS_ERROR })
    throw e
  }
}

export const getStrategySummary = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_HP_STRATEGY_SUMMARY })
    const res = await request<HpStrategySummaryResposeModel>(RequestMethods.Get, Paths.Hp.Strategy.summary(rfpId), {
      schema: HpStrategySummaryResposeModel
    })
    dispatch({ 
      type: GET_HP_STRATEGY_SUMMARY_SUCCESS, 
      payload: res.data
    })
  }
  catch (e) {
    dispatch({ type: GET_HP_STRATEGY_SUMMARY_ERROR })
    throw e
  }
}

export const setStrategySummary = (dispatch: Dispatch) =>
  async (rfpId: string, subType: TSummary, values: IHPStrategySummaryValues, tabType: string): Promise<string> => {
    try {
      dispatch({ type: SET_HP_STRATEGY_SUMMARY })
      const res = await request<SetHpStrategyWinThemesResposeModel>(RequestMethods.Post, Paths.Hp.Strategy.summary(rfpId, subType), {
        schema: SetHpStrategyWinThemesResposeModel,
        body: values
      })
      const id = res.data.data.id
      dispatch({ 
        type: SET_HP_STRATEGY_SUMMARY_SUCCESS, 
      })
      updateStrategyItem(dispatch, tabType, {
        id,
        subType,
        ...values
      })
      return id
    }
    catch (e) {
      dispatch({ type: SET_HP_STRATEGY_SUMMARY_ERROR })
      throw e
    }
  }

export const getResponseOutline = (dispatch: Dispatch) => async (rfpId: string): Promise<void> => {
  try {
    dispatch({ type: GET_RESPONSE_OUTLINE })
    const res = await request<ResponseOutlineResposeModel>(RequestMethods.Get, Paths.Hp.Response.outline(rfpId), {
      schema: ResponseOutlineResposeModel
    })
    dispatch({ 
      type: GET_RESPONSE_OUTLINE_SUCCESS, 
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({ type: GET_RESPONSE_OUTLINE_ERROR })
    throw e
  }
}

export const createResponseOutline = (dispatch: Dispatch) => async (rfpId: string, item: NoteModel): Promise<void> => {
  try {
    dispatch({ type: CREATE_RESPONSE_OUTLINE })
    dispatch({ 
      type: CREATE_RESPONSE_OUTLINE_SUCCESS, 
      payload: item
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Post, Paths.Hp.Response.outline(rfpId), {
      schema: CreateResponseOutlineResposeModel,
      body: { ...item }
    })
  }
  catch (e) {
    dispatch({ type: CREATE_RESPONSE_OUTLINE_ERROR })
    throw e
  }
}

export const deleteResponseOutline = (dispatch: Dispatch) => async (rfpId: string, ids: string[]): Promise<void> => {
  try {
    dispatch({ type: DELETE_RESPONSE_OUTLINE })
    dispatch({ 
      type: DELETE_RESPONSE_OUTLINE_SUCCESS, 
      payload: ids
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Delete, Paths.Hp.Response.outline(rfpId), {
      schema: CreateResponseOutlineResposeModel,
      body: { ids }
    })
  }
  catch (e) {
    dispatch({ type: DELETE_RESPONSE_OUTLINE_ERROR })
    throw e
  }
}

export const updateResponseOutline = (dispatch: Dispatch) => async (rfpId: string, notes: Note[]): Promise<void> => {
  try {
    dispatch({ type: UPDATE_RESPONSE_OUTLINE })
    dispatch({ 
      type: UPDATE_RESPONSE_OUTLINE_SUCCESS,
      payload: notes
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Put, Paths.Hp.Response.outline(rfpId), {
      schema: CreateResponseOutlineResposeModel,
      body: {
        values: notes.map(({id, content, parentId, prevId, nextId, titleType, quote}) => ({
          id,
          content,
          parentId,
          titleType,
          quote,
          prevId: prevId || null,
          nextId: nextId || null,
        }))
      }
    })
  }
  catch (e) {
    dispatch({ type: UPDATE_RESPONSE_OUTLINE_ERROR })
    throw e
  }
}


export const getResponseCompliance = (dispatch: Dispatch) => async (rfpId: string, field: FieldType): Promise<void> => {
  try {
    dispatch({ type: GET_RESPONSE_COMPLIANCE })
    const res = await request<ResponseOutlineResposeModel>(RequestMethods.Get, Paths.Hp.Response.compliance(rfpId, field), {
      schema: ResponseOutlineResposeModel
    })
    dispatch({ 
      type: GET_RESPONSE_COMPLIANCE_SUCCESS, 
      payload: res.data.data
    })
  }
  catch (e) {
    dispatch({ type: GET_RESPONSE_COMPLIANCE_ERROR })
    throw e
  }
}

export const createResponseCompliance = (dispatch: Dispatch) => async (rfpId: string, item: NoteModel, field: FieldType): Promise<void> => {
  try {
    dispatch({ type: CREATE_RESPONSE_COMPLIANCE })
    dispatch({ 
      type: CREATE_RESPONSE_COMPLIANCE_SUCCESS, 
      payload: item
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Post, Paths.Hp.Response.compliance(rfpId, field), {
      schema: CreateResponseOutlineResposeModel,
      body: { ...item }
    })
  }
  catch (e) {
    dispatch({ type: CREATE_RESPONSE_COMPLIANCE_ERROR })
    throw e
  }
}

export const deleteResponseCompliance = (dispatch: Dispatch) => async (rfpId: string, ids: string[]): Promise<void> => {
  try {
    dispatch({ type: DELETE_RESPONSE_COMPLIANCE })
    dispatch({ 
      type: DELETE_RESPONSE_COMPLIANCE_SUCCESS, 
      payload: ids
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Delete, Paths.Hp.Response.compliance(rfpId), {
      schema: CreateResponseOutlineResposeModel,
      body: { ids }
    })
  }
  catch (e) {
    dispatch({ type: DELETE_RESPONSE_COMPLIANCE_ERROR })
    throw e
  }
}

export const updateResponseCompliance = (dispatch: Dispatch) => async (rfpId: string, notes: Note[]): Promise<void> => {
  try {
    dispatch({ type: UPDATE_RESPONSE_COMPLIANCE })
    dispatch({ 
      type: UPDATE_RESPONSE_COMPLIANCE_SUCCESS,
      payload: notes
    })
    await request<CreateResponseOutlineResposeModel>(RequestMethods.Put, Paths.Hp.Response.compliance(rfpId), {
      schema: CreateResponseOutlineResposeModel,
      body: {
        values: notes.map(({id, content, parentId, prevId, nextId, titleType, quote}) => ({
          id,
          content,
          titleType,
          quote,
          parentId: parentId || undefined,
          prevId: prevId || null,
          nextId: nextId || null,
        }))
      }
    })
  }
  catch (e) {
    dispatch({ type: UPDATE_RESPONSE_COMPLIANCE_ERROR })
    throw e
  }
}