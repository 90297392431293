export enum PLAN {
  FREE = 'free',
  SUB = 'standard',
  PRIME = 'growth',
  LARGE = 'enterprise'
}
export const PLANS: PLAN[] = [PLAN.FREE, PLAN.SUB, PLAN.PRIME, PLAN.LARGE] // Can component usage
export type TPlan = 'free' | 'standard' | 'growth' | 'large'

export enum PLATFORM {
  APPLE = 'apple',
  GOOGLE = 'google',
  PAYPAL = 'paypal'
}

export enum STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  SUSPENDED = 'suspended',
  DELETED = 'deleted'
}

export enum VALUES {
  TBD = 'TBD',
  NA = 'N/A'
}

export const getPlanTitle = (plan: PLAN): string => {
  const titles: {[key in PLAN]: string} = {
    free: 'Free',
    standard: 'Pro',
    growth: 'Growth',
    enterprise: 'Enterprise'
  }
  return titles[plan]
}