import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userBaseResponseSelector } from 'store/common/user/selectors'
import { CircularProgress, Typography } from '@material-ui/core'

interface IProtectedRouteProps extends RouteProps {
  path: string
  children: React.ReactNode
}

const OwnerProtectedRoute = ({ path, children, ...restProps }: IProtectedRouteProps) => {
  const user = useSelector(userBaseResponseSelector)

  if (!user.data && !user.errors) {
    return <CircularProgress size={50}/>
  }

  if (
    !user.data ||
    !!user.errors ||
    user.data.role !== 'owner'
  ) {
    return (
      <div
        style={{
          'minHeight': 'calc(100vh - 80px)',
          'display': 'flex',
          'flexDirection': 'column',
          'alignItems': 'center',
          'justifyContent': 'center'
        }}
      >
        <Typography variant='h1' align='center'>403</Typography>
        <Typography variant='h2' align='center'>Visiting this page is not permitted</Typography>
      </div>
    )
  }

  return (
    <Route path={path} {...restProps}>
      {children}
    </Route>
  )
}

export default OwnerProtectedRoute