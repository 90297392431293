import React from 'react'
import { Typography, TypographyProps, useTheme } from '@material-ui/core'

interface Props extends TypographyProps {}

const Grayed = (props: Props) => {
  const theme = useTheme()
  return (
    <Typography
      {...props}
      style={{
        color: theme.palette.grey['400'],
        fontStyle: 'italic',
        fontWeight: 'bold',
        display: 'inline-block',
        textDecoration: 'inherit',
        ...props.style
      }}
    >
      {props.children}
    </Typography>
  )
}

export default Grayed