import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { PipelinePageType, RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import {
  PipelineDashboardCountResponseModel,
  PipelineDashboardProjections,
} from 'models/hp/pipeline-dashboard/index.model'
import { BaseResponseModel } from 'models/base/base.model'

export const PIPELINE_DASHBOARD_COUNTERS_SET = 'PIPELINE_DASHBOARD_COUNTERS_SET'

export const getPipelineDashboardCount = async (dispatch: Dispatch) => {
  try {
    const res = await request<PipelineDashboardCountResponseModel>(RequestMethods.Get, Paths.Hp.Pipeline.dashboardCount, {
      schema: PipelineDashboardCountResponseModel
    })
    dispatch({
      type: PIPELINE_DASHBOARD_COUNTERS_SET,
      payload: [PipelinePageType.open, PipelinePageType.distant, PipelinePageType.upcoming].reduce((acc, item) => {
        acc[item] = res.data.data.find(x => x.type === item)?.count || 0
        return acc
      }, {} as any)
    })
  }
  catch (e) {
    console.log(e)
  }
}

export const setPipelineDashboardProjectedData = async (projected: PipelineDashboardProjections) => {
  await request(RequestMethods.Put, Paths.Hp.Pipeline.setDashBoardProjected, {
    schema: BaseResponseModel,
    body: projected
  })
}