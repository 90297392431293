import React, { ChangeEvent } from 'react'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { FilterOption } from 'models/hp/search/search.model'
import { IFilterOption } from 'models/hp/search/search.interface'
import { useSelector } from 'react-redux'
import { naicsCodesSelector } from 'store/hp/grouped-code/selectors'
import { searchNaicsCodesAction } from 'store/hp/grouped-code/actions'
import { getChildrenIds } from 'helpers/filter-option'

let searchDebounceTimer: number | null = null

interface Props {
  selectedNaics: IFilterOption[]
  handleChange: (id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
}

const ServiceLineNaicsFilter = ({ handleChange, handlePartialChange, selectedNaics }: Props) => {

  const naicsCodesOptions: IFilterOption[] = useSelector(naicsCodesSelector) as IFilterOption[]
  const [options, setOptions] = React.useState<FilterOption[]>([])

  const loadOptions = async (value: string) => {
    const response = await searchNaicsCodesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const onSelect = (state: boolean, value: IFilterOption | null) => {
    if (value && value.sub?.length === 0) {
      handleChange(value.id, state)
    } else if (value && value.sub && value.sub.length > 0) {
      const children: IFilterOption[] = []
      getChildrenIds(value.sub, (id: number, sub) => {
        children.push(sub!)
      })
      handlePartialChange(children.map(i => i.id), state, children)
    }
  }

  return (
    <SearchFilter
      options={options}
      onSearch={onSearch}
      onSelect={onSelect}
      searchLabel='NAICS search'
      iconType='naics'
      filterTitle='Industry Area (NAICS)'
      filterOptions={naicsCodesOptions}
      selectedFilterOptions={selectedNaics.map(i => i.id)}
      handleChange={handleChange}
      handlePartialChange={handlePartialChange}
    />
  )
}

export default ServiceLineNaicsFilter
