import React, { useState } from 'react'
import styles from './index.module.scss'
import { Typography, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import classNames from 'classnames'
import Targets from '../Targets'
import ValueOfOpsChart from '../ValueOfOpsChart'
import EstimatedRevenueChart from '../EstimatedRevenueChart'
import Skeletons from './components/Skeletons'
import { IPiplineHealthItem, IPiplineRevenueItem } from 'models/hp/pipeline/pipeline.interface'

type HealthRevenueChartType = 'ValueOfOps' | 'EstimatedRevenue'

interface HealthRevenueItemProps {
    title: string
    hasInfo?: boolean
    chartInfo?: {
        title: string | JSX.Element
        description: string | JSX.Element
      }
    chartType: HealthRevenueChartType
    isLoading: boolean
    data: IPiplineRevenueItem[] | IPiplineHealthItem[]
}

const HealthRevenueItem = ({title, hasInfo, chartInfo, chartType, isLoading, data}: HealthRevenueItemProps) => {
  const theme = useTheme()
  const [isViewInfo, setViewInfo] = useState<boolean>(false)

  if(isLoading) {
    return <Skeletons />
  }

  return (
    <div className={styles['healthRevenue-item']}>
      <div className={styles['healthRevenue-item-header']}>
        <Typography className={styles['healthRevenue-item-header-title']}>{title}</Typography>
        {hasInfo ? (
          <div onMouseEnter={() => setViewInfo(true)} onMouseLeave={() => setViewInfo(false)} >
            <SvgIcon className={styles['healthRevenue-item-header-info']} name='info-circle' color={theme.palette.grey['500']} />
          </div>
        )
          : null}
      </div>
      {chartType === 'ValueOfOps' ? 
        <ValueOfOpsChart data={data as IPiplineHealthItem[]} /> :
        <EstimatedRevenueChart data={data as IPiplineRevenueItem[]}/>}
      {chartType === 'ValueOfOps' ? <Targets data={data as IPiplineHealthItem[]} />: null}        
      { chartInfo ? (
        <div className={classNames(styles['healthRevenue-item-i'], {
          [styles['healthRevenue-item-i-view']]: isViewInfo
        })}>
          <Typography className={styles['healthRevenue-item-i-title']}>{chartInfo.title}</Typography>
          <Typography className={styles['healthRevenue-item-description']}>{chartInfo.description}</Typography>
        </div>
      ) : null
      }
    </div>
  )
}

export default HealthRevenueItem