import React, { useEffect } from 'react'
import styles from '../index.module.scss'
import { Paper, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { filterOptionsLoadedSelector, filterOptionsLoadingSelector } from 'store/hp/search/selectors'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { agenciesLoadedSelector } from 'store/hp/agency/selectors'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { getSearchOptions } from 'store/hp/search/actions'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import { getAgenciesAction } from 'store/hp/agency/actions'
import { getLocationsAction } from 'store/hp/location/action'
import { convertStringToKeywords } from 'helpers/search'
import NaicsFilter from '../../Filters/NaicsFilter'
import PscFilter from '../../Filters/PscFilter'
import DepartmentFilter from '../../Filters/DepartmentFilter'
import KeywordsFilterWithOptions from '../../Filters/KeywordsFilterWithOptions'

export interface IFilters {
  keywords: string[]
  customers: number[]
  naicsCodes: number[]
  pscCodes: number[]
}

export interface Props {
  getListing: (filters: IFilters) => void
  saveCustomers: () => void
}

const Filters = ({ getListing, saveCustomers }: Props) => {
  const dispatch = useDispatch()
  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector)
  const filterOptionsLoading = useSelector(filterOptionsLoadingSelector)
  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const agenciesLoaded = useSelector(agenciesLoadedSelector)
  const locationsLoaded = useSelector(locationsLoadedSelector)

  const [filters, setFilters] = React.useState<IFilters>({
    keywords: [],
    customers: [],
    naicsCodes: [],
    pscCodes: []
  })

  const handleChange = (key: keyof IFilters) => (id: number | string, state: boolean) => {
    if (state) {
      setFilters(prev => ({...prev, [key]: [...prev[key], +id]}))
    } else {
      setFilters(prev => ({
        ...prev,
        [key]: (prev[key] as Array<string|number>).filter(x => x !== +id)
      }))
    }
  }

  const handlePartialChange = (key: keyof IFilters) => (ids: Array<string | number>, state: boolean) => {
    if (state) {
      setFilters(prev => ({...prev, [key]: (prev[key] as Array<string | number>).concat(ids.map(id => +id))}))
    } else {
      setFilters(prev => ({
        ...prev,
        [key]: (prev[key] as Array<string | number>).filter(id => !ids.map(id => +id).includes(+id))
      }))
    }
  }

  const handleKeywordsChange = (keywords?: string) => {
    setFilters({
      ...filters,
      keywords: convertStringToKeywords(keywords) || []
    })
  }

  useEffect(() => {
    // Get all selectable options if not downloaded
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
    if (!groupedCodesLoaded) {
      getNaicsCodesAction(dispatch)()
      getPscCodesAction(dispatch)()
    }
    if (!agenciesLoaded) {
      getAgenciesAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }
  }, [])

  if (filterOptionsLoading) {
    return (
      <Paper className={styles['paper']}>
        <Grid container justifyContent='center'>
          <CircularProgress color='primary' />
        </Grid>
      </Paper>
    )
  }

  return (
    <Paper className={styles['paper']}>
      <Typography variant='h4' gutterBottom>
        Customers that are new vendor friendly
      </Typography>
      <KeywordsFilterWithOptions
        keywords={filters['keywords']}
        handleChange={handleKeywordsChange}
      />
      <div style={{height: '16px'}} />
      <PscFilter
        handleChange={handleChange('pscCodes')}
        handlePartialChange={handlePartialChange('pscCodes')}
        selectedOptions={filters['pscCodes']}
      />
      <div style={{height: '16px'}} />
      <NaicsFilter
        handleChange={handleChange('naicsCodes')}
        handlePartialChange={handlePartialChange('naicsCodes')}
        selectedOptions={filters['naicsCodes']}
      />
      <div style={{height: '16px'}} />
      <DepartmentFilter
        handleChange={handleChange('customers')}
        handlePartialChange={handlePartialChange('customers')}
        selectedOptions={filters['customers']}
      />
      <div style={{height: '16px'}} />
      <Button
        color='primary'
        variant='contained'
        fullWidth
        onClick={() => getListing(filters)}
      >
        Find friendly customers
      </Button>
      <div style={{height: '16px'}} />
      <Button
        color='primary'
        variant='outlined'
        fullWidth
        onClick={() => saveCustomers()}
      >
        Save selected customers
      </Button>
    </Paper>
  )
}

export default Filters