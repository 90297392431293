import * as React from 'react'
import { Grid, Typography } from '@material-ui/core'
import styles from './index.module.scss'


interface Props {
  iconType: string // should match class in styles to add icon
  title: string
  onClick: () => void
}

const LinkItem = ({iconType, title, onClick}: Props) => {
  return (
    <Grid item onClick={onClick} className={styles['link-item']}>
      <div className={[styles['link-icon'], styles[iconType]].join(' ')}/>
      <Typography variant={'h5'} className={styles['link-title']}>{title}</Typography>
    </Grid>
  )

}

export default LinkItem
