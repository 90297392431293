import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, CircularProgress, Typography, Button, useTheme } from '@material-ui/core'
import styles from '../index.module.scss'
import ScrollManager from 'components/ScrollManager'
import {
  appendCompanyGroupFilteredAction,
  getCompanyGroupFilteredAction
} from 'store/hp/company-profiles/listing/actions'
import {
  companyProfileListingSelector,
  companyProfileFilterParamsSelector,
  companyProfileListingLoadingSelector, companyProfileListingLoadedSelector, companyProfileRankedListingSelector
} from 'store/hp/company-profiles/listing/selectors'
import CompanyListItem from 'components/v2/Company/CompanyListItem'
import CompanySearchFilters from '../components/SearchFilters'
import { userActivity } from 'store/common/owner/actions'
import { companyProfileSearchSelector } from 'store/hp/company-profiles/search/selectors'
import { CompanyProfilePreviewModel } from 'models/hp/company-profiles/base/company-profile.model'
import { groupTypes, useIncumbentGroupStrategy, userIncumbentGroups } from 'store/hp/company-profiles/search/reducers'
import RankedList from './RankedList'
import { getCompanyDetailsLink } from 'helpers/company'
import { ICompanyProfileParams } from 'store/hp/company-profiles/listing/reducers'

let searchDebounceTimer: number | null = null
let loadWithoutDebounce: boolean = true

const CompanyProfiles = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { type } = useParams<{ type: groupTypes }>()

  const companyProfileListing = useSelector(companyProfileListingSelector) as any
  const companyProfileRankedListing = useSelector(companyProfileRankedListingSelector)
  const companyProfileListingLoaded = useSelector(companyProfileListingLoadedSelector)
  const filterParams = useSelector(companyProfileFilterParamsSelector) as ICompanyProfileParams
  const search = useSelector(companyProfileSearchSelector)
  const companyListingLoading = useSelector(companyProfileListingLoadingSelector)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null)

  const setLoadWithoutDebounce = (state: boolean) => {
    loadWithoutDebounce = state
  }

  const scrollTop = () => {
    listRef && (listRef.scrollTop = 0)
  }

  const onLoadMore = async () => {
    setIsLoading(true)
    if (!isLoading) {
      await appendCompanyGroupFilteredAction(dispatch)(userIncumbentGroups[type], {
        ...filterParams,
        page: filterParams.page! + 1
      }, search)
      setIsLoading(false)
    }
  }

  const onGoToDetails = (item: { duns: string, name?: string }) => {
    history.push(getCompanyDetailsLink(item.duns, item.name, `vendors/group/${type}`))
  }

  useEffect(() => {
    if (companyProfileListingLoaded) {
      return
    }
    if (loadWithoutDebounce) {
      scrollTop()
      getCompanyGroupFilteredAction(dispatch)(userIncumbentGroups[type], { ...filterParams, page: 0 }, search)
      setLoadWithoutDebounce(false) // always reset to debounce after load
    } else {
      searchDebounceTimer && clearTimeout(searchDebounceTimer)
      searchDebounceTimer = window.setTimeout(() => {
        scrollTop()
        getCompanyGroupFilteredAction(dispatch)(userIncumbentGroups[type], { ...filterParams, page: 0 }, search)
      }, 1000)
    }
  }, [search])

  useEffect(() => {
    userActivity.event(userActivity.activities.openGroupSearchPage)
  }, [])

  const renderList = () => {
    if (companyListingLoading) {
      return (
        <Grid style={{ height: '100%' }} container justifyContent='center' alignItems='center'>
          <CircularProgress color='primary'/>
        </Grid>
      )
    }
    if (companyProfileListingLoaded && companyProfileListing.count === 0 && companyProfileRankedListing.count === 0) {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Typography>Nothing was found, please try another filters</Typography>
        </Grid>
      )
    }
    return (
      <ScrollManager scrollKey={type}>
        {({ connectScrollTarget }: any) =>
          <div ref={(ref) => {
            connectScrollTarget(ref)
            setListRef(ref)
          }} className={styles['list']}>
            <>
              <Typography style={{ textAlign: 'center', margin: '15px 0' }} variant='h3'>
                Company name search
              </Typography>
              {companyProfileListing.data.length > 0 ? companyProfileListing.data.map((item: CompanyProfilePreviewModel, idx: number) => (
                <CompanyListItem
                  key={idx}
                  onClick={() => onGoToDetails({
                    name: item.name,
                    duns: item.duns
                  })}
                  company={item}
                />
              )) : <Grid container justifyContent='center' alignItems='center'>
                <Typography>Nothing was found, please try another filters</Typography>
              </Grid>}
              {companyProfileListing.count > companyProfileListing.data.length &&
                <Button
                  style={{ backgroundColor: theme.palette.primary.contrastText }}
                  fullWidth
                  color='primary'
                  variant='text'
                  onClick={onLoadMore}
                  disabled={isLoading}
                >
                  Load more
                </Button>
              }
              <RankedList
                rankedList={companyProfileRankedListing}
                onGoToDetails={onGoToDetails}
              />
            </>
          </div>
        }
      </ScrollManager>
    )
  }

  const title = type === userIncumbentGroups.like ?
    `${useIncumbentGroupStrategy[type]} filtering-${companyProfileListing.count} matches` :
    `
    ${useIncumbentGroupStrategy[type]} filtering-${companyProfileListing.count} matches,
    Ranked filtering-${companyProfileRankedListing.count} matches
    `

  return (
    <div className={styles['company-list']}>
      <Grid container className={styles['height-block']} spacing={2}>
        <Grid className={styles['filter-block']} style={{ width: '100%' }} item md={3}>
          <CompanySearchFilters
            title={title}
            count={companyProfileListing.count}
            setLoadWithoutDebounce={() => setLoadWithoutDebounce(true)}/>
        </Grid>
        <Grid item md={9} className={styles['height-block']}>
          <div className={styles['container']}>
            <div className={styles['container-inner']}>
              {renderList()}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyProfiles
