import React, { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Modal from 'components/v2/Modal'
import { Typography } from '@material-ui/core'
import { ERROR_MESSAGES } from 'utils/message'
import { useNotification } from 'context/NotificationContext'
import { updateProfile } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { userBaseResponseSelector } from 'store/common/user/selectors'
import { userLogoutAction } from 'store/common/user/actions'
import { Formik, FormikProps } from 'formik'
import FormRenderer from './FormRenderer'

export interface IProfile {
  email: string
}

export const PROFILE_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Field'))
})

interface Props {
  cancelUpdate: () => void
}

const Form = ({ cancelUpdate }: Props) => {
  const { show } = useNotification()
  const dispatch = useDispatch()
  const history = useHistory()

  const [ isRequested, setIsRequested ] = useState(false)
  const [ formValues, setFormValues ] = useState<IProfile | null>(null)
  const [ modalOpened, setModalOpened ] = useState(false)

  const user = useSelector(userBaseResponseSelector)

  const onCloseModal = () => {
    setFormValues(null)
    setModalOpened(false)
  }

  const onSubmit = async (values: IProfile) => {
    setFormValues(values)
    setModalOpened(true)
  }

  const onSubmitConfirm = async () => {
    setModalOpened(false)
    setIsRequested(true)
    try {
      const { email } = formValues!
      const response = await updateProfile(user.data?.firstName!, user.data?.lastName!, email)

      setIsRequested(false)
      show(response?.message || 'You have to confirm your email', NotificationTypeList.Info)
      cancelUpdate() // close form
      userLogoutAction(dispatch)()
      history.push(`/auth/verify/${email}`)

    } catch (error) {
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Error)
      setIsRequested(false)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: user.data?.email || ''
        }}
        validationSchema={PROFILE_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formProps: FormikProps<IProfile>) => (
          <FormRenderer
            {...formProps}
            isRequested={isRequested}
            cancelUpdate={cancelUpdate}
          />
        )}
      </Formik>

      {/*Modal*/}
      { modalOpened && (
        <Modal
          onClose={onCloseModal}
          onConfirm={onSubmitConfirm}
          open={modalOpened}
          title='Update email confirmation'
        >
          <Typography gutterBottom align='center'>To proceed successfully you have to be sure that you have an access to:</Typography>
          <Typography variant='h5' gutterBottom align='center' color='primary'>{formValues!.email}</Typography>
          <Typography gutterBottom align='center'>Otherwise, you won't be able to access your FedScout account after changing email address</Typography>
        </Modal>
      )}
    </div>
  )
}

export default Form