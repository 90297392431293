import { Record } from 'immutable'
import { IAction } from 'types/common'
import { IPipelineCard } from 'models/hp/pipeline/pipeline.interface'
import { 
  GET_CARDS_LIST,
  GET_CARDS_LIST_ERROR,
  GET_CARDS_LIST_SUCCESS,
  UPDATE_TASKS
} from './actions'

export interface IPipelineTaskCardsRecord {
  taskCards: {
    loaded: boolean
    loading: boolean
    data: IPipelineCard[],
  }
}

const PipelineTaskCardsRecord = Record<IPipelineTaskCardsRecord>({
  taskCards: {
    loaded: false,
    loading: false,
    data: [],
  }
})

export const PipelineTaskCardsReducer = (state: Record<IPipelineTaskCardsRecord> = new PipelineTaskCardsRecord(), action: IAction) => {
  switch (action.type) {
    case GET_CARDS_LIST:
      return state
        .setIn(['taskCards', 'loading'], true)
    case GET_CARDS_LIST_SUCCESS:
      return state
        .setIn(['taskCards', 'loading'], false)
        .setIn(['taskCards', 'loaded'], true)
        .setIn(['taskCards', 'data'], action.payload)
    case GET_CARDS_LIST_ERROR:
      return state
        .setIn(['taskCards', 'loading'], false)
    case UPDATE_TASKS:
      return state
        .updateIn(['taskCards', 'data'], (data) => {
          const tasks = data as unknown as IPipelineCard[]
          return tasks.map((el: IPipelineCard) => el.id === action.payload.id ? action.payload : el)
        })
    default:
      return state
  }
}