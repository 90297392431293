import { Record } from 'immutable'
import { awardSearchReducer, IAwardSearchRecord } from './search/reducer'
import { awardsListingReducer, IAwardsListing } from './listing/reducer'
import { awardsListingCategorizedReducer, IAwardsListingCategorized } from './categorizationListing/reducer'
import { IAwardsDashboard, awardsDashboardReducer } from './dashboard/reducer'
import { IAwardsDashboardSimple, awardsDashboardSimpleReducer } from './dashboard-simple/reducer'
import { combineReducers } from 'redux'

export interface IAwardsRootReducer {
  search: Record<IAwardSearchRecord>
  listing: Record<IAwardsListing>
  categorizedListing: Record<IAwardsListingCategorized>
  dashboard: Record<IAwardsDashboard>
  dashboardSimple: Record<IAwardsDashboardSimple>
}

export const rootAwardsReducer = combineReducers<IAwardsRootReducer>({
  search: awardSearchReducer,
  listing: awardsListingReducer,
  categorizedListing: awardsListingCategorizedReducer,
  dashboard: awardsDashboardReducer,
  dashboardSimple: awardsDashboardSimpleReducer
})
