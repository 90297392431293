import { Grid, Typography } from '@material-ui/core'
import { FormikProps } from 'formik'
import React from 'react'
import TextField from '../TextField'
import { ICreateLazyAccountForm } from 'modules/Auth/SignIn/components/Form'

interface IFormRendererProps extends FormikProps<ICreateLazyAccountForm> {
  isRequested: boolean
  description?: string | React.ReactChild
}

const FormRenderer = ({values, handleSubmit, handleChange, handleBlur, touched, errors, isRequested, description}: IFormRendererProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography
        align='center'
        variant='h6'
        gutterBottom
      >
        {description}
      </Typography>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <TextField
            onBlur={handleBlur('email')}
            error={!!touched.email && !!errors.email}
            helperText={!!touched.email && !!errors.email && errors.email}
            value={values.email}
            onChange={handleChange('email')}
            label='Your email address'
            fullWidth
            variant='filled'
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer
