import { Record } from 'immutable'
import { combineReducers } from 'redux'
import { pipelineBoardListReducer, IPipelineBoardListRecord } from './rfp/reducer'
import { IPipelineTaskBoardListRecord, pipelineTaskBoardListReducer } from './task/reducer'
import { IPipelineDashboardRecord, pipelineDashboardReducer } from 'store/hp/pipeline/dashboard/reducer'
import { IPipelineTaskCardsRecord, PipelineTaskCardsReducer } from './task-card/reducer'

export interface IPipelineRootReducer {
  rfp: Record<IPipelineBoardListRecord>
  task: Record<IPipelineTaskBoardListRecord>
  dashboard: Record<IPipelineDashboardRecord>
  taskCards: Record<IPipelineTaskCardsRecord>
}

export const rootPipelineReducer = combineReducers<IPipelineRootReducer>({
  rfp: pipelineBoardListReducer,
  task: pipelineTaskBoardListReducer,
  dashboard: pipelineDashboardReducer,
  taskCards: PipelineTaskCardsReducer
})
