import { IEducationPhaseClass } from 'models/hp/education/education.interface'

export enum EDUCATION_DUAL_VC_SUBTITLE {
  WHY_USE_DUAL_USE_VC = 'WHY_USE_DUAL_USE_VC',
  THE_TYPES_OF_DUAL_USE = 'THE_TYPES_OF_DUAL_USE',
  THE_DUAL_USE_PITCH_DECK = 'THE_DUAL_USE_PITCH_DECK',
  PITCH_DECK_TEMPLATE = 'PITCH_DECK_TEMPLATE',
  COMMON_QUESTIONS = 'COMMON_QUESTIONS',
  GETTING_A_PITCH = 'GETTING_A_PITCH',
  YOUR_GOV_CUSTOMER = 'YOUR_GOV_CUSTOMER',
  FINDING_YOUR_CUSTOMER = 'FINDING_YOUR_CUSTOMER',
  FINDING_KEY_GOVIES = 'FINDING_KEY_GOVIES',
  FINDING_BUDGET = 'FINDING_BUDGET',
  CONTRACTING = 'CONTRACTING',
  YOUR_FEDERAL_TRACTION = 'YOUR_FEDERAL_TRACTION'
}


export const EDUCATION_DUAL_VC_IFRAME_SOURCE: { [key: string]: string | undefined } = {
  [EDUCATION_DUAL_VC_SUBTITLE.WHY_USE_DUAL_USE_VC]: 'https://www.fedscout.com/why-dual-use-vc-is-a-thing',
  [EDUCATION_DUAL_VC_SUBTITLE.THE_TYPES_OF_DUAL_USE]: 'https://www.fedscout.com/types-of-dual-use-vc',
  [EDUCATION_DUAL_VC_SUBTITLE.THE_DUAL_USE_PITCH_DECK]: 'https://www.fedscout.com/the-dual-use-pitch-deck',
  [EDUCATION_DUAL_VC_SUBTITLE.PITCH_DECK_TEMPLATE]: 'https://www.fedscout.com/the-dual-use-pitch-deck',
  [EDUCATION_DUAL_VC_SUBTITLE.YOUR_GOV_CUSTOMER]: 'https://www.fedscout.com/choosing-your-first-government-customers',
  [EDUCATION_DUAL_VC_SUBTITLE.FINDING_YOUR_CUSTOMER]: 'https://app.fedscout.com/awards',
  [EDUCATION_DUAL_VC_SUBTITLE.FINDING_KEY_GOVIES]: 'https://www.fedscout.com/finding-program-officers',
  [EDUCATION_DUAL_VC_SUBTITLE.FINDING_BUDGET]: 'https://www.fedscout.com/go-to-market-finding-budget',
  [EDUCATION_DUAL_VC_SUBTITLE.CONTRACTING]: 'https://www.fedscout.com/go-to-market-contracting-models',
  [EDUCATION_DUAL_VC_SUBTITLE.YOUR_FEDERAL_TRACTION]: 'https://www.fedscout.com/differentiating-federal-dollars',
  [EDUCATION_DUAL_VC_SUBTITLE.COMMON_QUESTIONS]: 'https://www.fedscout.com/common-questions-from-vcs',
  [EDUCATION_DUAL_VC_SUBTITLE.GETTING_A_PITCH]: 'https://www.fedscout.com/getting-a-meeting-with-a-dual-use-vc'
}

export const PitchingDualVcInvestors: IEducationPhaseClass[] = [{
  name: EDUCATION_DUAL_VC_SUBTITLE.WHY_USE_DUAL_USE_VC, title: 'Why Dual-Use VC is a Thing'
}, {
  name: EDUCATION_DUAL_VC_SUBTITLE.THE_TYPES_OF_DUAL_USE, title: 'The Types of Dual-Use VCs'
}, {
  name: EDUCATION_DUAL_VC_SUBTITLE.THE_DUAL_USE_PITCH_DECK, title: 'The Dual-Use Pitch Deck', children: [{
    name: EDUCATION_DUAL_VC_SUBTITLE.PITCH_DECK_TEMPLATE, title: 'Pitch Deck Template'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.YOUR_GOV_CUSTOMER, title: 'Your Gov. Customer'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.FINDING_YOUR_CUSTOMER, title: 'Finding Your customer'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.FINDING_KEY_GOVIES, title: 'Finding Key Govies'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.FINDING_BUDGET, title: 'Finding Budget'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.CONTRACTING, title: 'Contracting'
  }, {
    name: EDUCATION_DUAL_VC_SUBTITLE.YOUR_FEDERAL_TRACTION, title: 'Your Federal Traction'
  }]
}, {
  name: EDUCATION_DUAL_VC_SUBTITLE.COMMON_QUESTIONS, title: 'Common Questions'
}, {
  name: EDUCATION_DUAL_VC_SUBTITLE.GETTING_A_PITCH, title: 'Getting a Pitch'
}]


export const PitchingDualVcInvestorsNavs = PitchingDualVcInvestors.map(phase => phase.children ? [phase, ...phase.children] : phase).flat()

