import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

@jsonObject
export class TokenModel {
  @jsonMember({ constructor: String })
  id = ''

  @jsonMember({ constructor: String })
  token = ''

  @jsonMember({ constructor: Boolean })
  active = true

  @jsonMember({ constructor: Date })
  expirationDate = new Date()

  @jsonMember({ constructor: Date })
  updatedAt = new Date()
}

@jsonObject
export class TokenModelResponseModel extends BaseResponseModel {
  @jsonArrayMember(TokenModel)
  data: Array<TokenModel> = []
}

@jsonObject
export class CreateTokenResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: TokenModel })
  data: TokenModel = new TokenModel()
}