import React, { useEffect } from 'react'
import styles from '../index.module.scss'
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow
} from '@material-ui/core'
import { Pagination } from 'models/base/base.interfaces'
import { FeedbackModel } from 'models/hp/feedback/feedback.model'
import { getFeedbackList } from 'store/hp/feedback/action'
import FeedbackDetailsModal from 'modules/Main/Owner/modules/Feedback/components/FeedbackDetailsModal'

const tableHead: Array<{ label: string, id: string, sortable?: true }> = [{
  id: 'email', label: 'Email'
}, {
  id: 'type', label: 'Type'
}, {
  id: 'subject', label: 'Subject'
}, {
  id: 'createdAt', label: 'Created At'
}]

const Feedback = () => {
  const [feedbacks, setFeedback] = React.useState<FeedbackModel[]>([])
  const [feedbackCount, setFeedbackCount] = React.useState<number>(0)

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20)
  const [page, setPage] = React.useState<number>(0)

  const [activeFeedback, setActiveFeedback] = React.useState<FeedbackModel | null>(null)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    requestFeedbacks({
      page: newPage, limit: rowsPerPage
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    requestFeedbacks({
      page: 0, limit: Number.parseInt(event.target.value)
    })
  }

  const requestFeedbacks = async({page, limit, q}: Pagination) => {
    try {
      const data = await getFeedbackList({page, limit, q})
      setFeedbackCount(data.count)
      setFeedback(data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    requestFeedbacks({
      limit: rowsPerPage, page
    })
  }, [])

  return (
    <div className={[styles['wrapper'], styles['feedback-wrapper']].join(' ')}>
      <Paper>
        <TableContainer>
          <Table size={'small'} aria-label={'a dense table'}>
            <TableHead>
              <TableRow>
                {tableHead.map(head => (
                  <TableCell key={head.id}>{head.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbacks.map((feedback) => (
                <TableRow key={feedback.id} onClick={() => setActiveFeedback(feedback)} className={styles['table-row']}>
                  <TableCell>{feedback.email}</TableCell>
                  <TableCell>{feedback.type}</TableCell>
                  <TableCell>{feedback.subject || ''}</TableCell>
                  <TableCell>{feedback.createdAt.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          page={page}
          count={feedbackCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20, 40, 50]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {activeFeedback && (
        <FeedbackDetailsModal
          feedback={activeFeedback}
          onClose={() => setActiveFeedback(null)}
        />
      )}
    </div>
  )
}

export default Feedback