import React from 'react'
import styles from './index.module.scss'
import { Button, Dialog, Grid, Typography } from '@material-ui/core'
import { handleStep } from '../Steps/helpers'
import { useHistory } from 'react-router-dom'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import SvgIcon from 'components/SvgIcon'

interface NotAuthorizedDialogProps {
  isOpen: boolean
  user: IUserBaseModel
  handleClose?: () => void
}

const NotAuthorizedDialog = ({isOpen, user, handleClose}: NotAuthorizedDialogProps) => {
  const history = useHistory()
  const splitLocation = history.location.pathname.split('/')

  const onClose = () => {
    if(handleClose) {
      handleClose()
      return
    }
    handleStep('overview', history, user, splitLocation)
  }

  const handleLogin = () => {
    history.push('/auth/sign-in')
  }

  const handleSignUp = () => {
    history.push('/auth/sign-up')
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl">
      <div className={styles['dialog-wrapper']}>
        <SvgIcon className={styles['dialog-close-btn']} name='multiply' onClick={onClose} />
        <Grid 
          container
          spacing={2}
          direction='column'
          alignItems='center'
          justifyContent='center'
          className={styles['dialog-info']}
        >
          <Grid item>
            <Typography className={styles['dialog-info-title']}>
              Please login to your account
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={styles['dialog-info-description']}>
              To access the functionality of the current page, you need to log in or create an account if you don’t already have one.
            </Typography>
          </Grid>
        </Grid>
        <div className={styles['dialog-actions']}>
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            onClick={handleLogin}
          >
            Log in
          </Button>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={handleSignUp}
          >
            Sign up
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default NotAuthorizedDialog