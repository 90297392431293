import { Record } from 'immutable'
import { IAction } from 'types/common'
import { AWARD_DASHBOARD_SIMPLE_ERROR, AWARD_DASHBOARD_SIMPLE_SUCCESS, AWARD_DASHBOARD_SIMPLE, AWARD_DASHBOARD_SIMPLE_SET_FILTER } from './actions'
import { DashboardSimplePayload } from 'models/hp/awards/awards.model'

export interface IAwardsDashboardSimpleFilters {
  customer: number
  partner: number
}

export interface IAwardsDashboardSimple {
  data: DashboardSimplePayload
  loading: boolean
  loaded: boolean
  filters: IAwardsDashboardSimpleFilters
}

const AwardsDashboardSimple = Record<IAwardsDashboardSimple>({
  data: new DashboardSimplePayload(),
  loading: false,
  loaded: false,
  filters: {
    customer: 0,
    partner: 0
  }
})

export const awardsDashboardSimpleReducer = (state: Record<IAwardsDashboardSimple> = new AwardsDashboardSimple(), action: IAction) => {
  switch (action.type) {
    case AWARD_DASHBOARD_SIMPLE:
      return state
        .set('loading', true)

    case AWARD_DASHBOARD_SIMPLE_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)

    case AWARD_DASHBOARD_SIMPLE_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)

    case AWARD_DASHBOARD_SIMPLE_SET_FILTER:
      return state
        .update('filters', (filters) => ({
          ...filters,
          [action.payload.key]: action.payload.value
        }))

    default:
      return state
  }
}
