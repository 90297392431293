import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import ContractItem from './components/ContractItem'
import SaveModule from './components/SaveModule'
import { useDispatch, useSelector } from 'react-redux'
import { getPiplinePendingAwards, resetPiplinePendingAwards, setPiplinePendingAwards, upadatePiplinePendingAwards } from 'store/hp/pipeline/rfp/action'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { pipelinePenmdingAwardCountSelector, pipelinePenmdingAwardSelector } from 'store/hp/pipeline/rfp/selectors'
import Skeletons from './components/Skeletons'
import { Formik, FormikProps } from 'formik'
import { IPiplinePendingAward, IPiplinePendingAwardsBody } from 'models/hp/pipeline/pipeline.interface'
import * as Yup from 'yup'
import inputValidation from 'utils/inputValidation'
import { v4 as uuidv4 } from 'uuid'
import ScrollManager from 'components/ScrollManager'

const PendingAward = () => {
  const dispatch = useDispatch()
  const notification = useNotification()
  const {loading, loaded, data: {data: pendingAwards}} = useSelector(pipelinePenmdingAwardSelector)
  const pendingAwardsCount = useSelector(pipelinePenmdingAwardCountSelector)
  const { required } = inputValidation()

  const [activeContract, setActiveContract] = useState<IPiplinePendingAward | null>(null)

  const formValidationSchema = Yup.object().shape({
    outcome: required,
  })

  const initialValues = {
    description: activeContract?.pipelineAnalysis?.description || [],
    outcome:  activeContract?.pipelineAnalysis?.outcome || ''
  }

  useEffect(() => {
    getPiplinePendingAwards(dispatch)()
      .catch(e => notification.show(getErrorMessage(e), NotificationTypeList.Error))
    return () => {
      resetPiplinePendingAwards(dispatch)
    }
  }, [])

  const onSubmit = async (values: IPiplinePendingAwardsBody) => {
    upadatePiplinePendingAwards(dispatch)(activeContract!.id, values)
    setPiplinePendingAwards(dispatch)(activeContract!.id, values)
  }

  const loadMore = async (offset: number) => {
    try {
      if(!loading) {
        await getPiplinePendingAwards(dispatch)(offset)
      }
    } catch (e) {
      notification.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const body = () => {
    if(!loaded) {
      return <Skeletons />
    }

    if(pendingAwards && pendingAwards.length) {
      return (
        <Formik 
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={formValidationSchema}
          isInitialValid={formValidationSchema.isValidSync(initialValues)}
          validateOnChange
          enableReinitialize
        >
          {(formikProps: FormikProps<IPiplinePendingAwardsBody>) => (
            <div className={styles['pending-award-main']}>
              <ScrollManager scrollKey={'hp-rfp-archive'}>
                {({connectScrollTarget, onScrollBottom}: any) => (
                  <div className={styles['pending-award-left']} ref={(ref) => {
                    connectScrollTarget(ref)
                    onScrollBottom(ref, () => loadMore(pendingAwards.length))
                  }}>
                    <div className={styles['pending-award-list']}>
                      {
                        pendingAwards.map(el => (
                          <div key={`${el.id}-${uuidv4()}`} className={styles['pending-award-item']}>
                            <div className={styles['pending-award-item-contracts']}>
                              <ContractItem 
                                contract={el} 
                                activeContract={activeContract}
                                setActiveContract={setActiveContract}
                                formikProps={formikProps}
                              />
                            </div>
                          </div>
                        ))
                      }
                      {pendingAwardsCount > pendingAwards.length && (
                        <div className={styles['pending-award-progress']}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </ScrollManager>
              
              {activeContract && (
                <SaveModule 
                  formikProps={formikProps}
                  activeContract={activeContract}
                  setActiveContract={setActiveContract} 
                />
              )}
            </div>
          )}
        </Formik>
      )
    }

    if(!loading && !pendingAwards.length) {
      return <Typography style={{textAlign: 'center'}} className={styles['pending-award-title']}>Data not found</Typography>
    }
  }

  return (
    <Paper className={styles['pending-award']}>
      <Typography className={styles['pending-award-title']}>Anticipated Award</Typography>
      {body()}
    </Paper>
  )
}

export default PendingAward