import React from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import styles from '../../index.module.scss'
import { VideoSourceType } from 'utils/const'

const SearchIntro = () => {
  const history = useHistory()
  return (
    <div>
      <Typography variant='h2'>Market research: an Overview</Typography>
      <Grid container wrap='nowrap' className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_INTRO}/>
        </Grid>
        <Grid item md={8} className={styles['right-block']}>
          <div className={styles['right-block-content']} style={{height: 'calc(100% - 45px)'}}>
            <Grid className={styles['form']}>
              <Typography variant='h4'>Why you should do market research:</Typography>
              <Typography>• The federal market is massive, diverse, and fragmented</Typography>
              <Typography gutterBottom>• The government buys about $500B a year in good and services, so they probably buy what
                you are selling, but finding your customers, partners, and contracts is challenging</Typography>

              <Typography variant='h4'>What this tool will help you find:</Typography>
              <Typography>• The right keywords and codes to surface the most promising contracts • The size of the market in
                your area</Typography>
              <Typography>• The most promising government customers</Typography>
              <Typography>• A list of government officers to engage</Typography>
              <Typography gutterBottom>• A list of prospective partners/ competitors</Typography>
              <Typography variant='h4'>The Process:</Typography>
              <Typography>• We will start with keywords about your products and services, and then build and refine our
                understanding of your market through a series of guided questions</Typography>
              <Typography>• This exercise will probably take 30 minutes</Typography>
              <Typography>• You can save your work and come back at any time</Typography>
              <Typography>• If you have a FedScout account we will save your progress and insights so that you can quickly build
                searches and get alerts about promising contracts</Typography>
            </Grid>
            <Grid container justifyContent='flex-end' style={{ paddingTop: '32px' }}>
              <Grid item>
                <Button
                  size='large'
                  variant='contained'
                  color='primary'
                  onClick={() => history.push('/user/market-research/search-name')}
                >Start</Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchIntro