import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import {
  MARKET_RESEARCH_RESET,
  MARKET_RESEARCH_SET_SEARCH, MARKET_RESEARCH_UPDATE_FILTER,
  MARKET_RESEARCH_UPDATE_SEARCH_NAME
} from './actions'
import { SearchModel } from 'models/hp/search/search.model'

export interface IMarketResearch {
  search: SearchModel
}

const MarketResearchRecord = Record<IRecord<IMarketResearch>>({
  loading: false,
  loaded: false,
  data: {
    search: new SearchModel()
  }
})

export const marketResearchReducer = (state: Record<IRecord<IMarketResearch>> = new MarketResearchRecord(), action: IAction<any>) => {
  switch (action.type) {
    case MARKET_RESEARCH_SET_SEARCH:
      return state
        .set('loading', false)
        .set('loaded', true)
        .updateIn(['data', 'search'], (val: unknown) => ({
          ...val as SearchModel,
          ...action.payload
        }))
    case MARKET_RESEARCH_UPDATE_SEARCH_NAME:
      return state
        .updateIn(['data', 'search'], (val: unknown) => ({
          ...val as SearchModel,
          name: action.payload
        }))
    case MARKET_RESEARCH_UPDATE_FILTER:
      return state
        .updateIn(['data', 'search'], (val: unknown) => ({
          ...val as SearchModel,
          [action.payload.field]: action.payload.value
        }))
    case MARKET_RESEARCH_RESET:
      return new MarketResearchRecord()
    default:
      return state
  }
}