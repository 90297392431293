import React from 'react'
import styles from './index.module.scss'
import { Modal as MuiModal, Paper, Button, Grid, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

type Size = 'md' | 'lg' | 'xl' | 'xxl' | 'auto' // check styles
type Type = 'action' | 'info'
export interface Props {
  title?: string | React.ReactChild
  open: boolean
  onClose: () => void
  size?: Size
  type?: Type
  className?: string
  onConfirm?: () => void
  confirmLoading?: boolean
  children?: React.ReactNode | React.ReactNodeArray
  bodyStyles?: React.CSSProperties
}

const Modal = ({title, open, size = 'md', className, onConfirm, confirmLoading = false, onClose, children, type, bodyStyles}: Props) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      className={[
        styles['modal'],
        className
      ].join(' ')}
    >
      <Paper className={[
        styles['modal-inner'],
        styles[`modal-inner-${size}`]
      ].join(' ')}>
        {
          title && (
            <div className={styles['modal-header']}>
              <Grid container justifyContent='flex-end'>
                <Button variant='text' onClick={onClose}>
                  <SvgIcon name='multiply'/>
                </Button>
              </Grid>
              {typeof title === 'string' ?
                <Typography variant='h2'>{title}</Typography>
                :
                title
              }
            </div>
          )
        }

        <div className={styles['modal-body']} style={bodyStyles}>
          {children}
        </div>

        {onConfirm && (
          <div className={styles['modal-footer']}>
            <Grid container alignItems='center' justifyContent='space-between' spacing={2}>
              {type && type !== 'info' &&
                <Grid item md={6}>
                  <Button
                    className={styles['confirm-btn']}
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              }
              <Grid item md={6}>
                <Button
                  className={styles['confirm-btn']}
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  onClick={onConfirm}
                  disabled={confirmLoading}
                >
                  {type === 'info' ? 'Next' : 'Confirm'}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </MuiModal>
  )
}

export default Modal
