import * as React from 'react'
import styles from './index.module.scss'
import { LinearProgress, useTheme, makeStyles, Typography } from '@material-ui/core'

interface Props {
  value: number
  color?: string
  transparent?: boolean
  height?: number
  label?: string
}

const BorderLinearProgress = ({ value, color, transparent, height, label }: Props) => {
  const theme = useTheme()
  let primaryColor = color ? color : '#FFBE30'  // custom color used only here
  let secondaryColor = transparent ? 'transparent' : theme.palette.grey['300']
  const useStyles = makeStyles({
    root: {
      height: height || 25,
      borderRadius: 4,
    },
    colorPrimary: {
      backgroundColor: secondaryColor,
      border: `1px solid ${theme.palette.grey['300']}`
    },
    bar: {
      borderRadius: 4,
      backgroundColor: primaryColor,
    },
  })

  const classes = useStyles()

  return (
    <div className={styles['label-wrapper']}>
      <LinearProgress
        variant='determinate'
        value={value}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar
        }}
      />
      {label && (
        <Typography variant='h5' className={styles['label']}>{label}</Typography>
      )}
    </div>
  )
}

export default BorderLinearProgress