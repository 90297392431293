import React from 'react'

interface ISvgIconProps {
  name: string
  className?: string
  color?: string
  width?: number
  height?: number
  onClick?: () => void
}

const dw = 20
const dh = 20

const SvgIcon = (props: ISvgIconProps) => {
  return (
    <div
      className={props.className}
      onClick={props.onClick && props.onClick}
      style={{
        display: 'flex',
        width: props.width || `${dw}px`,
        height: props.height || `${dh}px`
      }}
    >
      <svg
        width={props.width || dw}
        height={props.height || dh}
      >
        <use
          xlinkHref={`#${props.name}`}
          fill={props.color || ''}
          width={props.width || dw}
          height={props.height || dh}
        />
      </svg>
    </div>
  )
}

export default SvgIcon
