import { IEducationPhaseClass, IContactsChartData, ICustomerFinderContactsResponse, ICustomerFinderData } from 'models/hp/education/education.interface'
import { EDUCATION_SUBTITLE } from 'utils/constants/education'
import { IEducationProgressData } from 'modules/Main/Education/components/EducationProgressBarSvg'
import { Record } from 'immutable'
import { jsonArrayMember, jsonObject, jsonMember } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'

export class EducationPhaseClass implements IEducationPhaseClass {
  name: EDUCATION_SUBTITLE = EDUCATION_SUBTITLE.EDUCATION_OVERVIEW
  title: string = 'Education overview'
}

export const EducationProgressData = Record<IEducationProgressData>({
  planning: false,
  auditioning: false,
  luckyWinning: false,
  processWinning: false
})

export class LikedItem {
  name: string = ''
  email: string = ''
  phone: string = ''
  linkedIn: string = ''
}

@jsonObject
export class UploadItem {
  @jsonMember({ constructor: String })
  id: string = ''
}
@jsonObject
export class UploadFileResponseModel {
  @jsonArrayMember(UploadItem)
  data: UploadItem[] = []
}

@jsonObject
export class ContractsChartPositions {
  @jsonMember({ constructor: Number })
  key: number = 0

  @jsonMember({ constructor: Number })
  other: number = 0

  @jsonMember({ constructor: Number })
  total: number = 0
}

@jsonObject
export class ContractsChartSpending {
  @jsonMember({ constructor: Number })
  friendly: number = 0

  @jsonMember({ constructor: Number })
  other: number = 0
}

@jsonObject
export class ContractsChart {
  @jsonMember({ constructor: Number })
  id: number = 0

  @jsonMember({ constructor: Number })
  customerId?: Number

  @jsonMember({ constructor: String })
  agency: string = ''

  @jsonMember({ constructor: String })
  subagency: string = ''

  @jsonMember({ constructor: Object })
  positions: ContractsChartPositions = new ContractsChartPositions()

  @jsonMember({ constructor: Object })
  spending: ContractsChartSpending = new ContractsChartSpending()
}

@jsonObject
export class ContractsChartModel {
  @jsonMember({ constructor: Number })
  total: number = 0
  
  @jsonArrayMember(ContractsChart)
  agencies: IContactsChartData[] = []
}


//not need
@jsonObject
export class ContractsChartInnerResponseModel extends BaseResponseModel  {
  @jsonMember({constructor: ContractsChartModel})
  data: ContractsChartModel = new ContractsChartModel()
}

@jsonObject
export class ContractsChartResponseModel extends BaseResponseModel {
  @jsonMember({constructor: ContractsChartModel})
  data: ContractsChartModel = new ContractsChartModel()
}

@jsonObject
export class ContactsItem {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  position: string = ''

  @jsonMember({ constructor: String })
  connectedOn: string = ''

  @jsonMember({ constructor: String })
  company: string = ''
}

@jsonObject
export class ContactsListItem {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  priority: string = ''

  @jsonMember({ constructor: String })
  title: string = ''

  @jsonArrayMember(ContactsItem)
  contacts: ContactsItem[] = []

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  position: string = ''

  @jsonMember({ constructor: String })
  connectedOn: string = ''

  @jsonMember({ constructor: String })
  company: string = ''

  @jsonMember({ constructor: Number })
  total: number = 0

  @jsonMember({ constructor: Number })
  offset: number = 0

  @jsonMember({ constructor: Number })
  customerId: number = 0

  @jsonMember({ constructor: String })
  companyId: string = ''
}


@jsonObject
export class ContactsList {
  @jsonArrayMember(ContactsListItem)
  connections: ContactsListItem[] = []
}
@jsonObject
export class ContactsListResponseModel extends BaseResponseModel {
  @jsonMember({constructor: ContactsList})
  data: ContactsList = new ContactsList()
}

@jsonObject
export class ContactsListByIdResponseModel extends BaseResponseModel {
  @jsonMember({constructor: ContactsListItem})
  data: ContactsListItem = new ContactsListItem()
}

@jsonObject
export class CustomerFinderAwardItem {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  awardDescription: string = ''

  @jsonMember({ constructor: String })
  awardOrIdvFlag: string = ''

  @jsonMember({ constructor: String })
  awardType: string = ''

  @jsonMember({ constructor: String })
  awardingAgencyName: string = ''

  @jsonMember({ constructor: String })
  contractTransactionUniqueKey: string = ''

  @jsonMember({ constructor: String })
  currentTotalValueOfAward: string = ''

  @jsonMember({ constructor: String })
  idvType: null = null

  @jsonMember({ constructor: String })
  multipleOrSingleAwardIdvTypeCode: null = null

  @jsonMember({ constructor: String })
  parentAwardType: null = null

  @jsonMember({ constructor: String })
  periodOfPerformancePotentialEndDate: string = ''

  @jsonMember({ constructor: String })
  potentialTotalValueOfAward: string = ''

  @jsonMember({ constructor: String })
  primaryPOPCityName: string = ''

  @jsonMember({ constructor: String })
  primaryPOPStateCode: string = ''

  @jsonMember({ constructor: String })
  productOrServiceCode: string = ''

  @jsonMember({ constructor: String })
  productOrServiceCodeDescription: string = ''

  @jsonMember({ constructor: String })
  recipientName: string = ''

  @jsonMember({ constructor: String })
  totalDollarsObligated: string = ''

  @jsonMember({ constructor: String })
  typeOfSetAside: string = ''
}

@jsonObject
export class CustomerFinderAward { 
  @jsonMember({ constructor: Number })
  count: number = 0
  
  @jsonArrayMember(CustomerFinderAwardItem)
  rows: CustomerFinderAwardItem[] = []
}

@jsonObject
export class CustomerFinderContactsInnerModel implements ICustomerFinderData {
  @jsonMember({constructor: CustomerFinderAward})
  awards: CustomerFinderAward = new CustomerFinderAward()

  @jsonArrayMember(ContactsListItem)
  connections: ContactsListItem[] = []
}

@jsonObject
export class CustomerFinderContactsResponseModel extends BaseResponseModel implements ICustomerFinderContactsResponse {
  @jsonMember({constructor: CustomerFinderContactsInnerModel})
  data: CustomerFinderContactsInnerModel = new CustomerFinderContactsInnerModel()
}

@jsonObject
export class PartnersFinderSpending {
  @jsonMember({ constructor: Number })
  friendly: number = 0
}

@jsonObject
export class PartnersFinderContact {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  company: string = ''

  @jsonMember({constructor: Number})
  key: number = 0

  @jsonMember({constructor: Number})
  other: number = 0

  @jsonMember({ constructor: Object })
  spending: PartnersFinderSpending = new PartnersFinderSpending()

  @jsonMember({constructor: String})
  duns: string = ''
}

@jsonObject
export class PartnersFinder {
  @jsonMember({constructor: String})
  agency: string = ''

  @jsonMember({constructor: String})
  subagency: string = ''

  @jsonMember({constructor: Number})
  customerId: number = 0

  @jsonArrayMember(PartnersFinderContact)
  companies: PartnersFinderContact[] = []

  @jsonMember({constructor: Number})
  offset: number = 0

  @jsonMember({constructor: Number})
  total: number = 0

  @jsonMember({constructor: Number})
  id: number = 0
}

@jsonObject
export class PartnersFinderInnerResponseModel {
  @jsonArrayMember(PartnersFinder)
  agencies: PartnersFinder[] = []
  
  @jsonMember({constructor: Number})
  total: number = 0
}

@jsonObject
export class PartnersFinderResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: PartnersFinderInnerResponseModel })
  data: PartnersFinderInnerResponseModel = new PartnersFinderInnerResponseModel()
}

@jsonObject
export class PartnersFinderInnerByIdResponseModel {
  @jsonArrayMember(PartnersFinderContact)
  companies: PartnersFinderContact[] = []
  
  @jsonMember({constructor: Number})
  total: number = 0

  @jsonMember({constructor: Number})
  offset: number = 0
}

@jsonObject
export class PartnersFinderByIdResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: PartnersFinderInnerByIdResponseModel })
  data: PartnersFinderInnerByIdResponseModel = new PartnersFinderInnerByIdResponseModel()
}