import { AFTER_SIGN_UP, FROM_DETAILS } from './actions'
import { IAction } from 'types/common'
import { Record } from 'immutable'

const isAfterSignUpRecord = Record<{ isAfterSignUp: boolean, signUpFromDetails: string }>({
  isAfterSignUp: false,
  signUpFromDetails: ''
})

export const afterSignUpReducer = (state: Record<{ isAfterSignUp: boolean, signUpFromDetails: string }> = isAfterSignUpRecord(), action: IAction) => {
  switch (action.type) {
    case AFTER_SIGN_UP:
      return state.set('isAfterSignUp', action.payload)
    case FROM_DETAILS:
      return state.set('signUpFromDetails', action.payload)
    default:
      return state
  }
}
