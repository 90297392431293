import { Record } from 'immutable'
import { IAction } from 'types/common'
import { GroupModel, UserBaseModel, UserGroupModel, UserGroupPendingModel } from 'models/common/user/user.model'
import { 
  USER_GROUP_GET_USER_LIST, 
  USER_GROUP_GET_USER_LIST_SUCCESS, 
  USER_GROUP_GET_USER_LIST_ERROR,
  GET_USER_GROUP_INVITATION,
  GET_USER_GROUP_INVITATION_SUCCESS,
  GET_USER_GROUP_INVITATION_ERROR,
  GET_USER_PENDING_INVITATION,
  GET_USER_PENDING_INVITATION_SUCCESS,
  GET_USER_PENDING_INVITATION_ERROR,
  GET_LIST_OF_GROUPS,
  GET_LIST_OF_GROUPS_SUCCESS,
  GET_LIST_OF_GROUPS_ERROR
} from './actons'

export interface IUserGroupRecord {
  loaded: boolean
  loading: boolean
  data: UserBaseModel[]
  count: number,
  invitations: UserGroupModel[],
  invitationsPending: UserGroupPendingModel[]
  groupsList: {
    loading: boolean
    data: GroupModel[]
  }
}

const UserGroupRecord = Record<IUserGroupRecord>({
  data: [],
  count: 0,
  loading: false,
  loaded: false,
  invitations: [],
  invitationsPending: [],
  groupsList: {
    loading: false,
    data: []
  }
})

export const userGroupReducer = (state: Record<IUserGroupRecord> = new UserGroupRecord(), action: IAction) => {
  switch (action.type) {
    case USER_GROUP_GET_USER_LIST:
      return state
        .set('loading', true)
    case USER_GROUP_GET_USER_LIST_SUCCESS:
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('data', action.payload.data.data)
        .set('count', action.payload.data.count)
    case USER_GROUP_GET_USER_LIST_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case GET_USER_GROUP_INVITATION:
      return state
        .set('loading', true)
    case GET_USER_GROUP_INVITATION_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', false)
        .update('invitations', () => action.payload.data.data)
    case GET_USER_GROUP_INVITATION_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case GET_USER_PENDING_INVITATION:
      return state
        .set('loading', true)
    case GET_USER_PENDING_INVITATION_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', false)
        .update('invitationsPending', () => action.payload.data.data)
    case GET_USER_PENDING_INVITATION_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)
    case GET_LIST_OF_GROUPS:
      return state
        .update('groupsList', (groupsList) => ({...groupsList, loading: true}))
    case GET_LIST_OF_GROUPS_SUCCESS:
      return state
        .update('groupsList', (groupsList) => ({...groupsList, loading: false, data: action.payload.data.data}))
    case GET_LIST_OF_GROUPS_ERROR:
      return state
        .update('groupsList', (groupsList) => ({...groupsList, loading: false}))
    default:
      return state
  }
}