import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'

export interface IVerifyForm {
  verificationCode: string
}

export const VERIFY_FORM_INIT_VALUE: IVerifyForm = {
  verificationCode: ''
}

export const VERIFY_FORM_VALIDATION_SCHEMA = Yup.object({
  verificationCode: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Verification Code'))
    .matches(/^\d+$/, ERROR_MESSAGES.Field.OnlyOneType('Verification Code', 'numbers'))
})