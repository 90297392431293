import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import {
  ICreateUserGroupInvitationResponseModel,
  IGroupResponseModel,
  ILazyAccountTokenModel,
  IUserBaseModel,
  IUserGetResponseModel, IUserGroupRelation, IUserLazyAccountCreationResponseModel,
  IUserPaymentPlan,
  IUserPendingResponseModel,
  IUserRecoverPasswordVerifyCodeCheck, IUserResponseModel, IUserSettings,
  IUserSignInResponseModel,
  IUserSignUpResponseModel,
  IUserVerifyResponseModel,
} from './user.interfaces'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import {PLAN, PLATFORM, STATUS} from 'constants/payment'
import { IHpRfpDetailModel } from '../../hp/rfp/rfp.interface'
import { InvitationType, TRole } from 'constants/user-group'
import { userActivity } from 'store/common/owner/actions'

@jsonObject
export class UserPaymentPlanModel implements IUserPaymentPlan {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  lastReceipt: string = ''

  @jsonMember({ constructor: String })
  transactionId: string = ''

  @jsonMember({ constructor: String })
  platform: PLATFORM = PLATFORM.PAYPAL

  @jsonMember({ constructor: String })
  plan: PLAN = PLAN.SUB

  @jsonMember({ constructor: String })
  status: STATUS = STATUS.ACTIVE

  @jsonMember({ constructor: String })
  startDate: string = ''

  @jsonMember({ constructor: String })
  endDate: string = ''
}

@jsonObject
export class UserOwnerPaymentPlanModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  plan: PLAN | null = null

  @jsonMember({ constructor: String })
  status: STATUS = STATUS.ACTIVE

  @jsonMember({ constructor: String })
  endDate: string = ''

  @jsonMember({ constructor: Boolean })
  manageable: boolean = true
}

@jsonObject
export class UserSettingsModel implements IUserSettings {
  @jsonMember({constructor: Boolean})
  hpRfpLikeNotification: boolean = true

  @jsonMember({constructor: Boolean})
  hpAdsShow: boolean = true

  @jsonMember({constructor: Boolean})
  hpNewSearchItemEmailNotification: boolean = false

  @jsonMember({constructor: Boolean})
  hpPocNewItemEmailNotification: boolean = false
}

@jsonObject
export class UserIncumbentGroup {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: String })
  title: string = ''
}

@jsonObject
export class UserGroupRelation implements IUserGroupRelation {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  userGroupId: string = ''

  @jsonMember({constructor: String})
  role: TRole = 'client'

  @jsonMember({constructor: Boolean})
  root: boolean = false

  @jsonMember({constructor: Boolean})
  active: boolean = false
}

@jsonObject
export class UserBaseModel implements IUserBaseModel {

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: Number })
  id: number | undefined = undefined

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  role: TRole = 'client'

  @jsonMember({ constructor: String })
  status: string = ''

  @jsonMember({ constructor: UserPaymentPlanModel})
  paymentPlan: UserPaymentPlanModel | null = null

  @jsonMember({ constructor: String })
  userGroupId: string = ''

  @jsonMember({ constructor: UserGroupRelation })
  userGroup?: UserGroupRelation | null = null

  @jsonMember({ constructor: String })
  provider: string = 'PASSWORD'
}

@jsonObject
export class UserOwnerModel {

  @jsonMember({ constructor: Number })
  id: number = 1

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  status: string = ''

  @jsonMember({ constructor: String })
  userGroupId: string = ''

  @jsonMember({ constructor: UserOwnerPaymentPlanModel })
  paymentPlan: UserOwnerPaymentPlanModel | null = new UserOwnerPaymentPlanModel()

  @jsonMember({ constructor: Date })
  createdAt: Date = new Date()
}

@jsonObject
export class UserOwnerActivityModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String, deserializer: (val) => {
    const mapper: {[key: string]: string} = {
      [userActivity.activities.createAccount]: 'Account created',
      [userActivity.activities.lastLogin]: 'User opened an app',
      [userActivity.activities.closeApp]: 'User closed an app',
      [userActivity.activities.rfpDetails]: 'Contract details visited',
      [userActivity.activities.rfpLike]: 'Contract liked',
      [userActivity.activities.rfpTrash]: 'Contract trashed',
      [userActivity.activities.joinVendorsPrimes]: 'User joined to Primes',
      [userActivity.activities.joinVendorsSubs]: 'User joined to Subs',
      [userActivity.activities.openEstimatedValueCalculator]: 'User opened Estimated Value calculator',
      [userActivity.activities.enterEstimatedValueCalculator]: 'User entered values in Estimated Value calculator',
      [userActivity.activities.openPWinCalculator]: 'User opened P-Win calculator',
      [userActivity.activities.enterPWinCalculator]: 'User entered values in P-Win calculator',
      [userActivity.activities.openCostToPursueCalculator]: 'User opened Estimated Effort calculator',
      [userActivity.activities.enterCostToPursueCalculator]: 'User entered values in Estimated Effort calculator',
      [userActivity.activities.keywordsSearch]: 'User ran search with keywords',
      [userActivity.activities.createSearch]: 'User created a search',
      [userActivity.activities.openPscFilter]: 'User opened PSC filter',
      [userActivity.activities.openNaicsFilter]: 'User opened NAICS filter',
      [userActivity.activities.openCustomerFilter]: 'User opened Customer filter',
      [userActivity.activities.openLocationFilter]: 'User opened Location filter',
      [userActivity.activities.openSetAsideFilter]: 'User opened Set-Aside filter',
      [userActivity.activities.openMaturityFilter]: 'User opened Maturity filter',
      [userActivity.activities.openDueInFilter]: 'User opened Due-In filter',
      [userActivity.activities.enterPscFilter]: 'User selected PSC filter value(s)',
      [userActivity.activities.enterNaicsFilter]: 'User selected NAICS filter value(s)',
      [userActivity.activities.enterCustomerFilter]: 'User selected Customer filter value(s)',
      [userActivity.activities.enterLocationFilter]: 'User selected Location filter value(s)',
      [userActivity.activities.enterSetAsideFilter]: 'User selected Set-Aside filter value(s)',
      [userActivity.activities.enterMaturityFilter]: 'User selected Maturity filter value(s)',
      [userActivity.activities.enterDueInFilter]: 'User selected Due-In filter value(s)',
      [userActivity.activities.openCompanySearchPage]: 'User opened Company Search page',
      [userActivity.activities.openGroupSearchPage]: 'User opened Group Search page',
      [userActivity.activities.openContractsSearchPage]: 'User opened Contracts Search page',
      [userActivity.activities.openSavedSearches]: 'User opened Saved Searches',
      [userActivity.activities.openCategorizedItemsPage]: 'User opened Categorized Contracts page',
      [userActivity.activities.openTrashedItemsPage]: 'User opened Archived Contracts page',
      [userActivity.activities.marketResearchToolSap]: 'Market Research Tool SAP/Micro-purchase opened',
      [userActivity.activities.marketResearchToolSapUsed]: 'Market Research Tool "SAP/Micro-purchase" used',
      [userActivity.activities.marketResearchToolCompanies]: 'Market Research Tool "Similar Companies" opened',
      [userActivity.activities.marketResearchToolCompaniesUsed]: 'Market Research Tool "Similar Companies" used',
      [userActivity.activities.marketResearchToolContracts]: 'Market Research Tool "Expiring Contracts" opened',
      [userActivity.activities.marketResearchToolContractsUsed]: 'Market Research Tool "Expiring Contracts" used',
      [userActivity.activities.marketResearchToolCodes]: 'Market Research Tool "Codes" opened',
      [userActivity.activities.marketResearchToolCodesUsed]: 'Market Research Tool "Codes" used',
      [userActivity.activities.marketResearchToolKeywords]: 'Market Research Tool "Keywords" opened',
      [userActivity.activities.marketResearchToolKeywordsUsed]: 'Market Research Tool "Keywords" used',
    }
    const dynamicMapperTitles: {[key: string]: (val: string) => string} = {
      [userActivity.dynamicActivities.rfpDetailsTab.key]: (key: string) => `User opened details tab: ${key}`
    }
    const dynamicMapper = (val: string) => {
      const dynamicType = Object.values(userActivity.dynamicActivities).find(type => val.includes(type.key))
      if (!dynamicType) return val

      const payload = val.replace(dynamicType.key + '_', '')
      return dynamicMapperTitles[dynamicType.key](payload)
    }
    return mapper[val] || dynamicMapper(val)
  }})
  type: string = ''

  @jsonMember({constructor: String})
  data: string = ''

  @jsonMember({constructor: Date})
  date: Date = new Date()

  @jsonMember({constructor: Date})
  identifier: string = ''
}

@jsonObject
export class UserSignUpResponseModel extends BaseResponseModel implements IUserSignUpResponseModel {

  @jsonMember({ constructor: String })
  message: string = ''
}

@jsonObject
export class UserSignInResponseModel extends BaseResponseModel implements IUserSignInResponseModel {

  @jsonMember({ constructor: String })
  token: string = ''

  @jsonMember({ constructor: UserBaseModel })
  user: UserBaseModel | undefined = undefined
}

@jsonObject
export class UserResponseModel extends BaseResponseModel implements IUserGetResponseModel {

  @jsonMember({ constructor: UserBaseModel })
  data: UserBaseModel | undefined = undefined
}

@jsonObject
export class UserSettingsResponseModel extends BaseResponseModel {

  @jsonMember({ constructor: UserSettingsModel })
  data: UserSettingsModel | undefined = undefined
}

@jsonObject
export class UserVerifyResponseModel extends BaseResponseModel implements IUserVerifyResponseModel {

  @jsonMember({ constructor: String })
  token: string = ''

}

export class UserLazyAccountPromptConditionModel {
  isMoreThan30SecOnPage: boolean = false
  viewedContracts: IHpRfpDetailModel[] = []
}

export class CreateLazyAccountModel {
  @jsonMember({ constructor: String })
  email: string = ''
}

export class LazyAccountTokenModel implements ILazyAccountTokenModel {
  @jsonMember({ constructor: Number })
  tokenExpiration: number = 0

  @jsonMember({ constructor: String })
  token: string = ''
}

@jsonObject
export class UserLazyAccountResponseModel extends BaseResponseModel implements IUserLazyAccountCreationResponseModel {

  @jsonMember({ constructor: LazyAccountTokenModel })
  data: LazyAccountTokenModel | undefined = undefined

}

@jsonObject
export class UserRecoverPasswordVerifyCodeCheck extends BaseResponseModel implements IUserRecoverPasswordVerifyCodeCheck {

  @jsonMember({ constructor: String })
  recovery_token: string = ''

}

@jsonObject
export class UserIncumbentGroupResponse extends BaseResponseModel {
  @jsonArrayMember(UserIncumbentGroup)
  data: UserIncumbentGroup[] = []
}

@jsonObject
export class UserOwnerResponseModel extends BasePaginationModel {
  @jsonArrayMember(UserOwnerModel)
  data: UserOwnerModel[] = []
}

@jsonObject
export class UserOwnerActivitiesResponseModel extends BaseResponseModel {
  @jsonArrayMember(UserOwnerActivityModel)
  data: UserOwnerActivityModel[] = []
}

@jsonObject
export class UserListResponseModel extends BasePaginationModel {
  @jsonArrayMember(UserBaseModel)
  data: UserBaseModel[] = []
}

@jsonObject
export class CreateUserGroupInvitationModel {
  @jsonMember({ constructor: String })
  id: string = ''
}

@jsonObject
export class CreateUserGroupInvitationResponseModel extends BaseResponseModel implements ICreateUserGroupInvitationResponseModel {
  @jsonMember({ constructor: CreateUserGroupInvitationModel })
  data: CreateUserGroupInvitationModel | undefined
}

@jsonObject
export class UserGroupInvitationModel {
  @jsonMember({ constructor: Number })
  id!: number

  @jsonMember({ constructor: String })
  email: string = ''

  @jsonMember({ constructor: String })
  firstName: string = ''

  @jsonMember({ constructor: String })
  lastName: string = ''

  @jsonMember({ constructor: String })
  status: string = ''

  @jsonMember({ constructor: String })
  role: TRole = 'client'
}

@jsonObject
export class UserGroupModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  role: string = ''

  @jsonMember({ constructor: String })
  type!: InvitationType

  @jsonMember({ constructor: String })
  createdAt: string = ''

  @jsonMember({ constructor: UserGroupInvitationModel })
  invitedUser!: UserGroupInvitationModel
}

export class UserGroupPendingModel extends UserGroupModel {
  @jsonMember({ constructor: UserGroupInvitationModel })
  user!: UserGroupInvitationModel
}

@jsonObject
export class UserGroupResponseModel extends BaseResponseModel implements IUserResponseModel {
  @jsonArrayMember(UserGroupModel)
  data: UserGroupModel[] = []
}

@jsonObject
export class UserGroupPendingResponseModel extends BaseResponseModel implements IUserPendingResponseModel {
  @jsonArrayMember(UserGroupPendingModel)
  data: UserGroupPendingModel[] = []
}

export class GroupModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  userGroupId: string = ''

  @jsonMember({ constructor: String })
  role!: TRole

  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Boolean })
  root!: boolean

  @jsonMember({ constructor: Boolean })
  active!: boolean
}

@jsonObject
export class GroupModelResponseModel extends BaseResponseModel implements IGroupResponseModel {
  @jsonArrayMember(GroupModel)
  data: GroupModel[] = []
}