import { CustomersMapperFieldType, ICustomMapperCustomers } from 'models/hp/agencies/agencies.interface'
import { FilterOption } from 'models/hp/search/search.model'
import { Dispatch } from 'redux'

export const joinText = (text: string[], defaultText = 'Unlisted'): string =>
  text.filter(i => !!i).map(i => i).join('-') || defaultText

export const getChildCount = (options: FilterOption[], defaultCount: number = 0): number => {
  let count = defaultCount
  for (const item of options) {
    if (!item.sub || item.sub.length === 0) {
      count += 1
    } else if (item.sub && item.sub.length > 0) {
      count = getChildCount(item.sub, count)
    }
  }
  return count
}


export const setOptionCount = (dispatch: Dispatch) => (type: string, data?: FilterOption[], count?: number) => {
  let c = count
  if (count === undefined && data && data.length > 0) {
    c = getChildCount(data, 0)
  }
  dispatch({
    type,
    payload: c
  })
}

export const getAllSubItem = (options: FilterOption[], defaultArray: ICustomMapperCustomers, fieldType: CustomersMapperFieldType): ICustomMapperCustomers => {
  let res = defaultArray
  for (const item of options) {
    if (!item.sub || item.sub.length === 0) {
      res = {...res, 
        [item[fieldType] as string]: item
      }
    } else if (item.sub && item.sub.length > 0) {
      res = getAllSubItem(item.sub, res, fieldType)
    }
  }
  return res
}

export const setCustomFiltersName = (dispatch: Dispatch) => (type: string, data: FilterOption[], fieldType: CustomersMapperFieldType) => {
  let res: ICustomMapperCustomers = {}
  if (data && data.length > 0) {
    res = getAllSubItem(data, res, fieldType)
  }
  dispatch({
    type,
    payload: res
  })
}
