import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { searchSelector } from 'store/hp/market-research/selectors'
import { useNotification } from 'context/NotificationContext'
import { MarketGroupedCodePayload } from 'models/hp/grouped-code/grouped-code.model'
import { getCodesResults as getCodesResultsAction, updateSearchFilter as updateSearchFilterAction } from 'store/hp/market-research/actions'
import { Checkbox, CircularProgress, Grid, Typography } from '@material-ui/core'
import Divider from 'components/v2/Divider'
import BorderLinearProgress from '../../components/BorderLinearProgress'
import ActionsBar from '../../components/ActionsBar'
import { marketResearchFilterPercentage } from 'helpers/search'
import { addSearchCodes as addSearchCodesAction } from 'store/hp/search/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import { VideoSourceType } from 'utils/const'
import { SearchModel } from 'models/hp/search/search.model'

interface CheckedCodes {
  [key: string]: boolean
}

const SearchCodes = () => {
  const history = useHistory()
  const search = useSelector(searchSelector) as SearchModel
  const dispatch = useDispatch()
  const { show } = useNotification()

  const [requesting, setRequesting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [items, setItems] = React.useState<MarketGroupedCodePayload>({ classificationCodes: [], naicsCodes: [] })
  const [checkedClassificationCodes, setCheckedClassificationCodes] = React.useState<CheckedCodes>({})
  const [checkedNaicsCodes, setCheckedNaicsCodes] = React.useState<CheckedCodes>({})

  const addCodes = async () => {
    try {
      setRequesting(true)

      const classificationCodes = Object.keys(checkedClassificationCodes).filter(key => checkedClassificationCodes[key])
      const naicsCodes = Object.keys(checkedNaicsCodes).filter(key => checkedNaicsCodes[key])

      await addSearchCodesAction(search.id, classificationCodes, naicsCodes)

      await updateSearchFilterAction(dispatch)('pscCodeIds', classificationCodes)
      await updateSearchFilterAction(dispatch)('groupedCodeIds', naicsCodes)

      setRequesting(false)
    }
    catch (e) {
      setRequesting(false)
    }
  }

  const handleContinue = async () => {
    try {
      await addCodes()
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleStop = async () => {
    try {
      await addCodes()
      show('Changes saved successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleChange = (key: string, id: number) => () => {
    if (key === 'classificationCodes') {
      setCheckedClassificationCodes(val => ({
        ...val,
        [id]: !val[id]
      }))
    }
    if (key === 'naicsCodes') {
      setCheckedNaicsCodes(val => ({
        ...val,
        [id]: !val[id]
      }))
    }
  }

  useEffect(() => {
    setLoading(true)
    getCodesResultsAction(search.id)
      .then(res => {
        setItems(res)
        setCheckedClassificationCodes(res.classificationCodes.reduce((acc, item) => {
          acc[item.id] = search.pscCodeIds.length > 0
            ? search.pscCodeIds.includes(item.id)
            : marketResearchFilterPercentage.handler(item) > marketResearchFilterPercentage.percent
          return acc
        }, {} as CheckedCodes))
        setCheckedNaicsCodes(res.naicsCodes.reduce((acc, item) => {
          acc[item.id] = search.groupedCodeIds.length > 0
            ? search.groupedCodeIds.includes(item.id)
            : marketResearchFilterPercentage.handler(item) > marketResearchFilterPercentage.percent
          return acc
        }, {} as CheckedCodes))
      })
      .finally(() => setLoading(false))
  }, [search.id])

  if (loading) {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Typography variant='h2'>Your NAICS and PSC codes</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_CODES} />
            <Typography variant='h4'>
              Recommended codes
            </Typography>
            <Typography>
              • The government uses two coding systems to identify contracts
            </Typography>
            <Typography>
              • For the time being don't worry about the difference between them (If you want to learn
              more click here)
            </Typography>
            <Typography gutterBottom>
              • Based on your keywords and the contracts you’ve liked we’ve identified the codes that
              appear the most, and selected the ones that look best for you, but feel free to edit our recommendations
            </Typography>
          </Grid>
        </Grid>
        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>
            Ratio of Contracts you Liked v. Trashed by Code:
          </Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']}>
            <Grid className={styles['form']}>
              <Grid container alignItems='center'>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                  <div className={styles['liked-indicator']} />
                  <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Liked</Typography>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={styles['trashed-indicator']} />
                  <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Trashed</Typography>
                </Grid>
              </Grid>
              <div className={styles['analys-items-wrapper']}>
                <Grid item md={12}>
                  <Grid item md={12}>
                    <Typography variant='h4'>PSC codes</Typography>
                    <Divider dark gutterBottom />
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                    <Grid item md={1} style={{ marginRight: '-40px' }}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Use</Typography>
                    </Grid>
                    <Grid item md={2} style={{ paddingRight: '10px' }}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Code</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Liked v. Trashed</Typography>
                    </Grid>
                  </Grid>
                  <Grid className={styles['gutter-top']}>
                    {items.classificationCodes.length > 0 && items.classificationCodes.map((item, idx) => (
                      <Grid container justifyContent='space-between' key={idx} spacing={1} className={styles['gutter-bottom']}>
                        <Grid item md={1} style={{ marginRight: '-40px' }}>
                          <Checkbox
                            name='select-keywords'
                            color={'primary'}
                            value={item.id}
                            onChange={handleChange('classificationCodes', item.id)}
                            checked={checkedClassificationCodes[item.id] || false}
                          />
                        </Grid>
                        <Grid item md={2} style={{ paddingRight: '10px' }}>
                          <Typography variant='body2'>{item.name}</Typography>
                        </Grid>
                        <Grid item md={9}>
                          <BorderLinearProgress
                            value={marketResearchFilterPercentage.handler(item)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item md={12} style={{ marginTop: '12px' }}>
                    <Typography variant='h4'>NAICS codes</Typography>
                    <Divider dark gutterBottom />
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item md={1} style={{ marginRight: '-40px' }}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Use</Typography>
                    </Grid>
                    <Grid item md={2} style={{ paddingRight: '10px' }}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Code</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>Liked v. Trashed</Typography>
                    </Grid>
                  </Grid>
                  <Grid className={styles['gutter-top']}>
                    {items.naicsCodes.length > 0 && items.naicsCodes.map((item, idx) => (
                      <Grid container justifyContent='space-between' key={idx} className={styles['gutter-bottom']}>
                        <Grid item md={1} style={{ marginRight: '-40px' }}>
                          <Checkbox
                            name='select-keywords'
                            color={'primary'}
                            value={item.id}
                            onChange={handleChange('naicsCodes', item.id)}
                            checked={checkedNaicsCodes[item.id] || false}
                          />
                        </Grid>
                        <Grid item md={2} style={{ paddingRight: '10px' }}>
                          <Typography variant='body2'>{item.name}</Typography>
                        </Grid>
                        <Grid item md={9}>
                          <BorderLinearProgress
                            value={marketResearchFilterPercentage.handler(item)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
              <ActionsBar
                handleContinue={handleContinue}
                handleStop={handleStop}
                handleGoBack={() => history.push('/user/market-research/keywords-results')}
                requesting={requesting}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchCodes