import React, { ChangeEvent } from 'react'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'
import { useSelector } from 'react-redux'
import { pscCodesSelector } from 'store/hp/grouped-code/selectors'
import { searchPscCodesAction } from 'store/hp/grouped-code/actions'
import { getChildrenIds } from 'helpers/filter-option'

let searchDebounceTimer: number | null = null

interface Props {
  selectedPsc: IFilterOption[]
  handleChange: (id: number | string, state: boolean, option?: IFilterOption) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean, options?: IFilterOption[]) => void
}

const ServiceLinePscFilter = ({ handleChange, handlePartialChange, selectedPsc }: Props) => {

  const pscCodesOptions: IFilterOption[] = useSelector(pscCodesSelector) as IFilterOption[]
  const [options, setOptions] = React.useState<IFilterOption[]>([])

  const onSelect = (state: boolean, value: IFilterOption | null) => {
    if (value && value.sub?.length === 0) {
      handleChange(value.id, state, value)
    } else if (value && value.sub && value.sub.length > 0) {
      const children: IFilterOption[] = []
      getChildrenIds(value.sub, (id: number, sub) => {
        children.push(sub!)
      })
      handlePartialChange(children.map(i => i.id), state, children)
    }
  }

  const loadOptions = async (value: string) => {
    const response = await searchPscCodesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  return (
    <SearchFilter
      filterTitle='R&D, SBIR, Product or Service'
      filterOptions={pscCodesOptions}
      selectedFilterOptions={selectedPsc.map(i => i.id)}
      handleChange={handleChange}
      handlePartialChange={handlePartialChange}
      options={options}
      onSearch={onSearch}
      onSelect={onSelect}
      searchLabel='Function search'
      iconType='psc'
    />
  )
}

export default ServiceLinePscFilter
