import { IKeywordResponseModel } from 'models/hp/keyword/keyword.interface'
import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { KeywordResponseModel } from 'models/hp/keyword/keyword.model'
import { BaseResponseModel } from 'models/base/base.model'

export const getKeywordsBySearchId = async (id: number): Promise<IKeywordResponseModel> => {
  const response = await request<IKeywordResponseModel>(RequestMethods.Get, Paths.Hp.Keywords.getKeywordsBySearchId(id), {
    schema: KeywordResponseModel
  })
  return response.data
}

export const setKeywordsBySearchId = async (id: number, keywords: string[]): Promise<void> => {
  await request<IKeywordResponseModel>(RequestMethods.Post, Paths.Hp.Keywords.setKeywordsBySearchId(id), {
    schema: BaseResponseModel,
    body: { keywords }
  })
}
