import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { searchSelector } from 'store/hp/market-research/selectors'
import { SearchModel } from 'models/hp/search/search.model'

interface Props {
  children?: React.ReactNode
}

const WithSearchRoute = ({ children }: Props) => {
  const search = useSelector(searchSelector) as SearchModel
  const history = useHistory()

  if (!search || !search.id) {
    history.replace('/user/market-research/search-name')
  }

  return <>{children}</>
}

export default WithSearchRoute