import { IResourceLinkModel } from './resource-link.interface'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ResourceLinkModel implements IResourceLinkModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  fileName: string = ''

  @jsonMember({ constructor: String })
  link: string = ''

  @jsonMember({ constructor: String })
  internalLink: string = ''
}