const emptyTitleName = 'no-name-reported'

export const getCompanyDetailsLink = (duns: string, name?: string, from?: string) => {
  const title = name?.slice(0, 144).replace(/ /g, '-') || emptyTitleName
  return `/vendors/teammate-profiles/duns-${duns}-title-${title}?from=${from}`
}

export const getCompanyTitle = (title: string) => {
  return title === emptyTitleName ? '' : title
}
