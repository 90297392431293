import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getMarketResearchCodes } from 'store/hp/market-research/actions'
import { MarketCodesGroupedResponseModel } from 'models/hp/market-research/market-research.model'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import Filters, { ICodesFiltersConverted } from './Filters'
import Listing, { ICode, ICodeMapper } from './Listing'
import { createLazyUserAccount } from 'store/common/user/actions'
import { UserBaseModel } from 'models/common/user/user.model'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { userActivity } from 'store/common/owner/actions'
import EducationNavigationButtons
  from 'modules/Main/Education/components/EducationNavigationButtons'
import { EDUCATION_SUBTITLE, PlanningPhaseNavigationList } from 'utils/constants/education'
import { selectedEducationPhaseClassSelector } from 'store/hp/education/selector'
import { IFilterOption } from 'models/hp/search/search.interface'
import {
  projectTypeOptions as projectTypeOptionsAction, selectedProjectTypeOptions as selectedProjectTypeOptionsAction
} from 'store/hp/search/selectors'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import {
  redirectAccordingEducationPhase,
  setCurrentEducationPhaseClass,
  setPhaseProgressValue
} from 'store/hp/education/action'
import { createFulfilledSearch, removeSearchFilterValues } from 'store/hp/search/actions'
import { updateSearchGroupedCodes } from 'store/hp/grouped-code/actions'
import NameModal from '../Keywords/components/NameModal'
import { SearchModel } from 'models/hp/search/search.model'

const Codes = () => {
  const notification = useRef(useNotification())
  const history = useHistory()
  const user = useSelector(userSelector) as UserBaseModel
  const dispatch = useDispatch()

  const [listingLoading, setListingLoading] = useState(false)
  const [listingData, setListingData] = useState<MarketCodesGroupedResponseModel>()
  const [codes, setCodes] = useState<ICodeMapper>({})
  const [isNameModalOpened, setNameModalState] = useState(false)
  const selectedEducationClass = useSelector(selectedEducationPhaseClassSelector)
  const projectTypeOptions: IFilterOption[] = useSelector(projectTypeOptionsAction)
  const selectedProjectTypeOptions: number[] = useSelector(selectedProjectTypeOptionsAction) as number[]

  const currentPhaseClassIndex = PlanningPhaseNavigationList.findIndex((phase: IEducationPhaseClass) => phase.name === selectedEducationClass.name)
  const lastClass = currentPhaseClassIndex + 1 === PlanningPhaseNavigationList.length

  const addCode = (code: ICode) => {
    setCodes(prev => ({
      ...prev,
      [code.code]: codes[code.code] ? undefined : code
    }))
  }

  const getListingData = (filters: ICodesFiltersConverted) => {
    setListingLoading(true)
    getMarketResearchCodes(filters)
      .then(setListingData)
      .catch(console.log)
      .finally(() => setListingLoading(false))
  }

  const toggleSearchNameModal = () => {
    setNameModalState(val => !val)
  }

  const onNext = () => {
    if (lastClass) {
      setPhaseProgressValue(dispatch)('planning', true)
      return
    }
    let nextPhaseClass = currentPhaseClassIndex < PlanningPhaseNavigationList.length ? PlanningPhaseNavigationList[currentPhaseClassIndex + 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(nextPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, nextPhaseClass, projectTypeOptions)
  }

  const onPrevious = () => {
    let previousPhaseClass = currentPhaseClassIndex ? PlanningPhaseNavigationList[currentPhaseClassIndex - 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(previousPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, previousPhaseClass, projectTypeOptions)
  }

  const createSearchWithCodes = async (name: string, codes: { pscCodes: string[], naicsCodes: string[] }) => {
    const search = await createFulfilledSearch(dispatch)({...new SearchModel(), type: undefined}, name, true, false)
    await updateSearchGroupedCodes(search.id, codes)
  }

  const getSortedCodes = () => {
    const values = []
    for (let key in codes) {
      if (!codes[key]) continue
      const item = {
        name: codes[key]!.name,
        code: codes[key]!.code,
        type: codes[key]!.type
      }
      values.push(item)
    }
    const pscCodes = values.filter(v => v.type === 'pscCodes')
    const naicsCodes = values.filter(v => v.type === 'naicsCodes')

    return { pscCodes, naicsCodes }
  }

  const saveSelectedCodes = async (name: string, email?: string) => {
    try {
      const {pscCodes, naicsCodes} = getSortedCodes()
      if (!pscCodes.length && !naicsCodes.length) {
        return notification.current.show('Please, select at least one option', NotificationTypeList.Warning)
      }

      if (!user) {
        await createLazyUserAccount({ email: email! })
      }

      await createSearchWithCodes(name, {
        pscCodes: pscCodes.map(c => c.code),
        naicsCodes: naicsCodes.map(c => c.code)
      })

      const msg = user
        ? 'Codes saved successfully'
        : 'Codes saved successfully and credentials were saved to provided email'
      notification.current.show(msg, NotificationTypeList.Success)
      toggleSearchNameModal()
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleUpdateSearch = async (searchId: number) => {
    const { pscCodes, naicsCodes } = getSortedCodes()
    try {
      await updateSearchGroupedCodes(searchId, {
        naicsCodes: naicsCodes.map(c => c.code),
        pscCodes: pscCodes.map(c => c.code)
      })
      notification.current.show('Codes successfully updated', NotificationTypeList.Success)
      setNameModalState(false)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    userActivity.event(userActivity.activities.marketResearchToolCodes)
  }, [])

  return (
    <div className={styles['container']}>
      <Grid container spacing={2} className={styles['height-block']}>
        <Grid item md={4} className={styles['filter-list']}>
          <Filters
            getListing={getListingData}
            saveCodes={toggleSearchNameModal}
          />
          {
            selectedEducationClass && selectedEducationClass.name === EDUCATION_SUBTITLE.IDENTIFY_YOUR_CODES &&
            <EducationNavigationButtons
              onNext={onNext}
              onPrevious={onPrevious}
              hasNext={!lastClass}
              hasPrevious={!!currentPhaseClassIndex}
            />
          }
        </Grid>
        <Grid item md={8} className={styles['height-block']}>
          <Listing
            listingData={listingData}
            listingLoading={listingLoading}
            codes={codes}
            addCode={addCode}
          />
        </Grid>
        {
          isNameModalOpened &&
          <NameModal
            onClose={() => setNameModalState(val => !val)}
            handleSave={saveSelectedCodes}
            handleUpdateSearch={handleUpdateSearch}
            user={user}
            createSearchTitle='Save these codes as a new search'
            updateSearchTitle='Update a saved search with these codes'
          />
        }
      </Grid>
    </div>
  )
}

export default Codes