import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const Skeletons = () => {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' height={60} width='100%' />
      </Grid>
    </Grid>
  )
}

export default Skeletons