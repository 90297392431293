import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  companySetAsidesSelector
} from 'store/hp/company-profiles/options/selectors'
import styles from './index.module.scss'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import {
  companyProfileSelectedSetAsideOptions
} from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue,
  removeCompanySearchFilterValues,
  setCompanySearchFilterValue,
  setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'

const SetAsideFilter = () => {

  const dispatch = useDispatch()

  const setAsideOptions = useSelector(companySetAsidesSelector)
  const selectedSetAsideOptions: number[] = useSelector(companyProfileSelectedSetAsideOptions)

  const handleChange = (value: number | string, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('setAsideIds', +value)
    } else {
      removeCompanySearchFilterValue(dispatch)('setAsideIds', +value)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('setAsideIds', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('setAsideIds', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={setAsideOptions?.length}
        iconType='setAside'
        filterTitle='Set Asides'
        filterOptions={setAsideOptions!}
        selectedFilterOptions={selectedSetAsideOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default SetAsideFilter
