import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Paper, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  filterOptionsLoadedSelector,
  filterOptionsLoadingSelector,
  projectTypeOptions as projectTypeOptionsAction, selectedProjectTypeOptions as selectedProjectTypeOptionsAction
} from 'store/hp/search/selectors'
import { groupedCodesLoadedSelector } from 'store/hp/grouped-code/selectors'
import { agenciesLoadedSelector } from 'store/hp/agency/selectors'
import { locationsLoadedSelector } from 'store/hp/location/selectors'
import { getSearchOptions, removeSearchFilterValues } from 'store/hp/search/actions'
import { getNaicsCodesAction, getPscCodesAction } from 'store/hp/grouped-code/actions'
import { getAgenciesAction } from 'store/hp/agency/actions'
import { getLocationsAction } from 'store/hp/location/action'
import { convertStringToKeywords } from 'helpers/search'
import NaicsFilter from '../../Filters/NaicsFilter'
import PscFilter from '../../Filters/PscFilter'
import DepartmentFilter from '../../Filters/DepartmentFilter'
import LocationFilter from '../../Filters/LocationFilter'
import SetAsideFilter from '../../Filters/SetAsideFilter'
import KeywordsFilterWithOptions from '../../Filters/KeywordsFilterWithOptions'
import EducationNavigationButtons
  from 'modules/Main/Education/components/EducationNavigationButtons'
import { selectedEducationPhaseClassSelector } from 'store/hp/education/selector'
import { EDUCATION_SUBTITLE, PlanningPhaseNavigationList } from 'utils/constants/education'
import {
  redirectAccordingEducationPhase,
  setCurrentEducationPhaseClass,
  setPhaseProgressValue
} from 'store/hp/education/action'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { IFilterOption } from 'models/hp/search/search.interface'
import { useHistory } from 'react-router-dom'

export interface ISimilarCompaniesFilters {
  keywords: string[]
  customers: number[]
  naicsCodes: number[]
  pscCodes: number[]
  locations: number[]
  setAsides: number[]
}

export interface Props {
  getListing: (filters: ISimilarCompaniesFilters) => void
  saveCompanies: () => void
}

let initLoading = true
let debounceTimer: number = 0

const Filters = ({ getListing, saveCompanies }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector)
  const filterOptionsLoading = useSelector(filterOptionsLoadingSelector)
  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const agenciesLoaded = useSelector(agenciesLoadedSelector)
  const locationsLoaded = useSelector(locationsLoadedSelector)
  const selectedEducationClass = useSelector(selectedEducationPhaseClassSelector)
  const projectTypeOptions: IFilterOption[] = useSelector(projectTypeOptionsAction)
  const selectedProjectTypeOptions: number[] = useSelector(selectedProjectTypeOptionsAction) as number[]

  const currentPhaseClassIndex = PlanningPhaseNavigationList.findIndex((phase: IEducationPhaseClass) => phase.name === selectedEducationClass.name)
  const lastClass = currentPhaseClassIndex + 1 === PlanningPhaseNavigationList.length

  const [filters, setFilters] = React.useState<ISimilarCompaniesFilters>({
    keywords: [],
    customers: [],
    naicsCodes: [],
    pscCodes: [],
    locations: [],
    setAsides: []
  })

  const handleChange = (key: keyof ISimilarCompaniesFilters) => (id: number | string, state: boolean) => {
    if (state) {
      setFilters(prev => ({...prev, [key]: [...prev[key], +id]}))
    } else {
      setFilters(prev => ({
        ...prev,
        [key]: (prev[key] as Array<string|number>).filter(x => x !== +id)
      }))
    }
  }

  const handlePartialChange = (key: keyof ISimilarCompaniesFilters) => (ids: Array<string | number>, state: boolean) => {
    if (state) {
      setFilters(prev => ({...prev, [key]: (prev[key] as Array<string | number>).concat(ids.map(id => +id))}))
    } else {
      setFilters(prev => ({
        ...prev,
        [key]: (prev[key] as Array<string | number>).filter(id => !ids.map(id => +id).includes(+id))
      }))
    }
  }

  const handleKeywordsChange = (keywords?: string) => {
    setFilters(prev => ({
      ...prev,
      keywords: convertStringToKeywords(keywords) || []
    }))
  }

  const onNext = () => {
    if (lastClass) {
      setPhaseProgressValue(dispatch)('planning', true)
      return
    }
    let nextPhaseClass = currentPhaseClassIndex < PlanningPhaseNavigationList.length ? PlanningPhaseNavigationList[currentPhaseClassIndex + 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(nextPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, nextPhaseClass, projectTypeOptions)
  }

  const onPrevious = () => {
    let previousPhaseClass = currentPhaseClassIndex ? PlanningPhaseNavigationList[currentPhaseClassIndex - 1] : selectedEducationClass
    setCurrentEducationPhaseClass(dispatch)(previousPhaseClass)
    removeSearchFilterValues(dispatch)('projectTypeIds', selectedProjectTypeOptions)
    redirectAccordingEducationPhase(dispatch)(history, previousPhaseClass, projectTypeOptions)
  }

  useEffect(() => {
    // Get all selectable options if not downloaded
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
    if (!groupedCodesLoaded) {
      getNaicsCodesAction(dispatch)()
      getPscCodesAction(dispatch)()
    }
    if (!agenciesLoaded) {
      getAgenciesAction(dispatch)()
    }
    if (!locationsLoaded) {
      getLocationsAction(dispatch)()
    }
    return () => {
      initLoading = true
    }
  }, [])

  useEffect(() => {
    if (initLoading) {
      initLoading = false
      return
    }
    debounceTimer && window.clearTimeout(debounceTimer)
    debounceTimer = window.setTimeout(() => {
      getListing(filters)
    }, 1500)
  }, [filters])

  if (filterOptionsLoading) {
    return (
      <Paper className={styles['paper']}>
        <Grid container justifyContent='center'>
          <CircularProgress color='primary' />
        </Grid>
      </Paper>
    )
  }

  return (
    <div>
      <Paper className={styles['paper']}>
        <Typography variant='h4' gutterBottom>
          Find Similar Companies
        </Typography>
        <KeywordsFilterWithOptions
          keywords={filters['keywords']}
          handleChange={handleKeywordsChange}
        />
        <div style={{height: '16px'}} />
        <PscFilter
          handleChange={handleChange('pscCodes')}
          handlePartialChange={handlePartialChange('pscCodes')}
          selectedOptions={filters['pscCodes']}
        />
        <div style={{height: '16px'}} />
        <NaicsFilter
          handleChange={handleChange('naicsCodes')}
          handlePartialChange={handlePartialChange('naicsCodes')}
          selectedOptions={filters['naicsCodes']}
        />
        <div style={{height: '16px'}} />
        <DepartmentFilter
          handleChange={handleChange('customers')}
          handlePartialChange={handlePartialChange('customers')}
          selectedOptions={filters['customers']}
        />
        <div style={{height: '16px'}} />
        <LocationFilter
          handleChange={handleChange('locations')}
          handlePartialChange={handlePartialChange('locations')}
          selectedOptions={filters['locations']}
        />
        <div style={{height: '16px'}} />
        <SetAsideFilter
          handleChange={handleChange('setAsides')}
          handlePartialChange={handlePartialChange('setAsides')}
          selectedOptions={filters['setAsides']}
        />
        <div style={{height: '16px'}} />
        <Button
          color='primary'
          variant='contained'
          fullWidth
          onClick={() => getListing(filters)}
        >
          Find similar companies
        </Button>
        <div style={{height: '16px'}} />
        <Button
          color='primary'
          variant='outlined'
          fullWidth
          onClick={() => saveCompanies()}
        >
          Save/Email me selected companies
        </Button>
      </Paper>
      {
        selectedEducationClass && selectedEducationClass.name === EDUCATION_SUBTITLE.PROMISING_PARTNERS &&
        <EducationNavigationButtons
          onNext={onNext}
          onPrevious={onPrevious}
          hasNext={!lastClass}
          hasPrevious={!!currentPhaseClassIndex}
        />
      }
    </div>
  )
}

export default Filters