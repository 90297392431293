import React, { useEffect } from 'react'
import styles from './index.module.scss'
import LinkItem from './components/LinkItem'
import Layer from './components/Layer'
import { default as FirstBanner } from 'assets/images/landing_page_banner.jpg'
import { default as SecondBanner } from 'assets/images/landing_page_banner2.jpg'
import { default as ThirdBanner } from 'assets/images/landing_page_banner3.jpg'
import { default as FourthBanner } from 'assets/images/landing_page_banner4.jpg'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  filterOptionsLoadedSelector,
  projectTypeOptions as projectTypeOptionsAction,
} from 'store/hp/search/selectors'
import { getSearchOptions, setFilterOptions } from 'store/hp/search/actions'
import {
  IFilterOption,
  ISearchFilterOption,
} from 'models/hp/search/search.interface'
import {
  getPscCodesAction,
  getNaicsCodesAction,
} from 'store/hp/grouped-code/actions'
import {
  groupedCodesLoadedSelector,
  pscCodesSelector,
} from 'store/hp/grouped-code/selectors'

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const filterOptionsLoaded = useSelector(filterOptionsLoadedSelector)
  const groupedCodesLoaded = useSelector(groupedCodesLoadedSelector)
  const projectTypeOptions: IFilterOption[] = useSelector(
    projectTypeOptionsAction
  )
  const pscCodesOptions: IFilterOption[] = useSelector(
    pscCodesSelector
  ) as IFilterOption[]

  useEffect(() => {
    if (!filterOptionsLoaded) {
      getSearchOptions(dispatch)()
    }
    if (!groupedCodesLoaded) {
      getPscCodesAction(dispatch)()
      getNaicsCodesAction(dispatch)()
    }
  }, [])

  const handleClick =
    (
      url: string,
      systemOut: boolean = false,
      searchFilter?: ISearchFilterOption
    ) =>
      () => {
        if (systemOut) {
          window.open(url, '_blank')
        } else {
          searchFilter &&
          setFilterOptions(dispatch)(
            searchFilter,
            projectTypeOptions,
            pscCodesOptions
          )
          history.push(url)
        }
      }

  return (
    <div className={styles['landing-page']}>
      <Grid container spacing={3} justifyContent={'space-around'}>
        <Layer banner={FirstBanner}>
          <LinkItem
            iconType={'doc_pen'}
            title={'Download the new contractor checklist'}
            onClick={handleClick(
              'https://www.fedscout.com/hubfs/FedScout-New-Government-Contractor-Checklist.pdf?of_m=61e68443784adb059f7084b0',
              true
            )}
          />
          <LinkItem
            iconType={'search'}
            title={'Find my keywords'}
            onClick={handleClick('/user/market-research/tools/keywords')}
          />
          <LinkItem
            iconType={'scan_code'}
            title={'Find my NAICS/PCSC Codes'}
            onClick={handleClick('/user/market-research/tools/codes')}
          />
          <LinkItem
            iconType={'contractors'}
            title={'Find established contractors like me'}
            onClick={handleClick('/user/market-research/tools/similar-company')}
          />
          <LinkItem
            iconType={'awards'}
            title={'Find recent awards that may need subs'}
            onClick={handleClick('/contracts', false, {
              projectTypes: ['Hot Fill'],
            })}
          />
          <LinkItem
            iconType={'market_size'}
            title={'Size my Federal market'}
            onClick={handleClick(
              'https://app.fedscout.com/user/market-research',
              true
            )}
          />
          <LinkItem
            iconType={'vendor_friendly'}
            title={'Find Federal customers that are new vendor friendly'}
            onClick={handleClick('/user/market-research/tools/sap')}
          />
          <LinkItem
            iconType={'development_center'}
            title={'Find Government Small Business Offices'}
            onClick={handleClick(
              'https://www.fedscout.com/blog/osdbu-office-of-small-and-disadvantaged-business-utilization',
              true
            )}
          />
          <LinkItem
            iconType={'settings'}
            title={'Find your Procurement Technical Assistance Center'}
            onClick={handleClick(
              'https://www.fedscout.com/blog/ptacs-procurement-technical-assistance-centers',
              true
            )}
          />
        </Layer>
        <Layer banner={SecondBanner}>
          <LinkItem
            iconType={'opportunities'}
            title={'Find re-compete / forecast opportunities'}
            onClick={handleClick('/contracts', false, {
              projectTypes: ['Distant opportunities'],
            })}
          />
          <LinkItem
            iconType={'competitor_analysis'}
            title={'Explore competitor contracts coming up from re-compete'}
            onClick={handleClick(
              '/user/market-research/tools/expiring-contracts'
            )}
          />
          <LinkItem
            iconType={'file_percentage'}
            title={'Find RFIs & Sources Sought'}
            onClick={handleClick('/contracts', false, {
              projectTypes: ['Upcoming opportunities'],
            })}
          />
          <LinkItem
            iconType={'file'}
            title={'Find open RFPs'}
            onClick={handleClick('/contracts', false, {
              projectTypes: ['Open opportunities'],
            })}
          />
          <LinkItem
            iconType={'vehicle'}
            title={'Find Vehicles that you may be able to use'}
            onClick={handleClick('https://app.fedscout.com/awards/idv', true)}
          />
          <LinkItem
            iconType={'library'}
            title={'Search old awards'}
            onClick={handleClick('https://app.fedscout.com/awards', true)}
          />
          <LinkItem
            iconType={'find_partners'}
            title={'Search for partners'}
            onClick={handleClick('https://app.fedscout.com/vendors', true)}
          />
        </Layer>
        <Layer banner={ThirdBanner}>
          <LinkItem
            iconType={'checklist'}
            title={'Download the new contractor checklist'}
            onClick={handleClick(
              'https://www.fedscout.com/sbir-checklist',
              true
            )}
          />
          <LinkItem
            iconType={'cash'}
            title={'Search for funding'}
            onClick={handleClick('/contracts', false, {
              pscCodes: ['R&D & Special Studies/analysis', 'SBIR/STTR'],
            })}
          />
          <LinkItem
            iconType={'notebook'}
            title={'Guide to SBIR IP'}
            onClick={handleClick(
              'https://www.fedscout.com/sbir-ip-for-founders',
              true
            )}
          />
          <LinkItem
            iconType={'rocket'}
            title={'Startup\'s Guide to SBIR/STTR'}
            onClick={handleClick(
              'https://www.fedscout.com/a-startup-guide-to-sbir',
              true
            )}
          />
          <LinkItem
            iconType={'mountains'}
            title={'Investor\'s Guide to SBIR/STTR'}
            onClick={handleClick(
              'https://www.fedscout.com/an-investors-guide-to-sbir',
              true
            )}
          />
          <LinkItem
            iconType={'development_center'}
            title={'Government Guide to SBIR/STTR'}
            onClick={handleClick(
              'https://www.fedscout.com/a-government-guide-to-sbir',
              true
            )}
          />
        </Layer>
        <Layer banner={FourthBanner}>
          <LinkItem
            iconType={'attention'}
            title={'Guide to Go/No-go decision making'}
            onClick={handleClick(
              'https://www.fedscout.com/a-small-business-guide-to-deciding-whether-to-bid-on-federal-contracts',
              true
            )}
          />
          <LinkItem
            iconType={'calendar'}
            title={'GSA Schedule guide'}
            onClick={handleClick(
              'https://www.fedscout.com/understanding-the-gsa-schedule',
              true
            )}
          />
          <LinkItem
            iconType={'checklist'}
            title={'Partner relationship building checklist'}
            onClick={handleClick(
              'https://www.fedscout.com/blog/how-to-contact-a-potential-partner',
              true
            )}
          />
        </Layer>
      </Grid>
    </div>
  )
}

export default Home
