import React from 'react'
import styles from './index.module.scss'
import { Route, Switch } from 'react-router-dom'
import { Typography, Grid } from '@material-ui/core'
import Menu from './components/Menu'
import Tokens from './modules/Tokens'
import Users from './modules/Users'
import Contracts from './modules/Contracts'
import Feedback from 'modules/Main/Owner/modules/Feedback'

const Owner = () => {
  return (
    <div className={styles['inner-block']}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <Typography variant='h3'>Owner page:</Typography>
        </Grid>
        <Grid item>
          <Menu />
        </Grid>
      </Grid>

      <Switch>
        <Route path={'/owner/tokens'}>
          <Tokens />
        </Route>
        <Route path={'/owner/users'}>
          <Users />
        </Route>
        <Route path={'/owner/contracts'}>
          <Contracts />
        </Route>
        <Route path={'/owner/feedback'}>
          <Feedback />
        </Route>
      </Switch>
    </div>
  )
}

export default Owner