export const tableHead = [
  {
    id: 1,
    title: 'Assigned to',
    fieldName: 'assignedUser',
    customClassName: 'gant-task-header-column-assigned',
    withSort: true
  },
  {
    id: 2,
    title: 'Value',
    fieldName: 'estimatedValue',
    customClassName: 'gant-task-header-column-value',
    withSort: true
  },
  {
    id: 3,
    title: '%',
    fieldName: 'estimatedValue',
    customClassName: 'gant-task-header-column-percent',
    withSort: false
  },
  {
    id: 4,
    title: 'Time',
    fieldName: 'createdAt',
    customClassName: 'gant-task-header-column-due',
    withSort: true
  },
]