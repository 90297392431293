import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { govConsSelector } from 'store/hp/company-profiles/options/selectors'
import styles from './index.module.scss'
import { companyProfileSelectedGovConOptions } from 'store/hp/company-profiles/search/selectors'
import {
  removeCompanySearchFilterValue,
  removeCompanySearchFilterValues,
  setCompanySearchFilterValue,
  setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'

const GovConFilter = () => {

  const dispatch = useDispatch()

  const govConSelector = useSelector(govConsSelector)
  const selectedGovConOptions: number[] = useSelector(companyProfileSelectedGovConOptions)

  const handleChange = (value: number | string, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('govConIds', +value)
    } else {
      removeCompanySearchFilterValue(dispatch)('govConIds', +value)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('govConIds', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('govConIds', values)
    }
  }

  return (
    <div className={styles['paper']}>
      <SearchFilter
        allCount={govConSelector?.length}
        iconType='govCon'
        handlePartialChange={handlePartialChange}
        filterTitle='GovCon Maturity'
        filterOptions={govConSelector!}
        selectedFilterOptions={selectedGovConOptions}
        handleChange={handleChange}
      />
    </div>
  )
}

export default GovConFilter
