import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const marketResearchTools = (state: IRootState) => state.marketResearchTools

const expiringContractsSelector = createSelector(
  marketResearchTools,
  tools => tools.get('expiringContracts')
)

const filtersSelector = createSelector(
  expiringContractsSelector,
  expiringContracts => expiringContracts.filters
)

const listingSelector = createSelector(
  expiringContractsSelector,
  expiringContracts => expiringContracts.listing
)

export const expiringContractsFiltersSelector = createSelector(
  filtersSelector,
  filters => filters.toJS()
)

export const expiringContractsListingSelector = createSelector(
  listingSelector,
  listing => listing.toJS()
)

export const expiringContractsSumsSelector = createSelector(
  listingSelector,
  listing => {
    let count = 0
    let amount = 0
    const obj: any = listing.toJS().data
    for (let key in obj) {
      count += obj[key].count
      amount += obj[key].amount
    }
    return [count, amount]
  }
  // listing => Object.values().reduce((acc: number[], item: ContractItem) => {
  //   acc[0] += item.count
  //   acc[1] += item.amount
  //   return acc
  // }, [0, 0])
)