import React, { useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { userSettingsSelector } from 'store/common/user/selectors'
import { useNotification } from 'context/NotificationContext'
import { IUserSettings } from 'models/common/user/user.interfaces'
import { updateUserSettings } from 'store/common/user/actions'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import SearchNotifications from './SearchNotifications'

const Notifications = () => {
  const dispatch = useDispatch()
  const notification = useRef(useNotification())
  const userSettings: IUserSettings | null = useSelector(userSettingsSelector) as IUserSettings

  const [hpNewSearchItemEmailNotification, setHpNewSearchItemEmailNotification] = React.useState(false)
  const [hpPocNewItemEmailNotification, setHpPocNewItemEmailNotification] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)

  const handleChange = (key: string, handler: Dispatch<SetStateAction<boolean>>) => async (_:any, checked: boolean) => {
    handler(prevState => !prevState)
    setProcessing(true)
    try {
      await updateUserSettings(dispatch)({
        ...userSettings!,
        [key]: checked
      })
      notification.current.show('Settings updated', NotificationTypeList.Success)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
      handler(prevState => !prevState)
    }
    setProcessing(false)
  }

  useEffect(() => {
    setHpNewSearchItemEmailNotification(
      typeof userSettings?.hpNewSearchItemEmailNotification === 'boolean'
        ? userSettings.hpNewSearchItemEmailNotification
        : false
    )
    setHpPocNewItemEmailNotification(
      typeof userSettings?.hpPocNewItemEmailNotification === 'boolean'
        ? userSettings.hpPocNewItemEmailNotification
        : false
    )
  }, [userSettings])

  return (
    <div>
      <Typography variant='h4'>Get notified when:</Typography>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={hpPocNewItemEmailNotification}
              onChange={handleChange('hpPocNewItemEmailNotification', setHpPocNewItemEmailNotification)}
              color='primary'
              disabled={processing}
            />
          }
          label={'New contracts from your favorite POCs'}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={hpNewSearchItemEmailNotification}
              onChange={handleChange('hpNewSearchItemEmailNotification', setHpNewSearchItemEmailNotification)}
              color='primary'
              disabled={processing}
            />
          }
          label={'New matches in your contract search'}
        />
        {hpNewSearchItemEmailNotification && (
          <div style={{paddingLeft: '32px'}}>
            <SearchNotifications />
          </div>
        )}
      </div>
    </div>
  )
}

export default Notifications