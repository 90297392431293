import React from 'react'
import { Button, Grid, Link, Typography } from '@material-ui/core'
import Divider from 'components/v2/Divider'

interface Info {
  link: string
  title: string
}

const info: Info[] = [
  {
    link: 'https://www.fedscout.com/blog/ptacs-procurement-technical-assistance-centers',
    title: 'PTAC: Dedicated Government contracting training and counseling'
  },
  {
    link: 'https://www.fedscout.com/blog/ptacs-procurement-technical-assistance-centers-0',
    title: 'SBDC: Business planning and founding counseling and support'
  },
  {
    link: 'https://www.fedscout.com/blog/ptacs-procurement-technical-assistance-centers-1',
    title: 'OSDBU: Agency specific support to small businesses'
  },
  {
    link: 'https://www.fedscout.com/blog/set-aside-goals-and-federal-performance-2020',
    title: 'Set-aside targets: Overview of which agencies are hitting their set-aside targets'
  },
  {
    link: 'https://www.fedscout.com/blog/our-wizards-to-help-you-quickly-register-in-duns-sam-and-sbir.gov',
    title: 'Registrations: DUNS and SAM registration'
  }
]

const InfoButtons = () => {
  return (
    <Grid container direction='column' style={{ paddingTop: '20px' }}>
      <Typography variant='h4'>Government funded contractor resources</Typography>
      <Divider dark gutterBottom />
      <Grid container alignItems='stretch'>
        {info.map((item, idx) => (
          <Grid item md={12} style={{marginTop: '10px'}} key={idx}>
            <Link href={item.link} target='_blank' rel='noreferrer'>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                style={{ height: '100%', fontSize: '14px' }}
              >
                {item.title}
              </Button>
            </Link>
          </Grid>
        ))}
        <Typography style={{ paddingTop: '10px' }} variant='h4'>Private contracting education and service providers</Typography>
        <Divider dark gutterBottom />
      </Grid>
    </Grid >
  )
}

export default InfoButtons