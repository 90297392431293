import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRouter } from 'context/RouterContext'
import { SEO_DATA } from 'utils/const'
import PaymentPlan from '../PaymentPlans'
import User from './User'
import Owner from './Owner'
import ProtectedRoute from 'modules/ProtectedRoute'
import OwnerProtectedRoute from 'modules/ProtectedRoute/OwnerProtectedRoute'
import CompanyGroup from './CompanyProfiles/Groups/Group'
import SeoData from 'components/v2/SeoData'
import CustomLoader from 'components/CustomLoader'
import Research from './Research'
import Home from './Home'
import Education from 'modules/Main/Education'
import PipelineBoardScreenNew from './Hp/PipelineBoardScreenNew'

// Lazy loaded pages
const Hp = lazy(() => import('./Hp'))
const Awards = lazy(() => import('./Awards'))
const CompanyProfiles = lazy(() => import('./CompanyProfiles'))
const CompanyProfileDetails = lazy(() => import('./CompanyProfiles/CompanyProfileDetails'))

const Main = () => {
  const routerContext = useRouter()
  if (!routerContext.current) {
    return null
  }

  return (
    <>
      {!routerContext.current?.pathname.includes('/federal-government-opportunity') && !routerContext.current?.pathname.includes('/teammate-profiles') &&
        <SeoData
          title={SEO_DATA.title}
          description={SEO_DATA.description}
        />
      }
      <Suspense fallback={(<CustomLoader/>)}>
        <Switch>
          <Route path={'/contracts'} component={Hp}/>
          <Route path={'/awards'} component={Awards}/>

          <Route path='/pipeline'>
            <PipelineBoardScreenNew />
          </Route>
          <Route path={'/plans'}>
            <PaymentPlan/>
          </Route>
          <Route path={'/plans/enterprise'}>
            <PaymentPlan/>
          </Route>
          <Route path={'/user'}>
            <User/>
          </Route>
          <OwnerProtectedRoute path={'/owner'}>
            <Owner/>
          </OwnerProtectedRoute>

          <ProtectedRoute exact={true} path={'/vendors'} component={CompanyProfiles}/>

          <ProtectedRoute exact={true} path={'/vendors/group/:type'}>
            <CompanyGroup/>
          </ProtectedRoute>

          <Route
            exact={true}
            path={'/vendors/teammate-profiles/duns-:duns-title-:title'}
            component={CompanyProfileDetails}
          />
          <Route exact={true} path={'/research'}>
            <Research/>
          </Route>
          <Route exact={true} path={'/home'}>
            <Home/>
          </Route>
          <Route path='/education'>
            <Education />
          </Route>
        </Switch>
      </Suspense>
    </>
  )
}

export default Main
