import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import {BasePaginationModel, BaseResponseModel} from 'models/base/base.model'
import { HpRfpDetailModel } from 'models/hp/rfp/rfp.model'
import { CompanyProfilePreviewModel } from 'models/hp/company-profiles/base/company-profile.model'
import { MarketVendors } from 'models/hp/awards/award.interface'

@jsonObject
export class AwardListItem {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  contractTransactionUniqueKey: string = ''

  @jsonMember({ constructor: String })
  parentAwardType: string = ''

  @jsonMember({ constructor: String })
  awardingSubAgencyName: string = ''

  @jsonMember({ constructor: String })
  awardType: string = ''

  @jsonMember({ constructor: String })
  multipleOrSingleAwardIdvTypeCode: string = ''

  @jsonMember({ constructor: String, name: 'primaryPOPStateCode' })
  stateCode: string = ''

  @jsonMember({ constructor: String, name: 'awardingAgencyName' })
  agency: string = ''

  @jsonMember({ constructor: String, name: 'primaryPOPCityName' })
  city: string = ''

  @jsonMember({ constructor: String, name: 'typeOfSetAside' })
  setAside: string = ''

  @jsonMember({ constructor: String, name: 'periodOfPerformancePotentialEndDate' })
  endDate: Date | null = null

  @jsonMember({ constructor: String, name: 'awardDescription' })
  title: string = ''

  @jsonMember({ constructor: String, name: 'productOrServiceCode' })
  pscCode: string = ''

  @jsonMember({ constructor: String, name: 'productOrServiceCodeDescription' })
  psc: string = ''

  @jsonMember({ constructor: String, name: 'recipientName' })
  awardee: string = ''

  @jsonMember({ constructor: String })
  idvType: string = ''

  @jsonMember({ constructor: String })
  awardOrIdvFlag: string = ''

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  totalDollarsObligated: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  currentTotalValueOfAward: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  potentialTotalValueOfAward: number = 0

  @jsonMember({constructor: String})
  categorization?: string = ''
}

@jsonObject
export class AwardListResponseModel extends BasePaginationModel {
  @jsonArrayMember(AwardListItem)
  data: AwardListItem[] = []
}

@jsonObject
export class AwardDetailsCalcCompetition {
  @jsonMember({ constructor: Number, deserializer: (val) => Number(val)})
  competitors: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val)})
  offers: number = 0

  @jsonMember({ constructor: String })
  ratio: string = ''

  @jsonMember({constructor: Number})
  subs: number = 0
}

@jsonObject
export class AwardDetailsCalc {
  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  totalValue: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  paid: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  unusedBudget: number = 0

  @jsonMember({ constructor: Date })
  unifiedEndDate: Date | null = null

  @jsonMember({ constructor: Date })
  startDate: Date = new Date()

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  totalDuration: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  durationToDate: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  remainingTime: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  averageSpendDay: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  spendingYear: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  expectedSpend: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  estimatedExcessBudget: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  fteYear: number = 0

  @jsonMember({ constructor: AwardDetailsCalcCompetition })
  competition: AwardDetailsCalcCompetition = new AwardDetailsCalcCompetition()
}

@jsonObject
export class HpRfpRelevantDateModel {
  @jsonMember({constructor: String})
  type: string = ''

  @jsonMember({constructor: Date})
  date: Date = new Date()

  @jsonMember({constructor: Date})
  endDate: Date = new Date()
}

@jsonObject
export class HpRfpAwardPointOfContactModel {
  @jsonMember({constructor: String})
  name: string = ''

  @jsonMember({constructor: String})
  email: string = ''

  @jsonMember({constructor: String})
  phone: string = ''
}

@jsonObject
export class HpRfpRelevantDataModel {
  @jsonMember({constructor: String})
  description: string = ''

  @jsonArrayMember(HpRfpRelevantDateModel)
  dates: HpRfpRelevantDateModel[] = []

  @jsonArrayMember(HpRfpAwardPointOfContactModel)
  pointsOfContact: HpRfpAwardPointOfContactModel[] = []
}

@jsonObject
export class HpRfpAwardeesModel {
  @jsonMember({constructor: CompanyProfilePreviewModel})
  prime: CompanyProfilePreviewModel = new CompanyProfilePreviewModel()

  @jsonArrayMember(CompanyProfilePreviewModel)
  subs: CompanyProfilePreviewModel[] = []
}

@jsonObject
export class HpRfpSpendingItemModel {
  @jsonMember({constructor: String})
  pscCode: string = ''

  @jsonMember({constructor: Number})
  amount: number = 0
}

@jsonObject
export class HpRfpSpendingModel {
  @jsonArrayMember(HpRfpSpendingItemModel)
  product: HpRfpSpendingItemModel[] = []

  @jsonArrayMember(HpRfpSpendingItemModel)
  service: HpRfpSpendingItemModel[] = []

  @jsonArrayMember(HpRfpSpendingItemModel)
  civilian: HpRfpSpendingItemModel[] = []

  @jsonArrayMember(HpRfpSpendingItemModel)
  defense: HpRfpSpendingItemModel[] = []
}

@jsonObject
export class AwardLinkedContract {
  @jsonMember({constructor: Date})
  date: Date | null = null

  @jsonMember({constructor: String})
  description: string = ''

  @jsonMember({constructor: String})
  solicitationNumber: string = ''

  @jsonMember({constructor: String})
  type: string = ''
}

@jsonObject
export class AwardTimeline {
  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: Date })
  date: Date | null = null
}

@jsonObject
export class AwardDetailsItem {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  contractTransactionUniqueKey: string = ''

  @jsonMember({constructor: String})
  contractAwardUniqueKey: string = ''

  @jsonMember({constructor: String})
  awardIdPiid: string = ''

  @jsonMember({constructor: String})
  parentAwardIdPiid: string = ''

  @jsonMember({constructor: Date})
  actionDate: Date = new Date()

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  currentTotalValueOfAward: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  baseAndAllOptionsValue: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  potentialTotalValueOfAward: number = 0

  @jsonMember({constructor: Date})
  periodOfPerformanceStartDate: Date | null = null

  @jsonMember({constructor: Date})
  periodOfPerformancePotentialEndDate: Date | null = null

  @jsonMember({constructor: Date})
  orderingPeriodDate: Date = new Date()

  @jsonMember({constructor: Date})
  solicitationDate: Date = new Date()

  @jsonMember({constructor: String})
  awardingAgencyCode: string = ''

  @jsonMember({constructor: String})
  awardingAgencyName: string = ''

  @jsonMember({constructor: String})
  fundingAgencyCode: string = ''

  @jsonMember({constructor: String})
  fundingAgencyName: string = ''

  @jsonMember({constructor: String})
  awardingSubAgencyCode: string = ''

  @jsonMember({constructor: String})
  awardingSubAgencyName: string = ''

  @jsonMember({constructor: String})
  fundingSubAgencyCode: string = ''

  @jsonMember({constructor: String})
  fundingSubAgencyName: string = ''

  @jsonMember({constructor: String})
  awardingOfficeCode: string = ''

  @jsonMember({constructor: String})
  awardingOfficeName: string = ''

  @jsonMember({constructor: String})
  fundingOfficeCode: string = ''

  @jsonMember({constructor: String})
  fundingOfficeName: string = ''

  @jsonMember({constructor: String})
  recipientDuns: string = ''

  @jsonMember({constructor: String})
  primaryPOPCountryCode: string = ''

  @jsonMember({constructor: String})
  primaryPOPCityName: string = ''

  @jsonMember({constructor: String})
  primaryPOPStateCode: string = ''

  @jsonMember({constructor: String})
  primaryPOPStateName: string = ''

  @jsonMember({constructor: String})
  primaryPOPZip: string = ''

  @jsonMember({constructor: String})
  primaryPOPCountryName: string = ''

  @jsonMember({constructor: String})
  awardOrIdvFlag: string = ''

  @jsonMember({constructor: String})
  typeOfContractPricingCode: string = ''

  @jsonMember({constructor: String})
  awardDescription: string = ''

  @jsonMember({constructor: String})
  actionTypeCode: string = ''

  @jsonMember({constructor: String})
  solicitationIdentifier: string = ''

  @jsonMember({constructor: String})
  productOrServiceCode: string = ''

  @jsonMember({constructor: String, name: 'productOrServiceCodeDescription'})
  productOrService: string = ''

  @jsonMember({constructor: String})
  naicsCode: string = ''

  @jsonMember({constructor: String})
  naics: string = ''

  @jsonMember({constructor: String})
  solicitationProceduresCode: string = ''

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  numberOfOffersReceived: number = 0

  @jsonMember({constructor: String})
  fedBizOppsCode: string = ''

  @jsonMember({constructor: String})
  majorProgram: string = ''

  @jsonMember({constructor: String})
  typeOfSetAsideCode: string = ''

  @jsonMember({constructor: String})
  typeOfSetAside: string = ''

  @jsonMember({constructor: String})
  businessSizeCode: string = ''

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  federalActionObligation: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  totalDollarsObligated: number = 0

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  baseAndExercisedOptionsValue: number = 0

  @jsonMember({constructor: String})
  idvType: string = ''

  @jsonMember({constructor: String})
  parentAwardType: string = ''

  @jsonMember({constructor: String})
  awardType: string = ''

  @jsonMember({constructor: String})
  alaskanNativeCorporationOwnedFirm: string = ''

  @jsonMember({constructor: String})
  americanIndianOwnedBusiness: string = ''

  @jsonMember({constructor: String})
  indianTribeFederallyRecognized: string = ''

  @jsonMember({constructor: String})
  nativeHawaiianOrganizationOwnedFirm: string = ''

  @jsonMember({constructor: String})
  triballyOwnedFirm: string = ''

  @jsonMember({constructor: String})
  veteranOwnedBusiness: string = ''

  @jsonMember({constructor: String})
  serviceDisabledVeteranOwnedBusiness: string = ''

  @jsonMember({constructor: String})
  womanOwnedBusiness: string = ''

  @jsonMember({constructor: String})
  womenOwnedSmallBusiness: string = ''

  @jsonMember({constructor: String})
  economicallyDisadvantagedWomenOwnedSmallBusiness: string = ''

  @jsonMember({constructor: String})
  minorityOwnedBusiness: string = ''

  @jsonMember({constructor: String})
  c8aProgramParticipant: string = ''

  @jsonMember({constructor: String})
  historicallyUnderutilizedBusinessZoneHubzoneFirm: string = ''

  @jsonMember({constructor: Date})
  periodOfPerformanceCurrentEndDate: Date | null = null

  @jsonMember({constructor: String})
  multipleOrSingleAwardIdvTypeCode: string = ''

  @jsonMember({constructor: String})
  recipientName: string = ''

  @jsonMember({constructor: String})
  recipientAddressLine: string = ''

  @jsonMember({constructor: String})
  recipientCityName: string = ''

  @jsonMember({constructor: String})
  recipientStateCode: string = ''

  @jsonMember({constructor: String})
  recipientStateName: string = ''

  @jsonMember({constructor: String})
  recipientCountyName: string = ''

  @jsonMember({constructor: String})
  recipientCountryCode: string = ''

  @jsonMember({constructor: String})
  recipientZipCode: string = ''

  @jsonMember({constructor: String})
  recipientPhoneNumber: string = ''

  @jsonMember({constructor: String})
  recipientFaxNumber: string = ''

  @jsonMember({constructor: String})
  disasterEmergencyFundCodesForOverallAward: string = ''

  @jsonMember({constructor: String})
  usaspendingPermalink: string = ''

  @jsonMember({constructor: AwardDetailsCalc})
  calc: AwardDetailsCalc = new AwardDetailsCalc()

  @jsonMember({constructor: HpRfpDetailModel})
  actual: HpRfpDetailModel = new HpRfpDetailModel()

  @jsonMember({constructor: HpRfpRelevantDataModel})
  relevant: HpRfpRelevantDataModel = new HpRfpRelevantDataModel()

  @jsonMember({constructor: HpRfpAwardeesModel})
  awardees: HpRfpAwardeesModel = new HpRfpAwardeesModel()

  @jsonMember({constructor: HpRfpSpendingModel})
  spendings: HpRfpSpendingModel = new HpRfpSpendingModel()

  @jsonArrayMember(AwardLinkedContract)
  linkedContracts: AwardLinkedContract[] = []

  @jsonMember(HpRfpDetailModel)
  linkedActiveContract: HpRfpDetailModel = new HpRfpDetailModel()

  @jsonArrayMember(AwardTimeline)
  timeline: AwardTimeline[] = []

  @jsonMember({constructor: String})
  extentCompeted: string = ''
}

@jsonObject
export class Transaction {
  @jsonMember({constructor: String})
  awardDescription: string = ''

  @jsonMember({constructor: String})
  actionTypeCode: string = ''

  @jsonMember({constructor: Number, deserializer: (val) => Number(val)})
  federalActionObligation: number = 0

  @jsonMember({constructor: Date})
  actionDate: Date = new Date()
}

@jsonObject
export class AwardDetailsResponseModel extends BasePaginationModel {
  @jsonMember({constructor: AwardDetailsItem})
  data: AwardDetailsItem = new AwardDetailsItem()
}

@jsonObject
export class AwardTransactionsResponseModel extends BaseResponseModel {
  @jsonArrayMember(Transaction)
  data: Transaction[] = []
}

@jsonObject
export class DashboardExtendedCount {
  @jsonMember({ constructor: Number })
  total: number = 0

  @jsonMember({ constructor: Number })
  idiq: number = 0

  @jsonMember({ constructor: Number })
  soleSource: number = 0

  @jsonMember({ constructor: Number })
  samSap: number = 0

  @jsonMember({ constructor: Number })
  samSetAsideCost: number = 0

  @jsonMember({ constructor: Number })
  samOpenCost: number = 0

  @jsonMember({ constructor: Number })
  samSetAsideFfp: number = 0

  @jsonMember({ constructor: Number })
  gsaSchedule: number = 0

  @jsonMember({ constructor: Number })
  samOpenFfp: number = 0
}

@jsonObject
export class _DashboardCommonItem {
  @jsonMember({ constructor: String })
  name: string = ''

  @jsonMember({ constructor: Number })
  total?: number = 0
}

@jsonObject
export class DashboardItem extends _DashboardCommonItem {
  @jsonMember({ constructor: Number })
  count: number = 0

  @jsonArrayMember(() => DashboardItem)
  items?: DashboardItem[] = []
}

@jsonObject
export class DashboardExtendedCountItem extends _DashboardCommonItem {
  @jsonMember({ constructor: DashboardExtendedCount })
  count: DashboardExtendedCount = new DashboardExtendedCount()

  @jsonArrayMember(() => DashboardExtendedCountItem)
  items?: DashboardExtendedCountItem[] = []
}

@jsonObject
export class DashboardCustomer {
  @jsonMember({constructor: DashboardItem})
  dod: DashboardItem = new DashboardItem()

  @jsonMember({constructor: DashboardItem})
  civilian: DashboardItem = new DashboardItem()
}

@jsonObject
export class DashboardPayload {
  @jsonMember({ constructor: DashboardCustomer })
  customers: DashboardCustomer = new DashboardCustomer()

  @jsonArrayMember(DashboardExtendedCountItem)
  agencies: DashboardExtendedCountItem[] = []

  @jsonArrayMember(DashboardItem)
  states: DashboardItem[] = []
}

@jsonObject
export class DashboardResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: DashboardPayload })
  data: DashboardPayload = new DashboardPayload()
}

@jsonObject
export class DashboardSimpleMarketItem {
  @jsonMember({ constructor: String })
  name: MarketVendors | '' = ''

  @jsonMember({ constructor: Number })
  count: number = 0

  @jsonMember({ constructor: Number })
  total: number = 0
}

@jsonObject
export class DashboardSimplePayload {
  @jsonArrayMember(DashboardSimpleMarketItem)
  marketSize: DashboardSimpleMarketItem[] = []
}

@jsonObject
export class DashboardSimpleResponseModel {
  @jsonMember({ constructor: DashboardSimplePayload })
  data: DashboardSimplePayload = new DashboardSimplePayload()
}

@jsonObject
export class SpendingChartItem {
  @jsonMember({ constructor: String })
  date: string = ''

  @jsonMember({ constructor: Number })
  documents: number = 0

  @jsonMember({ constructor: Number })
  rollingTotal: number = 0

  @jsonMember({ constructor: Number })
  total: number = 0
}


@jsonObject
export class SpendingChartModel {
  @jsonMember({ constructor: Number })
  redLine: number = 0

  @jsonMember({ constructor: Number })
  trendLine: number = 0

  @jsonArrayMember(SpendingChartItem)
  projectedMonths: SpendingChartItem[] = []

  @jsonArrayMember(SpendingChartItem)
  actualMonths: SpendingChartItem[] = []
}

@jsonObject
export class SpendingIndicatorsModel {
  @jsonMember({ constructor: Number })
  excessedCeiling: number = 0

  @jsonMember({ constructor: Number })
  ceiling: number = 0

  @jsonMember({ constructor: String })
  colorOfMoney: string = ''

  @jsonMember({ constructor: String })
  yearsRemaining: string = ''
}


@jsonObject
export class SpendingModel {
  @jsonMember({ constructor:  SpendingChartModel})
  chart: SpendingChartModel = new SpendingChartModel()

  @jsonMember({ constructor:  SpendingIndicatorsModel})
  indicators: SpendingIndicatorsModel = new SpendingIndicatorsModel()
}


@jsonObject
export class SpendingResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SpendingModel })
  data: SpendingModel = new SpendingModel()
}


@jsonObject
export class AwardSizeModel {
  @jsonMember({ constructor:  String})
  awardDescription: string = ''

  @jsonMember({ constructor:  String})
  currentTotalValueOfAward: string = ''

  @jsonMember({ constructor:  String})
  id: string = ''

  @jsonMember({ constructor:  Number})
  numberOfAwards: number = 0

  @jsonMember({ constructor:  Number})
  numberOfOffersReceived: number = 0
}


@jsonObject
export class AwardSizeResponseModel extends BaseResponseModel {
  @jsonArrayMember(AwardSizeModel)
  data: AwardSizeModel[] = []
}


@jsonObject
export class TransitionModel {
  @jsonMember({ constructor:  Number})
  id: number = 0

  @jsonMember({ constructor:  String})
  title: string = ''

  @jsonMember({ constructor:  Number})
  value: number = 0
}


@jsonObject
export class TransitionResponseModel extends BaseResponseModel {
  @jsonArrayMember(TransitionModel)
  data: TransitionModel[] = []
}


@jsonObject
export class SummaryOptionItemModel {
  @jsonMember({ constructor:  Number})
  id: number = 0

  @jsonMember({ constructor:  String})
  title: string = ''

  @jsonMember({ constructor:  String})
  description: string = ''

  @jsonMember({ constructor:  Number})
  rank: number = 0
}

@jsonObject
export class SummaryOptionModel {
  @jsonMember({ constructor:  String})
  name: string = ''

  @jsonArrayMember(SummaryOptionItemModel)
  options: SummaryOptionItemModel[] = []
}


@jsonObject
export class SummaryOptionsModel {
  @jsonMember({ constructor:  SummaryOptionModel})
  awardSize: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  transition: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  marketSize: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  internationMarkets: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  ipRights: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  awardChances: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  productionTime: SummaryOptionModel = new SummaryOptionModel()

  @jsonMember({ constructor:  SummaryOptionModel})
  solicitationComplexity: SummaryOptionModel = new SummaryOptionModel()
}


@jsonObject
export class SummaryOptionsResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SummaryOptionsModel })
  data: SummaryOptionsModel = new SummaryOptionsModel()
}


@jsonObject
export class SummaryRecordsModel {
  @jsonMember({ constructor:  SummaryOptionItemModel})
  awardSize: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  transition: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  marketSize: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  internationMarkets: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  ipRights: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  awardChances: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  productionTime: SummaryOptionItemModel = new SummaryOptionItemModel()

  @jsonMember({ constructor:  SummaryOptionItemModel})
  solicitationComplexity: SummaryOptionItemModel = new SummaryOptionItemModel()
}


@jsonObject
export class SummaryModel {
  @jsonMember({ constructor:  SummaryRecordsModel})
  records: SummaryRecordsModel = new SummaryRecordsModel()

  @jsonMember({ constructor:  Number})
  total: number = 0
}


@jsonObject
export class SummaryResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: SummaryModel })
  data: SummaryModel = new SummaryModel()
}

@jsonObject
export class TransitionMarketOptionModel {
  @jsonMember({ constructor:  Number})
  id: number = 0

  @jsonMember({ constructor:  String})
  title: string = ''

  @jsonMember({ constructor:  String})
  value: string = ''
}


@jsonObject
export class TransitionMarketOptionsResponseModel extends BaseResponseModel {
  @jsonArrayMember(TransitionMarketOptionModel)
  data: TransitionMarketOptionModel[] = []
}


@jsonObject
export class TransitionMarketUSGMarketModel {
  @jsonMember({ constructor:  Number})
  keywordTotal: number = 0

  @jsonMember({ constructor:  Number})
  incorporationKeywordTotal: number = 0
}


@jsonObject
export class TransitionMarketUSGMarketResponseModel extends BaseResponseModel {
  @jsonMember({constructor: TransitionMarketUSGMarketModel})
  data: TransitionMarketUSGMarketModel = new TransitionMarketUSGMarketModel()
}


@jsonObject
export class SolicitationModel {
  @jsonMember({ constructor:  String})
  id: string = ''

  @jsonMember({ constructor:  String})
  email: string = ''
  
  @jsonMember({ constructor:  String})
  phone: string = ''

  @jsonMember({ constructor:  String})
  title: string = ''

  @jsonMember({ constructor:  String})
  fullName: string = ''
}


@jsonObject
export class PostAwardModel {
  @jsonMember({ constructor:  String})
  id: string = ''

  @jsonMember({ constructor:  String})
  createdBy: string = ''
  
  @jsonMember({ constructor:  String})
  createdAt: string = ''

  @jsonMember({ constructor:  String})
  updatedBy: string = ''

  @jsonMember({ constructor:  String})
  updatedAt: string = ''

  @jsonMember({ constructor:  String})
  approvedBy: string = ''

  @jsonMember({ constructor:  String})
  approvedAt: string = ''

  @jsonMember({ constructor:  String})
  closedBy: string = ''

  @jsonMember({ constructor:  String})
  closedAt: string = ''
}


@jsonObject
export class GovContractsModel {
  @jsonArrayMember(SolicitationModel)
  samPocs: SolicitationModel[] = []

  @jsonArrayMember(PostAwardModel)
  fpdsPocs: PostAwardModel[] = []
}


@jsonObject
export class GovContractsResponseModel extends BaseResponseModel {
  @jsonMember({constructor: GovContractsModel})
  data: GovContractsModel = new GovContractsModel()
}


@jsonObject
export class TransitionMarketOverviewByTypeRecordModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  primeAwardDescription: string = ''

  @jsonMember({constructor: String})
  totalDollarsObligated: string = ''

  @jsonMember({constructor: String})
  fundingSubAgencyName: string = ''
}


@jsonObject
export class TransitionMarketOverviewByTypeSubAgencieModel {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  title: string = ''

  @jsonMember({constructor: Number})
  value: number = 0
}


@jsonObject
export class TransitionMarketOverviewByTypeModel {
  @jsonArrayMember(TransitionMarketOverviewByTypeRecordModel)
  records: TransitionMarketOverviewByTypeRecordModel[] = []

  @jsonArrayMember(TransitionMarketOverviewByTypeSubAgencieModel)
  subAgencies: TransitionMarketOverviewByTypeSubAgencieModel[] = []
}


@jsonObject
export class TransitionMarketOverviewByTypeResponseModel extends BaseResponseModel {
  @jsonMember({constructor: TransitionMarketOverviewByTypeModel})
  data: TransitionMarketOverviewByTypeModel = new TransitionMarketOverviewByTypeModel()
}
