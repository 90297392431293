import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetMarketResearch } from 'store/hp/market-research/actions'
import SearchName from './modules/SearchName'
import SearchKeywords from './modules/SearchKeywords'
import SearchCategorization from './modules/SearchCategorization'
import SearchCodes from './modules/SearchCodes'
import KeywordsResults from './modules/SearchKeywords/KeywordsResults'
import SearchAgencies from './modules/SearchAgencies'
import SearchRegions from './modules/SearchRegions'
import SearchMarketSize from './modules/SearchMarketSize'
import WithSearchRoute from './components/WithSearchRoute'
import SearchIntro from './modules/SearchIntro'

const MarketResearch = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => resetMarketResearch(dispatch)
  }, [])

  return (
    <div className={styles['container']}>
      <Switch>
        <Route exact path={'/user/market-research'}>
          <SearchIntro />
        </Route>
        <Route path={'/user/market-research/search-name'}>
          <SearchName />
        </Route>
        <Route path={'/user/market-research/keywords'}>
          <WithSearchRoute>
            <SearchKeywords/>
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/categorization'}>
          <WithSearchRoute>
            <SearchCategorization />
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/keywords-results'}>
          <WithSearchRoute>
            <KeywordsResults />
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/codes'}>
          <WithSearchRoute>
            <SearchCodes />
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/regions'}>
          <WithSearchRoute>
            <SearchRegions />
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/agencies'}>
          <WithSearchRoute>
            <SearchAgencies />
          </WithSearchRoute>
        </Route>
        <Route path={'/user/market-research/market-size'}>
          <WithSearchRoute>
            <SearchMarketSize />
          </WithSearchRoute>
        </Route>
      </Switch>
    </div>
  )
}

export default MarketResearch