import React from 'react'

export const memoryStore = {
  _data: new Map(),
  get(key) {
    if (!key) {
      return null
    }

    return this._data.get(key) || null
  },
  set(key, data) {
    if (!key) {
      return
    }
    return this._data.set(key, data)
  }
}

/**
 * Component that will save and restore Window scroll position.
 */
export default class ScrollPositionManager extends React.Component {
  constructor(props) {
    super(...arguments)
    this.connectScrollTarget = this.connectScrollTarget.bind(this)
    this.onScrollBottom = this.onScrollBottom.bind(this)
    this.handleScrollBottom = this.handleScrollBottom.bind(this)
    this._target = window
    this._onScrollBottom = () => null
    this.scrollTopStep = 200

    this.state = {
      isScroll: false
    }
  }

  connectScrollTarget(node) {
    this._target = node
  }

  onScrollBottom(node, callback) {
    this._target = node
    this._onScrollBottom = callback
  }

  updateIsScroll() {
    const scrollPosition = getScrollPosition(this._target).y
    if(scrollPosition > this.scrollTopStep && !this.state.isScroll) {
      this.setState({
        isScroll: true
      })
    } 
    if(scrollPosition < this.scrollTopStep && this.state.isScroll) {
      this.setState({
        isScroll: false
      })
    }
  }

  handleScrollBottom() {
    if(!this._target){
      return
    }
    if(getIsScrolledToBottom(this._target)){
      this._onScrollBottom()
    }
    this.updateIsScroll()
  }

  restoreScrollPosition(pos) {
    pos = pos || this.props.scrollStore.get(this.props.scrollKey)
    if (this._target && pos) {
      scroll(this._target, pos.x, pos.y)
    }
  }

  saveScrollPosition(key) {
    if (this._target) {
      const pos = getScrollPosition(this._target)
      key = key || this.props.scrollKey
      this.props.scrollStore.set(key, pos)
    }
  }

  componentDidMount() {
    this.restoreScrollPosition()
    this._target.addEventListener('scroll', this.handleScrollBottom)
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollKey !== prevProps.scrollKey) {
      this.restoreScrollPosition()
    }
  }

  componentWillUnmount() {
    this.saveScrollPosition()
    this._target.removeEventListener('scroll', this.handleScrollBottom)
  }

  render() {
    const { children = null, scrollToTop, ...props } = this.props
    return (
      children &&
      children({
        ...props,
        connectScrollTarget: this.connectScrollTarget,
        onScrollBottom: this.onScrollBottom,
        isScroll: this.state.isScroll
      })
    )
  }
}

ScrollPositionManager.defaultProps = {
  scrollStore: memoryStore
}

function scroll(target, x, y) {
  if (!target) return
  if (target instanceof window.Window) {
    target.scrollTo(x, y)
  } else {
    target.scrollLeft = x
    target.scrollTop = y
  }
}

function getScrollPosition(target) {
  if (target instanceof window.Window) {
    return { x: target.scrollX, y: target.scrollY }
  }

  return { x: target.scrollLeft, y: target.scrollTop }
}

function getIsScrolledToBottom(target) {
  return target.offsetHeight + target.scrollTop >= target.scrollHeight - 10
}
