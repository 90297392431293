import { combineReducers, Dispatch } from 'redux'
import { Record } from 'immutable'
import { IAction, IRecord } from 'types/common'
import { userGetReducer, IUserRecord } from './common/user/reducers'
import { IUserGroupRecord, userGroupReducer as userGroup } from './common/user-group/reducer'
import { ads, IAdsRecord } from './common/ads/reducers'
import { BaseRegionModel } from 'models/hp/location/location.model'
import { ISearchCreateForm, searchCreateFormReducer } from './hp/search-form/reducers'
import { afterSignUpReducer } from './common/sign-up/reducers'
import { hpRfpDetailsReducer, hpRfpListingReducer, IRfpDetails, IHpRfpListing } from './hp/rfp/reducer'
import { hpSearch, ISearchRecord } from './hp/search/reducers'
import { groupedCodesReducer as groupedCodes, IGroupedCodesData } from './hp/grouped-code/reducers'
import { agenciesReducer as agencies, IAgencies } from './hp/agency/reducers'
import { locationsReducer as locations } from './hp/location/reducers'
import { fpRfpDetailsReducer, fpRfpListingReducer, IFpRfpDetails, IFpRfpListWithFilter } from './fp/rfp/reducer'
import { ICompanyProfileRootReducer, rootCompanyProfileReducer } from './hp/company-profiles/company-reducers'
import { IAwardsRootReducer, rootAwardsReducer } from './hp/awards/reducer'
import { marketResearchReducer as marketResearch, IMarketResearch } from './hp/market-research/reducers'
import { marketResearchToolsReducer as marketResearchTools, IMarketResearchTools } from './hp/market-research-tools/reducers'
import { IPipelineRootReducer, rootPipelineReducer } from 'store/hp/pipeline/reducer'
import { educationReducer, IEducationRecord } from 'store/hp/education/reducer'

const GLOBAL_RESET = 'GLOBAL_RESET'
export const globalReset = (dispatch: Dispatch) => {
  dispatch({
    type: GLOBAL_RESET
  })
}

export interface IRootState {
  user: Record<IUserRecord>
  userGroup: Record<IUserGroupRecord>
  ads: Record<IAdsRecord>
  searchCreateForm: Record<ISearchCreateForm>
  signUpStatus: Record<{isAfterSignUp: boolean, signUpFromDetails: string}>
  hpRfpListingReducer: Record<IHpRfpListing>
  hpRfpDetailsReducer: Record<IRfpDetails>
  fpRfpListingReducer: Record<IFpRfpListWithFilter>
  fpRfpDetailsReducer: Record<IFpRfpDetails>
  hpSearch: Record<ISearchRecord>
  groupedCodes: Record<IRecord<IGroupedCodesData>>
  agencies: Record<IAgencies>
  locations: Record<IRecord<BaseRegionModel[]>>
  companyProfile: ICompanyProfileRootReducer
  awards: IAwardsRootReducer
  marketResearch: Record<IRecord<IMarketResearch>>
  marketResearchTools: Record<IMarketResearchTools>
  pipeline: IPipelineRootReducer
  education: Record<IEducationRecord>
}

export const app = combineReducers<IRootState>({
  user: userGetReducer,
  userGroup: userGroup,
  ads: ads,
  searchCreateForm: searchCreateFormReducer,
  signUpStatus: afterSignUpReducer,
  hpRfpListingReducer: hpRfpListingReducer,
  hpRfpDetailsReducer: hpRfpDetailsReducer,
  fpRfpListingReducer: fpRfpListingReducer,
  fpRfpDetailsReducer: fpRfpDetailsReducer,
  hpSearch: hpSearch,
  groupedCodes: groupedCodes,
  agencies: agencies,
  locations: locations,
  companyProfile: rootCompanyProfileReducer,
  awards: rootAwardsReducer,
  marketResearch: marketResearch,
  marketResearchTools: marketResearchTools,
  pipeline: rootPipelineReducer,
  education: educationReducer
})

export const root = (state: IRootState | undefined, action: IAction) => {
  if (action.type === GLOBAL_RESET) {
    return app(undefined, action)
  }
  return app(state, action)
}
