import React from 'react'
import styles from './index.module.scss'
import { Typography, Grid, Paper, Tabs, Tab } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { userInGroup } from 'helpers/user-group'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import Ads from './components/Ads'
import Name from './components/Name'
import Email from './components/Email'
import Password from './components/Password'
import PaymentPlan from './components/PaymentPlan'
import Notifications from './components/Notifications'
import ServiceLine from './ServiceLine'
import ContractUploader from 'components/v2/ContractUploader'
import Sap from './Sap'
import ExpiringContracts from './ExpiringContracts'
import ProfileSimilarCompanies from './SimilarCompanies'
import ProfileCodes from './Codes'
import Keywords from './Keywords'
import UserGroup from './UserGroup'
import Clients from './Clients/Clients'

const Profile = () => {
  const user = useSelector(userSelector) as IUserBaseModel

  const [activeTab, setActiveTab] = React.useState(0)

  return (
    <div className={styles['profile-wrapper']}>
      <div className={styles['container']}>
        <Paper square>
          <Tabs
            indicatorColor='primary'
            textColor='primary'
            value={activeTab}
            onChange={(event, tab) => setActiveTab(tab)}
          >
            <Tab label='Profile' value={0}/>
            <Tab label='Market Research' value={1}/>
            <Tab label='Contracts' value={2}/>
            {userInGroup(user) && (
              <Tab label='My team' value={3}/>
            )}
            <Tab label='Clients' value={4}/>
          </Tabs>
        </Paper>
        {activeTab === 0 && (
          <div className={styles['profile']}>
            <Paper className={styles['profile-paper']}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Email/>
                </Grid>
                <Grid item md={3}>
                  <Name/>
                </Grid>
                <Grid item md={3}>
                  <Password/>
                </Grid>
                <Grid item md={3}>
                  <PaymentPlan/>
                </Grid>
              </Grid>
            </Paper>
            <div style={{ height: '12px' }}/>
            <Typography variant='h3' gutterBottom>Notifications and Ads</Typography>
            <Paper className={styles['profile-paper']}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Notifications/>
                </Grid>
                <Grid item md={6}>
                  <Ads/>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
        {activeTab === 1 && (
          <div className={styles['profile']}>
            <ServiceLine/>

            <div style={{ height: '12px' }}/>
            <Typography variant='h3' gutterBottom>Keywords</Typography>
            <Keywords />

            <div style={{ height: '12px' }}/>
            <Typography variant='h3' gutterBottom>SAP/Micro-purchase</Typography>
            <Sap />

            <div style={{ height: '12px' }}/>
            <Typography variant='h3' gutterBottom>Expiring Contracts</Typography>
            <ExpiringContracts />

            <div style={{height: '12px'}}/>
            <Typography variant='h3' gutterBottom>Saved Companies (max 3 groups)</Typography>
            <ProfileSimilarCompanies/>

            <div style={{height: '12px'}}/>
            <Typography variant='h3' gutterBottom>NAICS/PSC Codes</Typography>
            <ProfileCodes />
          </div>
        )}
        {activeTab === 2 && (
          <div className={styles['profile']}>
            <ContractUploader fromAdmin={false}/>
          </div>
        )}
        {activeTab === 3 && (
          <div className={styles['profile']}>
            <UserGroup />
          </div>
        )}
        {
          activeTab === 4 &&
          <div className={styles['profile']}>
            <Clients />
          </div>
        }
      </div>
    </div>
  )
}

export default Profile
