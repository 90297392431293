import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  getMarketResearchCompanies,
  setMarketResearchCompanies
} from 'store/hp/market-research/actions'
import { SimilarCompanyItem } from 'models/hp/market-research/market-research.model'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import Filters, { ISimilarCompaniesFilters } from './Filters'
import Listing, { ISimilarCompany, ISimilarCompanyMapper } from './Listing'
import { createLazyUserAccount } from 'store/common/user/actions'
import { CreateLazyAccountModel } from 'models/common/user/user.model'
import { userSelector } from 'store/common/user/selectors'
import { userActivity } from 'store/common/owner/actions'
import SearchManagementModal from 'components/RfpDetailsBlocks/SearchFilters/SearchManagementModal'
import SearchManagementForLazyAccountUserModal from 'components/RfpDetailsBlocks/SearchFilters/SearchManagementForLazyAccountUserModal'

let page = 0
let localFilters: ISimilarCompaniesFilters | null = null

interface Props {
  noRedirect?: boolean
}

const SimilarCompany = ({ noRedirect }: Props) => {
  const notification = useRef(useNotification())
  const user = useSelector(userSelector)

  const [listingLoading, setListingLoading] = useState(false)
  const [listingData, setListingData] = useState<SimilarCompanyItem[]>([])
  const [companies, setCompanies] = useState<ISimilarCompanyMapper>({})
  const [isNameModalOpened, setNameModalState] = useState(false)
  const [isNameForLazyAccountUserModalOpened, setNameForLazyAccountUserModalOpened] = useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [moreButtonState, setMoreButtonState] = React.useState(true)

  const addCompany = (company: ISimilarCompany) => {
    setCompanies(prev => ({
      ...prev,
      [company.name]: companies[company.name] ? undefined : company
    }))
  }

  const getListingData = (filters: ISimilarCompaniesFilters) => {
    localFilters = filters
    page = 0

    setListingLoading(true)

    getMarketResearchCompanies(filters, page)
      .then((res) => {
        setListingData(res)
        page += 1
        setMoreButtonState(true)
      })
      .catch(console.log)
      .finally(() => setListingLoading(false))
  }

  const appendListingData = () => {
    return getMarketResearchCompanies(localFilters!, page)
      .then((res) => {
        setListingData(prev => prev.concat(res))
        page += 1
        if (res.length === 0) {
          setMoreButtonState(false)
        }
      })
  }

  const toggleSearchNameModal = () => {
    if (user) {
      setNameModalState(val => !val)
    } else {
      setNameForLazyAccountUserModalOpened(val => !val)
    }
  }

  const saveSelectedCompanies = async (name: string, email?: string) => {
    setProcessing(true)
    try {
      const values = []
      for (let key in companies) {
        if (!companies[key]) continue
        const item = {
          name: companies[key]!.name,
          duns: companies[key]!.duns
        }
        values.push(item)
      }
      if (values.length === 0) {
        return notification.current.show('Please, select at least one option', NotificationTypeList.Warning)
      }
      if (!user) {
        await createLazyUserAccount({email: email} as CreateLazyAccountModel)
      }

      await setMarketResearchCompanies(values, name)

      notification.current.show('Options are saved', NotificationTypeList.Success)

      setProcessing(false)
      setNameModalState(false)
      setNameForLazyAccountUserModalOpened(false)

      userActivity.event(userActivity.activities.marketResearchToolCompaniesUsed)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    userActivity.event(userActivity.activities.marketResearchToolCompanies)
    return () => {
      localFilters = null
      page = 0
    }
  }, [])

  return (
    <div className={styles['container']}>
      <Grid container spacing={2} className={styles['height-block']}>
        <Grid item md={4} className={styles['filter-list']}>
          <Filters
            getListing={getListingData}
            saveCompanies={toggleSearchNameModal}
          />
        </Grid>
        <Grid item md={8} className={styles['height-block']}>
          <Listing
            listingData={listingData}
            listingLoading={listingLoading}
            companies={companies}
            getListing={appendListingData}
            moreButtonState={moreButtonState}
            addCompany={addCompany}
          />
        </Grid>
        {
          isNameModalOpened &&
          <SearchManagementModal
            onClose={() => setNameModalState(val => !val)}
            opened={isNameModalOpened}
            onConfirm={saveSelectedCompanies}
            processing={processing}
          />
        }
        {
          isNameForLazyAccountUserModalOpened &&
          <SearchManagementForLazyAccountUserModal
            onClose={() => setNameForLazyAccountUserModalOpened(val => !val)}
            opened={isNameForLazyAccountUserModalOpened}
            onConfirm={saveSelectedCompanies}
            processing={processing}
          />
        }
      </Grid>
    </div>
  )
}

export default SimilarCompany