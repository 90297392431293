import { IFpRfpDetailModel, IFpRfpDetailResponseModel, IFpRfpModelResponseModel } from 'models/fp/rfp/rfp.interface'
import { FP_RFP_DETAILS, FP_RFP_FILTER, FP_RFP_LIST, FP_RFP_LIST_FILTER, IRfpFilter } from './action'
import { FpRfpDetailResponseModel, FpRfpModelResponseModel } from 'models/fp/rfp/rfp.model'
import { IAction } from 'types/common'
import { Record } from 'immutable'

export interface IFpRfpListWithFilter extends IFpRfpModelResponseModel {
  filterParams: IRfpFilter
}

const FpRfpListRecord = Record<IFpRfpListWithFilter>({
  ...new FpRfpModelResponseModel(),
  filterParams: {}
})

export interface IFpRfpDetails extends IFpRfpDetailResponseModel {
  data: IFpRfpDetailModel | null
}

const FpRfpDetailsRecord = Record<IFpRfpDetails>({
  ...new FpRfpDetailResponseModel(),
  data: null
})

export const fpRfpListingReducer = (state: Record<IFpRfpListWithFilter> = new FpRfpListRecord(), action: IAction<IFpRfpListWithFilter>) => {
  switch (action.type) {
    case FP_RFP_LIST:
      return state
        .set('data', [ ...state.get('data'), ...action.payload?.data || [] ])
        .set('count', action.payload?.count || state.get('count'))
        .set('new', action.payload?.new || state.get('new'))
    case FP_RFP_LIST_FILTER:
      return state
        .set('filterParams', action.payload?.filterParams || state.get('filterParams'))
    case FP_RFP_FILTER:
      return state
        .set('data', action.payload?.data || [])
        .set('count', action.payload?.count || 0)
        .set('new', action.payload?.new || 0)
    default:
      return state
  }
}

export const fpRfpDetailsReducer = (state: Record<IFpRfpDetails> = new FpRfpDetailsRecord(), action: IAction<IFpRfpDetails>) => {
  switch (action.type) {
    case FP_RFP_DETAILS:
      return state.set('data', action.payload ? action.payload.data : null)
    default:
      return state
  }
}
