import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  AWARD_DASHBOARD,
  AWARD_DASHBOARD_ERROR,
  AWARD_DASHBOARD_SUCCESS
} from './actions'
import { DashboardResponseModel } from 'models/hp/awards/awards.model'

export interface IAwardsDashboard {
  data: DashboardResponseModel
  loading: boolean
  loaded: boolean
}

const AwardsDashboard = Record<IAwardsDashboard>({
  data: new DashboardResponseModel(),
  loading: false,
  loaded: false,
})

export const awardsDashboardReducer = (state: Record<IAwardsDashboard> = new AwardsDashboard(), action: IAction) => {
  switch (action.type) {
    case AWARD_DASHBOARD:
      return state
        .set('loading', true)

    case AWARD_DASHBOARD_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)

    case AWARD_DASHBOARD_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)

    default:
      return state
  }
}
