import React from 'react'
import styles from '../Sap/Filter/index.module.scss'
import { IFilterOption } from 'models/hp/search/search.interface'
import { useSelector } from 'react-redux'
import { naicsCodeCountSelector, naicsCodesSelector } from 'store/hp/grouped-code/selectors'
import SearchFilter from 'components/v2/Filters/SearchFilter'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean) => void
  selectedOptions: number[]
}

const NaicsFilter = ({ handlePartialChange, handleChange, selectedOptions }: Props) => {
  const naicsCodesOptions: IFilterOption[] = useSelector(naicsCodesSelector) as IFilterOption[]
  const naicsCount: number = useSelector(naicsCodeCountSelector) as number

  return (
    <div className={styles['paper']}>
      <SearchFilter
        searchLabel='Function search'
        allCount={naicsCount}
        iconType='naics'
        filterTitle='Industry Area (NAICS)'
        filterOptions={naicsCodesOptions}
        selectedFilterOptions={selectedOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default React.memo(NaicsFilter, (prev, next) => prev.selectedOptions.length === next.selectedOptions.length)