import React, { ChangeEvent } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import styles from '../index.module.scss'
import TextField from 'components/v2/TextField'
import Modal from 'components/v2/Modal'
import { updateSearch } from 'store/hp/search/actions'
import { SearchModel } from 'models/hp/search/search.model'

interface Props {
  count: number
  search: SearchModel
  onEdit: () => void
  onDelete: () => void
  onDuplicate: () => void
  handleNameModal: () => void
  isModalOpen: boolean
  isEdit: boolean
  setName: (value: string) => void
}

const RowName = ({ search, onDelete, onDuplicate, onEdit, handleNameModal, isModalOpen, isEdit, setName, count }: Props) => {

  const [customName, setCustomName] = React.useState<string>('')

  React.useEffect(() => {
    setCustomName(search.name)
  }, [search, setCustomName])

  const onConfirm = async () => {
    try {
      await updateSearch({ name: customName }, search.id.toString())
      setName(customName)
      handleNameModal()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    !isEdit && search.id ?
      <Grid item md={4}>
        <Typography variant='h4'>{customName || '-'}</Typography>
        <hr/>
        <div style={{ display: 'inline-grid' }}>
          <Button
            className={styles['action']}
            variant='outlined'
            onClick={onEdit}
            color='primary'
          >
            Edit
          </Button>
          {count < 3 &&
          <Button
            className={styles['action']}
            variant='outlined'
            onClick={onDuplicate}
            color='primary'
          >
            Duplicate
          </Button>
          }
          <Button
            className={styles['action']}
            variant='outlined'
            onClick={onDelete}
            color='primary'
          >
            Delete
          </Button>
        </div>
      </Grid>
      :
      <Modal
        title='What would you like to name this Product/Service Line?'
        onConfirm={onConfirm}
        onClose={handleNameModal}
        open={isModalOpen}
        confirmLoading={!customName}
      >
        <TextField
          label='Service line name'
          variant='filled'
          value={customName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomName(event.target.value || '')}
          fullWidth
        />
      </Modal>
  )
}

export default RowName
