import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import {
  IFpReportModel,
  IFpRfpDetailModel,
  IFpRfpDetailResponseModel,
  IFpRfpModel,
  IFpRfpModelResponseModel, IFpRfpReportResponseModel, IFpSolicitation, IFpSolicitationDetails, ISubtopic
} from './rfp.interface'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { HpRfpLightCalcModel } from '../../hp/rfp/calc/calc.model'


@jsonObject
export class FpSolicitation implements IFpSolicitation {
  @jsonMember({ constructor: String })
  id: string = ''
  @jsonMember({ constructor: String })
  agency: string = ''
  @jsonMember({ constructor: String })
  closeDate: string = ''
  @jsonMember({ constructor: String })
  openDate: string = ''
  @jsonMember({ constructor: String })
  phase: string = ''
  @jsonMember({ constructor: String })
  program: string = ''
  @jsonMember({ constructor: String })
  sbirSolicitationLink: string = ''
  @jsonMember({ constructor: String })
  solicitationAgencyURL: string = ''
  @jsonMember({ constructor: String })
  solicitationTitle: string = ''
}

@jsonObject
export class FpSolicitationDetails extends FpSolicitation implements IFpSolicitationDetails {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  branch: string = ''

  @jsonMember({ constructor: String })
  currentStatus: string = ''

  @jsonMember({ constructor: String })
  occurrenceNumber: string = ''

  @jsonMember({ constructor: String })
  releaseDate: string = ''

  @jsonMember({ constructor: String })
  solicitationNumber: string = ''

  @jsonMember({ constructor: Number })
  solicitationYear: number = 0
}

@jsonObject
export class FpRfpModel implements IFpRfpModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: String })
  number: string = ''

  @jsonMember({ constructor: String })
  categorizationType: string = ''

  @jsonMember({ constructor: FpSolicitation })
  solicitation: FpSolicitation | null = null
}
@jsonObject
export class SubtopicModel implements ISubtopic {
  @jsonMember({ constructor: String })
  id: string = ''
  @jsonMember({ constructor: String })
  title: string = ''
  @jsonMember({ constructor: String })
  branch: string = ''
  @jsonMember({ constructor: String })
  number: string = ''
  @jsonMember({ constructor: String })
  description: string = ''
  @jsonMember({ constructor: Boolean })
  dualUse: boolean = false
}

@jsonObject
export class FpRfpDetailModel implements IFpRfpDetailModel {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  branch: string = ''
  @jsonMember({ constructor: String })

  categorizationType: string = ''
  @jsonMember({ constructor: String })
  checksum: string = ''

  @jsonMember({ constructor: String })
  description: string = ''

  @jsonMember({ constructor: Boolean })
  dualUse: boolean = false

  @jsonMember({ constructor: String })
  link: string = ''

  @jsonMember({ constructor: String })
  number: string = ''

  @jsonArrayMember(SubtopicModel)
  subtopics: SubtopicModel[] = []

  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: HpRfpLightCalcModel })
  calc?: HpRfpLightCalcModel = undefined

  @jsonMember({ constructor: FpSolicitationDetails })
  solicitation: FpSolicitationDetails | null = null
}

@jsonObject
export class FpRfpDetailResponseModel extends BaseResponseModel implements IFpRfpDetailResponseModel {
  @jsonMember({ constructor: FpRfpDetailModel })
  data: FpRfpDetailModel | null = null
}

@jsonObject
export class FpRfpModelResponseModel extends BasePaginationModel implements IFpRfpModelResponseModel {
  @jsonArrayMember(FpRfpModel)
  data: FpRfpModel[] = []
}

@jsonObject
export class FpRfpReportModel implements IFpReportModel {
  @jsonMember({constructor: String})
  url: string = ''
}

@jsonObject
export class FpRfpReportResponseModel extends BaseResponseModel implements IFpRfpReportResponseModel {
  @jsonMember({ constructor: FpRfpReportModel })
  data: FpRfpReportModel | null = null
}
