import React  from 'react'
import styles from './index.module.scss'
import moment from 'moment'
import { Divider, Grid, Typography } from '@material-ui/core'
import Header from 'components/RfpDetailsBlocks/Header'
import { previewProps } from 'components/v2/PscImg'
import { AwardListItem } from 'models/hp/awards/awards.model'
import { numberToStrWithRound } from 'common/number'
import { getSetAsideText, getSingleMultipleIdvByCode } from 'helpers/rfp'

interface Props {
  rfp: AwardListItem
  onClick: () => void
  onCategorizeClick?: () => void
  onDeleteClick?: () => void
}

const RfpItem = ({rfp, onClick, onCategorizeClick, onDeleteClick}: Props) => {
  const getBiggerValue = (a: number, b: number): string | undefined => {
    return numberToStrWithRound(Math.max(a, b))
  }

  const isIdv = rfp.awardOrIdvFlag === 'IDV'
  const shortenTitle = rfp.title.length < 144 ?  rfp.title.replace(/^(.*?)!/g, '') : rfp.title.substring(0, 141).replace(/^(.*?)!/g, '') + '...'

  const renderBody = () => {
    if (isIdv) {
      const availableCeiling = rfp.potentialTotalValueOfAward - rfp.totalDollarsObligated
      return (
        <>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid md={5} item>
              <Typography>Winner:</Typography>
              <Typography variant='h5'>
                {rfp.awardee || 'N/A'}
              </Typography>
            </Grid>
            <Grid md={3} item>
              <Typography>Available ceiling:</Typography>
              <Typography variant='h5'>
                {availableCeiling ? numberToStrWithRound(availableCeiling) : 'N/A'}
              </Typography>
            </Grid>
            <Grid md={4} item>
              <Typography>Type:</Typography>
              <Typography variant='h5'>
                {`${getSingleMultipleIdvByCode(rfp.multipleOrSingleAwardIdvTypeCode)} Award ${rfp.idvType}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' className={styles['header-content']} spacing={2}>
            <Grid md={2} item>
              <Typography>End Dates:</Typography>
              <Typography variant='h5'>
                {rfp.endDate ? moment(rfp.endDate).format('MMM YYYY') : 'N/A'}
              </Typography>
            </Grid>
            <Grid md={5} item>
              <Typography>Set aside:</Typography>
              <Typography variant='h5'>{getSetAsideText(rfp.setAside)}</Typography>
            </Grid>
            <Grid md={5} item>
              <Typography>Customer:</Typography>
              <Typography variant='h5'>{rfp.agency || 'N/A'}</Typography>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid md={4} item>
              <Typography>Winner:</Typography>
              <Typography variant='h5'>
                {rfp.awardee || 'N/A'}
              </Typography>
            </Grid>
            <Grid md={2} item>
              <Typography>Current value:</Typography>
              <Typography variant='h5'>
                {getBiggerValue(rfp.totalDollarsObligated, rfp.currentTotalValueOfAward) || 'N/A'}
              </Typography>
            </Grid>
            <Grid md={3} item>
              <Typography>Type: </Typography>
              <Typography variant='h5'>
                {
                  rfp.parentAwardType
                    ? `${rfp.awardType} under a ${rfp.parentAwardType}`
                    : `${rfp.awardType} from SAM`
                }
              </Typography>
            </Grid>
            <Grid md={3} item>
              <Typography>Location:</Typography>
              <Typography variant='h5'>
                {[rfp.city, rfp.stateCode].filter(x => !!x).join(', ') || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' className={styles['header-content']} spacing={2}>
            <Grid md={2} item>
              <Typography>End Dates:</Typography>
              <Typography variant='h5'>
                {moment(rfp.endDate).format('MMM YYYY')}
              </Typography>
            </Grid>
            <Grid md={5} item>
              <Typography>Set aside:</Typography>
              <Typography variant='h5'>
                {getSetAsideText(rfp.setAside)}
              </Typography>
            </Grid>
            <Grid md={5} item>
              <Typography>Customer:</Typography>
              <Typography variant='h5'>{rfp.agency || 'N/A'}</Typography>
            </Grid>
          </Grid>
        </>
      )
    }
  }
  return (
    <div className={styles['rfp-list-item']}>
      <Header
        title={isIdv? rfp.psc : shortenTitle}
        titleClass={styles['header-title']}
        pscCode={rfp.pscCode}
        previewProps={previewProps}
        actions={{
          navigate: {
            handler: onClick
          },
          like: onCategorizeClick && {
            state: rfp.categorization === 'like',
            handler: onCategorizeClick,
          },
          delete: onDeleteClick && {
            state: rfp.categorization === 'trash',
            handler: onDeleteClick
          }
        }}
      >
        <Divider light style={{margin: '24px 0'}} />
        {renderBody()}
      </Header>
    </div>
  )
}

export default RfpItem