import React from 'react'
import styles from './index.module.scss'
import { Typography, FormControl, InputBase, alpha, withStyles, Theme, createStyles } from '@material-ui/core'
import ButtonLink from 'components/v3/ButtonLink'
import { IPiplineHealthItem } from 'models/hp/pipeline/pipeline.interface'
import { Formik, FormikProps } from 'formik'
import { NumericFormat } from 'react-number-format'
import { getPiplineHealth, setPiplineHealth } from 'store/hp/pipeline/rfp/action'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { useDispatch } from 'react-redux'

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 12,
      width: '95px',
      padding: '6px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase)

interface TargetsProps {
  data: IPiplineHealthItem[]
}

interface IFormValues {
  [key: string]: string
}

const Targets = ({data}: TargetsProps) => {
  const dispatch = useDispatch()
  const notification = useNotification()

  const generateFormValues = (isInitial = true) => {
    return data.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: isInitial ? String(item.default) : ''
      }
    }, {})
  }

  const initialValues = generateFormValues()

  const onSubmit = (values: IFormValues) => {
    const valuesKeys = Object.keys(values)
    const changedValues = valuesKeys.reduce((acc, item) => {
      const regex = /[,/$]/g
      const currentItem = values[item].replace(regex, '')
      return {
        ...acc,
        [item]: Number(currentItem)
      }
    }, {})

    setPiplineHealth(changedValues)
      .then(() => {
        getPiplineHealth(dispatch)
        notification.show('Changes saved', NotificationTypeList.Success)
      })
      .catch(e =>  notification.show(getErrorMessage(e), NotificationTypeList.Error))
  }

  const clearForm = (formikProps: FormikProps<IFormValues>) => {
    const clearValues = generateFormValues(false)
    formikProps.setValues(clearValues)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formikProps: FormikProps<IFormValues>) => (
        <div className={styles['targets']}>
          <div className={styles['targets-header']}>
            <Typography className={styles['targets-header-title']}>Targets</Typography>
            <div className={styles['targets-header-actions']}>
              <ButtonLink title='Clear all' theme='gray' onClick={() => clearForm(formikProps)} />
              {formikProps.dirty ? <ButtonLink title='Apply Values' iconName='check' onClick={formikProps.handleSubmit} /> : null}
            </div>
          </div>
          <div className={styles['targets-inputs']}>
            {
              data.map(el => (
                <FormControl className={styles['targets-inputs-item']} key={el.id}>
                  <NumericFormat
                    name={String(el.id)}
                    value={formikProps.values[String(el.id)]}
                    onChange={formikProps.handleChange} 
                    customInput={BootstrapInput}
                    prefix="$"
                    thousandSeparator
                  />
                </FormControl>
              ))
            }
          </div>
        </div>
      )}
    </Formik>
    
  )
}

export default Targets