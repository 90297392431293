import { IEducationPhaseClass } from 'models/hp/education/education.interface'

export enum EDUCATION_FUNDING_SUBTITLE {
  OVERVIEW = 'FUNDING_OVERVIEW',
  ASSESSING_FEDERAL_FUNDING = 'ASSESSING_FEDERAL_FUNDING',
  QUALIFYING_FOR_FUNDING = 'QUALIFYING_FOR_FUNDING',
  BEST_WAYS_TO_USE_FUNDING = 'BEST_WAYS_TO_USE_FUNDING',
  FOUNDERS_INTERVIEWS = 'FOUNDERS_INTERVIEWS',
  REGISTRATIONS = 'REGISTRATIONS',
  REGISTRATIONS_SAM = 'REGISTRATIONS_SAM',
  REGISTRATIONS_SBIR = 'REGISTRATIONS_SBIR',
  SBIR_DETAILS = 'SBIR_DETAILS',
  SBIR_CHECKLIST = 'SBIR_CHECKLIST',
  FUNDING_CHANCES = 'FUNDING_CHANCES',
  FUNDING_AMOUNTS = 'FUNDING_AMOUNTS',
  TIMING = 'TIMING',
  PURSUING_FEDERAL_FUNDING_BASIC = 'PURSUING_FEDERAL_FUNDING_BASIC',
  PURSUING_FEDERAL_FUNDING_ADVANCED = 'PURSUING_FEDERAL_FUNDING_ADVANCED',
  RELATIONSHIP_BUILDING = 'RELATIONSHIP_BUILDING',
  RELATIONSHIP_WORKSHEET = 'RELATIONSHIP_WORKSHEET',
  SEEDING_TOPICS = 'SEEDING_TOPICS',
  WRITING_PROPOSAL = 'WRITING_PROPOSAL',
  READING_ASSESSING_RFPS = 'READING_ASSESSING_RFPS',
  PLANNING_COMPILANT_PROPOSAL = 'PLANNING_COMPILANT_PROPOSAL',
  SELLING_FEDERAL_FUNDED_TECH = 'SELLING_FEDERAL_FUNDED_TECH',
  OTHER_RESOURCES = 'OTHER_RESOURCES',
  IP_GUIDE = 'IP_GUIDE',
  CLASSES = 'CLASSES'
}


export const EDUCATION_FUNDING_IFRAME_SOURCE: { [key: string]: string | undefined } = {
  [EDUCATION_FUNDING_SUBTITLE.OVERVIEW]: 'https://www.fedscout.com/an-overview-of-federal-product-funding',
  [EDUCATION_FUNDING_SUBTITLE.ASSESSING_FEDERAL_FUNDING]: 'https://www.fedscout.com/assessing-whether-to-pursue-federal-funding',
  [EDUCATION_FUNDING_SUBTITLE.QUALIFYING_FOR_FUNDING]: 'https://www.fedscout.com/qualifying-for-funding',
  [EDUCATION_FUNDING_SUBTITLE.BEST_WAYS_TO_USE_FUNDING]: 'https://www.fedscout.com/the-best-ways-to-use-federal-funding',
  [EDUCATION_FUNDING_SUBTITLE.FOUNDERS_INTERVIEWS]: 'https://www.fedscout.com/undiluted',
  [EDUCATION_FUNDING_SUBTITLE.REGISTRATIONS_SAM]: 'https://www.fedscout.com/registering-in-sam',
  [EDUCATION_FUNDING_SUBTITLE.REGISTRATIONS_SBIR]: 'https://www.fedscout.com/registering-sbirgov',
  [EDUCATION_FUNDING_SUBTITLE.SBIR_CHECKLIST]: 'https://www.fedscout.com/sbir-checklist',
  [EDUCATION_FUNDING_SUBTITLE.FUNDING_CHANCES]: 'https://www.fedscout.com/sbir-chances-of-funding',
  [EDUCATION_FUNDING_SUBTITLE.FUNDING_AMOUNTS]: 'https://www.fedscout.com/sbir-funding-amount',
  [EDUCATION_FUNDING_SUBTITLE.TIMING]: 'https://www.fedscout.com/sbir-timelines',
  [EDUCATION_FUNDING_SUBTITLE.PURSUING_FEDERAL_FUNDING_BASIC]: 'https://www.fedscout.com/pursuing-federal-funding-basic',
  [EDUCATION_FUNDING_SUBTITLE.RELATIONSHIP_BUILDING]: 'https://www.fedscout.com/pursuing-federal-funding-advanced',
  [EDUCATION_FUNDING_SUBTITLE.RELATIONSHIP_WORKSHEET]: '/education/funding/worksheet',
  [EDUCATION_FUNDING_SUBTITLE.SEEDING_TOPICS]: 'https://www.fedscout.com/seeding-funding-topics',
  [EDUCATION_FUNDING_SUBTITLE.READING_ASSESSING_RFPS]: 'https://www.fedscout.com/reading-and-assessing-rfps',
  [EDUCATION_FUNDING_SUBTITLE.PLANNING_COMPILANT_PROPOSAL]: 'https://www.fedscout.com/planning-a-compliant-proposal',
  [EDUCATION_FUNDING_SUBTITLE.SELLING_FEDERAL_FUNDED_TECH]: 'https://www.fedscout.com/selling-federally-funded-tech',
  [EDUCATION_FUNDING_SUBTITLE.IP_GUIDE]: 'https://www.fedscout.com/sbir-ip-for-founders',
  [EDUCATION_FUNDING_SUBTITLE.CLASSES]: 'https://academy.fedscout.com/courses/relationship-building',
}

export const FundingClasses: IEducationPhaseClass[] = [
  {name: EDUCATION_FUNDING_SUBTITLE.OVERVIEW, title: 'An overview of Federal R&D funding'},
  {name: EDUCATION_FUNDING_SUBTITLE.ASSESSING_FEDERAL_FUNDING, title: 'Assessing whether to pursue Federal funding'},
  {name: EDUCATION_FUNDING_SUBTITLE.QUALIFYING_FOR_FUNDING, title: 'Qualifying for funding'},
  {name: EDUCATION_FUNDING_SUBTITLE.BEST_WAYS_TO_USE_FUNDING, title: 'The Best ways to use federal funding'},
  {name: EDUCATION_FUNDING_SUBTITLE.FOUNDERS_INTERVIEWS, title: 'Federally funded founder interviews'},
  {name: EDUCATION_FUNDING_SUBTITLE.REGISTRATIONS, title: 'Registrations', children: [
    {name: EDUCATION_FUNDING_SUBTITLE.REGISTRATIONS_SAM, title: 'SAM'},
    {name: EDUCATION_FUNDING_SUBTITLE.REGISTRATIONS_SBIR, title: 'SBIR'}
  ]},
  {name: EDUCATION_FUNDING_SUBTITLE.SBIR_DETAILS, title: 'SBIR details', children: [
    {name: EDUCATION_FUNDING_SUBTITLE.SBIR_CHECKLIST, title: 'SBIR checklist'},
    {name: EDUCATION_FUNDING_SUBTITLE.FUNDING_CHANCES, title: 'Funding chances'},
    {name: EDUCATION_FUNDING_SUBTITLE.FUNDING_AMOUNTS, title: 'Funding amounts'},
    {name: EDUCATION_FUNDING_SUBTITLE.TIMING, title: 'Timing'},
  ]},
  {name: EDUCATION_FUNDING_SUBTITLE.PURSUING_FEDERAL_FUNDING_BASIC, title: 'Pursuing Federal Funding (Basic)'},
  {name: EDUCATION_FUNDING_SUBTITLE.PURSUING_FEDERAL_FUNDING_ADVANCED, title: 'Pursuing Federal Funding (Advanced)', children: [
    {name: EDUCATION_FUNDING_SUBTITLE.RELATIONSHIP_BUILDING, title: 'Relationship building'},
    {name: EDUCATION_FUNDING_SUBTITLE.RELATIONSHIP_WORKSHEET, title: 'Relationship worksheet', native: true},
    {name: EDUCATION_FUNDING_SUBTITLE.SEEDING_TOPICS, title: 'Seeding topics'},
  ]},
  {name: EDUCATION_FUNDING_SUBTITLE.WRITING_PROPOSAL, title: 'Writing a proposal', children: [
    {name: EDUCATION_FUNDING_SUBTITLE.READING_ASSESSING_RFPS, title: 'Reading/assessing  RFPs'},
    {name: EDUCATION_FUNDING_SUBTITLE.PLANNING_COMPILANT_PROPOSAL, title: 'Planning a compliant proposal'},
  ]},
  {name: EDUCATION_FUNDING_SUBTITLE.SELLING_FEDERAL_FUNDED_TECH, title: 'Selling Federally funded tech'},
  {name: EDUCATION_FUNDING_SUBTITLE.OTHER_RESOURCES, title: 'Other resources', children: [
    {name: EDUCATION_FUNDING_SUBTITLE.IP_GUIDE, title: 'IP Guide'},
  ]},
]

export const FundingClassesNavs = FundingClasses.map(phase => phase.children ? [phase, ...phase.children] : phase).flat()

