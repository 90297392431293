import { Record } from 'immutable'
import { GET_AGENCIES_ERROR, GET_AGENCIES, GET_AGENCIES_SUCCESS, GET_AGENCY_COUNT, SET_CUSTOM_DEPARTMENT_NAMES, SET_CUSTOM_SUBTIER_NAMES } from './actions'
import { IAction, IRecord } from 'types/common'
import { ICustomMapperCustomers } from 'models/hp/agencies/agencies.interface'
import { IFilterOption } from 'models/hp/search/search.interface'

export interface IAgencies extends IRecord<IFilterOption[]> {
  departmentMapper: ICustomMapperCustomers,
  subtierMapper: ICustomMapperCustomers
}

const AgenciesRecord = Record<IAgencies>({
  loaded: false,
  loading: false,
  count: 0,
  data: [],
  departmentMapper: {},
  subtierMapper: {}
})

export const agenciesReducer = (state: Record<IAgencies> = new AgenciesRecord(), action: IAction) => {
  switch (action.type) {
    case GET_AGENCIES:
      return state
        .set('loading', true)
    case GET_AGENCIES_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('data', action.payload)
    case GET_AGENCY_COUNT:
      return state
        .set('count', action.payload)
    case GET_AGENCIES_ERROR:
      return state
        .set('loaded', false)
        .set('loading', false)
    case SET_CUSTOM_DEPARTMENT_NAMES:
      return state
        .set('departmentMapper', action.payload)
    case SET_CUSTOM_SUBTIER_NAMES:
      return state
        .set('subtierMapper', action.payload)
    default:
      return state
  }
}