import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const awards = (state: IRootState) => state.awards

const dashboard = createSelector(
  awards,
  (awards) => awards.dashboard
)

export const awardsDashboardLoadingSelector = createSelector(
  dashboard,
  (dashboard) => dashboard.get('loading')
)

export const awardsDashboardLoadedSelector = createSelector(
  dashboard,
  (dashboard) => dashboard.get('loaded')
)

export const awardsDashboardSelector = createSelector(
  dashboard,
  (dashboard) => dashboard.get('data')
)