import React, { useState } from 'react'
import styles from '../index.module.scss'
import { Typography, Button } from '@material-ui/core'
import Form from './Form'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'

const Password = () => {
  const user = useSelector(userSelector) as IUserBaseModel
  const [toggler, setToggler] = useState(false)

  const renderPresentation = () => {
    return (
      <>
        <div className={styles['text']}>
          <Typography variant='h5'>*************</Typography>
        </div>
        <Button
          variant='text'
          onClick={() => setToggler(true)}
          color='primary'
        >
          Change Password
        </Button>
      </>
    )
  }
  const renderForm = () => {
    return (
      <Form cancelUpdate={() => setToggler(false)} />
    )
  }

  const renderBody = () => {
    if (user.provider !== 'PASSWORD') {
      return (
        <>
          <Typography>One of SSO options were used to authorize:</Typography>
          <Typography variant='h5'>{user.provider}</Typography>
        </>
      )
    }
    return toggler ? renderForm() : renderPresentation()
  }

  return (
    <div>
      <Typography variant='h4'>Password</Typography>
      {renderBody()}
    </div>
  )
}

export default Password