import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'store/common/user/selectors'
import { userGroupCountSelector, userGroupListSelector } from 'store/common/user-group/selectors'
import { IUserBaseModel } from 'models/common/user/user.interfaces'
import { useNotification } from 'context/NotificationContext'
import { getUserGroupIvitations, getUserList } from 'store/common/user-group/actons'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import Modal from 'components/v3/Modal'
import { UserBaseModel } from 'models/common/user/user.model'
import { pipelineUserAssignment } from 'store/hp/pipeline/rfp/action'

interface AssinedDialogProps {
    open: boolean
    onClose: () => void
    contract: IPiplineTimeline | null
}

const AssignmentDialog = ({open, onClose, contract}: AssinedDialogProps) => {
  const dispatch = useDispatch()

  const storedUser = useSelector(userSelector) as IUserBaseModel
  const userList = useSelector(userGroupListSelector)
  const userCount = useSelector(userGroupCountSelector)
  const notification = useNotification()
  
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  
  const updateCurrentUserList = () => {
    return getUserList(dispatch)(page)
  }
  
  useEffect(() => {
    if(!userList.length) {
      getUserGroupIvitations(dispatch)()
    }
  }, [])
  
  useEffect(() => {
    setLoading(true)
    updateCurrentUserList()
      .finally(() => setLoading(false))
  }, [page])

  const handleAssign = (user: UserBaseModel) => {
    if(contract) {
      pipelineUserAssignment(dispatch)(contract.id, {
        id: user.id!,
        email: user.email,
        role: user.role,
        firstName: user.firstName, 
        lastName: user.lastName,
        status: user.status
      }).then(() => {
        notification.show('user successfully assigned', NotificationTypeList.Success)
        onClose()
      }).catch((e) => notification.show(getErrorMessage(e), NotificationTypeList.Error))
    }
  }

  const roles = ['manager', 'qualifier']

  return (
    <Modal isOpen={open} onClose={onClose} size='lg'>
      <div className={styles['assined-dialog-wrapper']}>
        <Typography variant='h4' gutterBottom>{contract?.title || 'My team members:'}</Typography>
        {
          loading ? (
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    {roles.includes(String(storedUser.userGroup?.role)) && <TableCell align='right'>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.firstName} {user.lastName} {user.email === storedUser.email && '(You)'}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      {roles.includes(String(storedUser.userGroup?.role)) &&
                        <TableCell align='right'>
                          {contract && (
                            <Button disabled={contract.assignedUser.id === user.id} onClick={() => handleAssign(user)}>
                              {contract.assignedUser.id === user.id ? 'Already assigned' : 'Assign'}
                            </Button>
                          )}
                        </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
        <TablePagination
          component='div'
          page={page}
          count={userCount}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          onPageChange={(ev, p) => setPage(p)}
        />
      </div>
    </Modal>
  )
}

export default AssignmentDialog