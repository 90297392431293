import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { CircularProgress, Grid, Typography, Button, useTheme } from '@material-ui/core'
import ScrollManager from 'components/ScrollManager'
import { SimilarCompanyItem } from 'models/hp/market-research/market-research.model'
import { BigHint, SmallHint } from './components/ListingHints'
import Wrapper from 'components/v2/Wrapper'
import { CompanyItem } from './Item'

export interface ISimilarCompany {
  name: string
  duns: string
}

export interface ISimilarCompanyMapper {
  [key: string]: ISimilarCompany | undefined
}

interface Props {
  listingData: SimilarCompanyItem[]
  listingLoading: boolean,
  companies: ISimilarCompanyMapper
  addCompany: (company: ISimilarCompany) => void
  getListing: () => Promise<void>
  moreButtonState: boolean
}

let initLoading = true

const Listing = ({listingData, listingLoading, companies, addCompany, getListing, moreButtonState}: Props) => {
  const theme = useTheme()

  const [hintClosed, setHintClosed] = React.useState(false)
  const [appending, setAppending] = React.useState(false)

  const loadMore = () => {
    setAppending(true)
    getListing()
      .finally(() => setAppending(false))
  }

  const renderBody = () => {
    if (listingLoading) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <CircularProgress color='primary'/>
        </Grid>
      )
    }

    if (!initLoading && listingData.length === 0) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{height: '100%'}}>
          <Typography variant='h4'>Nothing was found, please try another filters</Typography>
        </Grid>
      )
    }

    if (initLoading) {
      if (listingLoading || (listingData && listingData.length > 0)) {
        initLoading = false
      } else {
        if (hintClosed) {
          initLoading = false
          return (
            <Wrapper>
              <SmallHint />
            </Wrapper>
          )
        }
        return (
          <Wrapper>
            <BigHint close={() => setHintClosed(true)} />
          </Wrapper>
        )
      }
    }
    return (
      <ScrollManager scrollKey='market-research-tools-similar-companies'>
        {({ connectScrollTarget }: any) => (
          <div
            ref={(ref) => {
              connectScrollTarget(ref)
            }}
          >
            {listingData.map((item, idx) => {
              return (
                <CompanyItem
                  key={idx}
                  item={item}
                  addCompany={addCompany}
                  companies={companies}
                />
              )
            })}
            {moreButtonState && (
              <Button
                style={{ backgroundColor: theme.palette.primary.contrastText }}
                fullWidth
                color='primary'
                variant='text'
                onClick={loadMore}
                disabled={appending}
              >
                Load more
              </Button>
            )}
          </div>
        )}
      </ScrollManager>
    )
  }

  useEffect(() => {
    return () => {
      initLoading = true
    }
  }, [])

  if (listingData.length > 0 && initLoading) {
    initLoading = false
  }

  return (
    <Grid
      container
      spacing={2}
      className={styles['list']}
      direction='column'
      wrap='nowrap'
      justifyContent='flex-start'
    >
      {renderBody()}
    </Grid>
  )
}

export default Listing