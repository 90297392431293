import { Dispatch } from 'redux'
import { COMPANY_PROFILE_LIST_RESET, setCompanyListingLoadedAction } from '../listing/actions'

export const SET_COMPANY_SEARCH_FILTER_KEYWORD = 'SET_COMPANY_SEARCH_FILTER_KEYWORD'
export const SET_COMPANY_SEARCH_FILTER_VALUE_BOOL = 'SET_COMPANY_SEARCH_FILTER_VALUE_BOOL'
export const SET_COMPANY_SEARCH_FILTER_VALUE = 'SET_COMPANY_SEARCH_VALUE'
export const SET_COMPANY_SEARCH_FILTER_VALUES = 'SET_COMPANY_SEARCH_FILTER_VALUES'
export const REMOVE_COMPANY_SEARCH_FILTER_VALUE = 'REMOVE_COMPANY_SEARCH_FILTER_VALUE'
export const REMOVE_COMPANY_SEARCH_FILTER_VALUES = 'REMOVE_COMPANY_SEARCH_FILTERS_VALUE'
export const RESET_COMPANY_SEARCH_FILTERS = 'RESET_COMPANY_SEARCH_FILTERS'

export const setCompanySearchFilterKeyword = (dispatch: Dispatch) => (value: string) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: SET_COMPANY_SEARCH_FILTER_KEYWORD,
    payload: {value}
  })
}

export const setCompanySearchFilterValueBool = (dispatch: Dispatch) => (key: string, value?: boolean) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: SET_COMPANY_SEARCH_FILTER_VALUE_BOOL,
    payload: {key, value}
  })
}

export const setCompanySearchFilterValue = (dispatch: Dispatch) => (key: string, value: number | string) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: SET_COMPANY_SEARCH_FILTER_VALUE,
    payload: {key, value}
  })
}

export const setCompanySearchFilterValues = (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: SET_COMPANY_SEARCH_FILTER_VALUES,
    payload: {key, value}
  })
}

export const removeCompanySearchFilterValue = (dispatch: Dispatch) => (key: string, value: string | number) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: REMOVE_COMPANY_SEARCH_FILTER_VALUE,
    payload: {key, value}
  })
}

export const removeCompanySearchFilterValues = (dispatch: Dispatch) => (key: string, value: Array<string | number>) => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: REMOVE_COMPANY_SEARCH_FILTER_VALUES,
    payload: {key, value}
  })
}

export const resetCompanySearchFilters = (dispatch: Dispatch) => () => {
  setCompanyListingLoadedAction(dispatch)(false)
  dispatch({
    type: RESET_COMPANY_SEARCH_FILTERS
  })
  dispatch({
    type: COMPANY_PROFILE_LIST_RESET
  })
}
