import React, { useRef } from 'react'
import moment from 'moment'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, useTheme } from '@material-ui/core'
import Modal from 'components/v2/Modal'
import { deleteSubscription, manageSubscription } from 'store/common/owner/actions'
import { UserOwnerModel } from 'models/common/user/user.model'
import { ERROR_MESSAGES } from 'utils/message'
import ButtonWithLoader from 'components/ButtonWithLoader'
import { useNotification } from 'context/NotificationContext'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'

export interface IPermissions {
  plan: string
  endDate: string
  userGroupId: string | null
}

interface Props {
  user: UserOwnerModel
  onClose: () => void
  onManagePermissionsSuccess: () => void
  onDeletePermissionsSuccess: () => void
}

const validationSchema = Yup.object({
  plan: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Field')),
  endDate: Yup.date()
    .required(ERROR_MESSAGES.Field.Required('Field'))
    .min(new Date())
})

const GrantPerissionsModal = ({ user, onClose, onManagePermissionsSuccess, onDeletePermissionsSuccess }: Props) => {
  const notification = useRef(useNotification())
  const theme = useTheme()

  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (values: IPermissions) => {
    setLoading(true)
    try {
      await manageSubscription(values, user.id)
      setLoading(false)
      onManagePermissionsSuccess()
    }
    catch (e) {
      setLoading(false)
      console.log(e)
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteSubscription(user.userGroupId, user.id)
      setLoading(false)
      onDeletePermissionsSuccess()
    }
    catch (e) {
      setLoading(false)
      console.log(e)
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  return (
    <Modal
      title='Grant permissions'
      onClose={onClose}
      size='md'
      open={true}
    >
      <Formik
        initialValues={{
          plan: user.paymentPlan?.plan || 'growth',
          endDate: user.paymentPlan?.endDate
            ? moment(user.paymentPlan?.endDate).format('YYYY-MM-DD')
            : moment().add(1, 'month').format('YYYY-MM-DD'),
          userGroupId: user.userGroupId || null
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, handleSubmit, handleChange, setFieldValue, handleBlur, isValid, touched, errors}: FormikProps<IPermissions>) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Select
                  fullWidth
                  value={values.plan}
                  onChange={handleChange('plan')}
                  onBlur={handleBlur('plan')}
                >
                  <MenuItem value={'standard'}>Standard</MenuItem>
                  <MenuItem value={'growth'}>Growth</MenuItem>
                  <MenuItem value={'enterprise'}>Enterprise</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  label='End date'
                  variant='outlined'
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      min: moment().add(1, 'day').format('YYYY-MM-DD')
                    }
                  }}
                  fullWidth
                  onChange={handleChange('endDate')}
                  onBlur={handleBlur('endDate')}
                  value={values.endDate}
                  error={!!touched.endDate && !!errors.endDate}
                  helperText={errors.endDate}
                />
              </Grid>
              {
                user.userGroupId && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!values.userGroupId}
                          onChange={() => setFieldValue('userGroupId', values.userGroupId ? null : user.userGroupId)}
                          color='primary'
                        />
                      }
                      label='For all teammates in the group'
                    />
                  </Grid>
                )
              }
            </Grid>
            <Grid item>
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <ButtonWithLoader
                    isRequested={loading}
                    type='submit'
                    disabled={!isValid}
                    variant='contained'
                    color='primary'
                  >
                    Manage
                  </ButtonWithLoader>
                </Grid>
                <Grid item>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    onClick={onClose}
                    style={{marginLeft: '4px'}}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <ButtonWithLoader
                    isRequested={loading}
                    type='submit'
                    variant='outlined'
                    color='primary'
                    onClick={handleDelete}
                    style={{
                      marginLeft: '4px',
                      background: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                      border: 0
                    }}
                  >
                    Remove access
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default GrantPerissionsModal