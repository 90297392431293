import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@material-ui/core'
import EducationNavigationButtons from 'modules/Main/Education/components/EducationNavigationButtons'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import { EDUCATION_FUNDING_IFRAME_SOURCE, FundingClassesNavs } from 'utils/constants/education-funding'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import FundingClasses from './phases'

const EducationFunding = () => {
  const history = useHistory()
  const {search} = useLocation()

  const [selectedSubtitle, setSelectedSubtitle] = useState(FundingClassesNavs[0])

  const currentTitleIndex = FundingClassesNavs.findIndex((phase: IEducationPhaseClass) => phase.name === selectedSubtitle.name)
  const lastSubtitle = currentTitleIndex + 1 === FundingClassesNavs.length

  const onSelectSubtitle = (subtitle: IEducationPhaseClass) => {
    setSelectedSubtitle(subtitle)
    if (EDUCATION_FUNDING_IFRAME_SOURCE[subtitle.name]) {
      return
    }
  }

  const onNext = () => {
    if (lastSubtitle) {
      return
    }
    let nextSubtitle = currentTitleIndex < FundingClassesNavs.length ? FundingClassesNavs[currentTitleIndex + 1] : selectedSubtitle
    if (!EDUCATION_FUNDING_IFRAME_SOURCE[nextSubtitle.name]) {
      nextSubtitle = (currentTitleIndex + 1) < FundingClassesNavs.length ? FundingClassesNavs[currentTitleIndex + 2] : selectedSubtitle
    }
    setSelectedSubtitle(nextSubtitle)
  }

  const onPrevious = () => {
    let previousSubtitle = currentTitleIndex ? FundingClassesNavs[currentTitleIndex - 1] : selectedSubtitle
    if (!EDUCATION_FUNDING_IFRAME_SOURCE[previousSubtitle.name]) {
      previousSubtitle = (currentTitleIndex - 2) ? FundingClassesNavs[currentTitleIndex - 2] : selectedSubtitle
    }
    setSelectedSubtitle(previousSubtitle)
  }

  const renderScene = () => {
    if (selectedSubtitle && EDUCATION_FUNDING_IFRAME_SOURCE[selectedSubtitle.name]) {
      const url = EDUCATION_FUNDING_IFRAME_SOURCE[selectedSubtitle.name]
      const title = selectedSubtitle.title

      if (selectedSubtitle.native) {
        return <Redirect to={{pathname: url}}/>
      }
      return <iframe src={url} title={title}/>
    } else {
      return null
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(search)
    const phase = query.get('phase')
    const activeFundingClass = FundingClassesNavs.find(x => x.name === phase)
    if (activeFundingClass) {
      setSelectedSubtitle(activeFundingClass)
    }
  }, [])

  useEffect(() => {
    if (!selectedSubtitle.native) {
      history.push(`/education/funding?phase=${selectedSubtitle.name}`)
    }
  }, [selectedSubtitle])

  return (
    <Grid container className={styles['education']}>
      <Grid item md={3} className={styles['education-left-block']}>
        <FundingClasses
          selectedSubtitle={selectedSubtitle}
          onSelectSubtitle={onSelectSubtitle}
        />
        <EducationNavigationButtons
          onPrevious={onPrevious}
          onNext={onNext}
          hasNext={!lastSubtitle}
          hasPrevious={!!currentTitleIndex}
        />
      </Grid>
      <Grid className={styles['education-content']} item md={9}>
        {renderScene()}
      </Grid>
    </Grid>
  )
}

export default EducationFunding