import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { UserBaseModel } from 'models/common/user/user.model'
import { BasePaginationModel, BaseResponseModel } from 'models/base/base.model'
import { PipelineTaskDto } from 'models/hp/pipeline-task/pipeline-task.interface'
import { PipelineTaskStatuses } from 'utils/const'

@jsonObject
export class TaskItem implements PipelineTaskDto {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  type: PipelineTaskStatuses = '' as PipelineTaskStatuses

  @jsonMember({constructor: String})
  name: string = ''

  @jsonMember({constructor: String})
  role: string | undefined = undefined

  @jsonMember({constructor: Date})
  date: Date | undefined = undefined

  @jsonMember({constructor: String})
  text: string | undefined = undefined

  @jsonMember({constructor: UserBaseModel})
  assignedUser: UserBaseModel | undefined = undefined

  @jsonMember({constructor: Number})
  assignedUserId: number | undefined = undefined

  @jsonMember({constructor: String})
  month: string | undefined = undefined

  @jsonMember({constructor: String, deserializer: (step) => typeof step === 'number' ? step.toString() : step})
  step: string | null = null

  @jsonMember({constructor: Date})
  snooze?: Date = undefined
}

@jsonObject
export class TaskAnswer {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  text: string = ''

  @jsonMember({constructor: Date})
  createdAt: Date | null = null
}

@jsonObject
export class TaskQuestion {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  type: PipelineTaskStatuses | undefined = undefined

  @jsonMember({constructor: String})
  text: string = ''

  @jsonArrayMember(TaskAnswer)
  answer: TaskAnswer[] = []

  @jsonArrayMember(String)
  options: string[] = []
}

@jsonObject
export class TaskComment {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  createdAt: string = ''

  @jsonMember({constructor: String})
  text: string = ''

  @jsonMember(UserBaseModel)
  user: UserBaseModel | null = null
}

@jsonObject
export class PipelineTasksResponseModel extends BasePaginationModel {
  @jsonArrayMember(TaskItem)
  data: TaskItem[] = []
}

@jsonObject
export class PipelineTaskCreateResponse extends BaseResponseModel {
  @jsonMember(TaskItem)
  data: TaskItem = new TaskItem()
}

@jsonObject
export class PipelineTaskQuestionsResponseModel extends BaseResponseModel {
  @jsonArrayMember(TaskQuestion)
  data: TaskQuestion[] = []
}

@jsonObject
export class PipelineCommentsResponse extends BaseResponseModel {
  @jsonArrayMember(TaskQuestion)
  data: TaskComment[] = []
}

@jsonObject
export class PipelineTaskByIdResponse extends BaseResponseModel {
  @jsonMember(TaskItem)
  data: TaskItem = new TaskItem()
}