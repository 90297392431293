import React from 'react'
import { Grid } from '@material-ui/core'
import ButtonWithLoader from 'components/ButtonWithLoader'

interface Props {
  handleContinue?: () => void
  handleStop?: () => void
  handleGoBack?: () => void
  handleFinish?: () => void
  requesting?: boolean
}

const ActionsBar = ({ handleGoBack, handleContinue, handleStop, handleFinish, requesting = false }: Props) => {
  return (
    <Grid container justifyContent='flex-end' alignItems='flex-start'>
      <Grid item>
        {handleGoBack && (
          <ButtonWithLoader
            size='large'
            variant='outlined'
            onClick={handleGoBack}
            isRequested={requesting}
          >
            Back
          </ButtonWithLoader>
        )}
      </Grid>
      <Grid item>
        {
          handleFinish ? (
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Grid item>
                <ButtonWithLoader
                  size='large'
                  isRequested={requesting}
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={handleFinish}
                >
                  Save to profile
                </ButtonWithLoader>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Grid item>
                <ButtonWithLoader
                  size='large'

                  isRequested={requesting}
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={handleContinue}
                >
                  Save & Continue
                </ButtonWithLoader>
              </Grid>
              <Grid item>
                <ButtonWithLoader
                  isRequested={requesting}
                  variant='outlined'
                  onClick={handleStop}
                >
                  Save & Finish later
                </ButtonWithLoader>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  )
}

export default ActionsBar