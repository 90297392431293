import React, { useState } from 'react'
import { FormikProps } from 'formik'
import { IVerifyForm } from './Form'
import { CircularProgress, Grid, Hidden, Theme, Typography, useTheme, Button, Link as MuiLink } from '@material-ui/core'
import TextField from 'components/v2/TextField'
import styles from '../../index.module.scss'
import { userVerifyResendAction } from 'store/common/user/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import LeftBlock from 'components/v2/Auth/LeftBlock'
import { default as LogoWithText } from 'assets/svg-icons/logo-with-text.svg'
import SignInTextLink from 'components/SignInTextLink'

interface IFormRenderedProps extends FormikProps<IVerifyForm> {
  isRequested: boolean
  email: string
}

const FormRenderer = ({ values, handleSubmit, handleChange, handleBlur, touched, errors, isRequested, email }: IFormRenderedProps) => {
  const theme = useTheme<Theme>()
  const { show } = useNotification()
  const [ resendPendingStatus, setResendPendingStatus ] = useState<boolean>(false)

  const handleClickResend = async () => {
    setResendPendingStatus(true)
    try {
      const response = await userVerifyResendAction(email)
      setResendPendingStatus(false)
      response.message && show(response.message, NotificationTypeList.Success)
    } catch (error) {
      setResendPendingStatus(false)
      error.length && error[0].message && show(error[0].message, NotificationTypeList.Success)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={styles['form-block']}>
        <Hidden smDown>
          <Grid item md={6}>
            <LeftBlock />
          </Grid>
        </Hidden>
        <Grid item className={styles['form-right-block']} xs={12} sm={12} md={6}>
          <div className={styles['form-block-right-inner']}>
            <div className={styles['logo']}>
              <img src={LogoWithText} alt='logo'/>
            </div>
            <Grid container justifyContent='center' className={styles['form-subtitle']}>
              <Typography align='center'>Please complete the account verification by entering the verification code sent to your email.</Typography>
            </Grid>
            <TextField
              onBlur={handleBlur('verificationCode')}
              error={!!touched.verificationCode && !!errors.verificationCode}
              helperText={!!touched.verificationCode && !!errors.verificationCode && errors.verificationCode}
              value={values.verificationCode}
              onChange={handleChange('verificationCode')}
              className={styles['field']}
              label='Verification Code'
              variant='filled'
              fullWidth
            />
            <Grid container justifyContent={'center'} alignItems={'center'} className={styles['resend-block']}>
              <Typography>Didn't receive the verification code?</Typography>
              <MuiLink onClick={handleClickResend} style={{ color: theme.palette.info.main, paddingLeft: '4px' }} >
                Resend code
              </MuiLink>
            </Grid>
            <Button
              className={styles['submit']}
              variant='contained'
              color='primary'
              fullWidth
              disabled={isRequested || resendPendingStatus}
              type='submit'
            >
              {isRequested || resendPendingStatus ? (
                <CircularProgress color='primary' size={30} />
              ) : (
                <span>Verify</span>
              )}
            </Button>
            <Typography className={styles['helper-text']} align='center'>
              Please check your spam folder if you do not receive a confirmation email.
            </Typography>
            <SignInTextLink/>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormRenderer