import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { ISendFeedbackDto, ISendFeedbackForm } from 'models/hp/feedback/feedback.interface'
import { FEEDBACK_TYPE } from 'constants/feedback'
import { Pagination } from 'models/base/base.interfaces'
import {
  FeedbackDetailsModel, FeedbackDetailsOwnerResponseModel, FeedbackListOwnerResponseModel
} from 'models/hp/feedback/feedback.model'

export const sendFeedback = async(feedbackDto: ISendFeedbackForm, type: FEEDBACK_TYPE): Promise<any> => {
  const dto = Object.assign({} as ISendFeedbackDto, feedbackDto)
  dto.type = type
  try {
    const response = await request(RequestMethods.Post, Paths.Hp.Feedback.send, {
      body: dto
    })
    return response.data
  } catch (error) {
    throw error.response.data.errors
  }
}

export const getFeedbackList = async(pagination: Pagination): Promise<FeedbackListOwnerResponseModel> => {
  const res = await request<FeedbackListOwnerResponseModel>(RequestMethods.Get, Paths.Owner.Feedback.getList(pagination), {
    schema: FeedbackListOwnerResponseModel
  })

  return res.data
}

export const getFeedbackDetails = async (id: string): Promise<FeedbackDetailsModel> => {
  try {
    const response = await request<FeedbackDetailsOwnerResponseModel>(RequestMethods.Get, Paths.Owner.Feedback.getById(id), {
      schema: FeedbackDetailsOwnerResponseModel
    })
    return response.data.data
  } catch (error) {
    throw error.response.data.errors
  }
}
