import React from 'react'
import styles from './index.module.scss'
import { StepType } from '@reactour/tour'
import TutorialLayout from 'components/v3/Tutorials/TutorialLayout'
import { Typography } from '@material-ui/core'

export const steps: StepType[] = [
  {
    selector: '#due-in',
    content: () => (
      <TutorialLayout type='tutorialOverview'> 
        <div className={styles['internal-info-wrapper']}>
          <Typography className={styles['internal-title']}>How much time do you have and what does the future look like</Typography>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Due In:</Typography>
            <Typography className={styles['internal-info-item-description']}>
              The Due In bar shows how many days till the proposal/response is due
            </Typography>
          </div> 
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Timeline:</Typography>
            <Typography className={styles['internal-info-item-description']}>
              The contracting history and outlook for this contract
            </Typography>
          </div>
        </div>
      </TutorialLayout>
    ),
    position: 'left',
    padding: {
      mask: 0,
      popover: [34, 0], 
      wrapper: 0
    }
  },
  {
    selector: '#the-work',
    content: () => (
      <TutorialLayout type='tutorialOverview'> 
        <div className={styles['internal-info-wrapper']}>
          <Typography className={styles['internal-title']}>What kinds of services is the government looking for</Typography>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-description']}>
              This chart shows the kinds of work/the kinds of people you will need to do the work in this solicitation
              <br />
              The size of the bubbles reflects the quantity of each kind of work in this solicitation
            </Typography>
          </div>
        </div>
      </TutorialLayout>
    ),
    position: 'right',
    padding: {
      mask: 0,
      popover: [0, 34], 
      wrapper: 0
    }
  },
  {
    selector: '#chances-of-winning',
    content: () => (
      <TutorialLayout type='tutorialOverview'> 
        <div className={styles['internal-info-wrapper']}>
          <Typography className={styles['internal-title']}>What are your chances of winning</Typography>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Chances the Gov. Completes This Purchase:</Typography>
            <Typography className={styles['internal-info-item-description']}>
            Sometimes RFIs and Sources Sought do not become full RFPs. Or RFPs do not become awards. This bar chart shows our analysis of the chances that this solicitation will become a completed award
            </Typography>
          </div>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Chances this Op will be Won By:</Typography>
            <Typography className={styles['internal-info-item-description']}>
            Our analysis of the chances that this contract will be won by different groups.
            </Typography>
          </div>
        </div>
      </TutorialLayout>
    ),
    position: 'left',
    padding: {
      mask: 0,
      popover: [0, 34], 
      wrapper: 0
    }
  },
  {
    selector: '#effort',
    content: () => (
      <TutorialLayout type='tutorialOverview'> 
        <div className={styles['internal-info-wrapper']}>
          <Typography className={styles['internal-title']}>How many hours will you need to invest to have a chance of winning</Typography>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Low effort:</Typography>
            <Typography className={styles['internal-info-item-description']}>
            What is the least amount of time that you could put into this opportunity to have a chance of winning
            </Typography>
          </div>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>High effort:</Typography>
            <Typography className={styles['internal-info-item-description']}>
            If you “pulled out all the stops” to give yourself the best chances of winning how many hours would you have to invest
            </Typography>
          </div>
          <div className={styles['internal-info-item']}>
            <Typography className={styles['internal-info-item-title']}>Remaining B&P:</Typography>
            <Typography className={styles['internal-info-item-description']}>
            Most small businesses allocate about 1,300 hr/year to pursuing work, this gives you a sense for what percent of that this pursuit will consume
            </Typography>
          </div>
        </div>
      </TutorialLayout>
    ),
    position: 'left',
    padding: {
      mask: 0,
      popover: [0, 34],  
      wrapper: 0
    }
  },
]