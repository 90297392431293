export const URL = process.env.REACT_APP_REMOTE_URL || 'https://api-dev.fedscout.com'

export enum VideoSourceType {
  MARKET_RESEARCH_INTRO = 'MARKET_RESEARCH_INTRO',
  MARKET_RESEARCH_NAME = 'MARKET_RESEARCH_NAME',
  MARKET_RESEARCH_CHOOSING_KEYWORDS = 'MARKET_RESEARCH_CHOOSING_KEYWORDS',
  MARKET_RESEARCH_VALIDATE_KEYWORDS = 'MARKET_RESEARCH_VALIDATE_KEYWORDS',
  MARKET_RESEARCH_SELECT_KEYWORDS = 'MARKET_RESEARCH_SELECT_KEYWORDS',
  MARKET_RESEARCH_CODES = 'MARKET_RESEARCH_CODES',
  MARKET_RESEARCH_LOCATION = 'MARKET_RESEARCH_LOCATION',
  MARKET_RESEARCH_CUSTOMER = 'MARKET_RESEARCH_CUSTOMER',
  MARKET_RESEARCH_YOUR_MARKET = 'MARKET_RESEARCH_YOUR_MARKET',
  FEDSCOUT_TUTORIAL_DEVELOP_NEW_PRODUCT = 'FEDSCOUT_TUTORIAL_DEVELOP_NEW_PRODUCT',
  FEDSCOUT_TUTORIAL_EXPAND_COMPANY = 'FEDSCOUT_TUTORIAL_EXPAND_COMPANY',
  FEDSCOUT_TUTORIAL_FIND_CONTRACTS = 'FEDSCOUT_TUTORIAL_FIND_CONTRACTS',
  FEDSCOUT_TUTORIAL_SUBCONTRACT = 'FEDSCOUT_TUTORIAL_SUBCONTRACT',
  FEDSCOUT_TUTORIAL_SLACK = 'FEDSCOUT_TUTORIAL_SLACK',
  FEDSCOUT_TUTORIAL_BUSINESS_PLANNING = 'FEDSCOUT_TUTORIAL_BUSINESS_PLANNING',
  FEDSCOUT_TUTORIAL_GOV_CON_COACHING = 'FEDSCOUT_TUTORIAL_GOV_CON_COACHING',
  FEDSCOUT_TUTORIAL_QUESTIONS = 'FEDSCOUT_TUTORIAL_QUESTIONS'
}

export const YOUTUBE = {
  CONTRACT_SEARCH_HELPER: (query?: string) => `https://www.youtube.com/embed/vgIBQgxUxw4${query}`,
  [VideoSourceType.MARKET_RESEARCH_INTRO]: 'https://www.youtube.com/embed/UjnwUDsYiI4',
  [VideoSourceType.MARKET_RESEARCH_NAME]: 'https://www.youtube.com/embed/naa6eVgI2gQ',
  [VideoSourceType.MARKET_RESEARCH_CHOOSING_KEYWORDS]: 'https://www.youtube.com/embed/5fJh4ZLrHqM',
  [VideoSourceType.MARKET_RESEARCH_VALIDATE_KEYWORDS]: 'https://www.youtube.com/embed/bHHQT1pkEK0',
  [VideoSourceType.MARKET_RESEARCH_SELECT_KEYWORDS]: 'https://www.youtube.com/embed/e1ur_25oqQg',
  [VideoSourceType.MARKET_RESEARCH_CODES]: 'https://www.youtube.com/embed/MLiFRfFPLa4',
  [VideoSourceType.MARKET_RESEARCH_LOCATION]: 'https://www.youtube.com/embed/PQxJpRFELz4',
  [VideoSourceType.MARKET_RESEARCH_CUSTOMER]: 'https://www.youtube.com/embed/yaHD0zI7zVI',
  [VideoSourceType.MARKET_RESEARCH_YOUR_MARKET]: 'https://www.youtube.com/embed/Cgtu9b6cTtc',
  [VideoSourceType.FEDSCOUT_TUTORIAL_DEVELOP_NEW_PRODUCT]: 'https://www.youtube.com/embed/-OJLo8OtG68?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_EXPAND_COMPANY]: 'https://www.youtube.com/embed/Bb7hyEY_U84?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_FIND_CONTRACTS]: 'https://www.youtube.com/embed/gnt-dimwi-U?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_SUBCONTRACT]: 'https://www.youtube.com/embed/TMowHvZNATo?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_SLACK]: 'https://www.youtube.com/embed/xTVYdyoUziE?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_BUSINESS_PLANNING]: 'https://www.youtube.com/embed/r7R1QlbsDZUhttps://www.yout?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_GOV_CON_COACHING]: 'https://www.youtube.com/embed/6gC5a2tuZVU?autoplay=1',
  [VideoSourceType.FEDSCOUT_TUTORIAL_QUESTIONS]: 'https://www.youtube.com/embed/gay91rMa5l8?autoplay=1'
}

export const PAYMENT = {
  CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID || 'AdrSSmuoB4uLcN5Bo7weVz4rQ84f7ZMnwnbgn_k7FUMOBe8fd3JcILxnj2pJk_wvRX4dZCzGWxpwA8e8',
  PLAN_STANDARD: process.env.REACT_APP_PAYPAL_STANDART_PLAN_ID || 'P-8CA66531JL5122126L7QZKKA',
  PLAN_GROWTH: process.env.REACT_APP_PAYPAL_GROWTH_PLAN_ID || 'P-62821690VS1639818L7QZKXI',
  PLAN_ENTERPRISE: process.env.REACT_APP_PAYPAL_ENTERPRISE_PLAN_ID || 'P-0VE96398RS632233UL7QZLDY'
}

export enum RequestMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

export const MIN_PASSWORD_LEN = 8

export const SEO_DATA = {
  title: 'FedScout',
  description: 'FedScout helps you search for government contracts, estimates your chances of winning, the potential value, and much more. Start growing with FedScout!'
}

export enum PipelinePageType {
  open = 'open',
  distant = 'distant',
  upcoming = 'upcoming',
  award = 'award'
}

export const ableForEvaluationPages = [PipelinePageType.distant, PipelinePageType.open, PipelinePageType.upcoming]

export enum PipelineTaskPageType {
  government = 'government',
  marketing = 'marketing',
  partner = 'partner',
  business = 'business',
  hire = 'hire'
}

export enum PipelineTaskStatuses {
  marketing__1 = 'marketing__1',
  marketing__2 = 'marketing__2',
  marketing__3 = 'marketing__3',
  marketing__4 = 'marketing__4',

  government__1 = 'government__1',
  government__2 = 'government__2',
  government__3 = 'government__3',
  government__4 = 'government__4',

  partner__1 = 'partner__1',
  partner__2 = 'partner__2',
  partner__3 = 'partner__3',
  partner__4 = 'partner__4',

  business__1 = 'business__1',
  business__2 = 'business__2',
  business__3 = 'business__3',
  business__4 = 'business__4',

  hire__1 = 'hire__1',
  hire__2 = 'hire__2',
  hire__3 = 'hire__3',
  hire__4 = 'hire__4',
}

export const PIPELINE_BOARD_TITLES: { [key in PipelinePageType]: string } = {
  [PipelinePageType.distant]: 'Forecast & Recompetes',
  [PipelinePageType.upcoming]: 'RFIs & Sources Sought',
  [PipelinePageType.open]: 'RFPs',
  [PipelinePageType.award]: 'Pending Awards'
}

export const PIPELINE_BOARD_TITLES_SHORT: { [key in PipelinePageType]: string } = {
  [PipelinePageType.distant]: 'Forecast',
  [PipelinePageType.upcoming]: 'RFIs',
  [PipelinePageType.open]: 'RFPs',
  [PipelinePageType.award]: 'Pending'
}

export const PIPELINE_TASK_BOARD_TITLES: { [key in PipelineTaskPageType]: string } = {
  [PipelineTaskPageType.marketing]: 'Marketing',
  [PipelineTaskPageType.government]: 'Gov. relationships',
  [PipelineTaskPageType.partner]: 'Partner relationships',
  [PipelineTaskPageType.business]: 'Business Infrastructure',
  [PipelineTaskPageType.hire]: 'Prospective Hires & OEMs'
}
export const PIPELINE_TASK_BOARD_TITLES_SHORT: { [key in PipelineTaskPageType]: string } = {
  [PipelineTaskPageType.marketing]: 'Mrktng.',
  [PipelineTaskPageType.government]: 'Gov. Rel.',
  [PipelineTaskPageType.partner]: 'Partner Rel.',
  [PipelineTaskPageType.business]: 'Business',
  [PipelineTaskPageType.hire]: 'Hires'
}

export const PIPELINE_COLUMNS = {
  'pipeline__1': {
    [PipelinePageType.open]: 'Identified & Qualifying',
    [PipelinePageType.distant]: 'Identified & Qualifying',
    [PipelinePageType.upcoming]: 'Identified & Qualifying',
    [PipelinePageType.award]: '',
  },
  'pipeline__2': {
    [PipelinePageType.open]: 'Proposal Planning',
    [PipelinePageType.distant]: 'Capture Planning',
    [PipelinePageType.upcoming]: 'Response Planning',
    [PipelinePageType.award]: '',
  },
  'pipeline__3': {
    [PipelinePageType.open]: 'Proposal Writing',
    [PipelinePageType.distant]: 'Capture Execution',
    [PipelinePageType.upcoming]: 'Response Writing',
    [PipelinePageType.award]: '',
  },
  'pipeline__4': {
    [PipelinePageType.upcoming]: 'Prepare for RFP',
    [PipelinePageType.award]: '',
  }
}

export const PIPELINE_TASK_COLUMNS: {[key: string]: string} = {
  [PipelineTaskStatuses.marketing__1]: 'Ideating and Planning',
  [PipelineTaskStatuses.marketing__2]: 'Creating',
  [PipelineTaskStatuses.marketing__3]: 'Publishing',
  [PipelineTaskStatuses.marketing__4]: 'Tracking',

  [PipelineTaskStatuses.government__1]: 'They know you',
  [PipelineTaskStatuses.government__2]: 'They like you',
  [PipelineTaskStatuses.government__3]: 'They trust you',
  [PipelineTaskStatuses.government__4]: '',

  [PipelineTaskStatuses.partner__1]: 'They know you',
  [PipelineTaskStatuses.partner__2]: 'They like you',
  [PipelineTaskStatuses.partner__3]: 'They trust you',
  [PipelineTaskStatuses.partner__4]: '',

  [PipelineTaskStatuses.business__1]: 'To do',
  [PipelineTaskStatuses.business__2]: 'In flight',
  [PipelineTaskStatuses.business__3]: 'Completed',
  [PipelineTaskStatuses.business__4]: '',

  [PipelineTaskStatuses.hire__1]: 'They know you',
  [PipelineTaskStatuses.hire__2]: 'They like you',
  [PipelineTaskStatuses.hire__3]: 'Contingent Hire/ Re-seller',
  [PipelineTaskStatuses.hire__4]: '',
}

export const taskTypesAccordingPage = {
  [PipelineTaskPageType.marketing]: [
    PipelineTaskStatuses.marketing__1,
    PipelineTaskStatuses.marketing__2,
    PipelineTaskStatuses.marketing__3,
    PipelineTaskStatuses.marketing__4
  ],
  [PipelineTaskPageType.government]: [
    PipelineTaskStatuses.government__1,
    PipelineTaskStatuses.government__2,
    PipelineTaskStatuses.government__3,
  ],
  [PipelineTaskPageType.partner]: [
    PipelineTaskStatuses.partner__1,
    PipelineTaskStatuses.partner__2,
    PipelineTaskStatuses.partner__3,
  ],
  [PipelineTaskPageType.business]: [
    PipelineTaskStatuses.business__1,
    PipelineTaskStatuses.business__2,
    PipelineTaskStatuses.business__3,
  ],
  [PipelineTaskPageType.hire]: [
    PipelineTaskStatuses.hire__1,
    PipelineTaskStatuses.hire__2,
    PipelineTaskStatuses.hire__3,
  ]

}

export const getTaskNextType = (currentPage: PipelineTaskPageType, currentType: PipelineTaskStatuses) => {
  const typesForPage = taskTypesAccordingPage[currentPage]
  const currentTypeIndex = typesForPage.findIndex(type => type === currentType)
  return currentTypeIndex < typesForPage.length - 1 ? typesForPage[currentTypeIndex + 1] : undefined
}


export const dashboardBarColors = ['#c55a11', '#f4b283', '#f8cbad', '#ffd966', '#ffe699', ' #c5e0b5', '#aad18e', '#aad18e']