import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  selectedAgenciesOptions as selectedAgenciesOptionsSelector,
} from 'store/hp/search/selectors'
import { agenciesSelector, agencyCountSelector } from 'store/hp/agency/selectors'
import {
  setSearchFilterValue,
  removeSearchFilterValue,
  removeSearchFilterValues,
  setSearchFilterValues,
} from 'store/hp/search/actions'
import { AgencyModel } from 'models/hp/agencies/agencies.model'
import styles from './index.module.scss'
import { getChildrenIds } from 'helpers/filter-option'
import { searchAgenciesAction } from 'store/hp/agency/actions'
import { FilterOption } from 'models/hp/search/search.model'
import { addFiltersToUrl } from 'helpers/search'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import { IFilterOption } from 'models/hp/search/search.interface'

let searchDebounceTimer: number | null = null

const DepartmentFilter = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [options, setOptions] = React.useState<FilterOption[]>([])

  const agenciesOptions: FilterOption[] = useSelector(agenciesSelector) as FilterOption[]
  const selectedAgenciesOptions: number[] = useSelector(selectedAgenciesOptionsSelector) as number[]
  const agencyCount: number = useSelector(agencyCountSelector)!

  const handleChange = (id: number | string, state: boolean) => {
    if (state) {
      setSearchFilterValue(dispatch)('customerIds', +id)
    } else {
      removeSearchFilterValue(dispatch)('customerIds', +id)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setSearchFilterValues(dispatch)('customerIds', values.map(i => +i))
    } else {
      removeSearchFilterValues(dispatch)('customerIds', values.map(i => +i))
    }
  }

  const loadOptions = async (value: string) => {
    const response = await searchAgenciesAction(value)
    setOptions(response)
  }

  const onSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    searchDebounceTimer && clearTimeout(searchDebounceTimer)
    searchDebounceTimer = window.setTimeout(async () => {
      await loadOptions(value)
    }, 1000)
  }

  const onSelect = (state: boolean, value: AgencyModel | IFilterOption | null) => {
    if (value?.id) {
      handleChange(value.id, state)
    } else if (value && value.sub && value.sub.length > 0) {
      const childIds: number[] = []
      getChildrenIds(value.sub, (id: number) => {
        childIds.push(id)
      })
      handlePartialChange(childIds, state)
    }
  }

  useEffect(() => {
    addFiltersToUrl(history, 'customerIds', selectedAgenciesOptions)
  }, [selectedAgenciesOptions])

  return (
    <div className={styles['paper']}>
      <SearchFilter
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        searchLabel='Customer search'
        allCount={agencyCount}
        iconType='department'
        filterTitle='Customers'
        filterOptions={agenciesOptions}
        selectedFilterOptions={selectedAgenciesOptions}
        handleChange={handleChange}
        handlePartialChange={handlePartialChange}
      />
    </div>
  )
}

export default DepartmentFilter