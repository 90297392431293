import { PipelineDashboard } from 'models/hp/pipeline-dashboard/index.model'
import { Record } from 'immutable'
import { IAction } from 'types/common'
import {
  PIPELINE_DASHBOARD_COUNTERS_SET
} from 'store/hp/pipeline/dashboard/actions'
import { HP_RFP_CATEGORIZED_ITEM_UNSET, HP_RFP_ITEM_CATEGORIZED } from 'store/hp/rfp/action'
import { PipelinePageType } from 'utils/const'
import { pipelineTypes } from 'helpers/rfp'
import { TCategorization } from 'models/hp/rfp/rfp.interface'

export interface IPipelineDashboardCounters {
  [PipelinePageType.distant]: number
  [PipelinePageType.upcoming]: number
  [PipelinePageType.open]: number
}

export interface IPipelineDashboardRecord {
  dashboard: {
    loaded: boolean
    loading: boolean
    data: PipelineDashboard,
    counters: IPipelineDashboardCounters
    countersLoaded?: boolean
  }
}

const PipelineDashboardRecord = Record<IPipelineDashboardRecord>({
  dashboard: {
    loaded: false,
    loading: false,
    data: new PipelineDashboard(),
    counters: {
      distant: 0,
      open: 0,
      upcoming: 0
    },
    countersLoaded: false
  }
})

export const pipelineDashboardReducer = (state: Record<IPipelineDashboardRecord> = new PipelineDashboardRecord(), action: IAction) => {
  switch (action.type) {

    case PIPELINE_DASHBOARD_COUNTERS_SET:
      return state
        .setIn(['dashboard', 'counters'], action.payload)
        .setIn(['dashboard', 'countersLoaded'], true)

    case HP_RFP_ITEM_CATEGORIZED: { // update counter when contract was liked
      const type = Object.entries(pipelineTypes).find(([_, contractTypes]) => contractTypes.includes(action.payload.rfp.type))
      const categorizationType: TCategorization = action.payload.type

      if (type && (!categorizationType || categorizationType !== 'archive')) {
        const pipelineType = type[0]
        return state
          .updateIn(['dashboard', 'counters'], (counters: any) => ({
            ...counters,
            [pipelineType]: counters[pipelineType] + 1
          }))
      }

      return state
    }

    case HP_RFP_CATEGORIZED_ITEM_UNSET: { // update counter when contract was removed from pipeline
      const type = Object.entries(pipelineTypes).find(([_, contractTypes]) => contractTypes.includes(action.payload.rfp.type))

      if (type) {
        const pipelineType = type[0]
        return state
          .updateIn(['dashboard', 'counters'], (counters: any) => ({
            ...counters,
            [pipelineType]: counters[pipelineType] - 1
          }))
      }

      return state
    }

    default:
      return state
  }
}