import React from 'react'
import styles from './index.module.scss'
import { Paper, Button, CircularProgress, Grid, Typography, TextField } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { filterOptionsLoadingSelector } from 'store/hp/search/selectors'
import { convertStringToKeywords } from 'helpers/search'
import SearchKeywords from 'modules/Main/User/MarketResearchTools/Keywords/components/SearchKeywords'

export interface ICodesFilters {
  keywords: string
}

export interface ICodesFiltersConverted {
  keywords: string[]
}

export interface Props {
  getListing: (filters: ICodesFiltersConverted) => void
  saveCodes: () => void
}

const Filters = ({ getListing, saveCodes }: Props) => {
  const filterOptionsLoading = useSelector(filterOptionsLoadingSelector)

  const [filters, setFilters] = React.useState<ICodesFilters>({
    keywords: ''
  })

  const handleKeywordsChange = (key: keyof ICodesFilters) => (keywords: string) => {
    setFilters({
      ...filters,
      [key]: keywords
    })
  }

  const convertFilters = (): ICodesFiltersConverted => {
    return {
      ...filters,
      keywords: convertStringToKeywords(filters.keywords) || []
    }
  }

  if (filterOptionsLoading) {
    return (
      <Paper className={styles['paper']}>
        <Grid container justifyContent='center'>
          <CircularProgress color='primary' />
        </Grid>
      </Paper>
    )
  }

  return (
    <Paper className={styles['paper']}>
      <Typography variant='h4' gutterBottom>
        PSC & NAICS Code Finder
      </Typography>
      <SearchKeywords
        handleSelectKeywords={keywords => handleKeywordsChange('keywords')(keywords.join(' '))}
      />
      <Typography variant='h4' gutterBottom>
        Enter keywords
      </Typography>
      <TextField
        variant='outlined'
        color='primary'
        className={styles['keyword-input']}
        fullWidth
        placeholder='Keyword'
        value={filters['keywords']}
        onChange={e => handleKeywordsChange('keywords')(e.target.value)}
      />
      <div style={{height: '16px'}} />
      <Button
        color='primary'
        variant='contained'
        fullWidth
        onClick={() => getListing(convertFilters())}
      >
        Find codes
      </Button>
      <div style={{height: '16px'}} />
      <Button
        color='primary'
        variant='outlined'
        fullWidth
        onClick={() => saveCodes()}
      >
        Save selected Codes
      </Button>
    </Paper>
  )
}

export default Filters