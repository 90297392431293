import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from '../../base/base.model'

@jsonObject
export class MarketSimilarCompany {
  @jsonMember({ constructor: String })
  id: string =  ''

  @jsonMember({ constructor: String})
  companyName: string =  ''

  @jsonMember({ constructor: String})
  duns: string =  ''
}

@jsonObject
export class MarketSimilarCompanyGroup {
  @jsonMember({ constructor: String })
  id: string =  ''

  @jsonMember({ constructor: String})
  groupName: string =  ''

  @jsonArrayMember(MarketSimilarCompany)
  companies: MarketSimilarCompany[] = []
}

@jsonObject
export class MarketSimilarCompaniesResponseModel extends BaseResponseModel {
  @jsonArrayMember(MarketSimilarCompanyGroup)
  data: MarketSimilarCompanyGroup[] = []
}

@jsonObject
export class CompanyWithGroup {
  @jsonMember({ constructor: String})
  groupName: string =  ''

  @jsonMember({ constructor: String})
  companyName: string =  ''

  @jsonMember({ constructor: String})
  companyDuns: string =  ''

  @jsonMember({ constructor: String})
  groupId: string =  ''
}
