import { request } from 'utils/http'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import { Dispatch } from 'redux'
import {
  ISearchDTO, ISearchFilterOption, IFilterOption, ISearchSettingsDTO, INormalizedFilterOptions, INormalizedFilterOption, IDueDateRange
} from 'models/hp/search/search.interface'
import {
  SearchResponseModel,
  SearchOptionsResponseModel,
  SearchModel,
  ManageSearchResponseModel,
  ManageSearchPayload,
  SearchListResponse,
  SearchSettingsResponseModel,
  SearchSetting,
  SearchesResponseModel,
  FilterOption,
  SearchOptionsPayload
} from 'models/hp/search/search.model'
import { TSearchOpenedModals } from './reducers'
import { setListingLoadedFalse } from '../rfp/action'
import { convertSearchDto, convertStringToKeywords } from 'helpers/search'
import { BaseResponseModel } from 'models/base/base.model'
import { userActivity } from 'store/common/owner/actions'
import { IAutoFiltersRfp } from 'models/hp/rfp/rfp.interface'

// SEARCH
export const GET_SEARCHES = 'GET_SEARCHES'
export const GET_SEARCHES_SUCCESS = 'GET_SEARCHES_SUCCESS'
export const GET_SEARCHES_ERROR = 'GET_SEARCHES_ERROR'
export const CREATE_SEARCH = 'CREATE_SEARCH'
export const UPDATE_SEARCH = 'UPDATE_SEARCH'
export const DELETE_SEARCH = 'DELETE_SEARCH'

export const GET_SEARCH_DETAILS = 'GET_SEARCH_DETAILS'
export const GET_SEARCH_DETAILS_SUCCESS = 'GET_SEARCH_DETAILS_SUCCESS'
export const GET_SEARCH_DETAILS_ERROR = 'GET_SEARCH_DETAILS_ERROR'
export const REMOVE_SEARCH_DETAILS = 'REMOVE_SEARCH_DETAILS'

// FILTER OPTIONS
export const GET_FILTER_OPTIONS = 'GET_FILTER_OPTIONS'
export const GET_FILTER_OPTIONS_SUCCESS = 'GET_FILTER_OPTIONS_SUCCESS'
export const GET_FILTER_OPTIONS_ERROR = 'GET_FILTER_OPTIONS_ERROR'
export const SET_NORMALIZED_OPTIONS = 'SET_NORMALIZED_OPTIONS'
export const UPDATE_NORMALIZED_OPTIONS = 'UPDATE_NORMALIZED_OPTIONS'

// SEARCH FILTER OPTIONS
export const SET_SEARCH_FILTER_VALUE_BOOL = 'SET_SEARCH_FILTER_VALUE_BOOL'
export const SET_SEARCH_FILTER_VALUE = 'SET_SEARCH_FILTER_VALUE'
export const SET_SEARCH_FILTER_VALUES = 'SET_SEARCH_FILTER_VALUES'
export const UPDATE_SEARCH_FILTER_VALUES = 'UPDATE_SEARCH_FILTER_VALUES'
export const REMOVE_SEARCH_FILTER_VALUE = 'REMOVE_SEARCH_FILTER_VALUE'
export const REMOVE_SEARCH_FILTER_VALUES = 'REMOVE_SEARCH_FILTER_VALUES'
export const SET_SEARCH_FILTER_DATA = 'SET_SEARCH_FILTER_DATA'
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS'

// OPENED MODALS STATE
export const SET_MODAL_OPENED = 'SET_MODAL_OPENED'

export const getSavedSearches = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: GET_SEARCHES
  })
  try {
    const searches = await getAllFulfilledSearches()
    dispatch({
      type: GET_SEARCHES_SUCCESS,
      payload: searches
    })
  } catch (error) {
    dispatch({
      type: GET_SEARCHES_ERROR
    })
  }
}

export const getAndSetSearchDetails = (dispatch: Dispatch) => async (searchId: number, searchData?: SearchModel): Promise<SearchModel> => {
  dispatch({
    type: GET_SEARCH_DETAILS
  })
  try {
    if (!searchData) {
      const response = await request<SearchResponseModel>(RequestMethods.Get, Paths.Hp.Search.getById(searchId), {
        schema: SearchResponseModel
      })
      dispatch({
        type: GET_SEARCH_DETAILS_SUCCESS,
        payload: response.data.data
      })
      return response.data.data
    } else {
      dispatch({
        type: GET_SEARCH_DETAILS_SUCCESS,
        payload: searchData
      })
      return searchData
    }
  } catch (error) {
    dispatch({
      type: GET_SEARCH_DETAILS_ERROR
    })
    throw error
  }
}

export const getSearchOptions = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: GET_FILTER_OPTIONS
  })
  try {
    const response = await request<SearchOptionsResponseModel>(RequestMethods.Get, Paths.Hp.Search.getOptions, {
      schema: SearchOptionsResponseModel
    })
    dispatch({
      type: GET_FILTER_OPTIONS_SUCCESS,
      payload: response.data.data
    })

    const normalizedFilterOptions: INormalizedFilterOptions = Object.keys(response.data.data).reduce((acc, item) => {
      return {
        ...acc,
        [item]: response.data.data[item as keyof SearchOptionsPayload].reduce((acc: INormalizedFilterOption, el: FilterOption) => (
          {...acc, [el.id]: {
            ...el,
            text: el.title || el.name || el.subtierHP || el.subtierSAM || ''
          }}
        ), {})
      }
    }, {})

    dispatch({
      type: SET_NORMALIZED_OPTIONS,
      payload: normalizedFilterOptions
    })
  }
  catch (e) {
    dispatch({
      type: GET_FILTER_OPTIONS_ERROR
    })
    console.error(e)
  }
}

export const setSearchFilterValueBool = (dispatch: Dispatch) => (key: string, value: any) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: SET_SEARCH_FILTER_VALUE_BOOL,
    payload: {
      key: key,
      value: value
    }
  })
}

export const setValueAnalytics = (key: string, value: number | number[]) => {
  const mapper: {[key: string]: string} = {
    'contractTypeIds': userActivity.activities.enterMaturityFilter,
    'customerIds': userActivity.activities.enterCustomerFilter,
    'dueDateIds': userActivity.activities.enterDueInFilter,
    'groupedCodeIds': userActivity.activities.enterNaicsFilter,
    'locationIds': userActivity.activities.enterLocationFilter,
    'pscCodeIds': userActivity.activities.enterPscFilter,
    'setAsideIds': userActivity.activities.enterSetAsideFilter,
    'projectTypeIds': userActivity.activities.enterMaturityFilter,
  }
  if (mapper[key]) {
    userActivity.event(mapper[key], Array.isArray(value) ? value.join(',') : String(value))
  }
}

export const setSearchFilterValue = (dispatch: Dispatch) => (key: string, value: number) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: SET_SEARCH_FILTER_VALUE,
    payload: {
      key: key,
      value: value
    }
  })
  setValueAnalytics(key, value)
}

export const setSearchFilterValues = (dispatch: Dispatch) => (key: string, value: number[]) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: SET_SEARCH_FILTER_VALUES,
    payload: {
      key: key,
      value: value
    }
  })
  setValueAnalytics(key, value)
}

export const updateSearchFilterValues = (dispatch: Dispatch) => (key: string, value: IDueDateRange | number[]) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: UPDATE_SEARCH_FILTER_VALUES,
    payload: {
      key: key,
      value: value
    }
  })
}

export const removeSearchFilterValue = (dispatch: Dispatch) => (key: string, value: number) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: REMOVE_SEARCH_FILTER_VALUE,
    payload: {
      key: key,
      value: value
    }
  })
}

export const removeSearchFilterValues = (dispatch: Dispatch) => (key: string, value: number[]) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: REMOVE_SEARCH_FILTER_VALUES,
    payload: {
      key: key,
      value: value
    }
  })
}

export const setSearchFilterData = (dispatch: Dispatch) => (search: ISearchDTO) => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: SET_SEARCH_FILTER_DATA,
    payload: search
  })
}

export const resetSearchFilters = (dispatch: Dispatch) => () => {
  setListingLoadedFalse(dispatch)
  dispatch({
    type: RESET_SEARCH_FILTERS
  })
  dispatch({
    type: REMOVE_SEARCH_DETAILS
  })
}

export const setFilterOptions = (dispatch: Dispatch) => (
  searchFilter: ISearchFilterOption,
  projectTypeOptions: IFilterOption[],
  pscCodesOptions: IFilterOption[] = []) => {

  if (searchFilter.projectTypes) {
    const projectTypeIds = projectTypeOptions.filter(option => searchFilter.projectTypes!.includes(option.name!)).map(option => option.id)
    setSearchFilterValues(dispatch)('projectTypeIds', projectTypeIds as number[])
    setSearchModalOpened(dispatch)('maturityFilter', true)
  }
  if (searchFilter.pscCodes) {
    const pscCodeIds = pscCodesOptions.filter(option => searchFilter.pscCodes!.includes(option.name!)).map(option => option.id)
    setSearchFilterValues(dispatch)('pscCodeIds', pscCodeIds as number [])
    setSearchModalOpened(dispatch)('pscCodeFilter', true)
  }
}

export const setSearchModalOpened = (dispatch: Dispatch) => (key: TSearchOpenedModals, state: boolean) => {
  userActivity.event(userActivity.activities.openSavedSearches)
  dispatch({
    type: SET_MODAL_OPENED,
    payload: { key, state }
  })
}

export const createFulfilledSearch = (dispatch?: Dispatch) => async (dto: ISearchDTO, name: string, lazyUser: boolean = false, setActive = true): Promise<ManageSearchPayload> => {
  const res = await request<ManageSearchResponseModel>(RequestMethods.Post, Paths.Hp.Search.createFulfilled, {
    schema: ManageSearchResponseModel,
    body: convertSearchDto(dto, name),
    lazyUser
  })
  if (!dispatch) {
    return {
      id: res.data.data.id,
      name: res.data.data.name
    }
  }
  dispatch && dispatch({
    type: CREATE_SEARCH,
    payload: {
      id: res.data.data.id,
      name: res.data.data.name,
      done: true
    }
  })
  // set just created search as selected
  dispatch && setActive && getAndSetSearchDetails(dispatch)(res.data.data.id, {
    ...dto,
    id: res.data.data.id,
    name: res.data.data.name,
  } as SearchModel)

  userActivity.event(userActivity.activities.createSearch, name)

  return res.data.data
}

export const autoSearchCreator = (dispatch?: Dispatch) => async (dto: ISearchDTO): Promise<ManageSearchPayload> => {
  const name = dto.keywords.join(', ')
  const res = await request<ManageSearchResponseModel>(RequestMethods.Post, Paths.Hp.Search.createFulfilled, {
    schema: ManageSearchResponseModel,
    body: convertSearchDto(dto, name)
  })

  dispatch && dispatch({
    type: CREATE_SEARCH,
    payload: {
      id: res.data.data.id,
      name: res.data.data.name,
      done: true
    }
  })

  return res.data.data
}

export const updateFulfilledSearch = (dispatch?: Dispatch) => async (dto: ISearchDTO, name: string, searchId: number) => {
  const res = await request<ManageSearchResponseModel>(RequestMethods.Put, Paths.Hp.Search.updateFulfilled(searchId), {
    schema: ManageSearchResponseModel,
    body: convertSearchDto(dto, name)
  })
  dispatch && dispatch({
    type: UPDATE_SEARCH,
    payload: {
      id: res.data.data.id,
      name: res.data.data.name // name should be updated
    }
  })
}

export const deleteSearch = (dispatch?: Dispatch) => async (searchId: number) => {
  await request<BaseResponseModel>(RequestMethods.Delete, Paths.Hp.Search.delete(searchId), {
    schema: BaseResponseModel
  })
  dispatch && dispatch({
    type: DELETE_SEARCH,
    payload: {
      id: searchId
    }
  })
}

export const createSearch = async (values: {name: string, contractType: string, type: string}): Promise<ManageSearchPayload> => {
  const res = await request<ManageSearchResponseModel>(RequestMethods.Post, Paths.Hp.Search.create, {
    schema: ManageSearchResponseModel,
    body: values
  })
  return res.data.data
}

export const updateSearch = async (values: {name: string}, searchId: string): Promise<ManageSearchPayload> => {
  const res = await request<ManageSearchResponseModel>(RequestMethods.Put, Paths.Hp.Search.update(searchId), {
    schema: ManageSearchResponseModel,
    body: values
  })
  return res.data.data
}

export const addSearchKeywords = async (searchId: string | number, keywords: string[]): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Search.Partial.keywords(searchId), {
    schema: BaseResponseModel,
    body: { keywords }
  })
  return res.data
}

export const addSearchCodes = async (searchId: string | number, classificationCodes: string[], naicsCodes: string[]): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Search.Partial.codes(searchId), {
    schema: BaseResponseModel,
    body: { groupedCodeIds: naicsCodes, pscCodeIds: classificationCodes }
  })
  return res.data
}

export const addSearchAgencies = async (searchId: string | number, agencies: string[]): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Search.Partial.agencies(searchId), {
    schema: BaseResponseModel,
    body: { customerIds: agencies, allAgencies: false }
  })
  return res.data
}

export const addSearchLocations = async (searchId: string | number, locations: string[]): Promise<BaseResponseModel> => {
  const res = await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Search.Partial.locations(searchId), {
    schema: BaseResponseModel,
    body: { locationIds: locations, workLocation: locations.length > 0 ? 'SPECIFIC': 'CONUS' }
  })
  return res.data
}

export const getDetailedSearch = async (searchId: number): Promise<SearchModel> => {
  const response = await request<SearchResponseModel>(RequestMethods.Get, Paths.Hp.Search.getById(searchId), {
    schema: SearchResponseModel
  })
  return response.data.data
}

export const markSearchDone = async (searchId: number): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Post, Paths.Hp.Search.done(searchId), {
    schema: SearchResponseModel
  })
}

export const getSearchesByType = async (type = 'marketResearch'): Promise<SearchModel[]> => {
  const response = await request<SearchListResponse>(RequestMethods.Get, Paths.Hp.Search.searches, {
    schema: SearchListResponse,
    params: { type }
  })
  return response.data.data
}

export const getSearchSettings = async (): Promise<SearchSetting[]> => {
  const response = await request<SearchSettingsResponseModel>(RequestMethods.Get, Paths.Hp.Search.Settings.get, {
    schema: SearchSettingsResponseModel
  })
  return response.data.data
}

export const updateSearchSettings = async (settingsId: string, dto: ISearchSettingsDTO): Promise<void> => {
  await request<BaseResponseModel>(RequestMethods.Put, Paths.Hp.Search.Settings.manage(settingsId), {
    schema: BaseResponseModel,
    body: dto
  })
}

export const setAutoFiltersValues = (dispatch: Dispatch) => (rfp?: IAutoFiltersRfp) => {
  if (!rfp) return

  const keywordsArray = convertStringToKeywords(rfp && rfp.title) || []
  keywordsArray.length > 0 && setSearchFilterValueBool(dispatch)('keywords', keywordsArray.slice(0, 5)) // take first 5 keywords of title

  rfp.naics && setSearchFilterValue(dispatch)('groupedCodeIds', +rfp.naics)
  rfp.psc && setSearchFilterValue(dispatch)('pscCodeIds', +rfp.psc)
}

export const getAllFulfilledSearches = async () => {
  const res = await request<SearchesResponseModel>(RequestMethods.Get, Paths.Hp.Search.getAllFulfilled, {
    schema: SearchesResponseModel
  })
  return res.data.data
}