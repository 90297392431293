import { InputAdornment, TextField, Theme, createStyles, withStyles } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import React from 'react'

const StyledTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 24px)',
      border: 'none',
      '& .MuiOutlinedInput-root': {
        height: '36px',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '8px'
      },
      '& .MuiInputBase-input': {
        width: '100%',
        border: 'none'
      },
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.grey['400'],
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0.25px'
      }
    },
  }),
)(TextField)

const SearchInput = () => {
  return (
    <StyledTextField
      variant='outlined'
      placeholder='Search Project'
      color='primary'
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon name='search' width={20} height={20}/>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchInput