import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivacyPolicy from './Privacy'

const Policies = () => {
  return (
    <Switch>
      <Route path={'/policies/privacy'}>
        <PrivacyPolicy />
      </Route>
    </Switch>
  )
}

export default Policies