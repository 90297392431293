import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  companyVehiclesSelector
} from 'store/hp/company-profiles/options/selectors'
import SearchFilter from 'components/v2/Filters/SearchFilter'
import {
  removeCompanySearchFilterValue, removeCompanySearchFilterValues,
  setCompanySearchFilterValue, setCompanySearchFilterValues
} from 'store/hp/company-profiles/search/actions'
import {
  companyProfileSelectedVehicleOptions
} from 'store/hp/company-profiles/search/selectors'

const VehicleFilter = () => {

  const dispatch = useDispatch()

  const vehicleOptions = useSelector(companyVehiclesSelector)

  const companyProfileSelected = useSelector(companyProfileSelectedVehicleOptions)

  const handleChange = (value: number | string, state: boolean) => {
    if (state) {
      setCompanySearchFilterValue(dispatch)('vehicleNames', value)
    } else {
      removeCompanySearchFilterValue(dispatch)('vehicleNames', value)
    }
  }

  const handlePartialChange = (values: Array<string | number>, state: boolean) => {
    if (state) {
      setCompanySearchFilterValues(dispatch)('vehicleNames', values)
    } else {
      removeCompanySearchFilterValues(dispatch)('vehicleNames', values)
    }
  }

  return (
    <SearchFilter
      allCount={vehicleOptions?.length}
      iconType='vehicle'
      selectField='value'
      filterTitle='Vehicles'
      filterOptions={vehicleOptions!.map((i, idx) => ({id: idx, text: i.text, value: i.name, ...i}))}
      selectedFilterOptions={companyProfileSelected}
      handleChange={handleChange}
      handlePartialChange={handlePartialChange}
    />
  )
}

export default VehicleFilter
