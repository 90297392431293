import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import moment from 'moment'
import { ITimeRange } from '../GanttChart/GanttChart'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTheme } from '@material-ui/core'
import {  getDiffInDates } from 'helpers/date'
import { DateType } from '../../index'
import { 
  generateGridFromDays,
  generateGridFromMonth,
  generateGridFromQuarter,
  generateHeadFromDays,
  generateHeadFromMonth,
  generateHeadFromYear
} from './generateTable'

interface TimeTableProps {
  timeRange: ITimeRange
  data: IPiplineTimeline[]
  dateType: DateType
}

const TimeTable = ({timeRange, data, dateType}: TimeTableProps) => {
  const theme = useTheme()
  const ganttTimePeriodHead = useRef<HTMLDivElement>(null)
  const ganttChart = useRef<ScrollContainer>(null)

  // creating rows
  const startMonth = new Date(
    parseInt(timeRange.fromSelectYear),
    timeRange.fromSelectMonth
  )
  const endMonth = new Date(
    parseInt(timeRange.toSelectYear),
    timeRange.toSelectMonth
  )
  const numMonths = getDiffInDates(startMonth, endMonth, 'month') + 1
  const numYears = getDiffInDates(startMonth, endMonth, 'year') + 2
  
  let month = new Date(startMonth)

  const getCurrentDate = () => {
    const currentDate = moment()
    return {
      day: Number(currentDate.format('D')),
      month: Number(currentDate.format('M')),
      year: Number(currentDate.format('YYYY')),
      week: Number(currentDate.format('W')),
      qtr: Number(currentDate.utc().quarter())
    }
  }

  const currentDate = getCurrentDate()

  const generateTable = () => {
    switch(dateType) {
      case DateType.week:
        return {
          head: generateHeadFromDays(numMonths, month, currentDate, data),
          grid:  generateGridFromDays(data, startMonth, numMonths, theme)
        }
      case DateType.month:
        return {
          head: generateHeadFromMonth(numMonths, month, currentDate, data),
          grid: generateGridFromMonth(data, startMonth, numMonths, theme)
        }
      case DateType.quarter:
        return {
          head: generateHeadFromYear(numYears, month, currentDate, data),
          grid: generateGridFromQuarter(data, startMonth, numYears, theme)
        }
      default:
        return {
          head: [],
          grid: []
        }
    }
  }

  const table = generateTable()
  const gridRepeatValue = dateType === DateType.quarter ? numYears : numMonths

  const scrollToDatePicker = (ganttContainer: ScrollContainer) => {
    const container = ganttContainer.getElement()
    const datePicker = container.querySelector('.date-picker')
    if(datePicker) {
      datePicker.scrollIntoView({inline: 'center', behavior: 'smooth'})
    } else {
      const tableContainer = container.childNodes[1] as HTMLDivElement
      container.scrollLeft = tableContainer.clientWidth
    }
  }

  useEffect(() => {
    if(ganttTimePeriodHead.current && ganttChart.current) {
      scrollToDatePicker(ganttChart.current)
    }
  }, [table.head])

  return (
    <div className={styles['time-table-wrapper']}>
      <ScrollContainer
        hideScrollbars={false}
        activationDistance={5}
        className={styles['gantt-grid-container__time']}
        ref={ganttChart}
      >
        <div
          className={styles['gantt-time-period-cell-container']}
          style={{
            gridTemplateColumns: `repeat(${gridRepeatValue}, 1fr)`,
          }}
          ref={ganttTimePeriodHead}
        >
          {table.head}
        </div>
        <div
          className={styles['gantt-time-period-cell-container']}
          style={{
            gridTemplateColumns: `repeat(${gridRepeatValue}, 1fr)`,
          }}
        > 
          {table.grid}
        </div>
      </ScrollContainer>
    </div>
  )
}


export default TimeTable