import React from 'react'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { locationCountSelector, locationsSelector } from 'store/hp/location/selectors'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import RecursiveItem, { isChecked } from 'components/v2/Filters/SearchFilter/RecursiveItem'
import { BaseRegionModel } from 'models/hp/location/location.model'
import FilterTitle from 'components/v2/Filters/SearchFilter/FilterTitle'

interface Props {
  handleChange: (id: string | number, state: boolean) => void
  handlePartialChange: (ids: Array<string | number>, state: boolean) => void
  selectedOptions: string[]
}

const LocationFilter = ({ handleChange, handlePartialChange, selectedOptions }: Props) => {
  const locationOptions: BaseRegionModel[] = useSelector(locationsSelector) as BaseRegionModel[]
  const locationCount: number = useSelector(locationCountSelector)!

  const [showMain, setShowMain] = React.useState<boolean>(false)
  const [showMapper, setShowMapperValue] = React.useState<{[key: string]: boolean}>({})

  const handleShowMain = () => {
    setShowMain(!showMain)
  }

  const renderItems = () => {
    return locationOptions.map(option => {
      const checked = isChecked(option.sub, selectedOptions, 'id')
      return (
        <div className={styles['select-block-wrap']} key={option.region}>
          <div className={styles['select-block']}>
            <FormControlLabel
              className={styles['select-input']}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() =>
                    handlePartialChange(option.sub.map(item => item.id), !checked)
                  }
                  color='primary'
                />
              }
              label={option.region}
            />
            {option.sub && option.sub.length > 0 && (
              <Button
                className={showMapper[option.region] ? styles['show-btn-active'] : undefined}
                variant='text'
                disableRipple
                onClick={() => setShowMapperValue({...showMapper, [option.region]: !showMapper[option.region]})}
              >
                <SvgIcon
                  name='angle-down'
                />
              </Button>
            )}
          </div>
          {showMapper[option.region] && (
            <div className={styles['select-block-children']}>
              {option.sub.map(item => (
                <RecursiveItem
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  sub={item.sub || []}
                  handleChange={handleChange}
                  handlePartialChange={() => {}}
                  selected={selectedOptions}
                />
              ))}
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <div className={styles['paper']}>
      <div className={styles['paper']}>
        <div className={styles['select-block']}>
          <FilterTitle title='Locations' allCount={locationCount} iconType='location' selectedLength={selectedOptions.length}/>
          {locationOptions && locationOptions.length > 0 && (
            <Button
              className={showMain ? styles['show-btn-active'] : undefined}
              variant='text'
              disableRipple
              onClick={handleShowMain}
            >
              <SvgIcon
                name='angle-down'
              />
            </Button>
          )}
        </div>
        {showMain && (
          <div className={styles['select-block-children']}>
            {renderItems()}
          </div>
        )}
      </div>
    </div>
  )
}

export default LocationFilter