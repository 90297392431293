import React, { useState } from 'react'
import styles from 'modules/Main/Education/pages/main/phases/index.module.scss'
import { Grid, Typography } from '@material-ui/core'
import PlanningPhase from 'modules/Main/Education/pages/main/phases/PlanningPhase'
import AuditioningPhase from 'modules/Main/Education/pages/main/phases/AuditioningPhase'
import LuckyWinningPhase from 'modules/Main/Education/pages/main/phases/LuckyWinningPhase'
import ProcessDrivenPhase from 'modules/Main/Education/pages/main/phases/ProcessDrivenPhase'
import { IEducationPhaseClass } from 'models/hp/education/education.interface'
import SearchClassInput from 'modules/Main/Education/components/SearchClassInput'

export type Phases = 'planning' | 'auditioning' | 'luckyWinning' | 'processDriven' | 'getHelp'

interface Props {
  openedPhases: Phases[]
  setOpenedPhase: (phase: Phases) => () => void
  selectedSubtitle: IEducationPhaseClass
  setSelectedSubtitle: (subtitle: IEducationPhaseClass) => void
}

export interface PhaseProps {
  opened: boolean
  toggle: () => void
  setActiveSubtitle: (subtitle: IEducationPhaseClass) => void
  selectedSubtitle: IEducationPhaseClass
  searchTerm: string[]
}

const ClassesAndExercises = ({openedPhases, setOpenedPhase, setSelectedSubtitle, selectedSubtitle}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string[]>([])

  const onSearch = (value: string[]) => {
    setSearchTerm(value)
    value.length && !openedPhases.includes('planning') && setOpenedPhase('planning')()
  }

  return (
    <div className={styles['classes_exercises']}>
      <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
        <Typography variant={'h4'} color={'inherit'}>Classes & Exercises</Typography>
      </Grid>
      <SearchClassInput
        onSearch={onSearch}
      />
      <PlanningPhase
        searchTerm={searchTerm}
        opened={openedPhases.includes('planning')}
        toggle={setOpenedPhase('planning')}
        setActiveSubtitle={setSelectedSubtitle}
        selectedSubtitle={selectedSubtitle}
      />
      <AuditioningPhase
        searchTerm={searchTerm}
        opened={openedPhases.includes('auditioning')}
        toggle={setOpenedPhase('auditioning')}
        setActiveSubtitle={setSelectedSubtitle}
        selectedSubtitle={selectedSubtitle}
      />
      <LuckyWinningPhase
        searchTerm={searchTerm}
        opened={openedPhases.includes('luckyWinning')}
        toggle={setOpenedPhase('luckyWinning')}
        setActiveSubtitle={setSelectedSubtitle}
        selectedSubtitle={selectedSubtitle}
      />
      <ProcessDrivenPhase
        searchTerm={searchTerm}
        opened={openedPhases.includes('processDriven')}
        toggle={setOpenedPhase('processDriven')}
        setActiveSubtitle={setSelectedSubtitle}
        selectedSubtitle={selectedSubtitle}
      />
    </div>
  )
}

export default ClassesAndExercises