import React, { useEffect } from 'react'
import { setToken } from 'utils/localStorage'
import { useDispatch } from 'react-redux'
import { getUserAction } from 'store/common/user/actions'
import { useHistory } from 'react-router-dom'
import CustomLoader from 'components/CustomLoader'
import { Grid } from '@material-ui/core'

const OAuth = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const userCreated = queryParams.get('created')
    setToken(`Bearer ${queryParams.get('val')}`)
    getUserAction()(dispatch)
      .then(() => {
        if (userCreated === '1') {
          history.replace('/auth/starter-search')
        } else {
          history.replace('/contracts')
        }
      })
      .catch(() => history.replace('/auth/sign-in'))
  }, [])

  return (
    <Grid
      container
      style={{
        width: '100%',
        height: '100%'
      }}
      alignItems='center'
      justifyContent='center'
    >
      <CustomLoader />
    </Grid>
  )
}

export default OAuth