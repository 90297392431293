import React from 'react'
import { Button, Theme, useTheme } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'

type buttonLinkTheme = 'dark' | 'primary' | 'gray' | 'error' | 'success' | 'disabled'
type buttonLinkFontWeight = '600' | '500' | '400'

interface ButtonLinkProps {
    title: string
    iconName?: string
    onClick?: () => void
    theme?: buttonLinkTheme
    fontWeight?: buttonLinkFontWeight
    className?: string
    disabled?: boolean
    withBorder?: boolean
}

const themeColor = (theme: Theme) =>  ({
  'dark': theme.palette.common.black,
  'primary': theme.palette.primary.main,
  'gray': theme.palette.grey['600'],
  'error': theme.palette.error.main,
  'success': theme.palette.success.dark,
  'disabled': theme.palette.grey['400']
})

const ButtonLink = ({
  title,
  iconName,
  onClick,
  theme = 'primary',
  fontWeight = '400',
  className,
  disabled,
  withBorder
}:ButtonLinkProps) => {
  const themeMaterial = useTheme()
  return (
    <Button
      variant='text' 
      disableElevation 
      startIcon={iconName ? <SvgIcon name={iconName} width={20} height={20} color={themeColor(themeMaterial)[theme]} /> : null}
      style={{
        color: themeColor(themeMaterial)[theme],
        fontWeight: fontWeight,
        border: withBorder ? `1px solid ${themeColor(themeMaterial)[theme]}` : 'none'
      }}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {title}
    </Button>
  )
}

export default ButtonLink