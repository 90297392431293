import { jsonMember, jsonObject } from 'typedjson'
import { INaicsDescriptionModel } from './naics.interface'

@jsonObject
export class NaicsDescriptionModel implements INaicsDescriptionModel {
  @jsonMember({ constructor: String })
  minorName: string = ''

  @jsonMember({ constructor: String })
  majorName: string = ''

  @jsonMember({ constructor: String })
  fullNumber: string = ''

  @jsonMember({ constructor: String })
  fullName: string = ''

  @jsonMember({ constructor: Number })
  id: number | null = null
}