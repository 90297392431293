import React, { useEffect } from 'react'
import styles from '../../index.module.scss'
import { Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ERROR_MESSAGES } from 'utils/message'
import { getErrorMessage } from 'helpers/errors'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import TextField from 'components/v2/TextField'
import { getMarketSetAsides, manageSearch as manageSearchAction } from 'store/hp/market-research/actions'
import { createSetAsideAction } from 'store/hp/set-aside/actions'
import { searchSelector } from 'store/hp/market-research/selectors'
import ActionsBar from 'modules/Main/User/MarketResearch/components/ActionsBar'
import HelperVideoModal from 'components/v2/HelperVideoModal'
import { VideoSourceType } from 'utils/const'

const validationSchema = Yup.object({
  name: Yup.string()
    .required(ERROR_MESSAGES.Field.Required('Field')),
})

interface FormValues {
  name: string
}

export interface DTOValues extends FormValues {
  contractType: string,
  type: string,
  setAsideIds: string
}

const SearchName = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { show } = useNotification()
  const search = useSelector(searchSelector) as any

  const [requesting, setRequesting] = React.useState(false)
  const [selectedSetAsides, setSelectedSetAsides] = React.useState<number[]>([])

  const manageSearch = async (values: FormValues) => {
    try {
      setRequesting(true)

      const createdSearch = await manageSearchAction(dispatch)({
        ...values,
        type: 'marketResearch',
        contractType: 'contract opportunities'
      } as DTOValues, search.id)

      const searchId = createdSearch ? createdSearch.id : search.id
      await createSetAsideAction(searchId, selectedSetAsides)

      setRequesting(false)
    }
    catch (e) {
      setRequesting(false)
      throw e
    }
  }

  const handleStop = async (values: FormValues) => {
    try {
      await manageSearch(values)
      show('Search created successfully', NotificationTypeList.Success)
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleContinue = async (values: FormValues) => {
    try {
      await manageSearch(values)
      history.push('/user/market-research/keywords')
    }
    catch (e) {
      show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  useEffect(() => {
    setRequesting(false)
    getMarketSetAsides()
      .then(res => setSelectedSetAsides(res))
      .catch(console.log)
      .finally(() => setRequesting(false))
  }, [])

  return (
    <div>
      <Typography variant='h2'>Name This Market Research Project</Typography>
      <Grid wrap='nowrap' container className={styles['content']}>
        <Grid item md={4} className={styles['left-block']}>
          <Typography className={styles['content-title']} variant='h4'>Instructions & background:</Typography>
          <div className={styles['vertical-divider']} />
          <Grid className={styles['left-block-content']}>
            <HelperVideoModal source={VideoSourceType.MARKET_RESEARCH_NAME}/>
            <Typography variant='h4'>Casting a net that will get the right data:</Typography>
            <Typography>
              • First we need to collect data on government spending on your products and services
            </Typography>
            <Typography>
              • The good news is that the government publishes all the data that we need
            </Typography>
            <Typography>
              • The bad news is that there is so much data, and it is so badly organized, that its hard to
              find the pieces that are relevant to you
            </Typography>
            <Typography gutterBottom>
              • The next couple exercises will help us identify keywords, and codes that will lead us to the
              best slice of data for you
            </Typography>
            <Typography variant='h4'>
              So what are you considering selling?
            </Typography>
            <Typography>
              • Enter a title for the product or service you are considering
            </Typography>
            <Typography>
              • Be specific, the government market is huge so instead of putting "IT Services" put the
              specific type of IT services that you are considering providing
            </Typography>
          </Grid>
        </Grid>
        <div className={styles['horizontal-divider']} />
        <Grid item md={8} className={styles['right-block']}>
          <Typography className={styles['content-title-left']} variant='h4'>Name This Market Research Project</Typography>
          <div className={styles['vertical-divider-left']} />
          <Grid className={styles['right-block-content']}>
            <Formik
              initialValues={{ name: search.name || '' }}
              onSubmit={handleContinue}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors
              }) => (
                <form className={styles['form']} onSubmit={handleSubmit}>
                  <Grid container justifyContent='flex-start' direction='column' alignItems='center' style={{ width: '100%', height: '100%', paddingTop: '50px' }}>
                    <Grid item style={{ width: '50%' }}>
                      <TextField
                        fullWidth
                        variant={'filled'}
                        label={'Name'}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        value={values.name}
                        helperText={!!touched.name && !!errors.name && errors.name}
                        error={!!touched.name && !!errors.name}
                      />
                    </Grid>
                  </Grid>
                  <ActionsBar
                    handleGoBack={() => history.goBack()}
                    handleContinue={handleSubmit}
                    handleStop={() => handleStop(values)}
                    requesting={requesting}
                  />
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

export default SearchName