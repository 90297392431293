import React, { useState } from 'react'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@material-ui/core'
import { expiringContractsListingSelector, expiringContractsSumsSelector } from 'store/hp/market-research-tools/selectors'
import { IRecord } from 'types/common'
import { IMarketResearchToolsExpiringContracts } from 'store/hp/market-research-tools/reducers'
import ScrollManager from 'components/ScrollManager'
import Wrapper from 'components/v2/Wrapper'
import ListingItem from './Item'
import { BigHint, SmallHint } from './components/ListingHints'

let activeTab: string = ''
let initLoading: boolean = true

const Listing = () => {
  const listing = useSelector(expiringContractsListingSelector) as IRecord<IMarketResearchToolsExpiringContracts>
  const [itemSum, valueSum] = useSelector(expiringContractsSumsSelector)

  const [openedTab, setOpenedTab] = React.useState(activeTab)
  const [hintClosed, setHintClosed] = React.useState(false)
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null)

  const toggleTab = (key: string) => {
    const state = openedTab !== key ? key : ''
    activeTab = state
    setOpenedTab(state)

    state && scrollTop()
  }

  const scrollTop = () => {
    listRef && (listRef.scrollTop = 0)
  }

  if (initLoading) {
    if (listing.loading || listing.data) {
      initLoading = false
    } else {
      if (hintClosed) {
        initLoading = false
        return (
          <Wrapper>
            <SmallHint />
          </Wrapper>
        )
      }
      return (
        <Wrapper>
          <BigHint close={() => setHintClosed(true)} />
        </Wrapper>
      )
    }
  }

  if (listing.loading) {
    return (
      <Wrapper>
        <CircularProgress color='primary' size={30} />
      </Wrapper>
    )
  }

  return (
    <ScrollManager scrollKey='expiring-contracts'>
      {({ connectScrollTarget }: any) => (
        <Grid
          container
          className={styles['list']}
          spacing={2}
          direction='column'
          wrap='nowrap'
          justifyContent='flex-start'
          ref={(ref) => {
            setListRef(ref)
            connectScrollTarget(ref)
          }}
        >
          <ListingItem
            label='RFP IN 3 MONTHS'
            item={listing.data!.in3Month}
            itemSum={itemSum}
            valueSum={valueSum}
            dataKey={'in3Month'}
            tabOpened={openedTab === 'in3Month'}
            toggleTab={() => toggleTab('in3Month')}
          />
          <ListingItem
            label='RFP IN 6 MONTHS'
            item={listing.data!.in6Month}
            itemSum={itemSum}
            valueSum={valueSum}
            dataKey={'in6Month'}
            tabOpened={openedTab === 'in6Month'}
            toggleTab={() => toggleTab('in6Month')}
          />
          <ListingItem
            label='RFP IN 9 MONTHS'
            item={listing.data!.in9Month}
            itemSum={itemSum}
            valueSum={valueSum}
            dataKey={'in9Month'}
            tabOpened={openedTab === 'in9Month'}
            toggleTab={() => toggleTab('in9Month')}
          />
          <ListingItem
            label='RFP IN 12 MONTHS'
            item={listing.data!.in12Month}
            itemSum={itemSum}
            valueSum={valueSum}
            dataKey={'in12Month'}
            tabOpened={openedTab === 'in12Month'}
            toggleTab={() => toggleTab('in12Month')}
          />
        </Grid>
      )}
    </ScrollManager>
  )
}

export default Listing