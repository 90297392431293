import {
  IHpRfpDetailModel,
  IHpRfpDetailResponseModel,
  TCategorization
} from 'models/hp/rfp/rfp.interface'
import { Record } from 'immutable'
import {
  IRfpFilter,
  HP_RFP_DETAILS,
  HP_RFP_DETAILS_RESET,
  HP_RFP_LIST,
  HP_RFP_LIST_SUCCESS,
  HP_RFP_LIST_ERROR,
  HP_RFP_LIST_FILTER,
  HP_RFP_LIST_APPEND_SUCCESS,
  HP_RFP_CATEGORIZED,
  HP_RFP_CATEGORIZED_SUCCESS,
  HP_RFP_CATEGORIZED_ERROR,
  HP_RFP_TRASHED,
  HP_RFP_TRASHED_ERROR,
  HP_RFP_TRASHED_SUCCESS,
  HP_RFP_ITEM_CATEGORIZED,
  HP_RFP_CATEGORIZED_RESET,
  HP_RFP_LIST_LOADED_FALSE,
  CALCULATORS_SET_P_WIN,
  CALCULATORS_SET_ESTIMATED_VALUE,
  CALCULATORS_SET_COST_TO_PURSUE,
  CALCULATORS_SET_PROPOSAL_LENGTH,
  CALCULATORS_SET_TASK,
  HP_RFP_ITEM_TRASHED_UNSET,
  HP_RFP_TRASHED_RESET,
  HP_RFP_TRASHED_APPEND, HP_RFP_CATEGORIZED_ITEM_UNSET,
  SET_GO_NO_GO_SUGGESTION,
  SET_GO_NO_GO_SUGGESTION_SUCCESS,
  SET_GO_NO_GO_SUGGESTION_ERROR,
  GET_GO_NO_GO_SUGGESTIONS,
  GET_GO_NO_GO_SUGGESTIONS_SUCCESS,
  GET_GO_NO_GO_SUGGESTIONS_ERROR,
  GET_PIPELINE_RFP_STEPS,
  GET_PIPELINE_RFP_STEPS_SUCCESS,
  GET_PIPELINE_RFP_STEPS_ERROR,
  SET_PIPELINE_RFP_STEP,
  CLEAR_SUGGESTIONS,
  UPDATE_GO_NO_GO_SUGGESTIONS,
  HP_RFP_RESET,
  GET_HP_STRATEGY_WIN_THEMES,
  GET_HP_STRATEGY_WIN_THEMES_SUCCESS,
  GET_HP_STRATEGY_WIN_THEMES_ERROR,
  SET_HP_STRATEGY_WIN_THEMES,
  SET_HP_STRATEGY_WIN_THEMES_SUCCESS,
  SET_HP_STRATEGY_WIN_THEMES_ERROR,
  DELETE_HP_STRATEGY_ITEM,
  UPDATE_HP_STRATEGY_ITEM,
  GET_HP_STRATEGY_ACTIVITIES,
  GET_HP_STRATEGY_ACTIVITIES_SUCCESS,
  GET_HP_STRATEGY_ACTIVITIES_ERROR,
  SET_HP_STRATEGY_ACTIVITIES,
  SET_HP_STRATEGY_ACTIVITIES_SUCCESS,
  SET_HP_STRATEGY_ACTIVITIES_ERROR,
  GET_HP_STRATEGY_PAST_PERFORMANCE,
  GET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS,
  GET_HP_STRATEGY_PAST_PERFORMANCE_ERROR,
  SET_HP_STRATEGY_PAST_PERFORMANCE,
  SET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS,
  SET_HP_STRATEGY_PAST_PERFORMANCE_ERROR,
  GET_HP_STRATEGY_EVALUATION_CRITERIA,
  GET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS,
  GET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR,
  SET_HP_STRATEGY_EVALUATION_CRITERIA,
  SET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS,
  SET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR,
  GET_HP_STRATEGY_PROCESS,
  GET_HP_STRATEGY_PROCESS_SUCCESS,
  GET_HP_STRATEGY_PROCESS_ERROR,
  SET_HP_STRATEGY_PROCESS,
  SET_HP_STRATEGY_PROCESS_SUCCESS,
  SET_HP_STRATEGY_PROCESS_ERROR,
  GET_HP_STRATEGY_OPEN_QUESTIONS,
  GET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS,
  GET_HP_STRATEGY_OPEN_QUESTIONS_ERROR,
  SET_HP_STRATEGY_OPEN_QUESTIONS,
  SET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS,
  SET_HP_STRATEGY_OPEN_QUESTIONS_ERROR,
  GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS,
  GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS,
  GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR,
  SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS,
  SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS,
  SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR,
  GET_HP_STRATEGY_PROCESS_NAICS,
  GET_HP_STRATEGY_PROCESS_NAICS_SUCCESS,
  GET_HP_STRATEGY_PROCESS_NAICS_ERROR,
  GET_HP_STRATEGY_PROCESS_SET_ASIDE,
  GET_HP_STRATEGY_PROCESS_SET_ASIDE_SUCCESS,
  GET_HP_STRATEGY_PROCESS_SET_ASIDE_ERROR,
  GET_HP_STRATEGY_SUMMARY,
  GET_HP_STRATEGY_SUMMARY_SUCCESS,
  GET_HP_STRATEGY_SUMMARY_ERROR,
  SET_HP_STRATEGY_SUMMARY,
  SET_HP_STRATEGY_SUMMARY_SUCCESS,
  SET_HP_STRATEGY_SUMMARY_ERROR,
  HP_RFP_GET_DOWNLOAD_DOCUMENTS_LINK_SUCCESS,
  HP_RFP_GET_WORK_SUCCESS,
  GET_RESPONSE_OUTLINE,
  GET_RESPONSE_OUTLINE_SUCCESS,
  GET_RESPONSE_OUTLINE_ERROR,
  CREATE_RESPONSE_OUTLINE,
  CREATE_RESPONSE_OUTLINE_SUCCESS,
  CREATE_RESPONSE_OUTLINE_ERROR,
  DELETE_RESPONSE_OUTLINE,
  DELETE_RESPONSE_OUTLINE_SUCCESS,
  DELETE_RESPONSE_OUTLINE_ERROR,
  UPDATE_RESPONSE_OUTLINE,
  UPDATE_RESPONSE_OUTLINE_SUCCESS,
  UPDATE_RESPONSE_OUTLINE_ERROR,
  GET_RESPONSE_COMPLIANCE,
  GET_RESPONSE_COMPLIANCE_SUCCESS,
  GET_RESPONSE_COMPLIANCE_ERROR,
  CREATE_RESPONSE_COMPLIANCE,
  CREATE_RESPONSE_COMPLIANCE_SUCCESS,
  CREATE_RESPONSE_COMPLIANCE_ERROR,
  DELETE_RESPONSE_COMPLIANCE,
  DELETE_RESPONSE_COMPLIANCE_SUCCESS,
  DELETE_RESPONSE_COMPLIANCE_ERROR,
  UPDATE_RESPONSE_COMPLIANCE,
  UPDATE_RESPONSE_COMPLIANCE_SUCCESS,
  UPDATE_RESPONSE_COMPLIANCE_ERROR,
  UPDATE_RFP_CATEGORIZATION
} from './action'
import { HpRfpDetailResponseModel, HpRfpModel, CategorizedHpRfpModel, GoNoGoSuggestionModel } from 'models/hp/rfp/rfp.model'
import { IAction } from 'types/common'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'
import { PipelineRfpStep } from 'models/hp/pipeline/pipeline.model'
import { IDateModel } from 'models/common/calculators/due-timeline/due-timeline.interface'
import { CLEAR_GENERAL_CALC, GET_GENERAL_CALC, GET_GENERAL_CALC_SUCCESS } from '../calculators/general/actions'
import { IRiskAndEffort } from 'models/common/calculators/qualifying/qualifying.interface'
import { 
  CompetitivePositionModel,
  CompetitorLandscapeModel,
  CustomerConnectionModel,
  IncumbentPerformanceModel,
  IncumbentsModel,
  QualifyPrimeModel,
  QualifyPrimeSBIRModel,
  QualifySubModel,
  RiskAndEffortModel,
  SelfAssessmentInputCriteriaModel,
  SelfAssessmentInputModel,
  SubbingModel,
  SubsequentAwardsModel,
  SummaryModel,
  TasksCalcModel,
} from 'models/common/calculators/qualifying/qualifying.model'
import {
  GET_RISK_AND_EFFORT_CALC,
  GET_RISK_AND_EFFORT_CALC_ERROR,
  GET_RISK_AND_EFFORT_CALC_SUCCESS,
  GET_TASKS_CALC,
  GET_TASKS_CALC_SUCCESS,
  GET_TASKS_CALC_ERROR,
  UPDATE_TASKS_CALC,
  UPDATE_TASKS_CALC_ERROR,
  UPDATE_TASKS_CALC_SUCCESS,
  SET_TASKS_CALC,
  SET_TASKS_CALC_SUCCESS,
  SET_TASKS_CALC_ERROR,
  GET_INCUMBENTS,
  GET_INCUMBENTS_SUCCESS,
  GET_INCUMBENTS_ERROR,
  GET_INCUMBENT_PERFORMANCE,
  GET_INCUMBENT_PERFORMANCE_SUCCESS,
  GET_INCUMBENT_PERFORMANCE_ERROR,
  GET_SUBSEQUENT_AWARDS,
  GET_SUBSEQUENT_AWARDS_SUCCESS,
  GET_SUBSEQUENT_AWARDS_ERROR,
  GET_CUSTOMER_CONNECTION,
  GET_CUSTOMER_CONNECTION_SUCCESS,
  GET_CUSTOMER_CONNECTION_ERROR,
  GET_COMPETITOR_LANDSCAPE,
  GET_COMPETITOR_LANDSCAPE_SUCCESS,
  GET_COMPETITOR_LANDSCAPE_ERROR,
  GET_COMPETITIVE_POSITION,
  GET_COMPETITIVE_POSITION_SUCCESS,
  GET_COMPETITIVE_POSITION_ERROR,
  GET_SUMMARY,
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_ERROR,
  SET_SUMMARY,
  SET_SUMMARY_SUCCESS,
  SET_SUMMARY_ERROR,
  GET_SELF_ASSESSMENT_INPUT,
  GET_SELF_ASSESSMENT_INPUT_SUCCESS,
  SET_SELF_ASSESSMENT_INPUT_SELF_SCORE,
  SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_SUCCESS,
  SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_ERROR,
  GET_SELF_ASSESSMENT_INPUT_ERROR,
  UPDATE_SELF_ASSESSMENT_INPUT_SELF_SCORE,
  GET_QUALIFY_PRIME,
  GET_QUALIFY_PRIME_SUCCESS,
  GET_QUALIFY_PRIME_ERROR,
  GET_QUALIFY_PRIME_SBIR,
  GET_QUALIFY_PRIME_SBIR_SUCCESS,
  GET_QUALIFY_PRIME_SBIR_ERROR,
  UPDATE_QUALIFY_PRIME_SBIR,
  SET_SCORE_QUALIFY_PRIME_SBIR,
  DELETE_QUALIFY_PRIME_SBIR,
  GET_QUALIFY_SUB,
  GET_QUALIFY_SUB_SUCCESS,
  GET_QUALIFY_SUB_ERROR,
  SET_SCORE_QUALIFY_SUB,
  UPDATE_QUALIFY_SUB,
  SET_SCORE_QUALIFY_PRIME,
  UPDATE_QUALIFY_PRIME,
  DELETE_QUALIFY_PRIME,
  DELETE_QUALIFY_SUB,
  GET_SUBBING,
  GET_SUBBING_SUCCESS,
  GET_SUBBING_ERROR,
  SET_SUBBING,
  SET_SUBBING_SUCCESS,
  SET_SUBBING_ERROR,
  UPDATE_SUBBING,
} from '../calculators/qualifying/actions'
import {
  HpRfpWorkItem,
  HpStrategyActivitiesModel,
  HpStrategyEvaluationCriteriaModel,
  HpStrategyOpenQuestionsModel,
  HpStrategyPastPerformanceModel,
  HpStrategyPastPerformanceRecommendationsModel,
  HpStrategyProcessModel,
  HpStrategyProcessNaicsDataModel,
  HpStrategyProcessSetAsideDataModel,
  HpStrategySummaryModel,
  HpStrategyWinThemesModel,
  NoteModel
} from 'models/hp/rfp/calc/calc.model'
import { Note } from 'models/hp/rfp/calc/calc.interface'

const defaultFilters = (): IRfpFilter => ({
  title: undefined,
  offset: 0,
  limit: 25,
  date: undefined,
  searchId: undefined
})

export interface ICategorization<T> {
  title: string
  type: TCategorization
  count: number
  estValue?: string
  items: T
}

export const categorizationToKey = (type: TCategorization): string => {
  const mapper: {[key in TCategorization]?: string} = {
    'pipeline__1': 'liked',
    'pipeline__2': 'liked',
    'pipeline__3': 'liked',
    'pipeline__4': 'liked',
    'for proposal': 'forProposal',
    'pre-proposal': 'preProposal'
  }
  return mapper[type] || 'like'
}

export interface IHpRfpListing {
  filterParams: IRfpFilter
  listing: {
    loaded: boolean
    loading: boolean
    count: number
    new: number
    data: RfpTinderModel[]
  }
  categorized: {
    loading: boolean
    loaded: boolean
    liked: ICategorization<CategorizedHpRfpModel[]>
    preProposal: ICategorization<CategorizedHpRfpModel[]>
    forProposal: ICategorization<CategorizedHpRfpModel[]>
  }
  trashed: {
    count: number
    loading: boolean
    loaded: boolean
    data: HpRfpModel[]
  }
  goNoGoSuggestions: {
    loading: boolean
    data: GoNoGoSuggestionModel[]
  }
}

const HpRfpListRecord = Record<IHpRfpListing>({
  filterParams: defaultFilters(),
  listing: {
    loaded: false,
    loading: false,
    count: 0,
    new: 0,
    data: [],
  },
  categorized: {
    loading: false,
    loaded: false,
    liked: {
      title: '',
      type: 'pipeline__1',
      count: 0,
      estValue: '',
      items: []
    },
    preProposal: {
      title: '',
      type: 'pre-proposal',
      count: 0,
      estValue: '',
      items: []
    },
    forProposal: {
      title: '',
      type: 'for proposal',
      count: 0,
      estValue: '',
      items: []
    },
  },
  trashed: {
    loading: false,
    loaded: false,
    count: 0,
    data: []
  },
  goNoGoSuggestions: {
    loading: false,
    data: []
  },
})

export const hpRfpListingReducer = (state: Record<IHpRfpListing> = new HpRfpListRecord(), action: IAction<any>) => {
  switch (action.type) {
    case HP_RFP_LIST:
      return state
        .setIn(['listing', 'loading'], true)
    case HP_RFP_LIST_ERROR:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], false)
    case HP_RFP_LIST_SUCCESS:
      return state
        .setIn(['listing', 'loading'], false)
        .setIn(['listing', 'loaded'], true)
        .setIn(['listing', 'count'], action.payload?.count || 0)
        .setIn(['listing', 'new'], action.payload?.new || 0)
        .setIn(['listing', 'data'], action.payload?.data || [])
    case HP_RFP_LIST_LOADED_FALSE:
      return state
        .setIn(['listing', 'loaded'], false)
    case HP_RFP_LIST_APPEND_SUCCESS:
      return state
        .updateIn(['listing', 'data'], (data: any) => data.concat(action.payload?.data || []))
    case HP_RFP_LIST_FILTER:
      return state
        .set('filterParams', action.payload as IRfpFilter || state.get('filterParams'))
    case HP_RFP_CATEGORIZED:
      return state
        .setIn(['categorized', 'loading'], true)
    case HP_RFP_CATEGORIZED_ERROR:
      return state
        .setIn(['categorized', 'loading'], false)
        .setIn(['categorized', 'loaded'], false)
    case HP_RFP_CATEGORIZED_SUCCESS:
      return state
        .setIn(['categorized', 'loading'], false)
        .setIn(['categorized', 'loaded'], true)
        .setIn(['categorized', 'liked'], action.payload[0])
        .setIn(['categorized', 'preProposal'], action.payload[1])
        .setIn(['categorized', 'forProposal'], action.payload[2])
    case HP_RFP_CATEGORIZED_RESET:
      return state
        .setIn(['categorized', 'loading'], false)
        .setIn(['categorized', 'loaded'], false)

    case HP_RFP_TRASHED:
      return state
        .setIn(['trashed', 'loading'], true)
    case HP_RFP_TRASHED_ERROR:
      return state
        .setIn(['trashed', 'loading'], false)
        .setIn(['trashed', 'loaded'], false)
    case HP_RFP_TRASHED_SUCCESS:
      return state
        .setIn(['trashed', 'loading'], false)
        .setIn(['trashed', 'loaded'], true)
        .setIn(['trashed', 'count'], action.payload.count)
        .setIn(['trashed', 'data'], action.payload.data)
    case HP_RFP_TRASHED_APPEND:
      return state
        .setIn(['trashed', 'loading'], false)
        .setIn(['trashed', 'count'], action.payload.count)
        .updateIn(['trashed', 'data'], (data: any) => data.concat(action.payload.data))
    case HP_RFP_ITEM_CATEGORIZED:
      return state
        .updateIn(
          ['listing', 'data'],
          (items: any) => items.filter((item: any) => item.id !== action.payload.rfp.id)
        )
    case HP_RFP_CATEGORIZED_ITEM_UNSET:
      return state
        .updateIn(
          ['categorized', categorizationToKey(action.payload.rfp.categorizationType)],
          (segment: any) => ({
            ...segment,
            items: segment.items.filter((item: any) => item.id !== action.payload.rfp.id),
            count: segment.count - 1
          })
        )
    case HP_RFP_ITEM_TRASHED_UNSET:
      return state
        .update('trashed', (trashed) => ({
          ...trashed,
          data: trashed.data.filter((item: HpRfpModel) => item.id !== action.payload),
          count: trashed.count - 1
        }))
    case HP_RFP_TRASHED_RESET:
      return state
        .update('trashed', () => ({
          loaded: false,
          loading: false,
          data: [],
          count: 0,
          showLoadMore: false
        }))
    case GET_GO_NO_GO_SUGGESTIONS:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], true)
    case GET_GO_NO_GO_SUGGESTIONS_SUCCESS:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], false)
        .updateIn(['goNoGoSuggestions', 'data'], () => action.payload.data.data)
    case GET_GO_NO_GO_SUGGESTIONS_ERROR:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], false)
    case SET_GO_NO_GO_SUGGESTION:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], true)
    case SET_GO_NO_GO_SUGGESTION_SUCCESS:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], false)
    case SET_GO_NO_GO_SUGGESTION_ERROR:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], false)
    case CLEAR_SUGGESTIONS:
      return state
        .setIn(['goNoGoSuggestions', 'loading'], false)
        .updateIn(['goNoGoSuggestions', 'data'], () => [])
    case UPDATE_GO_NO_GO_SUGGESTIONS:
      return state
        .updateIn(['goNoGoSuggestions', 'data'], (data: any) => {
          return data.map(
            (el: any) => el.id === action.payload.id ?
              {...el, goNoGo: {...el.goNoGo, value: action.payload.value}}
              : el
          )
        })
    default:
      return state
  }
}

export interface IRfpDetails extends IHpRfpDetailResponseModel {
  data: IHpRfpDetailModel | null
  steps: {
    loading: boolean
    loaded: boolean
    data: PipelineRfpStep[]
  },
  calculators: {
    overview: {
      loading: boolean
      loaded: boolean
      data: {
        timeline: IDateModel[],
        reCompete: null,
        winnerByType: {
          govvet: number,
          incumbent: number,
          outsider: number,
          subtier: number
        },
        expiredContracts: HpRfpModel[] | null
        bpTotal: {
          full: number,
          min: number,
          remaining: number
        }
      }
    },
    qualifying: {
      risk: {
        loading: boolean,
        loaded: boolean,
        data: IRiskAndEffort
      },
      pWin: {
        prime: {
          loading: boolean,
          loaded: boolean,
          data: QualifyPrimeModel
        },
        sub: {
          loading: boolean,
          loaded: boolean,
          data: QualifySubModel
        },
        sbir: {
          loading: boolean,
          loaded: boolean,
          data: QualifyPrimeSBIRModel
        }
      }
      tasks: {
        loading: boolean,
        loaded: boolean,
        data: TasksCalcModel
      },
      incumbents: {
        loading: boolean,
        loaded: boolean,
        data: IncumbentsModel
      },
      incumbentPerformance: {
        loading: boolean,
        loaded: boolean,
        data: IncumbentPerformanceModel
      },
      subsequentAwards:{
        loading: boolean,
        loaded: boolean,
        data: SubsequentAwardsModel
      },
      customerConnection: {
        loading: boolean,
        loaded: boolean,
        data: CustomerConnectionModel
      },
      competitorLandscape: {
        loading: boolean,
        loaded: boolean,
        data: CompetitorLandscapeModel
      },
      competitivePosition: {
        loading: boolean,
        loaded: boolean,
        data: CompetitivePositionModel
      },
      summary: {
        loading: boolean,
        loaded: boolean,
        data: SummaryModel[]
      },
      selfAssessment: {
        loading: boolean,
        identifyLoading: boolean,
        selfScoreLoading: boolean,
        loaded: boolean,
        data: SelfAssessmentInputModel
      },
      subbing: {
        loading: boolean,
        loaded: boolean,
        data: SubbingModel
      }
    }
    strategy: {
      winThemes: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyWinThemesModel[],
        count: number
      },
      activities: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyActivitiesModel[],
        count: number
      },
      pastPerformance: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyPastPerformanceModel[],
        count: number
      },
      pastPerformanceRecommendations: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyPastPerformanceRecommendationsModel[],
        count: number
      },
      evaluationCriteria: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyEvaluationCriteriaModel[],
        count: number
      },
      process: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyProcessModel[],
        count: number
      },
      processNaics: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyProcessNaicsDataModel,
      },
      processSetAside: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyProcessSetAsideDataModel,
      },
      openQuestions: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategyOpenQuestionsModel[],
        count: number
      },
      summary: {
        loading: boolean,
        loaded: boolean,
        data: HpStrategySummaryModel[],
        count: number
      }
    },
  response: {
    outline: {
      loading: boolean,
      loaded: boolean,
      data: NoteModel[],
    },
    compliance: {
      loading: boolean,
      loaded: boolean,
      data: NoteModel[],
    }
  }
  },
  downloadDocumentsLink: string
  work: HpRfpWorkItem[] | null
}

const HpRfpDetailsRecord = Record<IRfpDetails>({
  ...new HpRfpDetailResponseModel(),
  data: null,
  steps: {
    loading: false,
    loaded: false,
    data: []
  },
  calculators: {
    overview: {
      loading: false,
      loaded: false,
      data: {
        timeline: [],
        winnerByType: {
          govvet: 0,
          incumbent: 0,
          outsider: 0,
          subtier: 0
        },
        expiredContracts: null,
        reCompete: null,
        bpTotal: {
          full: 0,
          min: 0,
          remaining: 0
        }
      }
    },
    qualifying: {
      risk: {
        loading: false,
        loaded: false,
        data: new RiskAndEffortModel() || null
      },
      pWin: {
        prime: {
          loading: false,
          loaded: false,
          data: new QualifyPrimeModel()
        },
        sub: {
          loading: false,
          loaded: false,
          data: new QualifySubModel()
        },
        sbir: {
          loading: false,
          loaded: false,
          data: new QualifyPrimeSBIRModel()
        }
      },
      tasks: {
        loading: false,
        loaded: false,
        data: new TasksCalcModel()
      },
      incumbents: {
        loading: false,
        loaded: false,
        data: new IncumbentsModel()
      },
      incumbentPerformance: {
        loading: false,
        loaded: false,
        data: new IncumbentPerformanceModel()
      },
      subsequentAwards: {
        loading: false,
        loaded: false,
        data: new SubsequentAwardsModel()
      },
      customerConnection: {
        loading: false,
        loaded: false,
        data: new CustomerConnectionModel()
      },
      competitorLandscape: {
        loading: false,
        loaded: false,
        data: new CompetitorLandscapeModel()
      },
      competitivePosition: {
        loading: false,
        loaded: false,
        data: new CompetitivePositionModel()
      },
      summary: {
        loading: false,
        loaded: false,
        data: []
      },
      selfAssessment: {
        loading: false,
        loaded: false,
        identifyLoading: false,
        selfScoreLoading: false,
        data: new SelfAssessmentInputModel()
      },
      subbing: {
        loading: false,
        loaded: false,
        data: new SubbingModel()
      }
    },
    strategy: {
      winThemes: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      activities: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      pastPerformance: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      pastPerformanceRecommendations: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      evaluationCriteria: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      process: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      processNaics: {
        loading: false,
        loaded: false,
        data: new HpStrategyProcessNaicsDataModel(),
      },
      processSetAside: {
        loading: false,
        loaded: false,
        data: new HpStrategyProcessSetAsideDataModel(),
      },
      openQuestions: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      },
      summary: {
        loading: false,
        loaded: false,
        data: [],
        count: 0
      }
    },
    response: {
      outline: {
        loading: false,
        loaded: false,
        data: [],
      },
      compliance: {
        loading: false,
        loaded: false,
        data: [],
      }
    }
  },
  downloadDocumentsLink: '',
  work: null
})

export const hpRfpDetailsReducer = (state: Record<IRfpDetails> = new HpRfpDetailsRecord(), action: IAction<any>) => {
  switch (action.type) {
    case HP_RFP_DETAILS:
      return state.set('data', action.payload ? action.payload.data : null)
    case HP_RFP_DETAILS_RESET:
      return state.set('data', null)
    case HP_RFP_RESET:
      return new HpRfpDetailsRecord()
    case CALCULATORS_SET_P_WIN:
      return state
        .update('data', (data) => ({
          ...data,
          calc: {
            ...data?.calc,
            pWin: {
              total: action.payload,
              calculated: true
            }
          }
        } as IHpRfpDetailModel))
    case CALCULATORS_SET_ESTIMATED_VALUE:
      return state
        .update('data', (data) => ({
          ...data,
          calc: {
            ...data?.calc,
            estimatedValue: action.payload.value,
            estimatedValueCalculated: action.payload.estimatedValueCalculated
          }
        } as IHpRfpDetailModel))
    case CALCULATORS_SET_COST_TO_PURSUE:
      return state
        .update('data', (data) => ({
          ...data,
          calc: {
            ...data?.calc,
            costToPursue: action.payload.costToPursue,
            hoursToPursue: action.payload.hoursToPursue,
            pursueCalculated: action.payload.pursueCalculated
          }
        } as IHpRfpDetailModel))
    case CALCULATORS_SET_PROPOSAL_LENGTH:
      return state
        .update('data', (data) => ({
          ...data,
          calc: {
            ...data?.calc,
            proposalLength: action.payload === 1 ? '1 page' : `${action.payload} pages`
          }
        } as IHpRfpDetailModel))
    case CALCULATORS_SET_TASK:
      return state
        .update('data', (data) => ({
          ...data,
          selfAssessed: action.payload
        } as IHpRfpDetailModel))

    case GET_PIPELINE_RFP_STEPS:
      return state.update('steps', (steps) => ({
        ...steps,
        loading: true,
        loaded: false
      }))

    case GET_PIPELINE_RFP_STEPS_SUCCESS:
      return state.update('steps', (steps) => ({
        ...steps,
        loading: false,
        loaded: true,
        data: action.payload
      }))

    case GET_PIPELINE_RFP_STEPS_ERROR:
      return state.update('steps', (steps) => ({
        ...steps,
        loading: false,
        loaded: false,
        data: []
      }))

    case HP_RFP_GET_DOWNLOAD_DOCUMENTS_LINK_SUCCESS:
      return state.set('downloadDocumentsLink', action.payload)

    case HP_RFP_GET_WORK_SUCCESS:
      return state.set('work', action.payload)

    case SET_PIPELINE_RFP_STEP:
      return state.update('steps', (steps) => {
        const stepIndex = steps.data.findIndex(x => x.step === action.payload.step)
        if (stepIndex !== -1 ) { // modify existing step
          steps.data[stepIndex].user = action.payload.user
          steps.data[stepIndex].createdAt = new Date()
          return steps
        }
        steps.data.push({ // add new step
          ...new PipelineRfpStep(),
          user: action.payload.user,
          step: Number(action.payload.step),
        })
        return steps
      })
    case GET_GENERAL_CALC:
      return state.update('calculators', (calculators) => ({
        ...calculators,
        overview: {
          ...calculators.overview,
          loading: true
        }
      }))
    case GET_GENERAL_CALC_SUCCESS:
      return state.update('calculators', (calculators) => ({
        ...calculators,
        overview: {
          loading: false,
          loaded: true,
          data: action.payload
        }
      }))
    case UPDATE_RFP_CATEGORIZATION:
      return state.updateIn(['data'], (data: any) => ({
        ...data,
        categorizationType: action.payload
      }))
    case CLEAR_GENERAL_CALC:
      return state.setIn('calculators', new HpRfpDetailsRecord())
    case GET_RISK_AND_EFFORT_CALC:
      return state.setIn(['calculators','qualifying', 'risk', 'loading'], true)
    case GET_RISK_AND_EFFORT_CALC_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'risk', 'loading'], false)
        .setIn(['calculators','qualifying', 'risk', 'loaded'], true)
        .setIn(['calculators','qualifying', 'risk', 'data'], action.payload)
    case GET_RISK_AND_EFFORT_CALC_ERROR:
      return state
        .setIn(['calculators','qualifying', 'risk', 'loading'], false)
        .setIn(['calculators','qualifying', 'risk', 'loaded'], true)
    case GET_TASKS_CALC:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], true)
        .setIn(['calculators','qualifying', 'tasks', 'loaded'], false)
    case GET_TASKS_CALC_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
        .setIn(['calculators','qualifying', 'tasks', 'loaded'], true)
        .setIn(['calculators','qualifying', 'tasks', 'data'], action.payload)
    case GET_TASKS_CALC_ERROR:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
        .setIn(['calculators','qualifying', 'tasks', 'loaded'], true)
    case UPDATE_TASKS_CALC:
      return state.setIn(['calculators','qualifying', 'tasks', 'loading'], true)
    case UPDATE_TASKS_CALC_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
        .updateIn(['calculators','qualifying', 'tasks', 'data'], (data: any) => {
          return {
            ...data,
            data: [...data.data, ...action.payload]
          }
        })
    case UPDATE_TASKS_CALC_ERROR:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
    case SET_TASKS_CALC:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], true)
    case SET_TASKS_CALC_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
    case SET_TASKS_CALC_ERROR:
      return state
        .setIn(['calculators','qualifying', 'tasks', 'loading'], false)
    case GET_INCUMBENTS:
      return state
        .setIn(['calculators','qualifying', 'incumbents', 'loading'], true)
    case GET_INCUMBENTS_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'incumbents', 'loading'], false)
        .setIn(['calculators','qualifying', 'incumbents', 'loaded'], true)
        .setIn(['calculators','qualifying', 'incumbents', 'data'], action.payload)
    case GET_INCUMBENTS_ERROR:
      return state
        .setIn(['calculators','qualifying', 'incumbents', 'loading'], false)
    case GET_INCUMBENT_PERFORMANCE:
      return state
        .setIn(['calculators','qualifying', 'incumbentPerformance', 'loading'], true)
    case GET_INCUMBENT_PERFORMANCE_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'incumbentPerformance', 'loading'], false)
        .setIn(['calculators','qualifying', 'incumbentPerformance', 'loaded'], true)
        .setIn(['calculators','qualifying', 'incumbentPerformance', 'data'], action.payload)
    case GET_INCUMBENT_PERFORMANCE_ERROR:
      return state
        .setIn(['calculators','qualifying', 'incumbentPerformance', 'loading'], false)
    case GET_SUBSEQUENT_AWARDS:
      return state
        .setIn(['calculators','qualifying', 'subsequentAwards', 'loading'], true)
    case GET_SUBSEQUENT_AWARDS_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'subsequentAwards', 'loading'], false)
        .setIn(['calculators','qualifying', 'subsequentAwards', 'loaded'], true)
        .setIn(['calculators','qualifying', 'subsequentAwards', 'data'], action.payload)
    case GET_SUBSEQUENT_AWARDS_ERROR:
      return state
        .setIn(['calculators','qualifying', 'subsequentAwards', 'loading'], false)
    case GET_CUSTOMER_CONNECTION:
      return state
        .setIn(['calculators','qualifying', 'customerConnection', 'loading'], true)
    case GET_CUSTOMER_CONNECTION_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'customerConnection', 'loading'], false)
        .setIn(['calculators','qualifying', 'customerConnection', 'loaded'], true)
        .setIn(['calculators','qualifying', 'customerConnection', 'data'], action.payload)
    case GET_CUSTOMER_CONNECTION_ERROR:
      return state
        .setIn(['calculators','qualifying', 'customerConnection', 'loading'], false)
    case GET_COMPETITOR_LANDSCAPE:
      return state
        .setIn(['calculators','qualifying', 'competitorLandscape', 'loading'], true)
    case GET_COMPETITOR_LANDSCAPE_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'competitorLandscape', 'loading'], false)
        .setIn(['calculators','qualifying', 'competitorLandscape', 'loaded'], true)
        .setIn(['calculators','qualifying', 'competitorLandscape', 'data'], action.payload)
    case GET_COMPETITOR_LANDSCAPE_ERROR:
      return state
        .setIn(['calculators','qualifying', 'competitorLandscape', 'loading'], false)
    case GET_COMPETITIVE_POSITION:
      return state
        .setIn(['calculators','qualifying', 'competitivePosition', 'loading'], true)
    case GET_COMPETITIVE_POSITION_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'competitivePosition', 'loading'], false)
        .setIn(['calculators','qualifying', 'competitivePosition', 'loaded'], true)
        .setIn(['calculators','qualifying', 'competitivePosition', 'data'], action.payload)
    case GET_COMPETITIVE_POSITION_ERROR:
      return state
        .setIn(['calculators','qualifying', 'competitivePosition', 'loading'], false)
    case GET_SUMMARY:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], true)
    case GET_SUMMARY_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], false)
        .setIn(['calculators','qualifying', 'summary', 'loaded'], true)
        .setIn(['calculators','qualifying', 'summary', 'data'], action.payload)
    case GET_SUMMARY_ERROR:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], false)
    case SET_SUMMARY:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], true)
    case SET_SUMMARY_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], false)
    case SET_SUMMARY_ERROR:
      return state
        .setIn(['calculators','qualifying', 'summary', 'loading'], false)
    case GET_SELF_ASSESSMENT_INPUT:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'loading'], true)
    case GET_SELF_ASSESSMENT_INPUT_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'loading'], false)
        .setIn(['calculators','qualifying', 'selfAssessment', 'loaded'], true)
        .setIn(['calculators','qualifying', 'selfAssessment', 'data'], action.payload)
    case GET_SELF_ASSESSMENT_INPUT_ERROR:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'loading'], false)
    case SET_SELF_ASSESSMENT_INPUT_SELF_SCORE:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'selfScoreLoading'], true)
    case SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'selfScoreLoading'], false)
        .updateIn(['calculators','qualifying', 'selfAssessment', 'data'], (data: any) => ({
          ...data,
          input: {
            ...data.input,
            grade: action.payload
          }
        }))
    case SET_SELF_ASSESSMENT_INPUT_SELF_SCORE_ERROR:
      return state
        .setIn(['calculators','qualifying', 'selfAssessment', 'selfScoreLoading'], false)
    case UPDATE_SELF_ASSESSMENT_INPUT_SELF_SCORE:
      return state
        .updateIn(['calculators','qualifying', 'selfAssessment', 'data'], (data: any) => {
          return {
            ...data,
            input: {
              ...data.input,
              criteria: data.input.criteria.map((el: SelfAssessmentInputCriteriaModel) => ({
                ...el,
                evaluation: {
                  ...el.evaluation,
                  value: action.payload[el.id]
                }
              }))
            }
          }
        })
    case GET_QUALIFY_PRIME:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'prime', 'loading'], true)
    case GET_QUALIFY_PRIME_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'prime', 'loading'], false)
        .setIn(['calculators','qualifying', 'pWin', 'prime', 'loaded'], true)
        .setIn(['calculators','qualifying', 'pWin', 'prime', 'data'], action.payload)
    case GET_QUALIFY_PRIME_ERROR:
      return state
    case GET_QUALIFY_PRIME_SBIR:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'loading'], true)
    case GET_QUALIFY_PRIME_SBIR_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'loading'], false)
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'loaded'], true)
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'data'], action.payload)
    case GET_QUALIFY_PRIME_SBIR_ERROR:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'loading'], false)
    case GET_QUALIFY_SUB:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'loading'], true)
    case GET_QUALIFY_SUB_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'loading'], false)
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'loaded'], true)
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'data'], action.payload)
    case GET_QUALIFY_SUB_ERROR:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'loading'], false)
    case UPDATE_QUALIFY_PRIME:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'prime', 'data'], (primeData: any) => ({...primeData, values: action.payload}))
    case UPDATE_QUALIFY_PRIME_SBIR:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'sbir', 'data'], (primeData: any) => ({...primeData, values: action.payload}))
    case SET_SCORE_QUALIFY_PRIME:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'prime', 'data'], (primeData: any) => ({...primeData, score: action.payload}))
    case SET_SCORE_QUALIFY_PRIME_SBIR:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'sbir', 'data'], (primeData: any) => ({...primeData, score: action.payload}))
    case UPDATE_QUALIFY_SUB:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'sub', 'data'], (subData: any) => ({...subData, values: action.payload}))
    case SET_SCORE_QUALIFY_SUB:
      return state
        .updateIn(['calculators','qualifying', 'pWin', 'sub', 'data'], (subData: any) => ({...subData, score: action.payload}))
    case DELETE_QUALIFY_SUB:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sub', 'data'], new QualifySubModel())
    case DELETE_QUALIFY_PRIME:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'prime', 'data'], new QualifyPrimeModel())
    case DELETE_QUALIFY_PRIME_SBIR:
      return state
        .setIn(['calculators','qualifying', 'pWin', 'sbir', 'data'], new QualifyPrimeSBIRModel())
    case GET_SUBBING:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], true)
    case GET_SUBBING_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], false)
        .setIn(['calculators','qualifying', 'subbing', 'loaded'], true)
        .setIn(['calculators','qualifying', 'subbing', 'data'], action.payload)
    case GET_SUBBING_ERROR:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], false)
    case SET_SUBBING:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], true)
    case SET_SUBBING_SUCCESS:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], false)
        .setIn(['calculators','qualifying', 'subbing', 'loaded'], true)
        .updateIn(['calculators','qualifying', 'subbing', 'data'], (data: any) => ({...data, input: {...data.input, grade: action.payload}}))
    case SET_SUBBING_ERROR:
      return state
        .setIn(['calculators','qualifying', 'subbing', 'loading'], false)
    case UPDATE_SUBBING:
      return state
        .updateIn(['calculators','qualifying', 'subbing', 'data'], (data: any) => ({...data, input: {...data.input, values: action.payload}}))
    case GET_HP_STRATEGY_WIN_THEMES:
      return state
        .setIn(['calculators', 'strategy', 'winThemes', 'loading'], true)
    case GET_HP_STRATEGY_WIN_THEMES_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'winThemes', 'loading'], false)
        .setIn(['calculators', 'strategy', 'winThemes', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'winThemes', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'winThemes', 'count'], action.payload.count)
    case GET_HP_STRATEGY_WIN_THEMES_ERROR:
      return state
        .setIn(['calculators','strategy', 'winThemes', 'loading'], false)
    case SET_HP_STRATEGY_WIN_THEMES:
      return state
        .setIn(['calculators','strategy', 'winThemes', 'loading'], true)
    case SET_HP_STRATEGY_WIN_THEMES_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'winThemes', 'loading'], false)
    case SET_HP_STRATEGY_WIN_THEMES_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'winThemes', 'loading'], false)
    case DELETE_HP_STRATEGY_ITEM:
      return state
        .updateIn(['calculators', 'strategy', action.payload.type, 'data'], (data: any) => data.filter((el: {id: string}) => el.id !== action.payload.id))
    case UPDATE_HP_STRATEGY_ITEM:
      return state
        .updateIn(['calculators', 'strategy', action.payload.type, 'data'], (data: any) => {
          const id = action.payload.data.id
          const findItemById = data.find((el: HpStrategyWinThemesModel) => el.id === id)
          if(findItemById) {
            return data.map((el: HpStrategyWinThemesModel) => el.id === id ? {
              ...el,
              ...action.payload.data
            } : el)
          } else {
            return [...data, {
              ...action.payload.data
            }]
          }
        })
    case GET_HP_STRATEGY_ACTIVITIES:
      return state
        .setIn(['calculators', 'strategy', 'activities', 'loading'], true)
    case GET_HP_STRATEGY_ACTIVITIES_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'activities', 'loading'], false)
        .setIn(['calculators', 'strategy', 'activities', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'activities', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'activities', 'count'], action.payload.count)
    case GET_HP_STRATEGY_ACTIVITIES_ERROR:
      return state
        .setIn(['calculators','strategy', 'activities', 'loading'], false)
    case SET_HP_STRATEGY_ACTIVITIES:
      return state
        .setIn(['calculators','strategy', 'activities', 'loading'], true)
    case SET_HP_STRATEGY_ACTIVITIES_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'activities', 'loading'], false)
    case SET_HP_STRATEGY_ACTIVITIES_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'activities', 'loading'], false)
    case GET_HP_STRATEGY_PAST_PERFORMANCE:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformance', 'loading'], true)
    case GET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformance', 'loading'], false)
        .setIn(['calculators', 'strategy', 'pastPerformance', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'pastPerformance', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'pastPerformance', 'count'], action.payload.count)
    case GET_HP_STRATEGY_PAST_PERFORMANCE_ERROR:
      return state
        .setIn(['calculators','strategy', 'pastPerformance', 'loading'], false)
    case SET_HP_STRATEGY_PAST_PERFORMANCE:
      return state
        .setIn(['calculators','strategy', 'pastPerformance', 'loading'], true)
    case SET_HP_STRATEGY_PAST_PERFORMANCE_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformance', 'loading'], false)
    case SET_HP_STRATEGY_PAST_PERFORMANCE_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformance', 'loading'], false)
    case GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'loading'], true)
    case GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'loading'], false)
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'count'], action.payload.count)
    case GET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR:
      return state
        .setIn(['calculators','strategy', 'pastPerformanceRecommendations', 'loading'], false)
    case SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS:
      return state
        .setIn(['calculators','strategy', 'pastPerformanceRecommendations', 'loading'], true)
    case SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'loading'], false)
    case SET_HP_STRATEGY_PAST_PERFORMANCE_RECOMMENDATIONS_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'pastPerformanceRecommendations', 'loading'], false)       
    case GET_HP_STRATEGY_EVALUATION_CRITERIA:
      return state
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'loading'], true)
    case GET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'loading'], false)
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'count'], action.payload.count)
    case GET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR:
      return state
        .setIn(['calculators','strategy', 'evaluationCriteria', 'loading'], false)
    case SET_HP_STRATEGY_EVALUATION_CRITERIA:
      return state
        .setIn(['calculators','strategy', 'evaluationCriteria', 'loading'], true)
    case SET_HP_STRATEGY_EVALUATION_CRITERIA_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'loading'], false)
    case SET_HP_STRATEGY_EVALUATION_CRITERIA_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'evaluationCriteria', 'loading'], false)
    case GET_HP_STRATEGY_PROCESS:
      return state
        .setIn(['calculators', 'strategy', 'process', 'loading'], true)
    case GET_HP_STRATEGY_PROCESS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'process', 'loading'], false)
        .setIn(['calculators', 'strategy', 'process', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'process', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'process', 'count'], action.payload.count)
    case GET_HP_STRATEGY_PROCESS_ERROR:
      return state
        .setIn(['calculators','strategy', 'process', 'loading'], false)
    case SET_HP_STRATEGY_PROCESS:
      return state
        .setIn(['calculators','strategy', 'process', 'loading'], true)
    case SET_HP_STRATEGY_PROCESS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'process', 'loading'], false)
    case SET_HP_STRATEGY_PROCESS_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'process', 'loading'], false)
    case GET_HP_STRATEGY_OPEN_QUESTIONS:
      return state
        .setIn(['calculators', 'strategy', 'openQuestions', 'loading'], true)
    case GET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'openQuestions', 'loading'], false)
        .setIn(['calculators', 'strategy', 'openQuestions', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'openQuestions', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'openQuestions', 'count'], action.payload.count)
    case GET_HP_STRATEGY_OPEN_QUESTIONS_ERROR:
      return state
        .setIn(['calculators','strategy', 'openQuestions', 'loading'], false)
    case SET_HP_STRATEGY_OPEN_QUESTIONS:
      return state
        .setIn(['calculators','strategy', 'openQuestions', 'loading'], true)
    case SET_HP_STRATEGY_OPEN_QUESTIONS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'openQuestions', 'loading'], false)
    case SET_HP_STRATEGY_OPEN_QUESTIONS_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'openQuestions', 'loading'], false)
    case GET_HP_STRATEGY_PROCESS_NAICS:
      return state
        .setIn(['calculators', 'strategy', 'processNaics', 'loading'], true)
    case GET_HP_STRATEGY_PROCESS_NAICS_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'processNaics', 'loading'], false)
        .setIn(['calculators', 'strategy', 'processNaics', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'processNaics', 'data'], action.payload)
    case GET_HP_STRATEGY_PROCESS_NAICS_ERROR:
      return state
        .setIn(['calculators','strategy', 'processNaics', 'loading'], false)
    case GET_HP_STRATEGY_PROCESS_SET_ASIDE:
      return state
        .setIn(['calculators', 'strategy', 'processSetAside', 'loading'], true)
    case GET_HP_STRATEGY_PROCESS_SET_ASIDE_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'processSetAside', 'loading'], false)
        .setIn(['calculators', 'strategy', 'processSetAside', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'processSetAside', 'data'], action.payload)
    case GET_HP_STRATEGY_PROCESS_SET_ASIDE_ERROR:
      return state
        .setIn(['calculators','strategy', 'processSetAside', 'loading'], false)
    
    case GET_HP_STRATEGY_SUMMARY:
      return state
        .setIn(['calculators', 'strategy', 'summary', 'loading'], true)
    case GET_HP_STRATEGY_SUMMARY_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'summary', 'loading'], false)
        .setIn(['calculators', 'strategy', 'summary', 'loaded'], true)
        .setIn(['calculators', 'strategy', 'summary', 'data'], action.payload.data)
        .setIn(['calculators', 'strategy', 'summary', 'count'], action.payload.count)
    case GET_HP_STRATEGY_SUMMARY_ERROR:
      return state
        .setIn(['calculators','strategy', 'summary', 'loading'], false)
    case SET_HP_STRATEGY_SUMMARY:
      return state
        .setIn(['calculators','strategy', 'summary', 'loading'], true)
    case SET_HP_STRATEGY_SUMMARY_SUCCESS:
      return state
        .setIn(['calculators', 'strategy', 'summary', 'loading'], false)
    case SET_HP_STRATEGY_SUMMARY_ERROR:
      return state
        .setIn(['calculators', 'strategy', 'summary', 'loading'], false)
    case GET_RESPONSE_OUTLINE:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], true)
    case GET_RESPONSE_OUTLINE_SUCCESS:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], false)
        .setIn(['calculators','response', 'outline', 'loaded'], true)
        .setIn(['calculators','response', 'outline', 'data'], action.payload)
    case GET_RESPONSE_OUTLINE_ERROR:
      return state
        .setIn(['calculators','response', 'outline',  'loading'], false)
    case CREATE_RESPONSE_OUTLINE:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], true)
    case CREATE_RESPONSE_OUTLINE_SUCCESS:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], false)
        .updateIn(['calculators','response', 'outline', 'data'], (data: any) => [...data, action.payload])
    case CREATE_RESPONSE_OUTLINE_ERROR:
      return state
        .setIn(['calculators','response', 'outline',  'loading'], false)
    case DELETE_RESPONSE_OUTLINE:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], true)
    case DELETE_RESPONSE_OUTLINE_SUCCESS:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], false)
        .updateIn(['calculators','response', 'outline', 'data'], (data: any) => data.filter((el: NoteModel) => !action.payload.includes(el.id)))
    case DELETE_RESPONSE_OUTLINE_ERROR:
      return state
        .setIn(['calculators','response', 'outline',  'loading'], false)
    case UPDATE_RESPONSE_OUTLINE:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], true)
    case UPDATE_RESPONSE_OUTLINE_SUCCESS:
      return state
        .setIn(['calculators','response', 'outline', 'loading'], false)
        .updateIn(['calculators','response', 'outline', 'data'], (data: any) => {
          const notesId = action.payload.map((el: Note) => el.id)
          return data.map((el: Note) => {
            if(notesId.includes(el.id)) {
              const findUpdatedNote = action.payload.find(({id}: Note) => id === el.id)
              return {
                ...findUpdatedNote
              }
            }
            return el
          })
        })
    case UPDATE_RESPONSE_OUTLINE_ERROR:
      return state
        .setIn(['calculators','response', 'outline',  'loading'], false)
    case GET_RESPONSE_COMPLIANCE:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], true)
    case GET_RESPONSE_COMPLIANCE_SUCCESS:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], false)
        .setIn(['calculators','response', 'compliance', 'loaded'], true)
        .setIn(['calculators','response', 'compliance', 'data'], action.payload)
    case GET_RESPONSE_COMPLIANCE_ERROR:
      return state
        .setIn(['calculators','response', 'compliance',  'loading'], false)
    case CREATE_RESPONSE_COMPLIANCE:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], true)
    case CREATE_RESPONSE_COMPLIANCE_SUCCESS:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], false)
        .updateIn(['calculators','response', 'compliance', 'data'], (data: any) => [...data, action.payload])
    case CREATE_RESPONSE_COMPLIANCE_ERROR:
      return state
        .setIn(['calculators','response', 'compliance',  'loading'], false)
    case DELETE_RESPONSE_COMPLIANCE:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], true)
    case DELETE_RESPONSE_COMPLIANCE_SUCCESS:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], false)
        .updateIn(['calculators','response', 'compliance', 'data'], (data: any) => data.filter((el: NoteModel) => !action.payload.includes(el.id)))
    case DELETE_RESPONSE_COMPLIANCE_ERROR:
      return state
        .setIn(['calculators','response', 'compliance',  'loading'], false)
    case UPDATE_RESPONSE_COMPLIANCE:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], true)
    case UPDATE_RESPONSE_COMPLIANCE_SUCCESS:
      return state
        .setIn(['calculators','response', 'compliance', 'loading'], false)
        .updateIn(['calculators','response', 'compliance', 'data'], (data: any) => {
          const notesId = action.payload.map((el: Note) => el.id)
          return data.map((el: Note) => {
            if(notesId.includes(el.id)) {
              const findUpdatedNote = action.payload.find(({id}: Note) => id === el.id)
              return {
                ...findUpdatedNote
              }
            }
            return el
          })
        })
    case UPDATE_RESPONSE_COMPLIANCE_ERROR:
      return state
        .setIn(['calculators','response', 'compliance',  'loading'], false)
    default:
      return state
  }
}
