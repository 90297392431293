import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const userBaseSelector = (state: IRootState) => state.user

export const userBaseResponseSelector = createSelector(
  userBaseSelector,
  userBase => userBase.get('data')
)

export const userSelector = createSelector(
  userBaseSelector,
  userBase => userBase.getIn(['data', 'data'])
)

export const userErrorSelector = createSelector(
  userBaseSelector,
  userBase => userBase.getIn(['data', 'errors'])
)

export const userPaymentPlanSelector = createSelector(
  userSelector,
  (user: any) => user && user.paymentPlan ? user.paymentPlan.plan : null
)

export const userSettingsSelector = createSelector(
  userBaseSelector,
  (userBase) => userBase.getIn(['settings', 'data'])
)

export const getUserIncumbentGroupSelector = createSelector(
  userBaseSelector,
  (userBase) => userBase.getIn(['incumbentGroup', 'data'])
)

export const userLazyAccountSelector = createSelector(
  userBaseSelector,
  userBase => userBase.get('userLazyAccountPromptCondition')
)
