import React, { useState } from 'react'
import styles from '../index.module.scss'
import { Button, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import SvgIcon from 'components/SvgIcon'
import { useDispatch } from 'react-redux'
import { useNotification } from 'context/NotificationContext'
import { uploadFile, getContactsChart, clearContactsList } from 'store/hp/education/action'
import { getErrorMessage } from 'helpers/errors'
import { NotificationTypeList } from 'components/NotificationType'
import { EDUCATION_FUNDING_IFRAME_SOURCE, EDUCATION_FUNDING_SUBTITLE } from 'utils/constants/education-funding'

const RelashionshipUploader = () => {
  const dispatch = useDispatch()
  const notification = useNotification()

  const [isLaoding, setLoading] = useState<boolean>(false)

  const onFileChange = async (e: { target: { files: any } }) => {
    let file = e.target.files![0]
    const formData = new FormData()
    formData.append('file', file)
    setLoading(true)
    uploadFile(formData)
      .then(() => {
        setLoading(false)
        clearContactsList(dispatch)
        getContactsChart(dispatch)
        
      })
      .catch(e => {
        setLoading(false)
        notification.show(getErrorMessage(e), NotificationTypeList.Error)
      })
  }

  const handleClasses = () => {
    window.open(EDUCATION_FUNDING_IFRAME_SOURCE[EDUCATION_FUNDING_SUBTITLE.CLASSES], '_blank')
  }
  
  return (
    <Paper className={styles['relashionShip-mapping']}>
      <Typography variant='subtitle1' className={styles['relashionShip-mapping-title']}>Relationship mapping</Typography>
      {
        !isLaoding && (
          <Grid container direction='column' alignItems='center' justifyContent='center' className={styles['download-block']}>
            <IconButton color='primary' aria-label='upload csv' component='label'>
              <input hidden accept='.csv' type='file' onChange={onFileChange} />
              <Grid item className={styles['download-icon']}>
                <SvgIcon name='download-alt' width={35} height={35}/>
              </Grid>
            </IconButton>
            <Grid item>
              <Typography variant='subtitle1' color='primary'>Upload LinkedIn Relationships</Typography>
            </Grid>
          </Grid>
        )
      }
      {
        isLaoding && (
          <Grid className={styles['file-uploader-loader']} container alignItems='center' justifyContent='center'>
            <CircularProgress size={30}/>
          </Grid>
        )
      }
      <Typography variant='subtitle2' className={styles['relashionShip-mapping-classes']}>Class on LinkedIn based relationship mapping</Typography>
      <Grid container alignItems='center' justifyContent='center' className={styles['btn-block']}>
        <Button onClick={handleClasses} variant='contained'>Classes</Button>
      </Grid>
    </Paper>
  )
}

export default RelashionshipUploader