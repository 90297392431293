import SvgIcon from 'components/SvgIcon'
import React from 'react'
import styles from './index.module.scss'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { IUserBaseModel } from 'models/common/user/user.interfaces'

interface NavigationProps {
  onBack: () => void
  user?: IUserBaseModel
}

const Navigation = ({onBack, user}: NavigationProps) => {
  const history = useHistory()
  const splitLocation = history.location.pathname.split('/')
  const currentPageName = splitLocation[2]

  return (
    <div className={styles['nav-wrapper']}>
      <SvgIcon name={'navigation-menu'} width={24} height={24} />
      <div className={styles['navigator-wrapper']}>
        <SvgIcon 
          className={classNames(styles['navigator-arrow'])} 
          name={'arrow-left'} 
          width={22} 
          height={22}
          onClick={onBack}
        />
        <SvgIcon 
          className={classNames(styles['navigator-arrow'], styles['navigator-arrow-right'])} 
          name={'arrow-left'} 
          width={22} 
          height={22}
          onClick={history.goForward}
        />
      </div>
      <div className={styles['breadcrumbs-wrapper']}>
        <Breadcrumbs separator={
          <span className={styles['breadcrumbs-separator']}>/</span>
        } aria-label='breadcrumb'>
          <Link className={styles['breadcrumbs-item']} to='/'>
            Main
          </Link>
          <Link className={styles['breadcrumbs-item']} to='/pipeline/opportunities'>
            Pipline
          </Link>
          <Typography className={styles['breadcrumbs-item']}>
            {currentPageName[0].toUpperCase() + currentPageName.slice(1)}
          </Typography>
        </Breadcrumbs>
      </div>
    </div>
  )
}

export default Navigation