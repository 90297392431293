import React, { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import Contracts from '../Contracts/Contracts'
import TimeTable from '../TimeLabel/TimeTable'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import Skeletons from '../Skeletons'
import moment from 'moment'
import classNames from 'classnames'
import { DateType } from '../../index'

export interface ITimeRange {
  fromSelectMonth: number
  fromSelectYear: string
  toSelectMonth: number
  toSelectYear: string
}

export interface GanttChartProps {
  data: IPiplineTimeline[]
  isLoading: boolean
  dateType: DateType
  page: number
}

const GanttChart = ({ data, isLoading, dateType, page }: GanttChartProps) => {
  const [timeRange, setTimeRange] = useState<ITimeRange | null>(null)
  const [currentContract, setCurrentContract] = useState<IPiplineTimeline | null>(null)

  useEffect(() => {
    if (data.length) {
      getMaxMinDate()
    }
  }, [data])

  const getMaxMinDate = () => {
    const filterData = data.filter(el => el.dates.length)
    const minDate = moment.min(filterData.map(el => moment(el.dates[0].start)))
    const maxDate = moment.max(filterData.map(el => moment(el.dates[el.dates.length - 1].end)))

    const newTimeRange = {
      fromSelectMonth: minDate.month(),
      fromSelectYear: String(minDate.year()),
      toSelectMonth: maxDate.month(),
      toSelectYear: String(maxDate.year()),
    }

    setTimeRange(newTimeRange)
  }

  const renderTimeTable = useMemo(() => {
    return timeRange &&
      <TimeTable
        timeRange={timeRange}
        data={data}
        dateType={dateType}
      />
  }, [timeRange, dateType])

  if (isLoading) {
    return <Skeletons />
  }

  return (
    <div className={styles['gantt-container']} id="gantt-container">
      <div className={styles['gantt-grid-container']}>
        <Contracts
          data={data}
          currentContract={currentContract}
          setCurrentContract={setCurrentContract}
          page={page}
        />
        {<div className={classNames(styles['time-table-overlay'], {
          [styles['time-table-overlay-active']]: currentContract
        })} />}
        {renderTimeTable}
      </div>
    </div>
  )
}

export default GanttChart
