import React from 'react'
import { Select as MuiSelect, MenuItem, SelectProps, FormControl, InputLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SvgIcon from 'components/SvgIcon'
import styles from './index.module.scss'

export interface Option {
  key: string
  value: string | number
}
interface Props extends SelectProps {
  label: string
  options: Option[]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    borderRadius: '12px !important',
    border: `2px solid ${theme.palette.grey['300']}`
  }
}))

const Select = ({options, label, ...props}: Props) => {
  const classes = useStyles()
  return (
    <FormControl
      variant='filled'
      color='primary'
      fullWidth
      className={styles['select']}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...props}
        disableUnderline
        classes={classes}
        className={styles['input']}
        IconComponent={() => <SvgIcon name='angle-down' className='MuiSelect-icon MuiSelect-iconFilled' />}
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>{option.key}</MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select