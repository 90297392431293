import { IRootState } from '../../reducers'
import { createSelector } from 'reselect'
import { IFilterOption } from 'models/hp/search/search.interface'

export const groupedCodes = (state: IRootState) => state.groupedCodes
export const groupedCodesLoadingSelector = createSelector(
  groupedCodes,
  (groupedCodes) => groupedCodes.get('loading')
)
export const groupedCodesLoadedSelector = createSelector(
  groupedCodes,
  (groupedCodes) => groupedCodes.get('loaded')
)
export const naicsCodesSelector = createSelector(
  groupedCodes,
  (groupedCodes) => groupedCodes.getIn(['data', 'naicsCodes'])
)

export const naicsCodeCategorySelector = (id: string | number | null) => createSelector(
  groupedCodes,
  (groupedCodes) => (groupedCodes.getIn(['data', 'naicsCodes']) as IFilterOption[]).find(x => x.id === id)
)

export const naicsCodeCountSelector = createSelector(
  groupedCodes,
  groupedCodes => groupedCodes.getIn(['data', 'naicsCount'])
)

export const pscCodesSelector = createSelector(
  groupedCodes,
  (groupedCodes) => groupedCodes.getIn(['data', 'pscCodes'])
)

export const pscCodeCountSelector = createSelector(
  groupedCodes,
  groupedCodes => groupedCodes.getIn(['data', 'pscCount'])
)

export const pscCodeCategorySelector = (id: string | number | null) => createSelector(
  groupedCodes,
  (groupedCodes) => (groupedCodes.getIn(['data', 'pscCodes']) as IFilterOption[]).find(x => x.id === id)
)
