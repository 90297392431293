import { CompanyWithGroup, MarketSimilarCompanyGroup } from 'models/hp/companies/companies.model'
import { CompanyProfileInlineItem } from 'models/hp/company-profiles/base/company-profile.model'

export const getCompaniesWithGroups = (group: MarketSimilarCompanyGroup): CompanyWithGroup[] => {
  return group.companies.map(company => {
    const companyWithGroup = new CompanyWithGroup()
    companyWithGroup.companyName = company.companyName
    companyWithGroup.companyDuns = company.duns
    companyWithGroup.groupName = group.groupName
    companyWithGroup.groupId = group.id
    return companyWithGroup
  })
}

export const getFoundCompaniesFlat = (items: CompanyProfileInlineItem[], baseItems: CompanyWithGroup[]): CompanyWithGroup[] => {
  const mapper: {[key: string]: true} = baseItems.reduce((acc, item) => {
    acc[item.companyDuns] = true
    return acc
  }, {} as any)

  return items.reduce((acc, item) => {
    if (!mapper[item.duns]) {
      acc.push({
        companyDuns: item.duns,
        companyName: item.name,
        groupId: '',
        groupName: 'Companies found'
      })
    }
    return acc
  }, [] as CompanyWithGroup[])
}