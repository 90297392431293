import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Button, Dialog, LinearProgress, Theme, Typography, createStyles, withStyles } from '@material-ui/core'
import { IPiplineTimeline } from 'models/hp/pipeline/pipeline.interface'
import SvgIcon from 'components/SvgIcon'
import classNames from 'classnames'
import { getFormattedTextDate } from 'helpers/date'
import ButtonLink from 'components/v3/ButtonLink'
import { useDispatch } from 'react-redux'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'
import { removeRfpCategorization } from 'store/hp/rfp/action'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'
import { CategorizedHpRfpModel, HpRfpModel } from 'models/hp/rfp/rfp.model'
import { IHpRfpDetailModel } from 'models/hp/rfp/rfp.interface'
import { getPiplineTimeline } from 'store/hp/pipeline/rfp/action'
import { getDetailsLink } from 'helpers/rfp'
import { numberToStrWithRound } from 'common/number'

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.success.dark,
    },
  }),
)(LinearProgress)

const DeleteButton = withStyles((theme: Theme) => ({
  outlined: {
    color: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button)

const PrimaryBtn = withStyles((theme: Theme) => ({
  outlined: {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`
  },
}))(Button)

interface ContractDetailsProps {
    contract: IPiplineTimeline | null
    setContractDetails: (value: IPiplineTimeline | null) => void
}

const ContractDetails = ({contract, setContractDetails}: ContractDetailsProps) => {
  const dispatch = useDispatch()
  const notification = useRef(useNotification())
  const [isActive, setActive] = useState<boolean>(false)
  const [isViewDeleteDialog, setViewDeleteDialog] = useState<boolean>(false)

  useEffect(() => {
    if(contract) {
      setActive(true)
    }
  }, [contract])

  const handleCloseDetails = () => {
    setActive(false)
    setContractDetails(null)
  }

  const handleDeleteContract = async () => {
    try {
      await removeRfpCategorization(dispatch)(contract as unknown as  RfpTinderModel | HpRfpModel | CategorizedHpRfpModel | IHpRfpDetailModel)
      notification.current.show('Contract moved to Trash', NotificationTypeList.Success)
      setViewDeleteDialog(false)
      handleCloseDetails()
      getPiplineTimeline(dispatch)()
    } catch (e) {
      const msg = getErrorMessage(e)
      notification.current.show(msg, NotificationTypeList.Error)
    }
  }

  const handleRedirectToContract = () => {
    window.open(getDetailsLink(contract as unknown as HpRfpModel, 'contracts'), '_blank')
  }

  return (
    <div className={classNames(styles['gant-task-contract-details-wrapper'], {
      [styles['gant-task-contract-details-wrapper-active']]: isActive
    })}>
      {contract ? (
        <>
          <div className={styles['gant-task-contract-details']}>
            <div className={styles['gant-task-contract-details-header']}>
              <Typography className={styles['gant-task-contract-details-header-title']} onClick={handleRedirectToContract}>{contract.title}</Typography>
              <SvgIcon name='multiply' onClick={handleCloseDetails} className={styles['gant-task-contract-details-header-hide-btn']} />
            </div>
            <div className={styles['gant-task-contract-details-department']}>
              <SvgIcon name='contract' />
              <Typography className={styles['gant-task-contract-details-department-title']}>{contract.department || '-'}</Typography>
            </div>
            <div className={styles['gant-task-contract-details-dates']}>
              <div className={styles['gant-task-contract-details-info-item']}>
                <Typography className={styles['gant-task-contract-details-info-label']}>Start</Typography>
                <Typography className={styles['gant-task-contract-details-info-value']}>{getFormattedTextDate(contract.postedDate, 'MMM DD YYYY')}</Typography>
              </div>
              <div className={styles['gant-task-contract-details-info-item']}>
                <Typography className={styles['gant-task-contract-details-info-label']}>Due</Typography>
                <Typography className={styles['gant-task-contract-details-info-value']}>{getFormattedTextDate(contract.responseDeadLine, 'MMM DD YYYY')}</Typography>
              </div>
            </div>
            <div className={styles['gant-task-contract-details-value']}>
              <div className={styles['gant-task-contract-details-info-item']}>
                <Typography className={styles['gant-task-contract-details-info-label']}>Value</Typography>
                <Typography className={styles['gant-task-contract-details-info-value']}>{contract.calc.estimatedValue ? numberToStrWithRound(Number(contract.calc.estimatedValue)) : '-'}</Typography>
              </div>
            </div>
            <div className={styles['gant-task-contract-details-value']}>
              <div className={styles['gant-task-contract-details-info-item']}>
                <Typography className={styles['gant-task-contract-details-info-label']}>Progress</Typography>
                {
                  contract.calc.pWin ? (
                    <div className={styles['gant-task-contract-details-info-progress']}>
                      <Typography className={styles['gant-task-contract-details-info-value']}>{contract.calc.pWin}%</Typography>
                      <BorderLinearProgress className={styles['gant-task-contract-details-info-progress-bar']} variant="determinate" value={contract.calc.pWin} />
                    </div>
                  ) : '-'
                }
              </div>
            </div>
            <div className={styles['gant-task-contract-details-value']}>
              <div className={styles['gant-task-contract-details-info-item']}>
                <Typography className={styles['gant-task-contract-details-info-label']}>Description</Typography>
                <Typography className={styles['gant-task-contract-details-info-value']}>{'-'}</Typography>
              </div>
            </div>
            <div className={styles['gant-task-contract-details-controls']}>
              <ButtonLink title='Delete' theme='error' iconName='trash' onClick={() => setViewDeleteDialog(true)} />
              <ButtonLink title='Go to Contract' iconName='go-to-project' onClick={handleRedirectToContract} />
            </div>
          </div>
          <Dialog open={isViewDeleteDialog} onClose={() => setViewDeleteDialog(false)} maxWidth={'xl'}>
            <div className={styles['dialog-wrapper']}>
              <SvgIcon name='multiply' onClick={() => setViewDeleteDialog(false)} className={styles['dialog-hide-btn']} />
              <div className={styles['dialog-header']}>
                <Typography className={styles['dialog-header-title']}>Delete Project form the List</Typography>
              </div>
              <Typography className={styles['dialog-description']}>Are you sure you want to delete {contract.title} from your Pipeline?</Typography>
              <div className={styles['dialog-controls']}>
                <PrimaryBtn variant='outlined' onClick={() => setViewDeleteDialog(false)} className={styles['dialog-controls-btn']}>No</PrimaryBtn>
                <DeleteButton variant='outlined' onClick={handleDeleteContract} className={styles['dialog-controls-btn']}>Yes, Delete</DeleteButton>
              </div>
            </div>
          </Dialog>
        </>
      ): null}
    </div>
  )
}

export default ContractDetails