import React from 'react'
import { Typography } from '@material-ui/core'
import Modal, { Props as ModalProps } from 'components/v2/Modal'
import Divider from 'components/v2/Divider'

interface Props extends ModalProps {}

const PublicEmailModal = (props: Props) => {
  return (
    <Modal
      {...props}
      title='Email Address Notice'
    >
      <Typography variant='h5'>If you use a personal email address we can’t connect you to other people in your company and you’ll miss out on:</Typography>
      <ul>
        <li><Typography>Chat</Typography></li>
        <li><Typography>Team liked lists</Typography></li>
        <li><Typography>Other collaboration features.</Typography></li>
      </ul>
      <Typography>So we strongly recommend using your corporate email</Typography>
      <Divider gutterTop gutterBottom light />
      <Typography color='primary'>Examples of personal emails:</Typography>
      <ul>
        <li><Typography>your.name<Typography variant='inherit' color='error'>@gmail.com</Typography></Typography></li>
        <li><Typography>your.name<Typography variant='inherit' color='error'>@outlook.com</Typography> </Typography></li>
        <li><Typography gutterBottom>...</Typography></li>
      </ul>
      <Typography color='primary'>Examples of company emails:</Typography>
      <ul>
        <li><Typography>your.name<Typography variant='inherit' color='primary'>@company.com</Typography></Typography></li>
      </ul>
    </Modal>
  )
}

export default PublicEmailModal