import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { BaseResponseModel } from 'models/base/base.model'
import { RfpTinderModel } from 'models/hp/rfp/tinder.model'
import { TaskAnswer } from 'models/hp/pipeline-task/pipeline-task.model'
import { IPiplineCardsResponseModel, IPiplineHealthResponseModel, IPiplinePendingAwardsResponseModel, IPiplineRevenueResponseModel, IPiplineTimelineResponseModel, PipelineQuestionTypes, pipelineStageMapper } from 'models/hp/pipeline/pipeline.interface'
import { UserBaseModel } from 'models/common/user/user.model'
import { getFormattedTextDate } from 'helpers/date'


@jsonObject
export class PipelineNumaratorItem {
  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  count: number = 0

  @jsonMember({ constructor: Number, deserializer: (val) => Number(val) })
  denominator: number = 3

}

@jsonObject
export class PipelineItem {
  @jsonMember({ constructor: PipelineNumaratorItem })
  quarter: PipelineNumaratorItem = new PipelineNumaratorItem()

  @jsonMember({ constructor: PipelineNumaratorItem })
  year: PipelineNumaratorItem = new PipelineNumaratorItem()

  @jsonMember({ constructor: PipelineNumaratorItem })
  longTerm: PipelineNumaratorItem = new PipelineNumaratorItem()
}

@jsonObject
export class PipelineRfpStep {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: Number})
  step: number = -1

  @jsonMember({constructor: Date})
  createdAt: Date = new Date()

  @jsonMember({constructor: UserBaseModel})
  user: UserBaseModel = new UserBaseModel()
}

@jsonObject
export class PipelineQuestionItem {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  type: PipelineQuestionTypes | undefined = undefined

  @jsonMember({constructor: String})
  text: string = ''

  @jsonMember({constructor: Number})
  parentId: number | null = null

  @jsonArrayMember(TaskAnswer)
  answer: TaskAnswer[] = []

  @jsonArrayMember(String)
  options: string[] | null = null
}

export class SortedPipelineQuestionItem extends PipelineQuestionItem {
  sub?: SortedPipelineQuestionItem[] = []
}

@jsonObject
export class PipelineQuestionsResponseModel extends BaseResponseModel {
  @jsonArrayMember(PipelineQuestionItem)
  data: PipelineQuestionItem[] = []
}

@jsonObject
export class PipelineResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: PipelineItem })
  data: PipelineItem = new PipelineItem()
}

@jsonObject
export class PipelineBoardListResponseModel extends BaseResponseModel {
  @jsonArrayMember(RfpTinderModel)
  data: RfpTinderModel[] = []
}

@jsonObject
export class PipelineRfpStepsResponseModel extends BaseResponseModel {
  @jsonArrayMember(PipelineRfpStep)
  data: PipelineRfpStep[] = []
}

@jsonObject
export class PiplineTimelineCalcModel {
  @jsonMember({constructor: Number})
  pWin: number | null = null

  @jsonMember({constructor: Number})
  estimatedValue: number | null = null
}

@jsonObject
export class PiplineTimelineDateModel {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: Date, deserializer: (val: Date) => getFormattedTextDate(val)})
  start: Date = new Date()

  @jsonMember({constructor: Date, deserializer: (val: Date) => getFormattedTextDate(val)})
  end: Date = new Date()
}

@jsonObject
export class PiplineTimelineAssinedUserModel {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  email: string = ''

  @jsonMember({constructor: String})
  firstName: string = ''

  @jsonMember({constructor: String})
  lastName: string = ''

  @jsonMember({constructor: String})
  role: string = ''

  @jsonMember({constructor: String})
  status: string = ''
}

@jsonObject
export class PiplineTimelineModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  title: string = ''

  @jsonMember({constructor: String})
  department: string = ''

  @jsonMember({constructor: String})
  subTier: string = ''

  @jsonMember({constructor: String})
  solicitationNumber: string = ''

  @jsonMember({constructor: String})
  type: string = ''

  @jsonMember({constructor: Date})
  responseDeadLine: Date = new Date()

  @jsonMember({constructor: Date})
  postedDate: Date = new Date()

  @jsonMember({constructor: PiplineTimelineCalcModel})
  calc: PiplineTimelineCalcModel = new PiplineTimelineCalcModel()

  @jsonArrayMember(PiplineTimelineDateModel)
  dates: PiplineTimelineDateModel[] = []

  @jsonMember({constructor: PiplineTimelineAssinedUserModel})
  assignedUser: PiplineTimelineAssinedUserModel = new PiplineTimelineAssinedUserModel()
}

@jsonObject
export class PiplineTimelineResponseModel extends BaseResponseModel implements IPiplineTimelineResponseModel {
  @jsonMember({constructor: Number})
  count: number = 0

  @jsonArrayMember(PiplineTimelineModel)
  data: PiplineTimelineModel[] = []
}

@jsonObject
export class PiplineHealthModel {
  @jsonMember({constructor: Number})
  id: number = 0
  
  @jsonMember({constructor: Number})
  default: number = 0

  @jsonMember({constructor: Number})
  value: number = 0

  @jsonMember({constructor: Number})
  percentage: number = 0
}

@jsonObject
export class PiplineHealthResponseModel extends BaseResponseModel implements IPiplineHealthResponseModel {
  @jsonArrayMember(PiplineHealthModel)
  data: PiplineHealthModel[] = []
}

@jsonObject
export class PiplineRevenueModel {
  @jsonMember({constructor: String})
  year: string = ''

  @jsonMember({constructor: Number})
  value: number = 0
}

@jsonObject
export class PiplineRevenueResponseModel extends BaseResponseModel implements IPiplineRevenueResponseModel {
  @jsonArrayMember(PiplineRevenueModel)
  data: PiplineRevenueModel[] = []
}

@jsonObject
export class PiplinePendingAwardAnalysisModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  outcome: string = ''

  @jsonArrayMember(String)
  description: string[] = []
}


@jsonObject
export class PiplinePendingAwardModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  solicitationNumber: string = ''

  @jsonMember({constructor: String})
  title: string = ''

  @jsonMember({constructor: String})
  type: string = ''

  @jsonMember({constructor: String})
  typeOfSetAside: string = ''

  @jsonMember({constructor: Boolean})
  deadlineCalculated: boolean = false

  @jsonMember({constructor: String})
  classificationCode: string = ''

  @jsonMember({constructor: PiplinePendingAwardAnalysisModel})
  pipelineAnalysis: PiplinePendingAwardAnalysisModel | null = null

  @jsonMember({constructor: String})
  awardDate: string = ''
}


@jsonObject
export class PiplinePendingAwardsResponseModel extends BaseResponseModel implements IPiplinePendingAwardsResponseModel {
  @jsonMember({constructor: Number})
  count: number = 0

  @jsonArrayMember(PiplinePendingAwardModel)
  data: PiplinePendingAwardModel[] = []
}

@jsonObject
export class PipelineCardUserModel {
  @jsonMember({constructor: Number})
  id: number = 0

  @jsonMember({constructor: String})
  email: string = ''

  @jsonMember({constructor: String})
  firstName: string = ''

  @jsonMember({constructor: String})
  lastName: string = ''
}



@jsonObject
export class PipelineCardModel {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  type: string = ''

  @jsonMember({constructor: String})
  title: string = ''

  @jsonMember({constructor: String})
  section: string = ''

  @jsonMember({constructor: Number, deserializer: (stage: number) => pipelineStageMapper[stage]})
  stage: number = 0

  @jsonMember({constructor: Date})
  updatedAt: Date = new Date()

  @jsonMember({constructor: Date})
  dueDate: Date = new Date()

  @jsonMember({constructor: PipelineCardUserModel})
  user: PipelineCardUserModel = new PipelineCardUserModel()
}


@jsonObject
export class PiplineCardsResponseModel extends BaseResponseModel implements IPiplineCardsResponseModel {
  @jsonArrayMember(PipelineCardModel)
  data: PipelineCardModel[] = []
}