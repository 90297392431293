import { createSelector } from 'reselect'
import { IRootState } from 'store/reducers'

const educationBaseSelector = (state: IRootState) => state.education

export const selectedEducationPhaseClassSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('currentPhaseClass')
)

export const educationPhaseProgressSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('progressData').toJS()
)

export const contactsChartSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('contactsChart')
)

export const contactsListtSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('contactsList')
)

export const customerFinderDashboardSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('customerFinderDashboard')
)

export const customerFinderContactsSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('customerFinderContacts')
)

export const partnersFinderDashboardSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('partnersFinderDashboard')
)

export const partnersFinderContactsSelector = createSelector(
  educationBaseSelector,
  (education) => education.get('partnersFinderContacts')
)