import * as React from 'react'
import styles from './index.module.scss'
import { LinearProgress, useTheme, makeStyles } from '@material-ui/core'

interface Props {
  value: number
  color: string
  label?: string
}

const ProgressBar = ({ value, label, color }: Props) => {
  const theme = useTheme()

  const useStyles = makeStyles({
    root: {
      height: 25,
      borderRadius: 4,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
      border: 0
    },
    bar: {
      borderRadius: 4,
      backgroundColor: color === 'primary' ? theme.palette.primary.light : theme.palette.success.main,
    },
  })

  const classes = useStyles()

  return (
    <div className={styles['container']}>
      {label && (
        <div className={styles['label']}><b>{label}</b></div>
      )}
      <LinearProgress
        variant='determinate'
        value={value}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar
        }}
      />
    </div>
  )
}

export default ProgressBar