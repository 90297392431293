import { Dispatch } from 'redux'
import { request } from 'utils/http'
import { GroupedCodeResponseModel } from 'models/hp/grouped-code/grouped-code.model'
import { RequestMethods } from 'utils/const'
import Paths from 'utils/paths'
import {
  CompanyGovConModelResponse,
  CompanyGovConSizeModelResponse
} from 'models/hp/company-profiles/gov-con/gov-con.model'
import { CompanySetAsideResponse } from 'models/hp/company-profiles/set-aside/set-aside.model'
import { VehicleResponseModel } from 'models/hp/company-profiles/vehicle/vehicle.model'
import { setOptionCount } from 'common/filter'

// Customers
export const COMPANY_GROUPED_CUSTOMERS = 'COMPANY_GROUPED_CUSTOMERS'
export const COMPANY_GROUPED_CUSTOMERS_SUCCESS = 'COMPANY_GROUPED_CUSTOMERS_SUCCESS'
export const COMPANY_GROUPED_CUSTOMERS_ERROR = 'COMPANY_GROUPED_CUSTOMERS_ERROR'
export const COMPANY_GROUPED_CUSTOMERS_COUNT = 'COMPANY_GROUPED_CUSTOMERS_COUNT'

// Gov cons
export const COMPANY_GOV_CON = 'COMPANY_GOV_CONS'
export const COMPANY_GOV_CON_SUCCESS = 'COMPANY_GOV_CONS_SUCCESS'
export const COMPANY_GOV_CON_ERROR = 'COMPANY_GOV_CONS_ERROR'

// Gov con size
export const COMPANY_GOV_CON_SIZE = 'COMPANY_GOV_CON_SIZE'
export const COMPANY_GOV_CON_SIZE_SUCCESS = 'COMPANY_GOV_CON_SIZE_SUCCESS'
export const COMPANY_GOV_CON_SIZE_ERROR = 'COMPANY_GOV_CON_SIZE_ERROR'

// Set aside
export const COMPANY_SET_ASIDE = 'COMPANY_SET_ASIDE'
export const COMPANY_SET_ASIDE_SUCCESS = 'COMPANY_SET_ASIDE_SUCCESS'
export const COMPANY_SET_ASIDE_ERROR = 'COMPANY_SET_ASIDE_ERROR'

//Vehicles
export const COMPANY_VEHICLE = 'COMPANY_VEHICLE'
export const COMPANY_VEHICLE_SUCCESS = 'COMPANY_VEHICLE_SUCCESS'
export const COMPANY_VEHICLE_ERROR = 'COMPANY_VEHICLE_ERROR'

export const getCompanyGroupedCustomersAction = (dispatch: Dispatch) => async (): Promise<void> => {
  try {
    dispatch({
      type: COMPANY_GROUPED_CUSTOMERS
    })
    const response = await request<GroupedCodeResponseModel>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getCompanyGroupedCustomersOption, {
      schema: GroupedCodeResponseModel
    })
    dispatch({
      type: COMPANY_GROUPED_CUSTOMERS_SUCCESS,
      payload: response.data.data
    })
    setOptionCount(dispatch)(COMPANY_GROUPED_CUSTOMERS_COUNT, response.data.data)
  } catch (error) {
    dispatch({
      type: COMPANY_GROUPED_CUSTOMERS_ERROR
    })
    throw error
  }
}

export const getCompanyGovConAction = (dispatch: Dispatch) => async (): Promise<void> => {
  try {
    dispatch({
      type: COMPANY_GOV_CON
    })
    const response = await request<CompanyGovConModelResponse>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getCompanyGovConOption, {
      schema: CompanyGovConModelResponse
    })
    dispatch({
      type: COMPANY_GOV_CON_SUCCESS,
      payload: response.data.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_GOV_CON_ERROR
    })
    throw error
  }
}

export const getCompanyGovConSizeAction = (dispatch: Dispatch) => async (): Promise<void> => {
  try {
    dispatch({
      type: COMPANY_GOV_CON_SIZE
    })
    const response = await request<CompanyGovConSizeModelResponse>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getCompanyGovConSizeOption, {
      schema: CompanyGovConSizeModelResponse
    })
    dispatch({
      type: COMPANY_GOV_CON_SIZE_SUCCESS,
      payload: response.data.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_GOV_CON_SIZE_ERROR
    })
  }
}

export const getCompanySetAsideAction = (dispatch: Dispatch) => async (): Promise<void> => {
  try {
    dispatch({
      type: COMPANY_SET_ASIDE
    })
    const response = await request<CompanySetAsideResponse>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getCompanySetAsideOption, {
      schema: CompanySetAsideResponse
    })
    dispatch({
      type: COMPANY_SET_ASIDE_SUCCESS,
      payload: response.data.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_SET_ASIDE_ERROR
    })
  }
}

export const getCompanyVehicleAction = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch({
    type: COMPANY_VEHICLE
  })
  try {
    const response = await request<VehicleResponseModel>(RequestMethods.Get, Paths.Hp.CompanyProfiles.getCompanyVehicleOption, {
      schema: VehicleResponseModel
    })
    dispatch({
      type: COMPANY_VEHICLE_SUCCESS,
      payload: response.data.data
    })
  } catch (error) {
    dispatch({
      type: COMPANY_VEHICLE_ERROR
    })
  }
}