import React from 'react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import TabPanel from './TabPanel'

export interface TabItemProps {
  index?: number
  setValue?: (value: number) => void
  parentIndex?: number
  setParentValue?: (value: number) => void
}

const StyledTabs = withStyles({
  root: {
    background: 'tranparent',
    padding: '0 20px',
    borderBottom: '1px solid #D9DBE9'
  },
})(Tabs)
    
const StyledTab = withStyles({
  root: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '32px',
    minWidth: 'auto',
    padding: 0,
    marginRight: '40px',
    textTransform: 'capitalize',
  },
})(Tab)

interface TabItem {
    id: number
    title: string
}

interface TabsProps {
    tabLabels: TabItem[]
    tabItems: JSX.Element[]
    handleChange?: () => void
}

const CustomTabs = ({tabLabels, handleChange, tabItems}: TabsProps) => {
  const [value, setValue] = React.useState<number>(0)

  const generateTabProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    if(handleChange) {
      handleChange()
    }
  }

  const propsToTabItem = (el: JSX.Element, index: number) => {
    return React.cloneElement(el, {...el.props, setValue, index})
  }

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleTab}
        indicatorColor='primary'
        textColor='primary'
        aria-label='full-width-tab'
        TabIndicatorProps={{
          style: {
            height: '4px',
            borderRadius: 10
          }
        }}
      >
        {
          tabLabels.map((el, index) => (
            <StyledTab key={el.id} label={el.title} {...generateTabProps(index)} />
          ))
        }
      </StyledTabs>
      {
        tabItems.map((el, index) => (
          <TabPanel key={index} value={value} index={index}>
            {propsToTabItem(el, index)}
          </TabPanel>
        ))
      }
    </>
  )
}

export default CustomTabs