import React, { useRef } from 'react'
import { Button, Checkbox, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import moment from 'moment'
import { TokenModel } from 'models/common/owner/token.model'
import { deleteToken, updateToken } from 'store/common/owner/actions'
import { useNotification } from 'context/NotificationContext'
import { NotificationTypeList } from 'components/NotificationType'
import { getErrorMessage } from 'helpers/errors'

export interface Values {
  token: string
  active: boolean
}

interface Props {
  token: TokenModel
  idx: number
  onUpdate?: (id: string, values: Values) => void
  onDelete?: (id: string) => void
}

const Item = ({token, idx, onDelete, onUpdate}: Props) => {
  const notification = useRef(useNotification())
  const theme = useTheme()

  const [updateState, setUpdateState] = React.useState(false)
  const [values, setValues] = React.useState<Values>({ token: token.token, active: token.active })

  const handleUpdateCancel = () => {
    setValues({
      token: token.token,
      active: token.active
    })
    setUpdateState(prev => !prev)
  }

  const handleUpdateConfirm = async () => {
    try {
      await updateToken(token.id, values)
      notification.current.show('Token successfully updated', NotificationTypeList.Success)
      setUpdateState(prev => !prev)
      onUpdate && onUpdate(token.id, values)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteToken(token.id)
      notification.current.show('Token successfully deleted', NotificationTypeList.Success)
      onDelete && onDelete(token.id)
    }
    catch (e) {
      notification.current.show(getErrorMessage(e), NotificationTypeList.Error)
    }
  }

  const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      token: e.target.value
    })
  }
  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      active: e.target.checked
    })
  }

  return (
    <Grid
      container
      spacing={2}
      key={token.id}
      alignItems={'center'}
    >
      <Grid item md={1}><Typography variant='h5'>{idx}</Typography></Grid>
      <Grid item md={4}>
        <TextField
          value={values.token}
          onChange={handleChangeToken}
          disabled={!updateState}
          fullWidth
        />
      </Grid>
      <Grid item md={2}>
        <Checkbox
          color='primary'
          checked={values.active}
          onChange={handleChangeActive}
          disabled={!updateState}
        />
      </Grid>
      <Grid item md={2}>{moment(token.updatedAt).format('lll')}</Grid>
      <Grid item md={3}>
        {!updateState ? (
          <Button color='primary' onClick={() => setUpdateState(prev => !prev)}>
            Update
          </Button>
        ) : (
          <>
            <Button
              style={{color: theme.palette.success.main}}
              onClick={handleUpdateConfirm}>Confirm</Button>
            <Button onClick={handleUpdateCancel}>Cancel</Button>
          </>
        )}
        <span> | </span>
        <Button
          style={{color: theme.palette.error.main}}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  )
}

export default Item