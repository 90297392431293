import { Record } from 'immutable'
import { Ad } from 'models/common/ads/ad.model'
import { IAction } from 'types/common'
import { GET_ITEMS, GET_ITEMS_SUCCESS, GET_ITEMS_ERROR, SET_COUNTER, SET_CLICKED } from './actions'

export interface IAdsRecord {
  data: Ad[]
  counter: number
  clicked: {
    [key: string]: true
  }
  loaded: boolean
  loading: boolean
}

const AdsRecord = Record<IAdsRecord>({
  data: [],
  counter: 0,
  clicked: {},
  loaded: false,
  loading: false
})

export const ads = (state: Record<IAdsRecord> = new AdsRecord(), action: IAction) => {
  switch (action.type) {
    case GET_ITEMS:
      return state
        .set('loading', true)

    case GET_ITEMS_SUCCESS:
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('data', action.payload)

    case GET_ITEMS_ERROR:
      return state
        .set('loading', false)
        .set('loaded', false)

    case SET_COUNTER:
      return state
        .set('counter', action.payload)

    case SET_CLICKED:
      return state
        .update('clicked', (val) => ({...val, [action.payload]: true}))

    default:
      return state
  }
}