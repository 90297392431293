import * as calcInterface from './calc.interface'
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'
import { numberToStrWithRound } from 'common/number'
import { BasePaginationModel, BaseResponseModel } from '../../../base/base.model'
import * as rfpInterface from '../rfp.interface'
import { AbstractHighlightArea, HighlightArea } from './calc.interface'

@jsonObject
export class PWinCalc implements calcInterface.IPWinCalc {
  @jsonMember({ constructor: Boolean })
  calculated: boolean = false

  @jsonMember({ constructor: String })
  total: string = ''
}

@jsonObject
export class HpRfpLightCalcModel implements calcInterface.IHpRfpLightCalcModel {
  @jsonMember({ constructor: Boolean })
  pursueCalculated: boolean = false

  @jsonMember({ constructor: Boolean })
  estimatedValueCalculated: boolean = false

  @jsonMember({ constructor: Number })
  estimatedValueRaw: number = 0

  @jsonMember({ constructor: PWinCalc })
  pWin: PWinCalc | undefined = undefined

  @jsonMember({ constructor: String })
  dueTimeline: string = ''

  @jsonMember({ constructor: String })
  proposalLength: string = 'N/A'

  @jsonMember({ constructor: Number })
  hoursToPursue?: number

  @jsonMember({ constructor: String })
  estimatedValue: string = 'N/A'

  @jsonMember({ constructor: String })
  estimatedValuePhase2?: string = 'N/A'

  @jsonMember({ constructor: String })
  costToPursue: string = 'N/A'

  @jsonMember({ constructor: String })
  previousWins?: string = ''

  @jsonMember({ constructor: String })
  winnerSize?: string = ''

  @jsonMember(Number)
  views: number = 0

  @jsonMember(Number)
  score: number = 0
}

@jsonObject
export class HpRfpTinderLightCalcModel {
  @jsonMember({ constructor: Number, deserializer: (costToPursue) => numberToStrWithRound(costToPursue) })
  costToPursue?: string = undefined
  @jsonMember({ constructor: Number, deserializer: (estimatedValue) => numberToStrWithRound(estimatedValue) })
  estimatedValue?: string = undefined
  @jsonMember({ constructor: Number })
  pWin?: number = undefined
  @jsonMember({ constructor: Number, deserializer: (costToPursue) => numberToStrWithRound(costToPursue) })
  riskAndEffort?: string = undefined
  @jsonMember({ constructor: Number})
  views?: number = 0
}

@jsonObject
export class HpRfpDocumentSynthesisText {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String })
  text: string = ''

  @jsonMember({ constructor: String })
  fileName: string = ''
}

@jsonObject
export class HpRfpDocumentSynthesisAnalysisValueItem {
  @jsonMember({ constructor: String })
  id: string = ''

  @jsonMember({ constructor: String, name: 'rawValue' })
  value: string = ''

  @jsonMember({ constructor: String })
  type: string = ''
}

@jsonObject
export class HpRfpDocumentSynthesisAnalysisValue {
  @jsonMember({ constructor: String })
  type: string = ''

  @jsonMember({ constructor: String })
  value?: string = ''

  @jsonArrayMember(HpRfpDocumentSynthesisAnalysisValueItem)
  values?: HpRfpDocumentSynthesisAnalysisValueItem[] = []
}

@jsonObject
export class HpRfpDocumentSynthesisAnalysis {
  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  pastPerformance: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  evaluation: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  pricing: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  certsQuals: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  proposal: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  tasks: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  formatting: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  pointsOfContact: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  jobTitles: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()

  @jsonMember({constructor: HpRfpDocumentSynthesisAnalysisValue})
  dates: HpRfpDocumentSynthesisAnalysisValue = new HpRfpDocumentSynthesisAnalysisValue()
}

@jsonObject
export class HpRfpDocumentSynthesisPayload {
  @jsonArrayMember(HpRfpDocumentSynthesisText)
  texts: HpRfpDocumentSynthesisText[] = []

  @jsonMember({ constructor: HpRfpDocumentSynthesisAnalysis })
  analysis: HpRfpDocumentSynthesisAnalysis = new HpRfpDocumentSynthesisAnalysis()
}

@jsonObject
export class HpRfpDocumentSynthesisResponseModel extends BaseResponseModel {
  @jsonMember({constructor: HpRfpDocumentSynthesisPayload})
  data?: HpRfpDocumentSynthesisPayload = new HpRfpDocumentSynthesisPayload()
}

@jsonObject
export class HpRfpAnalysisAnswer {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  value: string = ''
}

@jsonObject
export class HpRfpAnalysisItem {
  @jsonMember({constructor: String})
  id: string = ''

  @jsonMember({constructor: String})
  text: string = ''

  @jsonMember({constructor: String, deserializer: (val) => val && val.toString ? val.toString() : val})
  rawValue: string = ''

  @jsonMember({constructor: String, deserializer: (val) => val && val.toString ? val.toString() : val})
  value: string = ''

  @jsonMember({constructor: String})
  fileName: string = ''

  @jsonMember({constructor: String})
  type: calcInterface.IHpRfpAnalysisTypes | null = null

  @jsonMember({constructor: HpRfpAnalysisAnswer})
  answer: HpRfpAnalysisAnswer = new HpRfpAnalysisAnswer()
}

@jsonObject
export class HpRfpAnalysisHeaderData {
  @jsonMember({constructor: Number})
  page: number = 1

  @jsonMember({constructor: Number})
  level: number = 1
}

@jsonObject
export class HpRfpAnalysisThemeData {
  @jsonMember({constructor: Number})
  page: number = 1

  @jsonMember({constructor: String})
  predictions: string = ''
}

@jsonObject
export class HpRfpAnalysisHeaderItem extends HpRfpAnalysisItem {
  @jsonMember({constructor: HpRfpAnalysisHeaderData, name: 'rawValue', deserializer: (json) => JSON.parse(json)})
  data: HpRfpAnalysisHeaderData = new HpRfpAnalysisHeaderData()
}

@jsonObject
export class HpRfpAnalysisThemeItem extends HpRfpAnalysisItem {
  @jsonMember({constructor: HpRfpAnalysisHeaderData, name: 'rawValue', deserializer: (json) => JSON.parse(json)})
  data: HpRfpAnalysisThemeData = new HpRfpAnalysisThemeData()
}

@jsonObject
export class HpRfpWiredIndicatorResponseWindow {
  @jsonMember({ constructor: Boolean})
  weekend: boolean = false

  @jsonMember({ constructor: Boolean})
  shortWindow: boolean = false
}

@jsonObject
export class HpRfpWiredIndicatorNaicsCode {
  @jsonMember({ constructor: String})
  favorsBusiness: string | null = null

  @jsonMember({ constructor: Boolean})
  changedTypes: boolean = false
}

@jsonObject
export class HpRfpWiredIndicatorSetAside {
  @jsonMember({ constructor: Boolean})
  changedTypes: boolean = false

  @jsonMember({ constructor: Boolean})
  isUnusual: boolean = false
}

@jsonObject
export class HpRfpWiredIndicators {
  @jsonMember({ constructor: HpRfpWiredIndicatorResponseWindow})
  responseWindow: HpRfpWiredIndicatorResponseWindow = new HpRfpWiredIndicatorResponseWindow()

  @jsonMember({ constructor: HpRfpWiredIndicatorNaicsCode})
  naics: HpRfpWiredIndicatorNaicsCode = new HpRfpWiredIndicatorNaicsCode()

  @jsonMember({ constructor: HpRfpWiredIndicatorSetAside})
  setAside: HpRfpWiredIndicatorSetAside = new HpRfpWiredIndicatorSetAside()
}

@jsonObject
export class HpRfpAnalysisResponseModel extends BasePaginationModel {
  @jsonArrayMember(HpRfpAnalysisItem)
  data: HpRfpAnalysisItem[] = []
}

@jsonObject
export class HpRfpWiredIndicatorsResponseModel extends BaseResponseModel {
  @jsonMember({ constructor: HpRfpWiredIndicators })
  data: HpRfpWiredIndicators = new HpRfpWiredIndicators()
}

@jsonObject
export class HpRfpWorkItem {
  @jsonMember({ constructor: String })
  title: string = ''

  @jsonMember({ constructor: Number })
  count: number = 0
}

@jsonObject
export class HpRfpWorkResponseModel extends BaseResponseModel {
  @jsonArrayMember(HpRfpWorkItem)
  data: HpRfpWorkItem[] = []
}

@jsonObject
export class HpRfpDocumentSynthesisTypePayload {
  @jsonArrayMember(HpRfpAnalysisItem)
  data: HpRfpAnalysisItem[] = []
}

@jsonObject
export class HpRfpDocumentHeadersTypePayload {
  @jsonArrayMember(HpRfpAnalysisHeaderItem)
  data: HpRfpAnalysisHeaderItem[] = []
}

@jsonObject
export class HpRfpDocumentThemesTypePayload {
  @jsonArrayMember(HpRfpAnalysisThemeItem)
  data: HpRfpAnalysisThemeItem[] = []
}

@jsonObject
export class ExternalDownloadLinkResponseModel {
  @jsonMember({ constructor: String })
  location: string = ''
}

@jsonObject
export class HpStrategyWinThemesModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TWinThemes = 'primary'
}

@jsonObject
export class HpStrategyActivitiesModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TActivities = 'goals'
}

@jsonObject
export class HpStrategyWinThemesResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyWhinThemesResponseModel {
  @jsonArrayMember(HpStrategyWinThemesModel)
  data: HpStrategyWinThemesModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class SetHpStrategyActivitiesModel {
  @jsonMember({ constructor: String})
  id: string = ''
}

@jsonObject
export class SetHpStrategyWinThemesResposeModel extends BaseResponseModel {
  @jsonMember({constructor: SetHpStrategyActivitiesModel})
  data: SetHpStrategyActivitiesModel = new SetHpStrategyActivitiesModel()
}

@jsonObject
export class HpStrategyActivitiesResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyActivitiesResponseModel {
  @jsonArrayMember(HpStrategyActivitiesModel)
  data: HpStrategyActivitiesModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}


@jsonObject
export class HpStrategyPastPerformanceData {
  @jsonMember({ constructor: String})
  degrees: string = ''

  @jsonMember({ constructor: String})
  certifications: string = ''

  @jsonMember({ constructor: String})
  professionalExperience: string = ''

  @jsonMember({ constructor: String})
  governmentExperience: string = ''

  @jsonMember({ constructor: String})
  customerDescription: string = ''

  @jsonMember({ constructor: String})
  teamDescription: string = ''

  @jsonMember({ constructor: String})
  workDescription: string = ''

  @jsonMember({ constructor: String})
  workDuration: string = ''

  @jsonMember({ constructor: String})
  workEnd: string = ''

  @jsonMember({ constructor: String})
  workApproach: string = ''
}

@jsonObject
export class HpStrategyPastPerformanceModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: HpStrategyPastPerformanceData})
  data: HpStrategyPastPerformanceData = new HpStrategyPastPerformanceData()

  @jsonMember({ constructor: String})
  subType: rfpInterface.TPastPerformance = 'customer'
}

@jsonObject
export class HpStrategyPastPerformanceResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyPastPerformanceResponseModel {
  @jsonArrayMember(HpStrategyPastPerformanceModel)
  data: HpStrategyPastPerformanceModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class HpStrategyPastPerformanceRecommendationsModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TPastPerformance = 'customer'
}

@jsonObject
export class HpStrategyPastPerformanceRecommendationsResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyPastPerformanceRecommendationsResponseModel {
  @jsonArrayMember(HpStrategyPastPerformanceRecommendationsModel)
  data: HpStrategyPastPerformanceRecommendationsModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class HpStrategyEvaluationCriteriaModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TEvalueationCriteria = 'recommendations'
}

@jsonObject
export class HpStrategyEvaluationCriteriaResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyEvaluationCriteriaResponseModel {
  @jsonArrayMember(HpStrategyEvaluationCriteriaModel)
  data: HpStrategyEvaluationCriteriaModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}


@jsonObject
export class HpStrategyProcessModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TProcess = 'smallBusiness'
}

@jsonObject
export class HpStrategyProcessResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyProcessResponseModel {
  @jsonArrayMember(HpStrategyProcessModel)
  data: HpStrategyProcessModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class HpStrategyOpenQuestionsModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  data: string = ''

  @jsonMember({ constructor: String})
  subType: rfpInterface.TOpenQuestions = 'questions'
}

@jsonObject
export class HpStrategyOpenQuestionsResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyOpenQuestionsResponseModel {
  @jsonArrayMember(HpStrategyOpenQuestionsModel)
  data: HpStrategyOpenQuestionsModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class HpStrategyProcessNaicsModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  code: string = ''

  @jsonMember({ constructor: String})
  size: string = ''

  @jsonMember({ constructor: Number})
  value: number = 0

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: String})
  description: string = ''
}

@jsonObject
export class HpStrategyProcessNaicsDataModel {
  @jsonMember({ constructor: HpStrategyProcessNaicsModel})
  contractCode: HpStrategyProcessNaicsModel = new HpStrategyProcessNaicsModel()

  @jsonArrayMember(HpStrategyProcessNaicsModel)
  codes: HpStrategyProcessNaicsModel[] = []
}

@jsonObject
export class HpStrategyProcessNaicsResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyProcessNAICSResponseModel {
  @jsonMember({constructor: HpStrategyProcessNaicsDataModel})
  data: HpStrategyProcessNaicsDataModel = new HpStrategyProcessNaicsDataModel()
}

@jsonObject
export class HpStrategyProcessSetAsideModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  agencyCode: string = ''

  @jsonMember({ constructor: String})
  type: string = ''

  @jsonMember({ constructor: Number})
  delta: number = 0

  @jsonMember({ constructor: String})
  category: string = ''
}

@jsonObject
export class HpStrategyProcessSetAsideDataModel {
  @jsonArrayMember(HpStrategyProcessSetAsideModel)
  prime: HpStrategyProcessSetAsideModel[] = []

  @jsonArrayMember(HpStrategyProcessSetAsideModel)
  sub: HpStrategyProcessSetAsideModel[] = []
}

@jsonObject
export class HpStrategyProcessSetAsideResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategyProcessSetAsideResponseModel {
  @jsonMember({constructor: HpStrategyProcessSetAsideDataModel})
  data: HpStrategyProcessSetAsideDataModel = new HpStrategyProcessSetAsideDataModel()
}

@jsonObject
export class HpStrategySummaryDataModel {
  @jsonMember({ constructor: String})
  documents: string = ''

  @jsonMember({ constructor: String})
  questions: string = ''

  @jsonMember({ constructor: String})
  contractingTeamEngagement: string = ''

  @jsonMember({ constructor: String})
  endUserEngagement: string = ''

  @jsonMember({ constructor: String})
  smallBusinessEngagement: string = ''
}

@jsonObject
export class HpStrategySummaryModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  title: string = ''

  @jsonMember({ constructor: HpStrategySummaryDataModel})
  data: HpStrategySummaryDataModel = new HpStrategySummaryDataModel()

  @jsonMember({ constructor: String})
  subType: rfpInterface.TSummary = 'summary'
}

@jsonObject
export class HpStrategySummaryResposeModel extends BaseResponseModel implements rfpInterface.IHPStrategySummaryResponseModel {
  @jsonArrayMember(HpStrategySummaryModel)
  data: HpStrategySummaryModel[] = []

  @jsonMember({ constructor: Number})
  count: number = 0
}

@jsonObject
export class HighlightAreaModel {
  @jsonMember({ constructor: Number})
  height: number = 0

  @jsonMember({ constructor: Number})
  left: number = 0

  @jsonMember({ constructor: Number})
  pageIndex: number = 0

  @jsonMember({ constructor: Number})
  top: number = 0

  @jsonMember({ constructor: Number})
  width: number = 0
}

@jsonObject
export class NoteModel {
  @jsonMember({ constructor: String})
  id: string = ''

  @jsonMember({ constructor: String})
  content: calcInterface.HeaderType = 'title'

  @jsonMember({ constructor: String})
  parentId: string = ''

  @jsonMember({ constructor: String})
  quote: string = ''

  @jsonMember({ constructor: String})
  titleType: calcInterface.TitlesType = 'H1'

  // we have multiple formats for highlights in pdf reader and abstract render, we have to bypass the value as it is
  @jsonMember({ constructor: HighlightAreaModel, deserializer: (val: HighlightArea | AbstractHighlightArea) => val })
  highlightAreas: HighlightAreaModel[] = []

  @jsonMember({ constructor: String})
  prevId: string | undefined = undefined

  @jsonMember({ constructor: String})
  nextId: string | undefined = undefined

  @jsonMember({ constructor: String })
  fileName: string | undefined = undefined
}

@jsonObject
export class ResponseOutlineResposeModel extends BaseResponseModel implements calcInterface.IResponseOutlineResponseModel {
  @jsonArrayMember(NoteModel)
  data: NoteModel[] = []
}

@jsonObject
export class CreateResponseOutlineModel {
  @jsonMember({ constructor: String})
  id: string = ''
}

@jsonObject
export class CreateResponseOutlineResposeModel extends BaseResponseModel implements calcInterface.ICreateResponseOutlineResponseModel {
  @jsonMember({constructor: CreateResponseOutlineModel})
  data: CreateResponseOutlineModel = new CreateResponseOutlineModel()
}

