import { IRootState } from 'store/reducers'
import { createSelector } from 'reselect'

const options = (state: IRootState) => state.companyProfile.options

export const govConsLoadingSelector = createSelector(
  options,
  options => options.govCon.get('loading')
)

export const govConsLoadedSelector = createSelector(
  options,
  options => options.govCon.get('loaded')
)

export const govConsSelector = createSelector(
  options,
  options => options.govCon.get('data')
)

export const govConSizesLoadingSelector = createSelector(
  options,
  options => options.govConSize.get('loading')
)

export const govConSizesLoadedSelector = createSelector(
  options,
  options => options.govConSize.get('loaded')
)

export const govConSizesSelector = createSelector(
  options,
  options => options.govConSize.get('data')
)

export const companyAgenciesLoadedSelector = createSelector(
  options,
  options => options.agencies.get('loaded')
)

export const companyAgenciesSelector = createSelector(
  options,
  options => options.agencies.get('data')
)

export const companyAgencyCountSelector = createSelector(
  options,
  options => options.agencies.get('count')
)

export const companySetAsidesLoadedSelector = createSelector(
  options,
  options => options.setAsides.get('loaded')
)

export const companySetAsidesSelector = createSelector(
  options,
  options => options.setAsides.get('data')
)

export const companyVehicleLoadedSelector = createSelector(
  options,
  options => options.vehicles.get('loaded')
)

export const companyVehiclesSelector = createSelector(
  options,
  options => options.vehicles.get('data')
)
